<template>
  <p-panel-properties class="panel-properties">
    <p-property-tile title="Move">
      <v-switch v-model="value.protection.move"></v-switch>
    </p-property-tile>
    <p-property-tile title="Resize">
      <v-switch v-model="value.protection.resize"></v-switch>
    </p-property-tile>
    <p-property-tile title="Change Content">
      <v-switch v-model="value.protection.data"></v-switch>
    </p-property-tile>
    <p-property-tile title="Delete block">
      <v-switch v-model="value.protection.delete"></v-switch>
    </p-property-tile>
  </p-panel-properties>
</template>

<script>
import PPropertyTile from "../utils/PPropertyTile.vue"
import PPanelProperties from "../utils/PPanelProperties.vue"
export default {
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelProperties
  }
};
</script>

<style>
  .panel-properties .v-list__tile__action {
    overflow: inherit !important;
  }
</style>
