<template>
  <v-dialog :value="value" @input="$emit('input', false)" :full-width="fullWidth" :width="fullWidth ? 'auto' : '800'">
    <v-card style="height: calc(100vh - 50px)" class="lp-popup-button-action-library-card">
      <div style="display: flex; justify-content: flex-end">
        <v-icon @click="$emit('input', false)"> mdi-close </v-icon>
      </div>
      <div class="lp-popup-button-action-library-content" v-if="value">
        <item-viewer v-if="content_ref" :value="content_ref" :view="view" :key="updates" v-on="$listeners" />
        <v-card-title v-else>
          <div>
            <span>Content can not be loaded</span>
          </div>
        </v-card-title>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ItemViewer } from "plant-common"

export default {
  props: ["value", "fullWidth", "content_ref", "view"],
  components: { ItemViewer },
  data() {
    return {
      updates: 0
    }
  },
  watch: {
    value() {
      this.updates += 1
    }
  }
}
</script>

<style>
.lp-popup-button-action-library-card {
  width: 100%;
  padding: 20px;
}
.lp-popup-button-action-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 10px);
  background-color: #fff;
  position: relative;
}
.lp-popup-button-action-library-content .fix-viewer {
  overflow: hidden;
}
</style>