import { getType, getObjective, getCustomId, getTitle } from "../../../../utils/dbTool"
import uuid from "../../../../utils/uuid"
import * as models from "../../models/models.js"

export default {
  methods: {
    /**
     * getActivitiesObjectMap: returns a map of objective: [activities]
     * @param {*} slide
     * @returns object
     */
    getActivitiesObjectMap(slide) {
      let activityObjectiveMap = []
      let objectiveId = false
      let objectiveCustomId = false
      let objectiveTitle = false
      slide.slideProperties.activities.forEach(activity => {
        objectiveId = "none"
        objectiveCustomId = "N/A"
        objectiveTitle = "N/A"
        if (activity.selected) {
          if (getObjective(activity.content_ref)) {
            objectiveId = getObjective(activity.content_ref)._id
            objectiveCustomId = getCustomId(getObjective(activity.content_ref))
            objectiveTitle = getTitle(getObjective(activity.content_ref))
          }
          if (!activityObjectiveMap[objectiveId]) {
            activityObjectiveMap[objectiveId] = {
              custom_id: objectiveCustomId,
              title: objectiveTitle,
              content_ref: getObjective(activity.content_ref),
              activities: []
            }
          }
          activityObjectiveMap[objectiveId].activities.push(activity)
        }
      })
      return activityObjectiveMap
    },
    /**
     * getActivitiesInQuiz: returns a map of objective: [activities] that are being used inside the quiz
     * @param {*} slide
     * @returns object
     */
    getActivitiesInQuiz(slide) {
      let activityObjectiveMap = this.getActivitiesObjectMap(slide)
      const slideChildrenActivities = slide.children.filter(child => child.type !== "ResultsSlideTemplate")
      const quizActivities = slideChildrenActivities.map(child => child.id)
      // Filter the map (only return the ones that are inside quizActivities)
      for (let [objectiveId, objective] of Object.entries(activityObjectiveMap)) {
        objective.activities = objective.activities.filter(activity => quizActivities.includes(activity.slide_id))
      }
      return activityObjectiveMap
    },
    /**
     * getQuizActivities: returns Quiz activities considering number of activities and sort type
     * @param {*} activityObjectiveMap
     * @param {*} numberActivities
     * @param {*} random
     * @returns Array
     */
    getQuizActivities(activityObjectiveMap, numberActivities, random) {
      function randomIntFromInterval(min, max) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
      }

      let totalActivities = 0
      Object.entries(activityObjectiveMap).forEach(([key, value]) => {
        totalActivities = totalActivities + value.activities.length
      })
      let randomActivity = false
      let quizActivities = []
      const activitiesToBeLoaded = numberActivities < totalActivities ? numberActivities : totalActivities //minimun between quiz number and activities number
      // get activities randomly until we complete the quiz
      while (quizActivities.length < activitiesToBeLoaded) {
        Object.entries(activityObjectiveMap).forEach(([key, value]) => {
          if (quizActivities.length < activitiesToBeLoaded) {
            randomActivity = value.activities.splice(
              random ? randomIntFromInterval(0, value.activities.length - 1) : 0,
              1
            )
            if (randomActivity.length > 0) {
              randomActivity[0].objectiveId = key
              quizActivities.push(randomActivity[0])
            }
          }
        })
      }
      return quizActivities
    },
    buildRandomActivities(activityObjectiveMap, numberActivities) {
      let quizActivities = this.getQuizActivities(activityObjectiveMap, numberActivities, true)
      return quizActivities.sort((a, b) => 0.5 - Math.random())
    },
    buildActivityListActivities(activityObjectiveMap, numberActivities) {
      let quizActivities = this.getQuizActivities(activityObjectiveMap, numberActivities, false)
      return quizActivities.sort((a, b) => {
        if (a.objectiveId < b.objectiveId) {
          return -1
        }
        if (a.objectiveId > b.objectiveId) {
          return 1
        }
        return 0
      })
    },
    buildActivitiesFromState(activityQuizState, activities) {
      const activitiesLoaded = []
      for (const key in activityQuizState.activities) {
        activities.forEach(activity => {
          if (activity.slide_id === key) {
            activitiesLoaded.push(activity)
          }
        })
      }
      return activitiesLoaded
    },

    /**
     * getBlockId: return block id from activity state if it was set, otherwise return new uuid
     * @param {*} slide
     * @param {*} activity
     * @param {*} activityState
     */
    getBlockId(slide, activity, activityState, blockType) {
      if (!activityState) {
        return uuid()
      } else {
        if (
          this.getSlideActivityState(slide) &&
          this.getSlideActivityState(slide).activities &&
          this.getSlideActivityState(slide).activities[activity.slide_id] &&
          this.getSlideActivityState(slide).activities[activity.slide_id].blocks &&
          this.getSlideActivityState(slide).activities[activity.slide_id].blocks
        ) {
          let blockId = null
          for (const [id, block] of Object.entries(this.getSlideActivityState(slide).activities[activity.slide_id].blocks)) {
            if (block.type === blockType) {
              blockId = id
              break
            }
          }
          return blockId ? blockId : uuid()
        } else {
          return uuid()
        }
      }
    },

    /**
     * buildQuiz: activities array transformation to Slides using Templates
     * @param {*} slide
     * @param {*} activities
     * @param {*} templates
     * @param {*} activityState
     */
    buildQuiz(slide, activities, templates, activityState) {
      let index = 0
      const quizLocked =
        slide.slideProperties && slide.slideProperties.settings && slide.slideProperties.settings.locked
          ? slide.slideProperties.settings.locked
          : false
      slide.locked = quizLocked
      activities.forEach(activity => {
        if (activity.selected) {
          switch (getType(activity.content_ref)) {
            case "activityMultipleChoice":
              if (templates["multipleChoiceTemplate"]) {
                const amcTemplate = templates["multipleChoiceTemplate"].blocks.find(
                  block => block.type == "contentMultipleChoiceQuestionTemplate"
                )
                // get ActivityMultipleChoice template to create AMC block
                let amcTemplateBlock = JSON.parse(JSON.stringify(amcTemplate))
                if (amcTemplateBlock) {
                  amcTemplateBlock.id = this.getBlockId(slide, activity, activityState, "contentMultipleChoiceQuestion")
                  amcTemplateBlock.data.content_ref = activity.content_ref
                  amcTemplateBlock.type = "contentMultipleChoiceQuestion"
                  // create and add slide to Quiz
                  let amcSlide = new models.Slide()
                  index = index + 1
                  amcSlide.id = activity.slide_id
                  amcSlide.title = `Activity ${index}`
                  amcSlide.locked = quizLocked

                  // Add other blocks not like contentMultipleChoiceQuestionTemplate
                  templates["multipleChoiceTemplate"].blocks.map(block => {
                    if (block.type !== "contentMultipleChoiceQuestionTemplate") {
                      amcSlide.blocks.push(block)
                    } else {
                      amcSlide.blocks.push(amcTemplateBlock)
                    }
                  })

                  this.addPathToItem(amcSlide, slide)
                  slide.children.push(amcSlide)
                  // finish adding slide
                }
              }
              break
          }
        }
      })
    },
    initQuizActivities(activityState) {
      this.slideArray.forEach(slide => {
        if (slide.type == "QuizSlide") {
          // Get Activities templates
          const templates = []
          // - Activity multiple choice
          const multipleChoiceTemplate = slide.children.find(child => child.type == "MultipleChoiceSlideTemplate")
          slide.children.splice(
            slide.children.findIndex(child => child.type == "MultipleChoiceSlideTemplate"),
            1
          )
          templates["multipleChoiceTemplate"] = multipleChoiceTemplate

          // Get Result template
          const resultsSlideTemplate = slide.children.find(child => child.type == "ResultsSlideTemplate")
          slide.children.splice(
            slide.children.findIndex(child => child.type == "ResultsSlideTemplate"),
            1
          )

          const activityObjectiveMap = this.getActivitiesObjectMap(slide)
          const numberActivities = slide.slideProperties.settings.activities_number
            ? slide.slideProperties.settings.activities_number
            : 10
          let activities = slide.slideProperties.activities

          if (!activityState) {
            switch (slide.slideProperties.settings.sort_activities) {
              case "random":
                activities = this.buildRandomActivities(activityObjectiveMap, numberActivities)
                break
              case "activity_list":
                activities = this.buildActivityListActivities(activityObjectiveMap, numberActivities)
                break
              default:
                // do nothing
                break
            }
          } else {
            const activityQuizState = this.getSlideActivityState(slide)
            activities = this.buildActivitiesFromState(activityQuizState, activities)
          }
          this.buildQuiz(slide, activities, templates, activityState)
          slide.children.push(resultsSlideTemplate)
        }
      })
    }
  }
}
