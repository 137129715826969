export function mapInjectedData(injected, props) {
  return props.reduce(function (all, prop) {
    all[prop] = function () {
      return this[injected][prop]
    }
    return all
  }, {})
}
export function mapInjectedMethods(injected, methods) {
  return methods.reduce(function (all, method) {
    all[method] = function () {
      return this[injected][method].apply(null, arguments)
    }
    return all
  }, {})
}

export function isElectron() {
  return navigator.userAgent.toLowerCase().indexOf(" electron/") > -1
}

export function uploadFile(accept) {
  return new Promise((resolve, reject) => {
    var input = document.createElement("input")
    input.setAttribute("type", "file")
    if (accept) {
      input.setAttribute("accept", accept)
    }
    input.style.display = "none"
    input.onchange = function (evt) {
      if (!(evt.currentTarget.files.length > 0)) {
        reject()
        return
      }

      var file = evt.currentTarget.files[0]
      let url = URL.createObjectURL(file)
      resolve({ url, file, name })
    }
    input.click()
    document.body.appendChild(input)
  })
}

export function getContentId(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.content_id
    ? itemRevision.header.metadata.content_id
    : itemRevision.item && itemRevision.item.item_id
      ? itemRevision.item.item_id
      : getCustomId(itemRevision)
}

export function getCustomId(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.custom_id
    ? itemRevision.header.metadata.custom_id
    : itemRevision.item && itemRevision.item.custom_id
      ? itemRevision.item.custom_id
      : ""
}

export function getTitle(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.title
    ? itemRevision.header.metadata.title
    : itemRevision.item && itemRevision.item.title
      ? itemRevision.item.title
      : ""
}

export function getType(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.content_revision_type
    ? itemRevision.header.metadata.content_revision_type
    : itemRevision.header && itemRevision.header.item_revision_type
      ? itemRevision.header.item_revision_type
      : ""
}

export function getDescription(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.description
    ? itemRevision.header.metadata.description
    : itemRevision && itemRevision.item && itemRevision.item.description
      ? itemRevision.item.description
      : ""
}

export function getSummaryChanges(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.summary_changes
    ? itemRevision.header.metadata.summary_changes
    : ""
}

export function getMajorVersion(itemRevision) {
  return itemRevision && itemRevision.header && itemRevision.header.major_revision
    ? itemRevision.header.major_revision
    : "0"
}

export function getMinorVersion(itemRevision) {
  return itemRevision && itemRevision.header && itemRevision.header.minor_revision
    ? itemRevision.header.minor_revision
    : "0"
}

export function isUrlAbsolute(url) {
  return url.indexOf("//") === 0
    ? true
    : url.indexOf("://") === -1
      ? false
      : url.indexOf(".") === -1
        ? false
        : url.indexOf("/") === -1
          ? false
          : url.indexOf(":") > url.indexOf("/")
            ? false
            : url.indexOf("://") < url.indexOf(".")
              ? true
              : false
}

/**
 * Function to sort alphabetically an array of objects by some specific key.
 *
 * @param {String} property Key of the object to sort.
 */
export function dynamicSort(property) {
  var sortOrder = 1

  if (property[0] === "-") {
    sortOrder = -1
    property = property.substr(1)
  }

  return function (a, b) {
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property])
    } else {
      return a[property].localeCompare(b[property])
    }
  }
}

export function getFileExtension(file) {
  var matches = file.name.match(/^(.*)\.(.*)$/)
  var [, , files_extension] = matches
  return files_extension ? `.${files_extension.toLowerCase()}` : ""
}

export function getValidFileExtension(content_type) {
  // Returned extension must be "type/extension" format because we will check specific file type on drag&drop event. Can not add "type/*", for example "image/*"
  switch (content_type) {
    case "picture":
      return "image/jpg, image/png, image/jpeg"
    case "scorm":
      return "application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip"
    case "video":
      return "video/mp4, video/ogg, video/webm"
    default:
      return "*"
  }
}

export function getLibraryFromCrossOrigin(origin) {
  if (origin && origin.split("//")[1] && origin.split("//")[1].split("/")[0]) {
    return origin.split("//")[1].split("/")[0]
  } else {
    return undefined
  }
}

export function getLibraryId(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.library_id ? itemRevision.header.library_id : undefined
}

export function prefixCss(css, wrapperId) {
  let id = `#${wrapperId}`
  let char
  let nextChar
  let isAt
  let isIn
  const classLen = id.length

  // makes sure the id will not concatenate the selector
  id += " "

  // removes comments
  css = css.replace(/\/\*(?:(?!\*\/)[\s\S])*\*\/|[\r\n\t]+/g, "")

  // makes sure nextChar will not target a space
  css = css.replace(/}(\s*)@/g, "}@")
  css = css.replace(/}(\s*)}/g, "}}")

  for (let i = 0; i < css.length - 2; i++) {
    char = css[i]
    nextChar = css[i + 1]

    if (char === "@" && nextChar !== "f") isAt = true
    if (!isAt && char === "{") isIn = true
    if (isIn && char === "}") isIn = false

    if (
      !isIn &&
      nextChar !== "@" &&
      nextChar !== "}" &&
      (char === "}" || char === "," || ((char === "{" || char === ";") && isAt))
    ) {
      css = css.slice(0, i + 1) + id + css.slice(i + 1)
      i += classLen
      isAt = false
    }
  }

  // prefix the first select if it is not `@media` and if it is not yet prefixed
  if (css.indexOf(id) !== 0 && css.indexOf("@") !== 0) css = id + css
  return css
}

export function cleanHTMLContent(content) {
	let tmp = document.createElement('DIV');
	tmp.innerHTML = content;
	return tmp.textContent || tmp.innerText || '';
}
