import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeLineConnector"
    this.size.width = 400
    this.size.height = 100
    this.position.x = 0
    this.position.y = 0
    this.data = {
      controls: {
        slope: 0.5
      },
      begin: {
        x: 0,
        y: 0,
        marker: { 
          type: "none", 
          size: 1 
        }
      },
      end: {
        x: 400,
        y: 100,
        marker: { 
          type: "none", 
          size: 1
        }
      },
      style: {
        fill: "transparent",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
