<template>
  <div class="lp-block-video-record">
    <!-- Video -->
    <div>
      <div v-if="value.data.video">
        <block-video-viewer :value="value" />
      </div>
      <div v-else>
        <!-- container in video-recorder to avoid bug after recording -->
        <div>
          <video-recorder :width="640" :height="480" @finishRecord="saveVideo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PFile } from "plant-common/src/utils/models"
import VideoRecorder from "./VideoRecorder.vue"
import BlockVideoViewer from "./BlockVideoViewer.vue"
import BlockAudioViewer from "../audio/BlockAudioViewer.vue"

export default {
  name: "BlockVideoRecord",
  props: ["value"],
  components: { VideoRecorder, BlockVideoViewer, BlockAudioViewer },
  methods: {
    async saveVideo(blob) {
      const file = new File([blob], `record-${blob.lastModifiedDate}.webm`)
      var newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.value.data, "video", newFile)
      if (this.value.data.video && !this.value.data.video.type) {
        this.$set(this.value.data.video, "type", "video/webm")
      }
    }
  }
}
</script>