import UserLoginMessage from "./UserLoginMessage.vue"
import ContributorAssignedToMyselfMessage from "./ContributorAssignedToMyselfMessage.vue"
import NewContentMessage from "./NewContentMessage.vue"
import WorkflowStateChangeMessage from "./WorkflowStateChangeMessage.vue"
import WorkflowTypeChangeMessage from "./WorkflowTypeChangeMessage.vue"
import ContentApprovedMessage from "./ContentApprovedMessage.vue"
import DownloadScormSuccess from "./DownloadScormSuccess.vue"
import DownloadScormFail from "./DownloadScormFail.vue"
export default [
    UserLoginMessage,
    ContributorAssignedToMyselfMessage,
    NewContentMessage,
    WorkflowStateChangeMessage,
    WorkflowTypeChangeMessage,
    ContentApprovedMessage,
    DownloadScormSuccess,
    DownloadScormFail
]