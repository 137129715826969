<template>
  <v-list two-line>
    <style-select :value="wrapperStyle" :mode="lessonPlanController.mode" title="Wrapper styles" />
    <v-divider></v-divider>

    <style-select :value="slideStyle" :mode="lessonPlanController.mode" title="Slide styles" />
  </v-list>
</template>

<script>
import StyleSelect from "./StyleSelect.vue"

export default {
  inject: ["lessonPlanController"],
  components: { StyleSelect },
  computed: {
    wrapperStyle() {
      return this.lessonPlanController.lessonPlanContents.settings.styles.wrapper
    },
    slideStyle() {
      return this.lessonPlanController.lessonPlanContents.settings.styles.slide
    }
  }
}
</script>