import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "activityDiagram",
    title: "Activity Diagram Drag & Drop",
    icon: require("./content-icon.svg"),
    category: "training",
    Viewer,
    Editor,
    Preview: Viewer
}