<template>
  <div class="it-page-wrapper editor__desktop-page" ref="viewer" :style="settingStyles.wrapper">
    <div class="it-page editor__page" ref="canvas">
      <editor-content :editor="editor" ref="interactive-text" :class="{ 'it-editor': editionModeOn }"></editor-content>
    </div>
  </div>
</template>

<script>
import { EditorContent } from "@tiptap/vue-2"
import { renderKatexByClass } from "plant-common"

export default {
  name: "InteractiveTextCanvas",
  inject: ["controller", "DependencyBus"],
  components: { EditorContent },
  props: {
    settingStyles: Object,
    globalVariables: Array,
    editor: Object
  },
  data() {
    return {}
  },
  watch: {
    headerToGo() {
			if (!this.headerToGo) {
				return;
			}

			const header = this.getHeader();

			const canDoScroll = header && header.offsetTop;
			if (canDoScroll) {
				const marginTop = 15;

				this.doScrollBy(header.offsetTop + marginTop);
			}

			this.controller.setHeaderToGo(false);
		},
		editor() {
			if (this.editor && !this.editor.options.editable) {
				this.renderFormulas()
			}
    }
  },
  computed: {
    headerToGo() {
      return this.controller.headerToGo
    },
    editionModeOn() {
      return this.editor ? this.editor.options.editable : false
    },
		revisionHasOldDependencies() {
			return this.controller.vm.value.body.contents.page.dependencies;
		}
  },
  created() {
    if (this.revisionHasOldDependencies) {
      // Retrocompatibility (update "dependencies" property to "htmlDependencies")
      this.$set(
        this.controller.vm.value.body.contents.page,
        "htmlDependencies",
        this.controller.vm.value.body.contents.page.dependencies
      )
      this.$delete(this.controller.vm.value.body.contents.page, "dependencies")
    }

    this.DependencyBus.$on("updated", async dependenciesUpdated => {
      const res = await fetch(this.controller.page.html.url)
      let htmlContent = await res.text()

			dependenciesUpdated.forEach(({ oldOrigin, newOrigin }) => {
        htmlContent = htmlContent.replaceAll(oldOrigin, newOrigin)
      })

			this.editor.commands.clearContent();
			this.editor.commands.setContent(htmlContent);

      await this.controller.saveTextAsFile(this.controller.page, htmlContent)
    })
  },
  methods: {
    renderFormulas() {
      const previewDiv = this.$refs.canvas
      renderKatexByClass(previewDiv)
    },
		getHeader() {
			return this.$refs['interactive-text'].$el.querySelector(`[id="${this.headerToGo}"]`);
		},
		doScrollBy(value) {
			this.$refs['viewer'].scrollTo(0, value);
		}
  }
}
</script>

<style>
.it-editor span[data-inline-katex] {
  background: rgb(235, 235, 235);
  padding: 2px;
  display: inline-block;
}

.it-editor .katex-wrapper textarea {
  font-size: 1.2rem;
}

.it-editor .katex-component__title {
  font-size: 25px;
}

.it-editor .katex-component__title .v-icon {
  font-size: 30px;
}

.it-editor .katex-component__title button {
  width: 36px !important;
  height: 36px;
}
</style>
