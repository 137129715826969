<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title> Objectives </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <p-content-tile v-for="(objective, index) of controller.ibObjectives" :key="index" :value="objective">
      <template v-slot:append>
        <v-list-tile-action class="dependencies-list-action">
          <v-btn icon ripple @click="openDependency(objective)">
            <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
          </v-btn>
        </v-list-tile-action>
      </template>
    </p-content-tile>
  </div>
</template>

<script>
import { PContentTile } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "InteractiveBookObjectives",
  inject: ["controller"],
  components: { PContentTile },
  methods: {
    async openDependency(dependency) {
      PServiceContent.openContent(dependency, "tab")
    }
  }
}
</script>
