import Vue from "vue"
import { uploadFile, getContentId, getValidFileExtension } from "../../../utils";

class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    data() {
      return {};
    },
    created() {
      vm.$emit('select', getContentId(itemRevision))
    },
    computed: {
      file() {
        if (vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0].url;
        } else {
          return false;
        }
      },
      downloadLink() {
        if (vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0].download_url;
        } else {
          return false;
        }
      },
      selectedTag() {
        return vm.selectedTag;
      },
      notFound() {
        var http = new XMLHttpRequest();
        http.open('HEAD', this.file, false);
        http.send();
        if (http.status == 404) {
          return true
        } else {
          return false
        }
      }
    },
    methods: {
      removeDocument() {
        itemRevision.body.attachments.splice(0)
      },
      uploadDocument(file) {
        if (!itemRevision.body.attachments) {
          this.$set(itemRevision.body, "attachments", []);
        }
        if (file) {
          let url = URL.createObjectURL(file);
          itemRevision.body.attachments.push(new Attachment('Scorm', file, url, ".zip"))
        } else {
          uploadFile(getValidFileExtension("scorm"))
            .then(({ url, file }) => {
              itemRevision.body.attachments.push(new Attachment('Scorm', file, url, ".zip"))
            })
        }
      },
      downloadDocument() {
        const link = document.createElement('a')
        link.href = this.downloadLink
        link.setAttribute('download', 'scorm.zip') //or any other extension
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link);
      }
    }
  })
}