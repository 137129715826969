<template>
  <div class="lp-slide-properties">
    <div style="position: absolute; inset: 0px 0px 0px; overflow-y: auto; overflow-x: hidden">
      <v-toolbar flat class="elevation-0 transparent">
        <v-toolbar-title>Quiz Properties</v-toolbar-title>
        <v-spacer></v-spacer>
        <activities-dialog
          :activities="lessonPlanController.selectedSlide.slideProperties.activities"
          :iconify="true" />
      </v-toolbar>

      <!-- Quiz Activities -->
      <v-list subheader three-line v-if="slideProps && lessonPlanController.mode == 'edit'">
        <v-subheader>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                Quiz Activities ({{ slideProps.activities.length }} activities,
                {{ slideProps.activities.filter(act => act.selected).length }} active)
              </span>
            </template>
            <span>Activities to be shown inside current quiz</span>
          </v-tooltip>
        </v-subheader>

        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-sub-title>
              <div class="text-center">
                <activities-dialog :activities="lessonPlanController.selectedSlide.slideProperties.activities" />
              </div>
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <!-- Quiz Settings -->
      <v-list subheader three-line v-if="slideProps && lessonPlanController.mode == 'edit'">
        <p-panel-properties>
          <p-panel-properties-group title="Quiz Settings" :active="true">
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> Success Score </span>
                    </template>
                    <span>Minimun score to mark as proficient each objective of current Quiz</span>
                  </v-tooltip>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-slider
                  :label="slideProps.settings.success_score.toString()"
                  v-model="slideProps.settings.success_score"></v-slider>
              </v-list-tile-action>
            </v-list-tile>

            <p-property-tile title="Lock slides">
              <v-switch v-model="slideProps.settings.locked"></v-switch>
            </p-property-tile>

            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-on="on"> Number of activities </span>
                    </template>
                    <span>Set number of to activities to be shown in Quiz (Minimum: 10 or 1 per objective)</span>
                  </v-tooltip>
                </v-list-tile-title>
              </v-list-tile-content>
              <v-list-tile-action>
                <v-slider
                  :label="slideProps.settings.activities_number.toString()"
                  v-model="slideProps.settings.activities_number"
                  :min="minActivities"></v-slider>
              </v-list-tile-action>
            </v-list-tile>

            <p-property-tile title="Activities Sort ">
              <v-select
                box
                hide-details
                single-line
                v-model="slideProps.settings.sort_activities"
                :items="sortActivitiesOptions"
                label="Position"></v-select>
            </p-property-tile>
          </p-panel-properties-group>
        </p-panel-properties>
      </v-list>
    </div>
  </div>
</template>

<script>
import { PtModal } from "plant-common"
import ActivitiesDialog from "./ActivitiesDialog.vue"
import { getUniqueArray } from "../../../utils/utils"
import PPanelPropertiesGroup from "../../../blocks/_components/utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../../../blocks/_components/utils/PPanelProperties.vue"
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"

export default {
  name: "QuizProperties",
  inject: ["lessonPlanController"],
  mixins: [PtModal],
  components: {
    ActivitiesDialog,
    PPanelPropertiesGroup,
    PPanelProperties,
    PPropertyTile
  },
  data() {
    return {
      sortActivitiesOptions: [
        {
          text: "Get activities randomly",
          value: "random"
        },
        {
          text: "Get activities in import order",
          value: "activity_list"
        }
      ]
    }
  },
  computed: {
    slideProps() {
      return this.lessonPlanController.selectedSlide.slideProperties
    },
    minActivities() {
      let objectives = []
      this.lessonPlanController.slideArray.forEach(slide => {
        if (slide.objectives && slide.objectives.length > 0) {
          slide.objectives.forEach(obj => {
            objectives.push(obj)
          })
        }
      })
      objectives = getUniqueArray(objectives, "_id")
      return objectives.length > 10 ? objectives.length : 10
    }
  }
}
</script>

<style>
.lp-slide-properties .v-text-field,
.lp-slide-properties-view-mode {
  margin-top: -30px !important;
}

.lp-slide-properties-view-mode {
  margin-left: 15px;
  margin-bottom: 30px;
}

.lp-slide-properties .v-text-field--single-line input {
  margin-top: 0 !important;
}

.lp-slide-properties .theme--light.v-list .v-list__tile--link:hover {
  background: transparent !important;
}

.lp-slide-properties .v-text-field--outline > .v-input__control > .v-input__slot {
  min-height: 40px;
}

.lp-slide-properties-bookmark {
  margin-top: -30px;
}

/* Add remove layoutt */
.lp-slide-properties-add-remove.v-btn {
  min-width: 20px;
  padding: 0;
}

.lp-slide-properties-add-remove .v-icon {
  font-size: 18px;
}
</style>
