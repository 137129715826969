<template>
  <p-toolbar>
    <p-toolbar-image @click="addSection()" :imgSrc="addSectionSrc" tooltip="Add section" height="18"></p-toolbar-image>
    <p-toolbar-image @click="addCard()" :imgSrc="addCardSrc" tooltip="Add card" height="18"></p-toolbar-image>
    <p-toolbar-image @click="removeAll()" :imgSrc="removeAllSrc" tooltip="Remove all" height="18"></p-toolbar-image>
    <v-divider vertical v-show="selectedCard"></v-divider>
    <p-toolbar-image
      @click="selectContent()"
      :imgSrc="addContentSrc"
      tooltip="Explore Content"
      height="18"></p-toolbar-image>
    <p-toolbar-image
      @click="removeItemFromCard()"
      :imgSrc="removeContentSrc"
      tooltip="Delete Content"
      height="18"></p-toolbar-image>
    <p-toolbar-image @click="removeCard()" :imgSrc="removeCardSrc" tooltip="Remove Card" height="18"></p-toolbar-image>
    <p-toolbar-image @click="saveViewCard()" :imgSrc="pinViewSrc" tooltip="Pin View Card" height="18"></p-toolbar-image>
  </p-toolbar>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { PToolbarAction, PToolbar, PToolbarImage } from "plant-common"
const addCardURL = require("../resources/add_card.svg")
const addContentURL = require("../resources/add_content.svg")
const addSectionURL = require("../resources/add_section.svg")
const downloadURL = require("../resources/download_guide.svg")
const removeAllURL = require("../resources/remove_all.svg")
const removeCardURL = require("../resources/remove_card.svg")
const removeContentURL = require("../resources/remove_content.svg")
const pinViewURL = require("../resources/guide_pin.svg")

export default {
  components: { PToolbar, PToolbarAction, PToolbarImage },
  inject: ["guideController"],
  data() {
    return {}
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"]),
    addCardSrc() {
      return addCardURL
    },
    addContentSrc() {
      return addContentURL
    },
    addSectionSrc() {
      return addSectionURL
    },
    downloadSrc() {
      return downloadURL
    },
    removeAllSrc() {
      return removeAllURL
    },
    removeCardSrc() {
      return removeCardURL
    },
    removeContentSrc() {
      return removeContentURL
    },
    pinViewSrc() {
      return pinViewURL
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", [
      "addSection",
      "addCard",
      "removeCard",
      "removeAll",
      "removeItemFromCard",
      "selectContent",
      "saveViewCard"
    ])
  }
}
</script>

<style>
</style>
