<template>
  <div>
    <!-- Right panel-->
    <v-navigation-drawer stateless absolute permanent mini-variant right :dark="dark">
      <v-list class="pt-0 lp-right-panel">
        <template v-for="(panel, index) in currentRightPanels">
          <v-divider :key="index" v-if="panel.divider" id="divider-panel"></v-divider>
          <v-tooltip :key="index" left v-else>
            <template v-slot:activator="{ on }">
              <v-list-tile v-on="on" @click="toggleRightDrawer(index)" :value="panel.component == panelComponent">
                <v-list-tile-action>
                  <v-badge v-if="panel.badge" small :color="panel.badge.color" overlap>
                    <template v-slot:badge>
                      <v-icon small>{{ panel.badge.icon }}</v-icon>
                    </template>
                    <v-icon>{{ panel.icon }}</v-icon>
                  </v-badge>
                  <v-icon v-else-if="(typeof panel.icon==='string')">{{ panel.icon }}</v-icon>
                  <component v-else :is="panel.icon" ></component>
                </v-list-tile-action>
              </v-list-tile>
            </template>
            <span>{{ panel.title }}</span>
          </v-tooltip>
        </template>
      </v-list>
    </v-navigation-drawer>

    <!-- Component panel-->
    <v-navigation-drawer
      v-if="rightPanelSelected > -1"
      absolute
      right
      width="400px"
      class="lp-right-panel-component"
      :dark="dark"
      style="overflow: hidden; height: 100%">
      <component
        :is="currentRightPanels[rightPanelSelected] ? currentRightPanels[rightPanelSelected].component : null"
        :selectedBlock="selectedBlock" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import BlockArrange from "../../blocks/_components/panel/BlockArrange.vue"
import BlockActions from "../../blocks/_components/panel/BlockActions.vue"
import BlockAnimation from "../../blocks/_components/panel/BlockAnimation.vue"
import BlockProperties from "../../blocks/_components/panel/BlockProperties.vue"
import PopupProperties from "../../blocks/_components/panel/PopupProperties.vue"
import BlockSlideExplorer from "../../blocks/_components/panel/BlockSlideExplorer.vue"
import SlideMacrosPanel from "../../blocks/_components/panel/SlideMacrosPanel.vue"
import _ from "lodash"

export default {
  props: ["rightPanelSelected", "rightPanels", "dark", "readonly"],
  inject: ["blockController"],
  data() {
    return {
      selectedBlock: null,
      tempBlock: null
    }
  },
  mounted() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block }) => {
        this.setBlock(block)
      })
    }
  },
  computed: {
    panelComponent() {
      if (this.rightPanelSelected > -1 && this.currentRightPanels[this.rightPanelSelected]) {
        return this.currentRightPanels[this.rightPanelSelected].component
      } else return null
    },
    currentRightPanels() {
      const panels = this.rightPanels ? [...this.rightPanels] : []
      if (!this.readonly) {
        panels.push({
          title: "Slide Explorer",
          icon: "mdi-family-tree",
          component: BlockSlideExplorer
        })
        panels.push({ divider: true })
        if (this.blockController.overlay) {
          panels.push({
            title: "Popup Properties",
            icon: "mdi-application-cog-outline",
            component: PopupProperties
          })
        }
        if (this.selectedBlock) {
          panels.push(
            {
              title: "Block Properties",
              icon: "mdi-tune",
              component: BlockProperties
            },
            {
              title: "Arrange",
              icon: "mdi-arrange-bring-forward",
              component: BlockArrange
            },
            {
              title: "Animation",
              icon: "mdi-animation",
              component: BlockAnimation
            },
            {
              title: "Actions",
              icon: "mdi-gesture-tap-button",
              component: BlockActions
            }
          )
        }
        if (localStorage.PlantUserMacrosActive == "true") {
          panels.push({
            title: "Macros",
            icon: "mdi-robot",
            component: SlideMacrosPanel
          })
        }
      }

      return panels
    }
  },
  watch: {
    panelComponent(value, oldValue) {
      setTimeout(() => {
        if (this.panelComponent === null && this.selectedBlock === null) this.toggleRightDrawer(-1)
      }, 200)
    }
  },
  methods: {
    toggleRightDrawer(index) {
      this.$emit("update:rightPanelSelected", this.rightPanelSelected === index ? -1 : index)
    },
    setBlock(block) {
      this.tempBlock = block
      if (!block) {
        setTimeout(() => {
          this.selectedBlock = this.tempBlock
        }, 200)
        return
      }
      this.selectedBlock = block
    }
  }
}
</script>

<style>
.lp-right-panel .v-list__tile--active {
  color: #1976d2;
}
.lp-right-panel-component {
  margin-right: 80px;
}

/* Styles for subcomponents */
.lp-right-panel-header {
  margin-bottom: -20px;
}
.lp-right-panel-tile-title {
  font-size: 14px;
  font-weight: 500;
}
.lp-right-panel-tile-explanation {
  font-size: 14px;
  font-weight: 400;
}
</style>

<style scoped>
#divider-panel {
  border-color: rgba(0, 0, 0, 0.12);
  display: block !important;
  margin: 8px 0;
}
</style>
