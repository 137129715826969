<template>
  <v-toolbar
    flat
    color="white"
    :extension-height="48"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
    class="mb-2">
    <v-toolbar-title>Workflows</v-toolbar-title>

    <v-spacer></v-spacer>
    <new-content-button />
    <template v-slot:extension>
      <span class="items-selected">
        {{ filteredItemRevisionDrafts.length }}
      </span>
      <v-select
        class="mr-6"
        v-model="filterContent"
        :items="filters"
        append-icon="mdi-chevron-down"
        @change="filterByContent"
        solo
        flat
        e2e-id="content-filter"
        style="width: 250px"></v-select>
      <v-divider class="mx-3" vertical></v-divider>
      <v-autocomplete
        clearable
        class="mr-4"
        v-model="filterState"
        :items="filteredStateWorkflows"
        hide-no-data
        hide-selected
        placeholder="Workflow"
        prepend-icon="mdi-sitemap"
        append-icon="mdi-chevron-down"
        solo
        flat
        @change="filterByState"></v-autocomplete>
      <v-autocomplete
        clearable
        class="mr-4"
        v-model="filterUser"
        :items="users"
        hide-no-data
        hide-selected
        item-text="name"
        item-value="_id"
        placeholder="Assigned to"
        prepend-icon="mdi-clipboard-account"
        append-icon="mdi-chevron-down"
        return-object
        solo
        flat
        @change="filterByUser"></v-autocomplete>
      <v-autocomplete
        clearable
        class="mr-4"
        v-model="filterType"
        :items="Object.values(Extensions)"
        hide-no-data
        hide-selected
        item-text="title"
        item-value="name"
        placeholder="Type"
        prepend-icon=" mdi-file"
        append-icon="mdi-chevron-down"
        return-object
        solo
        flat
        @change="filterByType"></v-autocomplete>
    </template>
  </v-toolbar>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import PtApiClient from "plant-api-client"
import { Extensions } from "plant-common"
import NewContentButton from "./new-content/NewContentButton.vue"
import {
  STORE_WORKFLOWS_NAME,
  GETTER_FILTERED_STATE_WORKFLOWS,
  GETTER_FILTERED_ITEM_REVISION_DRAFTS,
  ACTION_SELECT_FILTER,
  STATE_SELECTED_LIST
} from "@/store_constants/workflows"

export default {
  components: { NewContentButton },
  computed: {
    ...mapState(STORE_WORKFLOWS_NAME, { selectedList: STATE_SELECTED_LIST }),
    ...mapGetters(STORE_WORKFLOWS_NAME, {
      filteredStateWorkflows: GETTER_FILTERED_STATE_WORKFLOWS,
      filteredItemRevisionDrafts: GETTER_FILTERED_ITEM_REVISION_DRAFTS
    })
  },
  data() {
    return {
      ...mapActions(STORE_WORKFLOWS_NAME, [ACTION_SELECT_FILTER]),
      users: [],
      filters: [],
      Extensions,
      filterState: null,
      filterType: null,
      filterUser: null,
      filterContent: null
    }
  },
  methods: {
    filterByState() {
      this.filterContent = "ALL"
      if (this.filterState) {
        let filter = {
          filter_name: "FILTERBYSTATE",
          filter_text: this.filterState
        }
        filter.others = []
        if (this.filterType)
          filter.others.push({
            filter_name: "FILTERBYTYPE",
            filter_text: this.filterType.name
          })
        if (this.filterUser)
          filter.others.push({
            filter_name: "FILTERBYUSER",
            filter_text: this.filterUser._id
          })
        this[ACTION_SELECT_FILTER](filter)
      } else {
        if (this.filterType) {
          this.filterByType()
        } else if (this.filterUser) {
          this.filterByUser()
        } else {
          this[ACTION_SELECT_FILTER](this.filterContent)
        }
      }
    },
    filterByType() {
      this.filterContent = "ALL"
      if (this.filterType) {
        let filter = {
          filter_name: "FILTERBYTYPE",
          filter_text: this.filterType.name
        }
        filter.others = []
        if (this.filterState)
          filter.others.push({
            filter_name: "FILTERBYSTATE",
            filter_text: this.filterState
          })
        if (this.filterUser)
          filter.others.push({
            filter_name: "FILTERBYUSER",
            filter_text: this.filterUser._id
          })
        this[ACTION_SELECT_FILTER](filter)
      } else {
        if (this.filterState) {
          this.filterByState()
        } else if (this.filterUser) {
          this.filterByUser()
        } else {
          this[ACTION_SELECT_FILTER](this.filterContent)
        }
      }
    },
    filterByUser() {
      this.filterContent = "ALL"
      if (this.filterUser) {
        let filter = {
          filter_name: "FILTERBYUSER",
          filter_text: this.filterUser._id
        }
        filter.others = []
        if (this.filterState)
          filter.others.push({
            filter_name: "FILTERBYSTATE",
            filter_text: this.filterState
          })
        if (this.filterType)
          filter.others.push({
            filter_name: "FILTERBYTYPE",
            filter_text: this.filterType.name
          })
        this[ACTION_SELECT_FILTER](filter)
      } else {
        if (this.filterState) {
          this.filterByState()
        } else if (this.filterType) {
          this.filterByType()
        } else {
          this[ACTION_SELECT_FILTER](this.filterContent)
        }
      }
    },

    filterByContent() {
      if (this.filterContent) {
        let filter = this.filterContent
        this[ACTION_SELECT_FILTER](filter)
      }
      this.filterState = null
      this.filterType = null
      this.filterUser = null
    }
  },
  mounted() {
    this.filterContent = this.selectedList
    this.filters = [
      { text: "All items", value: "ALL", count: true },
      { text: "Assigned to me", value: "ASSIGNEDTOME", count: true },
      {
        text: "My active contributions",
        value: "MYCONTRIBUTION",
        count: true
      },
      { text: "Created by me", value: "CREATEDBYME", count: true }
    ]
  },
  created() {
    PtApiClient.getAllUsers()
      .then(users => {
        return (this.users = users)
      })
      .catch(e => {
        this.isError = true
        console.error(e)
      })
  }
}
</script>

<style scoped>
.items-selected {
  color: #ffffff;
  background: #bdbcbc;
  border-radius: 50px;
  margin: 15px 10px;
  padding: 4px 7px;
  font-size: 19px;
}
</style>