import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeBrace"
    this.size.width = 200
    this.size.height = 600
    this.data = {
      controls: {
        controlPointBody: 0.25,
        controlPointHead: 0.5
      },
      style: {
        fill: "transparent",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
