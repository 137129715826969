<template>
  <v-footer class="interactive-footer-arrows">
    <div class="interactive-footer-arrows-prev-next">
      <v-btn :disabled="!canGoPrev" flat icon value="recent" @click="goPrevSlide">
        <v-icon small>mdi-arrow-left</v-icon>
      </v-btn>
      <v-container v-if="slide && total !== 0" class="interactive-slide-number">
        <v-layout row wrap>
          <v-flex xs6 sm6 md6>
            <v-text-field
              class="interactive-slide-selected"
              type="number"
              :value="slide"
              min="1"
              :max="total"
              :rules="[numberRule]"
              @change="slideChange"
              hide-details></v-text-field>
          </v-flex>
          <v-flex xs6 sm6 md6>
            <span> / {{ total }}</span>
          </v-flex>
        </v-layout>
      </v-container>
      <div></div>
      <v-btn :disabled="!canGoNext" flat icon @click="goNextSlide">
        <v-icon small>mdi-arrow-right</v-icon>
      </v-btn>
    </div>

    <div class="interactive-footer-arrows__toggle-fullscreen-btn">
      <v-tooltip top >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('toggleSubtitles')" flat icon small>
            <v-icon :class="subtitlesActive ? 'interactive-footer-subtitles-on' : 'interactive-footer-subtitles-off'">mdi-subtitles-outline</v-icon>
          </v-btn>
        </template>
        <span>{{subtitlesActive ? 'Turn off subtitles' : 'Turn on subtitles'}}</span>
      </v-tooltip>
      <v-menu top offset-y style="display: inline-block">
        <template #activator="{ on: menu }">
          <v-tooltip top>
            <template #activator="{ on: tooltip }">
              <v-btn v-on="{ ...tooltip, ...menu }" flat icon small>
                <v-icon>mdi-cog-outline</v-icon>
              </v-btn>
            </template>
            <span>Configuration</span>
          </v-tooltip>
        </template>
        <v-list>
          <v-list-tile
            v-for="(sub, index) in subtitlesLang"
            :key="index"
            @click="$emit('selectDefaultLang', index)">
            <v-list-tile-title :class="defaultSubtitles === index ? 'interactive-footer-subtitle-selected': ''">
              <v-icon v-if="defaultSubtitles === index ">mdi-check</v-icon>
              {{ sub }}
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" @click="$emit('toggleFullscreen')" flat icon small>
            <v-icon v-if="!isFullscreen" class="interactive-footer-fullscreen">mdi-fullscreen</v-icon>
            <v-icon v-else class="interactive-footer-fullscreen">mdi-fullscreen-exit</v-icon>
          </v-btn>
        </template>
        <span v-if="!isFullscreen">Enter Fullscreen</span>
        <span v-else>Exit Fullscreen</span>
      </v-tooltip>
    </div>
  </v-footer>
</template>

<script>
import { availableLanguages } from "../../lessonPlan/blocks/video/availableLanguages.js"
export default {
  props: {
    slide: Number,
    total: Number,
    canGoNext: Boolean,
    canGoPrev: Boolean,
    isFullscreen: Boolean,
    subtitlesActive: Boolean,
    defaultSubtitles: Boolean | String
  },
  data() {
    return {
      numberRule: v => {
        if (!isNaN(parseFloat(v)) && v >= 1 && v <= this.total) return true
        return ""
      },
      subtitlesLang: availableLanguages
    }
  },
  methods: {
    goPrevSlide() {
      this.$emit("prev")
      this.$emit("slideChanged")
    },
    goNextSlide() {
      this.$emit("next")
      this.$emit("slideChanged")
    },
    slideChange(n) {
      this.$emit("update", n)
    }
  }
}
</script>

<style>
.interactive-footer-arrows {
  width: 100% !important;
  position: absolute;
  bottom: 0;
  z-index: 1;
  background-color: #fff !important;
}

.interactive-footer-arrows-prev-next {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 160px;
}

.interactive-footer-arrows__toggle-fullscreen-btn {
  width: 160px;
}

.interactive-footer-subtitles-on {
  border-bottom: 3px solid #FF6D00;
}

.interactive-footer-subtitles-off {
  border-bottom: 3px solid transparent;
}

.interactive-footer-subtitle-selected {
  font-weight: bold;
}

.interactive-footer-fullscreen {
  border-bottom: 3px solid transparent;
}

.interactive-slide-number {
  max-width: 100px !important;
  margin: 0 !important;
  padding: 12px 12px 0px 12px !important;
}

.interactive-slide-selected {
  margin-top: -18px !important;
}

/* Fullscreen */
.lp-index.lp-slide-fullscreen .lp-slide-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  transition: all 0.2s;
}

.lp-index.lp-slide-fullscreen .lp-index-panel {
  display: none;
}

.lp-index.lp-slide-fullscreen .interactive-footer-arrows {
  position: fixed;
  bottom: -28px;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all 0.2s;
}

.lp-index.lp-slide-fullscreen .interactive-footer-arrows:hover {
  bottom: 0;
}
</style>
