<template>
  <p-toolbar class="p-activity-toolbar transparent elevation-0">
    <div class="p-activity-toolbar-progress">
      <span class="mr-2">{{ progressValue }}%</span>
      <v-progress-linear v-model="lessonPlanController.progress"></v-progress-linear>
    </div>
  </p-toolbar>
</template>

<script>
import { PToolbar } from "plant-common"

export default {
  inject: ["lessonPlanController"],
  components: { PToolbar },
  computed: {
    progressValue() {
      return parseInt(this.lessonPlanController.progress)
    }
  }
}
</script>

<style>
.p-activity-toolbar {
  height: auto !important;
}
.p-activity-toolbar > .v-toolbar__content {
  padding-left: 0px;
  background-color: #ffffff;
  height: auto !important;
}
.p-activity-toolbar-progress {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.p-activity-toolbar-progress .v-progress-linear {
  width: 75px;
}
.p-activity-toolbar-progress .primary {
  background-color: #1976d2 !important;
  border-color: #1976d2 !important;
}
</style>
