<template>
  <line-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <svg
        class="shape-line"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style="overflow: visible; z-index: 999; vertical-align: top"
        :width="w"
        :height="h"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <component
            v-if="MarkerBegin"
            :id="`markerBegin-${id}`"
            :is="MarkerBegin.component"
            :stroke="stroke"
            :markerSize="value.data.begin.marker.size"
            orient="auto-start-reverse" />
          <component
            v-if="MarkerEnd"
            :id="`markerEnd-${id}`"
            :is="MarkerEnd.component"
            :stroke="stroke"
            :markerSize="value.data.end.marker.size"
            orient="auto" />
        </defs>

        <path
          :d="`M 
            ${end.x > begin.x ? deltaBeginX : w - deltaBeginX} 
            ${end.y > begin.y ? deltaBeginY : h - deltaBeginY} 
            ${end.x > begin.x ? w - deltaEndX : deltaEndX} 
            ${end.y > begin.y ? h - deltaEndY : deltaEndY}
          `"
          :style="value.data.style" />
        <path
          :d="`M 
            ${end.x > begin.x ? 0 : w} 
            ${end.y > begin.y ? 0 : h} 
            ${end.x > begin.x ? w : 0} 
            ${end.y > begin.y ? h : 0}
          `"
          :style="{
            strokeWidth: value.data.style.strokeWidth,
            markerStart: `url(#markerBegin-${id})`,
            markerEnd: `url(#markerEnd-${id})`
          }" />
      </svg>
    </template>
  </line-block>
</template>

<script>
import LineBlock from "../_components/containers/LineBlock.vue"
import Markers from "./markers/Markers.js"

export default {
  name: "BlockShapeLine",
  inject: ["blockController"],
  components: {
    LineBlock
  },
  props: ["value", "mode"],
  data() {
    return {
      state: "not-selected"
    }
  },
  computed: {
    id() {
      return this.value.id
    },
    length() {
      return Math.sqrt(this.w * this.w + this.h * this.h)
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    deltaBeginX() {
      if (!this.MarkerBegin) return 0
      var { delta } = this.MarkerBegin
      return (delta * this.strokeWidth * this.beginSize * this.w) / this.length
    },
    deltaBeginY() {
      if (!this.MarkerBegin) return 0
      var { delta } = this.MarkerBegin
      return (delta * this.strokeWidth * this.beginSize * this.h) / this.length
    },
    deltaEndX() {
      if (!this.MarkerEnd) return 0
      var { delta } = this.MarkerEnd
      return (delta * this.strokeWidth * this.endSize * this.w) / this.length
    },
    deltaEndY() {
      if (!this.MarkerEnd) return 0
      var { delta } = this.MarkerEnd
      return (delta * this.strokeWidth * this.endSize * this.h) / this.length
    },

    begin() {
      return this.value.data.begin
    },
    beginSize() {
      return this.value.data.begin.marker.size
    },
    end() {
      return this.value.data.end
    },
    endSize() {
      return this.value.data.end.marker.size
    },
    stroke() {
      return this.value.data.style.stroke
    },
    strokeWidth() {
      return this.value.data.style.strokeWidth
    },
    MarkerBegin() {
      var selectedMarker = Markers.find(marker => marker.value == this.value.data.begin.marker.type)
      if (selectedMarker) {
        return selectedMarker
      } else {
        return false
      }
    },
    MarkerEnd() {
      var selectedMarker = Markers.find(marker => marker.value == this.value.data.end.marker.type)
      if (selectedMarker) {
        return selectedMarker
      } else {
        return false
      }
    }
  }
}
</script>
