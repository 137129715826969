<template>
	<span ref="text" :contenteditable="contenteditable" @blur="v => $emit('input', v.target.innerText)" @paste="onPaste">
		<span ref="innerText" v-html="value"></span>
	</span>
</template>

<script>
export default {
	props: ['value', 'contenteditable', 'parentId'],
	mounted() {
		const parent = document.getElementById(this.parentId);

		const resizeObserver = new ResizeObserver(() => {
			if (!this.$refs.text || !this.$refs.innerText) return;

			this.$refs.text?.setAttribute('style', 'width: 100%');

			const iconButtonWidth = parent.getBoundingClientRect().width;
			const innerTextWidth = this.$refs.innerText?.getBoundingClientRect().width;

			const percentage = Math.ceil((innerTextWidth / iconButtonWidth) * 100);

			this.$refs.text?.setAttribute('style', `width: ${percentage}%`);
		});

		resizeObserver.observe(parent);
	},
	methods: {
		onPaste(e) {
			// Ensures pasted content is a plain string (no HTML)
			e.preventDefault();
			var text = e.clipboardData.getData('text/plain');
			document.execCommand('insertText', false, text);
		}
	},
	watch: {
		contenteditable: {
			immediate: true,
			handler() {
				const parent = document.getElementById(this.parentId);
				const iconButtonWidth = parent?.getBoundingClientRect().width;

				if (this.contenteditable) {
					const icon = parent?.querySelector('.lp-icon-button-icon');
					const iconWidth = icon?.getBoundingClientRect().width;
					const iconWidthPercentage = iconWidth ? Math.ceil((iconWidth / iconButtonWidth) * 100) : 0;

					this.$refs.text?.setAttribute('style', `width: ${100 - iconWidthPercentage}%`);

					this.$nextTick(() => this.$refs.text.focus());
				} else {
					const innerTextWidth = this.$refs.innerText?.getBoundingClientRect().width;
					const textButtonPercentage = Math.ceil((innerTextWidth / iconButtonWidth) * 100);

					this.$refs.text?.setAttribute('style', `width: ${textButtonPercentage}%`);
				}
			}
		}
	}
};
</script>
