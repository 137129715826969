import uuid from "../../../utils/uuid.js"
import BlockAdaptiveLearningResult from "../blocks/adaptiveLearningResult/class.js"
import BlockTemplateMultipleChoiceCustom from "../blocks/contentMultipleChoiceQuestionTemplate/class.js"
import BlockTextarea from "../blocks/textarea/class.js"

/**
 * A Lesson plan.
 */

export class PContentLessonPlan {
  constructor(contents) {
    if (!contents) {
      this.slides = [new Slide()]
      //this.learning_objective = null;
      this.version = 0

      // Global Styles
      this.settings = {
        styles: {
          wrapper: {
            backgroundColor: "#ffffffff"
          },
          slide: {
            backgroundColor: "#ffffffff",
            // color: "#000000ff",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            border: "2px solid #ccc"
          },
          colors: [
            "#ffffffff",
            "#ffffffff",
            "#ffffffff",
            "#ffffffff",
            "#ffffffff",
            "#ffffffff",
            "#ffffffff",
            "#ffffffff"
          ],
          block: {
            fontFamily: "Roboto"
          },
          navigation: {
            locked: false,
            home: {
              slide_id: null
            }
          }
        }
      }

      // Saved Reusable Galleries of blocks for this lessonPlan
      this.galleries = []
      // Saved reusable slide Layouts for this lessonPlan
      this.layouts = []
      // Array of other lessonPlan that are imported.
      // their galleries and layouts are shown alongside the lessonPlan's galleries and layouts
      this.templates = []
    } else {
      if (!contents.breadcrumb) {
        Object.assign(contents, {
          breadcrumb: {
            home: {
              slide_id: null
            }
          }
        })
      }

      Object.assign(this, contents)
    }
  }
}

/**
 * A SlidesTree to work with the slides in the tree.
 * PContentLessonPlan.slides is a copy of this object but cleaning the tree data
 * to be able to save in the system without errors.
 */

export class SlidesTree {
  constructor(contents) {
    if (!contents) {
      this.slides = []
    } else {
      Object.assign(this, contents)
    }
  }
}

/**
 * An Slide
 */
export class Slide {
  constructor() {
    this.id = uuid()
    this.title = "New Slide"
    this.type = "Slide" //Slide, Section, QuizSlide, LearningObject
    this.notes = {
      speaker: "",
      audience: "",
      video: null
    }
    // Array of PRefs
    this.objectives = []

    // Section properties
    this.bookmark = null
    this.children = false // or [] if section

    // Properties from layout
    this.layout = {
      id: null
    }
    this.blocks = []
  }
}

/**
 * Gallery item in block_gallery.
 */
export class Gallery {
  constructor(name = "Block Gallery") {
    this.id = uuid()
    this.name = name
    this.imported = false // or template_id
    this.stencils = []
  }
}

/**
 * Gallery block inside SlideTemplate.
 */
export class Stencil {
  constructor(name = "New Gallery Block", block) {
    this.id = uuid()
    this.name = name
    this.icon = "mdi-toy-brick-outline"
    this.block = block
  }
}

/**
 * Slide Layout.
 */
export class Layout {
  constructor(name = "New Layout") {
    this.id = uuid()
    this.imported = false // or template_id
    this.name = name
    this.slide = {}
  }
}

/**
 * Lesson Plant used as a Template.
 */
export class Template {
  constructor($ref, id) {
    this.id = id ? id : uuid()
    this.reference = $ref
    this.contentImported = {
      layouts: false,
      galleries: false,
      colorPalette: false
    }
  }
}

export class MultipleChoiceSlideTemplate extends Slide {
  constructor() {
    super()
    this.type = "MultipleChoiceSlideTemplate"
  }
}

export class ResultsSlideTemplate extends Slide {
  constructor() {
    super()
    this.type = "ResultsSlideTemplate"
  }
}

export class QuizSlideActivity {
  constructor(ref) {
    this.content_ref = ref
    this.slide_id = uuid()
    this.points = 1
    this.selected = true
  }
}

export class QuizSlide extends Slide {
  constructor() {
    super()
    this.type = "QuizSlide"
    this.blocks.push(new BlockTextarea())
    this.blocks[0].data.html = '<p><span class="fields" data-field="SlideTitle">{{SlideTitle}}</span> </p>'
    this.blocks[0].size.width = 1920
    this.blocks[0].size.height = 250
    this.blocks.push(new BlockTextarea())
    this.blocks[1].data.html =
      "<p>📝</p>You are going to take a Quiz related to the content of this Interactive Presentation. Once you finish, the correct answers will autocomplete the sections you already know.</p>"
    this.blocks[1].data.fontSize = 46
    this.blocks[1].size.width = 1920
    this.blocks[1].size.height = 833
    this.blocks[1].position.y = 250
    this.children = [new MultipleChoiceSlideTemplate(), new ResultsSlideTemplate()]
    this.children[0].title = "Activity Multiple Choice Template"
    this.children[0].blocks.push(new BlockTemplateMultipleChoiceCustom())
    this.children[0].blocks[0].data.content_ref = {
      body: {
        references: [],
        attachments: [],
        contents: {
          question: "[Question template]",
          answer: "[Answer correct template]",
          explanation: "<p>[Explanation correct answer template]</p>",
          position: "R",
          objective: "",
          distractors: ["[Distractor 1 template]", "[Distractor 2 template]", "[Distractor 3 template]"],
          hotspots: []
        }
      },
      approval_process: {},
      __dependencies: [],
      _id: "template-default-id",
      header: {
        type_version: "2.0",
        metadata: {
          title: "[Multiple Choice Activity Title Template]",
          content_revision_type: "activityMultipleChoice",
          content_id: "content-default-id",
          custom_id: "[AMC Custom ID Template]"
        },
        major_revision: 1,
        minor_revision: 0
      },
      __latest: "1.0"
    }
    this.children[1].title = "Results"
    this.children[1].blocks.push(new BlockAdaptiveLearningResult())
    this.slideProperties = {
      settings: {
        success_score: 100,
        locked: true,
        sort_activities: "random",
        activities_number: 10
      },
      activities: [] // activities ref array {content_ref: $origin, slide_id: uuid(), points: 1, selected: true}
    }
  }
}

export class LearningObjectSlide extends Slide {
  constructor(title = "New Learning Object", ref, coverBlocks) {
    super()
    this.type = "LearningObject"
    this.title = title
    this.reference = ref
    if (coverBlocks) {
      this.blocks = coverBlocks
    } else {
      this.blocks.push(new BlockTextarea())
      this.blocks[0].size = {
        width: 1920,
        height: 1080
      }
      this.blocks[0].data.html =
        '<p>Learning Object: <strong><span class="fields" data-field="SlideTitle" v-html="SlideTitle">{{SlideTitle}}</span></strong></p>'
    }
  }
}
