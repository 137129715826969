import "./pt-logic-connector.css"
import model from "../../prototypes/VALUE_BOOLEAN"
import parseBoolean from "./config/parseBoolean"

export default {
  model,
  computed: {
    value() {
      var [, id, prop] = this.$el.dataset.inputs.split(/=|:/g)
 
      if (this.$model[id]) {
        if (typeof this.$model[id][prop] == "undefined") return undefined
        return parseBoolean(this.$model[id][prop])
      } else {
        return false
      }
    }
  },
}
