<template>
  <v-card class="elevation-0" width="500px">
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4>Notification</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>

    <v-list three-line>
      <v-slide-y-transition group name="list" tag="div">
        <template v-for="(notification, index) in notifications">
          <notifications-list-tile :key="index" :value="notification" @remove="removeNotification(notification._id)" />
        </template>
      </v-slide-y-transition>
      <v-list-tile avatar v-if="notifications.length == 0">
        <v-list-tile-avatar color="green">
          <v-icon dark>mdi-check</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title> You don't have new notifications. </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>

    <v-layout row pa-2>
      <v-btn block flat :to="'/notifications'">Show All</v-btn>
      <v-btn block flat @click.stop="removeAllNotifications()">Read All</v-btn>
    </v-layout>
  </v-card>
</template>

<script>
import NotificationsListTile from "./NotificationsListTile"
import {
  STORE_EVENT_NOTIFICATIONS_NAME,
  GETTER_EVENT_UNREAD_NOTIFICATIONS,
  ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ,
  ACTION_EVENT_NOTIFICATIONS_MARK_ALL_AS_READ
} from "@/store_constants/event-notifications"
import { mapActions, mapGetters } from "vuex"
export default {
  components: { NotificationsListTile },
  computed: {
    ...mapGetters(STORE_EVENT_NOTIFICATIONS_NAME, [GETTER_EVENT_UNREAD_NOTIFICATIONS]),
    notifications() {
      if (!this[GETTER_EVENT_UNREAD_NOTIFICATIONS]) return []
      return this[GETTER_EVENT_UNREAD_NOTIFICATIONS].slice(0, 5)
    }
  },
  methods: {
    ...mapActions(STORE_EVENT_NOTIFICATIONS_NAME, [
      ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ,
      ACTION_EVENT_NOTIFICATIONS_MARK_ALL_AS_READ
    ]),
    removeNotification(notificationId) {
      this[ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ]({
        notificationId: notificationId
      })
    },
    removeAllNotifications() {
      this[ACTION_EVENT_NOTIFICATIONS_MARK_ALL_AS_READ]()
    }
  }
}
</script>
<style>
.list-move {
  transition: transform 0.5s ease-out;
}
</style>