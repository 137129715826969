
<template>
  <v-footer :style="styleProp">
    <v-spacer></v-spacer>
    <v-btn flat value="recent" @click="decrement()">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-btn flat @click="increment()">
      <v-icon>mdi-arrow-right</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
  </v-footer>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
export default {
  inject: ["guideController"],
  props: ["styleProp"],
  methods: {
    ...mapInjectedMethods("guideController", ["decrement", "increment"])
  }
};
</script>

<style>
</style>