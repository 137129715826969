var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"width100"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_vm._v("Notifications")])],1),_c('v-data-table',{staticClass:"elevation-1 scrollDataTable",attrs:{"headers":_vm.headers,"pagination":_vm.pagination,"items":_vm.notifications},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_c('v-chip',[_vm._v(_vm._s(_vm._f("subscriptionTitle")(props.item.subscription_code)))])],1),_c('td',[_c('v-container',{attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"xs2":""}},[(
                  (props.item.event.payload &&
                    props.item.event.payload.metadata &&
                    props.item.event.payload.metadata.content_revision_type) ||
                  (props.item.event.payload && props.item.event.payload.content_revision_type)
                )?_c('content-type-icon',{attrs:{"type":props.item.event.payload.content_revision_type
                    ? props.item.event.payload.content_revision_type
                    : props.item.event.payload.metadata.content_revision_type,"width":"40px"}}):_c('content-type-icon',{attrs:{"width":"40px"}})],1),_c('v-flex',{attrs:{"xs10":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getNotificationContent(props.item, props.item.subscription_code))}})])],1)],1)],1),_c('td',[_vm._v(_vm._s(_vm._f("moment")(props.item.timestamp)))]),_c('td',[(props.item.read)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(props.item.read)))]):_c('span',[_vm._v("-")])]),_c('td',{staticClass:"justify-center layout px-0",staticStyle:{"margin-top":"10px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(!props.item.read)?{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"outline":"","fab":"","small":""}},[_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();return _vm.markAsRead({ notificationId: props.item._id, read: true })}}},on),[_vm._v("mdi-email-open")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Mark as read")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(props.item.event.payload)?{key:"activator",fn:function({ on }){return [_c('v-btn',{attrs:{"outline":"","fab":"","small":""}},[_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();return _vm.openNotification(props.item, props.item.subscription_code)}}},on),[_vm._v("mdi-open-in-new")])],1)]}}:null],null,true)},[_c('span',[_vm._v("Open content")])])],1)]}}])},[_c('template',{slot:"no-data"},[_c('span',[_vm._v("No notifications found for current user")])])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }