<template>
  <basic-block v-if="blockVariableUrl" :value="value" :mode="mode">
    <template v-slot:main>
      <template>
        <image-view :value="blockVariableImage" />
      </template>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ImageView from "./ImageViewMultipleChoice.vue"

export default {
  name: "BlockImageMultipleChoiceQuestion",
  components: {
    BasicBlock,
    ImageView
  },
  props: ["value", "mode"],

  created() {
    if (!this.value.data.style) {
      this.$set(this.value.data, "style", {
        normal: {
          borderRadius: 0
        }
      })
    }
  },

  computed: {
    blockVariableUrl() {
      if (this.$attrs.blockVariables) {
        return this.$attrs.blockVariables.find(
          blockVariable => blockVariable.name === "QuestionImageUrl" && blockVariable.value !== ""
        )
      } else {
        return false
      }
    },

    blockVariableImage() {
      if (this.blockVariableUrl) {
        return {
          data: {
            style: {
              normal: {
                borderRadius: 0
              }
            },
            image: {
              url: this.blockVariableUrl.value
            }
          }
        }
      }

      return false
    }
  }
}
</script>
