<template>
  <viewer-svg :svg="svg" :fields="fields" />
</template>

<script>
import { mapInjectedData , mapInjectedMethods} from "plant-common/src/utils";
import ViewerSvg from "./ViewerSvg";

export default {
  name: "GuideViewerSection",
  inject: ["guideController"],
  components: { ViewerSvg },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "guide"]),
    svg() {
      let section = this.guide.template.body.attachments.find(e => e.name == 'section' && e.attachment_type=='.svg')
      if(section) return section
      return this.getTemplate("section").svg
    },
    fields(){
      return this.selectedCard.fields
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["getTemplate", "getFields"])
  }
};
</script>