<template>
  <v-card class="type-dialog-modal" id="type-selector">
    <v-toolbar tabs color="white">
      <v-toolbar-title class="type-dialog-headline">Select Type</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        solo
        label="Search"
        prepend-inner-icon="search"
        v-model="search"
        class="type-dialog-search"></v-text-field>

      <template v-slot:extension>
        <v-tabs centered v-model="tabSelected">
          <v-tab class="type-dialog-tab" v-for="tab in tabs" :key="tab.id" :href="`#${tab.id}`">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items>
      <v-tab-item v-for="i in 1" :key="i" :value="`${i}`">
        <v-container grid-list-md class="type-dialog-container">
          <v-layout row wrap height="500px">
            <v-flex md2 xs3 v-for="extension in filteredExtensions" :key="extension.name">
              <v-card height="100%">
                <button
                  type="button"
                  @click="selectType(extension)"
                  v-bind:class="
                    selectedType == extension.name ? 'type-dialog-button selected-content-type' : 'type-dialog-button'
                  ">
                  <v-card-text class="type-dialog-item">
                    <ContentTypeIcon
                      :type="extension.name"
                      :class="extension.deprecated ? 'deprecated-content-type' : ''" />
                    <span class="type-dialog-name">{{ extension.title }}</span>
                  </v-card-text>
                </button>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { Extensions } from "plant-common"
import { ContentTypeIcon } from "plant-common"

export default {
  components: { ContentTypeIcon },
  props: ["content_types"],
  data: () => ({
    value: true,
    search: null,
    selectedType: null,
    tabs: [
      {
        title: "All",
        id: "all"
      },
      {
        title: "Documents",
        id: "document"
      },
      {
        title: "Multimedia",
        id: "multimedia"
      },
      {
        title: "Engineering",
        id: "engineering"
      },
      {
        title: "Training",
        id: "training"
      },
      {
        title: "Others",
        id: "other"
      }
    ],
    tabSelected: null
  }),
  computed: {
    extensions() {
      if (this.content_types) {
        return this.groupFilter(this.content_types)
      } else {
        return this.groupFilter(Object.values(Extensions))
      }
    },
    filteredExtensions() {
      if (this.search) {
        return this.extensions.filter(extension => {
          return extension.title.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      return this.extensions
    }
  },
  methods: {
    selectType(extension) {
      if (!extension.deprecated) {
        this.selectedType = extension.name
        this.$emit("select", extension)
      }
    },
    groupFilter(extensions) {
      if (this.tabSelected == "all") {
        return extensions
      } else {
        return extensions.filter(extension => extension.category == this.tabSelected)
      }
    }
  }
}
</script>

<style>
.selected-content-type {
  border: solid;
  border-color: #1976d2;
  border-width: 2px;
}
.type-dialog-modal {
  min-height: 550px;
  box-shadow: none !important;
}
.type-dialog-headline {
  color: #585858;
  font-size: 20px;
}
.v-toolbar .v-input.type-dialog-search {
  margin-top: 20px;
}
.type-dialog-tab {
  text-transform: none !important;
  margin-top: 5px;
}
.type-dialog-container.container {
  padding: 15px 24px;
}
.type-dialog-item.v-card__text {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.type-dialog-name {
  margin-top: 10px;
}
.type-dialog-button {
  height: 100%;
  width: 100%;
}
.deprecated-content-type {
  filter: grayscale(100%);
}
</style>