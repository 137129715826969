import Diagram from "../lib/rhojs"
import components from "../lib/masters/src/index"
import beforeInit from "../diagram/ptUpdate"
import Vue from "vue"

export default function (vm) {
  // eslint-disable-next-line no-unused-vars
  let view = false
  return new Vue({
    data() {
      return {
        diagramController: false,
        current_condition_id: "",
        current_condition_changed: false,
        domElement: null,
        pan: null,
        zoom: 1,
        highlighted: [],
        selected: [],
        answers: [],
        classSvg: "",
        studentAnswers: [],
        datasheetPrefix: "",
        datasheetPrefixExceptionsRegexps: []
      }
    },
    methods: {
      init(domElement) {
        this.domElement = domElement
        if (this.svg_attachment) this.refresh()
      },
      refresh() {
        fetch(this.svg_attachment.url)
          .then(r => r.text())
          .then(data => {
            this.domElement.innerHTML = "nothing"
            this.domElement.innerHTML = data
            var svg = this.domElement.querySelector("svg")
            this.classSvg = svg.className.baseVal
            svg.setAttribute("width", "100%")
            svg.setAttribute("height", "100%")
            beforeInit(svg)
            this.diagramController = new Diagram(svg, {
              components,
              zoom: true,
              globalEventListener: (evt, master, $model) => {
                this.$emit(evt.type, { evt, master, $model })
              }
            })
            this.diagramController.panZoom.resize()
            this.diagramController.panZoom.setOnPan(newPan => (this.pan = newPan))
            this.diagramController.panZoom.setOnZoom(newZoom => (this.zoom = newZoom))

            // Attempt to find the diagram datasheet prefix
            try {
              this.datasheetPrefix = svg.querySelector("g > g").dataset.datasheetPrefix || ""
              this.datasheetPrefixExceptionsRegexps = svg.querySelector("g > g").dataset.datasheetPrefixExceptions ? svg.querySelector("g > g").dataset.datasheetPrefixExceptions.split(",").map(prefix => new RegExp(`^${prefix.trim()}`)) : []
            } catch (e) {
              // do nothing if error is thrown
            }

            // Default view: all layers shown (fix visio write display:none as default in some diagrams)
            Object.keys(this.diagramController.layers).forEach(layerName => {
              this.diagramController.layers[layerName].show()
            })

            if (vm.value.body.contents.view) {
              view = vm.value.body.contents.view
            }

            if (view) {
              if (view.condition_id) {
                this.changeCondition(this.conditions.find(c => c._id == view.condition_id))
              } else {
                this.changeCondition(this.conditions[0])
              }

              if (view.selected) {
                this.selected = view.selected
              } else {
                this.selected = []
              }

              if (view.highlighted) {
                view.highlighted.forEach(e => {
                  let svgComponentHighlighted = document.getElementById(e)
                  this.diagramController.highlight(svgComponentHighlighted)
                })
              }

              if (view.layers) {
                Object.keys(view.layers).forEach(layerName => {
                  if (this.diagramController.layers.hasOwnProperty(layerName) && !view.layers[layerName].visible) {
                    this.diagramController.layers[layerName].hide()
                  }
                })
              }
            } else if (this.conditions.length > 0 && !this.current_condition) {
              this.changeCondition(this.conditions[0])
            }
          })
      },
      changeCondition(condition) {
        if (condition) {
          return fetch(condition.url)
            .then(r => r.json())
            .then(data => {
              this.diagramController.replaceState(data)
              this.current_condition_id = condition._id
              this.current_condition_changed = false
              this.unsetAnswers()
            })
            .catch(console.error)
        }
      },
      zoomIn() {
        this.diagramController.panZoom.zoomIn()
      },
      zoomOut() {
        this.diagramController.panZoom.zoomOut()
      },
      zoomReset() {
        this.diagramController.panZoom.reset()
      },
      setElementProp(id, prop, value) {
        this.diagramController.model[id][prop] = value
        this.current_condition_changed = true
      },
      getElementProp(id, prop) {
        return this.diagramController.model[id][prop]
      },
      highlight(currentTarget) {
        this.diagramController.highlight(currentTarget)
        vm.$emit("update:view", this.getCurrentView())
      },
      getCurrentView() {
        return {
          condition_id: this.current_condition_id,
          layers: JSON.parse(JSON.stringify(this.diagramController.layers)),
          highlighted: this.highlighted,
          selected: [...document.querySelectorAll("[data-selectedId]")].map(e => e.dataset.selectedId)
        }
      },
      /**
       * Activity Diagram Methods
       **/
      addAnswer(element, viewOffset) {
        let aux = {}
        let xOffset = -11
        if (element.getAttribute("transform").includes("scale(-1")) {
          element.setAttribute("transform", "translate(0, 0) scale(-1, 1)")
          xOffset = -31
        } else {
          element.setAttribute("transform", "translate(0, 0)")
        }
        aux.id = JSON.parse(JSON.stringify(element.id))
        aux.dataset = JSON.parse(JSON.stringify(element.dataset))
        aux.outerHTML = JSON.parse(JSON.stringify(element.outerHTML))
        if (element.className.baseVal == "pt-keypoint-point") {
          this.answers.push({
            classSvg: this.classSvg,
            shape: aux,
            offset: xOffset + 25 + " " + (viewOffset - 6) + " 80 80"
          })
        } else {
          this.answers.push({
            classSvg: this.classSvg,
            shape: aux,
            offset: xOffset + " " + (viewOffset - 6) + " 40 40"
          })
        }
        if (!this.valueActivity.answers) {
          this.$set(this.valueActivity, "answers", [])
        }
        this.valueActivity.answers = this.answers
      },
      deleteAnswers() {
        this.answers.forEach(answer => {
          this.deleteShape(answer.shape.id)
        })
      },
      deleteShape(id) {
        this.answers = this.answers.filter(el => el.shape.id != id)
        let aid = "a#" + id
        document.getElementById(aid).remove()
        this.$set(this.valueActivity, "answers", this.answers)
      },
      drawRectangleForAnswer(e, color) {
        let svgElement = document.querySelector(".svg-pan-zoom_viewport")
        // check if there is a old aid set before and delete it before adding a new one
        let elm = document.getElementById(e.shape.id)
        let aid = "a#" + e.shape.id
        if (document.getElementById(aid)) {
          document.getElementById(aid).remove()
        }
        const _OFFSET = 6
        var o = document.createElementNS("http://www.w3.org/2000/svg", "rect")
        var bbox

        bbox = elm.getBBox()
        o.setAttribute("x", bbox.x - _OFFSET / 2)
        o.setAttribute("y", bbox.y - _OFFSET / 2)
        o.setAttribute("width", bbox.width)
        o.setAttribute("height", bbox.height)
        o.setAttribute("stroke", color)
        o.setAttribute("stroke-width", "1.5")
        o.setAttribute("data-selectedId", elm.id)
        o.setAttribute("id", "a#" + elm.id)
        elm.classList.forEach(l => o.classList.add(l))
        o.setAttribute("transform", elm.getAttribute("transform"))
        svgElement.appendChild(o)
      },
      unsetAnswers() {
        if (this.valueActivity.answers) {
          this.answers = this.valueActivity.answers
          if (vm.mode === "edit") {
            // draw blue rectangle for edit mode
            this.answers.forEach(e => {
              this.drawRectangleForAnswer(e, "rgba(0,0,255,1)")
            })
          } else {
            // set status to undefined
            this.answers.forEach(e => {
              let elm = document.getElementById(e.shape.id)
              this.diagramController.model[e.shape.id]["status"] = undefined
              elm.setAttribute("data-status", "undefined")
            })
          }
        }
        this.unsetFlowForPipes()
      },
      unsetFlowForPipes() {
        function setNoFlow(pipes) {
          pipes.forEach(elm => {
            elm.setAttribute("data-status", "NO FLOW")
          })
        }
        if (vm.mode !== "edit") {
          const primaryPipes = document.getElementsByClassName("pt-piping-pipe-primary")
          setNoFlow(primaryPipes)
          const secondaryPipes = document.getElementsByClassName("pt-piping-pipe-secondary")
          setNoFlow(secondaryPipes)
          const nuscalePrimaryPipes = document.getElementsByClassName("pt-nuscale-piping-pipe-primary")
          setNoFlow(nuscalePrimaryPipes)
          const nuscaleSecondaryPipes = document.getElementsByClassName("pt-nuscale-piping-pipe-secondary")
          setNoFlow(nuscaleSecondaryPipes)
        }
      },
      checkAnswers() {
        const answersArray = []
        this.answers.forEach(answer => {
          let elm = document.getElementById(answer.shape.id)
          const status = elm.getAttribute("data-status")
          answersArray[answer.shape.id] = {
            answered: status,
            correct: answer.shape.dataset.status
          }
          this.drawRectangleForAnswer(
            answer,
            answersArray[answer.shape.id].answered === answersArray[answer.shape.id].correct
              ? "rgba(0,255,0,1)"
              : "rgba(255,0,0,1)"
          )
        })
        this.studentAnswers = answersArray
      },
      isAnswerCorrect(shape) {
        return this.studentAnswers[shape.shape.id]
          ? this.studentAnswers[shape.shape.id].answered === this.studentAnswers[shape.shape.id].correct
          : false
      }
      /**
       * END Activity Diagram Methods
       **/
    },
    computed: {
      value() {
        return vm.value.body.contents.diagram
      },
      valueActivity() {
        return vm.value.body.contents
      },
      view() {
        return vm.$props.view
      },
      attachments() {
        if (!this.value || !this.value.body) return []
        return this.value.body.attachments
      },
      shapes() {
        if (!this.value.body.contents) {
          this.$set(this.value.body, "contents", {})
        }
        if (!this.value.body.contents.shapes) {
          this.$set(this.value.body.contents, "shapes", {})
        }
        return this.value.body.contents.shapes
      },
      svg_attachment() {
        if (!this.value || !this.value.body) return false
        return this.value.body.attachments.find(e => e.name == "svg")
      },
      vsdx_attachment() {
        if (!this.value || !this.value.body) return false
        return this.value.body.attachments.find(e => e.name == "vsdx")
      },
      conditions() {
        if (!this.value || !this.value.body) return false
        return this.value.body.attachments.filter(e => e.attachment_type == ".json")
      },
      current_condition() {
        if (!this.value || !this.value.body) return false
        return this.value.body.attachments.find(e => e._id == this.current_condition_id)
      },
      layers() {
        return this.diagramController.layers
      }
    },
    watch: {
      current_condition_id: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      selected: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      highlighted: {
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      },
      layers: {
        deep: true,
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      }
    }
  })
}
