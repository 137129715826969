import "./pt-logic-delay-false.css"

export default {
  model: {
    "active": {
      default: false
    },
    "value": {
      default: false
    }
  },
  computed: {
    input_value() {
      // Default prop value if input is a number (for legacy diagrams)
      var inputs = this.$el.dataset.inputs.split(",")
      var [, id, prop] = inputs[0].split(/=|:/g)
      return  this.$model[id][prop]
    }
  },
  watch: {
    input_value() {
      var blockData = this.$model[this.$el.id]
      var time = parseInt(this.$el.dataset.time || 3)
      if (blockData.active) {
        clearTimeout(blockData.active)
        blockData.active = false
      } else {
        if (blockData.input_value) {
          blockData.value = true
        } else if (blockData.input_value != blockData.value) {
          blockData.active = setTimeout(function () {
            blockData.active = false
            blockData.value = false
          }, time * 1000)
        }
      }

    }
  }
}

