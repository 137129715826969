import {$http} from '../config';

const xAPI = {
	/**
	 * @param xAPIVerb {object}
	 * @param xAPIObject {object}
	 * @param xAPIContext {object}
	 * @param xAPIResult {result}
	 * @returns {PLANT.XAPIStatement}
	 */
	submitStatement: (xAPIVerb, xAPIObject, xAPIContext, xAPIResult) => new Promise(function (resolve, reject) {
		let xAPIActor = {
			name: "Test Armadillo",
			mbox: "test@armadilloamarillo.com"
		}
        resolve({xAPIActor, xAPIVerb, xAPIObject, xAPIContext, xAPIResult})
    }),
};

export default xAPI;
