export default {
  watch: {
    selectedSlide: {
      handler() {
        this.emitViewForComment()
      }
    }
  },
  methods: {
    getCommentsForSlide(comments, slide) {
      if (!comments) {
        return []
      }

      if (slide.type === "LearningObject" && this.mode === "edit") {
        return comments.filter(
          comment => !comment.deleted_at && comment.location && comment.location.slide_id.includes(slide.id)
        )
      }

      return comments.filter(
        comment => !comment.deleted_at && comment.location && comment.location.slide_id === slide.id
      )
    },
    emitViewForComment() {
      if (this.selectedSlide) {
        this.vm.$emit("update:view", {
          slide_id: this.selectedSlide.id
        })
      }
    },
    updateSelectedSlideFromComment() {
      if (!this.selectedSlide || this.view.slide_id != this.selectedSlide.id) {
        this.selectSlideById(this.view.slide_id)
      }
    }
  }
}
