import PtApiClient from "plant-api-client"
import { transitionGuideToLessonPlan } from "../../../../components/guideCommon/guide/Editor/Conversion/transition"
import store from "@/store"
import uuid from "@/utils/uuid"
import convertLearningObj from "../learning-objective-to-objective/convert"
export default async function convert({ guideCustomId, templateCustomId }) {
  var user_id = store.state.user.profile._id

  let guideContent = await PtApiClient.getContentByCustomId(guideCustomId)
  let templateContent = await PtApiClient.getContentByCustomId(templateCustomId)

  // Create a new DRAFT for the guide
  await PtApiClient.createDraft(guideContent.header.metadata.content_id)

  // Get learning objective of current guide => convert to objective if needed
  if (guideContent.body.contents.learning_objective) {
    try {
      await convertLearningObj({
        learningObjectiveCustomId: guideContent.body.contents.learning_objective.header.metadata.custom_id
      })
    } catch (e) {
      // nothing
    }
  }

  // Assign DRAFT author to the user
  await PtApiClient.updateDraftWorkflow(guideContent.header.metadata.content_id, "method", "reassignRole", {
    role: "author",
    user_id,
    lightweight: true
  })

  // Run conversion function
  const template = {
    id: uuid(),
    reference: templateContent
  }
  let { card_templates } = guideContent.body.contents.template.body.contents
  card_templates.push({ name: "slide" })
  let layouts = card_templates.reduce((prev, curr) => {
    prev[curr.name] = templateContent.body.contents.layouts.find(
      layout => layout.name.toLowerCase() == curr.name.toLowerCase()
    )
    return prev
  }, {})
  await transitionGuideToLessonPlan(guideContent.body.contents, template, layouts, guideContent.header.metadata, [])

  await PtApiClient.updateDraftBody(guideContent.header.metadata.custom_id, guideContent.body)
  await PtApiClient.updateDraftMetadata(guideContent.header.metadata.custom_id, guideContent.header.metadata)
}
