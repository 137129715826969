import  "./pt-piping-valve-safety-remote.css"

export default {
    "model": {
        "status": {
            type: "list",
            format: ["OPEN", "MID POSITION", "CLOSED"],
            default: "undefined",
            bind: {
                to: "attr",
                name: "data-status"
            }
        }   
    },
    listeners: {
        mouseenter: true,
        mouseleave: true,
        click: true,
        contextmenu: true
    }
}

