import { $http } from "../config";

export default {
	// getConfiguration: () => $http(`/configuration`).then(r => r.data),
	getRelations: ({ limit, page }) => $http(`/configuration/relations?limit=${limit || 5}&page=${page || 1}`).then(r => r.data),
	getOutdatedDependents: ({ limit = 5, page = 1, sortBy = "dependent.header.custom_id", sortOrder = 1 }) => {
		var paginationQuery = new URLSearchParams({ limit, page, sortBy, sortOrder }).toString()
		return $http(`/configuration/outdated/dependents?${paginationQuery}`).then(r => r.data)
	},
	getOutdatedDependencies: ({ limit = 5, page = 1, sortBy = "dependency.header.custom_id", sortOrder = 1 }) => {
		var paginationQuery = new URLSearchParams({ limit, page, sortBy, sortOrder }).toString()
		return $http(`/configuration/outdated/dependencies?${paginationQuery}`).then(r => r.data)
	},
	recalculateContentAndRelations: () => {
		return $http('/configuration/recalculate/relations')
	}
};
