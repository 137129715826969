import { selectSquare } from "./selectSquare";

var mask;
var _OFFSET = 6;

const MASK_SIZE = {
  x: -1e6,
  y: -1e6,
  width: 1e12,
  height: 1e12,
}

/**
 * Create and inject highlightble svg object
 *
 * @param {object}    elm   Selectable diagram object.
 * @param {object}    mask  Global diagram object with background mask.
 *
 */
var highlightElement = function (elm, mask) {
  var o = document.createElementNS("http://www.w3.org/2000/svg", "rect");
  var bbox;

  bbox = selectSquare(elm).getBBox();
  o.setAttribute("x", bbox.x - _OFFSET / 2);
  o.setAttribute("y", bbox.y - _OFFSET / 2);
  o.setAttribute("width", bbox.width + _OFFSET);
  o.setAttribute("height", bbox.height + _OFFSET);
  o.setAttribute("stroke", 'black')
  o.setAttribute("fill", 'black');
  o.setAttribute("rx", 5);
  o.setAttribute("ry", 5);
  o.setAttribute("transform", elm.getAttribute("transform"));
  mask.appendChild(o);
};

/**
 * Create black mask over diagram svg object
 *
 * @param {object}    parent  Global diagram object.
 *
 */
var addMask = function (parent) {
  var o = document.createElementNS("http://www.w3.org/2000/svg", "rect");
  o.setAttribute("id", "highlight-cover");
  o.setAttribute("x", MASK_SIZE.x); o.setAttribute("y", MASK_SIZE.y);
  o.setAttribute("width", MASK_SIZE.width); o.setAttribute("height", MASK_SIZE.height);
  o.setAttribute("style", 'fill:black;fill-opacity:0;transition: fill-opacity 1s linear;');
  o.setAttribute("mask", 'url(#highlight-mask)');
  o.onclick = function () {
    this.style.fillOpacity = 0
    this.addEventListener("transitionend", function (event) {
      if (document.getElementById('highlight-cover'))
        document.getElementById('highlight-cover').remove()
      if (document.getElementById('highlight-mask'))
        document.getElementById('highlight-mask').remove()
    }, false);
  }
  parent.appendChild(o);

  var mask = document.createElementNS("http://www.w3.org/2000/svg", "mask");
  mask.setAttribute("id", "highlight-mask");
  parent.appendChild(mask);

  var o = document.createElementNS("http://www.w3.org/2000/svg", "rect");
  o.setAttribute("x", MASK_SIZE.x); o.setAttribute("y", MASK_SIZE.y);
  o.setAttribute("width", MASK_SIZE.width); o.setAttribute("height", MASK_SIZE.height);
  o.setAttribute("style", 'fill:white;fill-opacity:0.8;');
  mask.appendChild(o);

  return mask;
}

var checkParents = function (elem) {
  var initElem = elem
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
    elem = elem.parentNode;
    if (initElem.getAttribute('class') === elem.getAttribute('class'))
      return false;
  }
  return true;
}

/**
 * Creates highlightable svg element in PLANT's diagram.
 *
 * @param {object}    svgElement  Global diagram object.
 * @param {object}    selector    Highligthable diagram object.
 *
 */
var highlight = function (svgElement, selector) {
  if (checkParents(selector) && Object.keys(selector.dataset).length > 0) {
    mask = addMask(svgElement);
    if (typeof selector == "string") {
      svgElement.querySelectorAll(selector).forEach(function (elm) {
        highlightElement(elm, mask);
      })
    } else {
      highlightElement(selector, mask)
    }
    document.getElementById('highlight-cover').style.fillOpacity = 0.8
  }
};


highlight.off = function () {
}

export default highlight