import { $http } from "../config"

/**
 * @typedef {object} FolderUpdated
 * @property {string} name - New folder name.
 * @property {string} [parent_id] - New Folder's parent_id.
 */

const Folders = {
  /**
   * @param folderId {string}
   * @param page {number}
   * @param limit {number}
   * @returns {PLANT.FolderList} Paginated folder response
   */
  getFolder: (folderId = undefined, page = 1, limit = 1000) =>
    $http(
      `/folders${folderId ? "/" + folderId : ""}` + (page ? "?page=" + page : "") + (limit ? "&limit=" + limit : "")
    ).then(r => r.data),
  /**
   * @param name {string} New Folder name
   * @param [parent_id] {string} _id of the parent for tne new folder
   * @returns {PLANT.Folder} Folder that has been created
   */
  createFolder: (name, parent_id) => $http.post(`/folders`, { name, parent_id }).then(r => r.data),
  /**
   * @param folderId {string} _id of the folder to delete
   * @returns {PLANT.Folder} Folder that has been deleted
   */
  deleteFolder: folderId => $http.delete(`/folders/${folderId}`).then(r => r.data),
  /**
   * @param folderId {string} _id of the folder to delete
   * @param updated_folder {FolderUpdated} New properties of the folder
   * @returns {PLANT.Folder} Updated Folder
   */
  updateFolder: (folderId, { name, parent_id }, action) =>
    $http.put(`/folders/${folderId}` + (action ? "?action=" + action : ""), { name, parent_id }).then(r => r.data),
  /**
   * @returns {PLANT.FolderList} Paginated folder response
   */
  getRootFolders: () => $http(`/folders/root`).then(r => r.data),
  /**
   * @returns {PLANT.FolderList} Paginated folder response
   */
  getAllFolders: () => $http(`/folders/all`).then(r => r.data)
}

export default Folders
