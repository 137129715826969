<template>
  <div>
    <!-- Left panel-->
    <v-navigation-drawer stateless absolute permanent mini-variant left :dark="dark">
      <v-list class="pt-0 lp-left-panel">
        <v-tooltip right v-for="(item, index) in leftPanelsTop" :key="index">
          <template v-slot:activator="{ on }">
            <v-list-tile
              v-on="on"
              @click="handleClickLeftPanel(item, index)"
              :value="item.component ? panelComponent == item.component : false"
              :id="item.id">
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </v-list>
      <v-list style="position: absolute; bottom: 0px; left: 0px; right: 0px">
        <v-tooltip right v-for="(item, index) in leftPanelsBottom" :key="index">
          <template v-slot:activator="{ on }" v-if="!item.disabled">
            <v-list-tile
              v-on="on"
              @click="handleClickLeftPanel(item, index + leftPanelsTop.length)"
              :value="item.component ? panelComponent == item.component : false">
              <v-list-tile-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </v-list>
    </v-navigation-drawer>

    <!-- Component panel-->
    <v-navigation-drawer v-if="panelComponent" absolute left width="400px" class="lp-left-panel-component" :dark="dark">
      <component
        :is="panelComponent"
        v-bind="allPanels[leftPanelSelected].props"
        @slideChanged="$emit('slideChanged')" />
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    leftPanelsTop: Array,
    leftPanelsBottom: Array,
    leftPanelSelected: Number, // .sync
    dark: Boolean
  },
  computed: {
    allPanels() {
      return [...this.leftPanelsTop, ...(this.leftPanelsBottom ? [...this.leftPanelsBottom] : [])]
    },
    panelComponent() {
      if (this.leftPanelSelected < 0) return null
      else {
        return this.allPanels[this.leftPanelSelected].component
      }
    }
  },
  methods: {
    handleClickLeftPanel(item, index) {
      if (item.component) this.toggleLeftDrawer(index)
      if (item.action) item.action()
    },
    toggleLeftDrawer(index) {
      this.$emit("update:leftPanelSelected", this.leftPanelSelected === index ? -1 : index)
    }
  }
}
</script>

<style>
.lp-left-panel .v-list__tile--active {
  color: #1976d2;
}
.lp-left-panel-component {
  margin-left: 80px;
}
</style>
