export const DatasheetConfigLevels = [
    {
        description: "Basic",
        value: 1
    },
    {
        description: "Intermediate",
        value: 2
    },
    {
        description: "Advanced",
        value: 3
    }
]