<template>
	<div
		style="
			background: #efefef;
			border: dashed;
			margin: 20px;
			width: calc(100% - 40px);
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		"
		@drop.prevent="importFile"
		@dragover.prevent
	>
		<div class="display-2" style="text-align: center">
			<v-container grid-list-xl text-xs-center>
				<v-layout row wrap>
					<v-flex xs12>
						<ContentTypeIcon height="200px" :type="type" />
						<p style="font-size: 32px">{{ type }}</p>
						<p>Drop Files here</p>
					</v-flex>
					<v-flex xs6 offset-xs3 style="font-size: 21px"> File types: {{ allowed_extensions.join(', ') }} </v-flex>
				</v-layout>
			</v-container>
		</div>
	</div>
</template>

<script>
import { ContentTypeIcon } from 'plant-common';
import { mapActions } from 'vuex';
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_ERROR } from '@/store_constants/notifications';
import * as ContentBodies from '@/classes/content/index.js';

export default {
	name: 'BulkUploadMethodFiles',
	props: ['type'],
	components: { ContentTypeIcon },
	computed: {
		allowed_extensions() {
			return this.ContentBodyConstructor.BULK_UPLOAD.extensions;
		},
		ContentBodyConstructor() {
			return Object.values(ContentBodies).find(c => c.CONTENT_TYPE == this.type);
		}
	},
	methods: {
		...mapActions(STORE_NOTIFICATIONS_NAME, {
			error: ACTION_NOTIFICATIONS_ERROR
		}),
		importFile(evt) {
			var new_contents = [];
			let droppedFiles = evt.dataTransfer.files;
			if (!droppedFiles) return;
			let fileDoesNotMatch = false;
			for (var file of droppedFiles) {
				// find first file extension. If any extension is different, it will show an error
				const matches = file.name.match(/^(.*)\.(.*)$/);
				let [, name, files_extension] = matches;
				files_extension = files_extension.toLowerCase();
				if (!this.allowed_extensions.includes(files_extension)) {
					this.showAlert(
						`Can not process file ${name} with extension .${files_extension} for ${this.type} upload. Available extensions: ${this.allowed_extensions}.`
					);
					fileDoesNotMatch = true;
					break;
				}
				const custom_id = name;
				const title = name;
				const valid = undefined;

				const fileWithSameName = new_contents.find(c => c.title === title);
				if (fileWithSameName && this.canAddMoreAttachmentsToSameContent(fileWithSameName.body)) {
					fileWithSameName.body.addAttachment(file);
				} else {
					new_contents.push({
						filename: file.name,
						custom_id,
						title,
						body: new this.ContentBodyConstructor(file),
						valid,
						custom_revision: '',
						status: 'not-started' // 'not-started', 'in-progress', 'done', 'error'
					});
				}
			}
			if (fileDoesNotMatch) {
				return;
			} else {
				this.$emit('drop-finished', new_contents);
			}
		},
		canAddMoreAttachmentsToSameContent(body) {
			return body.addAttachment && typeof body.addAttachment === 'function';
		},
		showAlert(message) {
			this.error({ message, timeout: -1 });
		}
	}
};
</script>

<style></style>
