export const N_A_TEXT = "No filter"
// Location
export const LOCATION_ALL_VALUE = "all"
export const LOCATION_CURRENT_VALUE = "current_view"
// State
export const STATE_OPEN_VALUE = "open"
export const STATE_RESOLVED_VALUE = "resolved"
export const STATE_CLOSED_VALUE = "closed"
// Replied
export const REPLIED_VALUE = "replied"
export const NOT_REPLIED_VALUE = "not_replied"
// Date
export const FROM_VALUE = "from_date"
export const TO_VALUE = "to_date"
export const BETWEEN_VALUE = "between_dates"