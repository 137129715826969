<template>
  <v-dialog :value="content" @input="$emit('input')">
    <v-toolbar flat>
      <content-type-icon
        :type="content.header.metadata.content_revision_type"
      />
      <v-toolbar-title>{{ content.header.metadata.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card height="900px">
      <v-card-text>
        <item-viewer :value="content" />
        <template slot="actions">
          <span></span>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ItemViewer } from "plant-common";
import { ContentTypeIcon } from "plant-common";
export default {
  props: ["content"],
  components: { ItemViewer, ContentTypeIcon }
};
</script>