<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Select a content</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title v-if="info_message">
        <div v-html="info_message"></div>
      </v-card-title>
      <v-data-table :headers="headers" :items="contents" :pagination.sync="pagination" select-all class="elevation-1">
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : ''
              ]"
              @click="changeSort(header.value)">
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <td @click="selectContent(props.item)" class="cursor-pointer text-center">
            <content-type-icon height="40px" :type="props.item.header.metadata.content_revision_type" />
          </td>
          <td @click="selectContent(props.item)" class="cursor-pointer text-center">
            {{ props.item.header.metadata.custom_id }}
          </td>
          <td @click="selectContent(props.item)" class="cursor-pointer text-center">
            {{ props.item.header.metadata.title }}
          </td>
          <td @click="selectContent(props.item)" class="cursor-pointer text-center">{{ getRevision(props.item) }}</td>
          <td>
            <v-icon medium @click="openInNewTab(props.item)"> mdi-open-in-new </v-icon>
          </td>
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <p class="mt-3">No contents to show</p>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { PtModal, ContentTypeIcon } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "FilterContentDialog",
  components: { ContentTypeIcon },
  props: {
    contents: {
      type: Array,
      default: []
    },
    info_message: {
      type: String,
      default: false
    }
  },
  mixins: [PtModal],
  data() {
    return {
      dialog: true,
      headers: [
        {
          text: "Content Revision Type",
          value: "header.metadata.content_revision_type"
        },
        { text: "Custom ID", value: "header.metadata.custom_id" },
        { text: "Title", value: "header.metadata.title" },
        { text: "Revision", value: "header.metadata.major_version", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      pagination: {
        sortBy: "name",
        rowsPerPage: 10 // -1 for All",
      }
    }
  },
  beforeMount() {
    this.$options.components.ContentTypeIcon = ContentTypeIcon
  },
  methods: {
    selectContent(content) {
      this.$emit("input", content)
    },
    openInNewTab(content) {
      PServiceContent.openContent(content, "tab")
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getRevision(content) {
      return `${content.header.major_revision}.${content.header.minor_revision}`
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
