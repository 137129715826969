<template>
  <div class="h-100">
    <library-selector name="Workflow" />
    <v-container
      :style="`margin-left:230px;margin-right:0px;margin-bottom:0px;padding:0px;height:100%;max-width: calc(100% - 230px) !important;`">
      <library-selector-card name="Workflow" v-if="!plant_id || !library_id" />
      <router-view />
    </v-container>
  </div>
</template>
<script>
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "@/store_constants/library"
import { mapState } from "vuex"
import LibrarySelector from "@/components/LibrarySelector.vue"
import workflows_store from "@/store/workflows"
import explorer_store from "@/store/explorer"
import { STORE_WORKFLOWS_NAME } from "@/store_constants/workflows"
import { STORE_EXPLORER_NAME } from "@/store_constants/explorer"
import LibrarySelectorCard from "@/components/LibrarySelectorCard.vue"

export default {
  components: { LibrarySelector, LibrarySelectorCard },
  beforeCreate() {
    if (!this.$store.state[STORE_WORKFLOWS_NAME]) {
      this.$store.registerModule(STORE_WORKFLOWS_NAME, workflows_store)
    }
    if (!this.$store.state[STORE_EXPLORER_NAME]) {
      this.$store.registerModule(STORE_EXPLORER_NAME, explorer_store)
    }
  },
  beforeDestroy() {
    if (this.$store.state[STORE_EXPLORER_NAME]) {
      this.$store.unregisterModule(STORE_EXPLORER_NAME)
    }
  },
  created() {
    if (this[STATE_PLANT_ID])
      this.$router.push({
        name: "Workflow",
        params: { library_id: this[STATE_PLANT_ID] }
      })
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    }),
    library_id() {
      return this.$route.params.library_id
    }
  }
}
</script>