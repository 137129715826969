<template>
  <img id="preview" :src="slidePreviewURL" class="slide-img-preview" />
</template>

<script>
import PtApiClient from "plant-api-client"
import { getContentId, getMajorVersion, getMinorVersion } from "../../../utils/dbTool"
export default {
  name: "SlidePreview",
  props: {
    slide: Object,
    content: Object
  },
  data() {
    return {
      slidePreviewURL: require("./no_screenshot.png")
    }
  },
  async created() {
    const previewURL = await PtApiClient.checkSlidePreview(
      getContentId(this.content),
      getMajorVersion(this.content),
      getMinorVersion(this.content),
      { slide_id: this.slide.id }
    )

    fetch(previewURL).then(r => {
      if (r.status === 200) {
        this.slidePreviewURL = previewURL
      }
    })
  }
}
</script>

<style scoped>
.slide-img-preview {
  width: auto;
  height: 100%;
}
</style>