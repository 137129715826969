<template>
  <v-menu
    e2e-id="comments-filter"
    bottom
    offset-y
    transition="slide-y-transition"
    :close-on-content-click="false"
    nudge-top="-15">
    <template v-slot:activator="{ on }">
      <v-icon small v-on="on">mdi-chevron-down</v-icon>
    </template>

    <v-list dense>
      <v-subheader>Filter Options ({{ commentsLength }} comment{{ commentsLength == 1 ? "" : "s" }})</v-subheader>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>Location</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action class="tile-action-filter">
          <v-select
            class="select-filter"
            :items="filterOptions.location"
            v-model="value.location"
            hide-details
            dense
            box
            single-line></v-select>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>Status</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action class="tile-action-filter">
          <v-select
            class="select-filter"
            :items="filterOptions.state"
            v-model="value.state"
            hide-details
            dense
            box
            single-line></v-select>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>Replied</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action class="tile-action-filter">
          <v-select
            class="select-filter"
            :items="filterOptions.replied"
            v-model="value.replied"
            hide-details
            dense
            box
            single-line></v-select>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>User</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action class="tile-action-filter">
          <v-select
            class="select-filter"
            :items="commentUsers"
            v-model="value.user"
            hide-details
            dense
            box
            single-line></v-select>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-sub-title>Date</v-list-tile-sub-title>
        </v-list-tile-content>

        <v-list-tile-action class="tile-action-filter">
          <v-select
            class="select-filter"
            :items="filterOptions.date.options"
            v-model="value.date.option"
            hide-details
            dense
            box
            single-line></v-select>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile
        v-if="value.date.option == FROM_VALUE || value.date.option == BETWEEN_VALUE"
        class="border-left-date">
        <v-list-tile-content>
          <v-list-tile-sub-title>From Date</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action class="tile-action-filter">
          <v-menu
            v-model="from_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="value.date.from_date"
                label="From date"
                prepend-icon="event"
                readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="value.date.from_date"
              @input="from_menu = false"
              :max="value.date.option == BETWEEN_VALUE ? value.date.to_date : undefined"></v-date-picker>
          </v-menu>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile v-if="value.date.option == TO_VALUE || value.date.option == BETWEEN_VALUE" class="border-left-date">
        <v-list-tile-content>
          <v-list-tile-sub-title>To Date</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action class="tile-action-filter">
          <v-menu
            v-model="to_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            lazy
            transition="scale-transition"
            offset-y
            full-width
            min-width="290px">
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="value.date.to_date"
                label="To date"
                prepend-icon="event"
                readonly
                v-on="on"></v-text-field>
            </template>
            <v-date-picker
              v-model="value.date.to_date"
              @input="to_menu = false"
              :min="value.date.option == BETWEEN_VALUE ? value.date.from_date : undefined"></v-date-picker>
          </v-menu>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import {
  LOCATION_ALL_VALUE,
  N_A_TEXT,
  LOCATION_CURRENT_VALUE,
  STATE_OPEN_VALUE,
  STATE_RESOLVED_VALUE,
  STATE_CLOSED_VALUE,
  REPLIED_VALUE,
  NOT_REPLIED_VALUE,
  FROM_VALUE,
  TO_VALUE,
  BETWEEN_VALUE
} from "./const"
export default {
  name: "CommentsFilter",
  props: {
    value: Object,
    commentUsers: Array,
    commentsLength: Number
  },
  data() {
    return {
      filterOptions: {
        location: [
          { text: "All", value: LOCATION_ALL_VALUE },
          { text: "Current View", value: LOCATION_CURRENT_VALUE }
        ],
        state: [
          { text: N_A_TEXT, value: "" },
          { text: "Open", value: STATE_OPEN_VALUE },
          { text: "Resolved", value: STATE_RESOLVED_VALUE },
          { text: "Closed", value: STATE_CLOSED_VALUE }
        ],
        replied: [
          { text: N_A_TEXT, value: "" },
          { text: "Replied", value: REPLIED_VALUE },
          { text: "Not Replied", value: NOT_REPLIED_VALUE }
        ],
        date: {
          options: [
            { text: N_A_TEXT, value: "" },
            { text: "From Date", value: FROM_VALUE },
            { text: "To Date", value: TO_VALUE },
            { text: "Between Dates", value: BETWEEN_VALUE }
          ]
        }
      },
      from_menu: false,
      to_menu: false,
      TO_VALUE: TO_VALUE,
      FROM_VALUE: FROM_VALUE,
      BETWEEN_VALUE: BETWEEN_VALUE
    }
  }
}
</script>

<style scoped>
.tile-action-filter {
  overflow: hidden;
  height: 36px;
  max-width: 60%;
}

.select-filter {
  font-size: 13px;
  text-align: right;
}

.border-left-date {
  border-left: solid;
  border-color: #90caf9;
}
</style>