const { BASE_URL, NODE_ENV } = process.env;
const { VUE_APP_BRANDING } = window.__env__ ? window.__env__ : {};
// If VUE_APP_BRANDING environmental variable is set, PLANT will show the logo in the branding folder, otherwise will show
// a default logo based on the NODE_ENV variable.
const BRANDING_PATH = `${BASE_URL}img/branding/${VUE_APP_BRANDING ? VUE_APP_BRANDING : "env-" + NODE_ENV
  }`;

export default {
  logo_url: `${BRANDING_PATH}/navbar-logo.svg`,
  login_background_url: `${BRANDING_PATH}/login-background.jpg`,
  name: VUE_APP_BRANDING,
};
