<template>
  <v-hover v-if="has_access">
    <v-card
      slot-scope="{ hover }"
      e2e-attr="workarea-card"
      :class="`elevation-${hover ? 6 : 1}`"
      :to="to"
      style="min-height: 250px">
      <v-card-text class="text-center">
        <v-avatar color="primary" class="mt-3 mb-3" size="60px">
          <v-icon color="white" large>{{ icon }}</v-icon>
        </v-avatar>
        <h3 class="heading mt-3 mb-3">{{ title }}</h3>
        <h4 class="subheading">{{ subtitle }}</h4>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    icon: {
      type: String
    },
    to: {
      type: String
    },
    has_access: {
      type: Boolean
    }
  }
}
</script>

<style>
</style>