export default {
  methods: {
    canDeleteBlock(id) {
      const block = this.getBlockById(id)
      return block && block.protection ? !block.protection.delete : false
    },
    deleteBlock(id) {
      function deleteBlock(elements, id) {
        const indexFound = elements.findIndex(el => el.id == id)

        if (indexFound > -1) {
          elements.splice(indexFound, 1)
          return true
        } else {
          for (const element of elements) {
            if (element.hasOwnProperty("blocks")) {
              if (deleteBlock(element.blocks, id)) break
            } else if (element.type === "carousel") {
              if (deleteBlock(element.data.cards, id)) break
            } else if (element.type === "tabs") {
              if (deleteBlock(element.data.tabs, id)) break
            } else if (element.type === "flashCard") {
              if (deleteBlock(element.data.blocks, id)) {
                break
              } else if (deleteBlock(element.data.overlays.answer.blocks, id)) {
                break
              }
            } else if (element.type === "libraryContentCustom") {
              if (deleteBlock(element.data.blocks, id)) {
                break
              } else if (deleteBlock(element.data.overlays.expanded.blocks, id)) {
                break
              }
            } else if (
              element.type === "contentMultipleChoiceQuestion" ||
              element.type === "contentMultipleChoiceQuestionTemplate" ||
              element.type === "adaptiveLearningResult"
            ) {
              if (deleteBlock(element.data.blocks, id)) {
                break
              } else if (deleteBlock(element.data.overlays.correct.blocks, id)) {
                break
              } else if (deleteBlock(element.data.overlays.incorrect.blocks, id)) {
                break
              }
            }

            // Check actions for popup blocks
            if (element.actions && element.actions.find(action => action.type == "popup")) {
              let action = element.actions.find(action => action.type == "popup")
              if (deleteBlock(action.blocks, id)) break
            }
          }
        }
      }

      // Avoid to deselect parent (when deleting a block inside another)
      if (!this.getParent(id)) {
        this.selectionClear()
      } else {
        this.selectionEdit(this.getParent(id).id)
      }

      if (this.canDeleteBlock(id)) deleteBlock(this.blocks, id)
    }
  }
}
