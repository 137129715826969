<template>
  <fragment>
    <p-panel-properties-group title="Canvas">
      <p class="lp-block-carousel-panel">Normal:</p>
      <style-select :value="value.data.style.canvas.normal" title="Normal" />
    </p-panel-properties-group>

    <p-panel-properties-group title="Arrows">
      <p class="lp-block-carousel-panel">Normal:</p>
      <style-select :value="value.data.style.arrows.normal" title="Normal" />
      <p class="lp-block-carousel-panel">Hover:</p>
      <style-select :value="value.data.style.arrows.hover" title="Hover" />
    </p-panel-properties-group>

    <p-panel-properties-group title="Navigation" class="panel-properties">
      <p-property-tile title="Loop" icon="sync">
        <v-switch v-model="value.data.navigation.loop"></v-switch>
      </p-property-tile>
    </p-panel-properties-group>

    <p-panel-properties-group title="Paginator">
      <p class="lp-block-carousel-panel">Normal:</p>
      <style-select :value="value.data.style.paginator.normal" title="Normal" />
      <p class="lp-block-carousel-panel">Hover:</p>
      <style-select :value="value.data.style.paginator.hover" title="Hover" />
      <p class="lp-block-carousel-panel">Active:</p>
      <style-select :value="value.data.style.paginator.active" title="Active" />
      <p class="lp-block-carousel-panel">Visited:</p>
      <style-select :value="value.data.style.paginator.visited" title="Visited" />
    </p-panel-properties-group>

    <p-panel-properties-group title="Cards">
      <item-style icon="mdi-tooltip" title="Show tooltip" class="lp-animation-form-item">
        <v-checkbox v-model="value.data.displayCardsTooltip" label="Display card name"></v-checkbox>
      </item-style>
      <item-style icon="mdi-transition" title="Transition" class="lp-animation-form-item">
        <v-select :items="availableTransitions" v-model="value.data.style.transition.type" item-text="text"></v-select>
      </item-style>
      <v-list>
        <draggable v-model="value.data.cards" handle=".handle-tab-list">
          <v-list-tile v-for="(card, index) in value.data.cards" :key="index">
            <v-list-tile-action>
              <v-icon class="handle-tab-list" style="cursor: row-resize">mdi-drag</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-text-field
                style="width: 100%"
                box
                hide-details
                full-width
                single-line
                type="text"
                v-model="card.title"
                label="Title"
                height="20px"
                @click:append-outer="removeCard(index)"
                append-outer-icon="mdi-delete-outline"></v-text-field>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon class="handle-tab-list" @click="duplicateCard(index)">mdi-content-duplicate</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </draggable>
        <v-list-tile class="mt-3">
          <v-list-tile-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn block outline color="primary" @click="addCard" v-on="on">
                  <v-icon left>mdi-folder-plus-outline</v-icon>Add card
                </v-btn>
              </template>
              Add a new Card
            </v-tooltip>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </p-panel-properties-group>
  </fragment>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../_components/utils/PPanelProperties.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import StyleSelect from "../../components/slides/styles/StyleSelect.vue"
import { Fragment } from "vue-fragment"
import { BlockCard } from "./class.js"
import Draggable from "vuedraggable"
import ItemStyle from "../../components/slides/styles/ItemStyle.vue"
import { changeAllBlockIds } from "../../utils/utils"

export default {
  inject: ["blockGlobals"],
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelPropertiesGroup,
    PPanelProperties,
    Fragment,
    StyleSelect,
    Draggable,
    ItemStyle
  },

  data() {
    return {
      fontSizes: [12, 14, 18, 24, 32, 42, 54],
      availableTransitions: [
        { text: "No Transition", value: "none" },
        { text: "Fade", value: "fade-in" }
      ]
    }
  },

  methods: {
    addCard() {
      this.value.data.cards.push(new BlockCard())
    },
    removeCard(index) {
      this.value.data.cards.splice(index, 1)
    },
    duplicateCard(index) {
      let currentCard = this.value.data.cards[index]
      let newCard = JSON.parse(JSON.stringify(currentCard))
      changeAllBlockIds(newCard)
      this.value.data.cards.push(newCard)
    }
  }
}
</script>

<style>
.lp-block-carousel-panel {
  font-size: 13px;
  margin: 5px 0 0 25px;
  font-weight: 500;
}
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
