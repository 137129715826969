<template>
  <p-panel-properties-group icon="mdi-grid" title="Grid">
    <!-- Grid Size -->
    <p-property-tile icon="mdi-grid-large" title="Grid Size">
      <v-select
        :items="availableSizes"
        v-model="lessonPlanController.gridSize"
        item-text="text"
        class="lp-info-left-panel-select"></v-select>
    </p-property-tile>
  </p-panel-properties-group>
</template>

<script>
import PPanelPropertiesGroup from "../../../blocks/_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"

export default {
  name: "GridSizeSelector",
  inject: ["lessonPlanController"],
  components: {
    PPanelPropertiesGroup,
    PPropertyTile
  },
  data() {
    return {
      availableSizes: [
        { text: "None", value: [1, 1] },
        { text: "Extra Small", value: [40, 22.5] },
        { text: "Small", value: [80, 45] },
        { text: "Medium small", value: [120, 67.5] },
        { text: "Medium", value: [160, 90] },
        { text: "Big", value: [320, 180] }
      ]
    }
  }
}
</script>

<style></style>
