<template>
  <div id="preview-wrapper" :style="`width: ${width}px; height: ${height}px; position: absolute;`">
    <div class="p-viewer-readonly-slide" ref="slide">
      <component
        v-for="(block, index) in blocks"
        :key="index + block.id"
        :is="getBlockComponent(block.type)"
        :value="block"
        :scale="scale"
        :fields="fields"
        mode="edit" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["blocks", "fields", "width", "height", "blockDefinitions"],
  data() {
    return {
      scale: 1
    }
  },
  mounted() {
    this.calculateScale()
  },
  watch: {
    width() {
      this.calculateScale()
    }
  },
  methods: {
    calculateScale() {
      this.scale = Math.min(this.width / 1920, this.height / 1080)
      this.translateX = (this.width / 2 - 1920 / 2) / this.scale
      this.translateY = (this.height / 2 - 1080 / 2) / this.scale
      this.$refs.slide.style.transform = `scale(${this.scale}) translate(${this.translateX}px, ${this.translateY}px) `
    },
    getBlockComponent(type) {
      return this.blockDefinitions[type].Block
    }
  }
}
</script>

<style>
.p-viewer-readonly-slide {
  width: 1920px;
  height: 1080px;
  position: absolute;
  pointer-events: none;
}
</style>
