<template>
  <v-container grid-list-xs>
    <v-toolbar flat class="elevation-0 transparent">
      <v-toolbar-title>Background Jobs</v-toolbar-title>
      <v-spacer></v-spacer>
      <span class="caption lighten-3 text-italic" :disabled="loading">Refreshed {{ lastReloadMoment }}</span>
      <v-btn outline @click="reload"> <v-icon left>mdi-refresh</v-icon>Refresh </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="jobs"
      :pagination.sync="pagination"
      :loading="loading"
      :total-items="totalItems"
      :rows-per-page-items="[10]">
      <template v-slot:items="props">
        <td>
          <status-job :job="props.item" />
        </td>

        <td v-if="props.item.data && props.item.data.custom_id">
          {{ props.item.data.custom_id }}
        </td>
        <td v-else>No custom ID</td>
        <td v-if="props.item.data && props.item.data.arguments">
          {{ props.item.data.arguments.content_id }}
        </td>
        <td v-else>No content ID</td>
        <td v-if="props.item.data && props.item.data.arguments && props.item.data.arguments.scorm_version">
          {{ props.item.data.arguments.scorm_version }}
        </td>
        <td v-else>N/A</td>
        <td>{{ props.item._id }} [{{ props.item.name }}]</td>
        <td>{{ props.item.lastRunAt }}</td>
        <td>{{ props.item.lastFinishedAt }}</td>
        <td>
          <v-avatar class="pointer" @click="detailJob(props.item)">
            <v-icon>mdi-information-outline</v-icon>
          </v-avatar>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { jobsApi } from "@/api"
import moment from "moment"
import { PtModal } from "plant-common"
import StatusJob from "./components/StatusJob.vue"
import JobDetailModalVue from "./JobDetailModal.vue"
export default {
  name: "BackgroundJobs",
  mixins: [PtModal],
  components: { StatusJob },
  data() {
    return {
      lastReload: "",
      lastReloadMoment: "",
      intervalUpdateTime: false,
      intervalReload: false,
      totalItems: 0,
      loading: false,
      headers: [
        {
          text: "State",
          sortable: false
        },
        {
          text: "Custom ID",
          value: "data.custom_id"
        },
        {
          text: "Content ID",
          value: "data.arguments.content_id"
        },
        {
          text: "SCORM version",
          value: "data.arguments.scorm_version"
        },
        {
          text: "Job",
          value: "_id"
        },
        {
          text: "Last Run At",
          value: "lastRunAt"
        },
        {
          text: "Last Finish At",
          value: "lastFinishedAt"
        },
        {
          text: "",
          sortable: false
        }
      ],
      jobs: [],
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10, // -1 for All",
        sortBy: "-_id"
      }
    }
  },
  created() {
    this.intervalUpdateTime = setInterval(() => (this.lastReloadMoment = moment(this.lastReload).fromNow()), 30 * 1000)
    this.intervalReload = setInterval(this.reload, 90 * 1000)
  },
  destroyed() {
    clearInterval(this.intervalUpdateTime)
    clearInterval(this.intervalReload)
  },
  methods: {
    async reload() {
      this.loading = true
      var { page } = this.pagination
      var limit = this.pagination.rowsPerPage
      var sort = ""
      if (this.pagination.sortBy) {
        sort = `${this.pagination.descending ? "-" : ""}${this.pagination.sortBy}`
      }
      var { data } = await jobsApi.getJobList(page, limit, sort)
      this.jobs = data.docs
      this.totalItems = data.paginator.totalDocs
      this.loading = false
      this.lastReload = new Date()
      this.lastReloadMoment = moment(this.lastReload).fromNow()
    },
    async detailJob(item) {
      var res = await this.$modal(JobDetailModalVue, {
        job: item,
        value: true
      })
      if (!res) return false
    }
  },
  watch: {
    pagination: {
      immediate: true,
      async handler() {
        this.reload()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>