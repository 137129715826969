import DatasheetReplaceDescription from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "datasheet-replace-description",
  title: "Replace Datasheet Description",
  description:
    'This operation replaces the "find" value (Case sensitive) with the "replace" value in datasheet\'s description',
  contentType: "datasheet",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.body && content.body.contents && content.body.contents.introduction) {
        content.body.contents.introduction = content.body.contents.introduction
          .toString()
          .replace(searchRegExp, replaceWith)
      }
    }
    return content
  },
  OperationOptionsComponent: DatasheetReplaceDescription
}
