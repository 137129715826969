import "./layers.css"
export default class Layer {
    constructor(index, svgElement) {
        this.index = index
        this.visible = true
        this.opacity = 1
        this.svgElement =svgElement
    }
    show() {
        this.visible = true;
        this.svgElement.dataset["ptLayer-" + this.index] = true
        //querySelectorAll("g[v\\:layermember*='" + this.index + "']").forEach((e) => e.style.opacity = 1);
    }
    hide() {
        this.visible = false;
        this.svgElement.dataset["ptLayer-" + this.index] = false
        //this.svgElement.querySelectorAll("g[v\\:layermember*='" + this.index + "']").forEach((e) => e.style.opacity = 0);
    }
    toggle() {
        //this.svgElement.querySelectorAll("g[v\\:layermember*='" + this.index + "']").forEach((e) => e.style.opacity = this.visible ? 0 : 1);
        this.visible = (!this.visible);
        this.svgElement.dataset["ptLayer-" + this.index] = this.visible
    }
    setOpacity(opacity){
        this.opacity=opacity
        this.svgElement.querySelector("style").sheet.rules[this.index].style.opacity= this.opacity
    }
}