<template>
  <v-list dense v-bind="$attrs">
    <slot />
  </v-list>
</template>

<script>
export default {}
</script>

<style>
.v-list--three-line .v-list__tile__sub-title {
  -webkit-box-orient: vertical;
  max-height: 40px;
}
</style>
