import ptCoreClient from "plant-core-client"
import store from '@/store'
import { STORE_LIBRARY_NAME, STATE_PLANT_ID, STATE_PLANT_SETTINGS, MUTATION_SET_PLANT, MUTATION_SET_PLANT_SETTINGS, ACTION_UPDATE_PLANT_SETTINGS, ACTION_SELECT_PLANT, ACTION_LEAVE_PLANT } from "@/store_constants/library"

export default {
    name: STORE_LIBRARY_NAME,
    namespaced: true,
    state: {
        [STATE_PLANT_ID]: false,
        [STATE_PLANT_SETTINGS]: {},
    },
    mutations: {
        [MUTATION_SET_PLANT](state, plant_id) {
            if (plant_id === false) {
                state.plant_id = false
                state.settings = {}
            } else {
                state.plant_id = plant_id
            }
        },
        [MUTATION_SET_PLANT_SETTINGS](state, settings) {
            state.settings = settings
        }
    },
    actions: {
        [ACTION_UPDATE_PLANT_SETTINGS]({ commit }, settings) {
            ptCoreClient.updatePlant(store.state[STORE_LIBRARY_NAME][STATE_PLANT_ID], settings).then(settings => {
                console.log("ptCoreClient - updatePlant", settings)
                commit(MUTATION_SET_PLANT_SETTINGS, settings);
            })
        },
        async [ACTION_SELECT_PLANT]({ commit }, plant_id) {
            commit(MUTATION_SET_PLANT, plant_id);
            let settings = await ptCoreClient.getPlant(store.state[STORE_LIBRARY_NAME][STATE_PLANT_ID]);
            commit(MUTATION_SET_PLANT_SETTINGS, settings);
            return settings;
        },
        [ACTION_LEAVE_PLANT]({ commit }) {
            commit(MUTATION_SET_PLANT, false)
        }
    }
}