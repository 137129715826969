import uuid from "../../../utils/uuid.js"

/**
 * An Interactive Text
 */

export class PContentInteractiveText {
  constructor() {
    this.page = new Page()

    // Global Styles
    this.settings = {
      styles: {
        page: {
          backgroundColor: "#ffffffff",
          color: "#000000ff",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "2px solid #ccc",
          fontFamily: "Roboto"
        }
      }
    }
  }
}

/**
 * A Page
 */
export class Page {
  constructor(title = "Interactive Text Page") {
    this.id = uuid()
    this.title = title
    this.html = {}
    this.images = []
    this.htmlDependencies = []
  }
}
