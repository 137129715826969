<template>
  <span>
    <v-btn
      flat
      color="yellow"
      icon
      class="transparent mr-3 ml-3 clickable"
      small
      style="padding-left:8px"
      @click="appWindow.toggleDevTools()"
    >
      <v-icon :color ="iconColor">mdi-dots-vertical</v-icon>
    </v-btn>
    <v-btn
      flat
      color="green"
      icon
      class="transparent clickable"
      style="padding-left:8px"
      small
      @click="appWindow.minimize()"
    >
      <v-icon :color ="iconColor">mdi-window-minimize</v-icon>
    </v-btn>
    <v-btn
      flat
      color="orange"
      icon
      class="transparent clickable"
      small
      style="padding-left:8px"
      @click="()=> appWindow.isMaximized() ?appWindow.unmaximize() : appWindow.maximize()"
    >
      <v-icon :color ="iconColor">mdi-window-{{ appWindow.isMaximized()? 'restore':'maximize'}}</v-icon>
    </v-btn>
    <v-btn
      icon
      color="red"
      flat
      style="padding-left:8px"
      small
      class="clickable"
      @click="appWindow.close()"
    >
      <v-icon :color ="iconColor">mdi-close</v-icon>
    </v-btn>
  </span>
</template>

<script>
export default {
  props:["iconColor"],
  computed: {
    appWindow() {
      if (typeof PtDesktopApi != "undefined") return PtDesktopApi.appWindow;
      else
        return {
          isMaximized: () => true
        };
    }
  }
};
</script>

<style>
.clickable {
  -webkit-app-region: no-drag;
}
</style>
