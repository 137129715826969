<template>
  <v-list two-line>
    <v-subheader>Current Content Information</v-subheader>
    <template v-if="selectedCard.type=='card'">
     <viewer-current-detail-content/>
    </template>
    <template v-else-if="selectedCard.type=='section'">
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-format-title</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Title</v-list-tile-sub-title>
          <v-list-tile-title>{{selectedCard.fields.title}}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile avatar>
        <v-list-tile-avatar>
          <v-icon>mdi-cards-outline</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Nb. of content</v-list-tile-sub-title>
          <v-list-tile-title>{{selectedCard.children.length}}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </v-list>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
import { ContentTypeIcon } from "plant-common";
import ViewerCurrentDetailContent from "./ViewerCurrentDetailContent"

export default {
  name: "GuideInfo",
  inject: ["guideController"],
  components: {
    ContentTypeIcon,ViewerCurrentDetailContent
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"]),
  }
};
</script>
