<template>
  <v-list dense>
    <template v-for="(item, key) in value">
      <!-- Background-color -->
      <p-property-tile v-if="key == 'backgroundColor'" icon="mdi-palette-outline" title="Background color" :key="key">
        <preset-input-color :value="value[key]" @input="value[key] = $event"></preset-input-color>
      </p-property-tile>

      <!-- Arrow-color -->
      <p-property-tile v-if="key == 'arrowColor'" icon="mdi-arrow-left-right" title="Arrow color" :key="key">
        <preset-input-color :value="value[key]" @input="value[key] = $event"></preset-input-color>
      </p-property-tile>

      <!-- Color -->
      <p-property-tile icon="mdi-format-color-text" title="Text color" v-if="key == 'color'" :key="key">
        <preset-input-color :value="value[key]" @input="value[key] = $event"></preset-input-color>
      </p-property-tile>

      <!-- Text scale -->
      <p-property-tile icon="mdi-format-size" title="Text scale" v-if="key == 'textScale'" :key="key">
        <v-select
          :items="availableScales"
          v-model="value[key]"
          item-text="text"
          class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Text-align -->
      <p-property-tile icon="mdi-format-align-center" title="Text align" v-if="key == 'textAlign'" :key="key">
        <v-select
          :items="availableAlignments"
          v-model="value[key]"
          item-text="text"
          class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Font-family -->
      <p-property-tile icon="mdi-format-font" title="Font-family" v-if="key == 'fontFamily'" :key="key">
        <v-select :items="blockGlobals.fontFamilies" v-model="value[key]" class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Box shadow slide -->
      <p-property-tile icon="mdi-box-shadow" title="Box-shadow" v-if="key == 'boxShadow'" :key="key">
        <v-select
          :items="availableShadows"
          v-model="value[key]"
          item-text="text"
          class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Box shadow button -->
      <p-property-tile icon="mdi-box-shadow" title="Box-shadow" v-if="key == 'boxShadowButton'" :key="key">
        <v-select
          :items="availableShadowsButton"
          v-model="value[key]"
          item-text="text"
          class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Space between elements -->
      <p-property-tile icon="mdi-keyboard-space" title="Space between" v-if="key == 'spaceBetween'" :key="key">
        <p-panel-input-number class="lp-layout-style-select-input" v-model="value[key]" :min="0" :max="100" />
      </p-property-tile>

      <!-- Padding in elements -->
      <p-property-tile icon="mdi-page-next-outline" title="Padding text" v-if="key == 'paddingText'" :key="key">
        <p-panel-input-number
          class="lp-layout-style-select-input"
          v-model="value['paddingText']"
          :min="0"
          :max="1000" />
      </p-property-tile>

      <!-- Font Size -->
      <p-property-tile icon="mdi-format-size" title="Font size" v-if="key == 'fontSize'" :key="key">
        <p-panel-input-number class="lp-layout-style-select-input" v-model="value['fontSize']" :min="0" :max="1000" />
      </p-property-tile>

      <!-- Icon color -->
      <p-property-tile icon="mdi-format-color-fill" title="Icon color" v-if="key == 'iconColor'" :key="key">
        <preset-input-color :value="value[key]" @input="value[key] = $event"></preset-input-color>
      </p-property-tile>

      <!-- String value -->
      <p-property-tile icon="mdi-format-text" title="Text" v-if="key == 'text'" :key="key">
        <v-text-field :value="value[key]" @input="value[key] = $event" />
      </p-property-tile>

      <!-- Icons -->
      <p-property-tile v-if="key == 'icon'" :key="key" icon="mdi-selection-ellipse" title="Icon">
        <v-select :items="options" v-model="value[key]" class="lp-layout-style-select">
          <template #item="{ item }">
            <span v-if="item == 'none'">None</span>
            <v-icon v-else> {{ item }} </v-icon>
          </template>
          <template #selection="{ item }">
            <span v-if="item == 'none'">None</span>
            <v-icon v-else> {{ item }} </v-icon>
          </template>
        </v-select>
      </p-property-tile>

      <!-- Border -->
      <p-property-tile v-if="key == 'border'" :key="key" icon="mdi-border-all-variant" title="Border">
        <v-select :items="availableBorders" v-model="value[key]" item-text="text" class="lp-layout-style-select" />
      </p-property-tile>

      <!-- Border radius -->
      <p-property-tile
        icon="mdi-checkbox-blank-circle-outline"
        title="Border radius"
        v-if="key == 'borderRadius'"
        :key="key">
        <p-panel-input-number
          class="lp-layout-style-select-input"
          v-model="value['borderRadius']"
          :min="0"
          :max="500" />
      </p-property-tile>

      <!-- Border width -->
      <p-property-tile icon="mdi-border-all-variant" title="Border width" v-if="key == 'borderWidth'" :key="key">
        <p-panel-input-number class="lp-layout-style-select-input" v-model="value['borderWidth']" :min="0" :max="100" />
      </p-property-tile>

      <!-- Border styles -->
      <p-property-tile icon="mdi-border-top-variant" title="Border style" v-if="key == 'borderStyle'" :key="key">
        <v-select
          :items="availableBorderStyle"
          v-model="value[key]"
          item-text="text"
          class="lp-layout-style-select"></v-select>
      </p-property-tile>

      <!-- Border color -->
      <p-property-tile icon="mdi-select-color" title="Border color" v-if="key == 'borderColor'" :key="key">
        <preset-input-color :value="value[key]" @input="value[key] = $event"></preset-input-color>
      </p-property-tile>
    </template>
  </v-list>
</template>

<script>
import PresetInputColor from "./PresetInputColor.vue"
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"
import PPanelInputNumber from "../../../blocks/_components/utils/PPanelInputNumber.vue"

export default {
  components: { PPropertyTile, PresetInputColor, PPanelInputNumber },
  props: ["value", "title", "options"],
  inject: ["blockGlobals"],
  data() {
    return {
      availableShadows: [
        { text: "None", value: "none" },
        {
          text: "Simple",
          value: "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)"
        }
      ],
      availableShadowsButton: [
        { text: "None", value: "none" },
        {
          text: "Simple",
          value: "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)"
        }
      ],
      availableBorders: [
        { text: "None", value: "none" },
        {
          text: "Simple",
          value: "2px solid #ccc"
        }
      ],
      availableAlignments: [
        { text: "Left", value: "flex-start" },
        { text: "Center", value: "center" }
      ],
      scales: this.scaleValues(50, 200, 5),
      borderStyle: ["solid", "dotted", "dashed", "double", "groove", "ridge", "inset", "outset"]
    }
  },
  computed: {
    availableScales() {
      return this.scales.map(scale => {
        return { text: `${scale}%`, value: scale }
      })
    },
    availableBorderStyle() {
      return this.borderStyle.map(border => {
        return { text: `${border}`, value: border }
      })
    }
  },
  methods: {
    scaleValues(from, to, step) {
      const scale = []
      for (let i = from; i <= to; i += step) {
        scale.push(i)
      }
      return scale
    }
  }
}
</script>

<style>
.lp-layout-style-select .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  width: 100px;
}
.lp-layout-style-checkbox.v-input {
  display: flex;
  justify-content: flex-end;
}
.v-list__tile__action .lp-layout-style-checkbox.v-input--selection-controls {
  margin-right: -10px;
}
.lp-layout-style-select-input {
  width: 100px;
}
</style>
