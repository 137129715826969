<template>
  <div
    style="position: absolute; top: 0px; bottom: 0px; left: 0px; right: 0px; background: rgba(0, 0, 0, 0.5)"
    @click.self="canCloseOutside ? closeOverlay() : ''">
    <div :style="style" ref="canvas">
      <div
        v-if="showCloseButton"
        class="close-icon"
        style="display: flex; justify-content: flex-end"
        @click="closeOverlay">
        <v-icon> mdi-close </v-icon>
      </div>
      <block-subcanvas-readonly
        class="block-subcanvas"
        :blocks="blockController.overlay.blocks"
        mode="view"
        :style="styleCanvas"
        @goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
        @selectSlideById="$emit('selectSlideById', $event)"
        :blockVariables="blockController.overlayVariables" />
    </div>
  </div>
</template>

<script>
import BlockSubcanvasReadonly from "./BlockSubcanvasReadonly.vue"

export default {
  components: { BlockSubcanvasReadonly },
  inject: ["blockController"],
  props: ["defaultBlockStyles"],
  computed: {
    parent() {
      return this.$refs.canvas
    },
    style() {
      return {
        position: "relative",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: `${this.blockController.overlay.width}px`,
        height: `${this.blockController.overlay.height}px`,
        borderRadius: `${this.blockController.overlay.style.borderRadius}px`,
        backgroundColor: this.blockController.overlay.style.backgroundColor || "white"
      }
    },
    styleCanvas() {
      return {
        fontFamily: this.defaultBlockStyles.fontFamily
      }
    },
    canCloseOutside() {
      return !this.blockController.overlay.avoidClosingOutside
    },
    showCloseButton() {
      return !this.blockController.overlay.hideCloseButton
    }
  },
  methods: {
    closeOverlay() {
      this.blockController.overlay = null
    }
  }
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 1rem 1rem;
}
.block-subcanvas {
  overflow: hidden;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
</style>