<template>
  <div v-if="isSubtitlesActive" :style="subtitleStyle" class="block-overlay-canvas">
    <span v-html="subtitlesText"></span>  
  </div>
</template>

<script>

export default {
  inject: ["lessonPlanController"],
  data() {
    return {
      // añadida esta línea para futuro (cuando el autor pueda configurar detalles de los subtítulos)
      subtitleStyle: {
        "font-size": "40px",
        "text-align": "justify",
        "background": "rgba(0, 0, 0, 0.7)",
        "color": "white"
      }
    }
  },
  computed: {
    isSubtitlesActive() {
      return this.lessonPlanController.subtitlesActive && this.lessonPlanController.subtitlesText
    },
    subtitlesText() {
      return this.lessonPlanController.subtitlesText
    }
  }
}
</script>

<style scoped>
.block-overlay-canvas {
  position: absolute;
  bottom: 10vh;
  left: 20vw;
  right: 20vw;
  padding: 10px 20px 10px 20px;
}
</style>