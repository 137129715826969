<template>
  <viewer-svg :svg="svg" :fields="fields" />
</template>

<script>
import { mapInjectedData , mapInjectedMethods} from "plant-common/src/utils";
import ViewerSvg from "./ViewerSvg";
import { getCustomId, getTitle } from '../../../../utils/dbTool';

export default {
  name: "GuideViewerTemplate",
  inject: ["guideController"],
  data() {
    return {
      domElement: null,
    };
  },
  components: { ViewerSvg },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "guide", "content"]),
    svg() {
      let cover = this.guide.template.body.attachments.find(e => e.name == this.selectedCard.content.template_name && e.attachment_type=='.svg')
      if(cover) return cover
      return '<svg></svg>'
    },
    fields(){
      return {
          _title: getTitle(this.content), 
          _custom_id: getCustomId(this.content)
        }
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["getTemplate", "findElementByAnchor", "selectCard"])   
  }
};
</script>