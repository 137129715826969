import {$http} from "./config";
import {Users, Auth, Folders, Content, Drafts, RecycledDrafts, xAPI} from "./routes"

const PtApi = {
	baseURL: '',
	token: '',
	interceptors: $http.interceptors,
	axiosInstance: $http,
	...Users,
	...Auth,
	...Folders,
	...Content,
	...Drafts,
	...RecycledDrafts,
	...xAPI
};

export default PtApi;
