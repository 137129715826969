<template>
  <v-dialog :value="value" max-width="1800px" persistent>
    <v-card height="90vh" width>
      <v-toolbar flat>
        <v-toolbar-title> <v-icon left>mdi-update</v-icon>Bulk Change </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon flat @click.stop="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="bulk-change-content">
        <bulk-change-options
          v-if="state == 'user-input' || state == 'waiting-for-operation'"
          :approve.sync="update_options.approve"
          :contributors.sync="update_options.contributors"
          :operationsSelected.sync="operationsSelected"
          :type.sync="update_options.type"
          :workflow.sync="update_options.workflow"
          :canAddOperation="canAddOperation"
          :canChangeProps="canChangeProps" />
        <div style="padding: 10px">
          <v-divider></v-divider>
          <bulk-upload-progress v-if="state == 'completed' || state == 'operating'" :items="selected" />
          <v-btn
            color="success"
            block
            outline
            @click.stop="startOperation"
            v-if="state == 'user-input' || state == 'waiting-for-operation'"
            :disabled="state == 'waiting-for-operation'">
            <v-icon left>mdi-play</v-icon>Start Operation
          </v-btn>
          <v-btn color="primary" block :disabled="true" v-if="state == 'operating'">
            <v-icon left>mdi-update</v-icon>Processing Operation...
          </v-btn>
          <v-btn color="success" block @click.stop="close(true)" v-if="state == 'completed'">
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
        </div>
        <div style="padding: 10px" v-if="state == 'completed' || state == 'operating'">
          <v-divider></v-divider>
          <operations-summary :operations="operationsSelected" />
        </div>
      </div>
      <bulk-change-table
        v-if="selectedFilled.length"
        :contents="selected"
        :changeProps="canChangeProps"
        :contentType="update_options.type"
        :operationsSelected="operationsSelected"
        @open:content="openContentInNewTab" />
      <div v-else class="bulk-change__progress">
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { STORE_EXPLORER_NAME, STATE_SELECTED, ACTION_CLEAN_SELECTED } from "@/store_constants/explorer"

import BulkChangeTable from "./Table.vue"
import BulkChangeOptions from "./Options.vue"
import OperationsSummary from "./OperationsSummary.vue"
import BulkUploadProgress from "../BulkUpload/Progress.vue"

import PtApiClient from "plant-api-client"
import { getContentId } from "../../../../../utils/dbTool"

export default {
  name: "BulkChange",
  props: ["value"],
  components: {
    BulkChangeOptions,
    BulkChangeTable,
    BulkUploadProgress,
    OperationsSummary
  },
  data() {
    return {
      state: "waiting-for-operation", // 'user-input', 'operating', 'completed'
      update_options: {
        type: "",
        workflow: "no_reviewer",
        approve: false,
        contributors: [{ role: "author", user_id: "" }]
      },
      operationsSelected: [
        {
          operation: false,
          data: {}
        }
      ],
      selectedFilled: []
    }
  },
  computed: {
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    ...mapState(STORE_EXPLORER_NAME, {
      selected: STATE_SELECTED
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    canChangeProps() {
      return this.haveAllSelectedSameSchema()
    },
    canAddOperation() {
      return (
        !this.operationsSelected[this.operationsSelected.length - 1].operation ||
        !this.operationsSelected[this.operationsSelected.length - 1].operation.hasOwnProperty("chainable") ||
        (this.operationsSelected[this.operationsSelected.length - 1].operation.hasOwnProperty("chainable") &&
          this.operationsSelected[this.operationsSelected.length - 1].operation.chainable)
      )
    }
  },
  async created() {
    // Set default options
    this.update_options.contributors[0].user_id = this.profile._id
    this.update_options.type = this.selected[0].header.metadata.content_revision_type
    this.update_options.workflow = "no_reviewer"

    const promises = this.selected.map(async element => {
      if (!element.hasOwnProperty("body")) {
        const content = await PtApiClient.getContentById(element._id)
        return Object.assign(element, content)
      } else {
        return element
      }
    })

    this.selectedFilled = await Promise.all(promises)
  },
  mounted() {
    // Use $set to make 'status' propery reactive (not-started by default)
    this.selected.forEach(c => {
      this.$set(c, "status", "not-started")
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      cleanSelected: ACTION_CLEAN_SELECTED
    }),
    close(flag) {
      this.cleanSelected()
      this.$emit("input", flag)
    },
    async checkForSpecialSteps(operation, draft) {
      switch (operation.id) {
        case "guide-transition-to-interactive-presentation":
          try {
            await PtApiClient.copyFiles(draft.body.contents.templates[0].reference._id, draft._id)
          } catch (e) {
            console.log(e)
          }
          break
        default:
          // do nothing
          break
      }
    },
    async startOperation() {
      this.state = "operating"
      for (var content of this.selected) {
        content.status = "in-progress"
        this.operationsSelected.forEach(operationSelected => {
          // Process all operations for selected content
          operationSelected.operation.operation(content, operationSelected.data)
        })
        // Start draft generation
        try {
          await PtApiClient.createDraft(getContentId(content))
          await PtApiClient.updateDraftWorkflow(getContentId(content), "method", "changeWorkflow", {
            name: this.update_options.workflow,
            lightweight: true
          })
          await PtApiClient.updateDraftWorkflow(getContentId(content), "method", "reassignRole", {
            role: this.update_options.contributors[0].role,
            user_id: this.update_options.contributors[0].user_id,
            lightweight: true
          })
          const metadata = {
            library_id: content.uploaded?.itemRevisionDraft?.header?.metadata?.library_id,
            custom_revision: content.header.metadata.custom_revision,
            title: content.header.metadata.title,
            description: content.header.metadata.description,
            content_revision_type: content.header.metadata.content_revision_type
          }
          const draft = await PtApiClient.updateDraftBody(getContentId(content), content.body)
          for (const operationSelected of this.operationsSelected) {
            // Check for special steps for each operation (we need new created draft id)
            await this.checkForSpecialSteps(operationSelected.operation, draft)
          }
          await PtApiClient.updateDraftMetadata(getContentId(content), metadata)
          if (this.update_options.approve) {
            await PtApiClient.updateDraftWorkflow(getContentId(content), "transition", "approveMinorChange")
          }
          content.status = "done"
        } catch (error) {
          console.error(error)
          content.status = "error"
          content.error = error.response.data.message
        }
      }
      this.state = "completed"
    },
    openContentInNewTab(item) {
      if (this.update_options.approve) {
        // Open latest version
        window.open(`/content/${this.settings._id}/${item.header.metadata.content_id}/latest`)
      } else {
        // Open draft
        window.open(`/content/${this.settings._id}/${item.header.metadata.content_id}/draft`)
      }
    },
    haveAllSelectedSameSchema() {
      const schemas = this.selectedFilled.map(element => {
        if (element.header.metadata.content_revision_type === "datasheet") {
          if (element.body?.contents?.schema.$origin) return element.body.contents.schema.$origin
          if (element.body?.contents?.schema.$ref) return element.body.contents.schema.$ref
        }
      })

      function normalizeOrigins(origins) {
        const regexAbsolute = new RegExp("plant://")
        const originObjects = []
        origins.forEach(origin => {
          if (origin) {
            if (regexAbsolute.test(origin)) {
              var [protocol, empty, library_id, content, content_id, major_revision, minor_revision] = origin.split("/")
            } else {
              var [, , content_id, major_revision, minor_revision] = origin.split("/")
            }
            originObjects.push({
              content_id,
              major_revision,
              minor_revision
            })
          }
        })
        return originObjects
      }

      const normalizedOrigins = normalizeOrigins(schemas)
      const uniqueSchema = normalizedOrigins.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            t =>
              t.content_id === value.content_id &&
              t.major_revision === value.major_revision &&
              t.minor_revision === value.minor_revision
          )
      )
      return uniqueSchema.length === 1
    }
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        // Close dialog if there is no content selected
        if (this.selected.length == 0) {
          this.close()
        }
      }
    },
    operationsSelected: {
      deep: true,
      handler() {
        // Update state watching operations array
        this.state = "user-input"
        this.operationsSelected.forEach(op => {
          if (!op.operation) {
            this.state = "waiting-for-operation"
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.bulk-change-content {
  position: absolute;
  top: 65px;
  bottom: 0px;
  left: 0px;
  height: calc(100% - 65px);
  width: 450px;
  border-right: rgba(0, 0, 0, 0.12) solid thin;
  overflow: auto;
}

.bulk-change__progress {
  position: absolute;
  top: 65px;
  left: 450px;
  right: 0;
}
</style>
