<template>
	<div style="margin-left: 350px; width: calc(100% - 350px)">
		<v-toolbar flat color="white">
			<v-toolbar-title>Contents to upload</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>

		<v-data-table
			hide-actions
			:headers="headers"
			:items="contents"
			class="elevation-0"
			v-if="contents.length > 0"
			style="position: absolute; top: 120px; left: 350px; right: 0px; bottom: 0px; overflow: auto"
		>
			<template v-slot:items="props">
				<td>
					<v-icon v-if="props.item.status == 'not-started'" color="primary">mdi-circle-outline</v-icon>
					<v-icon v-else-if="props.item.status == 'in-progress'" color="orange">mdi-progress-clock</v-icon>
					<v-icon v-else-if="props.item.status == 'done'" color="green">mdi-check-circle</v-icon>
					<v-tooltip bottom v-else-if="props.item.status == 'error'">
						<template v-slot:activator="{ on }">
							<v-icon color="red" v-on="on">mdi-circle</v-icon>
						</template>
						<span>{{ props.item.error }}</span>
					</v-tooltip>
				</td>
				<td v-if="props.item.filename">{{ props.item.filename }}</td>
				<td>
					<input
						class="hw-100"
						:value="props.item.custom_id"
						@blur="v => (props.item.custom_id = v.target.value)"
						:contenteditable="!disabled"
						placeholder="Custom ID"
					/>
				</td>
				<td>
					<input
						class="hw-100"
						:value="props.item.custom_revision"
						@blur="v => (props.item.custom_revision = v.target.value)"
						:contenteditable="!disabled"
						placeholder="Custom Revision"
					/>
				</td>
				<td>
					<input
						class="hw-100"
						:value="props.item.title"
						@blur="v => (props.item.title = v.target.value)"
						:contenteditable="!disabled"
						placeholder="Title"
					/>
				</td>
				<td v-if="showObjectiveRaw">
					<div
						v-if="contentHasObjective(props.item) && props.item.body.contents.instructionalSettings?.objective.$origin"
					>
						{{ props.item.body.contents.instructionalSettings.objective.custom_id }}
					</div>
					<div v-else class="obj-not-found">No content found</div>
				</td>
				<td>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-chip
								v-on="on"
								small
								v-if="props.item.body.attachments.length > 0"
								:color="getAttachmentBadgeColor(props.item.body)"
								text-color="white"
							>
								<v-icon left>mdi-attachment</v-icon>
								{{ props.item.body.attachments.length }}
							</v-chip>
						</template>
						<span>
							This content has {{ props.item.body.attachments.length }} file{{
								props.item.body.attachments.length > 1 ? 's' : ''
							}}
							attached (
							<span v-for="(attachment, index) in props.item.body.attachments">
								{{ attachment.attachment_type }} <span v-if="index + 1 < props.item.body.attachments.length">, </span>
							</span>
							)
						</span>
					</v-tooltip>
				</td>
				<td class="justify-center layout px-0">
					<v-btn
						v-if="!disabled"
						color="red"
						icon
						flat
						@click.stop="
							contents.splice(
								contents.findIndex(e => e == props.item),
								1
							)
						"
					>
						<v-icon>mdi-delete-outline</v-icon>
					</v-btn>
				</td>
			</template>
		</v-data-table>
	</div>
</template>

<script>
export default {
	props: ['contents', 'disabled', 'type'],
	data() {
		return {
			showObjectiveRaw: false
		};
	},
	computed: {
		headers() {
			var headers = [
				{
					text: '',
					value: '',
					sortable: false,
					width: '40px'
				},
				{
					text: 'Custom Identifier',
					value: 'custom_id',
					sortable: true,
					width: '175px'
				},
				{
					text: 'Custom Revision',
					value: 'custom_revision',
					sortable: true,
					width: '150px'
				},
				{ text: 'Title', value: 'title', sortable: true, width: '100%' },
				{ text: '', value: '', sortable: false, width: '30px' },
				{ text: '', value: 'upload', sortable: false, width: '30px' }
			];
			for (const content of this.contents) {
				if (this.contentHasObjective(content)) {
					const index = headers.findIndex(header => header.value === 'title');
					headers.splice(index + 1, 0, {
						text: 'Objective',
						value: '',
						sortable: false,
						width: '200px'
					});
					this.showObjectiveRaw = true;
					break;
				}
			}
			if (this.contents[0].filename)
				headers.splice(1, 0, {
					text: 'Original Filename',
					value: 'filename',
					sortable: true,
					width: '150px'
				});
			return headers;
		}
	},
	methods: {
		getAttachmentBadgeColor(body) {
			let badgeColor = 'blue';
			switch (this.type) {
				case 'diagram':
					if (body.attachments.length < 2) {
						badgeColor = 'orange';
					}
					break;

				default:
					break;
			}
			return badgeColor;
		},
		contentHasObjective(content) {
			return (
				content.body &&
				content.body.contents &&
				content.body.contents.instructionalSettings &&
				content.body.contents.instructionalSettings.objective
			);
		}
	}
};
</script>

<style scoped>
.hw-100 {
	height: 100%;
	width: 100%;
}
.obj-not-found {
	width: 100px;
	font-weight: 600;
}
</style>
