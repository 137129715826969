import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import Component from "./InteractiveTextComponent.vue"

export default Node.create({
  name: "InteractiveTextComponent",
  group: "block",
  content: "block*",
  atom: true,

  addAttributes() {
    return {
      origin: {
        default: ""
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "interactive-text"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["interactive-text", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
})
