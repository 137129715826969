<template>
  <div class="h-100">
    <library-selector name="Tools" />
    <v-container grid-list-xs class="tools-container">
      <library-selector-card name="Tools" v-if="!plant_id || !library_id" />
      <router-view />
    </v-container>
  </div>
</template>
<script>
import LibrarySelector from "@/components/LibrarySelector.vue"
import LibrarySelectorCard from "@/components/LibrarySelectorCard.vue"
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "@/store_constants/library"
import { mapState } from "vuex"

export default {
  name: "ContentToolsPage",
  components: { LibrarySelector, LibrarySelectorCard },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    }),
    library_id() {
      return this.$route.params.library_id
    }
  },
  created() {
    if (this[STATE_PLANT_ID])
      this.$router.push({
        name: "Tools",
        params: { library_id: this[STATE_PLANT_ID] }
      })
  }
}
</script>

<style scoped>
.tools-container {
  margin-left: 230px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
  height: 100%;
  max-width: calc(100% - 230px) !important;
}

.h-100 {
  height: calc(100% - 64px) !important;
}
</style>