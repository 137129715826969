<template>
  <component
    v-bind:is="component"
    :mode="mode"
    :value="itemRevision"
    ref="editor"
    @create:draft="createHotfixDraft"
    @copyItem="createDraftFromItem"
    :view.sync="view"
    :comments="comments" />
</template>

<script>
import { Extensions } from "plant-common"
import { ItemViewer } from "plant-common"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveBookViewer from "@/components/interactiveBook"
import interactiveTextViewer from "@/components/interactiveText"
import { mapState, mapActions, mapGetters } from "vuex"
import Loading from "../_layout/Loading"
import changeDocumentTitleAndIcon from "@/utils/changeDocumentTitleAndIcon.js"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_CAN_EDIT,
  STORE_MODULE_EDITOR,
  STATE_CURRENT_LOCATION,
  STATE_ITEM_IS_EDITED,
  ACTION_LOAD_DRAFT,
  ACTION_UPDATE_IS_EDITED,
  STORE_MODULE_COMMENTS,
  GETTER_COMMENTS,
  ACTION_SAVE_CURRENT_LOCATION,
  ACTION_CREATE_DRAFT,
  ACTION_COPY_CONTENT_FROM_ITEM
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_WARNING,
  ACTION_NOTIFICATIONS_INFO
} from "@/store_constants/notifications"
import { getContentId, getType, getLibraryId } from "../../../utils/dbTool"
import deepEqual from "@/utils/deepEqual"

export default {
  name: "ContentEditor",
  props: ["id"],
  components: {},
  data() {
    return {
      component: Loading,
      Extensions,
      view: false,
      isPreview: false,
      mode: "edit"
    }
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      canEdit: STATE_CAN_EDIT
    }),
    ...mapState(STORE_MODULE_EDITOR, {
      itemIsEdited: STATE_ITEM_IS_EDITED
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_MODULE_COMMENTS, {
      current_location: STATE_CURRENT_LOCATION
    }),
    ...mapGetters(STORE_MODULE_COMMENTS, { comments: GETTER_COMMENTS })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_DRAFT]),
    ...mapActions(STORE_MODULE_EDITOR, {
      updateIsEdited: ACTION_UPDATE_IS_EDITED,
      createDraft: ACTION_CREATE_DRAFT,
      copyContentFromItem: ACTION_COPY_CONTENT_FROM_ITEM
    }),
    ...mapActions(STORE_MODULE_COMMENTS, [ACTION_SAVE_CURRENT_LOCATION]),
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      warning: ACTION_NOTIFICATIONS_WARNING,
      info: ACTION_NOTIFICATIONS_INFO
    }),
    async createHotfixDraft(itemRevision) {
      const libraryId = getLibraryId(itemRevision) || this.settings._id
      const draftHotfix = await this.createDraft({ itemRevision, libraryId })
      if (draftHotfix) {
        this.info({
          message: "Draft correctly created. Reassigning workflow and roles... Opening draft in new tab..."
        })
      } else {
        this.warning({
          message: "Draft already exists for selected content. Opening draft in new tab..."
        })
      }
      window.open(`/content/${libraryId}/${getContentId(itemRevision)}/draft`)
    },
    createDraftFromItem(draftId, title, custom_id, path_id, type = "guide") {
      if (path_id == "$root") path_id = undefined
      this.copyContentFromItem({ draftId, title, custom_id, path_id, type })
        .then(res => {
          if (res.header) {
            window.open(`/content/${this.settings._id}/${getContentId(res)}/draft`)
          }
        })
        .catch(e => console.log(e))
    },
    getType: getType
  },
  created() {
    this[ACTION_LOAD_DRAFT]({ item_id: this.id, mode: "edit" }).then(() => {
      if (!this.itemRevision) return
      const content_type = this.getType(this.itemRevision)
      // Guide and lesson plan contents placed inside app
      Extensions["guide"] = guideItemViewer
      Extensions["guideTemplate"] = guideTemplateItemViewer
      Extensions["lessonPlan"] = lessonPlanViewer
      Extensions["interactiveSlide"] = interactiveSlideViewer
      Extensions["interactiveText"] = interactiveTextViewer
      Extensions["interactiveBook"] = interactiveBookViewer
      if (Extensions[content_type] && Extensions[content_type]["Editor"]) {
        switch (content_type) {
          case "guide":
            this.component = this.canEdit && !this.isPreview ? guideItemViewer["Editor"] : guideItemViewer["Viewer"]
            break
          case "guideTemplate":
            this.component =
              this.canEdit && !this.isPreview ? guideTemplateItemViewer["Editor"] : guideTemplateItemViewer["Viewer"]
            break
          case "lessonPlan":
            this.mode = this.canEdit ? "edit" : "review"
            this.component = lessonPlanViewer["Viewer"]
            break

          case "interactiveSlide":
            this.mode = this.canEdit ? "edit" : "review"
            this.component = interactiveSlideViewer["Viewer"]
            break
          case "interactiveText":
            this.mode = this.canEdit ? "edit" : "review"
            this.component = interactiveTextViewer["Viewer"]
            break
          case "interactiveBook":
            this.mode = this.canEdit ? "edit" : "review"
            this.component = interactiveBookViewer["Viewer"]
            break
          default:
            this.component = this.canEdit && !this.isPreview ? Extensions[content_type]["Editor"] : ItemViewer
            break
        }
        var { title, custom_id, content_revision_type } = this.itemRevision.header.metadata
        changeDocumentTitleAndIcon(`*[${custom_id}] ${title}`, Extensions[content_revision_type].icon)
      } else {
        this.component = {
          template: `<div>Editor for the extension ${content_type} not found.</div>`
        }
      }
    })
    this[ACTION_SAVE_CURRENT_LOCATION](null)
  },
  watch: {
    itemRevision: {
      deep: true,
      handler() {
        if (this.itemRevision) this.updateIsEdited()
      }
    },
    view: {
      deep: true,
      handler() {
        if (deepEqual(this.current_location, this.view)) return
        this[ACTION_SAVE_CURRENT_LOCATION](this.view)
      }
    },
    current_location: {
      deep: true,
      handler() {
        if (deepEqual(this.current_location, this.view)) return
        this.view = this.current_location
      }
    }
  }
}
</script>
