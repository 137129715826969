<template>
  <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Objective's activities</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :headers="headers" :items="activities" :pagination.sync="pagination" select-all class="elevation-1">
        <template v-slot:headers="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : ''
              ]"
              @click="changeSort(header.value)">
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <td class="text-center">
            <content-type-icon height="40px" :type="props.item.question.content_revision_type" />
          </td>
          <td class="text-center">
            <div v-html="props.item.question.text"></div>
          </td>
          <td class="text-center">
            <v-icon v-if="props.item.isCorrect" medium color="success"> mdi-check-circle-outline </v-icon>
            <v-icon v-else medium color="red"> mdi-close-circle-outline </v-icon>
          </td>
          <td class="text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon medium @click="goToFeedback(props.item)" v-on="on"> mdi-open-in-new </v-icon>
              </template>
              <span>Navigate to question's feedback</span>
            </v-tooltip>
          </td>
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <p class="mt-3">No activities found</p>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import { ContentTypeIcon } from "plant-common"

export default {
  name: "AnswersModal",
  components: { ContentTypeIcon },
  props: {
    activities: Array
  },
  data() {
    return {
      dialog: true,
      headers: [
        {
          text: "Content Revision Type",
          value: "question.content_revision_type"
        },
        { text: "Question", value: "question.text" },
        { text: "Assessment", value: "isCorrect" },
        { text: "Feedback", value: "id", sortable: false }
      ],
      pagination: {
        sortBy: "name",
        rowsPerPage: 10 // -1 for All",
      }
    }
  },
  computed: {},
  beforeMount() {
    this.$options.components.ContentTypeIcon = ContentTypeIcon
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    goToFeedback(activity) {
      this.$emit("input", activity.id)
    }
  }
}
</script>

<style scoped>
</style>
