export default {
    "status": {
        type: "list",
        format: ["FLOW", "NO FLOW"],
        bind: {
            to: "attr",
            name: "data-status"
        },

    }
}