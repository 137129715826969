
import parseBoolean from "./config/parseBoolean"
import "./pt-logic-input-bistable.css"

export default {
  model: {
    "value": {
      type: "boolean",
      default: false
    }
  },
  listeners: {
    click() {
      this.data.value = !this.data.value 
      return true
    }
  }
}
