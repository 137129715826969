import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "video",
    title: "Video",
    icon: require("./content-icon.svg"),
    category: "multimedia",
    Viewer,
    Editor,
    Preview: Viewer
}