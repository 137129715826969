<template>
  <p-panel-properties-group title="Breadcrumb">
    <p class="lp-block-tabs-panel">Normal:</p>
    <style-select :value="value.data.style.normal" title="Normal" />
    <p class="lp-block-tabs-panel mt-3">Link:</p>
    <style-select :value="value.data.style.link" title="Link" />
    <p class="lp-block-tabs-panel mt-3">Home:</p>
    <style-select :value="value.data.style.home" title="Home" :options="homeIcons" />
    <p class="lp-block-tabs-panel mt-3">Divider:</p>
    <style-select :value="value.data.style.divider" title="Divider" :options="dividerIcons" />

    <p-property-tile icon="mdi-eye-off-outline" title="Hide current slide title">
      <v-switch v-model="value.data.hideLastPathSection" hide-details></v-switch>
    </p-property-tile>
    <p-property-tile class="mb-5" icon="mdi-folder-eye-outline" title="Show hidden sections">
      <v-switch v-model="value.data.showHiddenSections" hide-details></v-switch>
    </p-property-tile>
  </p-panel-properties-group>
</template>
<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputSelect from "../_components/utils/PPanelInputSelect.vue"
import StyleSelect from "../../components/slides/styles/StyleSelect.vue"

export default {
  inject: ["blockGlobals"],
  components: {
    PPanelPropertiesGroup,
    PPropertyTile,
    PPanelInputSelect,
    StyleSelect
  },
  props: ["value"],
  computed: {
    homeIcons() {
      return ["none", "mdi-home", "mdi-book-education-outline", "mdi-table-of-contents", "mdi-school-outline"]
    },
    dividerIcons() {
      return [
        "mdi-slash-forward",
        "mdi-arrow-right-thin",
        "mdi-arrow-right",
        "mdi-arrow-right-bold",
        "mdi-arrow-right-bold-outline",
        "mdi-chevron-right",
        "mdi-chevron-double-right",
        "mdi-menu-right",
        "mdi-circle-medium"
      ]
    }
  }
}
</script>
