<template>
  <p-dialog :value="value" max-width="fit-content">
    <template #toolbar>
      <v-toolbar-title v-if="success"> Content created successfully </v-toolbar-title>
      <v-toolbar-title v-else> Content error </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-layout row wrap id="newContentFinished-modal">
      <v-flex xs12 sm12 md12 d-inline-block>
        <v-card class="mb-1 newContentFinished-card">
          <div>
            <div v-if="success">
              <v-alert :value="true" type="success">
                New draft <b>{{ draftTitle }}</b> successfully created
              </v-alert>
              <div style="text-align: center" class="mt-2">
                <ContentTypeIcon height="90px" :type="draftType" />
              </div>
              <content-info :content="draft" />
            </div>
            <div v-else>
              <v-alert :value="true" type="error"> Something happend when trying to create the new draft </v-alert>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <template #actions>
      <v-layout row justify-space-between>
        <v-flex xs3 class="text-center">
          <v-btn @click="$emit('input', false)">Close</v-btn>
        </v-flex>
        <v-flex xs3 class="text-center">
          <v-btn @click="$emit('input', 'new')" color="success">Create other draft</v-btn>
        </v-flex>
        <v-flex xs3 v-if="success" class="text-center">
          <v-btn @click="$emit('input', 'open')" color="primary">Open</v-btn>
        </v-flex>
      </v-layout>
    </template>
  </p-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import { ContentTypeIcon, PDialog } from "plant-common"
import ContentInfo from "./ContentInfo.vue"
import { STORE_WORKFLOWS_NAME, GETTER_SELECTED_ITEM_REVISION_DRAFT } from "@/store_constants/workflows"
import { getTitle, getType } from "../../../../utils/dbTool"

export default {
  components: { ContentTypeIcon, ContentInfo, PDialog },
  props: {
    value: Boolean,
    success: Boolean
  },
  computed: {
    ...mapGetters(STORE_WORKFLOWS_NAME, {
      draft: GETTER_SELECTED_ITEM_REVISION_DRAFT
    }),
    draftTitle() {
      if (this.draft) {
        return getTitle(this.draft)
      } else {
        return "N/A"
      }
    },
    draftType() {
      if (this.draft) {
        return getType(this.draft)
      } else {
        return "N/A"
      }
    }
  }
}
</script>

<style>
#newContentFinished-modal {
  width: 65vw;
  max-width: 1024px;
  height: 60vh;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 6px 6px;
}

.newContentFinished-card {
  height: 55vh;
  overflow: scroll;
  box-shadow: none !important;
}

.data-headline {
  color: #585858;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
 