<template>
  <v-system-bar
    color="white"
    window
    app
    bottom
    status
    height="40px"
    style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
    class="__system_bar_class"
    v-if="isDesktop">
    <v-spacer></v-spacer>
    <img
      ref="logo"
      class="mr-2"
      :src="require('@/../assets/icon.svg')"
      height="18px"
      style="vertical-align: middle" /><span style="color: black">PLANT Desktop App</span>
    <v-spacer></v-spacer>

    <app-system-bar-window-actions />
  </v-system-bar>
</template>

<script>
import PtAbout from "../PtAbout"
import PtDesktopApi from "@/PtDesktopApi.js"
import AppSystemBarWindowActions from "./AppSystemBarWindowActions"
import { PtModal } from "plant-common"

export default {
  name: "AppSystemBar",
  mixins: [PtModal],
  data() {
    return {
      build_date: process.env.VUE_APP_BUILD
    }
  },
  computed: {
    isDesktop() {
      return typeof PtDesktopApi != "undefined"
    },
    appWindow() {
      if (typeof PtDesktopApi != "undefined") return PtDesktopApi.appWindow
      else
        return {
          isMaximized: () => true
        }
    },
    electronVersion() {
      return this.isDesktop ? navigator.userAgent.match(/Electron\/([\d\.]+\d+)/)[1] : false
    }
  },
  mounted() {
    const targetElectronVersion = "27.0.2"
    if (this.electronVersion && this.electronVersion !== targetElectronVersion) {
      this.$modal("info", {
        title: "New PLANT Desktop App available",
        message: `You are using an old version of PLANT Desktop App. We recommend you to <a href="https://docs.myplanttraining.com/books/diagram/page/prerequisites" target="_blank"> download the latest version</a> to use all new features.`,
        ok: {
          text: "Accept",
          color: "#2196f3",
          dark: true
        }
      })
    }
  },
  components: {
    AppSystemBarWindowActions,
    PtAbout
  },
  props: ["color"]
}
</script>

<style>
.__system_bar_class {
  -webkit-app-region: drag;
}
.clickable {
  -webkit-app-region: no-drag;
}
</style>
