 import { BlockBasic } from "../_models/models.js"
import defaultQuestionBlocks from "./defaultQuestionBlocks.js"
/**
 * A Block showing content from the libary customizable.
 */
export default class BlockLibraryContentCustom extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "flashCard"
    this.size.width = 1920
    this.size.height = 1080
    this.position.x = 0
    this.position.y = 0
    this.data = defaultQuestionBlocks()
    this.protection.move = true
    this.protection.resize = true
  }
}
