/**
 * A recorded or upload audio.
 */
import { BlockBasic } from "../_models/models.js"

/**
 * Large FlipCard with rich text for front and back sides
 */
export default class BlockFlipCard extends BlockBasic {
  constructor(block) {
    super(block)
    if (!block) {
      this.type = "flipCard"
      this.size.width = 700
      this.size.height = 400
      this.protection.type = "flipCard"
      this.data = {
        front: {
          html: "<p>Flip Card front</p>",
          backgroundColor: "#ffffffff",
          fontSize: 72,
          color: "#000000ff",
          textAlign: "center",
          alignItems: "center",
          fontFamily: false,
          padding: 0
        },
        back: {
          html: "<p>Flip Card back</p>",
          backgroundColor: "#ffffffff",
          fontSize: 72,
          color: "#000000ff",
          textAlign: "center",
          alignItems: "center",
          fontFamily: false,
          padding: 0
        },
        // "hover" or "click"
        trigger: "hover"
      }
    } else {
      Object.assign(this, block)
    }
  }
}
