<template>
  <p-content-container>
    <conversion :value="value" />
    <splitpanes class="default-theme">
      <EditorNavBar
        splitpanes-size="25"
        splitpanes-min="20"
        splitpanes-max="40"
        :sortable="true"
        v-on="$listeners"
        style="height: 100%" />
      <splitpanes splitpanes-size="75" class="default-theme" horizontal="horizontal">
        <v-card splitpanes-size="80" splitpanes-min="60" splitpanes-max="100" height="100%">
          <EditorCard />
        </v-card>
        <Properties splitpanes-size="20" splitpanes-min="0" splitpanes-max="40" :readonly="false" />
      </splitpanes>
    </splitpanes>
  </p-content-container>
</template>

<script>
import { PContentContainer } from "plant-common"
import GuideToolbarEdit from "./EditorToolbar"
import EditorNavBar from "./EditorNavBar"
import EditorCard from "../Common/Viewer"
import Properties from "../Common/Properties"
import guideController from "../GuideController"
import Splitpanes from "splitpanes"
import Conversion from "./Conversion/index"
import "splitpanes/dist/splitpanes.css"

export default {
  name: "GuideEditor",
  props: ["value", "view"],
  components: {
    GuideToolbarEdit,
    EditorCard,
    Properties,
    EditorNavBar,
    PContentContainer,
    Splitpanes,
    Conversion
  },
  data() {
    return {
      guideController: guideController(this)
    }
  },
  provide() {
    return {
      guideController: guideController(this)
    }
  }
}
</script>
<style>
#pane_0 {
  overflow: hidden !important;
}

.default-theme {
  height: calc(100vh - 85px);
}
</style>