<template>
	<v-menu :value="true" :position-x="x" :position-y="y" absolute offset-y @input="$emit('input', false)">
		<v-list dense>
			<v-list-tile v-for="action in actions" :key="action.name" @click="$emit('input', action.name)">
				<v-list-tile-action>
					<v-icon >{{action.icon}}</v-icon>
				</v-list-tile-action>
				<v-list-tile-content>
					<v-list-tile-title>{{action.title}}</v-list-tile-title>
				</v-list-tile-content>
			</v-list-tile>
		
		</v-list>
	</v-menu>
</template>

<script>
// Known Issue: do not close if click with right button in other part of the screen
export default {
	props: ["x", "y", "actions"]
}
</script>

<style>
</style>