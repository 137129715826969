import OrderedList from "@tiptap/extension-ordered-list";
import { mergeAttributes } from '@tiptap/core'

export const OrderListClass = OrderedList.extend({
    addAttributes() {
        return {
            listStyleType: {
                default: 'decimal',
                parseHTML: element => {
                    return element.style.listStyleType || 'decimal'
                },
            },
        }
    },

    renderHTML({ HTMLAttributes }) {
        const { start, listStyleType, ...attributesWithoutStart } = HTMLAttributes
        return start === 1
            ? ['ol', mergeAttributes(this.options.HTMLAttributes, { style: `list-style-type: ${listStyleType};` }, attributesWithoutStart), 0]
            : ['ol', mergeAttributes(this.options.HTMLAttributes, { style: `list-style-type: ${listStyleType};` }, HTMLAttributes), 0]
    },

    addCommands() {
        return {
            setListStyleType: (listStyle) => ({ commands }) => {
                return commands.updateAttributes("orderedList", {
                    listStyleType: listStyle
                })
            },
        }

    },
});
