import { $http } from "../config";

const Auth = {
	login(credentials) {
		localStorage.setItem('authToken', credentials.credentials.id_token);
		return $http.post('/auth/login', credentials).then(r => {
			this.token = r.data.token;
			return r.data
		}).catch(e => e)
	},
	logout() {
		this.token = false
	},
};
export default Auth;
