<template>
  <v-list-tile :class="{ extended: extended, 'extended-height': extended }">
    <v-list-tile-avatar v-if="icon">
      <v-icon :color="iconColor">{{ icon }}</v-icon>
    </v-list-tile-avatar>
    <v-list-tile-content>
      <v-tooltip v-if="showTitleTooltip" right max-width="500">
        <template v-slot:activator="{ on }">
          <v-list-tile-title v-on="on">{{ title }}</v-list-tile-title>
        </template>
        <span>
          <p-rich-text :value="title" :canEdit="false" min-height="fit-content" padding="0px" />
        </span>
      </v-tooltip>
      <div v-else>
        <v-tooltip v-if="showTitleAndSubtitleTooltip" top max-width="500">
          <template v-slot:activator="{ on }">
            <v-list-tile-title v-on="on">{{ title }}</v-list-tile-title>
          </template>
          <span>
            <p-rich-text :value="title" :canEdit="false" min-height="fit-content" padding="0px" />
          </span>
        </v-tooltip>
        <v-list-tile-title v-else>{{ title }}</v-list-tile-title>
        <v-tooltip right max-width="500">
          <template v-slot:activator="{ on }">
            <v-list-tile-sub-title v-if="subTitle" v-on="on">
              <div>
                <p-rich-text :value="subTitle" :canEdit="false" min-height="fit-content" padding="0px" />
              </div>
            </v-list-tile-sub-title>
          </template>
          <span>
            <p-rich-text :value="title + ': ' + subTitle" :canEdit="false" min-height="fit-content" padding="0px" />
          </span>
        </v-tooltip>
      </div>
      <slot name="content" />
    </v-list-tile-content>
    <v-list-tile-action :style="{ overflow: 'hidden', height, 'max-width': maxWidth }">
      <slot />
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
export default {
  components: { PRichText: () => import("plant-common/src/components/PRichText/index.vue") },
  props: {
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    icon: {
      type: String
    },
    iconColor: {
      type: String
    },
    showTitleTooltip: {
      type: Boolean
    },
    showTitleAndSubtitleTooltip: {
      type: Boolean
    },
    height: {
      type: String,
      default: "36px"
    },
    extended: {
      type: Boolean
    },
    maxWidth: {
      type: String,
      default: "60%"
    }
  }
}
</script>

<style></style>
