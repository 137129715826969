<template>
  <basic-block :value="value" :mode="mode" v-if="showQuestion">
    <template v-slot:main>
      <portal :to="`contentMultipleChoiceQuestion-toolbar-${value.id}`" v-if="mode === 'edit'">
        <toolbar-library-revision
          :value="value"
          :currentRevision="currentRevision"
          :latestRevision="latestRevision"
          v-if="isContentSelected"
          @updateSlideLatestRevision="updateSlideLatestRevision"
          :disabled="changeContentProtection"
          :dark="blockController.dark" />
        <block-toolbar-button
          icon="mdi-arrow-expand-right"
          tooltip="Create minor version"
          @click="createMinorVersion"
          v-if="isContentSelected"
          :dark="blockController.dark" />
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-image-off-outline"
          tooltip="Change Content"
          @click="changeContent"
          :disabled="changeContentProtection">
          Change Content
        </p-toolbar-button>
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-restore"
          tooltip="Reset to default styles"
          @click="resetToDefaultStyles"
          :disabled="changeContentProtection">
          Reset to default styles
        </p-toolbar-button>
        <p-toolbar-menu v-if="isContentSelected" icon="mdi-layers-outline" title="Layers" tooltip="Display layers">
          <v-list dense>
            <v-list-tile @click="displayQuestion()">
              <v-list-tile-action>
                <v-icon>mdi-border-all-variant</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Question</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('correct')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Popup when Correct</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('incorrect')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Popup when Incorrect</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </p-toolbar-menu>
      </portal>
      <template v-if="mode === 'edit'">
        <div class="lp-block-library-content">
          <v-btn color="primary" @click="addFromContentManager" v-if="!isContentSelected" large outline>
            Add from Content Manager
          </v-btn>
          <template v-else>
            <block-subcanvas :value="value" :blocks="value.data.blocks" :mode="mode" :blockVariables="blockVariables" />
          </template>
        </div>
      </template>
      <template v-else>
        <block-subcanvas
					v-if="value.data.content_ref.$origin"
          :blocks="value.data.blocks"
          mode="view"
          :blockVariables="blockVariables"
          :previousResponse="previousResponse" />
      </template>
    </template>
  </basic-block>
  <div v-else class="lp-slide answer-quiz">
    <div
      class="question-quiz mh-14 overflowAuto"
      v-html="lessonPlanController.answerHandler.data.response.question"></div>

    <div
      class="mh-65 overflow-y-auto"
      :class="{
        succeded: lessonPlanController.answerHandler.data.success,
        errored: !lessonPlanController.answerHandler.data.success
      }">
      <p>Student Answer:</p>

      <div class="student-answer">
        <span v-if="lessonPlanController.answerHandler.data.success" class="answer-icon">✅</span>
        <span v-else class="answer-icon">❌</span>

        <span v-html="lessonPlanController.answerHandler.data.response.userChoice"></span>
      </div>

      <p>
        Correct Answer:
        <b v-html="lessonPlanController.answerHandler.data.response.correctAnswer"></b>
      </p>
      <p>Explanation: <span v-html="lessonPlanController.answerHandler.data.response.explanation"></span></p>
    </div>

    <div class="mh-20 py-4 overflowAuto">
      <div class="mb-2">
        <span>Objective:</span>
        <v-chip label dark style="font-size: 24px">
          <v-icon>mdi-tag</v-icon>
          {{ lessonPlanController.answerHandler.data.objCustomId }}
        </v-chip>
        {{ lessonPlanController.answerHandler.data.objTitle }}
      </div>
      <div>
        <span>
          Interactive Presentation Sections: <b>{{ lessonPlanController.answerHandler.data.sections }}</b>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { PToolbarButton, PToolbarMenu } from "plant-common"
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockOverlay from "../_components/containers/BlockOverlay.vue"
import BlockSubcanvas from "../_components/containers/BlockSubcanvas.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import blockOverlayCommon from "../../blocks/_mixins/blockOverlayCommon.vue"
import defaultQuestionBlocks from "./defaultQuestionBlocks.js"
import ToolbarLibraryRevision from "../libraryContent/ToolbarLibraryRevision.vue"
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import { getCustomId } from "../../../../utils/dbTool.js"
import ptApiClient from "plant-api-client"
import _ from "lodash"
import { orderChoices } from "../../../../../../plant-common/src/components/plant-item-viewer/activity-multiple-choice/helpers"

export default {
  name: "BlockContentMultipleChoiceQuestion",
  inject: ["blockEventBus", "blockController", "lessonPlanController"],
  mixins: [blockOverlayCommon],
  components: {
    PToolbarButton,
    PToolbarMenu,
    BasicBlock,
    BlockSubcanvas,
    BlockOverlay,
    ToolbarLibraryRevision,
    BlockToolbarButton
  },
  props: ["value", "mode"],
  data() {
    return {
      correctChoice: 0,
      blockVariables: false,
      currentRevision: this.getCurrentRevision(),
      latestRevision: this.getLatestRevision()
    }
  },
  created() {
    if (this.isContentSelected) this.addVariables()
    if (this.blockEventBus) this.blockEventBus.$on("submit", this.submitted)
  },
  beforeDestroy() {
    if (this.blockEventBus) this.blockEventBus.$off("submit", this.submitted)
    if (this.correctOverlay && !_.isEmpty(this.blockController)) {
      this.blockController.$off(`state-changed:${this.correctOverlay.id}`)
      this.correctOverlay.blocks.forEach(correctBlock => {
        this.blockController.$off(`state-changed:${correctBlock.id}`)
      })
    }
    if (this.incorrectOverlay && !_.isEmpty(this.blockController)) {
      this.blockController.$off(`state-changed:${this.incorrectOverlay.id}`)
      this.incorrectOverlay.blocks.forEach(incorrectBlock => {
        this.blockController.$off(`state-changed:${incorrectBlock.id}`)
      })
    }
  },
  computed: {
    isContentSelected() {
      return Object.keys(this.value.data.content_ref).length > 0
    },
    correctOverlay() {
      return this.value.data.overlays.correct
    },
    incorrectOverlay() {
      return this.value.data.overlays.incorrect
    },
    showQuestion() {
      let mustShowQuestion = true
      const parentQuiz = this.lessonPlanController.getParentQuiz(this.lessonPlanController.selectedSlide)
      if (parentQuiz) {
        mustShowQuestion = !this.lessonPlanController.isQuizSubmitted(parentQuiz)
      }
      return mustShowQuestion
    },
    previousResponse() {
      if (this.lessonPlanController.answerHandler && this.lessonPlanController.answerHandler.active) {
        return this.lessonPlanController.answerHandler.data.response.userChoice
      } else {
        return false
      }
    },
    changeContentProtection() {
      return this.value.protection.data
    }
  },
  watch: {
    "value.data.content_ref": {
      immediate: true,
      deep: true,
      handler() {
        this.addVariables()
      }
    },
    "blockController.overlay": {
      immediate: true,
      handler() {
        if (!this.blockController.overlay && !_.isEmpty(this.blockController)) {
          this.blockController.$emit("overlayActive", { type: null, overlay: null })
        }
      }
    },
    "correctOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.correctOverlay && !_.isEmpty(this.blockController)) {
          this.blockController.$on(`state-changed:${this.correctOverlay.id}`, state => {
            if (state === "primary-selected") {
              this.displayOverlay("correct")
            }
          })
          // if correct in overlay have blocks, listen to each block state-change
          this.correctOverlay.blocks.forEach(correctBlock => {
            this.blockController.$on(`state-changed:${correctBlock.id}`, state => {
              if (state === "primary-selected") {
                this.displayOverlay("correct")
              }
            })
          })
        }
      }
    },
    "incorrectOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.incorrectOverlay && !_.isEmpty(this.blockController)) {
          this.blockController.$on(`state-changed:${this.incorrectOverlay.id}`, state => {
            if (state === "primary-selected") {
              this.displayOverlay("incorrect")
            }
          })
          // if correct in overlay have blocks, listen to each block state-change
          this.incorrectOverlay.blocks.forEach(incorrectBlock => {
            this.blockController.$on(`state-changed:${incorrectBlock.id}`, state => {
              if (state === "primary-selected") {
                this.displayOverlay("incorrect")
              }
            })
          })
        }
      }
    }
  },
  methods: {
    submitted() {
      const userChoice = this.blockVariables.find(variable => variable.name === "QuestionUserChoice")
      if (userChoice && userChoice.value !== undefined) {
        if (userChoice.value === this.correctChoice) {
          this.blockController.overlay = this.value.data.overlays.correct
        } else {
          this.blockController.overlay = this.value.data.overlays.incorrect
        }
        this.blockController.overlayVariables = this.blockVariables

        // Update adaptive learning result if necessary
        const slide = this.lessonPlanController.getSlideFromBlock(this.value)
        if (this.lessonPlanController.activityState && slide) {
          const questionChoices = this.blockVariables.find(variable => variable.name === "QuestionChoices")
          const response = {
            correctAnswer: questionChoices.value[this.correctChoice],
            userChoice: questionChoices.value[userChoice.value],
            question: this.value.data.content_ref.body.contents.question,
            explanation: this.value.data.content_ref.body.contents.explanation
          }
          this.lessonPlanController.updateActivityResponse(slide, userChoice.value == this.correctChoice, response)
        }
      }
    },
    addVariables() {
      if (this.value.data.content_ref.body) {
        const { answer, distractors, position } = this.value.data.content_ref.body.contents
        const pictureUrl = this.value.data.content_ref.body.attachments.length
          ? this.value.data.content_ref.body.attachments[0].url
          : ""
        const choices = orderChoices(answer, distractors, position)
        this.correctChoice = choices.findIndex(c => c === answer)
        this.blockVariables = [
          {
            name: "ContentHeaderMetadataTitle",
            type: "string",
            value: this.value.data.content_ref.header.metadata.title,
            title: "Content Title"
          },
          {
            name: "ContentHeaderMetadataCustomId",
            type: "string",
            value: this.value.data.content_ref.header.metadata.custom_id,
            title: "Content Custom ID"
          },
          {
            name: "QuestionQuestion",
            type: "string",
            value: this.value.data.content_ref.body.contents.question,
            title: "Question"
          },
          {
            name: "QuestionChoices",
            type: "array",
            value: choices,
            title: "Choices"
          },
          {
            name: "QuestionUserChoice",
            type: "number",
            value: undefined,
            title: "User Choice"
          },
          {
            name: "QuestionAnswerExplanation",
            type: "string",
            value: this.value.data.content_ref.body.contents.explanation,
            title: "Answer Explanation"
          },
          {
            name: "QuestionAnswer",
            type: "string",
            value: this.value.data.content_ref.body.contents.answer,
            title: "Answer"
          },
          {
            name: "QuestionImageUrl",
            type: "string",
            value: pictureUrl,
            title: "Picture"
          }
        ]
      }
    },
    async addFromContentManager() {
      const newContentRef = await PServiceContent.selectContent({ type: "activityMultipleChoice" })
      if (!newContentRef) return
      this.value.data.content_ref = newContentRef
      this.addVariables()
    },
    changeContent() {
      this.value.data.content_ref = {}
    },
    resetToDefaultStyles() {
      const originalContent_ref = this.value.data.content_ref
      this.value.data = defaultQuestionBlocks()
      this.value.data.content_ref = originalContent_ref
    },
    displayQuestion() {
      this.closeOverlay()
      this.blockController.overlayVariables = null
      this.blockController.$emit("overlayActive", { type: null, overlay: null })
    },
    displayOverlay(type) {
      this.blockController.overlay = this.value.data.overlays[type]
      this.blockController.overlay.parent = this.value.id
      this.blockController.overlayVariables = this.blockVariables
      this.blockController.$emit("overlayActive", { type: type, overlay: this.value.data.overlays[type] })
    },
    getCurrentRevision() {
      if (!this.value.data || !this.value.data.content_ref) return ""
      const header = this.value.data.content_ref.header
      if (!header) return ""
      return `${header.major_revision}.${header.minor_revision}`
    },
    getLatestRevision() {
      return this.value.data && this.value.data.content_ref ? this.value.data.content_ref.__latest : null
    },
    async updateSlideLatestRevision() {
      const customId = getCustomId(this.value.data.content_ref)

      let last_content = await ptApiClient.getContentByCustomId(customId)
      last_content.__latest = `${last_content.header.major_revision}.${last_content.header.minor_revision}`
      this.value.data.content_ref = last_content
      this.currentRevision = this.getCurrentRevision()
    },
    createMinorVersion() {
      if (this.value && this.value.data.content_ref) {
        this.$emit("create:draft", this.value.data.content_ref)
      }
    }
  }
}
</script>

<style scoped>
.lp-block-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: unset;
  position: relative;
}
.overflowAuto {
  overflow: auto;
}

.mh-14 {
  max-height: 14%;
}

.mh-65 {
  max-height: 65%;
}

.mh-20 {
  max-height: 20%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.question-quiz {
  font-size: 30px;
  padding-bottom: 24px;
}

.student-answer {
  display: flex;
  flex-direction: column;
  flex-direction: row;
}

.errored {
  background: #ff000080;
  padding: 20px;
  border-radius: 25px;
}

.succeded {
  background: #00ff0080;
  padding: 20px;
  border-radius: 25px;
}

.answer-quiz {
  font-size: 30px;
  padding: 40px;
}

.answer-icon {
  text-align: center;
  font-size: 60px;
  flex: 0 1 auto;
  margin-right: 10px;
}
</style>
../_mixins/blockOverlayCommon.vue