<template>
  <span style="text-align: center; font-size: 21px">
    <v-btn color="primary" block outline @click.stop="downloadXlsxTemplate()">
      <v-icon left>mdi-download</v-icon>Download XLSX template
    </v-btn>
  </span>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "BulkChangeCustomIdDownloadTemplate",
  methods: {
    downloadXlsxTemplate() {
      var wb = XLSX.utils.book_new();
      var columns = ["guideCustomId", "templateCustomId"];
      var ws_import_data = XLSX.utils.aoa_to_sheet([columns]);
      XLSX.utils.book_append_sheet(wb, ws_import_data, "LIST");
      var ws_import_config = XLSX.utils.json_to_sheet([{ type: "guide-to-presentation" }]);
      ws_import_config["!protect"] = { password: "PLANT-IMPORT" };
      XLSX.utils.book_append_sheet(wb, ws_import_config, "IMPORT-CONFIG");
      XLSX.writeFile(wb, "guide-to-presentation.xlsx");
    }
  }
};
</script>
