<template>
  <v-tooltip right v-if="is_latest != undefined && !is_latest && !is_student">
    <template v-slot:activator="{ on }">
      <v-icon color="warning" v-on="on">mdi-alert</v-icon>
    </template>
    <span>There is a newer revision of this content.</span>
  </v-tooltip>
</template>

<script>
import { mapState } from "vuex";
import { mapInjectedData } from "plant-common/src/utils";
import { roles } from "../../../../config/constants";
import {
  STORE_USER_NAME,
  STATE_PROFILE
} from "../../../../store_constants/user";
import { getContentId, getCustomId } from "../../../../utils/dbTool";

export default {
  props: {
    value: Object //card
  },
  inject: ["guideController"],
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"]),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    is_student() {
      return this.profile.role == roles.student.value;
    },
    is_latest() {
      var is_latest;
      if (!this.value.content) is_latest = undefined;
      else {
        var latest_revision =
          this.guideController.latests_revisions[
            getCustomId(this.value.content)
          ];
        var current_revision = `${this.value.content.header.major_revision}.${this.value.content.header.minor_revision}`;

        is_latest = current_revision == latest_revision;
      }
      return is_latest;
    }
  }
};
</script>

<style>
</style>