<template>
  <p-panel-properties-group title="On hover">
    <p-property-tile title="Action">
      <v-select
        box
        hide-details
        single-line
        :items="availableActions"
        :value="hoverAction.type"
        label="Actions"
        @change="createUpdateAction"></v-select>
    </p-property-tile>
    <template v-if="hoverAction && hoverAction.type == 'tooltip'">
      <p-property-tile title="Tooltip Text">
        <v-text-field hide-details single-line box v-model="hoverAction.text"></v-text-field>
      </p-property-tile>
      <p-property-tile title="Tooltip Position">
        <v-select
          box
          hide-details
          single-line
          :items="availableTooltipPositions"
          v-model="hoverAction.position"
          label="Position"></v-select>
      </p-property-tile>
    </template>
  </p-panel-properties-group>
</template>

<script>
import PPanelPropertiesGroup from "../../utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../../utils/PPropertyTile.vue"
import { BlockActionTooltip } from "./blockActionClass.js"

export default {
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelPropertiesGroup
  },
  data: () => ({
    availableActions: [
      {
        text: "No Action",
        value: "none"
      },
      {
        text: "Show Tooltip",
        value: "tooltip"
      }
    ],
    availableTooltipPositions: [
      {
        text: "Top",
        value: "top"
      },
      {
        text: "Bottom",
        value: "bottom"
      },
      {
        text: "Left",
        value: "left"
      },
      {
        text: "Right",
        value: "right"
      }
    ]
  }),
  computed: {
    hoverAction() {
      var actions = this.value.data.actions || this.value.actions
      var hoverAction = actions ? actions.find(action => action.trigger == "hover") : false
      return hoverAction || { type: "none" }
    }
  },
  methods: {
    createUpdateAction(type) {
      var actions = this.value.data.actions || this.value.actions
      if (type == "none") {
        var index = actions.findIndex(action => action.trigger == "hover")
        actions.splice(index, 1)
      } else if (type === "tooltip") {
        var action = new BlockActionTooltip({
          trigger: "hover"
        })
        actions.push(action)
      }
    }
  }
}
</script>
