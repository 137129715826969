<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">Confirmation step</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12>
              <p>Are you sure you want to recalculate all contents and relations? Enter the code below to confirm:</p>
              <p>
                <b>{{ code }}</b>
              </p>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                label="Code"
                onpaste="return false;"
                ondrop="return false;"
                required
                v-model="userCode"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="darken-1" flat @click="closeModal">Close</v-btn>
        <v-btn color="blue darken-1" flat @click="confirmRecalculation" :disabled="!recalculateAvailable"
          >Recalculate</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RecalculateConfirmationModal",
  props: ["dialog"],
  data() {
    return {
      code: "",
      userCode: ""
    }
  },
  computed: {
    recalculateAvailable() {
      return this.userCode === this.code
    }
  },
  mounted() {
    this.code = this.generateCode()
  },
  methods: {
    confirmRecalculation() {
      if (this.recalculateAvailable) {
        this.$emit("recalculate")
      }
      this.closeModal()
    },
    closeModal() {
      this.$emit("close")
      this.userCode = ""
      this.code = this.generateCode()
    },
    generateCode() {
      return Math.random().toString(36).substring(2, 8)
    }
  }
}
</script>