<template>
  <v-layout row justify-center>
    <v-dialog :value="true" max-width="50%" persistent scrollable>
      <v-card>
        <v-card-title class="headline">
          Select Slide

          <v-spacer></v-spacer>

          <v-btn flat icon @click="$emit('input')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pt-0">
          <v-text-field
            outline
            hide-details
            label="Search"
            prepend-inner-icon="search"
            v-model="inputSearch"></v-text-field>
        </v-card-text>

        <v-card-text style="height: 400px">
          <v-layout row>
            <v-flex>
              <v-card>
                <v-list>
                  <v-list-tile
                    class="slide-row"
                    :class="{ 'selected-slide': selectedSlide && selectedSlide.id === slide.id }"
                    v-for="slide in slides"
                    :key="slide.id"
                    :style="slideRowStyle(slide)"
                    @click="selectedSlide = slide"
                    avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>
                        <v-icon v-if="slide.type && slide.type === 'Section'" color="blue">folder</v-icon>
                        <v-icon v-else-if="slide.type && slide.type === 'QuizSlide'" color="blue"
                          >mdi-book-education-outline</v-icon
                        >
                        <v-icon v-else-if="slide.type && slide.type === 'LearningObject'" color="blue"
                          >mdi-book-open-page-variant-outline</v-icon
                        >
                        {{ slide.title }}
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" flat="flat" @click="$emit('input', false)"> Cancel </v-btn>

          <v-btn :disabled="!selectedSlide" color="green darken-1" flat="flat" @click="$emit('input', selectedSlide)">
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: "SlideSelector",

  data() {
    return {
      inputSearch: "",
      selectedSlide: null
    }
  },

  props: ["lessonPlanController"],

  computed: {
    slides() {
      if (this.inputSearch === "") return this.lessonPlanController.slideArray

      return this.lessonPlanController.slideArray.filter(slide =>
        slide.title.toLowerCase().includes(this.inputSearch.toLowerCase())
      )
    }
  },

  created() {
    if (this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home) {
      this.selectedSlide = this.lessonPlanController.slideArray.find(
        slide => slide.id === this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home.slide_id
      )
    }

    if (!this.selectedSlide && this.lessonPlanController.slideArray.length) {
      this.selectedSlide = this.lessonPlanController.slideArray[0]
    }
  },

  methods: {
    slideRowStyle(slide) {
      const basePaddingLeft = 16
      return slide.path.length > 1 ? `padding-left: ${slide.path.length * basePaddingLeft}px` : ""
    }
  }
}
</script>

<style scoped>
.slide-row {
  cursor: pointer;
}

.slide-row:hover {
  background-color: lightgrey;
}

.selected-slide {
  background-color: lightgrey;
}
</style>
