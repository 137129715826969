<template>
  <p-panel-input-select
    :items="paddings"
    v-model="padding"
    item-text="text"
    :return-object="true"></p-panel-input-select>
</template>

<script>
import { changePadding } from "./utils.js"
import PPanelInputSelect from "../_components/utils/PPanelInputSelect.vue"
export default {
  props: ["data", "side", "id"],
  data: () => ({
    padding: null,
    paddingNumbers: [0, 10, 20, 30, 40, 50, 60]
  }),
  components: {
    PPanelInputSelect
  },
  computed: {
    paddings() {
      return this.paddingNumbers.map(padding => {
        return { text: `${padding}px`, id: padding }
      })
    }
  },
  watch: {
    padding() {
      changePadding(this.id, this.padding.id, this.side)
      this.savePadding(this.padding)
    }
  },
  mounted() {
    this.padding = {
      id: this.data.padding,
      text: `${this.data.padding}px`
    }
  },
  methods: {
    savePadding(padding) {
      this.data.padding = padding.id
    }
  }
}
</script>

