<template>
  <div class="lp-notes-panel" style="height: 100%">
    <div
      v-if="lessonPlanController.selectedSlide"
      v-bind:class="[isDraft && isAuthor ? 'notes-scroll-editor' : 'notes-scroll-viewer']">
      <p-rich-text
        v-model="lessonPlanController.selectedSlide.notes.speaker"
        :canEdit="isAuthor"
        fixed-toolbar
        placeholder="Write speaker notes..." />
    </div>
    <div v-else>
      <p class="lp-right-panel-tile-title">No slide selected</p>
    </div>
  </div>
</template>

<script>
import { PRichText } from "plant-common"

export default {
  inject: ["lessonPlanController"],
  components: { PRichText },
  computed: {
    isDraft() {
      if (this.$router) {
        let path = this.$router.app.$route.fullPath.split("/")
        return path[path.length - 1] === "draft"
      } else {
        return false
      }
    },
    isAuthor() {
      return this.lessonPlanController.mode == "edit"
    }
  }
}
</script>

<style scoped>
.notes-scroll-editor {
  overflow: auto;
  height: calc(100vh - 230px);
}
.notes-scroll-viewer {
  overflow: auto;
  height: calc(100vh - 192px);
}
</style>
