<template>
  <p-panel-properties>
    <p-panel-properties-group icon="mdi-format-line-style" title="Style Sheet">
      <p-property-tile v-if="controller.mode === 'edit'" @click="addFromContentManager" title="Select Style Sheet">
        <span>
          <v-btn
            v-if="css && isCustomStyleSheet"
            small
            outline
            color="error"
            @click="resetDefaultStyleSheet"
            class="mr-2">
            Reset
          </v-btn>
          <v-btn small outline color="primary" @click="addFromContentManager">
            <span v-if="css">Change</span>
            <span v-else>Select</span>
          </v-btn>
        </span>
      </p-property-tile>

      <template v-if="isCustomStyleSheet">
        <p-property-tile title="Custom id">
          {{ css ? getCustomId(css) : "--" }}
        </p-property-tile>
        <p-property-tile title="Name">
          {{ css ? getTitle(css) : "--" }}
        </p-property-tile>
        <p-property-tile title="Revision">
          {{ css ? `${getMajorVersion(css)}.${getMinorVersion(css)}` : "--" }}
        </p-property-tile>
      </template>

      <template v-else>
        <p-property-tile title="Deafult Style Sheet Loaded"> </p-property-tile>
      </template>
    </p-panel-properties-group>
  </p-panel-properties>
</template>

<script>
import PPanelProperties from "../../lessonPlan/blocks/_components/utils/PPanelProperties.vue"
import PPropertyTile from "../../lessonPlan/blocks/_components/utils/PPropertyTile.vue"
import PPanel from "../../lessonPlan/blocks/_components/utils/PPanel.vue"
import PPanelPropertiesGroup from "../../lessonPlan/blocks/_components/utils/PPanelPropertiesGroup.vue"
import PPanelInputNumber from "../../lessonPlan/blocks/_components/utils/PPanelInputNumber.vue"
import { getCustomId, getTitle, getMajorVersion, getMinorVersion } from "../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "StyleSheetSettings",
  inject: ["controller"],
  components: {
    PPanelProperties,
    PPropertyTile,
    PPanel,
    PPanelPropertiesGroup,
    PPanelInputNumber
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    css() {
      return this.controller.vm.value.body.contents.settings.styles.css
    },
    isCustomStyleSheet() {
      return this.css && typeof this.css === "object"
    }
  },
  methods: {
    async addFromContentManager() {
      var styleSheet = await PServiceContent.selectContent({ type: "styleSheet" })
      if (!styleSheet) return
      this.controller.setStyleSheet(styleSheet)
    },
    getCustomId: getCustomId,
    getTitle: getTitle,
    getMajorVersion: getMajorVersion,
    getMinorVersion: getMinorVersion,
    resetDefaultStyleSheet() {
      this.controller.setStyleSheet()
    }
  }
}
</script>

<style scoped>
</style>