<template>
  <component
    v-bind:is="component"
    mode="activity"
    :value="value"
    ref="editor"
    :view.sync="view"
    :activityState.sync="activityState" />
</template>
<script>
import { Extensions } from "plant-common"
import { ItemViewer } from "plant-common"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveBookViewer from "@/components/interactiveBook"
import interactiveTextViewer from "@/components/interactiveText"
import { mapState } from "vuex"
import Loading from "../_layout/Loading"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { getType } from "../../../utils/dbTool"

export default {
  name: "ContentActivity",
  props: ["value"],
  components: {},
  data() {
    return {
      component: Loading,
      Extensions,
      view: false,
      activityState: undefined
    }
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    getType: getType,
    async goTo(custom_id) {
      if (getType(this.value) == "diagram") {
        window.open(`/content/${this.settings._id}/${encodeURIComponent(custom_id)}/latest`)
      }
    }
  },
  watch: {
    value: {
      immediate: false,
      handler() {
        if (!this.value) return
        const item_revision_type = this.getType(this.value)
        // Guide and lesson plan contents placed inside app
        Extensions["guide"] = guideItemViewer
        Extensions["guideTemplate"] = guideTemplateItemViewer
        Extensions["lessonPlan"] = lessonPlanViewer
        Extensions["interactiveSlide"] = interactiveSlideViewer
        Extensions["interactiveText"] = interactiveTextViewer
        Extensions["interactiveBook"] = interactiveBookViewer
        if (Extensions[item_revision_type]) {
          switch (item_revision_type) {
            case "guide":
              this.component = guideItemViewer["Viewer"]
              break
            case "guideTemplate":
              this.component = guideTemplateItemViewer["Viewer"]
              break
            case "lessonPlan":
              this.component = lessonPlanViewer["Viewer"]
              break
            case "interactiveSlide":
              this.component = interactiveSlideViewer["Viewer"]
              break
            case "interactiveText":
              this.component = interactiveTextViewer["Viewer"]
              break
            case "interactiveBook":
              this.component = interactiveBookViewer["Viewer"]
              break
            default:
              this.component = ItemViewer
              break
          }
        } else {
          this.component = {
            template: `<div>Editor for the extension ${item_revision_type} not found.</div>`
          }
        }
      }
    }
  }
}
</script>
<style scoped></style>
