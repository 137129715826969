import LoginPage from "./login/Content.vue"
import DashboardRoutes from "./dashboard/routes"
import ConfigurationControl from "./configuration-control/routes"
import BackgroundJobs from "./background-jobs/routes"
import ContentTools from "./content-tools/routes"
import ExplorerRoutes from "./explorer/routes"
import AuthorRoutes from "./workflow/routes"
import RecycleBinRoutes from "./recycle-bin/routes"
import NotificationsRoutes from "./notifications/routes"
import ContentRoutes from "./content/routes"
import SettingsRoutes from "./settings/routes"
import TrainingRoutes from "./training/routes.js"
import SideToolbarLayout from "@/layouts/SideToolbarLayout.vue"
import Present from "./present/index.vue"
import NotFound from "./notfound/Content.vue"
import CollectionRoutes from "./collection/routes"

export default [
  {
    path: "/present/:library_id/:content_id/:major_revision/:minor_revision",
    name: "Present",
    component: Present,
    props: true
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage
  },
  {
    path: "/",
    component: SideToolbarLayout,
    children: [
      {
        name: "PLANT",
        path: "",
        redirect: "dashboard"
      },
      ConfigurationControl,
      BackgroundJobs,
      ContentTools,
      NotificationsRoutes,
      DashboardRoutes,
      ExplorerRoutes,
      AuthorRoutes,
      RecycleBinRoutes,
      SettingsRoutes,
      TrainingRoutes,
      CollectionRoutes
    ]
  },
  {
    path: "/notfound",
    name: "Not Found",
    component: NotFound
  },
  ...ContentRoutes
]
