<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <svg
        class="shape-diamond"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M ${w / 2} 0
                L ${w} ${h / 2}
                L ${w / 2} ${h}
                L 0 ${h / 2}
                L ${w / 2} 0
              `"
            :style="value.data.style" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
export default {
  name: "BlockShapeRectangle",
  inject: ["blockController"],
  components: {
    BasicBlock
  },
  props: ["value", "mode"],
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    }
  }
}
</script>
