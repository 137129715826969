<template>
  <v-card-text class="content-grid-card-container">
    <ContentTypeIcon height="60px" :type="type" class="mr-3" />
    <div class="content-grid-card-document">
      <v-chip class="caption" label small>
        <v-icon left small>mdi-tag</v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ custom_id }}</span>
          </template>
          <span>{{ custom_id }}</span>
        </v-tooltip>
      </v-chip>
      <div class="subheading overflow-hidden font-weight-medium mt-2 mb-2" style="width: 100%; text-align: left">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{ title }}</span>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
      </div>
      <div class="caption font-italic lighten-2">{{ date }}</div>
    </div>
  </v-card-text>
</template>

<script>
import { ContentTypeIcon } from "plant-common"
export default {
  components: { ContentTypeIcon },
  props: {
    type: {
      type: String
    },
    title: {
      type: String
    },
    custom_id: {
      type: String
    },
    date: {
      type: String
    }
  }
}
</script>

<style scoped>
.content-grid-card-document {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80%;
  height: 85px;
  overflow: hidden;
}
.content-grid-card-container {
  display: flex;
  align-items: center;
}
.overflow-hidden {
  overflow: hidden;
}
</style>