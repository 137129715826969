<template>
  <v-autocomplete
    v-bind="$attrs"
    @change="payload => $emit('change', payload)"
    :items="users"
    :item-text="
      item =>
        item.disabled
          ? item.name + ' (disabled)'
          : usersDisabled.includes(item._id)
          ? item.name + ' (role already assigned)'
          : item.name
    "
    item-value="_id"
    :item-disabled="item => usersDisabled.includes(item._id) || item.disabled"
    :error="isError"
    clearable
    hide-no-data
    hide-selected
    prepend-icon="mdi-clipboard-account"
    append-icon="mdi-chevron-down"></v-autocomplete>
</template>

<script>
import PtApiClient from "plant-api-client"
export default {
  props: ["users-disabled"],
  data() {
    return {
      users: [],
      isError: false
    }
  },
  created() {
    PtApiClient.getAllUsers()
      .then(users => (this.users = users))
      .catch(e => {
        this.isError = true
        console.error(e)
      })
  }
}
</script>