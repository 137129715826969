<script>
export default {
  methods: {
    closeOverlay() {
      this.blockController.selectionClear()
      this.blockController.overlay = null
      this.blockController.overlayVariables = null
    }
  }
}
</script>