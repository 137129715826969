export function rereference(obj) {
    return rereferenceObject(JSON.parse(JSON.stringify(obj)))
}

export function rereferenceObject(obj) {
    if (obj == null) return obj
    if (obj.$origin) {
        if (isNotAFile(obj.$origin)) {
            return { $ref: obj.$origin, $id: 0 }
        } else {
            return { $ref: obj.$origin, $id: 0, data: clearObject(obj) }
        }
    }
    else if (typeof obj == 'object' && obj != null) {
        Object.keys(obj).forEach(k => obj[k] = rereference(obj[k]))
        return obj
    }
    else return obj
}

function isNotAFile(origin) {
    const regexFile = new RegExp("plant:/files")
    return !regexFile.test(origin)
}

function clearObject(obj) {
    const cleanObj = JSON.parse(JSON.stringify(obj))
    delete cleanObj.$origin
    delete cleanObj.$id
    delete cleanObj.$db
    return rereference(cleanObj)
}