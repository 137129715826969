<template>
  <p-panel title="Block Actions" v-if="selectedBlock">
    <template #toolbar>
      <v-toolbar-title class="subheading">
        Block Actions <span v-if="selectedBlock && selectedBlock.type">({{ selectedBlock.type }})</span>
      </v-toolbar-title>
    </template>
    <p-panel-properties>
      <panel-on-hover :value="selectedBlock" />
      <panel-on-click :value="selectedBlock" />
    </p-panel-properties>
  </p-panel>
</template>

<script>
import PPanelProperties from "../utils/PPanelProperties.vue"
import PPanel from "../utils/PPanel.vue"
import PanelOnHover from "./actions/PanelOnHover.vue"
import PanelOnClick from "./actions/PanelOnClick.vue"

export default {
  name: "BlockActions",
  props: ["selectedBlock"],
  components: {
    PPanelProperties,
    PPanel,
    PanelOnHover,
    PanelOnClick
  }
}
</script>

<style>
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
