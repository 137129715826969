import { getObjective } from "../../../../utils/dbTool"

// Performance level
const NOT_EVALUATED = "notEvaluated"
const NOT_PROFICIENT = "notProficient"
const PROFICIENT = "proficient"
const MASTER = "master"

export default {
  methods: {
    initPerformanceForQuiz(quiz) {
      const quizObjectivesMap = this.getActivitiesInQuiz(quiz)
      for (const [objectiveId, objective] of Object.entries(quizObjectivesMap)) {
        if (!this.activityState.performance[objectiveId]) {
          this.activityState.performance[objectiveId] = { objective: {}, section: {}, quizzes: [] }
          this.activityState.performance[objectiveId].objective = {
            id: objectiveId,
            title: objective.title,
            custom_id: objective.custom_id,
            description: objective.content_ref && objective.content_ref.body && objective.content_ref.body.contents && objective.content_ref.body.contents.text ? objective.content_ref.body.contents.text : "No description"
          }
          this.activityState.performance[objectiveId].section = {
            id: this.getSectionForObjective(objectiveId)
          }
          this.activityState.performance[objectiveId].quizzes = []
        }
        const quizObject = {
          id: quiz.id,
          title: quiz.title,
          currentLevel: NOT_EVALUATED,
          activities: objective.activities.map(activity => {
            return {
              id: activity.slide_id,
              question: {
                content_revision_type: activity.content_ref.header.metadata.content_revision_type,
                text: activity.content_ref.body.contents.question
              },
              points: activity.points,
              isCorrect: null
            }
          })
        }
        this.activityState.performance[objectiveId].quizzes.push(quizObject)
      }
    },
    updatePerformanceForQuiz(slide, value) {
      const quizId = this.getParentIdFromSlide(slide)
      const multipleChoiceQuestion = slide.blocks.find(block => block.type == "contentMultipleChoiceQuestion")
      let objective = false
      if (multipleChoiceQuestion && getObjective(multipleChoiceQuestion.data.content_ref)) {
        objective = getObjective(multipleChoiceQuestion.data.content_ref)
      }
      // calculate performance for specific quiz's objective
      this.setPerformanceForActivity(quizId, objective, slide.id, value)
    },
    setPerformanceForActivity(quizId, objective, slideId, value) {
      const calculateLevel = (quizSlide, quiz) => {
        const correctAnswers = quiz.activities.filter(activity => activity.isCorrect)
        const incorrectAnswers = quiz.activities.filter(activity => activity.isCorrect === false)
        const score = (correctAnswers.length / quiz.activities.length) * 100
        let level = NOT_EVALUATED
        if ((incorrectAnswers.length + correctAnswers.length) === quiz.activities.length) {
          if (correctAnswers.length === quiz.activities.length) {
            level = MASTER
          } else if (score >= quizSlide.slideProperties.settings.success_score) {
            level = PROFICIENT
          } else {
            level = NOT_PROFICIENT
          }
        }
        return level
      }
      let objectiveId = objective ? objective._id : "none"
      const performanceObjective = Object.entries(this.activityState.performance).find(([performanceObjId, performanceObj]) => performanceObjId === objectiveId)
      const PERFORMANCE_INDEX = 1
      if (performanceObjective && performanceObjective[PERFORMANCE_INDEX]) {
        const quizToUpdate = performanceObjective[PERFORMANCE_INDEX].quizzes.find(quiz => quiz.id === quizId)
        if (quizToUpdate) {
          const activityToUpdate = quizToUpdate.activities.find(activity => activity.id === slideId)
          if (activityToUpdate) {
            activityToUpdate.isCorrect = value
            quizToUpdate.currentLevel = calculateLevel(this.getSlideFromSlideId(quizId), quizToUpdate)
          }
        }
      }
    }
  }
}