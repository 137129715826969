import Component from "../lessonPlan/index.vue"
import Print from "../lessonPlan/Print"
import Preview from "../lessonPlan/Preview"

export default {
  name: "interactiveSlide",
  title: "Interactive Slide",
  icon: require("plant-common/src/components/plant-item-viewer/interactive-slide/content-icon.svg"),
  Viewer: Component,
  Editor: Component,
  Print,
  Preview
}
