<template>
  <p-panel-input-select :items="fontSizes" v-model="fontSize" item-text="text" :return-object="true" />
</template>

<script>
import { changeFontSize } from "./utils.js"
import PPanelInputSelect from "../_components/utils/PPanelInputSelect.vue"
export default {
  props: ["data", "side", "id", "fontSizesNumbers"],
  components: {
    PPanelInputSelect
  },
  data: () => ({
    fontSize: null
  }),
  computed: {
    fontSizes() {
      return this.fontSizesNumbers.map(font => {
        return { text: `${font}px`, id: font }
      })
    }
  },
  watch: {
    fontSize() {
      changeFontSize(this.id, this.fontSize.id, this.side)
      this.saveFontSize(this.fontSize)
    }
  },
  mounted() {
    this.fontSize = {
      id: this.data.fontSize,
      text: `${this.data.fontSize}px`
    }
  },
  methods: {
    saveFontSize(fontSize) {
      this.data.fontSize = fontSize.id
    }
  }
}
</script>