import { render, staticRenderFns } from "./PreviewContentImage.vue?vue&type=template&id=73852e43&scoped=true"
import script from "./PreviewContentImage.vue?vue&type=script&lang=js"
export * from "./PreviewContentImage.vue?vue&type=script&lang=js"
import style0 from "./PreviewContentImage.vue?vue&type=style&index=0&id=73852e43&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73852e43",
  null
  
)

export default component.exports