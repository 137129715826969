<template>
  <v-dialog v-model="dialog" width="900px" height="800px" persistent>
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 0" step="0">Introduction</v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="step > 1" step="1">Import Template</v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2">Select Layouts</v-stepper-step>
        <v-divider></v-divider>

        <v-stepper-step :complete="step > 3" step="3">Convert</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <step-0 :step="step" @setStep="step = $event" @closeDialog="dialog = false" />
        <step-1 :step="step" :template="template" @setStep="step = $event" @setTemplate="setTemplate" />
        <step-2
          :step="step"
          :templateToLayouts="templateToLayouts"
          :layouts="template ? template.reference.body.contents.layouts : []"
          @setStep="step = $event" />
        <step-3 :step="step" :loader="loader" :template="template" />
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import { getType } from "../../../../../utils/dbTool"
import step0 from "./step0"
import step1 from "./step1"
import step2 from "./step2"
import step3 from "./step3"
import { transitionGuideToLessonPlan } from "./transition"

export default {
  components: { step0, step1, step2, step3 },
  props: ["value"],
  data() {
    return {
      dialog: true,
      step: 0,
      loader: false,
      // variables for the transitions
      template: null,
      templateToLayouts: []
    }
  },
  computed: {
    cardTemplates() {
      let cardTemplates = []
      if (!this.value.body.contents.template) return []
      cardTemplates = this.value.body.contents.template.body.contents.card_templates.map(value => value.name)
      return [...cardTemplates, "slide"]
    },
    contents() {
      return this.value.body.contents
    },
    comments() {
      return this.value.approval_process.comments
    }
  },
  watch: {
    step() {
      if (this.step === 3) {
        this.loader = true
        // transform array of layouts to a map
        let layouts = this.templateToLayouts.reduce((prev, curr) => {
          prev[curr.template] = curr.layout
          return prev
        }, {})

        transitionGuideToLessonPlan(this.contents, this.template, layouts, this.value.header.metadata, this.comments)
        this.loader = false
      }
    }
  },
  mounted() {
    this.cardTemplates.forEach(name => {
      this.templateToLayouts.push({ template: name, layout: null })
    })
  },
  methods: {
    setTemplate(templateContent) {
      this.template = templateContent
      if (templateContent && getType(templateContent.reference) == "lessonPlan") {
        let layouts = templateContent.reference.body.contents.layouts
        for (let templateToLayout of this.templateToLayouts) {
          templateToLayout.layout = layouts.find(
            layout => layout.name.toLowerCase() == templateToLayout.template.toLowerCase()
          )
        }
      }
    }
  }
}
</script>