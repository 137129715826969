<template>
  <v-btn-toggle :value="value" @change="value => $emit('input', value)">
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="list">
        <v-icon>mdi-format-list-bulleted-square</v-icon>
        <slot />
      </v-btn>
      <span>List</span>
    </v-tooltip>
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="icons">
        <v-icon>mdi-format-list-text</v-icon>
        <slot />
      </v-btn>
      <span>Icons</span>
    </v-tooltip>
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="preview">
        <v-icon>mdi-view-grid</v-icon>
        <slot />
      </v-btn>
      <span>Grid</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    }
  }
}
</script>
