<template>
  <v-card
    style="display: flex; align-items: center; height: 100px; width: 300px"
    class="pl-3"
  >
    <content-type-icon
      height="70px"
      :type="header.content_revision_type"
      style="flex-grow: 0; flex-shrink: 0"
    />

    <div class="pl-4">
      <div>
        <kbd>{{ header.custom_id }}</kbd>
      </div>
      <div class="title mt-2 mb-2">{{ header.custom_id }}</div>
      <div class="subtitle light-3">
        Revision {{ header.major_revision }}.{{ header.minor_revision }}
      </div>
    </div>
  </v-card>
</template>

<script>
import { ContentTypeIcon } from "plant-common";
export default {
  props: ["header"],
  components: { ContentTypeIcon }
};
</script>
