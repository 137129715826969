<template>
  <component :is="component"></component>
</template>

<script>
import { Fragment } from "vue-fragment"
import PlantLibraryComponent from "./structure/PlantLibraryComponent.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { renderKatex } from "plant-common"

export default {
  name: "DynamicInteractiveText",
  props: ["origin"],
  components: {
    Fragment
  },
  data() {
    return {
      content: null
    }
  },
  computed: {
    component() {
      if (!this.content) return false
      return {
        template: `<div class="dynamicIT" ref="it_component">${this.content}</div>`,
        components: { PlantLibraryComponent },
        mounted() {
          this.renderFormulas()
        },
        methods: {
          renderFormulas() {
            const previewDiv = this.$refs.it_component
            if (previewDiv) {
              renderKatex(previewDiv)
            }
          }
        }
      }
    }
  },
  async created() {
    if (this.origin) {
      const contentFromRef = await PServiceContent.getContentFromRef(this.origin)
      if (contentFromRef.body.contents.page.html && contentFromRef.body.contents.page.html.url) {
        const res = await fetch(contentFromRef.body.contents.page.html.url)
        this.content = res.ok ? await res.text() : ""
      }
    }
  }
}
</script>
