<template>
  <v-container grid-list-xs class="recycled-table" style="padding: 0px">
    <v-data-table
      @input="$emit('selected', $event)"
      :value="selected"
      :headers="headers"
      :items="items"
      item-key="_id"
      :pagination.sync="pagination"
      class="elevation-1"
      select-all>
      <template v-slot:items="props">
        <td>
          <v-checkbox v-model="props.selected" primary hide-details />
        </td>
        <td class="text-center">
          <content-type-icon :type="props.item.header.metadata?.content_revision_type" width="40px"></content-type-icon>
        </td>
        <td>
          <v-chip style="z-index: 0" e2e-attr="item_custom_id" label small>
            <v-icon left small>mdi-tag</v-icon>
            {{ props.item?.header?.metadata?.custom_id }}
          </v-chip>
        </td>
        <td>
          {{ props.item.header.metadata?.title }}
        </td>
        <td>
          {{ new Date(props.item.header.metadata?.createdAt) | moment }}
        </td>
        <td>
          {{ new Date(props.item.header.metadata?.deletedAt) | moment }}
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import ContentTypeIcon from "plant-common/src/components/ContentTypeIcon"
import moment from "moment"

export default {
  components: { ContentTypeIcon },
  props: ["items", "selected"],
  data() {
    return {
      headers: [
        {
          text: "Type",
          value: "header.metadata.content_revision_type",
          align: "center",
          sortable: true
        },
        {
          text: "Custom Id",
          value: "header.metadata.custom_id",
          align: "left",
          sortable: true
        },
        {
          text: "Title",
          value: "header.metadata.title",
          align: "left",
          sortable: true
        },
        {
          text: "Created at",
          value: "header.metadata.createdAt",
          align: "left",
          sortable: true
        },
        {
          text: "Deleted at",
          value: "header.metadata.deletedAt",
          align: "left",
          sortable: true
        }
      ],
      pagination: {
        sortBy: "no-sort-default",
        rowsPerPage: 25
      }
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("LLLL")
    }
  }
}
</script>

<style scoped>
.recycled-table {
  height: calc(100vh - 140px);
  overflow-y: scroll;
}
</style>