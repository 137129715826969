import { Auth, Users, Plants, Notifications, Configuration } from "./routes";
import { $http } from "./config";

const ptCoreClient = {
    baseURL: '',
    token: '',
    interceptors: $http.interceptors,
    ...Auth,
    ...Users,
    ...Plants,
    ...Notifications,
    ...Configuration,
    $http

};

export default ptCoreClient
