<template>
  <v-dialog v-model="menu" width="500">
    <template v-slot:activator="{ on }">
      <block-toolbar-button
        :icon="icon"
        tooltip="Update to last revision"
        v-on="on"
        :dark="dark"
        :disabled="disabled" />
    </template>

    <toolbar-dialog-card title="Update to last revision" @closeMenu="menu = false">
      <!-- Info if last version -->
      <p v-if="currentRevision == latestRevision || !latestRevision">
        The content shown is the latest revision <b>{{ currentRevision }}</b
        >.
      </p>

      <!-- Info if not last version -->
      <div v-else>
        <p>
          The content shown is revision
          <b>{{ currentRevision }}</b>
          <v-btn
            v-if="
              value.data &&
              value.data.content_ref &&
              value.data.content_ref.header &&
              value.data.content_ref.header.metadata &&
              value.data.content_ref.header.metadata.custom_id
            "
            icon
            small
            @click="openCurrentVersion()"
            class="lp-toolbar-library-revision-open">
            <v-icon>mdi-open-in-new</v-icon> </v-btn
          >, but there is a newer revision available <b>{{ latestRevision }}</b>
          <v-btn
            v-if="value.data && value.data.content_ref"
            icon
            small
            @click="openNewVersion()"
            class="lp-toolbar-library-revision-open">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </p>
        <div class="lp-toolbar-library-revision-button">
          <v-btn @click="updateSlideLastestRevision()">Update to latest revision</v-btn>
        </div>
      </div>
    </toolbar-dialog-card>
  </v-dialog>
</template>

<script>
import PServiceContent from "../../../../../../plant-common/src/services/PServiceContent"
import { getContentIdFromCustomId } from "../../../../utils/dbTool"
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import ToolbarDialogCard from "../_components/utils/ToolbarDialogCard.vue"

export default {
  name: "LibraryContentRevisionToolbar",
  components: { BlockToolbarButton, ToolbarDialogCard },
  props: ["value", "currentRevision", "latestRevision", "dark", "disabled"],
  data: () => ({
    menu: false
  }),
  computed: {
    icon() {
      return this.currentRevision == this.latestRevision || !this.latestRevision ? "mdi-check" : "mdi-alert-outline"
    }
  },
  watch: {
    menu() {
      this.$emit("menuOpened", this.menu)
    }
  },
  methods: {
    closeMenu() {
      this.menu = false
    },
    updateSlideLastestRevision() {
      this.$emit("updateSlideLatestRevision")
      this.closeMenu()
    },
    async openNewVersion() {
      const contentId = await getContentIdFromCustomId(this.value.data.content_ref.header.metadata.custom_id)
      PServiceContent.openContent(contentId, "tab")
    },
    openCurrentVersion() {
      PServiceContent.openContent(this.value.data.content_ref, "tab")
    }
  }
}
</script>

<style>
.lp-toolbar-library-revision-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.lp-toolbar-library-revision-open {
  margin-left: 0;
}
</style>