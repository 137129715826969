const NOT_VIEWED = null
const OPTIONAL = "optional"
const SKIPPED = "skipped"
const REVIEWED = "reviewed"
const VIEWED = "viewed"

export default {
    [NOT_VIEWED]: {
        name: "Not viewed",
        description: "You have not completed content related to this objective",
        color: "orange",
        avatar: "N"
    },
    [OPTIONAL]: {
        name: "Optional",
        description: "You have the proficient knowledge of this content but you can rewiew it if you want",
        color: "light-green",
        avatar: "O"
    },
    [SKIPPED]: {
        name: "Skipped",
        description: "You have skipped this content but you can rewiew it if you want",
        color: "grey",
        avatar: "S"
    },
    [REVIEWED]: {
        name: "Reviewed",
        description: "You have reviewed content related to this objective",
        color: "green",
        avatar: "R"
    },
    [VIEWED]: {
        name: "Viewed",
        description: "You have completed the content related to this objective",
        color: "blue",
        avatar: "V"
    }
}