import PtApiClient from "plant-api-client"
import {
  STATE_ALL_ITEMS_REVISION_DRAFTS,
  STATE_FILTER_INPUT,
  STATE_INPUT_OTHERS,
  STATE_IS_ERROR_CREATE,
  STATE_LOADING,
  STATE_SELECTED_BULK_OPERATIONS,
  STATE_SELECTED_ITEM_REVISION_DRAFT_ID,
  STATE_SELECTED_LIST,
  STORE_WORKFLOWS_NAME,
  GETTER_ACTIVE_USER,
  GETTER_CAN_EDIT,
  GETTER_FILTERED_ITEM_REVISION_DRAFTS,
  GETTER_FILTERED_STATE_WORKFLOWS,
  GETTER_IS_ADMIN,
  GETTER_IS_SUPERVISOR,
  GETTER_LOADING,
  GETTER_SELECTED_ITEM_REVISION_DRAFT,
  MUTATION_ADD_NEW_ITEM_TO_LIST,
  MUTATION_ALL_SELECTION,
  MUTATION_ERROR_CREATE,
  MUTATION_ITEM_REVISION_DRAFTS,
  MUTATION_REMOVE_SELECTED_DRAFT,
  MUTATION_REMOVE_SELECTED_DRAFTS,
  MUTATION_SELECTED_ITEM_REVISION,
  MUTATION_SELECTION,
  MUTATION_SET_FILTERED_ITEM_REVISION_DRAFTS,
  MUTATION_TOGGLE_LOADING,
  MUTATION_UNSELECTED_BULK_DRAFTS,
  MUTATION_UPDATE_SELECTED_DRAFT,
  MUTATION_UPDATE_SELECTED_DRAFTS,
  MUTATION_UPDATE_SELECTED_DRAFT_METADATA,
  ACTION_CHANGE_SELECTION,
  ACTION_CHANGE_WORKFLOW,
  ACTION_CHANGE_WORKFLOWS,
  ACTION_CREATE_ITEM,
  ACTION_DELETE_SELECTED_ITEM,
  ACTION_DISCARD_SELECTED_ITEMS_DRAFT,
  ACTION_DISCARD_SELECTED_ITEM_DRAFT,
  ACTION_GET_PROPERTIES_DRAFT,
  ACTION_GET_WORKFLOW,
  ACTION_ITEM_REVISION_DRAFTS,
  ACTION_MOVE_SELECTED,
  ACTION_REASSIGN_USERS_ROLE,
  ACTION_REASSIGN_USER_ROLE,
  ACTION_SELECT_FILTER,
  ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
  ACTION_TOGGLE_ALL_SELECTION,
  ACTION_UNSELECT_ITEM_REVISION_DRAFT,
  ACTION_UPDATE_SELECTED_DRAFT_METADATA
} from "@/store_constants/workflows"

import { roles } from "@/config/constants"
import { getContentId, getType } from "@/utils/dbTool"

const FILTER_ALL = "ALL"
const FILTER_CREATEDBYME = "CREATEDBYME"
const FILTER_MYCONTRIBUTION = "MYCONTRIBUTION"
const FILTER_ASSIGNEDTOME = "ASSIGNEDTOME"
const FILTER_BYUSER = "FILTERBYUSER"
const FILTER_BYSTATE = "FILTERBYSTATE"
const FILTER_BYTYPE = "FILTERBYTYPE"

const FILTERS = [
  FILTER_ALL,
  FILTER_CREATEDBYME,
  FILTER_ASSIGNEDTOME,
  FILTER_MYCONTRIBUTION,
  FILTER_BYUSER,
  FILTER_BYSTATE,
  FILTER_BYTYPE
]

export default {
  namespaced: true,
  name: STORE_WORKFLOWS_NAME,
  state: {
    [STATE_IS_ERROR_CREATE]: false,
    [STATE_ALL_ITEMS_REVISION_DRAFTS]: [],
    [STATE_SELECTED_ITEM_REVISION_DRAFT_ID]: false,
    [STATE_SELECTED_LIST]: FILTER_ASSIGNEDTOME,
    [STATE_LOADING]: true,
    [STATE_FILTER_INPUT]: null,
    [STATE_INPUT_OTHERS]: null,
    [STATE_SELECTED_BULK_OPERATIONS]: []
  },
  getters: {
    [GETTER_FILTERED_ITEM_REVISION_DRAFTS](state, getters) {
      const user_id = getters.activeUser._id
      const filter_functions = {
        [FILTER_CREATEDBYME]: item => {
          if (item.approval_process.log && item.approval_process.log[item.approval_process.log.length - 1]) {
            return item.approval_process.log[item.approval_process.log.length - 1].user === user_id
          } else {
            return false
          }
        },
        [FILTER_MYCONTRIBUTION]: item => {
          return item.approval_process.workflow.contributors
            .filter(c => c.user_id != undefined)
            .some(c => c.user_id === user_id)
        },
        [FILTER_ASSIGNEDTOME]: item => {
          return (
            item.approval_process.workflow.contributors.find(
              contributor => contributor.role === item.approval_process.workflow.assigned_to
            ).user_id === user_id
          )
        },
        [FILTER_BYUSER]: item => {
          if (state[STATE_INPUT_OTHERS] && state[STATE_INPUT_OTHERS].length) {
            let filter_by_type = null
            let filter_by_state = null
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYTYPE"))
              filter_by_type = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYTYPE").filter_text
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYSTATE"))
              filter_by_state = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYSTATE").filter_text

            if (filter_by_type && !filter_by_state) {
              return (
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === state[STATE_FILTER_INPUT] && getType(item) === filter_by_type
              )
            }
            if (!filter_by_type && filter_by_state) {
              return (
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === state[STATE_FILTER_INPUT] && item.approval_process.workflow.state === filter_by_state
              )
            }
            if (filter_by_type && filter_by_state) {
              return (
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === state[STATE_FILTER_INPUT] &&
                item.approval_process.workflow.state === filter_by_state &&
                getType(item) === filter_by_type
              )
            }
          }
          return (
            item.approval_process.workflow.contributors.find(
              contributor => contributor.role === item.approval_process.workflow.assigned_to
            ).user_id === state[STATE_FILTER_INPUT]
          )
        },
        [FILTER_BYSTATE]: item => {
          if (state[STATE_INPUT_OTHERS] && state[STATE_INPUT_OTHERS].length) {
            let filter_by_type = null
            let filter_by_user = null
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYTYPE"))
              filter_by_type = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYTYPE").filter_text
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYUSER"))
              filter_by_user = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYUSER").filter_text

            if (filter_by_type && !filter_by_user) {
              return (
                item.approval_process.workflow.state === state[STATE_FILTER_INPUT] && getType(item) === filter_by_type
              )
            }
            if (!filter_by_type && filter_by_user) {
              return (
                item.approval_process.workflow.state === state[STATE_FILTER_INPUT] &&
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === filter_by_user
              )
            }
            if (filter_by_type && filter_by_user) {
              return (
                item.approval_process.workflow.state === state[STATE_FILTER_INPUT] &&
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === filter_by_user &&
                getType(item) === filter_by_type
              )
            }
          }
          return item.approval_process.workflow.state === state[STATE_FILTER_INPUT]
        },
        [FILTER_BYTYPE]: item => {
          if (state[STATE_INPUT_OTHERS] && state[STATE_INPUT_OTHERS].length) {
            let filter_by_state = null
            let filter_by_user = null
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYSTATE"))
              filter_by_state = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYSTATE").filter_text
            if (state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYUSER"))
              filter_by_user = state[STATE_INPUT_OTHERS].find(e => e.filter_name === "FILTERBYUSER").filter_text

            if (filter_by_state && !filter_by_user) {
              return (
                getType(item) === state[STATE_FILTER_INPUT] && item.approval_process.workflow.state === filter_by_state
              )
            }
            if (!filter_by_state && filter_by_user) {
              return (
                getType(item) === state[STATE_FILTER_INPUT] &&
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === filter_by_user
              )
            }
            if (filter_by_state && filter_by_user) {
              return (
                getType(item) === state[STATE_FILTER_INPUT] &&
                item.approval_process.workflow.state === filter_by_state &&
                item.approval_process.workflow.contributors.find(
                  contributor => contributor.role === item.approval_process.workflow.assigned_to
                ).user_id === filter_by_user
              )
            }
          }
          return getType(item) === state[STATE_FILTER_INPUT]
        }
      }
      return state[STATE_ALL_ITEMS_REVISION_DRAFTS].filter(filter_functions[state[STATE_SELECTED_LIST]] || (() => true))
    },
    [GETTER_FILTERED_STATE_WORKFLOWS](state) {
      let uniqueState = []
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].forEach(element => {
        if (!uniqueState.includes(element.approval_process.workflow.state))
          uniqueState.push(element.approval_process.workflow.state)
      })
      return uniqueState
    },
    [GETTER_SELECTED_ITEM_REVISION_DRAFT](state) {
      return state[STATE_ALL_ITEMS_REVISION_DRAFTS].find(
        d => getContentId(d) == state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID]
      )
    },
    [GETTER_ACTIVE_USER](state, getters, rootState) {
      return rootState.user.profile
    },
    [GETTER_CAN_EDIT](state, getters) {
      if (!getters.selectedItemRevisionDraft) return false
      const workflow = getters.selectedItemRevisionDraft.approval_process.workflow
      return (
        workflow.contributors.find(contributor => contributor.role === workflow.assigned_to).user_id ==
        getters.activeUser._id && workflow.permissions.includes("edit")
      )
    },
    [GETTER_IS_ADMIN](state, getters) {
      return getters.activeUser.role === roles.admin.value
    },
    [GETTER_IS_SUPERVISOR](state, getters) {
      return getters.activeUser.role === roles.supervisor.value
    },
    [GETTER_LOADING](state, getters) {
      return state[STATE_LOADING]
    }
  },
  mutations: {
    [MUTATION_UPDATE_SELECTED_DRAFT](state, draft) {
      const index = state[STATE_ALL_ITEMS_REVISION_DRAFTS].findIndex(d => getContentId(d) == getContentId(draft))
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].splice(index, 1, draft)
    },
    [MUTATION_UPDATE_SELECTED_DRAFT_METADATA](state, { draft, metadata }) {
      const draftFound = state[STATE_ALL_ITEMS_REVISION_DRAFTS].find(d => getContentId(d) == getContentId(draft))

      draftFound.header.metadata = { ...draftFound.header.metadata, ...metadata }
    },
    [MUTATION_UPDATE_SELECTED_DRAFTS](state, draft) {
      const index = state[STATE_ALL_ITEMS_REVISION_DRAFTS].findIndex(d => getContentId(d) == getContentId(draft))
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].splice(index, 1, draft)
      const index_selected = state[STATE_SELECTED_BULK_OPERATIONS].findIndex(
        d => getContentId(d) == getContentId(draft)
      )
      state[STATE_SELECTED_BULK_OPERATIONS].splice(index_selected, 1, draft)
      state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID] = false
    },
    [MUTATION_REMOVE_SELECTED_DRAFT](state) {
      const index = state[STATE_ALL_ITEMS_REVISION_DRAFTS].findIndex(
        d => getContentId(d) == state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID]
      )
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].splice(index, 1)
      state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID] = false
    },
    [MUTATION_REMOVE_SELECTED_DRAFTS](state, draft) {
      const index = state[STATE_ALL_ITEMS_REVISION_DRAFTS].findIndex(d => getContentId(d) == getContentId(draft))
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].splice(index, 1)
      const index_selected = state[STATE_SELECTED_BULK_OPERATIONS].findIndex(
        d => getContentId(d) == getContentId(draft)
      )
      state[STATE_SELECTED_BULK_OPERATIONS].splice(index_selected, 1)
      state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID] = false
    },
    [MUTATION_ITEM_REVISION_DRAFTS](state, items) {
      state[STATE_ALL_ITEMS_REVISION_DRAFTS] = items
    },
    [MUTATION_SELECTED_ITEM_REVISION](state, itemRevisionDraftId) {
      state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID] = itemRevisionDraftId
    },
    [MUTATION_ADD_NEW_ITEM_TO_LIST](state, newItem) {
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].push(newItem)
    },
    [MUTATION_SET_FILTERED_ITEM_REVISION_DRAFTS](state, filter) {
      if (typeof filter === "object" && filter !== null) {
        state[STATE_FILTER_INPUT] = filter.filter_text
        state[STATE_SELECTED_LIST] = filter.filter_name
        state[STATE_INPUT_OTHERS] = filter.others
      } else {
        state[STATE_SELECTED_LIST] = filter
      }
    },
    [MUTATION_TOGGLE_LOADING](state, load) {
      state[STATE_LOADING] = load
    },
    [MUTATION_SELECTION](state, selection) {
      if (selection.selected) {
        if (!state[STATE_SELECTED_BULK_OPERATIONS].some(selectedItem => selectedItem._id === selection._id)) {
          state[STATE_SELECTED_BULK_OPERATIONS].push(selection)
        }
      } else {
        let index = state[STATE_SELECTED_BULK_OPERATIONS].indexOf(selection)
        state[STATE_SELECTED_BULK_OPERATIONS].splice(index, 1)
      }
    },
    [MUTATION_ALL_SELECTION](state, { getters, flag }) {
      if (flag) {
        state[STATE_SELECTED_BULK_OPERATIONS] = JSON.parse(
          JSON.stringify(getters[GETTER_FILTERED_ITEM_REVISION_DRAFTS])
        )
      } else {
        state[STATE_SELECTED_BULK_OPERATIONS] = []
      }
    },
    [MUTATION_ERROR_CREATE](state, isErrorCreate) {
      state[STATE_IS_ERROR_CREATE] = isErrorCreate
    },
    [MUTATION_UNSELECTED_BULK_DRAFTS](state) {
      state[STATE_ALL_ITEMS_REVISION_DRAFTS].forEach(draft => {
        draft.selected = false
      })
      state[STATE_SELECTED_BULK_OPERATIONS] = []
    }
  },
  actions: {
    // Import all users from DB
    [ACTION_ITEM_REVISION_DRAFTS]({ commit, state, dispatch }) {
      commit(MUTATION_TOGGLE_LOADING, true)
      commit(MUTATION_SELECTED_ITEM_REVISION, false)
      state[STATE_SELECTED_BULK_OPERATIONS] = []
      return PtApiClient.getAllDrafts()
        .then(drafts => {
          commit(MUTATION_TOGGLE_LOADING, false)
          commit(MUTATION_ITEM_REVISION_DRAFTS, drafts.docs)
        })
        .catch(e => {
          commit(MUTATION_TOGGLE_LOADING, false)
          if (e.response && e.response.data && e.response.data.error) {
            dispatch(
              "notifications/error",
              { message: "Error: " + e.response.data.error },
              {
                root: true
              }
            )
          } else {
            dispatch(
              "notifications/error",
              { message: "Error: Oops! Something happend when trying to fetch data" },
              { root: true }
            )
          }
        })
    },
    [ACTION_SELECT_ITEM_REVISION_DRAFT_ID]({ commit }, itemRevisionDraftId) {
      commit(MUTATION_SELECTED_ITEM_REVISION, itemRevisionDraftId)
    },
    [ACTION_UNSELECT_ITEM_REVISION_DRAFT]({ commit }) {
      commit(MUTATION_SELECTED_ITEM_REVISION, false)
      commit(MUTATION_UNSELECTED_BULK_DRAFTS)
    },
    [ACTION_REASSIGN_USER_ROLE]({ commit, state, dispatch, getters }, { user_id, role, lightweight }) {
      return PtApiClient.updateDraftWorkflow(
        getContentId(getters.selectedItemRevisionDraft),
        "method",
        "reassignRole",
        { role, user_id, lightweight }
      )
        .then(draft => {
          commit(MUTATION_UPDATE_SELECTED_DRAFT, draft)
          dispatch("notifications/info", { message: "Contributor role " + role + " reassigned" }, { root: true })
        })
        .catch(e => {
          dispatch(
            "notifications/error",
            {
              message: "Contributor role " + role + " not reassigned due to: " + e.response.data.message
            },
            { root: true }
          )
        })
    },
    async [ACTION_REASSIGN_USERS_ROLE]({ commit, state, dispatch, getters }, { user_id, role, lightweight }) {
      for (const draft of state[STATE_SELECTED_BULK_OPERATIONS]) {
        await PtApiClient.updateDraftWorkflow(getContentId(draft), "method", "reassignRole", { role, user_id, lightweight })
          .then(draft => {
            commit(MUTATION_UPDATE_SELECTED_DRAFTS, draft)
            dispatch("notifications/info", { message: "Contributor role " + role + " reassigned" }, { root: true })
          })
          .catch(e => {
            dispatch(
              "notifications/error",
              {
                message: "Contributor role " + role + " not reassigned due to: " + e.response.data.message
              },
              { root: true }
            )
          })
      }

    },
    [ACTION_CHANGE_WORKFLOW]({ commit, state, dispatch, getters }, { name, lightweight }) {
      return PtApiClient.updateDraftWorkflow(
        getContentId(getters.selectedItemRevisionDraft),
        "method",
        "changeWorkflow",
        { name, lightweight }
      )
        .then(draft => {
          commit(MUTATION_UPDATE_SELECTED_DRAFT, draft)
          dispatch("notifications/info", { message: "Workflow changed" }, { root: true })
        })
        .catch(() => {
          dispatch(
            "notifications/error",
            { message: "Workflow not changed" },
            {
              root: true
            }
          )
        })
    },
    [ACTION_CHANGE_WORKFLOWS]({ commit, state, dispatch, getters }, { name, lightweight }) {
      state[STATE_SELECTED_BULK_OPERATIONS].forEach(draft => {
        return PtApiClient.updateDraftWorkflow(getContentId(draft), "method", "changeWorkflow", { name, lightweight })
          .then(draft => {
            commit(MUTATION_UPDATE_SELECTED_DRAFTS, draft)
            dispatch("notifications/info", { message: "Workflow changed" }, { root: true })
          })
          .catch(() => {
            dispatch(
              "notifications/error",
              { message: "Workflow not changed" },
              {
                root: true
              }
            )
          })
      })
    },
    [ACTION_MOVE_SELECTED]({ commit, state }, n) {
      var index = state[STATE_ALL_ITEMS_REVISION_DRAFTS].findIndex(
        d => getContentId(d) == state[STATE_SELECTED_ITEM_REVISION_DRAFT_ID]
      )
      var new_index = Math.min(state[STATE_ALL_ITEMS_REVISION_DRAFTS].length - 1, Math.max(0, index + n))
      commit(MUTATION_SELECTED_ITEM_REVISION, getContentId(state[STATE_ALL_ITEMS_REVISION_DRAFTS][new_index]))
    },
    [ACTION_CREATE_ITEM]({ commit, dispatch }, { title, custom_id, path_id, item_type }) {
      commit(MUTATION_ERROR_CREATE, false)

      return PtApiClient.createContent(title, custom_id, path_id, item_type)
        .then(res => {
          dispatch("notifications/info", { message: "New Item Created" }, { root: true })
          dispatch(ACTION_ITEM_REVISION_DRAFTS).then(() => {
            commit(MUTATION_SELECTED_ITEM_REVISION, getContentId(res.itemRevisionDraft))
          })
          return res
        })
        .catch(e => {
          commit(MUTATION_ERROR_CREATE, true)
          let errorMessage = "Not created"
          if (e.response && e.response.data && e.response.data.message) errorMessage = e.response.data.message
          dispatch("notifications/error", { message: errorMessage }, { root: true })
        })
    },
    [ACTION_DELETE_SELECTED_ITEM]({ getters, commit, dispatch }) {
      return PtApiClient.deleteDraft(getContentId(getters.selectedItemRevisionDraft))
        .then(res => {
          dispatch("notifications/info", { message: "Content Deleted" }, { root: true })
          commit(MUTATION_REMOVE_SELECTED_DRAFT)
        })
        .catch(e => {
          dispatch(
            "notifications/error",
            { message: "Content was not deleted" },
            {
              root: true
            }
          )
        })
    },
    [ACTION_DISCARD_SELECTED_ITEM_DRAFT]({ getters, commit, dispatch }) {
      return PtApiClient.deleteDraft(getContentId(getters.selectedItemRevisionDraft))
        .then(res => {
          dispatch("notifications/info", { message: "Draft Discarded" }, { root: true })
          commit(MUTATION_REMOVE_SELECTED_DRAFT)
        })
        .catch(e => {
          dispatch(
            "notifications/error",
            { message: "Draft was not discarded" },
            {
              root: true
            }
          )
        })
    },
    [ACTION_DISCARD_SELECTED_ITEMS_DRAFT]({ commit, state, dispatch }) {
      state[STATE_SELECTED_BULK_OPERATIONS].forEach(draft => {
        return PtApiClient.deleteDraft(getContentId(draft))
          .then(res => {
            dispatch("notifications/info", { message: "Draft Discarded" }, { root: true })
            commit(MUTATION_REMOVE_SELECTED_DRAFTS, draft)
            commit(MUTATION_UNSELECTED_BULK_DRAFTS, draft)
          })
          .catch(e => {
            dispatch(
              "notifications/error",
              { message: "Draft was not discarded" },
              {
                root: true
              }
            )
          })
      })
    },
    [ACTION_SELECT_FILTER]({ commit }, filter) {
      commit(MUTATION_SELECTED_ITEM_REVISION, false)
      commit(MUTATION_SET_FILTERED_ITEM_REVISION_DRAFTS, filter)
    },
    [ACTION_CHANGE_SELECTION]({ commit }, item) {
      commit(MUTATION_SELECTION, item)
    },
    [ACTION_TOGGLE_ALL_SELECTION]({ commit, getters }, flag) {
      getters[GETTER_FILTERED_ITEM_REVISION_DRAFTS].forEach(draft => {
        draft.selected = flag
      })
      commit(MUTATION_ALL_SELECTION, { getters, flag })
    },
    [ACTION_GET_PROPERTIES_DRAFT]({ commit, state, dispatch }, draft) {
      return PtApiClient.getDraftApprovalProcess(getContentId(draft))
        .then(res => {
          return res
        })
        .catch(e => {
          dispatch(
            "notifications/warning",
            { message: "Something happend when trying to get properties. Properties might be incompleted" },
            {
              root: true
            }
          )
          return e
        })
    },
    [ACTION_GET_WORKFLOW]({ }, workflowName) {
      return PtApiClient.getWorkflowData(workflowName)
    },
    [ACTION_UPDATE_SELECTED_DRAFT_METADATA]({ commit, state, dispatch }, { draft, metadata }) {
      return PtApiClient.updateDraftMetadata(getContentId(draft), metadata)
        .then(res => {
          commit(MUTATION_UPDATE_SELECTED_DRAFT_METADATA, { draft, metadata })
          dispatch("notifications/info", { message: "Properties saved" }, { root: true })
        })
        .catch(e => {
          dispatch(
            "notifications/error",
            { message: "Properties were not saved" },
            {
              root: true
            }
          )
        })
    }
  }
}
