<template>
  <v-container class="width100">
    <v-toolbar flat color="transparent">
      <v-toolbar-title>Notifications</v-toolbar-title>
    </v-toolbar>

    <v-data-table
      :headers="headers"
      :pagination.sync="pagination"
      :items="notifications"
      class="elevation-1 scrollDataTable">
      <template slot="items" slot-scope="props">
        <td>
          <v-chip>{{ props.item.subscription_code | subscriptionTitle }}</v-chip>
        </td>
        <td>
          <v-container grid-list-md>
            <v-layout row wrap>
              <v-flex xs2>
                <content-type-icon
                  v-if="
                    (props.item.event.payload &&
                      props.item.event.payload.metadata &&
                      props.item.event.payload.metadata.content_revision_type) ||
                    (props.item.event.payload && props.item.event.payload.content_revision_type)
                  "
                  :type="
                    props.item.event.payload.content_revision_type
                      ? props.item.event.payload.content_revision_type
                      : props.item.event.payload.metadata.content_revision_type
                  "
                  width="40px"></content-type-icon>
                <content-type-icon v-else width="40px"></content-type-icon>
              </v-flex>
              <v-flex xs10>
                <span v-html="getNotificationContent(props.item, props.item.subscription_code)"></span>
              </v-flex> </v-layout
          ></v-container>
        </td>
        <td>{{ props.item.timestamp | moment }}</td>
        <td>
          <span v-if="props.item.read">{{ props.item.read | moment }}</span>
          <span v-else>-</span>
        </td>
        <td class="justify-center layout px-0" style="margin-top: 10px">
          <v-tooltip bottom>
            <template v-if="!props.item.read" v-slot:activator="{ on }">
              <v-btn outline fab small>
                <v-icon v-on="on" @click.stop="markAsRead({ notificationId: props.item._id, read: true })"
                  >mdi-email-open</v-icon
                >
              </v-btn>
            </template>
            <span>Mark as read</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-if="props.item.event.payload" v-slot:activator="{ on }">
              <v-btn outline fab small>
                <v-icon v-on="on" @click.stop="openNotification(props.item, props.item.subscription_code)"
                  >mdi-open-in-new</v-icon
                >
              </v-btn>
            </template>
            <span>Open content</span>
          </v-tooltip>
        </td>
      </template>
      <template slot="no-data">
        <span>No notifications found for current user</span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import {
  STORE_EVENT_NOTIFICATIONS_NAME,
  ACTION_EVENT_NOTIFICATIONS_FETCH,
  STATE_EVENT_NOTIFICATIONS_LIST,
  ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ
} from "@/store_constants/event-notifications"
import moment from "moment"
import available_subscriptions from "./available_subscriptions"
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_ERROR } from "@/store_constants/notifications"
import { ContentTypeIcon } from "plant-common"
export default {
  data() {
    return {
      headers: [
        {
          text: "Subscription code",
          value: "subscription_code",
          sortable: true,
          width: 120
        },
        { text: "Content", value: "event.payload", sortable: true, width: 480 },
        { text: "Received at", value: "timestamp", sortable: true, width: 180 },
        { text: "Read date", value: "read", sortable: true, width: 180 },
        { text: "Actions", value: "actions", sortable: false, width: 120 }
      ],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10, // -1 for All",
        sortBy: "timestamp"
      }
    }
  },
  components: { ContentTypeIcon },
  created() {
    this.fetchNotifications(true)
  },
  filters: {
    moment: function (date) {
      return moment(date).format("LLLL")
    },
    subscriptionTitle: function (type) {
      var subscription = available_subscriptions.find(an => an.code == type)
      return subscription ? subscription.title : `Subscription code not found: ${type}`
    }
  },
  computed: {
    ...mapState(STORE_EVENT_NOTIFICATIONS_NAME, [STATE_EVENT_NOTIFICATIONS_LIST]),
    notifications() {
      if (!this[STATE_EVENT_NOTIFICATIONS_LIST]) return []
      return this[STATE_EVENT_NOTIFICATIONS_LIST]
    }
  },
  methods: {
    ...mapActions(STORE_EVENT_NOTIFICATIONS_NAME, {
      fetchNotifications: ACTION_EVENT_NOTIFICATIONS_FETCH,
      markAsRead: ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ
    }),
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    openNotification(item, type) {
      if (item.event.payload.library_id) {
        var subscription = available_subscriptions.find(an => an.code == type)
        if (subscription) {
          if (!item.read) {
            this.markAsRead({ notificationId: item._id, read: true })
          }
          window.open(subscription.getURL(item.event.payload), "_blank")
        } else {
          this.error({
            message: `Could not open current content. ${type} not registered.`
          })
        }
      } else {
        this.error({
          message: "Could not open current content. Library ID is missing"
        })
      }
    },
    getNotificationContent(item, type) {
      var subscription = available_subscriptions.find(an => an.code == type)
      return subscription ? subscription.readable(item) : `Subscription code not found: ${type}`
    }
  }
}
</script>

<style>
.title-card {
  color: #4e4e4e;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 24px;
}

.scrollable {
  overflow: auto;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}

.subtext-card {
  color: #929292;
  height: 75px;
}

.fix-card-height {
  min-height: 385px;
}

.width100 {
  max-width: 100% !important;
}
</style>