import Content from "./Content.vue";
import Dashboard from "./Dashboard.vue";
export default {
  path: "workflows",
  name: "Workflows",
  component: Content,
  children: [
    {
      path: ":library_id",
      name: "Workflow",
      props: true,
      component: Dashboard,
    },
  ],
};
