<template>
  <div>
    <div class="lp-toolbar-textarea" v-if="editor">
      <block-toolbar-button
        icon="mdi-format-bold"
        tooltip="Bold"
        @click="editor.chain().focus().toggleBold().run()"
        :isActive="editor.isActive('bold')"
        :dark="dark">
      </block-toolbar-button>
      <block-toolbar-button
        icon="mdi-format-italic"
        tooltip="Italic"
        @click="editor.chain().focus().toggleItalic().run()"
        :isActive="editor.isActive('italic')"
        :dark="dark">
      </block-toolbar-button>
      <block-toolbar-button
        icon="mdi-format-underline"
        tooltip="Underline"
        @click="editor.chain().focus().toggleUnderline().run()"
        :isActive="editor.isActive('underline')"
        :dark="dark">
      </block-toolbar-button>
      <block-toolbar-button
        icon="mdi-format-strikethrough-variant"
        :tooltip="editor.isActive('strike') ? 'Unset strike' : 'Set strike'"
        @click="editor.chain().focus().toggleStrike().run()"
        :isActive="editor.isActive('strike')"
        :dark="dark">
      </block-toolbar-button>

      <v-divider vertical></v-divider>
      <block-toolbar-button
        tooltip="Paragraph"
        @click="editor.chain().focus().setParagraph().run()"
        icon="mdi-format-paragraph"
        :isActive="editor.isActive('paragraph')"
        :dark="dark"></block-toolbar-button>
      <block-toolbar-button
        tooltip="Level 1"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :isActive="editor.isActive('heading', { level: 1 })"
        icon="mdi-format-header-1"
        :dark="dark"></block-toolbar-button>

      <block-toolbar-button
        tooltip="Level 2"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :isActive="editor.isActive('heading', { level: 2 })"
        icon="mdi-format-header-2"
        :dark="dark"></block-toolbar-button>

      <block-toolbar-button
        tooltip="Level 3"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :isActive="editor.isActive('heading', { level: 3 })"
        icon="mdi-format-header-3"
        :dark="dark"></block-toolbar-button>

      <v-divider vertical></v-divider>

      <block-toolbar-button tooltip="Paste with format" @click="pasteWithFormat" :dark="dark">
        <template v-slot:icon>
          <svg
            style="margin-top: 4px; padding: 4px"
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M79.1667 12.4998H61.75C60 7.6665 55.4167 4.1665 50 4.1665C44.5833 4.1665 40 7.6665 38.25 12.4998H20.8333C18.6232 12.4998 16.5036 13.3778 14.9408 14.9406C13.378 16.5034 12.5 18.623 12.5 20.8332V79.1665C12.5 81.3766 13.378 83.4963 14.9408 85.0591C16.5036 86.6219 18.6232 87.4998 20.8333 87.4998H79.1667C81.3768 87.4998 83.4964 86.6219 85.0592 85.0591C86.622 83.4963 87.5 81.3766 87.5 79.1665V20.8332C87.5 18.623 86.622 16.5034 85.0592 14.9406C83.4964 13.3778 81.3768 12.4998 79.1667 12.4998ZM50 12.4998C51.1051 12.4998 52.1649 12.9388 52.9463 13.7202C53.7277 14.5016 54.1667 15.5614 54.1667 16.6665C54.1667 17.7716 53.7277 18.8314 52.9463 19.6128C52.1649 20.3942 51.1051 20.8332 50 20.8332C48.8949 20.8332 47.8351 20.3942 47.0537 19.6128C46.2723 18.8314 45.8333 17.7716 45.8333 16.6665C45.8333 15.5614 46.2723 14.5016 47.0537 13.7202C47.8351 12.9388 48.8949 12.4998 50 12.4998V12.4998ZM29.1667 29.1665H70.8333V20.8332H79.1667V79.1665H20.8333V20.8332H29.1667V29.1665Z"
              fill="black" />
            <path
              d="M41.8889 60.1111C42.8667 60.1111 43.6667 60.9111 43.6667 61.8889C43.6667 63.8444 42.0667 65.4444 40.1111 65.4444C39.8089 65.4444 39.5244 65.4089 39.2222 65.3556C39.7733 64.3778 40.1111 63.2044 40.1111 61.8889C40.1111 60.9111 40.9111 60.1111 41.8889 60.1111ZM62.6356 37C62.1733 37 61.7289 37.1778 61.3733 37.5156L45.4444 53.4444L50.3333 58.3333L66.2622 42.4044C66.9556 41.7111 66.9556 40.5911 66.2622 39.8978L63.88 37.5156C63.5244 37.16 63.08 37 62.6356 37V37ZM41.8889 56.5556C38.9378 56.5556 36.5556 58.9378 36.5556 61.8889C36.5556 64.2178 34.4933 65.4444 33 65.4444C34.6356 67.6133 37.4267 69 40.1111 69C44.04 69 47.2222 65.8178 47.2222 61.8889C47.2222 58.9378 44.84 56.5556 41.8889 56.5556Z"
              fill="black" />
          </svg>
        </template>
      </block-toolbar-button>
      <block-toolbar-button tooltip="Paste only text" @click="pasteWithoutFormat" :dark="dark">
        <template v-slot:icon>
          <svg
            style="margin-top: 4px; padding: 4px"
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M79.1667 12.4998H61.75C60 7.6665 55.4167 4.1665 50 4.1665C44.5833 4.1665 40 7.6665 38.25 12.4998H20.8333C18.6232 12.4998 16.5036 13.3778 14.9408 14.9406C13.378 16.5034 12.5 18.623 12.5 20.8332V79.1665C12.5 81.3766 13.378 83.4963 14.9408 85.0591C16.5036 86.6219 18.6232 87.4998 20.8333 87.4998H79.1667C81.3768 87.4998 83.4964 86.6219 85.0592 85.0591C86.622 83.4963 87.5 81.3766 87.5 79.1665V20.8332C87.5 18.623 86.622 16.5034 85.0592 14.9406C83.4964 13.3778 81.3768 12.4998 79.1667 12.4998ZM50 12.4998C51.1051 12.4998 52.1649 12.9388 52.9463 13.7202C53.7277 14.5016 54.1667 15.5614 54.1667 16.6665C54.1667 17.7716 53.7277 18.8314 52.9463 19.6128C52.1649 20.3942 51.1051 20.8332 50 20.8332C48.8949 20.8332 47.8351 20.3942 47.0537 19.6128C46.2723 18.8314 45.8333 17.7716 45.8333 16.6665C45.8333 15.5614 46.2723 14.5016 47.0537 13.7202C47.8351 12.9388 48.8949 12.4998 50 12.4998V12.4998ZM29.1667 29.1665H70.8333V20.8332H79.1667V79.1665H20.8333V20.8332H29.1667V29.1665Z"
              fill="black" />
            <path
              d="M42.7273 63.6H57.2727L59.8909 70H66L52.1818 38H47.8182L34 70H40.1091L42.7273 63.6ZM50 43.76L55.44 58.3636H44.56L50 43.76Z"
              fill="black" />
          </svg>
        </template>
      </block-toolbar-button>
      <block-toolbar-button
        icon="mdi-format-clear"
        tooltip="Clean format"
        @click="editor.chain().focus().unsetAllMarks().run()"
        :dark="dark">
      </block-toolbar-button>

      <v-divider vertical></v-divider>

      <block-toolbar-button
        tooltip="Subscript"
        @click="setSubscript"
        :isActive="editor.isActive('subscript')"
        icon="mdi-format-subscript"
        :dark="dark"></block-toolbar-button>

      <block-toolbar-button
        tooltip="Superscript"
        @click="setSuperscript"
        :isActive="editor.isActive('superscript')"
        icon="mdi-format-superscript"
        :dark="dark"></block-toolbar-button>

      <v-divider vertical></v-divider>

      <block-toolbar-button
        tooltip="Bullet list"
        @click="editor.chain().focus().toggleBulletList().run()"
        :isActive="editor.isActive('bulletList')"
        icon="mdi-format-list-bulleted"
        :dark="dark"></block-toolbar-button>

      <block-toolbar-button
        tooltip="Ordered list"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :isActive="editor.isActive('orderedList')"
        icon="mdi-format-list-numbered"
        :dark="dark"></block-toolbar-button>
      <order-list-button :editor="editor" />

      <block-toolbar-button
        tooltip="Math inline"
        @click="editor.chain().focus().toggleFormulaMark().run()"
        :isActive="editor.isActive('formulaMark')"
        icon="mdi-math-integral"
        :dark="dark"></block-toolbar-button>
      <block-toolbar-button
        tooltip="Math box"
        @click="editor.chain().focus().addKatex().run()"
        :isActive="editor.isActive('formulaNode')"
        icon="mdi-math-integral-box"
        :dark="dark"></block-toolbar-button>

      <toolbar-textarea-color :editor="editor" close-button="true" />
      <toolbar-textarea-font-family :editor="editor" />

      <v-divider vertical></v-divider>

      <p-panel-input-variable
        :value="''"
        :blockVariables="blockVariables ? blockVariables : []"
        @input="name => insertVariable(name)" />
    </div>
  </div>
</template>

<script>
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import ToolbarTextareaColor from "./ToolbarTextareaColor.vue"
import ToolbarTextareaFontFamily from "./ToolbarTextareaFontFamily.vue"
import PPanelInputVariable from "../_components/utils/PPanelInputVariable.vue"
import { OrderListButton } from "plant-common"

export default {
  inject: ["blockGlobals", "lessonPlanController"],
  components: {
    BlockToolbarButton,
    ToolbarTextareaColor,
    ToolbarTextareaFontFamily,
    PPanelInputVariable,
    OrderListButton
  },
  props: ["editor", "data", "side", "id", "dark", "blockVariables"],
  methods: {
    insertVariable(name) {
      if (this.blockVariables) {
        var variable = this.blockVariables.find(variable => variable.name == name)
        var id = variable.name
        var label = `{{${variable.name}}}`
        this.editor.commands.setFields({ id, label })
      }
    },
    setSubscript() {
      this.editor.chain().focus().toggleSubscript().run()
      this.editor.chain().focus().unsetSuperscript().run()
    },
    setSuperscript() {
      this.editor.chain().focus().toggleSuperscript().run()
      this.editor.chain().focus().unsetSubscript().run()
    },
    async pasteWithFormat() {
      let textClipboard = await navigator.clipboard.readText()
      this.editor.chain().focus().pasteFormat(textClipboard).run()
    },
    async pasteWithoutFormat() {
      let textClipboard = await navigator.clipboard.readText()
      this.editor.chain().focus().pasteText(textClipboard).run()
    }
  }
}
</script>

<style>
.lp-toolbar-textarea {
  display: flex;
  align-items: center;
}
</style>
