var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-block',{attrs:{"value":_vm.value,"mode":_vm.mode,"state":_vm.state},on:{"update:state":function($event){_vm.state=$event}},scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.displayHandler)?_c('ShapeControlHandle',{staticClass:"shape-arrow-90-deg-control-handler-x",attrs:{"x":_vm.r,"x-range":[0, Math.min(_vm.s, _vm.h / 2 + _vm.tB / 2)],"y":0,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.radius = x / _vm.w / _vm.h)}}):_vm._e(),(_vm.displayHandler)?_c('ShapeControlHandle',{staticClass:"shape-arrow-90-deg-control-handler-x",attrs:{"x":_vm.s,"x-range":[_vm.w * 0.51, _vm.w],"y":0,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.slope = x / _vm.w)}}):_vm._e(),(_vm.displayHandler)?_c('ShapeControlHandle',{staticClass:"shape-arrow-90-deg-control-handler-y",attrs:{"x":_vm.w,"y":_vm.tH,"y-range":[5, _vm.h],"angle":_vm.value.rotation},on:{"update:y":y => (_vm.value.data.controls.thickHead = y / _vm.h)}}):_vm._e(),(_vm.displayHandler)?_c('ShapeControlHandle',{staticClass:"shape-arrow-90-deg-control-handler-y",attrs:{"x":_vm.tB,"x-range":[5, _vm.w * 0.49],"y":_vm.h,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.thickBody = x / _vm.w)}}):_vm._e(),_c('svg',{staticClass:"shape-arrow-90-deg",staticStyle:{"vertical-align":"top"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":"100%","height":"100%","preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`}},[_c('defs',[_c('path',{style:(_vm.value.data.style),attrs:{"id":`path-${_vm.id}`,"d":`
              M ${_vm.w} ${_vm.tH / 2}
              L ${_vm.s} 0
              L ${_vm.s} ${(_vm.tH - _vm.tB) / 2}
              L ${_vm.r} ${(_vm.tH - _vm.tB) / 2}
              A ${_vm.r} ${_vm.r} 0 0 0 0 ${(_vm.tH - _vm.tB) / 2 + _vm.r}
              L 0 ${(_vm.tH - _vm.tB) / 2 + _vm.r}
              L 0 ${_vm.h}
              L ${_vm.tB} ${_vm.h}
              L ${_vm.tB} ${(_vm.tH + _vm.tB) / 2 + _vm.rI}
              A ${_vm.rI} ${_vm.rI} 0 0 1 ${_vm.tB + _vm.rI} ${(_vm.tH + _vm.tB) / 2}
              L ${_vm.tB + _vm.rI} ${(_vm.tH + _vm.tB) / 2}
              L ${_vm.s} ${(_vm.tH + _vm.tB) / 2}
              L ${_vm.s} ${_vm.tH}
              L ${_vm.w} ${_vm.tH / 2}
            `,"stroke-linecap":"round"}}),_c('clipPath',{attrs:{"id":`clip-${_vm.id}`}},[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`}})])]),_c('g',[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`,"clip-path":`url(#clip-${_vm.id})`}})])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }