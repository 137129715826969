<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title> Outline </v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <div>
      <v-treeview
        :items="headingsTree"
        :open.sync="open"
        :active.sync="active"
        activatable
        active-class="primary--text"
        class="pointer">
        <template> </template>
      </v-treeview>
    </div>
  </div>
</template>

<script>
import uuid from "../../../utils/uuid"
export default {
  name: "InteractiveTextOutline",
  inject: ["controller"],
  components: {},
  data() {
    return {
      open: [],
      active: []
    }
  },
  mounted() {
    this.headings.forEach(heading => {
      this.open.push(heading.id)
    })
  },
  computed: {
    headings() {
      const headings = this.controller.editor.contentComponent.$el.querySelectorAll("h1, h2, h3")
      const formatedHeadings = []
      headings.forEach(heading => {
        formatedHeadings.push({
          id: heading.id,
          level: heading.tagName,
          title: heading.textContent
        })
      })
      return formatedHeadings
    },
    headingsTree() {
      const headingTreeArray = []
      let headingH1 = false
      let headingH2 = false
      this.headings.forEach(heading => {
        let headingValue = {
          id: heading.id,
          name: heading.title,
          children: []
        }
        switch (heading.level) {
          case "H1":
            headingTreeArray.push(headingValue)
            headingH1 = headingValue
            break
          case "H2":
            const parentH1 = headingTreeArray.find(heading => heading.id === headingH1.id)
            if (parentH1) {
              parentH1.children.push(headingValue)
            } else {
              const idParentH1 = uuid()
              headingTreeArray.push({
                id: idParentH1,
                name: "No H1",
                children: []
              })
              headingTreeArray.find(heading => heading.id === idParentH1).children.push(headingValue)
            }
            headingH2 = headingValue
            break
          case "H3":
            const grandparentH1 = headingTreeArray.find(heading => heading.id === headingH1.id)
            if (grandparentH1) {
              const parentH2 = grandparentH1.children.find(heading => heading.id === headingH2.id)
              if (parentH2) {
                parentH2.children.push(headingValue)
              } else {
                const idParentH2 = uuid()
                grandparentH1.children.push({
                  id: idParentH2,
                  name: "No H2",
                  children: []
                })
                grandparentH1.find(heading => heading.id === idParentH2).children.push(headingValue)
              }
            } else {
              const idParentH1 = uuid()
              headingTreeArray.push({
                id: idParentH1,
                name: "No H1",
                children: []
              })
              const idParentH2 = uuid()
              const grandparentH1Aux = headingTreeArray.find(heading => heading.id === idParentH1)
              grandparentH1Aux.children.push({ id: idParentH2, name: "No H2", children: [] })
              grandparentH1Aux.children.find(heading => heading.id === idParentH2).children.push(headingValue)
            }
            break
        }
      })
      return headingTreeArray
    }
  },
  watch: {
    active() {
      const headingExists = this.headings.find(heading => heading.id === this.active[0])
      if (headingExists) {
        this.goToHeader(headingExists.id)
      }
    }
  },
  methods: {
    getML(level) {
      let mlClass = "ml-0"
      switch (level) {
        case "H1":
          mlClass = "ml-2"
          break
        case "H2":
          mlClass = "ml-4"
          break
        case "H3":
          mlClass = "ml-5"
          break
      }
      return mlClass
    },
    goToHeader(id) {
      this.controller.setHeaderToGo(id)
    }
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
