<template>
  <fragment>
    <p-panel-properties-group title="Tab fonts">
      <p-property-tile icon="mdi-format-font" title="Font-family">
        <div style="width: 40%">
          <v-select
            solo
            flat
            hide-details
            single-line
            :items="blockGlobals.fontFamilies"
            v-model="value.data.fontFamily"></v-select>
        </div>
      </p-property-tile>

      <p-property-tile icon="mdi-format-size" title="Font-size">
        <div style="width: 40%">
          <v-select solo flat hide-details single-line :items="fontSizes" v-model="value.data.fontSize"></v-select>
        </div>
      </p-property-tile>
    </p-panel-properties-group>
    <p-panel-properties-group title="Tabs style">
      <p class="lp-block-tabs-panel">Normal:</p>
      <style-select :value="value.data.style.tabs.normal" title="Normal" />
      <p class="lp-block-tabs-panel">Active:</p>
      <style-select :value="value.data.style.tabs.active" title="Active" />
      <p class="lp-block-tabs-panel">Visited:</p>
      <style-select :value="value.data.style.tabs.visited" title="Visited" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Arrows">
      <p class="lp-block-tabs-panel">Normal:</p>
      <style-select :value="value.data.style.arrows.normal" title="Normal" />
      <p class="lp-block-tabs-panel">Hover:</p>
      <style-select :value="value.data.style.arrows.hover" title="Active" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Canvas">
      <p class="lp-block-carousel-panel">Background:</p>
      <style-select :value="value.data.style.canvas.normal" title="Normal" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Tabs">
      <v-list>
        <draggable v-model="value.data.tabs" handle=".handle-tab-list">
          <v-list-tile v-for="(tab, index) in value.data.tabs" :key="index">
            <v-list-tile-action>
              <v-icon class="handle-tab-list" style="cursor: row-resize">mdi-drag</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-text-field
                style="width: 100%"
                box
                hide-details
                full-width
                single-line
                type="text"
                v-model="tab.title"
                label="Title"
                height="20px"
                @click:append-outer="removeTab(index)"
                append-outer-icon="mdi-delete-outline"></v-text-field>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon class="handle-tab-list" @click="duplicateTab(index)">mdi-content-duplicate</v-icon>
            </v-list-tile-action>
          </v-list-tile>
        </draggable>
        <v-list-tile class="mt-3">
          <v-list-tile-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn block outline color="primary" @click="addTab" v-on="on">
                  <v-icon left>mdi-folder-plus-outline</v-icon>add tab
                </v-btn>
              </template>
              Add a new Tab
            </v-tooltip>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </p-panel-properties-group>
  </fragment>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputNumber from "../_components/utils/PPanelInputNumber.vue"
import { fontSizesNumbers } from "../_components/panel/fontGlobalCommon"
import StyleSelect from "../../components/slides/styles/StyleSelect.vue"
import { Fragment } from "vue-fragment"
import { BlockTab } from "./class.js"
import Draggable from "vuedraggable"
import ItemStyle from "../../components/slides/styles/ItemStyle.vue"
import { changeAllBlockIds } from "../../utils/utils"

export default {
  inject: ["blockGlobals"],
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelPropertiesGroup,
    PPanelInputNumber,
    Fragment,
    StyleSelect,
    ItemStyle,
    Draggable
  },

  data() {
    return {
      fontSizes: fontSizesNumbers
    }
  },
  methods: {
    addTab() {
      this.value.data.tabs.push(new BlockTab())
    },
    removeTab(index) {
      this.value.data.tabs.splice(index, 1)
    },
    duplicateTab(index) {
      let currentTab = this.value.data.tabs[index]
      let newTab = JSON.parse(JSON.stringify(currentTab))
      changeAllBlockIds(newTab)
      this.value.data.tabs.push(newTab)
    }
  }
}
</script>

<style>
.lp-block-tabs-panel {
  font-size: 13px;
  margin: 5px 0 0 25px;
  font-weight: 500;
}
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 40% !important;
}
</style>
