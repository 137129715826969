export default {
  methods: {
    moveBlock(blocks, id, position, isRelative = false) {
      const indexFound = blocks.findIndex((el) => el.id == id)

      if (indexFound > -1) {
        let newPos = isRelative ? indexFound + position : position
        const block = blocks[indexFound]
        if (position < 0 && !isRelative) {
          newPos = blocks.length - 1
        }
        if (newPos >= 0 && newPos < blocks.length) {
          blocks.splice(indexFound, 1)
          blocks.splice(newPos, 0, block)
          this.$nextTick(() => {
            this.selectionClear()
            this.selectionAdd(id)
          })
        }
      } else {
        for (const block of blocks) {
          if (block.hasOwnProperty("blocks")) {
            if (!this.moveBlock(block.blocks, id, position, isRelative)) {
              break
            }
          } else if (block.type === "carousel") {
            if (!this.moveBlock(block.data.cards, id, position, isRelative)) {
              break
            }
          } else if (block.type === "tabs") {
            if (!this.moveBlock(block.data.tabs, id, position, isRelative)) {
              break
            }
          } else if (block.type === "contentMultipleChoiceQuestion" || block.type === "contentMultipleChoiceQuestionTemplate" || block.type === "adaptiveLearningResult") {
            if (!this.moveBlock(block.data.blocks, id, position, isRelative)) {
              break
            }
            if (!this.moveBlock(block.data.overlays.correct.blocks, id, position, isRelative)) {
              break
            }
            if (!this.moveBlock(block.data.overlays.incorrect.blocks, id, position, isRelative)) {
              break
            }
          } else if (block.type === "flashCard") {
            if (!this.moveBlock(block.data.blocks, id, position, isRelative)) {
              break
            }
            if (!this.moveBlock(block.data.overlays.answer.blocks, id, position, isRelative)) {
              break
            }
          } else if (block.type === "libraryContentCustom") {
            if (!this.moveBlock(block.data.blocks, id, position, isRelative)) {
              break
            }
            if (!this.moveBlock(block.data.overlays.expanded.blocks, id, position, isRelative)) {
              break
            }
          }
          if (block.actions && block.actions.length > 0) {
            const popup = block.actions.find(action => action.type == "popup")
            if (popup) {
              if (!this.moveBlock(popup.blocks, id, position, isRelative)) {
                break
              }
            }
          }
        }
        return -1
      }
    },
    moveToTop(id) {
      this.moveBlock(this.blocks, id, -1, false)
    },
    moveToBack(id) {
      this.moveBlock(this.blocks, id, 0, false)
    },
    moveBlockUp(id) {
      // Move block to position +1 respect current position
      // If not possible the block does not move

      this.moveBlock(this.blocks, id, +1, true)
    },
    moveBlockDown(id) {
      // Move block to position -1 respect current position
      // If not possible the block does not move

      this.moveBlock(this.blocks, id, -1, true)
    },
    reorderBlock(id, newTree, reversed = false) {
      function getPositionInSiblings(elements, id, reversed) {
        for (const [index, element] of elements.entries()) {
          if (element.id == id) {
            return reversed ? elements.length - index - 1 : index
          } else if (element.children) {
            const siblingPosition = getPositionInSiblings(element.children, id, reversed)
            if (siblingPosition > -1) return siblingPosition
          }
        }
      }

      const positionInSibling = getPositionInSiblings(newTree, id, reversed)
      if (positionInSibling > -1) {
        this.moveBlock(this.blocks, id, positionInSibling)
      }
    }
  }
}