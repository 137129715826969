<template>
  <p-panel title="Instructional Settings">
    <p-instructional-settings
      :isEdit="isEdit"
      :instructionalSettings="instructionalSettings"></p-instructional-settings>
  </p-panel>
</template>

<script>
import PPanel from "../../blocks/_components/utils/PPanel.vue"
import PInstructionalSettings from "plant-common/src/components/PInstructionalSettings.vue"

export default {
  inject: ["lessonPlanController", "controller"],
  components: {
    PPanel,
    PInstructionalSettings
  },
  data() {
    return {
      myController: null
    }
  },
  created() {
    this.myController = this.lessonPlanController ? this.lessonPlanController : this.controller
    if (!this.instructionalSettings) {
      this.$set(this.myController.vm.value.body.contents, "instructionalSettings", {
        completionTime: 0,
        objective: undefined
      })
    }
    if (!this.instructionalSettings.completionTime) {
      this.$set(this.myController.vm.value.body.contents.instructionalSettings, "completionTime", 0)
    }
    if (!this.instructionalSettings.objective) {
      this.$set(this.myController.vm.value.body.contents.instructionalSettings, "objective", undefined)
    }
  },
  computed: {
    instructionalSettings() {
      if (this.myController.vm.value.body.contents.instructionalSettings) {
        return this.myController.vm.value.body.contents.instructionalSettings
      } else {
        return false
      }
    },
    isEdit() {
      return this.myController.mode === "edit"
    }
  }
}
</script>
