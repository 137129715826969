import parseBoolean from "./config/parseBoolean"
import "./pt-logic-memory-rs-auto.css"
export default {
  model: {
    "value": {
      default: false
    },
    "active": {
      default: false
    }
  },
  computed: {
    input_set() {
      var [, id, prop] = this.$el.dataset.inputs.split(/=|:/)
      return parseBoolean(this.$model[id][prop])
    }
  },
  watch: {
    input_set() {
      var time = parseInt(this.$el.dataset.time || 3)
      if (this.data.input_set) {
        this.data.value = true
        this.data.active = true
        setTimeout(() => {
          this.data.active = false
          this.data.value = false
        }, time * 1000)
      } 
    },

  }
}
