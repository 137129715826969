import Content from "./index";
import ExploreLibrary from "./ExploreLibrary";

export default {
  path: "explorer",
  name: "Content Explorer",
  component: Content,
  children: [
    {
      path: ":library_id",
      name: "Explore Library",
      props: true,
      component: ExploreLibrary,
    },
  ],
};
