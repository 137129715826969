const mimeText = [
  "text/1d-interleaved-parityfec",
  "text/cache-manifest",
  "text/calendar",
  "text/cql",
  "text/cql-expression",
  "text/cql-identifier",
  "text/css",
  "text/csv",
  "text/csv-schema",
  "text/directory",
  "text/dns",
  "text/ecmascript",
  "text/encaprtp",
  "text/example",
  "text/fhirpath",
  "text/flexfec",
  "text/fwdred",
  "text/gff3",
  "text/grammar-ref-list",
  "text/hl7v2",
  "text/html",
  "text/javascript",
  "text/jcr-cnd",
  "text/markdown",
  "text/mizar",
  "text/n3",
  "text/parameters",
  "text/parityfec",
  "text/provenance-notation",
  "text/prs.fallenstein.rst",
  "text/prs.lines.tag",
  "text/prs.prop.logic",
  "text/prs.texi",
  "text/raptorfec",
  "text/RED",
  "text/rfc822-headers",
  "text/rtf",
  "text/rtp-enc-aescm128",
  "text/rtploopback",
  "text/rtx",
  "text/SGML",
  "text/shaclc",
  "text/shex",
  "text/spdx",
  "text/strings",
  "text/t140",
  "text/tab-separated-values",
  "text/troff",
  "text/turtle",
  "text/ulpfec",
  "text/uri-list",
  "text/vcard",
  "text/vnd.a",
  "text/vnd.abc",
  "text/vnd.ascii-art",
  "text/vnd.curl",
  "text/vnd.debian.copyright",
  "text/vnd.DMClientScript",
  "text/vnd.dvb.subtitle",
  "text/vnd.esmertec.theme-descriptor",
  "text/vnd.exchangeable",
  "text/vnd.familysearch.gedcom",
  "text/vnd.ficlab.flt",
  "text/vnd.fly",
  "text/vnd.fmi.flexstor",
  "text/vnd.gml",
  "text/vnd.graphviz",
  "text/vnd.hans",
  "text/vnd.hgl",
  "text/vnd.in3d.3dml",
  "text/vnd.in3d.spot",
  "text/vnd.IPTC.NewsML",
  "text/vnd.IPTC.NITF",
  "text/vnd.latex-z",
  "text/vnd.motorola.reflex",
  "text/vnd.ms-mediapackage",
  "text/vnd.net2phone.commcenter.command",
  "text/vnd.radisys.msml-basic-layout",
  "text/vnd.senx.warpscript",
  "text/vnd.si.uricatalogue",
  "text/vnd.sun.j2me.app-descriptor",
  "text/vnd.sosi",
  "text/vnd.trolltech.linguist",
  "text/vnd.wap.si",
  "text/vnd.wap.sl",
  "text/vnd.wap.wml",
  "text/vnd.wap.wmlscript",
  "text/vtt",
  "text/wgsl",
  "text/xml",
  "text/xml-external-parsed-entity"
]

export default mimeText
