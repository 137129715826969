<template>
  <div class="lp-toolbar-textarea-font-family">
    <v-overflow-btn
      :items="blockGlobals.fontFamilies"
      label="Font Family"
      @change="editor.chain().focus().setFontFamily($event).run()"
      :value="editorFontFamily" />
  </div>
</template>

<script>
export default {
  inject: ["blockGlobals"],
  props: ["editor"],

  computed: {
    editorFontFamily() {
      return this.editor.getAttributes("textStyle").fontFamily
    }
  }
}
</script>

<style>
.lp-toolbar-textarea-font-family {
  width: 220px;
  height: 40px;
  margin-top: -8px;
}
</style>