<template>
  <p-panel title="Block Properties" v-if="selectedBlock">
    <template #toolbar>
      <v-toolbar-title class="subheading">
        Block Properties <span v-if="selectedBlock && selectedBlock.type">({{ selectedBlock.type }})</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" width="800" v-if="macrosActive">
        <template v-slot:activator="{ on }">
          <v-btn flat icon v-on="on"> <v-icon small>mdi-xml</v-icon></v-btn>
        </template>
        <v-toolbar>
          <v-toolbar-title><v-icon left>mdi-xml</v-icon> Block Code</v-toolbar-title>
        </v-toolbar>
        <v-card flat>
          <v-card-text>
            <p-code-editor :value="JSON.stringify(selectedBlock, null, 2)" />
          </v-card-text>
        </v-card>
        <template #actions>
          <span></span>
        </template>
      </v-dialog>
    </template>
    <p-panel-properties>
      <p-panel-properties-group title="General" v-if="selectedBlock.type != 'shapeLine'">
        <div class="pl-4 pr-4">
          <v-text-field box v-model="selectedBlock.name" label="Block name"></v-text-field>
          <div>
            <item-style
              icon="mdi-alpha-x-box"
              title="Position X"
              class="lp-animation-form-item"
              v-if="selectedBlock.position.x !== 'none'">
              <p-panel-input-number v-model="selectedBlock.position.x" :disabled="selectedBlock.protection.move" />
            </item-style>
            <item-style
              icon="mdi-alpha-y-box"
              title="Position Y"
              class="lp-animation-form-item"
              v-if="selectedBlock.position.y !== 'none'">
              <p-panel-input-number v-model="selectedBlock.position.y" :disabled="selectedBlock.protection.move" />
            </item-style>
          </div>
          <div>
            <item-style
              icon="mdi-arrow-left-right-bold-outline"
              title="Width"
              class="lp-animation-form-item"
              v-if="selectedBlock.size.width !== 'none'">
              <p-panel-input-number
                v-model="selectedBlock.size.width"
                :disabled="selectedBlock.protection.resize"
                :min="0" />
            </item-style>
            <item-style
              icon="mdi-arrow-up-down-bold-outline"
              title="Height"
              class="lp-animation-form-item"
              v-if="selectedBlock.size.height !== 'none'">
              <p-panel-input-number
                v-model="selectedBlock.size.height"
                :disabled="selectedBlock.protection.resize"
                :min="0" />
            </item-style>
          </div>
          <div>
            <item-style
              icon="mdi-rotate-left"
              title="Rotation"
              class="lp-animation-form-item"
              v-if="selectedBlock.rotation !== 'none'">
              <p-panel-input-number v-model="selectedBlock.rotation" :min="-90" :max="270" />
            </item-style>
          </div>
        </div>
      </p-panel-properties-group>
      <p-panel-properties-group title="General" v-else>
        <div class="pl-4 pr-4">
          <v-text-field box v-model="selectedBlock.name" label="Block name" />
          <div>
            <!-- Begin X -->
            <item-style icon="mdi-ray-start" title="Begin X" class="lp-animation-form-item">
              <p-panel-input-number
                v-model="selectedBlock.data.begin.x"
                @input="val => updateLineSizePosition('begin', 'x', val)"
                :min="0" />
            </item-style>

            <!-- Begin Y -->
            <item-style icon="mdi-ray-start" title="Begin Y" class="lp-animation-form-item">
              <p-panel-input-number
                v-model="selectedBlock.data.begin.y"
                @input="val => updateLineSizePosition('begin', 'y', val)"
                :min="0" />
            </item-style>
          </div>
          <div>
            <!-- End X -->
            <item-style icon="mdi-ray-end" title="End X" class="lp-animation-form-item">
              <p-panel-input-number
                v-model="selectedBlock.data.end.x"
                @input="val => updateLineSizePosition('end', 'x', val)"
                :min="0" />
            </item-style>

            <!-- End Y -->
            <item-style icon="mdi-ray-end" title="End Y" class="lp-animation-form-item">
              <p-panel-input-number
                v-model="selectedBlock.data.end.y"
                @input="val => updateLineSizePosition('end', 'y', val)"
                :min="0" />
            </item-style>
          </div>
        </div>
      </p-panel-properties-group>

      <!-- Protections -->
      <p-panel-properties-group title="Protections">
        <protection-form :value="selectedBlock" />
      </p-panel-properties-group>

      <component
        v-if="selectedBlockPanel && !selectedBlock.protection.data"
        :is="selectedBlockPanel"
        :key="selectedBlock.id"
        :value="selectedBlock" />
      <v-alert
        :value="true"
        color="info"
        icon="info"
        outline
        class="ma-2"
        v-if="selectedBlockPanel && selectedBlock.protection.data">
        <b>Note:</b> Additional panels are not displayed, this block is protected against Change content.
      </v-alert>
    </p-panel-properties>
  </p-panel>
</template>

<script>
import ProtectionForm from "./ProtectionForm.vue"
import blocks from "../../index.js"
import PPanelPropertiesGroup from "../utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../utils/PPanelProperties.vue"
import PPanelInputNumber from "../utils/PPanelInputNumber.vue"
import PPanel from "../utils/PPanel.vue"
import { PContentTile } from "plant-common"
import ItemStyle from "../../../components/slides/styles/ItemStyle.vue"
import StyleSelect from "../../../components/slides/styles/StyleSelect.vue"
import { PCodeEditor } from "plant-common"

export default {
  name: "BlockProperties",
  props: ["selectedBlock"],
  components: {
    PCodeEditor,
    ProtectionForm,
    PPanelPropertiesGroup,
    PPanelProperties,
    PPanel,
    PPanelInputNumber,
    ItemStyle,
    StyleSelect,
    PContentTile
  },
  data() {
    return {
      panel: [true, false],
      dialog: false,
      previousPanelType: null,
      previousPanel: null
    }
  },
  computed: {
    macrosActive() {
      return localStorage.PlantUserMacrosActive == "true"
    },
    selectedBlockPanel() {
      if (!this.selectedBlock) return false
      const { type } = this.selectedBlock
      if (this.previousPanelType !== type) {
        this.previousPanelType = type
        if (blocks[type] && blocks[type].Panel) {
          this.previousPanel = blocks[type].Panel
        } else {
          this.previousPanel = false
        }
      }
      return this.previousPanel
    }
  },
  methods: {
    updateLineSizePosition(beginOrEnd, XorY, val) {
      this.selectedBlock.data[beginOrEnd][XorY] = parseInt(val)
      this.selectedBlock.position.x = Math.min(this.selectedBlock.data.begin.x, this.selectedBlock.data.end.x)
      this.selectedBlock.position.y = Math.min(this.selectedBlock.data.begin.y, this.selectedBlock.data.end.y)
      this.selectedBlock.size.width = Math.max(
        1,
        Math.abs(this.selectedBlock.data.begin.x - this.selectedBlock.data.end.x)
      )
      this.selectedBlock.size.height = Math.max(
        1,
        Math.abs(this.selectedBlock.data.begin.y - this.selectedBlock.data.end.y)
      )
    }
  },
  watch: {
    "selectedBlock.rotation"(newVal) {
      if (newVal === "") {
        this.selectedBlock.rotation = 0
      }
    }
  }
}
</script>

<style>
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
