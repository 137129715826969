import { render, staticRenderFns } from "./GlbModelViewer.vue?vue&type=template&id=7f3a1782&scoped=true"
import script from "./GlbModelViewer.vue?vue&type=script&lang=js"
export * from "./GlbModelViewer.vue?vue&type=script&lang=js"
import style0 from "./GlbModelViewer.vue?vue&type=style&index=0&id=7f3a1782&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f3a1782",
  null
  
)

export default component.exports