<template>
  <v-stepper-content step="0">
    <v-card>
      <v-layout>
        <v-flex xs5>
          <v-img :src="require('./step-0.svg')" height="225px" contain></v-img>
        </v-flex>
        <v-flex xs7>
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">"Guides" are now "Interactive Presentations"</h3>
              <p class="subheading">
                The "Interactive Presentations" have new and exciting features. We will ask you a few questions to
                update your “Guide” to this new format.
              </p>
              <p class="subheading">Before continuing:</p>
              <ul class="subheading">
                <li>
                  You have to create an "Interactive Presentation" with the layouts you had, to be able to convert the
                  Guide to the new format.
                </li>
                <li>
                  You also have to create Objectives from each learning objective and assign them to the sections after
                  the transition is completed.
                </li>
              </ul>
            </div>
          </v-card-title>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-btn block flat e2e-id="skip-transition" @click="$emit('closeDialog')">Maybe later</v-btn>
        <v-btn block color="#48C634" dark @click="goToStep1">Start</v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
export default {
  props: ["step"],
  methods: {
    goToStep1() {
      this.$emit("setStep", 1)
    }
  }
}
</script>
