import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeTriangle"
    this.size.width = 400
    this.size.height = 400
    this.data = {
      controls: {
        slope: 0.5
      },
      style: {
        fill: "#000000ff",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
