import Routes from "@/pages/router.js"
import Vue from "vue"
import VueRouter from "vue-router"
import guards from "./guards"
import autostore from "./autostore"


Vue.use(VueRouter)

var router = new VueRouter({
  mode: "history",
  routes: [
    ...Routes
  ]
})

router.beforeEach(guards)
router.beforeEach(autostore)
export default router