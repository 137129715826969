import ptCoreClient from "plant-core-client"
import { STORE_FLEET_NAME, STATE_LIBRARIES, STATE_ALL_LIBRARIES, STATE_USERS, MUTATION_SET_LIBRARIES, MUTATION_SET_LIBRARY, MUTATION_REMOVE_LIBRARY, MUTATION_SET_USERS, MUTATION_SET_USER, MUTATION_REMOVE_USER, ACTION_GET_LIBRARIES, ACTION_DELETE_LIBRARY, ACTION_CREATE_LIBRARY, ACTION_UPDATE_LIBRARY, ACTION_DELETE_USER, ACTION_CREATE_USER, ACTION_UPDATE_USER, ACTION_GET_USERS } from "@/store_constants/fleet";

export default {
    namespaced: true,
    name: STORE_FLEET_NAME,
    state: {
        [STATE_LIBRARIES]: [],
        [STATE_ALL_LIBRARIES]: [],
        [STATE_USERS]: []
    },
    mutations: {
        [MUTATION_SET_LIBRARIES](state, libraries) {
            state[STATE_LIBRARIES] = libraries.filter(library => library.active)
            state[STATE_ALL_LIBRARIES] = libraries
        },
        [MUTATION_SET_LIBRARY](state, library) {
            state.libraries.push(library)
        },
        [MUTATION_REMOVE_LIBRARY](state, library_id) {
            state.libraries.splice(state.libraries.findIndex(obj => obj._id === library_id), 1);
        },
        [MUTATION_SET_USERS](state, users) {
            state[STATE_USERS] = users;
        },
        [MUTATION_SET_USER](state, user) {
            state[STATE_USERS].push(user);
        },
        [MUTATION_REMOVE_USER](state, user_id) {
            state[STATE_USERS].splice(state[STATE_USERS].findIndex(obj => obj._id === user_id), 1);
        },
    },
    actions: {
        [ACTION_GET_LIBRARIES]({ commit, dispatch }) {
            return ptCoreClient.getAllPlants()
                .then(res => {
                    commit(MUTATION_SET_LIBRARIES, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_DELETE_LIBRARY]({ commit, dispatch }, plant_id) {
            return ptCoreClient.deletePlant(plant_id)
                .then(res => {
                    commit(MUTATION_REMOVE_LIBRARY, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_CREATE_LIBRARY]({ commit, dispatch }, { name, desc, img }) {
            // Fleet server doesen't support base64 img with the image format header of Base64Upload
            img = "ZW1wdHk="; // "Empty" in base64
            return ptCoreClient.createPlant(name, desc, img)
                .then(res => {
                    commit(MUTATION_SET_LIBRARY, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_UPDATE_LIBRARY]({ commit, dispatch }, { id, name, description, active, img }) {
            // Fleet server doesen't support base64 img with the image format header of Base64Upload
            img = "ZW1wdHk="; // "Empty" in base64
            return ptCoreClient.updatePlant(id, { name, description, img, active })
                .then(res => {
                    dispatch(ACTION_GET_LIBRARIES);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_GET_USERS]({ commit, dispatch }) {
            return ptCoreClient.getAllUsers()
                .then(res => {
                    commit(MUTATION_SET_USERS, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_DELETE_USER]({ commit, dispatch }, user_id) {
            return ptCoreClient.deleteUserById(user_id)
                .then(res => {
                    commit(MUTATION_REMOVE_USER, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_CREATE_USER]({ commit, dispatch }, { email, name, role }) {
            console.log({ email, name, role });
            return ptCoreClient.createUser(email, name, role)
                .then(res => {
                    commit(MUTATION_SET_USER, res);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_UPDATE_USER]({ commit, dispatch }, { id, email, name, role, disabled }) {
            return ptCoreClient.updateUser(id, { email, name, role, disabled })
                .then(res => {
                    dispatch(ACTION_GET_USERS);
                    return res;
                }).catch(e => { return e; })
        }
    }
}