import uuid from "../../../../../utils/uuid"

export default {
  methods: {
    getBlockChildren(blocks) {
      const children = []

      for (const block of blocks) {
        children.push({
          id: block.id,
          name: block.title,
          type: block.type || "tabOrCard",
          state: "not-selected",
          children: this.buildBranch(block.blocks)
        })
      }

      return children
    },
    addPopupChildren(block, children) {
      if (block.actions) {
        for (const [index, action] of block.actions.entries()) {
          if (action.type == "popup") {
            if (!action.id) action.id = uuid()
            children.push({
              id: action.id,
              name: action.type,
              state: "not-selected",
              children: action.blocks ? this.buildBranch(action.blocks) : []
            })
          }
        }
      }
    },
    buildBranch(blocks) {
      let selectionTreeElements = []

      for (const block of blocks) {
        const { id, type } = block
        let { name } = block

        let children = undefined
        switch (type) {
          case "carousel":
            children = this.getBlockChildren(block.data.cards)
            this.addPopupChildren(block, children)
            break
          case "tabs":
            children = this.getBlockChildren(block.data.tabs)
            this.addPopupChildren(block, children)
            break
          case "flashCard":
            children = []
            children.push({
              id: uuid(),
              name: "FlashCard Question",
              state: "not-selected",
              children: this.buildBranch(block.data.blocks)
            })
            if (!block.data.overlays.answer.id) block.data.overlays.answer.id = uuid()
            children.push({
              id: block.data.overlays.answer.id,
              name: "FlashCard Answer",
              state: "not-selected",
              children: this.buildBranch(block.data.overlays.answer.blocks)
            })
            this.addPopupChildren(block, children)
            break
          case "libraryContentCustom":
            children = []
            children.push({
              id: uuid(),
              name: "Library Content",
              state: "not-selected",
              children: this.buildBranch(block.data.blocks)
            })
            if (!block.data.overlays.expanded.id) block.data.overlays.expanded.id = uuid()
            children.push({
              id: block.data.overlays.expanded.id,
              name: "Library Content Expanded",
              state: "not-selected",
              children: this.buildBranch(block.data.overlays.expanded.blocks)
            })
            this.addPopupChildren(block, children)
            break
          case "contentMultipleChoiceQuestion":
          case "contentMultipleChoiceQuestionTemplate":
            children = []
            children.push({
              id: uuid(),
              name: "Multiple Choice Question",
              state: "not-selected",
              children: this.buildBranch(block.data.blocks)
            })
            if (!block.data.overlays.correct.id) block.data.overlays.correct.id = uuid()
            children.push({
              id: block.data.overlays.correct.id,
              name: "Multiple Choice - Correct",
              state: "not-selected",
              children: this.buildBranch(block.data.overlays.correct.blocks)
            })
            if (!block.data.overlays.incorrect.id) block.data.overlays.incorrect.id = uuid()
            children.push({
              id: block.data.overlays.incorrect.id,
              name: "Multiple Choice - Incorrect",
              state: "not-selected",
              children: this.buildBranch(block.data.overlays.incorrect.blocks)
            })
            this.addPopupChildren(block, children)
            break
          case "adaptiveLearningResult":
            children = []
            children.push({
              id: uuid(),
              name: "Adaptive Learning Result",
              state: "not-selected",
              children: this.buildBranch(block.data.blocks)
            })
            this.addPopupChildren(block, children)
            break
          default:
            // "Simple Block": check for actions
            children = []
            this.addPopupChildren(block, children)
            break
        }
        if (name === "") {
          name = type + " " + id.slice(-4)
        }
        var selectionElement = {
          id,
          name,
          type,
          state: "not-selected"
        }
        if (children && children.length > 0) {
          selectionElement.children = children
        }
        selectionTreeElements.push(selectionElement)
      }

      return selectionTreeElements
    },
    buildSelectionTree() {
      this.selectionTree = this.buildBranch(this.blocks)
    }
  }
}