//@ts-check

/**
 * @typedef  {('cover'|'section'| 'card' |'card-template')} CardType
 */


class GuideCardGeneric {
  constructor(type) {
    this.id = Math.floor(Math.random() * 10000000)
    this.type = type
    this.internal_link_name = ""    
  }

}
class GuideSection extends GuideCardGeneric {
  constructor() {
    super('section')
    this.objective = false
    this.fields = false
    this.children = []
  }
}
/**
 * @prop notes {string}
 * @prop content {false | ItemRevision | CardTemplateReference}
 * @prop view {Object} 
 */
class GuideCard extends GuideCardGeneric {
  /**
   * @prop type {"card" |"card-template"}
   */
  constructor(type = "card") {
    super(type)
    this.notes = ''
    this.content = false
    this.view = false
  }
}

class GuideCover extends GuideCardGeneric {
  constructor() {
    super('cover')
    this.fields = false
  }
}

class IRBGuide {
  constructor() {
    this.template = false
    this.cover = new GuideCover()
    this.sections = []
  }
}

export {
  GuideCard,
  GuideCardGeneric,
  GuideCover,
  GuideSection,
  IRBGuide
}