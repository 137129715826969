import Vue from "vue"

import { uploadFile } from "../../../utils"
import pannellum from "pannellum"
import css from "pannellum/src/css/pannellum.css"
import { shuffle } from "lodash"

class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = "" + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value.body.contents.vr || vm.value
  const questions = vm.value.body.contents.questions
  let view = false
  if (vm.$attrs.view) {
    view = vm.$attrs.view
  }
  return new Vue({
    data() {
      return {
        pannellum: false,
        config: false,
        current: {
          hfov: false,
          yaw: false,
          pitch: false
        }
      }
    },
    computed: {
      file() {
        if (itemRevision.body.attachments && itemRevision.body.attachments.length > 0) {
          return itemRevision.body.attachments[0]
        } else {
          return false
        }
      },
      defaultView() {
        if (view) {
          return view
        } else {
          return {
            hfov: itemRevision.body.contents.hfov || 120,
            yaw: itemRevision.body.contents.yaw || 0,
            pitch: itemRevision.body.contents.pitch || 0
          }
        }
      },
      itemRevisionObject() {
        return itemRevision
      },
      questions() {
        return shuffle(questions)
      },
      hotspots() {
        if (!itemRevision.body.contents.hotspots) itemRevision.body.contents.hotspots = []
        if (itemRevision.body.contents.hotspots.length > 0) {
          itemRevision.body.contents.hotspots.forEach(hp => {
            hp.type == "text" ? (hp.cssClass = "text-hotspot") : (hp.cssClass = "link-hotspot")
          })
          return itemRevision.body.contents.hotspots.filter(hs => hs.type === "text")
        } else {
          return []
        }
      }
    },
    methods: {
      registerPanzoom(panzoom) {
        this.panzoom = panzoom
      },
      hotspotClick(itemTag) {
        vm.$emit("select", itemTag)
      },
      zoomIn() {
        this.pannellum.setHfov(this.pannellum.getHfov() - 10)
      },
      zoomOut() {
        this.pannellum.setHfov(this.pannellum.getHfov() + 10)
      },
      zoomReset() {
        this.pannellum.lookAt(this.defaultView.pitch, this.defaultView.yaw, this.defaultView.hfov)
      },
      setView(hs) {
        this.pannellum.lookAt(hs.pitch, hs.yaw, hs.hfov)
      },
      destroyPannellum() {
        this.pannellum.destroy()
      },
      init({ divElement, clickHandlerFunc, createTooltipFunc, from }) {
        this.config = { divElement, clickHandlerFunc, createTooltipFunc }
        var initialView = this.defaultView
        if (from) {
          this.hotspots.forEach(hs => {
            if (hs.type == "link" && hs.itemRevisionId == from) {
              initialView.hfov = 120
              initialView.yaw = hs.yaw + (hs.yaw < 0 ? 180 : -180)
              initialView.pitch = hs.pitch
            }
          })
        }
        if (this.file) this.refresh(initialView)
      },
      refresh({ hfov, yaw, pitch }) {
        if (this.pannellum) {
          this.pannellum.destroy()
        }
        var hotSpots = JSON.parse(JSON.stringify(this.hotspots))
        var i = 0
        hotSpots.forEach(hs => {
          hs.clickHandlerFunc = this.config.clickHandlerFunc
          hs.clickHandlerArgs = i
          hs.createTooltipFunc = this.config.createTooltipFunc
          hs.createTooltipArgs = i
          i++
        })
        this.pannellum = window.pannellum.viewer(this.config.divElement, {
          type: "equirectangular",
          panorama: this.file.url,
          autoLoad: true,
          hotSpotDebug: true,
          hotSpots,
          showControls: false,
          hfov,
          yaw,
          pitch
        })
        this.pannellum.on("mouseup", () => {
          this.current.hfov = this.pannellum.getHfov()
          this.current.yaw = this.pannellum.getYaw()
          this.current.pitch = this.pannellum.getPitch()
        })
      },
      uploadPicture() {
        uploadFile().then(({ url, file }) => {
          vm.value.body.attachments.push(new Attachment("Picture", file, url, ".jpg"))
          this.init(this.config)
        })
      },
      removePicture() {
        this.pannellum.destroy()
        vm.value.body.attachments.splice(0)
        this.hotspots.splice(0)
      },
      addHotspot() {
        let newHotspot = {
          hfov: this.pannellum.getHfov(),
          yaw: this.pannellum.getYaw(),
          pitch: this.pannellum.getPitch(),
          value: "New",
          type: "text"
        }
        if (!vm.value.body.contents.hotspots) {
          Vue.set(vm.value.body.contents, "hotspots", [])
        }
        vm.value.body.contents.hotspots.push(newHotspot)
        this.refresh({ hfov: newHotspot.hfov, yaw: newHotspot.yaw, pitch: newHotspot.pitch })
      },
      removeHotspot(index) {
        vm.value.body.contents.hotspots.splice(index, 1)
        this.refresh({ hfov: this.pannellum.getHfov(), yaw: this.pannellum.getYaw(), pitch: this.pannellum.getPitch() })
      },
      setDefaultView() {
        vm.value.body.contents.hfov = this.pannellum.getHfov()
        vm.value.body.contents.pitch = this.pannellum.getPitch()
        vm.value.body.contents.yaw = this.pannellum.getYaw()
      }
    },
    watch: {
      current: {
        deep: true,
        handler() {
          vm.$emit("update:view", this.current)
        }
      }
    }
  })
}
