import { DatasheetConfigGroups } from './groups';
import { DatasheetConfigLevels } from './levels';
import { TextFieldTypes } from './textFieldTypes';
/**
 * @class  DatasheetConfigProperty
 *
 * @param title
 * @param name
 * @param type
 * @param group // General, Mechanic, Electric, Instrumentation, Control, Operation or Other
 * @param detailLevel // 0 (Show always), 1 (Basic), 2 (Intermediate) or 3 (Advanced)
 *
 */
export default class DatasheetConfigProperty {
	constructor() {
		this.title = '';
		this.name = '';
		this.type = TextFieldTypes[0].value;
		this.group = DatasheetConfigGroups[0];
		this.detailLevel = DatasheetConfigLevels[0];
	}
}
