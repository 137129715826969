import uuid from "../../../../../../utils/uuid.js"

/**
 * Button actions
 */
export class BlockAction {
  constructor(action) {
    this.id = uuid()
    if (!action) {
      // "hover" or "click"
      this.trigger = undefined
    } else {
      Object.assign(this, action)
    }
  }
}

export class BlockActionLink extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "link"
    this.bookmark_target = ""
  }
}

export class BlockActionExternalLink extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "externalLink"
    this.url = ""
  }
}

export class BlockActionDownloadAttachment extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "downloadAttachment"
    this.attachment = {}
  }
}

export class BlockActionTooltip extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "tooltip"
    this.text = ""
  }
}

export class BlockActionPopup extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "popup"
    this.width = 1720
    this.height = 880
    this.style = {
      backgroundColor: "#ffffffff",
      /* borderRadius will change to 50px */
      borderRadius: 0
    }
    this.blocks = []
  }
}

export class BlockActionLibraryContentPopup extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "library"
    this.fullWidth = true
    this.content_ref = {}
    this.view = {}
  }
}

export class BlockActionLibraryContentNewTab extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "libraryNewTab"
    this.content_ref = {}
  }
}

export class BlockActionEvent extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "event"
    this.event = ""
  }
}

export class BlockActionClose extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "close"
  }
}
