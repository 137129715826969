<template>
  <v-navigation-drawer permanent absolute stateless width="230px">
    <v-list dense>
      <v-subheader>Content Libraries</v-subheader>
      <v-list-tile
        v-for="(library, index) in libraries"
        :key="index"
        :to="{ name, params:{library_id: library._id}}"
        e2e-attr="plant-plant"
      >
        <v-list-tile-action>
          <v-icon>mdi-library-shelves</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-list-tile-title v-on="on">{{ library.name }}</v-list-tile-title>
            </template>
            <span>{{ library.name }}</span>
          </v-tooltip>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  STORE_FLEET_NAME,
  STATE_LIBRARIES,
  ACTION_GET_LIBRARIES,
} from "@/store_constants/fleet";
export default {
  props: {
    name: String,
  },
  created() {
    this.getLibraries();
  },
  computed: {
    ...mapState(STORE_FLEET_NAME, {
      libraries: STATE_LIBRARIES,
    }),
  },
  methods: {
    ...mapActions(STORE_FLEET_NAME, {
      getLibraries: ACTION_GET_LIBRARIES,
    }),
  },
};
</script>