import { PFile } from "plant-common/src/utils/models"
import PServiceContent from "plant-common/src/services/PServiceContent"
import DependentsForContent from "../../../lessonPlan/components/slides/modal/DependentsForContent.vue"
import { PtModal } from "plant-common"
import { prefixCss } from "plant-common/src/utils"

export default {
  mixins: [PtModal],
  data() {
    return {
      editTitleMode: false,
      cssContent: null
    }
  },
  methods: {
    prefixCss,
    addObjectiveToIB(objective) {
      this.contents.objectives.push(objective)
    },
    async importITFromObjective() {
      const objective = await PServiceContent.selectContent({ type: "objective" })
      if (!objective) return

      // Check objective doesn't exist
      if (
        this.ibObjectives.length &&
        this.ibObjectives.findIndex(o => o.header.metadata.content_id === objective.header.metadata.content_id) !== -1
      ) {
        this.$modal("info", {
          title: "Can not import objective data",
          message: `The selected objective with custom_id ${objective.header.metadata.custom_id} is already imported`,
          ok: {
            text: "Accept",
            color: "#2196f3"
          }
        })
        return
      }

      //Modal for selecting IT
      let interactiveText = null
      interactiveText = await this.$modal(DependentsForContent, {
        content: objective,
        types: ["interactiveText"]
      })
      if (!interactiveText) return

      this.addObjectiveToIB(objective)

      return interactiveText
    },
    async saveTextAsFile(triggerPage, contentHTML) {
      const blob = new Blob([contentHTML], { type: "plain/text" })

      const myFile = new File([blob], triggerPage.id + ".html", { type: blob.type })
      const myPFile = new PFile(myFile)
      await myPFile.upload()

      triggerPage.html = myPFile
      this.page.html = myPFile
    },
    setStyleSheet(styleSheet) {
      this.vm.value.body.contents.settings.styles.css = styleSheet ? styleSheet : this.defaultCss
      this.applyStyleSheet()
    },
    getStyleTag() {
      const styleTag = document.getElementById(this.styleTagId)
      return styleTag
    },
    async applyStyleSheet() {
      let styleTag = this.getStyleTag()

      // Create style tag
      if (!styleTag) {
        styleTag = document.createElement("style")
        styleTag.id = this.styleTagId
        styleTag.type = "text/css"
        document.head.appendChild(styleTag)
      }

      // Get css content from style sheet
      if (typeof this.contents.settings.styles.css === "object") {
        const res = await fetch(this.vm.value.body.contents.settings.styles.css.body.contents.attachment.url)
        this.cssContent = res.ok ? await res.text() : ""
      }

      if (typeof this.contents.settings.styles.css === "string") {
        this.cssContent = this.contents.settings.styles.css
      }

      // Put content inside style tag
      styleTag.innerHTML = this.prefixCss(this.cssContent, this.styleTargetId)
    }
  }
}
