export var roles = {
    "admin": {
        "title": "Admin",
        "icon": "mdi-account-cog-outline",
        "value": "admin"
    },
    "supervisor": {
        "title": "Supervisor",
        "icon": "mdi-account-key-outline",
        "value": "supervisor"
    },
    "instructor": {
        "title": "Instructor",
        "icon": "mdi-account-star",
        "value": "instructor"
    },
    "student": {
        "title": "Student",
        "icon": "mdi-account",
        "value": "student"
    },
}
