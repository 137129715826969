<template>
  <v-badge small color="orange" :value="canShowBadge">
    <template v-slot:badge>
      <span>
        <v-icon small>{{ icon }}</v-icon>
      </span>
    </template>
    <v-icon>mdi-notebook</v-icon>
  </v-badge>
</template>

<script>
export default {
  inject: ["lessonPlanController"],
  data() {
    return {
      icon: "mdi-exclamation"
    }
  },
  computed: {
    hasAudienceNotes() {
      return (
        this.lessonPlanController.selectedSlide.notes.audience !== "<p></p>" &&
        this.lessonPlanController.selectedSlide.notes.audience !== ""
      )
    },
    hasSpeakerNotes() {
      return (
        this.lessonPlanController.selectedSlide.notes.speaker !== "<p></p>" &&
        this.lessonPlanController.selectedSlide.notes.speaker !== ""
      )
    },
    isStudent() {
      if (!this.$store) return true
      return this.$store.getters["user/isStudent"]
    },
    isEditMode() {
      return this.lessonPlanController.mode === "edit"
    },
    canShowBadge() {
      if (this.hasAudienceNotes || this.hasSpeakerNotes) {
        if (this.hasAudienceNotes && this.hasSpeakerNotes) {
          this.icon = "mdi-exclamation"
        } else if (this.hasSpeakerNotes) {
          this.icon = "mdi-human-male-board"
        } else {
          this.icon = "mdi-account-group"
        }
        return true
      } else {
        return false
      }
    }
  }
}
</script>
