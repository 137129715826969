<template>
  <div>
    <v-container fluid>
      <v-layout wrap>
        <v-flex xs6 class="px-3">
          <v-btn v-if="!guide.template" color="success" @click="selectTemplate" e2e-id="select_template"
            >Select Template</v-btn
          >
          <template v-else>
            <v-text-field
              v-for="(value, key) in guide.cover.fields"
              v-model="guide.cover.fields[key]"
              :label="key"
              :key="key"
              e2e-attr="cover-field"></v-text-field>
            <v-btn color="danger" @click="selectTemplate">Change Template</v-btn>
          </template>
        </v-flex>
        <v-flex xs-6 class="px-3">
          <v-text-field
            v-if="
              learning_objective &&
              learning_objective.body &&
              learning_objective.body.contents &&
              learning_objective.body.contents.terminal
            "
            :value="learning_objective.body.contents.terminal.title"
            label="Terminal objective"
            readonly>
            <v-tooltip slot="append" bottom>
              <v-icon slot="activator" @click="openTemplate(getContentId(learning_objective))" color="primary"
                >mdi-open-in-new</v-icon
              >
              <span>Open in new tab</span>
            </v-tooltip>
          </v-text-field>
          <v-btn @click="selectLearningObjective">
            <span>Select Learning Objective</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ContentSelectorDropdown from "./ContentSelectorDropdown"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { getContentId } from "@/utils/dbTool"

import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { getType } from "../../../../utils/dbTool"
export default {
  name: "PropertiesCover",
  inject: ["guideController"],
  components: { ContentSelectorDropdown },
  mounted() {
    if (!this.guide.cover.fields) this.guide.cover.fields = this.getFields("cover")
  },
  computed: {
    ...mapInjectedData("guideController", ["guide"]),
    learning_objective() {
      return this.guide.learning_objective
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["getTemplate", "getFields", "deleteEnablingObjective"]),
    getContentId: getContentId,
    deleteLearningObjective() {
      this.guide.learning_objective = {}
      this.deleteEnablingObjective(this.guide.sections)
    },
    selectTemplate() {
      PServiceContent.selectContent()
        .then(r => {
          if (r.body.attachments && r.body.attachments.length) {
            if (r.body.attachments.find(e => e.name == "cover")) {
              fetch(r.body.attachments.find(e => e.name == "cover").url)
                .then(res => res.text())
                .then(cover_svg => {
                  r.body.contents.card_templates.forEach(element => {
                    if (element.name == "cover") {
                      let index = cover_svg.indexOf("<svg")
                      element.svg = cover_svg.substring(index)
                      this.guide.cover.fields = this.getFields("cover")
                    }
                  })

                  if (r.body.attachments.find(e => e.name == "section")) {
                    fetch(r.body.attachments.find(e => e.name == "section").url)
                      .then(res => res.text())
                      .then(section_svg => {
                        r.body.contents.card_templates.forEach(element => {
                          if (element.name == "section") {
                            let index = section_svg.indexOf("<svg")
                            element.svg = section_svg.substring(index)
                          }
                        })
                      })
                  }
                })
            }
          }
          this.guide.template = r
          // this.guide.cover.fields = this.getFields('cover');
        })
        .catch(console.error)
    },
    selectLearningObjective() {
      PServiceContent.selectContent()
        .then(r => {
          if (!this.guide.learning_objective) this.$set(this.guide, "learning_objective", {})
          if (getType(r) === "learningObjective") {
            this.deleteLearningObjective()
            this.guide.learning_objective = r
          }
        })
        .catch(console.error)
    },
    openTemplate(contentId) {
      PServiceContent.openContent(contentId, "tab")
    }
  }
}
</script>
