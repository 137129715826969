<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <portal :to="`contentFlashcardQuestion-toolbar-${value.id}`" v-if="mode === 'edit'">
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-image-off-outline"
          tooltip="Change Content"
          @click="changeContent"
          :disabled="changeContentProtection">
          Change Content
        </p-toolbar-button>
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-restore"
          tooltip="Reset to default styles"
          @click="resetToDefaultStyles"
          :disabled="changeContentProtection">
          Reset to default styles
        </p-toolbar-button>
        <p-toolbar-menu v-if="isContentSelected" icon="mdi-layers-outline" title="Layers" tooltip="Display layers">
          <v-list dense>
            <v-list-tile @click="displayContent()">
              <v-list-tile-action>
                <v-icon>mdi-border-all-variant</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Block</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('expanded')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Expanded View</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </p-toolbar-menu>
      </portal>
      <template v-if="mode === 'edit'">
        <div class="lp-block-library-content">
          <v-btn color="primary" @click="addFromContentManager" v-if="!isContentSelected" large outline
            >Add from Content Manager</v-btn
          >
          <template v-else>
            <block-subcanvas :value="value" :blocks="value.data.blocks" :mode="mode" :blockVariables="blockVariables" />
          </template>
        </div>
      </template>
      <template v-else>
        <block-subcanvas :value="value" :blocks="value.data.blocks" mode="view" :blockVariables="blockVariables" />
      </template>
    </template>
  </basic-block>
</template>

<script>
import { PToolbarButton, PToolbarMenu, Extensions } from "plant-common"
import contentNotAllowed from "../contentNotAllowed"
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockSubcanvas from "../_components/containers/BlockSubcanvas.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import blockOverlayCommon from "../../blocks/_mixins/blockOverlayCommon.vue"
import _ from "lodash"
import defaultContentBlocks from "./defaultContentBlocks.js"

export default {
  name: "BlockLibraryContentCustom",
  inject: ["blockEventBus", "blockController"],
  mixins: [blockOverlayCommon],
  props: ["value", "mode"],
  components: {
    PToolbarButton,
    PToolbarMenu,
    BasicBlock,
    BlockSubcanvas
  },
  data() {
    return {
      blockVariables: false
    }
  },
  computed: {
    isContentSelected() {
      return Object.keys(this.value.data.content_ref).length > 0
    },
    expandedOverlay() {
      return this.value.data.overlays.expanded
    },
    changeContentProtection() {
      return this.value.protection.data
    }
  },
  created() {
    if (this.isContentSelected) this.addVariables()
    if (this.blockEventBus) this.blockEventBus.$on("submit", this.submitted)
  },
  methods: {
    async addFromContentManager() {
      const contentTypes = Object.keys(Extensions).filter(el => {
        return contentNotAllowed.indexOf(el) < 0
      })
      var newContentRef = await PServiceContent.selectContent({ type: contentTypes })
      if (!newContentRef) return
      this.value.data.content_ref = newContentRef
      this.addVariables()
      let libraryContentBlock = this.value.data.blocks.find(block => block.type === "libraryContent")
      if (libraryContentBlock) libraryContentBlock.data.content_ref = newContentRef
      let libraryContentBlockExpanded = this.value.data.overlays.expanded.blocks.find(
        block => block.type === "libraryContent"
      )
      if (libraryContentBlockExpanded) libraryContentBlockExpanded.data.content_ref = newContentRef
    },
    submitted() {
      this.blockController.overlay = this.value.data.overlays.expanded
      this.blockController.overlayVariables = this.blockVariables
    },
    addVariables() {
      if (this.value.data.content_ref.body) {
        this.blockVariables = [
          {
            name: "LibraryContentTitle",
            type: "string",
            value: this.value.data.content_ref.header.metadata.title,
            title: "Library Content Title"
          },
          {
            name: "LibraryContentId",
            type: "string",
            value: this.value.data.content_ref.header.metadata.custom_id,
            title: "Library Content Custom Id"
          },
          {
            name: "LibraryContentReference",
            type: "object",
            value: this.value.data.content_ref,
            title: "Library Content Reference"
          }
        ]
      }
    },
    changeContent() {
      this.$delete(this.value, "content_ref")
      deleteContentRef(this.value)
    },
    resetToDefaultStyles() {
      this.value.data = defaultContentBlocks()
    },
    displayContent() {
      this.closeOverlay()
      this.blockController.overlayVariables = null
      this.blockController.$emit("overlayActive", { type: null, overlay: null })
    },
    displayOverlay(type) {
      this.blockController.overlay = this.value.data.overlays[type]
      this.blockController.overlay.parent = this.value.id
      this.blockController.overlayVariables = this.blockVariables
      this.blockController.$emit("overlayActive", { type: type, overlay: this.value.data.overlays[type] })
    }
  },
  beforeDestroy() {
    if (this.blockEventBus) this.blockEventBus.$off("submit", this.submitted)

    if (this.expandedOverlay && !_.isEmpty(this.blockController)) {
      this.blockController.$off(`state-changed:${this.expandedOverlay.id}`)
      this.expandedOverlay.blocks.forEach(block => {
        this.blockController.$off(`state-changed:${block.id}`)
      })
    }
  },
  watch: {
    "blockController.overlay": {
      immediate: true,
      handler() {
        if (!this.blockController.overlay && !_.isEmpty(this.blockController)) {
          this.blockController.$emit("overlayActive", { type: null, overlay: null })
        }
      }
    },
    "expandedOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.expandedOverlay && !_.isEmpty(this.blockController)) {
          this.blockController.$on(`state-changed:${this.expandedOverlay.id}`, state => {
            if (state === "primary-selected") {
              this.displayOverlay("expanded")
            }
          })
          // if  overlay have blocks, listen to each block state-change
          this.expandedOverlay.blocks.forEach(block => {
            this.blockController.$on(`state-changed:${block.id}`, state => {
              if (state === "primary-selected") {
                this.displayOverlay("expanded")
              }
            })
          })
        }
      }
    },
    "value.data.content_ref": {
      immediate: true,
      deep: true,
      handler() {
        this.addVariables()
      }
    }
  }
}

function deleteContentRef(value) {
  value.data.content_ref = {}
  value.data.blocks.forEach(block => {
    if (block.type === "libraryContent") {
      block.data.content_ref = {}
    }
  })
  value.data.overlays.expanded.blocks.forEach(block => {
    if (block.type === "libraryContent") {
      block.data.content_ref = {}
    }
  })
}
</script>

<style scoped>
.lp-block-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: none;
  position: relative;
}
</style>
../_mixins/blockOverlayCommon.vue