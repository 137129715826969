export default function (value) {
    var type = typeof value
    switch (type) {
        case "string":
            return value.toLowerCase() == "true" ? true : false
            break
        case "boolean":
            return value
            break
        case "undefined":
            return undefined
            break
        default:
            throw new Error(`Can not parse type ${type}`)
            break
    }
}