import Vue from "vue"
import Edit from "./Edit.js"
import * as models from "../../models/models.js"
import defaultCss from "plant-common/src/components/plant-item-viewer/styleSheet/defaultCss"

export default function (vm) {
  let contents = vm.value.body.contents
  if (!contents || Object.keys(contents).length === 0) {
    vm.value.body.contents = new models.PContentInteractiveText()
  }
  return new Vue({
    mixins: [Edit],
    data() {
      return {
        vm: vm,
        mode: vm.mode,
        comments: vm.comments,
        editor: null,
        headerToGo: false,
        styleTargetId: "ib-prosemirror-for-css",
        defaultCss: defaultCss
      }
    },
    created() {
      if (this.isIB && !this.contents.objectives) {
        this.$set(this.contents, "objectives", [])
      }

      if (this.isIB && !this.contents.settings.styles.css) {
        this.$set(this.contents.settings.styles, "css", defaultCss)
      }

      if (this.isIB && this.contents.settings.styles.css) {
        this.applyStyleSheet()
      }
    },
    computed: {
      contents() {
        return vm.value.body.contents
      },
      page() {
        return this.contents.page
      },
      isDraft() {
        return this.mode === "edit"
      },
      isIT() {
        return this.vm.value.header.metadata.content_revision_type === "interactiveText"
      },
      isIB() {
        return this.vm.value.header.metadata.content_revision_type === "interactiveBook"
      },
      ITsFromPage() {
        return this.page.htmlDependencies.filter(d => d.header.metadata.content_revision_type === "interactiveText")
      },
      glossariesFromItsFromPage() {
        const glossaries = []
        this.ITsFromPage.forEach(it => {
          if (it.body.contents.glossary && it.body.contents.glossary.length) {
            glossaries.push(...it.body.contents.glossary)
          }
          if (it.body.contents.glossaryImported && it.body.contents.glossaryImported.length) {
            it.body.contents.glossaryImported.map(glosImp => {
              if (glosImp.body.contents.items && glosImp.body.contents.items.length)
                glossaries.push(...glosImp.body.contents.items)
            })
          }
        })
        return glossaries
      },
      objectivesFromItsFromPage() {
        return this.ITsFromPage.map(it => it.body.contents.instructionalSettings.objective)
      },
      ibObjectives() {
        return this.contents.objectives
      },
      styleTagId() {
        return `${this.styleTargetId}-${this.vm.value.header.metadata.content_id}`
      }
    },
    methods: {
      setHeaderToGo(id) {
        this.headerToGo = id
      }
    }
  })
}
