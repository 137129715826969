<template>
  <div class="bulk-change-table">
    <v-toolbar flat color="white">
      <v-toolbar-title>Contents to update ({{ contents.length }} items selected)</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      hide-actions
      :headers="[...headers, ...(Array.isArray(propHeaders) ? propHeaders : [])]"
      :items="contents"
      class="elevation-0 bulk-change-datatable"
      v-if="contents.length > 0">
      <template v-slot:items="props">
        <td>
          <v-icon v-if="props.item.status == 'not-started'" color="primary">mdi-circle-outline</v-icon>
          <v-icon v-else-if="props.item.status == 'in-progress'" color="orange">mdi-progress-clock</v-icon>
          <v-icon v-else-if="props.item.status == 'done'" color="green">mdi-check-circle</v-icon>
          <v-tooltip bottom v-else-if="props.item.status == 'error'">
            <template v-slot:activator="{ on }">
              <v-icon color="red" v-on="on">mdi-circle</v-icon>
            </template>
            <span>{{ props.item.error }}</span>
          </v-tooltip>
        </td>
        <td>
          {{ props.item.header.metadata.custom_id }}
        </td>
        <td>
          {{ props.item.header.metadata.custom_revision }}
        </td>
        <td>
          {{ props.item.header.metadata.title }}
        </td>

        <td v-for="prop in propHeaders" :key="prop.text">
          <input
            class="hw-100"
            :value="props.item.body.contents.datasheet[prop.text]"
            @blur="v => (props.item.body.contents.datasheet[prop.text] = v.target.value)"
            :contenteditable="changeProps"
            :placeholder="prop.text" />
        </td>

        <td class="justify-center layout px-0">
          <v-tooltip bottom v-if="props.item.status == 'done'">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon flat @click.stop="openContent(props.item)">
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
            <span>Open in new tab</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="red" icon flat @click.stop="deselectContent(props.item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Remove from list</span>
          </v-tooltip>
        </td>
      </template>
    </v-data-table>
    <div v-else class="bulk-change-datatable">No content selected</div>
  </div>
</template>

<script>
import PServiceContent from "plant-common/src/services/PServiceContent.js"

export default {
  props: ["contents", "changeProps", "contentType", "operationsSelected"],
  data() {
    return {
      addHeaders: []
    }
  },
  computed: {
    headers() {
      let headers = [
        {
          text: "",
          value: "",
          sortable: false,
          width: "40px"
        },
        {
          text: "Custom Identifier",
          value: "header.metadata.custom_id",
          sortable: true,
          width: "175px"
        },
        {
          text: "Custom Revision",
          value: "header.metadata.custom_revision",
          sortable: true,
          width: "150px"
        },
        {
          text: "Title",
          value: "header.metadata.title",
          sortable: true
        }
      ]

      return headers
    },
    propHeaders() {
      if (!this.changeProps) return []

      return this.operationsHaveChangeProps ? this.addHeaders : []
    },
    operationsHaveChangeProps() {
      if (!this.operationsSelected) return false
      return this.operationsSelected.findIndex(op => op.operation.id === "datasheet-replace-properties-from-table") ===
        -1
        ? false
        : true
    }
  },
  async created() {
    if (
      this.contents[0].header.metadata.content_revision_type === "datasheet" &&
      this.contents[0].hasOwnProperty("body")
    ) {
      this.addHeaders = await this.buildHeadersFromSchema(this.contents[0].body.contents.schema)
    }
  },
  methods: {
    deselectContent(item) {
      const index = this.contents.findIndex(e => e == item)
      this.contents[index].selected = false
      this.contents.splice(index, 1)
    },
    async buildHeadersFromSchema(schema) {
      const builHeadersArray = properties =>
        Object.keys(properties).map(key => ({
          text: key,
          value: "body.contents.datasheet[key]",
          sortable: false
        }))

      if (schema.hasOwnProperty("body")) return builHeadersArray(schema.body.contents.properties)

      let ref = ""
      if (schema.hasOwnProperty("$origin")) ref = schema.$origin
      if (schema.hasOwnProperty("$ref")) ref = schema.$ref

      const schemaContent = await PServiceContent.getContentFromRef(ref)
      if (!schemaContent) return []

      return builHeadersArray(schemaContent.body.contents.properties)
    },
    openContent(item) {
      this.$emit("open:content", item)
    }
  }
}
</script>

<style>
.bulk-change-datatable .hw-100 {
  height: 100%;
  width: 100%;
}

.bulk-change-datatable input {
  cursor: pointer;
}

.bulk-change-datatable input:focus {
  cursor: text;
}

.bulk-change-table {
  position: absolute;
  top: 65px;
  left: 450px;
  width: calc(100% - 450px);
  bottom: 0;
}

.bulk-change-datatable {
  height: calc(100% - 65px);
}

.bulk-change-datatable .v-table__overflow {
  height: 100%;
  overflow: auto;
}

.bulk-change-datatable .v-table__overflow table thead {
  position: sticky;
  top: 0;
  background: white;
}
</style>
