<template>
	<basic-block :value="value" :mode="mode" :state.sync="state">
		<template v-slot:main>
			<div class="carousel">
				<div class="carousel-inner" :style="canvasStyle">
					<template v-for="card in value.data.cards">
						<input
							v-if="cardItem == card.id"
							:key="`input-${card.id}`"
							class="carousel-open"
							type="radio"
							:id="`carousel-${card.id + 1}`"
							name="carousel"
							aria-hidden="true"
							hidden=""
							:checked="card.id == 0 ? true : false"
						/>
						<div v-if="cardItem == card.id" :key="`item-${card.id}`" :class="getTransition" class="carousel-item">
							<block-subcanvas
								v-if="mode === 'edit'"
								:value="value"
								:blocks="card.blocks"
								mode="edit"
								@create:draft="$emit('create:draft', $event)"
								:blockVariables="blockVariables"
							/>
							<block-subcanvas-readonly
								v-else-if="cardItem == card.id"
								:blocks="card.blocks"
								mode="view"
								@goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
								@selectSlideById="$emit('selectSlideById', $event)"
								:blockVariables="blockVariables"
								:subcanvasDelay="subcanvasHasDelay ? cardsDelay : undefined"
							/>
						</div>
					</template>

					<label
						:for="`carousel-${cardItem + 1}`"
						class="carousel-control prev"
						:style="arrowsStyle"
						@click="handleNavigation('prev')"
						:class="{ disabled: isLeftArrowDisabled }"
					>
						‹
					</label>
					<label
						:for="`carousel-${cardItem + 1}`"
						class="carousel-control next"
						:style="arrowsStyle"
						@click="handleNavigation('next')"
						:class="{ disabled: isRightArrowDisabled }"
					>
						›
					</label>

					<ol class="carousel-indicators" :id="`indicators-${value.id}`">
						<li v-for="card in value.data.cards" :key="card.id">
							<v-tooltip v-if="displayTooltip" top>
								<template v-slot:activator="{ on }">
									<label
										:for="`carousel-${card.id + 1}`"
										class="carousel-bullet"
										:class="card.id === cardItem ? 'activeBullet' : ''"
										:style="paginatorStyle"
										@click="goToCard(card.id)"
										v-on="on"
									>
										•
									</label>
								</template>
								<span>{{ card.title }}</span>
							</v-tooltip>
							<label
								v-else
								:for="`carousel-${card.id + 1}`"
								class="carousel-bullet"
								:class="card.id === cardItem ? 'activeBullet' : ''"
								:style="paginatorStyle"
								@click="goToCard(card.id)"
							>
								•
							</label>
						</li>
						<li v-if="mode === 'edit'">
							<v-btn flat icon class="carousel-add" @click="addCard">
								<v-icon> mdi-plus </v-icon>
							</v-btn>
						</li>
					</ol>
				</div>
			</div>
		</template>
	</basic-block>
</template>

<script>
import BasicBlock from '../_components/containers/BasicBlock.vue';
import BlockToolbarButton from '../button/BlockToolbarButton.vue';
import BlockSubcanvas from '../_components/containers/BlockSubcanvas.vue';
import BlockSubcanvasReadonly from '../_components/containers/BlockSubcanvasReadonly.vue';
import { BlockCard } from './class.js';
import _ from 'lodash';

export default {
	name: 'BlockCarousel',
	inject: ['blockController'],
	props: ['value', 'mode', 'blockVariables'],
	components: {
		BasicBlock,
		BlockToolbarButton,
		BlockSubcanvas,
		BlockSubcanvasReadonly
	},
	data() {
		return {
			cardItem: this.value.data.cards[0] ? this.value.data.cards[0].id : -1,
			transition: '',
			state: 'not-selected',
			cardsVisited: [],
			subcanvasHasDelay: false
		};
	},
	computed: {
		displayTooltip() {
			return this.value.data.displayCardsTooltip;
		},
		canvasStyle() {
			return {
				'--canvas-color-background': this.value.data.style.canvas.normal.backgroundColor
			};
		},
		arrowsStyle() {
			return {
				'--arrows-color-background': this.value.data.style.arrows.normal.backgroundColor,
				'--arrows-color-arrows': this.value.data.style.arrows.normal.arrowColor,
				'--arrows-color-background-hover': this.value.data.style.arrows.hover.backgroundColor,
				'--arrows-color-arrows-hover': this.value.data.style.arrows.hover.arrowColor
			};
		},
		paginatorStyle() {
			return {
				'--pagination-color-background': this.value.data.style.paginator.normal.backgroundColor,
				'--pagination-color-background-hover': this.value.data.style.paginator.hover.backgroundColor,
				'--pagination-color-background-active': this.value.data.style.paginator.active.backgroundColor
			};
		},
		getTransition() {
			return this.value.data.style.transition.type;
		},
		isLoopNavigation() {
			return this.value.data.navigation.loop;
		},
		currentIndex() {
			return this.value.data.cards.findIndex(card => card.id == this.cardItem);
		},
		isLeftArrowDisabled() {
			return !this.isLoopNavigation && this.currentIndex === 0;
		},
		isRightArrowDisabled() {
			return !this.isLoopNavigation && this.currentIndex === this.value.data.cards.length - 1;
		},
		cardsDelay() {
			const animationIn =
				this.value.animations && this.value.animations[0] && this.value.animations[0].name !== 'none'
					? this.value.animations[0]
					: false;
			return animationIn ? parseFloat(animationIn.delay) + this.inheritDelay : undefined;
		},
		inheritDelay() {
			return this.$attrs.subcanvasDelay || 0;
		}
	},
	created() {
		this.subcanvasHasDelay = this.firstTimeLoaded(this.value.data.cards[0]);
		if (!_.isEmpty(this.blockController)) {
			this.blockController.$on(`selection-changed`, ({ block, state }) => {
				if (state === 'primary-selected') {
					var cardSelected = this.value.data.cards.find(card => card.id == block.id);
					if (cardSelected) {
						this.cardItem = cardSelected.id;
					}
					var blockSelected = false;
					this.value.data.cards.forEach(card => {
						blockSelected = card.blocks.find(cardBlock => cardBlock.id == block.id);
						if (blockSelected) {
							this.cardItem = card.id;
						}
					});
				}
			});
		}

		// Set default navigation property to old carousel block (if it is not set)
		if (this.value?.data?.navigation?.loop == undefined) this.$set(this.value.data, 'navigation', { loop: true });

		// Set default background to old carousel block (if it is not set)
		if (!this.value.data.style.canvas) {
			this.$set(this.value.data.style, 'canvas', {
				normal: {
					backgroundColor: '#ffffff00'
				}
			});
		}
	},
	mounted() {
		if (!this.value.data.cards[0]) return;
		if (!this.isCardVisited(this.value.data.cards[0].id) && this.mode !== 'edit') {
			this.cardsVisited.push(this.value.data.cards[0].id);
			this.$set(this.value.data.cards[0], 'visited', true);
		}
		this.updateBackgroundColor();
	},
	watch: {
		cardItem: {
			immediate: true,
			handler() {
				if (!_.isEmpty(this.blockController)) {
					this.blockController.$emit('childrenIdActive', this.cardItem);
				}
				this.updateBackgroundColor();
			}
		},
		'value.data.style': {
			deep: true,
			handler() {
				this.updateBackgroundColor();
			}
		},
		state: {
			immediate: true,
			handler() {
				if (!_.isEmpty(this.blockController)) {
					if (this.state == 'edit-content') {
						this.blockController.$emit('childrenIdActive', this.cardItem);
					} else if (this.state == 'not-selected') {
						this.blockController.$emit('childrenIdActive', null);
					}
				}
			}
		}
	},
	methods: {
		isCardVisited(cardItem) {
			return this.cardsVisited.includes(cardItem);
		},

		goToCard(cardId) {
			this.subcanvasHasDelay = false;
			this.blockController.selectionClear();
			this.blockController.selectionEdit(this.value.id);
			this.cardItem = cardId;
			if (!this.isCardVisited(cardId) && this.mode !== 'edit') {
				this.cardsVisited.push(cardId);
				this.$set(
					this.value.data.cards.find(card => card.id === cardId),
					'visited',
					true
				);
			}
		},

		addCard() {
			this.value.data.cards.push(new BlockCard());
		},

		isIndexBetweenMinMax(index, min, max) {
			return index >= min && index <= max;
		},

		canNavigateForward(index) {
			return index > this.value.data.cards.length - 1 && this.isLoopNavigation;
		},

		canNavigateBackward(index) {
			return index < 0 && this.isLoopNavigation;
		},

		canNavigate(toIndex) {
			return (
				this.isIndexBetweenMinMax(toIndex, 0, this.value.data.cards.length - 1) ||
				this.canNavigateForward(toIndex) ||
				this.canNavigateBackward(toIndex)
			);
		},

		handleNavigation(dir) {
			const targetIndex = dir === 'next' ? this.currentIndex + 1 : this.currentIndex - 1;

			if (!this.canNavigate(targetIndex)) return;

			this.changeCardItem(targetIndex);
		},

		changeCardItem(index) {
			if (index > this.value.data.cards.length - 1) {
				index = 0;
			}
			if (index < 0) {
				index = this.value.data.cards.length - 1;
			}
			this.goToCard(this.value.data.cards[index].id);
		},
		updateBackgroundColor() {
			// Background changed with JS because
			// the parent of active element must be changed and can't be done with CSS
			const carouselIndicators = document.getElementById(`indicators-${this.value.id}`);
			if (carouselIndicators) {
				const bullets = carouselIndicators.querySelectorAll('.carousel-bullet');
				bullets.forEach((bullet, index) => {
					if (this.cardItem === index) {
						bullet.style.color = this.value.data.style.paginator.active.backgroundColor;
					} else if (this.isCardVisited(this.value.data.cards[index].id) && this.mode !== 'edit') {
						bullet.style.color = this.value.data.style.paginator.visited.backgroundColor;
					} else {
						bullet.style.color = this.value.data.style.paginator.normal.backgroundColor;
					}
				});
			}
		},
		firstTimeLoaded(card) {
			if (!card) return false;
			return !this.isCardVisited(card.id) && this.isFirstCard(card);
		},
		isFirstCard(card) {
			return this.value.data.cards[0].id === card.id;
		}
	}
};
</script>

<style>
.carousel {
	position: relative;
	box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.64);
	height: 100%;
}

.carousel-inner {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background-color: var(--canvas-color-background);
}

.carousel-open:checked + .carousel-item {
	position: static;
	opacity: 100;
}

.carousel-item {
	top: 0px;
	bottom: 0px;
	width: 100vw;
	position: absolute;
}

.carousel-control {
	background: var(--arrows-color-background);
	color: var(--arrows-color-arrows);
	border-radius: 50%;
	cursor: pointer;
	font-size: 40px;
	height: 40px;
	line-height: 35px;
	position: absolute;
	top: 50%;
	cursor: pointer;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
	text-align: center;
	width: 40px;
	z-index: 10;
}

.carousel-control:hover {
	background: var(--arrows-color-background-hover);
	color: var(--arrows-color-arrows-hover);
}

.carousel-control.prev {
	left: 2%;
}

.carousel-control.next {
	right: 2%;
}

.carousel-indicators {
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	bottom: 4px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	z-index: 10;
}

.carousel-indicators li {
	display: inline-block;
	margin: 0 5px;
	user-select: none;
}

.carousel-bullet {
	color: var(--pagination-color-background);
	cursor: pointer;
	display: block;
	font-size: 80px;
	width: 28px;
	line-height: 1rem;
}

.carousel-bullet:hover {
	color: var(--pagination-color-background-hover) !important;
}

.carousel-add {
	bottom: 25px;
}

.activeBullet {
	color: var(--pagination-color-background-active) !important;
}

.fade-in {
	animation: fade-in linear 1s;
	-webkit-animation: fade-in linear 1s;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
