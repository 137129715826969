<template>
  <div id="lp-index" class="lp-index lp-index-viewer">
    <div
      class="lp-index-panel"
      :style="{
        left: 0,
        width: (leftPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <left-panel
        :leftPanelsTop="leftPanelsTop"
        :leftPanelsBottom="leftPanelsBottom"
        :leftPanelSelected.sync="leftPanelSelected"
        @slideChanged="$emit('slideChanged', blockController)" />
    </div>
    <main
      class="lp-index-main"
      :style="{
        marginLeft: (leftPanelSelected > -1 ? 400 : 0) + 'px',
        marginRight: (rightPanelSelected > -1 ? 400 : 0) + 'px'
      }">
      <footer-arrows
        @slideChanged="$emit('slideChanged', blockController)"
        @prev="goToPrevSlide"
        @next="goToNextSlide"
        @update="goToSlideIndex"
        @toggleFullscreen="toggleFullscreen"
        @toggleSubtitles="toggleSubtitles"
        @selectDefaultLang="selectDefaultLang"
        :slide="lessonPlanController.selectedSlideIndex + 1"
        :total="lessonPlanController.slidesVisible.length"
        :can-go-next="lessonPlanController.canGoNext"
        :can-go-prev="lessonPlanController.canGoPrev"
        :isFullscreen="lessonPlanController.isFullscreen"
        :subtitlesActive="lessonPlanController.subtitlesActive"
        :defaultSubtitles="lessonPlanController.defaultSubtitles"/>
      <block-canvas-readonly
        ref="canvas"
        :blocks="blocks"
        :key="slideId"
        :defaultBlockStyles="defaultBlockStyles"
        :settingStyles="settingStyles"
        @selectSlideById="selectSlideById($event)"
        @goToBookmarkTarget="goToBookmarkTarget($event)"
        @openObjectives="openObjectivesPanel()"
        :globalVariables="globalVariables"
        class="p-block-viewer-canvas"
        :mode="mode"></block-canvas-readonly>
    </main>
    <div
      class="lp-index-panel"
      :style="{
        right: 0,
        width: (rightPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <right-panel
        :rightPanels="rightPanels"
        :rightPanelSelected.sync="rightPanelSelected"
        :key="slideId"
        :readonly="true" />
    </div>
  </div>
</template>

<script>
import LeftPanel from "../../../../components/structure/LeftPanel.vue"
import BlockCanvasReadonly from "../../containers/BlockCanvasReadonly.vue"
import RightPanel from "../../../../components/structure/RightPanel.vue"
import FooterArrows from "../../../../../commonInteractive/navigation/FooterArrows.vue"
import blockController from "../../../_controllers/BlockController/index.js"
import Vue from "vue"

export default {
  name: "BlockViewer",
  inject: ["lessonPlanController"],
  components: {
    LeftPanel,
    RightPanel,
    BlockCanvasReadonly,
    FooterArrows
  },
  provide() {
    return {
      blockDefinitions: this.blockDefinitions,
      blockController: this.blockController,
      blockEventBus: new Vue()
    }
  },
  props: {
    blocks: Array,
    slideId: String,
    blockDefinitions: Object,
    defaultBlockStyles: Object,
    settingStyles: Object,
    leftPanelsTop: Array,
    leftPanelsBottom: Array,
    rightPanels: Array,
    globalVariables: Array,
    mode: String
  },
  mounted() {
    document.addEventListener("keydown", this.navigationKeyHandler)
  },
  unmounted() {
    // remove global events
    document.removeEventListener("keydown", this.navigationKeyHandler)
  },
  data() {
    return {
      leftPanelSelected: -1,
      rightPanelSelected: -1,
      blockController: blockController(this)
    }
  },
  methods: {
    openObjectivesPanel() {
      const objIndex = this.leftPanelsTop.findIndex(panel => panel.title === "Objectives")
      this.leftPanelSelected = objIndex
    },
    selectSlideById(id) {
      this.$emit("selectSlideById", id)
      this.blockController.overlay = null
    },
    goToBookmarkTarget(bookmark) {
      this.$emit("goToBookmarkTarget", bookmark)
      this.blockController.overlay = null
    },
    navigationKeyHandler(e) {
      // Avoid changing slide when editing comments
      if (e.srcElement !== document.body) return

      switch (e.keyCode) {
        case 33: // page up
        case 37: // left
        case 38: // up
          // go to previous
          this.goToPrevSlide()
          break
        case 34: // pagae down
        case 39: // right
        case 40: // down
          // go to next
          this.goToNextSlide()
          break
        case 27: // esc
          this.lessonPlanController.setFullscreenTo(false)
          break
        default:
          return // exit this handler for other keys
      }
    },
    goToPrevSlide() {
      this.blockController.overlay = null
      this.lessonPlanController.prevSlide()
    },
    goToNextSlide() {
      this.blockController.overlay = null
      this.lessonPlanController.nextSlide()
    },
    goToSlideIndex(number) {
      const index = number - 1
      if (index < this.lessonPlanController.slidesVisible.length && index > -1) {
        const slideVisible = this.lessonPlanController.slidesVisible[index]
        const slideNav = this.lessonPlanController.slideNavigationMap.find(slideN => slideN.id === slideVisible.id)
        if (slideNav && !slideNav.available) {
          // avoid to select slide
          return
        }
        this.lessonPlanController.selectSlideByIndex(index)
      }
    },
    toggleFullscreen() {
      this.lessonPlanController.toggleFullscreen()
    },
    toggleSubtitles() {
      this.lessonPlanController.toggleSubtitles()
    },
    selectDefaultLang(lang) {
      this.lessonPlanController.selectDefaultLang(lang)
    }
  }
}
</script>

<style>
.lp-index-viewer .lp-index-panel {
  top: 0;
}
.lp-index-viewer .lp-index-main {
  top: 0;
}
</style>
