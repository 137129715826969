<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <shape-style
        :shape="value.data.shape"
        :width="value.size.width"
        :height="value.size.height"
        :shape-style="value.data.style" />
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"

import ShapeStyle from "./ShapeStyle.vue"

export default {
  name: "BlockShape",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeStyle
  },
  props: ["value", "mode"]
}
</script>


