<template>
  <v-dialog :value="true" persistent width="300">
    <v-card style="background:white;" flat>
      <v-card-text>
        <animated-logo/>
        <p >Loading content...</p>
        <v-progress-linear indeterminate color="purple" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AnimatedLogo from "@/components/AnimatedLogo.vue"
export default {
  components : {AnimatedLogo}
}
</script>