import { BlockBasic } from "../_models/models.js"

/**
 * A block showing the lesson plan breadcrumb
 */
export default class BlockBreadcrumb extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "breadcrumb"
    this.size.width = 1920
    this.size.height = 100
    this.data = {
      style: {
        normal: {
          fontFamily: false,
          fontSize: 34,
          spaceBetween: 20,
          color: "#000101"
        },
        link: {
          color: "#1976d2"
        },
        home: {
          spaceBetween: 8,
          icon: "mdi-home",
          iconColor: "#000101",
          text: "",
          color: "#000101"
        },
        divider: {
          icon: "mdi-slash-forward",
          color: "#000101"
        }
      },
      hideLastPathSection: true,
      showHiddenSections: false
    }
  }
}
