import { mergeAttributes, Node } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-2"

import Component from "./PlantLibraryComponent.vue"

export default Node.create({
  name: "plantLibraryComponent",
  group: "block",
  atom: true,

  addAttributes() {
    return {
      origin: {
        default: ""
      },
      pinned_view: {
        default: null
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "plant-library-component"
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["plant-library-component", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(Component)
  }
})
