<template>
  <div class="lp-block-video-record">
    <div v-if="value.data.video">
      <block-video-viewer :value="value" />
    </div>
    <div v-else>
      <!-- container in video-recorder to avoid bug after recording -->
      <div>
        <video-recorder :width="640" :height="480" :video="false" :screen="true" @finishRecord="saveVideo" />
      </div>
    </div>
  </div>
</template>

<script>
import { PFile } from "plant-common/src/utils/models"
import VideoRecorder from "./VideoRecorder.vue"
import BlockVideoViewer from "./BlockVideoViewer.vue"

export default {
  name: "BlockVideoRecordScreen",
  props: ["value"],
  components: { VideoRecorder, BlockVideoViewer },
  methods: {
    async saveVideo(blob) {
      const file = new File([blob], `record-screen-${blob.lastModifiedDate}.webm`)
      var newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.value.data, "video", newFile)
      if (this.value.data.video && !this.value.data.video.type) {
        this.$set(this.value.data.video, "type", "video/webm")
      }
    }
  }
}
</script>