<template>
  <div class="lp-tree-activity">
    <!-- Sections -->
    <v-icon v-if="lessonPlanController.isSection(item)" :color="lessonPlanController.getColorSection(item)">
      {{ iconSection }}
    </v-icon>

    <!-- Slides -->
    <template v-else-if="lessonPlanController.isSlide(item)">
      <v-tooltip bottom v-if="iconSlide.tooltip">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" :color="iconSlide.color">
            {{ iconSlide.icon }}
          </v-icon>
        </template>
        <span>{{ iconSlide.tooltip }}</span>
      </v-tooltip>
      <v-icon v-else :color="iconSlide.color">
        {{ iconSlide.icon }}
      </v-icon>
    </template>
  </div>
</template>

<script>
const SLICE_NORMALIZE = 100 / 8
export default {
  inject: ["lessonPlanController"],
  props: ["item"],
  computed: {
    iconSection() {
      if (this.lessonPlanController.isSection(this.item)) {
        const progress = this.lessonPlanController.calculateProgressSection(this.item.children, this.item)
        const progressNormalized = progress / SLICE_NORMALIZE
        const progressRounded = Math.round(progressNormalized)
        return progressRounded === 0 ? "mdi-circle-outline" : `mdi-circle-slice-${progressRounded}`
      } else {
        return false
      }
    },
    iconSlide() {
      if (this.lessonPlanController.isSlide(this.item)) {
        const iconSlideFromMap = this.lessonPlanController.getIconColorForSlide(this.item)
        return iconSlideFromMap
      }
    }
  }
}
</script>