<template>
  <print-layout @size-changed="changeSize" :custom_id="custom_id" :title="title" :revision="revision">
    <template v-slot:main>
      <!-- Page     -->
      <div v-for="(slide, index) in slides" :key="index" class="page" :style="{ width: width, height: height }">
        <!-- Layout       -->
        <component :is="selectedLayout" :slide="slide" :content="slide.content ? slide.content : value"></component>
      </div>

      <v-dialog v-model="loading" width="500" persistent>
        <v-card>
          <v-card-title class="headline" primary-title> Generating previews </v-card-title>
          <v-card-text>
            We are generating previews for all Interactive Presentation slides. This action may take some time. Please,
            wait...
            <v-progress-linear :indeterminate="true"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="loadingFinished" width="500">
        <v-card>
          <v-card-title class="headline" primary-title> Generating previews finished</v-card-title>
          <v-card-text> Reload this page to load them correctly </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="reloadPage"> Reload page </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-if="layouts" v-slot:leftDrawerContents>
      <!-- Page layouts          -->
      <v-list-tile class="mb-3">
        <v-select
          v-model="selectedLayout"
          :items="layouts"
          label="Layout"
          prepend-icon="mdi-page-layout-header-footer"
          append-icon="mdi-chevron-down">
        </v-select>
      </v-list-tile>

      <v-list-tile class="mb-3">
        <div class="pl-slides-preview-button">
          <v-btn color="primary" block outline @click="createSlidePreviews">
            <span> <v-icon left>mdi-play-box-multiple-outline</v-icon> Create Slides Preview</span>
          </v-btn>
        </div>
      </v-list-tile>
    </template>
  </print-layout>
</template>

<script>
import { getContentId, getCustomId, getMajorVersion, getMinorVersion, getTitle } from "../../../utils/dbTool"
import { PrintLayout } from "plant-common"
import layouts from "./layouts"
import PtApiClient from "plant-api-client"

export default {
  name: "LessonPlanPrint",
  props: ["value", "view"],
  components: { PrintLayout },
  data: function () {
    return {
      width: "8.5in",
      height: "11in",
      selectedLayout: null,
      layouts: layouts,
      loading: false,
      loadingFinished: false
    }
  },
  computed: {
    printCssId() {
      return `print-lessonplan-${getContentId(this.value)}`
    },
    printPageSize() {
      let pageSize = ""
      switch (this.width) {
        case "8.5in":
          pageSize = "letter"
          break
        case "11in":
          pageSize = "letter landscape"
          break
        case "210mm":
          pageSize = "A4"
          break
        case "297mm":
          pageSize = "A4 landscape"
          break
        default:
          pageSize = "letter"
          break
      }
      return pageSize
    },
    printHtml() {
      return `
        @media print {
          @page {
            size: ${this.printPageSize}; margin:-1px;
          }
        }
      `
    },
    custom_id() {
      return getCustomId(this.value)
    },
    title() {
      return getTitle(this.value)
    },
    revision() {
      return `${getMajorVersion(this.value)}.${getMinorVersion(this.value)}`
    },
    slides() {
      const dereferencedSlides = []

      function loadSlides(slides, parent) {
        slides.forEach(slideLO => {
          const slideLearningObject = JSON.parse(JSON.stringify(slideLO))
          if (parent) {
            slideLearningObject.content = parent
          }
          dereferencedSlides.push(slideLearningObject)
          if (slideLO.children) {
            loadSlides(slideLO.children, parent)
          }
        })
      }

      this.value.body.contents.slides.forEach(slide => {
        dereferencedSlides.push(slide)
        if (slide.reference) {
          loadSlides(slide.reference.body.contents.slides, slide.reference)
        }
        if (slide.children) {
          loadSlides(slide.children)
        }
      })

      return dereferencedSlides
    }
  },
  created() {
    if (!this.layouts) {
      this.selectedLayout = {
        template: "<div>Sorry, there is no selected layout</div>"
      }
    } else {
      this.selectedLayout = this.layouts[0].value
    }

    this.initPrintCss()
  },
  methods: {
    changeSize({ width, height }) {
      this.width = width
      this.height = height

      this.updatePrintCss()
    },
    async createSlidePreviews() {
      this.loading = true
      const response = await PtApiClient.createLessonPreview(
        getContentId(this.value),
        getMajorVersion(this.value),
        getMinorVersion(this.value)
      )
      this.loading = false
      this.loadingFinished = true
    },
    reloadPage() {
      location.reload()
    },
    initPrintCss() {
      const styleTag = document.createElement("style")
      styleTag.id = this.printCssId
      styleTag.type = "text/css"
      document.head.appendChild(styleTag)
      styleTag.innerHTML = this.printHtml
    },
    updatePrintCss() {
      const styleTag = document.getElementById(this.printCssId)

      if (!styleTag) {
        this.initPrintCss()
        return
      }

      styleTag.innerHTML = this.printHtml
    }
  }
}
</script>

<style scoped>
.page {
  margin: 20px auto;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  padding: 10mm;
}

@media print {
  .page {
    margin: 0;
    box-shadow: unset;
    background-color: white;
  }
}
.pl-slides-preview-button {
  width: 100%;
}
</style>
