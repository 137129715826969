<template>
  <content-explorer v-if="!loading" :key="library_id" />
</template>
<script>
import { mapActions, mapState } from "vuex"
import ContentExplorer from "./Content.vue"
import { STORE_LIBRARY_NAME, ACTION_SELECT_PLANT } from "@/store_constants/library"
import { STORE_USER_NAME, ACTION_SELECT_LIBRARY } from "@/store_constants/user"
import { STORE_CONTENT_NAME } from "@/store_constants/content"
import { STORE_EXPLORER_NAME, ACTION_GET_ALL_LIBRARY_FOLDERS } from "@/store_constants/explorer"
import explorer_store from "@/store/explorer"
import content_store from "@/store/content"

export default {
  props: ["library_id"],
  components: { ContentExplorer },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    ...mapActions(STORE_LIBRARY_NAME, {
      selectPlant: ACTION_SELECT_PLANT
    }),
    ...mapActions(STORE_USER_NAME, {
      selectLibrary: ACTION_SELECT_LIBRARY
    }),
    ...mapActions(STORE_EXPLORER_NAME, {
      getAllLibraryFolders: ACTION_GET_ALL_LIBRARY_FOLDERS
    })
  },
  watch: {
    library_id: {
      immediate: true,
      async handler() {
        this.loading = true
        const plant = await this.selectPlant(this.library_id)
        await this.selectLibrary()
        await this.getAllLibraryFolders()
        this.loading = false
      }
    }
  },
  beforeCreate() {
    if (!this.$store.state[STORE_EXPLORER_NAME]) this.$store.registerModule(STORE_EXPLORER_NAME, explorer_store)
    if (!this.$store.state[STORE_CONTENT_NAME]) this.$store.registerModule(STORE_CONTENT_NAME, content_store)
  }
}
</script>
