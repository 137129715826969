<template>
  <div v-if="!selectedCard.content">
    <div class="card-no-content">
      <v-img height="500" contain :src="emptyCardURL"></v-img>
      <div class="text-center">
        Empty Card
        <v-btn small color="info" class="add-content" @click="selectContent" e2e-id="add_content">Add content</v-btn>
        or
        <v-btn small color="info" class="add-content" @click="selectTemplate(selectedCard)" e2e-id="template_card"
          >Use card from template</v-btn
        >
      </div>
    </div>
  </div>
  <item-viewer
    v-else
    style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px"
    :value="selectedCard.content"
    :view="selectedCard.actualView"
    @update:view="setCurrentViewCard"
    from="guide" />
</template>

<script>
import { ItemViewer } from "plant-common"
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
export default {
  name: "GuideViewerCard",
  inject: ["guideController"],
  components: {
    ItemViewer
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"]),
    emptyCardURL() {
      return require("../empty_card.svg")
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["selectContent", "selectTemplate", "setCurrentViewCard", "reloadContent"])
  }
}
</script>

<style scoped>
.content-fit {
  text-align: center;
  height: 100%;
  width: 100%;
}

.section-fit {
  padding-top: 25%;
  text-align: center;
  height: 100%;
  width: 100%;
}

.card-no-content {
  height: 100%;
}

.add-content {
  border-radius: 15px;
}
</style>
