<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-arrow-control-handler-x"
        :x="s"
        :x-range="[0, w]"
        :y="0"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.slope = x / w)" />
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-arrow-control-handler-y"
        :x="0"
        :y="t"
        :y-range="[0, h * 0.49]"
        :angle="value.rotation"
        @update:y="y => (value.data.controls.thick = y / h)" />
      <svg
        class="shape-arrow"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M ${w} ${h / 2}
                L ${s} 0
                L ${s} ${t}
                L 0 ${t}
                L 0 ${h - t}
                L ${s} ${h - t}
                L ${s} ${h}
                L ${w} ${h / 2}
              `"
            :style="value.data.style"
            stroke-linecap="round" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"

export default {
  name: "BlockShapeArrow",
  inject: ["blockController"],
  data() {
    return {
      state: "not-selected"
    }
  },
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    s() {
      return this.value.size.width * this.value.data.controls.slope
    },
    t() {
      return this.value.size.height * this.value.data.controls.thick
    }
  }
}
</script>
