import PtApiClient from "plant-api-client"

export default {
  name: "training",
  namespaced: true,
  state: {
    contents: [],
    loading: false
  },
  mutations: {
    startLoading(state) {
      if (state.contents) state.contents.splice(0, state.contents.length)
      state.loading = true
    },
    setGuidesAndIps(state, contents) {
      state.contents = contents
    },
    setLoading(state, loadingState) {
      state.loading = loadingState
    }
  },
  actions: {
    async getAllGuidesAndIps({ state, commit, dispatch }) {
      commit("startLoading")
      try {
        const interactivePresentations = await PtApiClient.getContentByType("lessonPlan")
        const guides = await PtApiClient.getContentByType("guide")
        commit("setLoading", false)
        commit("setGuidesAndIps", [...interactivePresentations, ...guides])
      } catch (e) {
        commit("setLoading", false)
        if (e.response && e.response.data && e.response.data.error) {
          dispatch("notifications/error", { message: "Error: " + e.response.data.error }, { root: true })
        } else {
          dispatch(
            "notifications/error",
            { message: "Error: Oops! Something happend when trying to fetch data" },
            { root: true }
          )
        }
      }
    }
  }
}
