<template>
  <div
    class="block-subcanvas"
    @click.stop
    @drop="onBlockDrop"
    @dragover.prevent
    @dragenter.prevent
    ref="canvas"
    @click.self="focusOnSubCanvas">
    <component
      v-for="block in blocks"
      :key="block.id"
      :is="getBlockComponent(block.type)"
      :value="block"
      :mode="mode"
      @blockClick="onBlockClick(block)"
      @click.native.stop=""
      @blockDblClick="blockController.selectionEdit(block.id)"
      @dblclick.native.stop=""
      @create:draft="$emit('create:draft', $event)"
      :blockVariables="blockVariables"
      v-bind="$attrs" />
  </div>
</template>

<script>
import onBlockDrop from "../../_mixins/onBlockDrop.vue"

export default {
  props: ["value", "blocks", "mode", "blockVariables"],
  inject: ["blockController", "blockDefinitions"],
  mixins: [onBlockDrop],
  computed: {
    parent() {
      return this.$refs.canvas
    }
  },
  methods: {
    onBlockClick(block) {
      this.blockController.selectionClear()
      this.blockController.selectionAdd(block.id)
    },
    getBlockComponent(type) {
      return this.blockDefinitions[type].Block
    },
    focusOnSubCanvas() {
      if (this.mode == "edit") {
        var parent = false
        if (this.value) {
          // If value is set (block like tab, carousel)
          parent = this.value.id
        } else {
          // layout from popup or overlay => set edit content to parent
          parent = this.blockController.overlay.parent
        }
        this.blockController.selectionClear()
        this.blockController.selectionEdit(parent)
      }
    }
  }
}
</script>

<style scoped>
.block-subcanvas {
  overflow: hidden;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
</style>
