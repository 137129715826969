<template>
  <fragment>
    <p-panel-properties-group title="Image style">
      <p class="lp-block-tabs-panel">Normal:</p>
      <style-select :value="value.data.style.normal" title="Normal" />
      <p-property-tile title="Overlay Color" icon="mdi-palette-outline">
        <preset-input-color :value="value.data.overlay" @input="value.data.overlay = $event"></preset-input-color>
      </p-property-tile>
    </p-panel-properties-group>

    <p-panel-properties-group title="Full Screen">
      <p-property-tile title="Enable">
        <v-switch v-model="value.data.fullScreen.enable"></v-switch>
      </p-property-tile>
    </p-panel-properties-group>
  </fragment>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PresetInputColor from "../../components/slides/styles/PresetInputColor.vue"
import StyleSelect from "../../components/slides/styles/StyleSelect.vue"
import { Fragment } from "vue-fragment"

export default {
  props: ["value"],
  components: {
    PPanelPropertiesGroup,
    PresetInputColor,
    PPropertyTile,
    StyleSelect,
    Fragment
  },
  created() {
    if (!this.value.data.hasOwnProperty("fullScreen")) this.$set(this.value.data, "fullScreen", { enable: false })
  }
}
</script>
