<template>
  <p-toolbar id="guide-toolbar" width="100%" class="transparent elevation-0">
    <p-toolbar-action @click="decrement()" icon="mdi-chevron-left" tooltip="Previous"></p-toolbar-action>
    <p-toolbar-action @click="increment()" icon="mdi-chevron-right" tooltip="Next"></p-toolbar-action>
    <v-divider vertical></v-divider>
    <v-breadcrumbs divider=">">
      <v-breadcrumbs-item key="cover" @click="selectCard(guide.cover)">
        <v-icon>mdi-home</v-icon>
      </v-breadcrumbs-item>
      <v-breadcrumbs-item
        v-for="(item, index) in selectedCard.path"
        :key="index"
        :disabled="index > selectedCard.path.length - 2"
        @click="selectCardFromId(item.id)"
        >{{ item.title }}</v-breadcrumbs-item
      >
    </v-breadcrumbs>

    <v-spacer />
    {{ Math.floor(guideController.activityState.progress) }}%
    <span class="ml-3">
      <v-progress-linear
        :value="guideController.activityState.progress"
        style="width: 100px"
        :color="guideController.activityState.completion ? 'green' : 'blue'"></v-progress-linear>
    </span>
    <p-toolbar-action
      @click="selectCard({ type: 'mindmap' })"
      icon="mdi-share-variant"
      tooltip="Mindmap"></p-toolbar-action>
  </p-toolbar>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { PToolbarAction, PToolbar } from "plant-common"

export default {
  components: { PToolbar, PToolbarAction },
  inject: ["guideController"],
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "content", "guide"])
  },
  methods: {
    ...mapInjectedMethods("guideController", ["decrement", "increment", "selectCard", "selectCardFromId"])
  }
}
</script>

<style>
#guide-toolbar > .v-toolbar__content {
  padding-left: 0px;
}
</style>
