<template>
  <v-toolbar
    app
    flat
    color="rgba(255, 255, 255, 1)"
    :class="isDesktop ? 'pt-main-toolbar-desktop pt-main-toolbar elevation-0' : 'pt-main-toolbar elevation-0'"
    style="z-index: 5; width: 100vw; margin-top: -1px">
    <img
      ref="logo"
      class="mx-3"
      :src="branding.logo_url"
      height="40px"
      style="vertical-align: middle"
      data-v-step="logo" />
    <breadcrumbs />
    <v-spacer></v-spacer>
    <app-notifications-menu />
    <app-user-menu />
  </v-toolbar>
</template>
<script>
import AppUserMenu from "./AppUserMenu"
import Breadcrumbs from "./Breadcrumbs"
import { mapState, mapActions } from "vuex"
import branding from "@/branding"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS, ACTION_LEAVE_PLANT } from "@/store_constants/library"
import AppNotificationsMenu from "./AppNotificationsMenu/index.vue"
import PtDesktopApi from "@/PtDesktopApi.js"

export default {
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    isDesktop() {
      return typeof PtDesktopApi != "undefined"
    }
  },
  methods: {
    ...mapActions(STORE_LIBRARY_NAME, [ACTION_LEAVE_PLANT])
  },
  props: ["value"],
  components: { AppNotificationsMenu, Breadcrumbs, AppUserMenu },
  data() {
    return {
      branding
    }
  }
}
</script>

<style>
.pt-main-toolbar > div.v-toolbar__content {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  height: 66px !important;
}
.pt-main-toolbar-desktop {
  margin-top: 39px !important;
}
</style>