import store from "@/store"
import { STORE_LIBRARY_NAME } from "@/store_constants/library";
export default async (to, from, next) => {

  if (to.path.match(/^\/present/)) {
    return next()
  }

  // Save lastRoute
  if (to.path != '/login' && to.path != '/notfound' && to.path != '/' && !to.path.match(/^\/present/)) {
    localStorage.lastPlantRoute = to.path;
  }

  // Log in to library if URL loaded is from another library

  if (to.params && to.params.library_id) {
    if (store.state[STORE_LIBRARY_NAME].plant_id != to.params.library_id) {
      await store.dispatch('library/selectPlant', to.params.library_id);
      await store.dispatch('user/selectLibrary');
    }
  }
  // If it is not going specifically to Dashboard
  if (to.path == '/dashboard') {
    if (localStorage.lastPlantRoute && localStorage.lastPlantRoute != "/dashboard") {
      let route = localStorage.lastPlantRoute.split("/");
      let plant_id = route[1];
      if (!store.state[STORE_LIBRARY_NAME].plant_id) {
        if (from.path != "/login" && to.path == "/dashboard") {
          console.log("From login to dash")
          return next()
        } else {
          // Try to login to plant and get plant settings
          await store.dispatch('library/selectPlant', plant_id);
          await store.dispatch('user/selectLibrary');
          return next(localStorage.lastPlantRoute);
        }
      } else {
        return next(localStorage.lastPlantRoute);
      }
    }
  }
  var profile = store.state.user.profile
  if (to.redirectedFrom == "/") return next("/dashboard")
  if (to.path == "/login") return next()
  if (!profile) return next("/login")
  if (to.path.match(/^\/settings/) && profile.role != "admin") {
    if (to.path == "/settings/profile") {
      return next()
    } else {
      return next("/dashboard")
    }
  }

  if (!to.meta || !to.meta.roles || to.meta.roles.includes(store.state.user.profile.role)) {
    return next()
  }

  return next()
}