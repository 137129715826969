import uuid from "../../../../utils/uuid.js"

export default function () {
  return {
    "content_ref": {},
    "blocks": [
      {
        "id": uuid(),
        "name": "",
        "position": {
          "x": 0,
          "y": 300
        },
        "animations": [
          {
            "trigger": "auto",
            "name": "none",
            "duration": 1,
            "delay": 0
          }
        ],
        "has_activity": false,
        "size": {
          "width": 1920,
          "height": 180
        },
        "protection": {
          "type": [
            "textarea"
          ],
          "data": false,
          "move": false,
          "resize": false,
          "delete": false
        },
        "data": {
          "html": "<p><span class=\"fields\" data-field=\"FlashcardQuestion\">{{FlashcardQuestion}}</span></p>",
          "fontSize": 60,
          "color": "#000000ff",
          "textAlign": "center",
          "alignItems": "center",
          "fontFamily": false,
          "padding": 0
        },
        "type": "textarea"
      },
      {
        "id": uuid(),
        "name": "",
        "position": {
          "x": 760,
          "y": 600
        },
        "animations": [
          {
            "trigger": "auto",
            "name": "none",
            "duration": 1,
            "delay": 0
          }
        ],
        "has_activity": false,
        "size": {
          "width": 400,
          "height": 90
        },
        "protection": {
          "type": [
            "button"
          ],
          "data": false,
          "move": false,
          "resize": false,
          "delete": false
        },
        "data": {
          "text": "Show Answer",
          "icon": "none",
          "visited": false,
          "style": {
            "regular": {
              "backgroundColor": "#f5f5f5ff",
              "color": "#000000ff",
              "textScale": 100,
              "textAlign": "center",
              "boxShadowButton": "none",
              "borderRadius": 0,
              "fontFamily": false
            },
            "hover": {
              "backgroundColor": "#e0e0e0ff",
              "color": "#000000ff",
              "textScale": 100,
              "textAlign": "center",
              "boxShadowButton": "none",
              "borderRadius": 0,
              "fontFamily": false
            },
            "active": {
              "backgroundColor": "#bdbdbdff",
              "color": "#000000ff",
              "textScale": 100,
              "textAlign": "center",
              "boxShadowButton": "none",
              "borderRadius": 0,
              "fontFamily": false
            },
            "visited": {
              "backgroundColor": "#bdbdbdff",
              "color": "#000000ff",
              "textScale": 100,
              "textAlign": "center",
              "boxShadowButton": "none",
              "borderRadius": 0,
              "fontFamily": false
            }
          },
          "actions": [
            {
              "trigger": "click",
              "type": "event",
              "event": "submit"
            }
          ]
        },
        "type": "button"
      }
    ],
    "overlays": {
      "answer": {
        "id": uuid(),
        "width": 960,
        "height": 630,
        "style": {
          "backgroundColor": "#ffffffff",
          "borderRadius": 0
        },
        "blocks": [
          {
            "id": uuid(),
            "name": "",
            "position": {
              "x": 0,
              "y": 300
            },
            "animations": [
              {
                "trigger": "auto",
                "name": "none",
                "duration": 1,
                "delay": 0
              }
            ],
            "has_activity": false,
            "size": {
              "width": 960,
              "height": 180
            },
            "protection": {
              "type": [
                "textarea"
              ],
              "data": false,
              "move": false,
              "resize": false,
              "delete": false
            },
            "data": {
              "html": "<p><span class=\"fields\" data-field=\"FlashcardAnswer\">{{FlashcardAnswer}}</span></p>",
              "fontSize": 30,
              "color": "#000000ff",
              "textAlign": "center",
              "alignItems": "flex-start",
              "fontFamily": false,
              "padding": 0
            },
            "type": "textarea"
          },
          {
            "id": uuid(),
            "name": "",
            "position": {
              "x": 0,
              "y": 200
            },
            "animations": [
              {
                "trigger": "auto",
                "name": "none",
                "duration": 1,
                "delay": 0
              }
            ],
            "has_activity": false,
            "size": {
              "width": 960,
              "height": 90
            },
            "protection": {
              "type": [
                "textarea"
              ],
              "data": false,
              "move": false,
              "resize": false,
              "delete": false
            },
            "data": {
              "html": "<p><strong>Answer</strong></p>",
              "fontSize": 34,
              "color": "#000000ff",
              "textAlign": "center",
              "alignItems": "center",
              "fontFamily": false,
              "padding": 0
            },
            "type": "textarea"
          }
        ]
      }
    }
  }
}
