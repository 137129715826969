<template>
  <v-stepper-content step="1">
    <div class="step-container">
      <h2 class="headline text-center mb-4 mb-4">Select Interactive Presentation Template</h2>
      <p class="subheading text-center">
        Choose the interactive presentation you want to use as a template. We will use the layouts on that presentation
        on your "Guide" Cover and sections.
      </p>
      <p-content-tile :value="template.reference" v-if="template" class="mt-2 mb-4">
        <template slot="append">
          <v-list-tile-action>
            <v-btn flat icon>
              <v-icon color="red" @click="removeTemplate()">mdi-delete-outline</v-icon>
            </v-btn>
          </v-list-tile-action>
        </template>
      </p-content-tile>
      <div v-else>
        <v-btn flat @click="goToStep0" class="mb-4"> Back </v-btn>
        <v-btn color="primary" @click="selectContent" class="mb-4">Select Content</v-btn>
      </div>
      <div v-if="template">
        <v-btn flat @click="goToStep0"> Back </v-btn>
        <v-btn v-if="isLessonPlan" color="#48C634" dark @click="goToStep2">Next</v-btn>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on">Next</v-btn>
          </template>
          <span>Please, select a Interactive Presentation content</span>
        </v-tooltip>
      </div>
    </div>
  </v-stepper-content>
</template>

<script>
import PServiceContent from "plant-common/src/services/PServiceContent"
import uuid from "../../../../../utils/uuid.js"
import { PContentTile } from "plant-common"
import { getType } from "../../../../../utils/dbTool.js"

export default {
  props: ["step", "template"],
  components: { PContentTile },
  computed: {
    isLessonPlan() {
      return getType(this.template.reference) == "lessonPlan"
    }
  },
  methods: {
    goToStep0() {
      this.$emit("setStep", 0)
    },
    goToStep2() {
      this.$emit("setStep", 2)
    },
    async selectContent() {
      var newContentRef = await PServiceContent.selectContent()
      if (!newContentRef) return

      const template = {
        id: uuid(),
        reference: newContentRef
      }

      this.$emit("setTemplate", template)
    },
    removeTemplate() {
      this.$emit("setTemplate", null)
    }
  }
}
</script>