<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn small icon v-on="$listeners" :disabled="disabled" :style="style">
        <v-icon v-if="icon" small v-on="on">{{ icon }}</v-icon>
        <span v-else v-on="on"> 
          <slot name="icon"></slot>
        </span>
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["icon", "tooltip", "disabled", "isActive", "dark", "background"],
  computed: {
    style() {
      return {
        backgroundColor: this.dark ? this.darkColors : this.lightColors
      };
    },
    darkColors() {
      return this.isActive ? "rgb(25, 118, 210)" : "rgb(66, 66, 66)";
    },
    lightColors() {
      return this.isActive ? "#dedede" : this.background ? this.brackground : "#ffffff";
    }
  }
};
</script>

<style>
</style>