<template>
  <fragment>
    <p-panel-properties-group title="Text alignment">
      <text-alignment-form :data="value.data" :show-justify="true" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Font">
      <font-global-form
        :data="value.data"
        :id="value.id"
        :type="value.type"
        :font-sizes-numbers="fontSizesNumbers"
        :showAlternatePadding="true"
        :showParagraphSpacing="true" />
    </p-panel-properties-group>
  </fragment>
</template>
<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import { Fragment } from "vue-fragment"
import FontGlobalForm from "../_components/panel/FontGlobalForm.vue"
import { fontSizesNumbers } from "../_components/panel/fontGlobalCommon"
import TextAlignmentForm from "../_components/panel/TextAlignmentForm.vue"

export default {
  components: {
    PPanelPropertiesGroup,
    Fragment,
    FontGlobalForm,
    TextAlignmentForm
  },
  props: ["value"],
  data() {
    return {
      fontSizesNumbers
    }
  }
}
</script>
