import Vue from "vue"
import { uploadFile, getContentId } from "../../../utils";
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    data() {
      return {};
    },
    created() {
      vm.$emit('select', getContentId(itemRevision))
    },
    computed: {
      file() {
        if (vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0];
        } else {
          return false;
        }
      },
      selectedTag() {
        return vm.selectedTag;
      }
    },
    methods: {
      removeVideo() {
        itemRevision.body.attachments.splice(0)
      },
      uploadVideo(file) {
        if (file) {
          let url = URL.createObjectURL(file);
          itemRevision.body.attachments.push(new Attachment('Video', file, url, ".mp4"))
        } else {
          uploadFile()
            .then(({ url, file }) => {
              itemRevision.body.attachments.push(new Attachment('video', file, url, ".mp4"))
            })
        }
      }
    }
  })
}