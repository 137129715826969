<template>
  <div>
    <!-- <v-text-field
      solo
      label="Search"
      prepend-inner-icon="search"
      v-model="treeSearch"
      class="lp-tree-edit-search"
      v-if="
        lessonPlanController.slidesTree &&
        lessonPlanController.slidesTree.slides.length > 0
      "
		></v-text-field>-->
    <div v-if="lessonPlanController.slideArray.length === 0" class="text-center">
      <div class="lp-tree-edit-no-slide-img-container">
        <img class="lp-tree-edit-no-slide-img" :src="require('../placeholder-no-slide.svg')" />
      </div>

      <p class="ma-4 subheading">No slide created, please add one to continue working</p>
      <v-btn outline color="default" @click="lessonPlanController.addSlide()">
        <v-icon left>mdi-shape-rectangle-plus</v-icon>
        Add Slide
      </v-btn>
    </div>
    <v-snackbar color="error" top center v-model="errorActive">
      {{ errorMessage }}
      <v-btn flat @click.native="errorActive = false">&times;</v-btn>
    </v-snackbar>

    <Tree
      :value="lessonPlanController.slidesTree.slides"
      class="lp-tree-edit"
      :draggable="lessonPlanController.mode == 'edit'"
      :droppable="lessonPlanController.mode == 'edit'"
      :ondragstart="onDragStart"
      :ondragend="onDragEnd">
      <span slot-scope="{ node, path, tree }">
        <span>
          <slide-detail
            :slide="node"
            :path="path"
            :tree="tree"
            :is-active="isSelected(node)"
            :is-edit-mode="
              lessonPlanController.mode == 'edit' && isSelected(node) && lessonPlanController.editTitleMode
            "
            @slideChanged="$emit('slideChanged')">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  color="red lighten-2"
                  v-on="on"
                  v-if="node.objectives.length > 0"
                  :class="{
                    'mr-2': lessonPlanController.mode == 'review' || lessonPlanController.mode == 'activity'
                  }"
                  >mdi-bullseye-arrow</v-icon
                >
              </template>
              <span v-if="node.objectives.length === 1">
                {{ node.objectives[0].header.metadata.custom_id }} -
                {{ node.objectives[0].header.metadata.title }}
              </span>
              <span v-else-if="node.objectives.length > 1"> Several objectives </span>
            </v-tooltip>
            <!-- Comments review mode -->
            <tree-comments
              :item="node"
              v-if="lessonPlanController.mode == 'review' || lessonPlanController.mode == 'edit' || isDraftOrPreview" />

            <!-- Actions edit mode -->
            <tree-actions
              :item="node"
              :lessonPlanObject="lessonPlanController.lessonPlanObject"
              v-if="
                lessonPlanController.mode == 'edit' &&
                !lessonPlanController.isQuizChildren(lessonPlanController.slidesTree.slides, node)
              "></tree-actions>

            <!-- Progress activity mode-->
            <tree-activity :item="node" v-if="lessonPlanController.mode == 'activity'" />
          </slide-detail>
        </span>
      </span>
    </Tree>
  </div>
</template>

<script>
import { Tree, Draggable, Fold, walkTreeData } from "he-tree-vue"
import TreeActions from "./TreeActions"
import TreeActivity from "./TreeActivity"
import TreeComments from "./TreeComments"
import SlideDetail from "./SlideDetail"
import _ from "lodash"

export default {
  inject: ["lessonPlanController"],
  components: {
    Tree: Tree.mixPlugins([Draggable, Fold]),
    TreeActions,
    TreeActivity,
    TreeComments,
    SlideDetail
  },
  data() {
    return {
      treeSearch: "",
      isDraftOrPreview: false,
      isActivityPreview: false,
      errorActive: false,
      errorMessage: ""
    }
  },
  mounted() {
    if (this.$router) {
      let path = this.$router.app.$route.fullPath.split("/")
      const urlPathMode = path[path.length - 1].split("#")[0]
      if (urlPathMode === "draft" || urlPathMode === "preview") {
        this.isDraftOrPreview = true
      }
      if (urlPathMode === "preview-activity") {
        this.isActivityPreview = true
      }
    }
  },
  watch: {
    treeSearch() {
      walkTreeData(this.lessonPlanController.slidesTree.slides, node => {
        this.diplayNode(node)

        if (this.treeSearch) {
          this.hideSlideIfNotInSearch(node)
        }
      })
    },
    "lessonPlanController.slideArray": {
      deep: false,
      handler() {
        walkTreeData(this.lessonPlanController.slidesTree.slides, node => {
          this.$set(node, "$droppable", this.lessonPlanController.isSection(node))
        })
        const updateSlidesDebounce = _.debounce(() => {
          this.lessonPlanController.updateSlides()
        }, 300)
        updateSlidesDebounce()
      }
    }
  },
  methods: {
    getTargetParentFromTargetPath(originalTargetPath) {
      const targetPath = JSON.parse(JSON.stringify(originalTargetPath))

      /**
       * getCloserParent: return closer parent to targetPath
       * - slide: current parent (Slide)
       * - targetPath: positions of slides to get the selected slide where element is drop
       *      example: [0, 3, 1] => recursivity needs to return slide.children[0].children[3].children[1]
       *  */
      function getCloserParent(slide, targetPath) {
        if (targetPath.length > 1) {
          let parent = slide.children[targetPath[0]]
          targetPath.shift()
          return getCloserParent(parent, targetPath)
        } else {
          return slide
        }
      }

      let parentFound = false
      if (targetPath.length > 1) {
        // if targetPath has two positions, get the first slide
        const slide = this.lessonPlanController.slidesTree.slides[targetPath[0]]
        targetPath.shift() // position 0 already accessed before, delete it from array
        parentFound = getCloserParent(slide, targetPath)
      }

      return parentFound
    },
    onDragStart(tree, draggableHelperInfo) {
      // isQuizChildren: avoid to move a node which is inside QuizSlide
      return !this.lessonPlanController.isQuizChildren(
        this.lessonPlanController.slidesTree.slides,
        draggableHelperInfo.dragNode
      )
    },
    onDragEnd(tree, draggableHelperInfo) {
      // Avoid to move a Slide/Section inside QuizSlide
      const targetParent = this.getTargetParentFromTargetPath(draggableHelperInfo.targetPath)
      if (targetParent && targetParent.type == "QuizSlide") {
        this.errorActive = true
        this.errorMessage = `Could not move slides inside Quiz`
        return false
      } else {
        return true
      }
    },
    isSelected(node) {
      return this.lessonPlanController.selectedSlide && node.id == this.lessonPlanController.selectedSlide.id
    },
    hideSlideIfNotInSearch(node) {
      if (node.children.length === 0) {
        this.$set(node, "$hidden", !this.isInSearch(node.title))
      } else {
        if (!this.isAnyChildInSearch(node)) {
          this.$set(node, "$hidden", !this.isInSearch(node.title))
        }
      }
    },
    diplayNode(node) {
      this.$set(node, "$hidden", false)
    },
    isAnyChildInSearch(section) {
      let result = false

      for (const child of section.children) {
        if (this.isInSearch(child.title)) {
          result = true
          break
        }
        if (child.children.length > 0) {
          result = this.isAnyChildInSearch(child)
        }
      }

      return result
    },
    isInSearch(title) {
      const treeSearch = this.treeSearch.toLowerCase()
      return title.toLowerCase().indexOf(treeSearch) > -1
    }
  }
}
</script>

<style>
.lp-tree-edit .tree-node:hover,
.lp-tree-edit .tree-node .v-btn:hover:before,
.lp-tree-edit .tree-node .v-btn:focus:before {
  background-color: transparent;
}

.lp-tree-edit .he-tree .tree-node:hover {
  background-color: #f5f5f5;
}

.lp-tree-edit-search {
  margin-bottom: -10px !important;
}

.lp-tree-edit-no-slide-img-container {
  display: flex;
  justify-content: center;
}

.lp-tree-edit-no-slide-img {
  width: 50%;
  margin-top: 40px;
}
</style>