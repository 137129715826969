<template>
  <p-toolbar dense class="lp-edit-toolbar transparent elevation-0" :class="{ dark: blockController.dark }">
    <p-toolbar-action icon="mdi-fullscreen" tooltip="Fit Zoom" @click="v => $emit('zoomFit')" />

    <v-divider vertical></v-divider>

    <div>
      <p-toolbar-button
        :disabled="!selectedBlock"
        :tooltip="selectedBlock ? 'Duplicate block' : 'Select a block to duplicate it'"
        icon="mdi-content-duplicate"
        @click="duplicateBlock"
        >Duplicate
      </p-toolbar-button>
      <p-toolbar-button
        :disabled="!selectedBlock"
        :tooltip="selectedBlock ? 'Copy block' : 'Select a block to copy it'"
        icon="mdi-content-copy"
        @click="copyToClipboard(selectedBlock)"
        >Copy
      </p-toolbar-button>
    </div>

    <div v-if="showPasteTool">
      <p-toolbar-button
        icon="mdi-content-paste"
        :disabled="readClipboardState != 'granted'"
        :tooltip="readClipboardState == 'granted' ? 'Paste block' : 'Clipboard permission not granted'"
        @click="pasteBlock"
        v-if="isInClipboard"
        >Paste
      </p-toolbar-button>
      <p-toolbar-button
        icon="mdi-book-remove"
        tooltip="Remove from clipboard"
        @click="clearClipboard"
        v-if="isInClipboard"
        >Clear Clipboard
      </p-toolbar-button>
    </div>

    <v-divider vertical></v-divider>

    <div>
      <component :is="selectedBlockToolbar" :value="selectedBlock || editingBlock" />
    </div>

    <v-spacer></v-spacer>

    <v-divider vertical></v-divider>
    <v-flex class="sm1">
      <v-select
        v-model="editModeSelect"
        @change="handleChangeSelect"
        :items="editModeSelectItems"
        :hide-details="true"
        solo
        flat
        :dark="lessonPlanController.editLayouts"
        class="lp-edit-toolbar-layout-selector"></v-select>
    </v-flex>
    <v-divider vertical></v-divider>

    <p-toolbar-button
      icon="mdi-trash-can-outline"
      @click="blockController.deleteBlock(selectedBlock.id)"
      color="red"
      :tooltip="
        !selectedBlock || (selectedBlock.protection && !selectedBlock.protection.delete)
          ? 'Remove block'
          : 'This block is protected against deletion'
      "
      :disabled="!selectedBlock || (selectedBlock.protection && selectedBlock.protection.delete)"
      >DELETE
    </p-toolbar-button>
  </p-toolbar>
</template>

<script>
import { PToolbarAction, PToolbar, PToolbarButton } from "plant-common"
import Blocks from "../../../index.js"
import { copyBlock, convertFileToObject, convertObjectToFile, cloneDeep } from "../../../../utils/utils.js"
import _ from "lodash"

export default {
  inject: ["blockController", "lessonPlanController"],
  components: {
    PToolbar,
    PToolbarAction,
    PToolbarButton
  },
  data() {
    return {
      isInClipboard: false,
      selectedBlock: null,
      selectedBlockToolbar: null,
      editingBlock: null, // block value when user is "editing-content"
      editingBlockActiveChildren: null,
      overlayActive: { type: null, overlay: null },
      readClipboardState: undefined,
      editModeSelect: null,
      editModeSelectItems: [
        { text: "Slides view", value: "Slides" },
        { text: "Layouts view", value: "Layouts" }
      ]
    }
  },
  created() {
    this.editModeSelect = this.lessonPlanController.editLayouts ? "Layouts" : "Slides"
  },
  mounted() {
    this.checkIsInClipboard()

    document.addEventListener("copy", async event => {
      // Copy only when the target is the block
      if (this.selectedBlock && event.target.tagName === "DIV" && event.target.classList.contains("movable")) {
        this.copyToClipboard(this.selectedBlock)
      }
      this.checkIsInClipboard()
    })
    document.addEventListener("paste", async () => {
      if (this.showPasteTool) {
        this.pasteBlock()
      }
    })

    document.addEventListener("visibilitychange", event => {
      if (document.visibilityState === "visible") {
        this.checkIsInClipboard()
      }
    })

    navigator.permissions.query({ name: "clipboard-read" }).then(permission => {
      this.readClipboardState = permission.state
      permission.onchange = () => {
        this.readClipboardState = permission.state
      }
    })

    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block, state }) => {
        // Do not mark as select block if state is "edit-content" (avoid deleting/duplicate/copy block when editing content)
        if (state === "edit-content") {
          this.editingBlock = block
          this.selectedBlock = null
        } else {
          this.editingBlock = null
          this.selectedBlock = block
        }
        if (!block) return
        var { type } = block
        if (Blocks[type] && Blocks[type].Toolbar) {
          this.selectedBlockToolbar = Blocks[type].Toolbar
        } else {
          this.selectedBlockToolbar = { template: "<div></div>" }
        }
      })
      this.blockController.$on(`childrenIdActive`, cardId => {
        this.editingBlockActiveChildren = cardId
      })
      this.blockController.$on(`overlayActive`, ({ type, overlay }) => {
        this.overlayActive = { type, overlay }
      })
    }
  },
  computed: {
    blocks() {
      if (!this.selectedBlock) return this.blockController.blocks
      return this.selectedBlockContext
    },
    showPasteTool() {
      return (
        ((!this.editingBlock && !this.selectedBlock) ||
          (this.editingBlock && this.editingBlock.type === "tabs") ||
          (this.editingBlock && this.editingBlock.type === "carousel") ||
          (this.editingBlock && this.editingBlock.type === "flashCard") ||
          (this.editingBlock && this.editingBlock.type === "contentMultipleChoiceQuestion") ||
          (this.editingBlock && this.editingBlock.type === "contentMultipleChoiceQuestionTemplate") ||
          (this.editingBlock && this.editingBlock.type === "adaptiveLearningResult") ||
          (this.overlayActive.overlay && !this.selectedBlock)) &&
        this.isInClipboard
      )
    }
  },
  methods: {
    addCopiedBlock(copy) {
      switch (this.editingBlock.type) {
        case "carousel":
          var carouselChildren = this.editingBlock.data.cards.find(card => card.id == this.editingBlockActiveChildren)
          if (carouselChildren) {
            carouselChildren.blocks.push(copy)
          }
          break
        case "tabs":
          var tabChildren = this.editingBlock.data.tabs.find(tab => tab.id == this.editingBlockActiveChildren)
          if (tabChildren) {
            tabChildren.blocks.push(copy)
          }
          break
        case "flashCard":
        case "contentMultipleChoiceQuestion":
        case "contentMultipleChoiceQuestionTemplate":
        case "libraryContentCustom":
          if (this.overlayActive.overlay) {
            this.overlayActive.overlay.blocks.push(copy)
          } else {
            this.editingBlock.data.blocks.push(copy)
          }
          break
        case "adaptiveLearningResult":
          this.editingBlock.data.blocks.push(copy)
          break
        default:
          if (this.overlayActive.overlay) {
            this.overlayActive.overlay.blocks.push(copy)
          }
          break
      }
    },
    duplicateBlock() {
      const copy = copyBlock(this.selectedBlock, { x: 0, y: 0 })
      var editBlockId = false
      const parent = this.blockController.getParent(this.selectedBlock.id)
      // parent: selectedBlock is inside other block
      if (parent) {
        // editingBlockActiveChildren: tabs/cards
        // overlayActive: [popup, multipleChoice, flashCard, etc...] overlay selected
        if (this.editingBlockActiveChildren || this.overlayActive.overlay) {
          this.editingBlock = parent
          editBlockId = this.editingBlock.id
          this.addCopiedBlock(copy)
        } else {
          // block is inside other block and in its root (parent.data.blocks)
          parent.data.blocks.push(copy)
        }
      } else {
        this.blockController.blocks.push(copy)
      }
      this.$nextTick(() => {
        this.blockController.selectionClear()
        if (editBlockId) {
          this.blockController.selectionEdit(editBlockId)
        }
        this.blockController.selectionAdd(copy.id)
      })
    },
    handleChangeSelect() {
      if (this.editModeSelect === "Slides") {
        this.lessonPlanController.changeToEditSlides()
      } else {
        this.lessonPlanController.changeToEditLayout()
      }
    },
    async pasteBlock() {
      let block = await this.getFromClipboard()
      if (block) {
        await convertObjectToFile(block)
        const copy = copyBlock(block, { x: 0, y: 0 })
        var editBlockId = false
        if (this.editingBlock || this.selectedBlock) {
          if (this.editingBlock) {
            editBlockId = this.editingBlock.id
            this.blockController.selectionEdit(this.editingBlock.id)
          } else {
            editBlockId = this.selectedBlock.id
            this.blockController.selectionEdit(this.selectedBlock.id)
          }
          this.addCopiedBlock(copy)
        } else {
          this.blockController.blocks.push(copy)
        }
        this.$nextTick(() => {
          this.blockController.selectionClear()
          if (editBlockId) {
            this.blockController.selectionEdit(editBlockId)
          }
          this.blockController.selectionAdd(copy.id)
        })
      }
    },
    clearClipboard() {
      navigator.clipboard.writeText("")
      this.isInClipboard = false
    },
    async checkIsInClipboard() {
      let block = await this.getFromClipboard()
      this.isInClipboard = block !== null
    },
    async getFromClipboard() {
      try {
        let text = await navigator.clipboard.readText()
        const json = JSON.parse(text)
        return typeof json === "object" ? json : null
      } catch (e) {
        return null
      }
    },
    copyToClipboard(selectedBlock) {
      // Check if selectedBlock has _file objects
      const clipboardObject = cloneDeep(selectedBlock)
      convertFileToObject(clipboardObject)
      var text = JSON.stringify(clipboardObject)
      navigator.clipboard
        .writeText(text)
        .then(() => {
          this.isInClipboard = true
        })
        .catch(e => {
          this.isInClipboard = false
        })
    }
  }
}
</script>

<style>
.lp-edit-toolbar {
  height: 50px !important;
  z-index: 5;
}

.lp-edit-toolbar > .v-toolbar__content {
  padding-left: 0px;
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  height: auto !important;
}

.lp-edit-toolbar.dark .v-toolbar__content {
  background-color: rgb(66, 66, 66);
  color: #fff;
}

.lp-edit-toolbar.dark .v-btn__content {
  color: #fff;
}

.lp-edit-toolbar.dark .v-btn {
  background-color: transparent !important;
}

.lp-edit-toolbar-layout-selector {
  width: 160px;
  right: 20px;
}
</style>
