var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"id":"metadata","fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-2",attrs:{"xs12":"","md4":""}},[_c('v-text-field',{attrs:{"rules":_vm.titleRules,"label":"Title","readonly":_vm.readonly,"required":""},model:{value:(_vm.value.body.contents.metadata.title),callback:function ($$v) {_vm.$set(_vm.value.body.contents.metadata, "title", $$v)},expression:"value.body.contents.metadata.title"}}),_c('v-text-field',{attrs:{"min":"0","rules":_vm.timeRules,"readonly":_vm.readonly,"label":"Completion Time (minutes)","type":"number"},model:{value:(_vm.value.body.contents.metadata.completionTime),callback:function ($$v) {_vm.$set(_vm.value.body.contents.metadata, "completionTime", $$v)},expression:"value.body.contents.metadata.completionTime"}}),_c('v-select',{attrs:{"prepend-icon":"mdi-human-male-board","box":"","items":[
          'Instructor-Led Classroom Lecture',
          'Instructor-Led Online Lecture',
          'Self-Paced',
          'Blended',
          'Adaptive Learning',
          'Laboratory Lesson'
        ],"readonly":_vm.readonly,"label":"Teaching Style"},model:{value:(_vm.value.body.contents.metadata.methodology),callback:function ($$v) {_vm.$set(_vm.value.body.contents.metadata, "methodology", $$v)},expression:"value.body.contents.metadata.methodology"}})],1),_c('v-flex',{staticClass:"px-2",attrs:{"xs12":"","md8":""}},[_c('p-rich-text',{attrs:{"placeholder":"Description of the lesson","maxHeight":"50vh","minHeight":"25vh","canEdit":!_vm.readonly},model:{value:(_vm.value.body.contents.metadata.description),callback:function ($$v) {_vm.$set(_vm.value.body.contents.metadata, "description", $$v)},expression:"value.body.contents.metadata.description"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }