<template >
  <v-dialog width="25%" height="25%">
    <template v-slot:activator="{ on }">
      <v-btn icon class="clickable">
        <img
          ref="logo"
          class="mx-3"
          :src="require('@/../assets/icon.svg')"
          v-on="on"
          height="18px"
          style="vertical-align: middle" />
      </v-btn>
    </template>
    <v-card class="mx-auto">
      <v-card-text>
        <v-img class="pa-5 ma-4" :src="require('../assets/plant-logo.svg')" aspect-ratio="5" contain></v-img>
        <v-data-table :items="versionInfo" hide-actions>
          <template slot="items" slot-scope="props">
            <tr v-if="props.item.info">
              <td>{{ props.item.label }}</td>
              <td class="text-right font-weight-bold">{{ props.item.info }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { STORE_USER_NAME, STATE_VERSION_INFO } from "@/store_constants/user"
export default {
  computed: {
    ...mapState(STORE_USER_NAME, {
      versionInfo: STATE_VERSION_INFO
    })
  }
}
</script>

<style scoped>
.clickable {
  -webkit-app-region: no-drag;
}
</style>