<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <portal :to="`contentMultipleChoiceQuestion-toolbar-${value.id}`" v-if="mode === 'edit'">
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-restore"
          tooltip="Reset to default styles"
          @click="resetToDefaultStyles"
          >Reset to default styles
        </p-toolbar-button>
        <p-toolbar-menu v-if="isContentSelected" icon="mdi-layers-outline" title="Layers" tooltip="Display layers">
          <v-list dense>
            <v-list-tile @click="displayQuestion()">
              <v-list-tile-action>
                <v-icon>mdi-border-all-variant</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Question</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('correct')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Popup when Correct</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('incorrect')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Popup when Incorrect</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </p-toolbar-menu>
      </portal>
      <template v-if="mode === 'edit'">
        <div class="lp-block-library-content">
          <template>
            <block-subcanvas :value="value" :blocks="value.data.blocks" :mode="mode" :blockVariables="blockVariables" />
          </template>
        </div>
      </template>
      <template v-else>
        <block-subcanvas :blocks="value.data.blocks" mode="view" :blockVariables="blockVariables" />
      </template>
    </template>
  </basic-block>
</template>

<script>
import { PToolbarButton, PToolbarMenu } from "plant-common"
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockOverlay from "../_components/containers/BlockOverlay.vue"
import BlockSubcanvas from "../_components/containers/BlockSubcanvas.vue"
import blockOverlayCommon from "../../blocks/_mixins/blockOverlayCommon.vue"
import BlockTemplateMultipleChoiceCustom from "./class"
import { orderChoices } from "../../../../../../plant-common/src/components/plant-item-viewer/activity-multiple-choice/helpers"

export default {
  name: "TemplateBlockMultipleChoiceTemplateCustom",
  inject: ["blockController"],
  mixins: [blockOverlayCommon],
  components: {
    PToolbarButton,
    PToolbarMenu,
    BasicBlock,
    BlockSubcanvas,
    BlockOverlay
  },
  props: ["value", "mode"],
  data() {
    return {
      correctChoice: 0,
      blockVariables: false
    }
  },
  created() {
    if (this.isContentSelected) this.addVariables()
  },
  beforeDestroy() {
    if (this.correctOverlay) {
      this.blockController.$off(`state-changed:${this.correctOverlay.id}`)
      this.correctOverlay.blocks.forEach(correctBlock => {
        this.blockController.$off(`state-changed:${correctBlock.id}`)
      })
    }
    if (this.incorrectOverlay) {
      this.blockController.$off(`state-changed:${this.incorrectOverlay.id}`)
      this.incorrectOverlay.blocks.forEach(incorrectBlock => {
        this.blockController.$off(`state-changed:${incorrectBlock.id}`)
      })
    }
  },
  computed: {
    isContentSelected() {
      return Object.keys(this.value.data.content_ref).length > 0
    },
    correctOverlay() {
      return this.value.data.overlays.correct
    },
    incorrectOverlay() {
      return this.value.data.overlays.incorrect
    }
  },
  watch: {
    "value.data.content_ref": {
      immediate: true,
      deep: true,
      handler() {
        this.addVariables()
      }
    },
    "blockController.overlay": {
      immediate: true,
      handler() {
        if (!this.blockController.overlay) {
          this.blockController.$emit("overlayActive", { type: null, overlay: null })
        }
      }
    },
    "correctOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.correctOverlay) {
          this.blockController.$on(`state-changed:${this.correctOverlay.id}`, state => {
            if (state === "primary-selected") {
              this.displayOverlay("correct")
            }
          })
          // if correct inoverlay have blocks, listen to each block state-change
          this.correctOverlay.blocks.forEach(correctBlock => {
            this.blockController.$on(`state-changed:${correctBlock.id}`, state => {
              if (state === "primary-selected") {
                this.displayOverlay("correct")
              }
            })
          })
        }
      }
    },
    "incorrectOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.incorrectOverlay) {
          this.blockController.$on(`state-changed:${this.incorrectOverlay.id}`, state => {
            if (state === "primary-selected") {
              this.displayOverlay("incorrect")
            }
          })
          // if correct inoverlay have blocks, listen to each block state-change
          this.incorrectOverlay.blocks.forEach(incorrectBlock => {
            this.blockController.$on(`state-changed:${incorrectBlock.id}`, state => {
              if (state === "primary-selected") {
                this.displayOverlay("incorrect")
              }
            })
          })
        }
      }
    }
  },
  methods: {
    addVariables() {
      if (this.value.data.content_ref.body) {
        const { answer, distractors, position } = this.value.data.content_ref.body.contents
        const pictureUrl = require("./background_empty.svg")
        const choices = orderChoices(answer, distractors, position)
        this.correctChoice = choices.findIndex(c => c == answer)
        this.blockVariables = [
          {
            name: "ContentHeaderMetadataTitle",
            type: "string",
            value: this.value.data.content_ref.header.metadata.title,
            title: "Content Title"
          },
          {
            name: "ContentHeaderMetadataCustomId",
            type: "string",
            value: this.value.data.content_ref.header.metadata.custom_id,
            title: "Content Custom ID"
          },
          {
            name: "QuestionQuestion",
            type: "string",
            value: this.value.data.content_ref.body.contents.question,
            title: "Question"
          },
          {
            name: "QuestionChoices",
            type: "array",
            value: choices,
            title: "Choices"
          },
          {
            name: "QuestionUserChoice",
            type: "number",
            value: undefined,
            title: "User Choice"
          },
          {
            name: "QuestionAnswerExplanation",
            type: "string",
            value: this.value.data.content_ref.body.contents.explanation,
            title: "Answer Explanation"
          },
          {
            name: "QuestionAnswer",
            type: "string",
            value: this.value.data.content_ref.body.contents.answer,
            title: "Answer"
          },
          {
            name: "QuestionImageUrl",
            type: "string",
            value: pictureUrl,
            title: "Picture"
          }
        ]
      }
    },
    resetToDefaultStyles() {
      this.value = new BlockTemplateMultipleChoiceCustom()
      this.value.data.content_ref = {
        body: {
          references: [],
          attachments: [],
          contents: {
            question: "[Question template]",
            answer: "[Answer correct template]",
            explanation: "<p>[Explanation correct answer template]</p>",
            position: "R",
            objective: "",
            distractors: ["[Distractor 1 template]", "[Distractor 2 template]", "[Distractor 3 template]"],
            hotspots: []
          }
        },
        approval_process: {},
        __dependencies: [],
        _id: "template-default-id",
        header: {
          type_version: "2.0",
          metadata: {
            title: "[Multiple Choice Activity Title Template]",
            content_revision_type: "activityMultipleChoice",
            content_id: "content-default-id",
            custom_id: "[AMC Custom ID Template]"
          },
          major_revision: 1,
          minor_revision: 0
        },
        __latest: "1.0"
      }
    },
    displayQuestion() {
      this.closeOverlay()
      this.blockController.overlayVariables = null
      this.blockController.$emit("overlayActive", { type: null, overlay: null })
    },
    displayOverlay(type) {
      this.blockController.overlay = { ...this.value.data.overlays[type], parent: this.value.id }
      this.blockController.overlayVariables = this.blockVariables
      this.blockController.$emit("overlayActive", { type: type, overlay: this.value.data.overlays[type] })
    }
  }
}
</script>

<style scoped>
.lp-block-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: transparent;
  position: relative;
}
</style>
../_mixins/blockOverlayCommon.vue