const available_subscriptions = [
    {
        code: "user-login",
        title: "User has logged in",
        readable: function (content) {
            return `Logged at ${content.timestamp}`
        }
    },
    {
        code: "contributor-assigned-myself",
        title: "Added as contributor",
        readable: function (content) {
            return `You've been assigned as <b>${content.event.payload.new_role}</b> for <b>${content.event.payload && content.event.payload.metadata ? content.event.payload.metadata.custom_id : ""}</b> draft`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/draft`
        }
    },
    {
        code: "workflow-state-change",
        title: "Workflow state updated",
        readable: function (content) {
            return `Workflow state has been updated <b>${content.event.payload && content.event.payload.metadata ? content.event.payload.metadata.custom_id : ""}</b> draft to <b>${content.event.payload.workflow.state}</b>`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/draft`
        }
    },
    {
        code: "workflow-change",
        title: "Workflow type updated",
        readable: function (content) {
            return `Workflow type has been updated <b>${content.event.payload && content.event.payload.metadata ? content.event.payload.metadata.custom_id : ""}</b> draft to <b>${content.event.payload.workflow.name}</b>`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/draft`
        }
    },
    {
        code: "content-approved",
        title: "Content has been approved",
        readable: function (content) {
            return `A new content has been approved <b>${content.event.payload && content.event.payload.metadata ? content.event.payload.metadata.custom_id : ""}</b>`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/latest`
        }
    },
    {
        code: "new-content",
        title: "New content created",
        readable: function (content) {
            return `A new draft has been created <b>${content.event.payload ? content.event.payload.custom_id : ""}</b>`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/draft`
        }
    },
    {
        code: "scorm-completed",
        title: "SCORM successfully created",
        readable: function (content) {
            return `New SCORM has been created <b>${content.event.payload ? content.event.payload.custom_id : ""}</b>${content.event.payload ? " (" + content.event.payload.major_revision + "." + content.event.payload.minor_revision + ") " : ""}`
        },
        getURL: function (payload) {
            return `${window.location.protocol}//${window.location.host}/api/library/${payload.library_id}/content/${payload.content_id}/${payload.major_revision}/${payload.minor_revision}/download/url${payload.scorm_version ? "?version=" + payload.scorm_version : ""}`
        }
    },
    {
        code: "scorm-failed",
        title: "SCORM failed",
        readable: function (content) {
            return `Something happened when trying to create SCORM for <b>${content.event.payload ? content.event.payload.custom_id : ""}</b>${content.event.payload ? " (" + content.event.payload.major_revision + "." + content.event.payload.minor_revision + ") " : ""}`
        },
        getURL: function (payload) {
            return `/content/${payload.library_id}/${payload.content_id}/${payload.major_revision}/${payload.minor_revision}`
        }
    },
]
export default available_subscriptions