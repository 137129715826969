import Component from "./index.vue"

export default {
    name: "objective",
    title: "Objective",
    icon: require("./content-icon.svg"),
    category: "training",
    Viewer: Component,
    Editor: Component,
    Preview: Component
}