<template>
  <v-toolbar class="transparent elevation-0" extension-height="50px">
    <v-toolbar-title>Content Explorer</v-toolbar-title>
    <v-spacer></v-spacer>
    <search-bar />

    <template v-slot:extension>
      <breadcrumb class="explorer-content-breadcrumb" />
      <v-spacer></v-spacer>
      <template v-if="isAdmin || isSupervisor">
        <v-spacer></v-spacer>
        <content-type-filter-icon />
        <v-divider vertical class="mx-2"></v-divider>
        <content-view-toggle />
        <v-divider vertical class="mx-2"></v-divider>
        <span id="global-options">
          <tool-new-folder />
          <tool-new-content tooltip="Create content in current path" />
          <toolbar-button
            icon="mdi-upload"
            tooltip="Bulk upload new content"
            @click="openBulkUploadSelector"></toolbar-button>
        </span>
        <v-divider vertical class="mx-2" v-if="selected.length > 0"></v-divider>
        <span id="folder-options" v-if="isAllSelectedFolders">
          <tool-rename-folder />
          <tool-delete-folder />
        </span>
        <span id="all-content-options" v-if="isAllSelectedContents && !isAnyDraftSelected">
          <tool-new-revision />
        </span>
        <span id="same-type-content-options" v-if="isAllSelectedSameType && !isAnyDraftSelected">
          <tool-bulk-change />
        </span>
        <span id="global-selected-options">
          <tool-move-to />
        </span>
      </template>
    </template>
  </v-toolbar>
</template>

<script>
import { mapState, mapGetters } from "vuex"
import { PToolbar } from "plant-common"
import ToolNewRevision from "./Tools/NewRevision"
import ToolBulkChange from "./Tools/BulkChange"
import ToolNewFolder from "./Tools/NewFolder"
import ToolNewContent from "./Tools/NewContent"
import ToolRenameFolder from "./Tools/RenameFolder"
import ToolMoveTo from "./Tools/MoveTo"
import ContentTypeFilterIcon from "./Tools/ContentTypeFilterIcon.vue"
import SearchBar from "./Tools/SearchBar.vue"
import ToolDeleteFolder from "./Tools/DeleteFolder"
import ToolbarButton from "./Tools/ToolbarButton"

import ContentViewToggle from "./Tools/ContentViewToggle"
import BulkUpload from "./BulkUpload/index.vue"
import TypeDialog from "@/pages/workflow/components/new-content/TypeDialog.vue"
import { PtModal } from "plant-common"
import { Extensions } from "plant-common"
import * as ContentBodies from "@/classes/content/index.js"

import Breadcrumb from "./Tools/Breadcrumb"
import { STORE_USER_NAME, GETTER_IS_ADMIN, GETTER_IS_SUPERVISOR } from "@/store_constants/user"

import { STORE_EXPLORER_NAME, STATE_FOLDER, STATE_SELECTED } from "@/store_constants/explorer"
export default {
  mixins: [PtModal],
  components: {
    Breadcrumb,
    ToolbarButton,
    ToolNewFolder,
    ToolNewContent,
    ToolRenameFolder,
    ToolDeleteFolder,
    ToolMoveTo,
    ToolNewRevision,
    ToolBulkChange,
    PToolbar,

    ContentTypeFilterIcon,
    SearchBar,
    BulkUpload,
    TypeDialog,
    ContentViewToggle
  },
  data() {
    return {
      bulkupload_dialog: false
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      selected: STATE_SELECTED,
      folder: STATE_FOLDER
    }),
    ...mapGetters(STORE_USER_NAME, {
      isAdmin: GETTER_IS_ADMIN,
      isSupervisor: GETTER_IS_SUPERVISOR
    }),
    isAllSelectedContents() {
      if (!this.selected?.length > 0) return false
      
      let contents = []
      this.selected.forEach(e => e.header && contents.push(e))
      
      return contents.length === this.selected.length
    },
    isAllSelectedSameType() {
      if (!this.selected?.length > 0) return false
      
      let contents = []
      let content_type = false
      if (this.selected[0].header) {
        content_type = this.selected[0].header.metadata.content_revision_type
      }
      if (!content_type) return false
      
      this.selected.forEach(
        e => e.header && e.header.metadata.content_revision_type === content_type && contents.push(e)
      )
      
      return contents.length === this.selected.length
    },
    isAllSelectedFolders() {
      if (!this.selected?.length > 0) return false
      
      let folders = []
      this.selected.forEach(e => !e.header && folders.push(e))

      return folders.length === this.selected.length
    },
    isAnyDraftSelected() {
      return this.selected.some(item => item.state === "draft")
    }
  },
  methods: {
    async openBulkUploadSelector() {
      const bulk_upload_types = Object.values(ContentBodies).map(cb => cb.CONTENT_TYPE)
      const availableContentTypes = Object.values(Extensions).filter(extension => {
        return bulk_upload_types.some(available => available === extension.name)
      })
      var type = await this.$modal(TypeDialog, {
        content_types: availableContentTypes
      })
      await this.$modal(BulkUpload, {
        value: true,
        content_type: type.name
      })
    }
  }
}
</script>
