import { $http } from "../config";

const Plants = {
	getAllPlants: () => $http(`/plants`).then(r => r.data),
	getPlant: (plant_id) => $http(`/plants/${plant_id}`).then(r => r.data),
	createPlant: (name, description, img) => $http.post(`/plants`, { name, description, img }).then(r => r.data),
	deletePlant: (plant_id) => $http.delete(`/plants/${plant_id}`).then(r => r.data),
	updatePlant: (plant_id, { name, description, img, active }) => $http.put(`/plants/${plant_id}`, { name, description, img, active }).then(r => r.data),
};

export default Plants;
