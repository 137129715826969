import Content from "./index.vue"
import ExplorerContent from "./ExplorerContent.vue"
import store from "./store"
export default {
  path: "training",
  name: "Training",
  component: Content,
   children :[
    {
      path:":library_id",
      name: 'Training List',
      props:true,
      component: ExplorerContent,
      meta:{
        store
      }
    }
  ]

}
