import Vue from "vue"
import { getTitle, uploadFile } from "../../../utils";
import PServiceContent from "../../../services/PServiceContent";

class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}

export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    data() {
      return {

      };
    },
    computed: {
      file() {
        if (
          vm.value.body.attachments &&
          vm.value.body.attachments.length > 0
        ) {
          return vm.value.body.attachments[0];
        } else {
          return false;
        }
      },
      description() {
        if (itemRevision && itemRevision.body.contents && itemRevision.body.contents.description) {
          return itemRevision.body.contents.description;
        } else {
          return '';
        }
      },
      requeriments() {
        if (itemRevision && itemRevision.body.contents && itemRevision.body.contents.requeriments) {
          return itemRevision.body.contents.requeriments;
        } else {
          return '';
        }
      },
      outline() {
        if (itemRevision && itemRevision.body.contents && itemRevision.body.contents.outline) {
          return itemRevision.body.contents.outline;
        } else {
          return [];
        }
      },
      contents() {
        if (itemRevision && itemRevision.body.contents) {
          return itemRevision.body.contents;
        } else {
          return false;
        }
      }
    },
    methods: {
      addModule() {
        this.outline.push({
          id: Math.random() * 1000000,
          type: "module",
          title: `Module ${this.outline.length + 1}`,
          visible: false,
          children: []
        });
      },
      addContent(item) {
        PServiceContent.selectContent().then(res => {
          if (res) {
            item.visible = true;
            item.children.push({
              title: getTitle(res),
              value: { ...res.header }
            });
          }
        })
      },
      deleteContent(item) {
        this.outline.forEach((element, index) => {
          if (element == item) {
            this.outline.splice(index, 1)
          }
        });
      },
      deleteSubContent(item, subItem) {
        this.outline.forEach(e => {
          if (e == item) {
            e.children.forEach((k, key) => {
              if (k == subItem) e.children.splice(key, 1)
            })
          }
        });
      },
      removePicture() {
        itemRevision.body.attachments.splice(0)
      },
      uploadPicture(file) {
        this.removePicture()
        if (file) {
          let url = URL.createObjectURL(file);
          itemRevision.body.attachments.push(new Attachment('Document', file, url, ".jpg"))
        } else {
          uploadFile("image/*")
            .then(({ url, file }) => {
              itemRevision.body.attachments.push(new Attachment('document', file, url, ".jpg"))
            })
        }
      },
    }
  })
}