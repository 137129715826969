import MarkerCircle from "./MarkerCircle.vue"

export default [
    {
        value: 'none',
        title: 'None',
        icon: 'mdi-minus',
        delta: 0,
        component: false
    },
    {
        value: 'circle',
        title: 'Circle',
        delta: 0,
        icon: 'mdi-ray-start',
        component: MarkerCircle
    },
]
