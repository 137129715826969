<template>
  <p-content-container>
    <splitpanes class="default-theme">
      <ViewerNavBar splitpanes-size="25" splitpanes-min="00" splitpanes-max="40"/>
      <div splitpanes-size="75" class="default-theme" horizontal="horizontal" style="height:100%">
        <v-card style="height: calc(100vh - 155px);">
          <ViewerCard />
        </v-card>
        <GuideActions/>
      </div>
    </splitpanes>
    <ViewerShortcuts />
  </p-content-container>
</template>

<script>
import { PContentContainer } from "plant-common";
import GuideActions from "./GuideActions";
import ViewerNavBar from "../StudentViewer/ViewerNavBar";
import ViewerShortcuts from "../Viewer/ViewerShortcuts";
import ViewerCard from "../Common/Viewer";
import guideController from "../GuideController";
import Splitpanes from "splitpanes";

export default {
  name: "GuideViewer",
  props: ["value"],
  components: {
    GuideActions,
    ViewerCard,
    ViewerNavBar,
    ViewerShortcuts,
    PContentContainer,
    Splitpanes
  },
  provide() {
    return {
      guideController: guideController(this)
    };
  }
};
</script>

<style>
#pane_0 {
  overflow-y:auto;
}

.default-theme {
  height: calc(100vh - 85px);
}
</style>./ViewerNavBar