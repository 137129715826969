
var submitStatementFunction = () => new Promise((resolve, reject) => reject('Not implemented'))

/**
 * Registers a driver that implements the PServiceXAPI
 * @param {Object} PXAPIDriver 
 * @param {function} PXAPIDriver.submitStatement - A driver function to submit statement
 */
const registerDriver = function ({submitStatementDriver}) {
    submitStatementFunction = submitStatementDriver

}
/**
 * Submit statement
 * @returns {Promise<Statement>} The Statement submited by user
 */
const submitStatement = function (statement) {
    return submitStatementFunction(statement)
}

export default { submitStatement, registerDriver }