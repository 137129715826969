import Vue from "vue"
import { getContentId } from "../../../utils";

export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    data() {
      return {};
    },
    created() {
      vm.$emit('select', getContentId(itemRevision))
    },
    computed: {
      datasheetConfig() {
        if (itemRevision.body.contents) {
          return itemRevision.body.contents;
        } else {
          return false;
        }
      },
      selectedTag() {
        return vm.selectedTag;
      }
    },
    methods: {
      setNewSchema(newSchema) {
        itemRevision.body.contents = newSchema
      }
    }
  })
}