<script>
export default {
  inject: ["blockController", "blockDefinitions"],
  data() {
    return {
      translateX: 0,
      translateY: 0,
      viewerWidth: 0,
      viewerHeight: 0
    }
  },
  computed: {
    parent() {
      return this.$refs.viewer
    },
    slideStyles() {
      const { backgroundColor, border, boxShadow, color } = this.settingStyles.slide
      return {
        outline: border,
        boxShadow,
        color,
        transform: `scale(${this.blockController.scale}) translate(${this.translateX}px, ${this.translateY}px)`,
        backgroundColor
      }
    },
    styleCanvas() {
      return {
        fontFamily: this.defaultBlockStyles.fontFamily,
        width: "100%",
        height: "100%"
      }
    }
  },
  mounted() {
    // Watch for changes in viewer div to fit slide in the screen
    var ro = new ResizeObserver(this.zoomFit)
    if (this.$refs.viewer) {
      ro.observe(this.$refs.viewer)

      this.$refs.viewer.onwheel = event => {
        if (!event.ctrlKey) return
        event.preventDefault()
        var newScale = this.blockController.scale + event.deltaY * -0.001

        // Restrict scale
        this.blockController.scale = Math.min(Math.max(0.5, newScale), 4)
        var viewer = this.$refs.viewer
        var slide = this.$refs.canvas
        var { x, y } = viewer.getBoundingClientRect()
        this.blockController.origin.x = x - slide.getBoundingClientRect().x
        this.blockController.origin.y = y - slide.getBoundingClientRect().y
      }
      this.zoomFit()
    }
  },
  methods: {
    getBlockComponent(type) {
      return this.blockDefinitions[type].Block
    },
    zoomFit() {
      var viewer = this.$refs.viewer
      var slide = this.$refs.canvas
      if (viewer) {
        var newScale = Math.min(
          viewer.offsetWidth / (slide.offsetWidth + 30),
          viewer.offsetHeight / (slide.offsetHeight + 30)
        )
        this.translateX = (viewer.offsetWidth / 2 - slide.offsetWidth / 2) / newScale
        this.translateY = (viewer.offsetHeight / 2 - slide.offsetHeight / 2) / newScale
        this.blockController.scale = newScale
        var { x, y, width, height } = viewer.getBoundingClientRect()
        this.blockController.origin.x = x - slide.getBoundingClientRect().x
        this.blockController.origin.y = y - slide.getBoundingClientRect().y
        this.blockController.viewerWidth = width
        this.blockController.viewerHeight = height
      }
    }
  }
}
</script>

<style>
.lp-slide {
  width: 1920px;
  height: 1080px;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 32px;
  background: white;
}

.lp-slide-wrapper {
  position: absolute;
  top: 0px;
  bottom: 32px;
  left: 0px;
  right: 0px;
  overflow: auto;
  background: #cecece;
}

.lp-slide-wrapper:focus {
  outline: none !important;
}

.lp-slide-wrapper.dark {
  background-color: rgb(66, 66, 66) !important;
}
</style>
