<template>
  <viewer-svg v-if="guide.template" :svg="cover_svg" :fields="fields" />
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
import ViewerSvg from "./ViewerSvg";
import { getTitle, getCustomId } from "../../../../utils/dbTool";

export default {
  name: "GuideViewerCard",
  inject: ["guideController"],
  components: { ViewerSvg },
  computed: {
    ...mapInjectedData("guideController", ["guide", "content"]),
    cover_svg() {
      if (this.guide.template.body) {
        let cover = this.guide.template.body.attachments.find(
          (e) => e.name == "cover" && e.attachment_type == ".svg"
        );
        if (cover) return cover;
      }
      return this.getTemplate("cover").svg;
    },
    fields() {
      if (this.guide.template.body) {
        let cover = this.guide.template.body.attachments.find(
          (e) => e.name == "cover" && e.attachment_type == ".svg"
        );
        if (cover) {
          return {
            _title: getTitle(this.content),
            _custom_id: getCustomId(this.content),
          };
        }
      }
      return Object.assign({}, this.guide.cover.fields, {
        $CUSTOM_ID: getCustomId(this.content),
        $TITLE: getTitle(this.content),
      });
    },
  },
  methods: {
    ...mapInjectedMethods("guideController", ["getTemplate"]),
  },
};
</script>