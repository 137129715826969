<template>
  <marker 
    :viewBox="`0 0 ${radius*2} ${radius*2}`" 
    :refX="radius"
    :refY="radius" 
    :markerWidth="radius" 
    :markerHeight="radius" 
    orient="auto"
  >
    <circle :cx="radius" :cy="radius" :r="radius" :style="`fill: ${stroke}`" />
  </marker>
</template>

<script>
export default {
  props: ["stroke", "markerSize"],
  computed: {
    radius() {
      return 5 * this.markerSize
    }
  }
}
</script>