import MarkerCircle from "./MarkerCircle.vue"
import MarkerTriangleFilled from "./MarkerTriangleFilled.vue"

export default [
    {
        value: 'none',
        title: 'None',
        icon: 'mdi-minus',
        delta: 0,
        component: false
    },
    {
        value: 'arrow-bold',
        title: 'Arrow bold',
        icon: 'mdi-arrow-left-bold',
        delta: 5,
        component: MarkerTriangleFilled
    },
    {
        value: 'circle',
        title: 'Circle',
        delta: 0,
        icon: 'mdi-ray-start',
        component: MarkerCircle
    },
]
