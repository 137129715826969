export default function (elm) {
    if (elm.dataset) return elm
    elm.dataset = {}
    var getSetter = function (nodeName) {
        return function (newValue) {
            console.log(nodeName)
            return elm.setAttribute(nodeName, newValue)
        }
    }
    var getGetter = function (nodeName) {
        return function () {
            console.log(nodeName)
            return elm.getAttribute(nodeName)
        }
    }
    for (var i = 0; i < elm.attributes.length; i++) {
        var nodeName = elm.attributes[i].nodeName
        if (nodeName.slice(0, 5) === "data-") {
            Object.defineProperty(elm.dataset, nodeName.slice(5), {
                set: getSetter(nodeName),
                get: getGetter(nodeName)
            });
        }
    }
    return elm
}
