export default {
  methods: {
    getBlockById(id) {
      function getBlock(blocks, id) {
        let foundBlock
        for (const block of blocks) {
          if (id == block.id) {
            foundBlock = block
          } else if (block.hasOwnProperty("blocks")) {
            foundBlock = getBlock(block.blocks, id)
          } else if (block.type === "carousel") {
            foundBlock = getBlock(block.data.cards, id)
          } else if (block.type === "tabs") {
            foundBlock = getBlock(block.data.tabs, id)
          } else if (block.type === "flashCard") {
            foundBlock = getBlock(block.data.blocks, id)
            if (!foundBlock) {
              foundBlock = getBlock(block.data.overlays.answer.blocks, id)
            }
          } else if (block.type === "libraryContentCustom") {
            foundBlock = getBlock(block.data.blocks, id)
            if (!foundBlock) {
              foundBlock = getBlock(block.data.overlays.expanded.blocks, id)
            }
          } else if (
            block.type === "contentMultipleChoiceQuestion" ||
            block.type === "contentMultipleChoiceQuestionTemplate" ||
            block.type === "adaptiveLearningResult"
          ) {
            foundBlock = getBlock(block.data.blocks, id)
            if (!foundBlock && block.data.overlays) {
              // Try to find block in correct overlay
              foundBlock = getBlock(block.data.overlays.correct.blocks, id)
            }
            if (!foundBlock && block.data.overlays) {
              // Try to find block in incorrect overlay
              foundBlock = getBlock(block.data.overlays.incorrect.blocks, id)
            }
          }
          if (!foundBlock && block.actions) {
            const popup = block.actions.find(action => action.type == "popup")
            if (popup) {
              foundBlock = getBlock(popup.blocks, id)
            }
          }
          if (foundBlock) return foundBlock
        }
      }

      return getBlock(this.blocks, id)
    }
  }
}
