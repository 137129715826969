<template>
  <toolbar-button
    v-if="selected"
    v-show="selected.length != 0"
    icon="mdi-file-move"
    tooltip="Move Content"
    @click="moveItem"
    :button="{ color: 'success', disabled: selected.length < 1 }"
  ></toolbar-button>
</template>

<script>
import { PtModal } from "plant-common";
import MoveToDialog from "./MoveToDialog";
import ToolbarButton from "./ToolbarButton";
import { mapState, mapActions } from "vuex";

import ptApiClient from "plant-api-client";
import { getContentId } from "../../../../../utils/dbTool";

import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  STATE_SELECTED,
  ACTION_REFRESH
} from "@/store_constants/explorer";
export default {
  mixins: [PtModal],
  components: { ToolbarButton, MoveToDialog },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER,
      selected: STATE_SELECTED
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      refresh: ACTION_REFRESH
    }),
    moveItem() {
      this.$modal(MoveToDialog, { selected: this.selected }).then(
        destination_folder_id => {
          this.selected.forEach(s => {
            if (!destination_folder_id) return;
            let parentId = destination_folder_id;
            if (destination_folder_id == "$root") {
              parentId = undefined;
            }
            if (!s.header) {
              console.log(s);
              ptApiClient
                .updateFolder(s._id, { parent_id: parentId }, "move")
                .then(r => this.refresh())
                .catch(e => console.log(e));
            } else {
              ptApiClient
                .updateContent(getContentId(s), { folder_id: parentId })
                .then(itemMoved => {
                  this.refresh();
                })
                .catch(e => console.log(e));
            }
          });
        }
      );
    }
  }
};
</script>

<style>
</style>
