import { BlockBasic } from "../_models/models.js"

export default class BlockObjectives extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "objectives"
    this.size.width = 1760
    this.size.height = 810
    this.position.x = 80
    this.position.y = 200
    this.data = {
			// "sections" or "alphabetical"
			order: 'alphabetical',
			code: 'custom_id',
			starterNumber: 1,
			fontFamily: false,
			navigation: {
				loop: true
			},
			style: {
				transition: {
					type: 'none'
				},
				arrows: {
					normal: {
						backgroundColor: '#ffffffff',
						arrowColor: '#000000e6'
					},
					hover: {
						backgroundColor: '#cccccc',
						arrowColor: '#000000e6'
					}
				},
				paginator: {
					normal: {
						backgroundColor: '#cccccc'
					},
					hover: {
						backgroundColor: '#000000'
					},
					active: {
						backgroundColor: '#2b52ff'
					},
					visited: {
						backgroundColor: '#cc0000'
					}
				}
			}
		};
    this.protection.move = true
    this.protection.resize = true
  }
}