<template>
  <p-panel-properties-group title="hola">
    <p-panel-properties>
      <p-property-tile title="Example" icon="mdi-cat" />
    </p-panel-properties>
  </p-panel-properties-group>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../_components/utils/PPanelProperties.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"

export default {
  components: {
    PPanelPropertiesGroup,
    PPanelProperties,
    PPropertyTile
  }
}
</script>
