<template>
	<fragment>
		<p-panel-properties-group title="Objectives">
			<p-property-tile icon="mdi-reorder-horizontal" title="Order">
				<p-panel-input-select :items="choices" v-model="value.data.order" item-text="text"></p-panel-input-select>
			</p-property-tile>
			<p-property-tile icon="mdi-format-font" title="Font-family" class="lp-block-objecives-properties-item">
				<p-panel-input-select :items="blockGlobals.fontFamilies" v-model="value.data.fontFamily"></p-panel-input-select>
			</p-property-tile>
			<p-property-tile icon="mdi-xml" title="Objective Code">
				<p-panel-input-select :items="codeChoices" v-model="value.data.code" item-text="text"></p-panel-input-select>
			</p-property-tile>
			<p-property-tile v-if="isObjectiveOrderSelected" icon="mdi-numeric" title="Starter number">
				<p-panel-input-number v-model="value.data.starterNumber" :min="1" />
			</p-property-tile>
		</p-panel-properties-group>
		<p-panel-properties-group title="Arrows">
			<p class="lp-block-carousel-panel">Normal:</p>
			<style-select :value="value.data.style.arrows.normal" title="Normal" />
			<p class="lp-block-carousel-panel">Hover:</p>
			<style-select :value="value.data.style.arrows.hover" title="Hover" />
		</p-panel-properties-group>
		<p-panel-properties-group title="Navigation">
			<p-property-tile title="Loop" icon="sync">
				<v-switch v-model="value.data.navigation.loop"></v-switch>
			</p-property-tile>
		</p-panel-properties-group>
		<p-panel-properties-group title="Paginator">
			<p class="lp-block-carousel-panel">Normal:</p>
			<style-select :value="value.data.style.paginator.normal" title="Normal" />
			<p class="lp-block-carousel-panel">Hover:</p>
			<style-select :value="value.data.style.paginator.hover" title="Hover" />
			<p class="lp-block-carousel-panel">Active:</p>
			<style-select :value="value.data.style.paginator.active" title="Active" />
			<p class="lp-block-carousel-panel">Visited:</p>
			<style-select :value="value.data.style.paginator.visited" title="Visited" />
		</p-panel-properties-group>
	</fragment>
</template>
<script>
import PPanelPropertiesGroup from '../_components/utils/PPanelPropertiesGroup.vue';
import PPropertyTile from '../_components/utils/PPropertyTile.vue';
import PPanelInputSelect from '../_components/utils/PPanelInputSelect.vue';
import PPanelInputNumber from "../_components/utils/PPanelInputNumber.vue"
import StyleSelect from '../../components/slides/styles/StyleSelect.vue';
import { Fragment } from 'vue-fragment';
import Draggable from 'vuedraggable';
import ItemStyle from '../../components/slides/styles/ItemStyle.vue';

export default {
	inject: ['blockGlobals'],
	props: ['value'],
	components: {
		PPanelInputSelect,
		PPanelPropertiesGroup,
		PPropertyTile,
		PPanelInputNumber,
		StyleSelect,
		Fragment,
		Draggable,
		ItemStyle
	},
	data() {
		return {
			choices: [
				{ text: 'Alphabetical', value: 'alphabetical' },
				{ text: 'Presentation order', value: 'sections' }
			],
			codeChoices: [
				{ text: 'Custom ID', value: 'custom_id' },
				{ text: 'Title', value: 'title' },
				{ text: 'Objective order', value: 'objective_order' },
				{ text: 'Hide', value: 'hide' }
			]
		};
	},
	computed: {
		isObjectiveOrderSelected() {
			return this.value.data.code === 'objective_order';
		}
	}
};
</script>

<style>
.lp-block-carousel-panel {
	font-size: 13px;
	margin: 5px 0 0 25px;
	font-weight: 500;
}
.lp-animation-form-item .v-list__tile__action {
	flex-basis: 60%;
}
</style>
