var render = function render(){var _vm=this,_c=_vm._self._c;return _c('line-block',{attrs:{"value":_vm.value,"mode":_vm.mode,"state":_vm.state},on:{"update:state":function($event){_vm.state=$event}},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('svg',{staticClass:"shape-line",staticStyle:{"overflow":"visible","z-index":"999","vertical-align":"top"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":_vm.w,"height":_vm.h,"preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`}},[_c('defs',[(_vm.MarkerBegin)?_c(_vm.MarkerBegin.component,{tag:"component",attrs:{"id":`markerBegin-${_vm.id}`,"stroke":_vm.stroke,"markerSize":_vm.value.data.begin.marker.size,"orient":"auto-start-reverse"}}):_vm._e(),(_vm.MarkerEnd)?_c(_vm.MarkerEnd.component,{tag:"component",attrs:{"id":`markerEnd-${_vm.id}`,"stroke":_vm.stroke,"markerSize":_vm.value.data.end.marker.size,"orient":"auto"}}):_vm._e()],1),_c('path',{style:(_vm.value.data.style),attrs:{"d":`M 
          ${_vm.end.x > _vm.begin.x ? _vm.deltaBeginX : _vm.w - _vm.deltaBeginX} 
          ${_vm.end.y > _vm.begin.y ? _vm.deltaBeginY : _vm.h - _vm.deltaBeginY} 
          ${_vm.end.x > _vm.begin.x ? _vm.w - _vm.deltaEndX : _vm.deltaEndX} 
          ${_vm.end.y > _vm.begin.y ? _vm.h - _vm.deltaEndY : _vm.deltaEndY}
        `}}),_c('path',{style:({
          strokeWidth: _vm.value.data.style.strokeWidth,
          markerStart: `url(#markerBegin-${_vm.id})`,
          markerEnd: `url(#markerEnd-${_vm.id})`
        }),attrs:{"d":`M 
          ${_vm.end.x > _vm.begin.x ? 0 : _vm.w} 
          ${_vm.end.y > _vm.begin.y ? 0 : _vm.h} 
          ${_vm.end.x > _vm.begin.x ? _vm.w : 0} 
          ${_vm.end.y > _vm.begin.y ? _vm.h : 0}
        `}})])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }