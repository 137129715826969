<template>
	<div>
		<block-canvas
			v-if="slide"
			ref="canvas"
			:blocks="slide.blocks"
			:key="slide.id"
			:slide-id="slide.id"
			:defaultBlockStyles="defaultBlockStyles"
			:settingStyles="settingStyles"
			class="p-block-activity-canvas"
			:globalVariables="globalVariables"
			mode="preview"
		></block-canvas>
	</div>
</template>

<script>
import BlockCanvas from '../blocks/_components/containers/BlockCanvas.vue';
import blockDefinitions from '../blocks/index.js';
import lessonPlanController from '../controllers/LessonPlanController/index.js';

export default {
	name: 'LessonPlanPreview',
	props: ['value', 'view'],
	components: { BlockCanvas },
	data() {
		return {
			lessonPlanController: lessonPlanController(this)
		};
	},
	provide() {
		return {
			blockController: {},
			blockDefinitions: blockDefinitions,
			lessonPlanController: this.lessonPlanController,
			blockGlobals: {},
			blockEventBus: {}
		};
	},
	computed: {
		slide() {
			if (this.view && this.view.slide_id) {
				return this.lessonPlanController.slideArray.find(slide => slide.id === this.view.slide_id);
			} else {
				return this.lessonPlanController.slideArray.length > 0 ? this.lessonPlanController.slideArray[0] : false;
			}
		},
		settingStyles() {
			return this.value.body.contents.settings && this.value.body.contents.settings.styles
				? this.value.body.contents.settings.styles
				: false;
		},
		defaultBlockStyles() {
			return this.settingStyles && this.settingStyles.block ? this.settingStyles.block : {};
		},
		globalVariables() {
			this.lessonPlanController.selectedSlide = this.slide;
			return this.lessonPlanController.globalVariables;
		}
	}
};
</script>
