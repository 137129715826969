<template>
  <v-dialog v-model="dialog" width="60vw" persistent scrollable transition="dialog-bottom-transition">
    <v-card max-height="80vh">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Select content from</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!-- content type from -->
      <v-layout row wrap mt-3>
        <v-flex xs12 md6 lg5 xl3>
          <p-content-tile :value="content">
            <template v-slot:append>
              <v-icon @click="openInNewTab(content)"> mdi-open-in-new </v-icon>
            </template>
          </p-content-tile>
        </v-flex>
      </v-layout>

      <v-card-text class="dependents-dialog__table">
        <v-data-table
          :headers="headers"
          :items="dependents"
          :pagination.sync="pagination"
          select-all
          class="elevation-1">
          <template v-slot:headers="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="[
                  'column sortable',
                  pagination.descending ? 'desc' : 'asc',
                  header.value === pagination.sortBy ? 'active' : ''
                ]"
                @click="changeSort(header.value)">
                <v-icon small>arrow_upward</v-icon>
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template v-slot:items="props">
            <td>
              <v-radio-group hide-details v-model="selectedContent">
                <v-radio color="primary" :value="props.item"></v-radio>
              </v-radio-group>
            </td>
            <td class="text-center">
              <content-type-icon height="40px" :type="props.item.header.metadata.content_revision_type" />
            </td>
            <td class="text-center">
              {{ props.item.header.metadata.custom_id }}
            </td>
            <td class="text-center">
              {{ props.item.header.metadata.title }}
            </td>
            <td class="text-center">
              {{ getRevision(props.item) }}
            </td>
            <td>
              <v-icon medium @click="openInNewTab(props.item)"> mdi-open-in-new </v-icon>
            </td>
          </template>
          <template v-slot:no-data>
            <div class="text-center">
              <p class="mt-3">No dependents found</p>
            </div>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outline color="danger" @click="$emit('input', false)">close</v-btn>
        <v-btn outline color="primary" @click="selectActivity(selectedContent)" :disabled="!canEmmitSelected"
          >Select</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { PtModal, ContentTypeIcon, PContentTile } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import ptApiClient from "plant-api-client"
import { getContentId, getCustomId, getMajorVersion, getMinorVersion, getTitle } from "../../../../../utils/dbTool"

export default {
  name: "DependentsForContent",
  components: { ContentTypeIcon, PContentTile },
  props: ["content", "types"],
  mixins: [PtModal],
  data() {
    return {
      dependents: [],
      dialog: true,
      headers: [
        {},
        {
          text: "Content Revision Type",
          value: "header.metadata.content_revision_type"
        },
        { text: "Custom ID", value: "header.metadata.custom_id" },
        { text: "Title", value: "header.metadata.title" },
        { text: "Revision", value: "header.major_revision", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      pagination: {
        sortBy: "name",
        rowsPerPage: 10 // -1 for All",
      },
      selectedContent: null
    }
  },
  async created() {
    this.dependents = await ptApiClient.getDependentsByType(
      [
        {
          content_id: getContentId(this.content),
          major_revision: getMajorVersion(this.content),
          minor_revision: getMinorVersion(this.content)
        }
      ],
      this.types
    )
  },
  computed: {
    canEmmitSelected() {
      return this.selectedContent !== null
    }
  },
  beforeMount() {
    this.$options.components.ContentTypeIcon = ContentTypeIcon
  },
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle,
    selectActivity(content) {
      this.$emit("input", content)
    },
    openInNewTab(content) {
      PServiceContent.openContent(content, "tab")
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    getRevision(content) {
      return `${content.header.major_revision}.${content.header.minor_revision}`
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.dependents-dialog__table {
  height: 500px;
}
</style>
