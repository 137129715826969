<template>
  <v-menu bottom offset-y transition="slide-y-transition" :close-on-content-click="false" nudge-top="-15">
    <template v-slot:activator="{ on }">
      <toolbar-button v-on="on" icon="mdi-filter" tooltip="Filter content by type"></toolbar-button>
    </template>

    <v-card>
      <v-card-title class="filter-type-card">
        <v-autocomplete
          clearable
          v-model="filterValue"
          :items="Object.values(Extensions)"
          hide-no-data
          hide-selected
          item-text="title"
          item-value="name"
          placeholder="Content Type"
          prepend-icon=" mdi-file"
          append-icon="mdi-chevron-down"
          return-object
          solo
          flat
          @change="filterByType"></v-autocomplete>
      </v-card-title>
    </v-card>
  </v-menu>
</template>

<script>
import { Extensions } from "plant-common"
import { mapState, mapActions } from "vuex"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import {
  STORE_EXPLORER_NAME,
  STATE_FILTER_TYPE,
  ACTION_CHANGE_FILTER_TYPE,
  ACTION_SEARCH_QUERY,
  ACTION_OPEN_FOLDER,
  ACTION_CLEAN_SELECTED
} from "@/store_constants/explorer"
import ToolbarButton from "./ToolbarButton"
export default {
  name: "ContentTypeFilterIcon",
  components: { ToolbarButton },
  data() {
    return {
      Extensions,
      filterValue: null
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      filterType: STATE_FILTER_TYPE
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      changeFilterType: ACTION_CHANGE_FILTER_TYPE,
      searchQuery: ACTION_SEARCH_QUERY,
      openFolder: ACTION_OPEN_FOLDER,
      cleanSelected: ACTION_CLEAN_SELECTED
    }),
    filterByType() {
      this.changeFilterType(this.filterValue)
      if (this.filterValue) {
        if (this.$route.query.q) {
          this.searchQuery(this.$route.query.q)
        } else {
          this.searchQuery("")
        }
      } else {
        this.openFolder()
      }
    }
  },
  watch: {
    filterType: {
      immediate: true,
      deep: true,
      handler() {
        this.filterValue = this.filterType
        this.cleanSelected()
      }
    }
  }
}
</script>

<style scoped>
.filter-type-card {
  padding: 24px 16px 0px 16px;
}
</style>
