<template>
  <v-tooltip bottom offset-y>
    <v-btn icon flat slot="activator" v-bind="button"  v-on="$listeners">
      <v-icon>{{icon}}</v-icon>
      <slot/>
    </v-btn>
    <span>{{tooltip}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: ["icon", "tooltip", "button"]
};
</script>

<style>
</style>
