<template>
	<span>You've logged in.</span>
</template>

<script>
export default {
	props: ["value"],
	metadata: {
		color: 'blue',
		icon: "mdi-login",
		subscription_code: "user-login"
	},
}
</script>
