<template>
  <div class="content-fit">
    <tree :data="treeGuideData" zoomable @clickedNode="openItem" style="height: 100%;">
      <template #node="{data, node: {depth}, radius, isRetracted}">
        <template v-if="data.type =='cover'">
          <path
            transform="translate(-5,-13)"
            d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
            fill="#626262"
          ></path>
        </template>
        <template v-if="data.type =='section'">
          <text
            x="10"
            y="20"
            style="font-family: Times New Roman;
                      font-size  : 24;
                      stroke     : #00ff00;
                      fill       : #0000ff;"
          >Section</text>
          <text
            transform="translate(-15, 0)"
            x="10"
            y="20"
            style="font-size  : 12;                   
                   fill       : #000000;"
          >{{data.fields ? data.fields.title : ''}}</text>
          <path
            transform="translate(-2,-13)"
            :d="isRetracted ? 'M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z' : 'M19 20H4a2 2 0 0 1-2-2V6c0-1.11.89-2 2-2h6l2 2h7a2 2 0 0 1 2 2H4v10l2.14-8h17.07l-2.28 8.5c-.23.87-1.01 1.5-1.93 1.5z'"
            :fill="isRetracted ? '#626262' : '#1976d2'"
          >
            <title>{{data.fields ? data.fields.title : 'Section'}}</title>
          </path>
        </template>
        <template v-if="data.type =='card'">
          <text
            x="10"
            y="20"
            style="font-family: Times New Roman;
                      font-size  : 24;
                      stroke     : #00ff00;
                      fill       : #0000ff;"
          >Card</text>
          <text
            transform="translate(5, -15)"
            x="10"
            y="20"
            style="font-size  : 12;                   
                   fill       : #000000;"
          >{{(data.content && data.content.header) ? getTitle(data.content) : ''}}</text>
          <circle r="6" stroke="yellow" fill="yellow">
            <title>{{data.content && data.content.header ? getTitle(data.content) : 'Card'}}</title>
          </circle>
        </template>
      </template>
    </tree>
  </div>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
import { tree } from "vued3tree";
import { getTitle } from '../../../../utils/dbTool';

export default {
  name: "GuideViewerMindmap",
  inject: ["guideController"],
  components: { tree },
  data() {
    return {};
  },
  computed: {
    ...mapInjectedData("guideController", ["guide", "content"]),
    treeGuideData() {
      return {
        ...this.guide.cover,
        children: this.guide.sections
      };
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["getTemplate", "selectCard"]),
    getTitle: getTitle,
    openItem(element) {
      if (element.target.nodeName === "text") {
        this.selectCard(element.data);
      }
    }
  }
};
</script>

<style scoped>
.content-fit {
  height: calc(100vh - 120px);
}
</style>