<template>
  <v-list-tile
    avatar
    class="lp-layout-detail"
    :class="{ active: isActive }"
    @click="selectLayoutById(layout.id, imported)"
  >
    <v-btn
      flat
      type="button"
      class="lp-layout-detail-content"
      @click="handleClick(layout)"
    >
      <v-list-tile-content
        :class="{ 'lp-layout-detail-content-edit-mode': isEditMode }"
      >
        <!-- text -->
        <v-list-tile-title>
          <v-text-field
            v-if="isEditMode"
            ref="renameTitle"
            outline
            class="mt-0 pl-3 pr-3"
            solo
            v-model="newTitle"
            @blur="onEdit(layout)"
            @input="onLiveEdit(layout)"
            @keydown.enter="onEdit(layout)"
          ></v-text-field>

          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ layout.name }}</span>
            </template>
            <span>{{ layout.name }}</span>
          </v-tooltip>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-btn>

    <slot></slot>
  </v-list-tile>
</template>

<script>
export default {
  inject: ["lessonPlanController"],
  props: ["layout", "isActive", "isEditMode", "imported"],
  data() {
    return {
      newTitle: this.layout.name,
      clicks: 0,
      delay: 300,
      timer: null
    };
  },
  watch: {
    isEditMode() {
      if (this.isEditMode && this.$refs.renameTitle) {
        this.$nextTick(() => {
          this.$refs.renameTitle.focus();
        });
      }
    },
    "layout.name"() {
      this.newTitle = this.layout.name;
    }
  },
  methods: {
    handleClick(layout) {
      this.clicks++;
      if (this.clicks === 1) {
        this.timer = setTimeout(() => {
          // click
          this.clicks = 0;
        }, this.delay);
      } else {
        // double click
        clearTimeout(this.timer);
        this.lessonPlanController.setEditTitleMode();
        this.clicks = 0;
      }
    },
    onEdit(layout) {
      this.changeTitleLayout(layout);
      this.lessonPlanController.editTitleMode = false;
    },
    onLiveEdit(layout) {
      this.changeTitleLayout(layout);
    },
    changeTitleLayout(layout) {
      layout.name = this.newTitle;
    },
    selectLayoutById(layoutId, imported) {
      this.lessonPlanController.selectLayoutById(layoutId, imported);
      this.$emit("slideChanged");
    }
  }
};
</script>

<style>
/* Content all space and slot only the minimum required */
.lp-layout-detail > a {
  display: flex;
  justify-content: space-between;
}
.lp-layout-detail-content.v-btn {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}
.lp-layout-detail.active {
  background-color: #545353;
}
.lp-layout-detail .v-list__tile__sub-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}
.lp-layout-detail .v-list__tile__title {
  font-size: 14px;
  font-weight: 400;
}
.lp-layout-detail .v-list__tile__action {
  min-width: auto;
}
.lp-layout-detail .v-list__tile--avatar {
  height: 40px;
}

/* Not capitalize all content inside button */
.lp-layout-detail button {
  text-transform: none;
}

/* Same behavior as v-treeview */
.lp-layout-detail
  .v-btn:not(.v-btn--disabled):not(.v-btn--floating):not(.v-btn--icon)
  .v-btn__content
  .v-icon {
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.lp-layout-detail .v-icon.lp-arrow {
  transform: none;
}
.lp-layout-detail .v-icon.lp-arrow.lp-arrow-open {
  transform: rotate(90deg);
}
.lp-layout-detail-content.v-btn:hover:before {
  background-color: transparent !important;
}

/* Edit title */
.lp-layout-detail .v-list__tile__content.lp-layout-detail-content-edit-mode,
.lp-layout-detail .lp-layout-detail-content-edit-mode .v-list__tile__title {
  min-height: 46px;
}
.lp-layout-detail .v-text-field--outline > .v-input__control > .v-input__slot,
.lp-layout-detail .v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px;
  max-height: 30px;
}
.lp-layout-detail .v-input__slot {
  margin-top: 7px;
}
.lp-layout-detail .v-text-field--box.v-text-field--single-line input,
.lp-layout-detail .v-text-field--full-width.v-text-field--single-line input,
.lp-layout-detail .v-text-field--outline.v-text-field--single-line input {
  margin-top: -1px;
}
.lp-layout-detail
  .v-text-field.v-text-field--enclosed
  > .v-input__control
  > .v-input__slot {
  padding: 0 10px;
}
.lp-layout-detail .v-text-field.v-text-field--enclosed {
  margin-left: -15px;
}

/* Max width title */
.lp-layout-detail .v-list__tile__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}
</style>