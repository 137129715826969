var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('block-toolbar-button',_vm._g({attrs:{"icon":_vm.icon,"tooltip":"Update to last revision","dark":_vm.dark,"disabled":_vm.disabled}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('toolbar-dialog-card',{attrs:{"title":"Update to last revision"},on:{"closeMenu":function($event){_vm.menu = false}}},[(_vm.currentRevision == _vm.latestRevision || !_vm.latestRevision)?_c('p',[_vm._v(" The content shown is the latest revision "),_c('b',[_vm._v(_vm._s(_vm.currentRevision))]),_vm._v(". ")]):_c('div',[_c('p',[_vm._v(" The content shown is revision "),_c('b',[_vm._v(_vm._s(_vm.currentRevision))]),(
            _vm.value.data &&
            _vm.value.data.content_ref &&
            _vm.value.data.content_ref.header &&
            _vm.value.data.content_ref.header.metadata &&
            _vm.value.data.content_ref.header.metadata.custom_id
          )?_c('v-btn',{staticClass:"lp-toolbar-library-revision-open",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openCurrentVersion()}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e(),_vm._v(", but there is a newer revision available "),_c('b',[_vm._v(_vm._s(_vm.latestRevision))]),(_vm.value.data && _vm.value.data.content_ref)?_c('v-btn',{staticClass:"lp-toolbar-library-revision-open",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openNewVersion()}}},[_c('v-icon',[_vm._v("mdi-open-in-new")])],1):_vm._e()],1),_c('div',{staticClass:"lp-toolbar-library-revision-button"},[_c('v-btn',{on:{"click":function($event){return _vm.updateSlideLastestRevision()}}},[_vm._v("Update to latest revision")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }