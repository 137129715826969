import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import Component from './FormulaComponent.vue'

export const FormulaClass = Node.create({
    name: 'formulaComponent',

    group: 'block',

    addAttributes() {
        return {
            content: {
                default: '',
                renderHTML: attributes => {
                    return {
                        content: attributes.content
                    }
                }
            }
        }
    },

    parseHTML() {
        return [
            {
                tag: 'katex',
            },
        ]
    },

    renderHTML({ HTMLAttributes }) {
        return ['katex', mergeAttributes(HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(Component)
    },

    addCommands() {
        return {
            addKatex: (attrs) => ({ state, dispatch }) => {
                const { selection } = state
                const position = selection.$cursor ? selection.$cursor.pos : selection.$to.pos
                const node = this.type.create(attrs)
                const transaction = state.tr.insert(position, node);

                dispatch(transaction);
            }
        }
    }
})