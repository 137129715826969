/**
 * Opens a component as a modal
 */
import Vue from "vue"
export default function (Component, propsData) {
    return new Promise((resolve, reject) => {
        var ModalInstance = Vue.extend(Component);
        var modal = new ModalInstance({
            propsData
        });
        modal.$on("input", r => {
            modal.$destroy(true);
            modal.$el.remove();
            resolve(r);
        });
        modal.$mount();
    });
}