<template>
  <v-app>
    <div class="bg" v-if="loading">
      <animated-logo />
      <v-progress-linear :indeterminate="true"></v-progress-linear>
    </div>
    <content-viewer
      v-else
      :id="content_id"
      :major_revision="major_revision"
      :minor_revision="minor_revision"
      :view.sync="view"
      mode="present" />
  </v-app>
</template>

<script>
import AnimatedLogo from "@/components/AnimatedLogo.vue"
import { mapState, mapMutations } from "vuex"
import ContentViewer from "../content/ContentViewer/index.vue"
import content_store from "@/store/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS, MUTATION_SET_PLANT_SETTINGS } from "@/store_constants/library"

import devtools from "@vue/devtools"

import ptCoreClient from "plant-core-client"
import ptApiClient from "plant-api-client"
import { STORE_CONTENT_NAME } from "@/store_constants/content"
import { STORE_USER_NAME, STATE_TOKEN } from "@/store_constants/user"

export default {
  props: {
    library_id: String,
    content_id: String,
    major_revision: String,
    minor_revision: String
  },
  beforeCreate() {
    if (this.$store.registerModule(STORE_CONTENT_NAME, content_store) === false) {
      this.$store.unregisterModule(STORE_CONTENT_NAME)
      this.$store.registerModule(STORE_CONTENT_NAME, content_store)
    }
  },
  components: {
    AnimatedLogo,
    ContentViewer
  },
  data: () => ({
    loading: true,
    view: false
  }),
  computed: {
    ...mapState(STORE_LIBRARY_NAME, { settings: STATE_PLANT_SETTINGS }),
    ...mapState(STORE_USER_NAME, { token: STATE_TOKEN })
  },
  methods: {
    ...mapMutations(STORE_LIBRARY_NAME, {
      setSettings: MUTATION_SET_PLANT_SETTINGS
    })
  },
  created() {
    let connectionIdx = 0
    let messageIdx = 0
    let vm = this
    if (process.env.NODE_ENV === "development") {
      devtools.connect(/* host, port */)
    }

    function addConnection(connection) {
      connection.connectionId = ++connectionIdx
      log("New connection #" + connectionIdx)
      connection.send(JSON.stringify({ type: "ready" }))
      connection.addEventListener("message", function (event) {
        messageIdx++
        log(`Message ${messageIdx}  from connection #${connection.connectionId}: ${event.data} `)
        connection.send("Received message " + messageIdx)
        try {
          const data = JSON.parse(event.data)
          if (data.type == "state") {
            const state = JSON.parse(data.message.state)
            // Replace state and set tokens
            vm.$store.replaceState(Object.assign(vm.$store.state, state))
            ptApiClient.token = vm.$store.state.user.token
            ptCoreClient.token = vm.$store.state.user.token
            ptApiClient.baseURL = vm.$store.state[STORE_LIBRARY_NAME].settings.url
            vm.loading = false
          } else if ((data.type = "view")) {
            vm.view = data.message
          }
        } catch (e) {
          console.error(e)
        }
      })

      connection.addEventListener("close", function (event) {
        log(`Connection #${connection.connectionId} closed, reason = ${event.reason} , message = ${event.message}`)
      })
    }

    function log(msg) {
      console.log(msg)
    }

    document.addEventListener("DOMContentLoaded", function () {
      if (navigator.presentation.receiver) {
        navigator.presentation.receiver.connectionList.then(list => {
          list.connections.map(connection => addConnection(connection))
          list.addEventListener("connectionavailable", function (event) {
            addConnection(event.connection)
          })
        })
      }
    })
  }
}
</script>

