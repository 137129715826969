import ptCoreClient from "plant-core-client";
import {
    STORE_EVENT_NOTIFICATIONS_NAME,
    STATE_EVENT_NOTIFICATIONS_LIST,
    GETTER_EVENT_UNREAD_NOTIFICATIONS,
    MUTATION_EVENT_NOTIFICATIONS_SET,
    MUTATION_EVENT_NOTIFICATIONS_REMOVE,
    ACTION_EVENT_NOTIFICATIONS_FETCH,
    ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ,
    ACTION_EVENT_NOTIFICATIONS_MARK_ALL_AS_READ
} from "@/store_constants/event-notifications"

export default {
    namespaced: true,
    name: STORE_EVENT_NOTIFICATIONS_NAME,
    state: {
        [STATE_EVENT_NOTIFICATIONS_LIST]: undefined
    },
    getters: {
        [GETTER_EVENT_UNREAD_NOTIFICATIONS](state, getters) {
            if (state[STATE_EVENT_NOTIFICATIONS_LIST]) {
                return state[STATE_EVENT_NOTIFICATIONS_LIST].filter(notification => !notification.read);
            } else {
                return undefined
            }
        },
    },
    mutations: {
        [MUTATION_EVENT_NOTIFICATIONS_SET](state, list) {
            state[STATE_EVENT_NOTIFICATIONS_LIST] = list
        },
        [MUTATION_EVENT_NOTIFICATIONS_REMOVE](state, index) {
            state[STATE_EVENT_NOTIFICATIONS_LIST].splice(index, 1)
        },
    },
    actions: {
        [ACTION_EVENT_NOTIFICATIONS_FETCH]({ commit }, read) {
            ptCoreClient.getAllNotifications(read)
                .then(res => {
                    commit(MUTATION_EVENT_NOTIFICATIONS_SET, res.docs);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ]({ commit }, { notificationId, read }) {
            ptCoreClient.markAsRead(notificationId, read)
                .then(res => {
                    commit(MUTATION_EVENT_NOTIFICATIONS_SET, res.docs);
                    return res;
                }).catch(e => { return e; })
        },
        [ACTION_EVENT_NOTIFICATIONS_MARK_ALL_AS_READ]({ commit }, read) {
            ptCoreClient.markAllAsRead(read)
                .then(res => {
                    commit(MUTATION_EVENT_NOTIFICATIONS_SET, res.docs);
                    return res;
                }).catch(e => { return e; })
        }
    }

}