<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title>Block Gallery</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon @click="saveBlockInGallery" :disabled="!selectedBlock">
            <v-icon>mdi-toy-brick-plus-outline</v-icon>
          </v-btn>
        </template>
        Save selected block in gallery
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon @click="addGallery" v-on="on">
            <v-icon>mdi-folder-plus-outline</v-icon>
          </v-btn>
        </template>
        Add a new Presentation Gallery
      </v-tooltip>
    </v-toolbar>
    <v-list>
      <template v-for="(group, index) in lessonPlanController.galleryGroups">
        <v-subheader :key="index" @click="toggleCollapsed(group.title)" class="lp-gallery-panel-collapsed">
          <v-icon small left> mdi-chevron-double-{{ collapsed.includes(group.title) ? "up" : "down" }} </v-icon>
          {{ group.title }}
        </v-subheader>

        <v-list-group
          v-show="!collapsed.includes(group.title)"
          v-for="gallery in group.galleries"
          :key="getGalleryKey(gallery)"
          :value="collapsedGalleries.includes(getGalleryKey(gallery))"
          @input="toggleCollapsedGallery(getGalleryKey(gallery))"
          @contextmenu="
            evt => (group.application ? evt.preventDefault() : showContextMenu(evt, 'gallery', { group, gallery }))
          "
          :prepend-icon="collapsedGalleries.includes(getGalleryKey(gallery)) ? 'mdi-folder-open' : 'mdi-folder-outline'"
          no-action>
          <template v-slot:activator>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title class="lp-gallery-panel-stencil-group">
                  {{ gallery.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </template>

          <!-- A empty @click is needed for the dragstart to fire. I do not know why 🙈  -->
          <v-list-tile
            v-for="stencil in gallery.stencils"
            :key="stencil.id"
            draggable
            @dragstart="evt => dragging(evt, stencil.id)"
            @contextmenu="
              evt =>
                group.application ? evt.preventDefault() : showContextMenu(evt, 'block', { group, gallery, stencil })
            "
            @dblclick="evt => addInCenterSlide(evt, stencil.id)"
            @click="() => {}">
            <v-list-tile-action>
              <v-icon>{{ stencil.icon || "mdi-toy-brick-outline" }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title :contenteditable="editBlockId === stencil.id" class="lp-gallery-panel-stencil-name">
                {{ stencil.title || stencil.name }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
      </template>
    </v-list>
  </div>
</template>

<script>
import ContextMenu from "./ContextMenu.vue"
import RenameDialog from "./RenameDialog.vue"
import componentModal from "../../../../utils/componentModal.js"
import DialogSaveBlockInGallery from "../slides/properties/DialogSaveBlockInGallery.vue"
import * as models from "../../models/models.js"
import { Stencil, Gallery } from "../../models/models.js"
import _ from "lodash"

export default {
  inject: ["lessonPlanController", "blockController", "blockDefinitions"],
  data() {
    return {
      collapsed: [],
      collapsedGalleries: [],
      editBlockId: false,
      selectedBlock: false
    }
  },
  created() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block }) => {
        this.selectedBlock = block
      })
    }
  },
  computed: {
    lessonPlanGalleries() {
      return this.lessonPlanController.lessonPlanContents.galleries
    }
  },
  methods: {
    addInCenterSlide(evt, stencilId) {
      evt.preventDefault()
      const newBlock = this.getNewBlock(stencilId)

      if (newBlock !== null) {
        if (!this.lessonPlanController.selectedSlide) {
          // There is no slides in current Interactive Presentation
          const slide = new models.Slide()
          this.lessonPlanController.slidesTree.slides.push(slide)
          this.lessonPlanController.selectedSlide = slide
        }
        // update newBlock position after adding it to slide
        if (newBlock.data.hasOwnProperty("begin") && newBlock.data.hasOwnProperty("end")) {
          newBlock.data.begin.x = newBlock.position.x
          newBlock.data.begin.y = newBlock.position.y
          newBlock.data.end.x = newBlock.position.x + newBlock.size.width
          newBlock.data.end.y = newBlock.position.y + newBlock.size.height
        }
        this.lessonPlanController.selectedSlide.blocks.push(newBlock)
      }
    },
    getNewBlock(stencilId) {
      let newBlock = null
      let stencil = Object.values(this.blockDefinitions).find(blockDefinition => blockDefinition.id === stencilId)

      if (stencil) {
        newBlock = new stencil.class()
        newBlock.position.x = 1920 / 2 - newBlock.size.width / 2
        newBlock.position.y = 1080 / 2 - newBlock.size.height / 2
      }

      return newBlock
    },
    async showContextMenu(evt, type, { group, gallery, stencil }) {
      evt.preventDefault()
      const res = await componentModal(ContextMenu, {
        x: evt.clientX,
        y: evt.clientY,
        actions: [
          {
            name: "rename",
            icon: "mdi-pencil",
            title: "Rename"
          },
          {
            name: "delete",
            icon: "mdi-delete-outline",
            title: "Delete"
          }
        ]
      })
      if (!res) return
      switch (type) {
        case "gallery":
          if (res === "delete") {
            const galleryIndex = this.lessonPlanGalleries.findIndex(g => g.id === gallery.id)
            this.lessonPlanGalleries.splice(galleryIndex, 1)
          } else if (res === "rename") {
            const newName = await componentModal(RenameDialog, {
              dialogTitle: "Rename Gallery",
              name: gallery.name
            })
            if (newName) gallery.name = newName
          }
          break
        case "block":
          if (res === "delete") {
            const galleryIndex = this.lessonPlanGalleries.findIndex(g => g.id === gallery.id)
            const blockIndex = this.lessonPlanGalleries[galleryIndex].stencils.findIndex(b => b.id === stencil.id)
            this.lessonPlanGalleries[galleryIndex].stencils.splice(blockIndex, 1)
          } else if (res === "rename") {
            const newName = await componentModal(RenameDialog, {
              dialogTitle: "Rename Block",
              name: stencil.name
            })
            if (newName) stencil.name = newName
          }
          break
      }
    },
    addGallery() {
      this.lessonPlanGalleries.push(new Gallery())
    },
    dragging(evt, stencilId) {
      evt.dataTransfer.setData("stencilId", stencilId)
    },
    toggleCollapsed(id) {
      const collapsedIndex = this.collapsed.findIndex(c => c === id)
      if (collapsedIndex > -1) {
        this.collapsed.splice(collapsedIndex, 1)
      } else {
        this.collapsed.push(id)
      }
    },
    toggleCollapsedGallery(key) {
      const collapsedIndex = this.collapsedGalleries.findIndex(c => c === key)
      if (collapsedIndex > -1) {
        this.collapsedGalleries.splice(collapsedIndex, 1)
      } else {
        this.collapsedGalleries.push(key)
      }
    },

    async saveBlockInGallery() {
      const res = await componentModal(DialogSaveBlockInGallery, {
        galleries: this.lessonPlanController.lessonPlanContents.galleries.filter(gallery => gallery.imported === false)
      })
      if (res) {
        const { gallery_id, name, icon } = res
        const newGalleryBlock = new Stencil(name, this.selectedBlock)
        const gallery = this.lessonPlanController.lessonPlanContents.galleries.find(g => g.id === gallery_id)
        newGalleryBlock.icon = icon
        gallery.stencils.push(newGalleryBlock)
      }
    },
    getGalleryKey(gallery) {
      return gallery.imported ? `${gallery.imported}-${gallery.id}` : gallery.id
    }
  }
}
</script>

<style>
.lp-gallery-panel-stencil-group {
  font-size: 15px;
}
.lp-gallery-panel-stencil-name {
  font-size: 14px;
}
.lp-gallery-panel-collapsed {
  cursor: pointer;
}
</style>
