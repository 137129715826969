<template>
  <v-toolbar-title v-if="itemRevision">
    <span class="caption">
      <v-icon small>mdi-library-shelves</v-icon>
      {{ settings.name }}
      <v-chip small label dark>
        <v-icon small>mdi-tag</v-icon>
        {{ getCustomId(itemRevision) }}
      </v-chip>
    </span>
    <br />
    {{ getTitle(itemRevision) }}
    <title-bar-revision />
  </v-toolbar-title>
</template>

<script>
import { mapState, mapActions } from "vuex"
import TitleBarRevision from "./TitleBarRevision"
import { STORE_CONTENT_NAME, STATE_ITEM_REVISION } from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { getCustomId, getTitle } from "../../../utils/dbTool"

export default {
  components: { TitleBarRevision },
  computed: {
    ...mapState(STORE_CONTENT_NAME, { itemRevision: STATE_ITEM_REVISION }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle
  }
}
</script>
