<template>
  <toolbar-button
    v-if="selected"
    v-show="selected.length != 0"
    icon="mdi-folder-remove"
    tooltip="Delete folder"
    color="error"
    @click="deleteFolderModal"
    :button="{ disabled: selected.length < 1 }"
  ></toolbar-button>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PtModal } from "plant-common";
import ToolbarButton from "./ToolbarButton";
import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  STATE_SELECTED,
  ACTION_DELETE_ITEM,
  ACTION_REFRESH
} from "@/store_constants/explorer";
export default {
  mixins: [PtModal],
  components: { ToolbarButton },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER,
      selected: STATE_SELECTED
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      deleteItem: ACTION_DELETE_ITEM,
      refresh: ACTION_REFRESH
    }),
    deleteFolderModal() {
      var parent_id = this.folder ? this.folder.id : false;
      this.$modal("confirm", {
        title: `Are you sure you want to delete ${
          this.selected.length
        } selected folder${this.selected.length > 1 ? "s" : ""}?`,
        accept: {
          color: "error",
          text: "Delete",
          icon: "mdi-delete"
        },
        cancel: { text: "cancel", color: "success" }
      }).then(r => {
        if (!r) return;
        var results = [];
        let promises = [];
        this.selected.map(s => {
          promises.push(
            this.deleteItem(s)
              .then(r => {
                results.push({ item: s, response: r });
              })
              .catch(e => {
                console.error(e);
              })
          );
        });
        Promise.all(promises)
          .then(() => {
            if (promises.length != results.length) {
              this.$modal("info", { title: "Not all items were deleted." });
            }
            this.refresh();
          })
          .catch(e => {
            console.error(e);
          });
      });
    }
  }
};
</script>

<style>
</style>
