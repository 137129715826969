<template>
  <span
    >Failed when building SCORM
    <b>{{ custom_id }}</b>
  </span>
</template>

<script>
export default {
  props: ["value"],
  metadata: {
    color: "red",
    icon: "mdi-file-alert-outline",
    subscription_code: "scorm-failed"
  },
  computed: {
    custom_id() {
      return this.value.event.payload && this.value.event.payload ? `${this.value.event.payload.custom_id}` : ""
    }
  }
}
</script>
