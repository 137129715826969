import "./pt-flowchart-block.css"

export default {
  model: {
    "active": {
      type:"boolean",
      default: false
    }
  },
  listeners: {
    mouseenter: true,
    mouseleave: true,
    click: true,
    contextmenu: true
  },
  options: {
    hasNotes: true,
    canLink: true
  }
}
