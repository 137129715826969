import { $http, utils, WorkflowSettings } from '../config';
import { injectTokenTo401Files } from '../config/utils';
import PtApi from "../main";
import { Drafts } from "./index";

const Content = {
	getContentByContentId: (contentId) => $http(`/content/${encodeURIComponent(contentId)}`)
		.then(res => res.data.docs[0]),
	// Review getAllRevisionsByContentId functionality, is is returning only the first itemRevision
	getAllRevisionsByContentId: (contentId) => $http(`/content/${contentId}/revisions`)
		.then(res => res.data.revisions.reverse()),
	/**
	 * @param contentId {string}
	 * @returns {object}
	 */
	deleteContent: (contentId) => $http.delete(`/content/${contentId}`).then(r => r.data),
	/**
	 * @param contentId {string}
	 * @returns {object}
	 */
	hardDeleteContent: (contentId) => $http.delete(`/content/${contentId}/force`).then(r => r.data),
	/**
	 * @param title {string}
	 * @param custom_id {string}
	 * @param folder_id {string}
	 * @param content_revision_type {string}
	 * @returns {PLANT.DraftResponse}
	   */
	createContent: (title, custom_id, folder_id, content_revision_type) => $http.post(`/content`, { title, custom_id, folder_id, content_revision_type }).then(async r => {
		// update workflow -> one_reviewer
		let workflowType = (WorkflowSettings && WorkflowSettings.default && WorkflowSettings.default.type) ? WorkflowSettings.default.type : "direct_approval";
		await Drafts.updateDraftWorkflow(r.data._id, "method", "changeWorkflow", { name: workflowType, lightweight: true })
		// update default roles
		async function assignDefaultRoles() {
			let arrayRoles = Object.entries(WorkflowSettings.default.roles)
			let authorId = null
			let res = null
			for (let j = 0; j < arrayRoles.length; j++) {
				authorId = (arrayRoles[j][1] == "@me") ? utils.getIdFromToken(PtApi.token) : arrayRoles[j][1]
				res = await Drafts.updateDraftWorkflow(r.data._id, "method", "reassignRole", { role: arrayRoles[j][0], user_id: authorId })
			}
			return res
		}
		let res = await assignDefaultRoles()
		return {
			item: res.item,
			itemRevisionDraft: res
		}
	}),
	/**
	 * @returns {PLANT.ContentList}
	 */
	getContent: () => $http(`/content`).then(r => r.data),
	/**
	 * @param contentId {string} 
	 * @returns {PLANT.ContentRevision}
	 */
	getContentById: (contentId) => $http(`/content/${contentId}`)
		.then(res => res.data)
		.then(data => data.docs[0]),
	/**
	 * @param type {string} 
	 * @param text {string} 
	 * @returns {PLANT.ContentList}
	 */
	getContentWithTypeOrText: (type, text, hotspot) => $http(`/content?type=` + type + (text ? '&text=' + `${text}` : '') + (hotspot ? '&hotspot=' + `true` : '')).then(async r => {
		r.data.docs.forEach(revision => {
			if (revision.header.metadata.library_id) {
				revision.$origin = `plant://${revision.header.metadata.library_id}/content/${revision.header.metadata.content_id}/${revision.header.major_revision}/${revision.header.minor_revision}`;
			} else {
				revision.$origin = `plant:/content/${revision.header.metadata.content_id}/${revision.header.major_revision}/${revision.header.minor_revision}`;
			}
		});
		await injectTokenTo401Files(r.data)
		return r.data
	}),
	/**
	 * @param contentId {string} 
	 * @param majorRev {number} 
	 * @param minorRev {number} 
	 * @returns {PLANT.ContentRevision}
	 */
	getRevisionByContentId: (contentId, majorRev, minorRev) => $http(`/content/${contentId}/${majorRev}/${minorRev ? minorRev : ''}?mode=view`)
		.then(res => res.data),
	updateContent: (contentId, { name, folder_id, title, description, metadata }) => $http.put(`/content/${contentId}`, { name, folder_id, title, description, metadata }).then(r => r.data),
	searchContent: (query, content_revision_type, folder_id) => $http.post(`/content/search`, { query, content_revision_type, folder_id }).then(r => r.data).then(contents => {
		contents.docs.forEach(content => {
			if (content.header.metadata.library_id) {
				content.$origin = `plant://${content.header.metadata.library_id}/content/${content.header.metadata.content_id}/${content.header.major_revision}/${content.header.minor_revision}`;
			} else {
				content.$origin = `plant:/content/${content.header.metadata.content_id}/${content.header.major_revision}/${content.header.minor_revision}`;
			}
		});
		return contents
	}),
	/**
	 * @param {string} custom_id 
	 * @param {boolean} return_draft 
	 * @returns {PLANT.ContentRevision}
	 */
	getContentByCustomId: function (custom_id, return_draft = false) {
		return $http.post(`/content/search/custom_id`, { custom_id, return_draft })
			.then(r => r.data)
			.then(content => {
				if (content) {
					if (content.header.metadata.library_id) {
						content.$origin = `plant://${content.header.metadata.library_id}/content/${content.header.metadata.content_id}/${content.header.major_revision}/${content.header.minor_revision}`;
					} else {
						content.$origin = `plant:/content/${content.header.metadata.content_id}/${content.header.major_revision}/${content.header.minor_revision}`;
					}
					return content
				} else {
					throw new Error(`No content found for ${custom_id}`);
				}
			})
	},
	/**
	 * @param contentId {string} 
	 * @param majorRev {number} 
	 * @param minorRev {number} 
	 * @returns {String[]}
	 */
	getContentDependents: function (contentId, majorRev, minorRev) {
		return $http(`/content/${contentId}/${majorRev}/${minorRev}/dependents`)
			.then(res => res.data)
	},
	getScreenshot: function (url, localStorageState) {
		return $http.post(`/screenshot`, { url, localStorageState }, { responseType: 'blob' })
			.then(r => r.data)
			.catch(e => {
				return false
			})
	},

	getContentPreviewUrl: function (content_id, major_revision, minor_revision, size, view, seconds) {
		let viewJsonStr = JSON.stringify(view);
		let viewJsonEncode = encodeURI(viewJsonStr);
		const sizeAvailables = ['small', 'medium', 'large'];
		if (!size || !sizeAvailables.includes(size)) {
			size = "default"
		}
		return `${PtApi.baseURL}/preview/${content_id}/${major_revision}/${minor_revision}/${size}?view=${viewJsonEncode}${seconds ? "&seconds=" + seconds : ""}`
	},

	deleteContentPreview: function (content_id, major_revision, minor_revision, size, view) {
		let viewJsonStr = JSON.stringify(view);
		let viewJsonEncode = encodeURI(viewJsonStr);
		const sizeAvailables = ['small', 'medium', 'large'];
		if (!size || !sizeAvailables.includes(size)) {
			size = "default"
		}
		return $http.delete(`/preview/${content_id}/${major_revision}/${minor_revision}/${size}?view=${viewJsonEncode}`)
			.then(r => true)
			.catch(e => false)
	},

	createLessonPreview: function (content_id, major_revision, minor_revision) {
		return $http(`/preview/lesson/${content_id}/${major_revision}/${minor_revision}`)
	},

	checkSlidePreview: function (content_id, major_revision, minor_revision, view) {
		let viewJsonStr = JSON.stringify(view);
		let viewJsonEncode = encodeURI(viewJsonStr);
		return `${PtApi.baseURL}/preview/checkSlide/${content_id}/${major_revision}/${minor_revision}/${viewJsonEncode}`
	},

	getLatestVersions: function (customIds) {
		return $http.post(`/content/search/latest`, { custom_ids: customIds })
			.then(r => r.data)
			.then(revisions => {
				if (revisions) {
					var revisionsOpt = {}
					revisions.forEach(revision => {
						revisionsOpt[revision.custom_id] = revision.latest
					});
					return revisionsOpt
				} else {
					throw new Error('No revisions found');
				}
			})
	},

	getContentByType: function (type) {
		return $http(`/content/type/${type}`).then(res => res.data)
	},

	buildScorm: function (contentId, majorRev, minorRev, version) {
		return $http(`/content/${contentId}/${majorRev}/${minorRev}/download/scorm${version ? "?version=" + version : ""}`).then(res => res.data)
	},

	deleteScorm: function (contentId, majorRev, minorRev, version) {
		return $http.delete(`/content/${contentId}/${majorRev}/${minorRev}/download${version ? "?version=" + version : ""}`).then(res => res.data)
	},

	downloadScorm: function (contentId, majorRev, minorRev, version) {
		return `${PtApi.baseURL}/content/${contentId}/${majorRev}/${minorRev}/download/url${version ? "?version=" + version : ""}`
	},
	getScormUnpackUrl: function (contentId, majorRev, minorRev) {
		return `${PtApi.baseURL}/content/${contentId}/${majorRev}/${minorRev}/download/scorm-unpack`
	},
	/**
	 * @param {string} from
	 * @param {string} custom_id
	 * @param {string} title
	 * @param {string} folder_id
	 * @param {string} content_revision_type
	 * @returns {PLANT.Content}
	 */
	createDraftFromApprovedRevision(from, custom_id, title, folder_id, content_revision_type) {
		return $http.post(`/content`, { from, custom_id, title, folder_id, content_revision_type })
			.then(r => r.data)
	},
	forceUpdateCustomId: (old_custom_id, new_custom_id) => $http.put(`/content/custom_id`, { old_custom_id, new_custom_id }).then(r => r.data),
	getDependentsByType: function (objectives, types) {
		return $http.post(`/content/dependents`, { contents: objectives, types: types }).then(r => {
			const dependents = r.data
			dependents.forEach(dependent => {
				if (dependent.header.metadata.library_id) {
					dependent.$origin = `plant://${dependent.header.metadata.library_id}/content/${dependent.header.metadata.content_id}/${dependent.header.major_revision}/${dependent.header.minor_revision}`;
				} else {
					dependent.$origin = `plant:/content/${dependent.header.metadata.content_id}/${dependent.header.major_revision}/${dependent.header.minor_revision}`;
				}
			});
			return dependents
		})
	},
};

export default Content;
