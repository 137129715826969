<template>
  <v-layout class="h-100">
    <library-selector name="Explore Library" />

    <v-container
      :style="`margin-left:230px;margin-right:0px;margin-bottom:0px;padding:0px;height:100%;max-width: calc(100% - 230px) !important;`">
      <library-selector-card name="Explore Library" v-if="!plant_id || !library_id" />
      <router-view />
    </v-container>
  </v-layout>
</template>
<script>
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "@/store_constants/library"
import { mapState, mapActions } from "vuex"
import LibrarySelector from "@/components/LibrarySelector.vue"
import LibrarySelectorCard from "@/components/LibrarySelectorCard.vue"
import changeDocumentTitleAndIcon from "@/utils/changeDocumentTitleAndIcon.js"

export default {
  name: "Explorer",
  components: { LibrarySelector, LibrarySelectorCard },
  data() {
    return {
      favicon: process.env.BASE_URL + "favicon.ico"
    }
  },
  created() {
    if (this[STATE_PLANT_ID]) {
      this.$router.push({
        name: "Explore Library",
        params: { library_id: this[STATE_PLANT_ID] }
      })
    }
    changeDocumentTitleAndIcon("PLANT", this.favicon)
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    }),
    library_id() {
      return this.$route.params.library_id
    }
  }
}
</script>
