<template>
  <v-menu offset-y origin="center center" :nudge-bottom="10" transition="slide-y-transition">
    <v-btn icon flat large slot="activator" :disabled="loading">
      <v-badge :color="loading ? 'blue' : new_notifications ? 'orange' : 'green'" overlap>
        <span slot="badge" v-if="new_notifications > 0">{{ new_notifications }}</span>
        <span slot="badge" v-if="loading">
          <v-icon>mdi-progress-clock</v-icon>
        </span>
        <v-icon>mdi-bell-outline</v-icon>
      </v-badge>
    </v-btn>
    <notifications-list />
  </v-menu>
</template>

<script>
import NotificationsList from "./NotificationsList"
import {
  STORE_EVENT_NOTIFICATIONS_NAME,
  GETTER_EVENT_UNREAD_NOTIFICATIONS,
  ACTION_EVENT_NOTIFICATIONS_FETCH
} from "@/store_constants/event-notifications"
import { mapActions, mapGetters } from "vuex"
export default {
  components: { NotificationsList },
  created() {
    this[ACTION_EVENT_NOTIFICATIONS_FETCH]()
  },
  computed: {
    ...mapGetters(STORE_EVENT_NOTIFICATIONS_NAME, [GETTER_EVENT_UNREAD_NOTIFICATIONS]),
    loading() {
      return this[GETTER_EVENT_UNREAD_NOTIFICATIONS] == undefined
    },
    new_notifications() {
      if (!this[GETTER_EVENT_UNREAD_NOTIFICATIONS]) return 0
      return this[GETTER_EVENT_UNREAD_NOTIFICATIONS].length
    }
  },
  methods: {
    ...mapActions(STORE_EVENT_NOTIFICATIONS_NAME, [ACTION_EVENT_NOTIFICATIONS_FETCH])
  }
}
</script>

<style>
</style>