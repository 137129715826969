<template>
  <div>
    <block-designer
      v-if="mode === 'edit' && !lessonPlanController.editLayouts"
      :blockDefinitions="blockDefinitions"
      :blocks="blocks"
      :slide-id="slideId"
      :defaultBlockStyles="lessonPlanController.lessonPlanContents.settings.styles.block"
      :settingStyles="lessonPlanController.lessonPlanContents.settings.styles"
      :isBlockSelected.sync="isBlockSelected"
      :leftPanelsTop="leftPanelsTop"
      :leftPanelsBottom="leftPanelsBottom"
      :rightPanels="rightPanels"
      :grid-size="lessonPlanController.gridSize"
      :available-stencils="lessonPlanController.availableStencils"
      :globalVariables="lessonPlanController.globalVariables"
      @create:draft="$emit('create:draft', $event)"
      @slideChanged="slideChangeAction">
    </block-designer>
    <layout-designer
      v-if="mode === 'edit' && lessonPlanController.editLayouts"
      :blockDefinitions="blockDefinitions"
      :blocks="blocks"
      :slide-id="slideId"
      :defaultBlockStyles="lessonPlanController.lessonPlanContents.settings.styles.block"
      :settingStyles="lessonPlanController.lessonPlanContents.settings.styles"
      :isBlockSelected.sync="isBlockSelected"
      :leftPanelsTop="leftPanelsLayoutTop"
      :leftPanelsBottom="leftPanelsLayoutBottom"
      :grid-size="lessonPlanController.gridSize"
      :available-stencils="lessonPlanController.availableStencils"
      :dark="true"
      :imported="lessonPlanController.imported"
      @create:draft="$emit('create:draft', $event)"
      @slideChanged="slideChangeAction">
    </layout-designer>
    <block-viewer
      v-if="mode === 'view' || mode === 'review' || mode === 'preview'"
      :blockDefinitions="blockDefinitions"
      :blocks="blocks"
      :slide-id="slideId"
      :defaultBlockStyles="lessonPlanController.lessonPlanContents.settings.styles.block"
      :settingStyles="lessonPlanController.lessonPlanContents.settings.styles"
      :leftPanelsTop="leftPanelsViewTop"
      :leftPanelsBottom="leftPanelsBottom"
      :rightPanels="rightPanels"
      @selectSlideById="lessonPlanController.selectSlideById($event)"
      @goToBookmarkTarget="lessonPlanController.goToBookmarkTarget($event)"
      :globalVariables="lessonPlanController.globalVariables"
      @slideChanged="slideChangeAction"
      :mode="mode">
    </block-viewer>
    <block-activity
      v-if="mode === 'activity'"
      :blockDefinitions="blockDefinitions"
      :blocks="blocks"
      :slide-id="slideId"
      :defaultBlockStyles="lessonPlanController.lessonPlanContents.settings.styles.block"
      :settingStyles="lessonPlanController.lessonPlanContents.settings.styles"
      :leftPanelsTop="leftPanelsViewTop"
      :leftPanelsBottom="leftPanelsBottom"
      :rightPanels="rightPanels"
      @selectSlideById="lessonPlanController.selectSlideById($event)"
      @goToBookmarkTarget="lessonPlanController.goToBookmarkTarget($event)"
      :globalVariables="lessonPlanController.globalVariables"
      @slideChanged="slideChangeAction">
    </block-activity>
    <v-snackbar
      v-model="lessonPlanController.notificationHandler.active"
      top
      :color="lessonPlanController.notificationHandler.color ? lessonPlanController.notificationHandler.color : 'error'"
      auto-height>
      {{ lessonPlanController.notificationHandler.text }}
      <v-btn flat @click="lessonPlanController.notificationHandler.active = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import BlockDesigner from "./blocks/_components/modes/edit/BlockDesigner.vue"
import LayoutDesigner from "./blocks/_components/modes/edit/LayoutDesigner.vue"
import BlockViewer from "./blocks/_components/modes/view/BlockViewer.vue"
import BlockActivity from "./blocks/_components/modes/activity/BlockActivity.vue"
import blockDefinitions from "./blocks/index.js"
import lessonPlanController from "./controllers/LessonPlanController/index.js"
import { upgradeModel, resetVisited } from "./models/upgradeModel.js"

import OutlinePanel from "./components/slides/OutlinePanel.vue"
import LessonSettingsPanel from "./components/slides/styles/LessonSettingsPanel.vue"
import GalleryPanel from "./components/gallery/GalleryPanel.vue"
import LayoutsPanel from "./components/layouts/LayoutsPanel.vue"
import ObjectivesPanel from "./components/slides/objectives/ObjectivesPanel.vue"
import NotesPanel from "./components/slides/notes/NotesPanel.vue"
import NotesPanelIcon from "./components/slides/notes/NotesPanelIcon.vue"
import SlideProperties from "./components/slides/properties/SlideProperties.vue"
import QuizProperties from "./components/slides/quiz/QuizProperties.vue"
import activity from "./mixins/activity.vue"
import { mapState } from "vuex"
import { STORE_CONTENT_NAME, STATE_REVISION_CHANGED_TOGGLE } from "../../store_constants/content"
import InstructionalSettings from "./components/settings/InstructionalSettings.vue"
import GlossaryPanel from "../commonInteractive/glossary/GlossaryPanel"
import glossaryController from "../commonInteractive/glossary/GlossaryController"
import PerformancePanel from "./components/performance/PerformancePanel.vue"
import uuid from "../../utils/uuid"

export default {
  name: "LessonPlan",
  props: ["value", "view", "mode", "comments", "activityState", "isStarted"],
  components: { BlockDesigner, LayoutDesigner, BlockViewer, BlockActivity },
  mixins: [activity],
  data() {
    return {
      lessonPlanController: lessonPlanController(this),
      blockDefinitions,
      isBlockSelected: false,
      leftPanelsTop: [
        {
          title: "Outline",
          icon: "mdi-file-tree",
          component: OutlinePanel
        },
        {
          title: "Block Gallery",
          icon: "mdi-toy-brick-outline",
          component: GalleryPanel,
          id: "blockGallery"
        },
        {
          title: "Glossary",
          icon: "mdi-book-alphabet",
          component: GlossaryPanel,
          props: {
            mode: this.mode,
            value: this.value,
            controller: glossaryController(this)
          }
        }
      ],
      leftPanelsLayoutTop: [
        {
          title: "Layout",
          icon: "mdi-file-tree",
          component: LayoutsPanel
        }
      ],
      leftPanelsLayoutBottom: [
        {
          title: "Lesson Settings",
          icon: "mdi-cog",
          component: LessonSettingsPanel,
          disabled: this.mode !== "edit"
        }
      ],
      addons: {
        glossary: {
          title: "Glossary",
          icon: "mdi-book-alphabet",
          component: GlossaryPanel,
          props: {
            mode: this.mode,
            value: this.value,
            controller: glossaryController(this)
          }
        },
        performance: {
          title: "Objectives Level",
          icon: "mdi-bullseye-arrow",
          component: PerformancePanel
        }
      }
    }
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      revisionChangedToggle: STATE_REVISION_CHANGED_TOGGLE
    }),
    content() {
      return this.lessonPlanController.lessonPlanContents
    },
    blocks() {
      return this.lessonPlanController.selectedSlide ? this.lessonPlanController.selectedSlide.blocks : []
    },
    hasSomeGlossary() {
      return (
        (this.value.body.contents.glossary && this.value.body.contents.glossary.length) ||
        (this.value.body.contents.glossaryImported && this.value.body.contents.glossaryImported.length) ||
        (this.interactiveTextController &&
          this.interactiveTextController.glossariesFromItsFromPage &&
          this.interactiveTextController.glossariesFromItsFromPage.length)
      )
    },
    slideId() {
      // Key for BlockCanvas, to repaint it
      // To avoid duplicate slide and don't repaint when changing to those
      return this.lessonPlanController.selectedSlide ? this.lessonPlanController.selectedSlide.id : "0"
    },
    rightPanels() {
      var panels = []
      if (this.lessonPlanController.selectedSlide) {
        if (this.mode === "edit") {
          panels.push({
            title: "Slide Properties Panel",
            icon: "mdi-card-text-outline",
            component: SlideProperties
          })
        }
        panels = panels.concat([
          {
            title: "Notes",
            icon: NotesPanelIcon,
            component: NotesPanel
          },
          {
            title: "Objectives",
            icon: "mdi-bullseye-arrow",
            component: ObjectivesPanel
          }
        ])
      }
      if (this.lessonPlanController.isQuiz(this.lessonPlanController.selectedSlide)) {
        if (this.mode === "edit") {
          panels.unshift({
            title: "Quiz Properties Panel",
            icon: "mdi-book-education-outline",
            component: QuizProperties
          })
        }
      }

      return panels
    },
    leftPanelsViewTop() {
      const panels = [
        {
          title: "Outline",
          icon: "mdi-file-tree",
          component: OutlinePanel
        }
      ]
      if (this.lessonPlanController.hasQuizInIP()) {
        panels.push(this.addons.performance)
      }
      if (this.mode !== "edit" && this.hasSomeGlossary) {
        panels.push(this.addons.glossary)
      }

      return panels
    },
    leftPanelsBottom() {
      return [
        {
          title: "Instructional Settings",
          icon: "mdi-school-outline",
          component: InstructionalSettings
        },
        {
          title: "Lesson Settings",
          icon: "mdi-cog",
          component: LessonSettingsPanel,
          disabled: this.lessonPlanController.mode !== "edit"
        }
      ]
    }
  },
  created() {
    this.initSlides()
    // create a new $origin for Interactive Presentation data
    if (this.needToCreateOrigin()) {
      this.value.body.contents.$origin = `plant:/files/${this.value._id}/${uuid()}.json`
    }
  },
  provide() {
    return {
      lessonPlanController: this.lessonPlanController,
      blockGlobals: {
        fontFamilies: this.lessonPlanController.fontFamilies
      }
    }
  },
  watch: {
    revisionChangedToggle() {
      // Recalculate slidesTree and selectedSlide
      // to repaint content after changing revision
      this.lessonPlanController.initSlidesTree()
      this.lessonPlanController.selectedSlide =
        this.lessonPlanController.slideArray[this.lessonPlanController.selectedSlideIndex]
    }
  },
  methods: {
    initSlides() {
      if (
        (this.mode === "edit" && !this.lessonPlanController.editLayouts) ||
        this.mode === "view" ||
        this.mode === "review" ||
        this.mode === "activity"
      ) {
        if (this.isStarted) {
          this.goToNextSlideAfterCompleted()
        } else {
          this.lessonPlanController.goToFirstSlide()
        }
        upgradeModel(this.content)
        resetVisited(this.content)
      }
    },
    slideChangeAction(blockCtrl) {
      blockCtrl.overlay = null
      blockCtrl.selectionClear()
    },
    needToCreateOrigin() {
      // New draft or old contents which don't have $origin
      if (!this.value.body.contents.$origin) {
        return true
      }
      // It's a new revision of the content
      const [, , revision_id, filename] = this.value.body.contents.$origin.split("/")
      if (revision_id !== this.value._id) {
        return true
      }
      return false
    }
  }
}
</script>

<style>
@font-face {
  /* Gotham-Book */
  font-family: "Gotham";
  src: url("../../../public/img/branding/brucepower/fonts/Gotham.otf") format("opentype");
}

@font-face {
  /* ProximaNova */
  font-family: "ProximaNova";
  src: url("../../../public/img/branding/entergy/fonts/ProximaNova.otf") format("opentype");
}

.lp-index {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/* To remove a style in DiagramRightDrawer that is applied everywhere */
.lp-index.dark .v-text-field input {
  color: #ffffff !important;
}

.lp-index-panel {
  position: absolute;
  top: 50px;
  bottom: 0;
}

.lp-index-main {
  position: absolute;
  top: 50px;
  left: 80px;
  right: 80px;
  bottom: 0;
}
</style>
