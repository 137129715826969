import { BlockBasic } from "../_models/models.js"

export default class BlockRadiobutton extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "inputRadiobutton"
    this.size.width = 1200
    this.size.height = 600
    this.data = {
      items: false,
      value: false,
      style: {
        color: "#000000ff",
        fontSize: 46,
        fontFamily: false,
        /* left, center, right, justify */
        textAlign: "left",
        /* flex-start, center, flex-end */
        alignItems: "center",
        padding: 0
      }
    }
  }
}
