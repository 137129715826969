import Vue from "vue"
import PServiceContent from "plant-common/src/services/PServiceContent";
import ptApiClient from 'plant-api-client';
import { GuideCard, GuideSection, IRBGuide } from "./classes"
import { PtModal } from "plant-common";
import { STORE_MODULE_COMMENTS, ACTION_SAVE_CURRENT_LOCATION } from '../../../store_constants/content';
import { getContentId, getCustomId, getTitle } from "../../../utils/dbTool";


export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    mixins: [PtModal],
    data() {
      return {
        selectedCard: false,
        selectedIndex: false,
        currentViewCard: false,
        latests_revisions: {},
        activityState: vm.activityState ? Object.assign({}, vm.activityState) : undefined,
      };
    },
    async created() {
      if (!itemRevision.body.contents || Object.keys(itemRevision.body.contents).length < 1) {
        itemRevision.body.contents = new IRBGuide()
      }
      this.initActivityState();
      await this.checkLatestRevisions()
      function iter(path) {
        path = path || [];
        return function (o) {
          o.path = path.concat({ id: o.id, title: o.fields ? o.fields.title : getTitle(o.content) });
          if (o.children) {
            o.children.forEach(iter(o.path));
          }
        }
      }
      itemRevision.body.contents.sections.forEach(iter())
    },
    computed: {
      ncards() {
        return this.cards.length;
      },
      cards() {
        function flat(array) {
          var result = [];
          array.forEach(function (a) {
            result.push(a);
            if (Array.isArray(a.children)) {
              result = result.concat(flat(a.children));
            }
          });
          return result
        }
        return [this.guide.cover, ...flat(this.guide.sections)]
      },
      resolved_information() {
        return card => {
          if (!card.content) return {}
          var $origin = card.content.$origin.split("/");
          var contentId = $origin[2];
          var major_revision_created = $origin[3];
          var minor_revision_created = $origin[4];
          var { major_revision, minor_revision } = card.content.header;
          return {
            resolved: `${major_revision}.${minor_revision}`,
            created: `${major_revision_created}.${minor_revision_created}`,
            is_latest: (major_revision == major_revision_created) && (minor_revision == minor_revision_created),
            created_major: major_revision_created,
            created_minor: minor_revision_created,
            contentId: contentId
          };
        }
      },
      guide() {
        return itemRevision.body.contents
      },
      content() {
        return itemRevision
      },
      view() {
        return vm.view
      },
      templates() {
        if (this.guide.template
          && this.guide.template.body
          && this.guide.template.body.contents
          && this.guide.template.body.contents.card_templates
          && this.guide.template.body.contents.card_templates.length) {
          return this.guide.template.body.contents.card_templates.filter(e => e.name !== 'cover' && e.name !== 'section')
        } else {
          return []
        }
      },
      section() {
        if (this.guide.template
          && this.guide.template.body
          && this.guide.template.body.contents
          && this.guide.template.body.contents.card_templates
          && this.guide.template.body.contents.card_templates.length) {
          return this.guide.template.body.contents.card_templates.filter(e => e.name == 'section')
        } else {
          return []
        }
      }
    },
    methods: {
      initActivityState() {
        this.activityState = {
          completion: false,
          success: false,
          activities: {},
          progress: 0
        }
        for (var card of this.cards) {
          this.$set(this.activityState.activities, card.id, {
            completion: false
          })
        }
      },
      getCardStatus(id) {
        return this.activityState.activities[id]
      },
      async checkLatestRevisions() {
        this.latests_revisions = {}
        let guideCustomIds = []
        for (var card of this.cards) {
          if (card.content && card.content.header && guideCustomIds.indexOf(getCustomId(card.content)) === -1) {
            guideCustomIds.push(getCustomId(card.content))
          }
        }
        this.latests_revisions = await PServiceContent.getLatest(guideCustomIds)
      },
      async updateAllCards() {
        var confirm = await this.$modal("confirm", {
          title: 'Confirm Update',
          message: 'This action will update ALL references in this guide to its latest reference available. Do you want to continue?',
          accept: { text: 'Yes' },
          cancel: { text: 'No' }
        })
        if (!confirm) return
        for (var card of this.cards) {
          if (card.content && card.content.header) {
            var { major_revision, minor_revision } = card.content.header
            if (this.latests_revisions[getCustomId(card.content)] != `${major_revision}.${minor_revision}`) {
              this.updateCardToLatestContent(card)
            }
          }
        }
      },
      async updateCardToLatestContent(card) {
        // We are using plant-api-client inside GuideController, if we want to move guide to plant-common we will need to move this functionality as a service
        let last_content = await ptApiClient.getContentByCustomId(getCustomId(card.content));
        this.latests_revisions[getCustomId(card.content)] == `${last_content.header.major_revision}.${last_content.header.minor_revision}`;
        card.content = last_content
      },
      getTemplate(template_name) {
        try {
          return this.guide.template.body.contents.card_templates.find(
            t => t.name == template_name
          )

        } catch (e) {
          return { svg: '<svg></svg>' }
        }
      },
      getFields(keyGuide) {
        let regex = /\{\{([\S*\d+\S*]*)\}\}/gm;
        let match
        let fields = {};
        while ((match = regex.exec(this.getTemplate(keyGuide).svg)) !== null) {
          let key = match[1];
          fields[key] = '';
        }
        return fields
      },
      addSubsection() {
        if (this.selectedCard && this.selectedCard.type === 'section') {
          this.pushSection(this.selectedCard)
        }
      },
      addSection() {
        this.pushSection()
      },
      pushSection(card) {
        var newSection = new GuideSection();
        if (this.section && this.section[0] && this.section[0].fields) {
          newSection.fields = JSON.parse(JSON.stringify(this.section[0].fields));
        }
        if (card) {
          this.selectedCard.children.push(newSection)
        } else {
          this.guide.sections.push(newSection)
        }
        this.selectedCard = newSection;
      },
      cloneCard() {
        let BreakException = {};
        if (this.selectedCard) {
          var newCard = JSON.parse(JSON.stringify(this.selectedCard));
          newCard.id = Math.floor(Math.random() * 10000000)
          let search = this.getParents(this.guide.sections, this.selectedCard.id, [])
          if (search.found) {
            let lastParent = search.parents[search.parents.length - 1]
            try {
              lastParent.children.forEach((element, index, array) => {
                if (element.id == this.selectedCard.id) {
                  lastParent.children.splice(index, 0, newCard)
                  throw BreakException;
                }
              })
            } catch (error) {
              if (error !== BreakException) throw error;
            }
          }
        }
      },
      addCard() {
        if (this.selectedCard) {
          var newCard = new GuideCard();
          switch (this.selectedCard.type) {
            case 'section':
              this.selectedCard.children.push(newCard)
              this.selectedCard = newCard;
              break;
            case 'card':
              let sectionOfSelectedCard = false;
              let indexOfSelectedCard = false;
              this.guide.sections.forEach(section => {
                section.children.forEach((card, index) => {
                  if (card.id == this.selectedCard.id) {
                    sectionOfSelectedCard = section
                    indexOfSelectedCard = index + 1
                  }
                })
              });
              if (sectionOfSelectedCard) {
                sectionOfSelectedCard.children.splice(indexOfSelectedCard, 0, newCard);
              }
              this.selectedCard = newCard;
              break;
            case 'cover':
              this.addSection();
              this.selectedCard.children.push(newCard)
              this.selectedCard = newCard;
              break;
            default:
              break;
          }
        }
      },

      saveCurrentLocation(location) {
        vm.$store.dispatch(`${STORE_MODULE_COMMENTS}/${ACTION_SAVE_CURRENT_LOCATION}`, location)
      },

      selectCard(card) {
        if (card) {
          localStorage.latestCard = JSON.stringify({
            type: card.type,
            fields: card.fields
          })
          try {
            localStorage.currentGuide = JSON.stringify(this.guide)
          } catch (err) {
            console.log(err)
          }
          if (card.content && card.content.header) {
            localStorage.latestItemRevisionId = getContentId(card.content)
            localStorage.latestItemRevisionView = (card.view) ? JSON.stringify(card.view) : false
          } else {
            localStorage.latestItemRevisionId = -1
            localStorage.latestItemRevisionView = null
          }

          this.$nextTick(() => {
            if (this.selectedCard != card) {
              this.currentViewCard = false;
              this.selectedCard = card;
              if (this.selectedCard.id) {
                this.saveCurrentLocation({ id: this.selectedCard.id, type: this.selectedCard.type });
              }
              if (this.selectedCard.view)
                this.$set(this.selectedCard, "actualView", JSON.parse(JSON.stringify(this.selectedCard.view)));
            }
          });
        }
      },

      removeCard() {
        if (this.selectedCard) {
          if (this.selectedCard.type == "section") {
            let childrenIDS = this.getChildrenIds(this.selectedCard.children, [])
            childrenIDS.array.push(this.selectedCard.id)
            // TODO: Recursive find ids of sections and cards
            if (itemRevision.approval_process.comments) {
              itemRevision.approval_process.comments.forEach(el => {
                if (el.location && el.location.id && childrenIDS.array.includes(el.location.id)) {
                  el.toDelete = true;
                }
              });
            }
          } else if (this.selectedCard.type == "card") {
            if (itemRevision.approval_process.comments) {
              itemRevision.approval_process.comments.forEach(comment => {
                if (comment.location && comment.location.id == this.selectedCard.id) {
                  comment.toDelete = true;
                }
              })
            }
          }
          this.deleteElement(this.guide.sections, this.selectedCard.id, [])
          this.selectedCard = this.guide.cover;
        }
      },

      getChildrenIds(tree, array) {
        var result = {
          array: array
        }
        for (let i = 0; i < tree.length; i++) {
          array.push(tree[i].id)
          if (tree[i].type == 'section') {
            if (tree[i].children && tree[i].children.length) {
              result = this.getChildrenIds(tree[i].children, array)
              array = result.array
            }
          }
          result.array = array

        }
        return result;
      },

      removeAll() {
        this.guide.sections = [];
        this.selectedCard = this.guide.cover;
      },

      deleteEnablingObjective(tree) {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].type == 'section') {
            tree[i].enabling_objectives = []
            if (tree[i].children && tree[i].children.length) {
              this.deleteEnablingObjective(tree[i].children)
            }
          }
        }
      },

      setDroppable(tree) {
        for (let i = 0; i < tree.length; i++) {
          if (tree[i].type == 'card') {
            tree[i].$droppable = false
          }
          if (tree[i].type == 'section') {
            if (tree[i].children && tree[i].children.length) {
              this.setDroppable(tree[i].children)

            }
          }

        }
        return true;
      },

      totalElements(tree, index) {
        var result = {
          found: false,
          index: index
        }
        for (let i = 0; i < tree.length; i++) {
          index++
          if (tree[i].type == 'section') {
            if (tree[i].children && tree[i].children.length) {
              result = this.totalElements(tree[i].children, index)
              index = result.index
            }
          }
          result.index = index

        }
        return result;
      },

      selectCardFromId(id) {
        let card = this.cards.find(card => card.id == id)
        this.selectCard(card)
      },

      loadCover() {
        this.selectCard(itemRevision.body.contents.cover)
      },

      findElement(tree, id, index) {
        var result = {
          found: false,
        }
        for (let i = 0; i < tree.length; i++) {
          index++
          if (!result.found) {
            if (tree[i].id == id) {
              result = {
                found: true,
                element: tree[i],
                index: index
              };
            }
            else if (tree[i].type == 'section') {
              if (tree[i].children && tree[i].children.length) {
                result = this.findElement(tree[i].children, id, index)
                index = result.index
              }
            }
            result.index = index
          }

        }
        return result;
      },

      findElementByIndex(tree, indexToCheck, index) {
        var result = {
          found: false,
        }
        for (let i = 0; i < tree.length; i++) {
          index++
          if (!result.found) {
            if (index == indexToCheck) {
              result = {
                found: true,
                element: tree[i],
                index: index
              };
            }
            else if (tree[i].type == 'section') {
              if (tree[i].children && tree[i].children.length) {
                result = this.findElementByIndex(tree[i].children, indexToCheck, index)
                index = result.index
              }
            }
            result.index = index
          }

        }
        return result;
      },

      getParents(tree, id, parents) {
        var result = {
          found: false,
          parents: parents
        }
        tree.forEach(element => {
          if (!result.found) {
            if (element.id == id) {
              result.found = true,
                result.parents = parents
            }
            else if (element.type == 'section') {
              if (element.children && element.children.length) {
                parents.push(element)
                result = this.getParents(element.children, id, parents)
                if (!result.found) parents.pop()
              }
            }
          }

        });
        return result;
      },

      increment() {
        if (this.selectedCard) {
          switch (this.selectedCard.type) {
            case 'cover':
              if (this.guide.sections.length > 0) this.selectCard(this.guide.sections[0])
              break;
            default:
              let lastIndex = this.findElement(this.guide.sections, -1, -1).index
              let indice = this.findElement(this.guide.sections, this.selectedCard.id, -1).index
              if (indice < lastIndex) this.selectCard(this.findElementByIndex(this.guide.sections, indice + 1, -1).element)
              break;
          }
        }
      },

      decrement() {
        if (this.selectedCard) {
          switch (this.selectedCard.type) {
            case 'cover':
              break;
            default:
              let indice = this.findElement(this.guide.sections, this.selectedCard.id, -1).index
              if (indice == 0) {
                return this.selectCard(this.guide.cover)
              } else {
                this.selectCard(this.findElementByIndex(this.guide.sections, indice - 1, -1).element)
              }
              break;
          }
        }
      },

      selectContent() {
        PServiceContent.selectContent()
          .then(r => {
            if (r) {
              this.latests_revisions[getCustomId(r)] = `${r.header.major_revision}.${r.header.minor_revision}`
              this.selectedCard.content = r;
            }
          })
          .catch(console.error);
      },

      selectTemplate(card) {
        this.$modal("templatesList", {
          title: "Select template",
          options: this.templates,
          attachments: this.guide.template.body.attachments.filter(e => e.name != 'cover' && e.name != 'section')
        }).then(r => {

          let svg = this.guide.template.body.attachments.find(e => e.name === r.name && e.attachment_type === '.svg')
          if (svg) {
            this.selectedCard = false;
            fetch(svg.url)
              .then(res => res.text())
              .then(section_svg => {

                card.type = "card-template"
                card.content = {
                  template_id: r.id,
                  template_name: r.name
                }
                this.$nextTick(() => {
                  this.selectedCard = card
                });
              })
          }
        });
      },

      reloadContent(r) {
        let card = this.selectedCard
        this.$nextTick(() => {
          this.currentViewCard = false;
          this.selectedCard = card
        });

      },

      removeItemFromCard() {
        this.selectedCard.type = "card"
        this.selectedCard.content = false
      },

      setCurrentViewCard(view) {
        this.currentViewCard = view;
      },
      saveViewCard() {
        this.selectedCard.view = this.currentViewCard
      },
      deleteElement(tree, id, parents) {
        let index = null
        var result = {
          found: false,
        }
        tree.forEach(element => {
          if (!result.found) {
            if (element.id == id) {
              result = {
                found: true,
                parent: parents
              };

              if (Array.isArray(parents) && parents.length) {
                for (let i = 0; i < parents.children.length; i++) {
                  if (parents.children[i].id === element.id) {
                    index = i
                  }
                }
                if (index !== null) {
                  parents.children.splice(index, 1);
                }
              } else {
                for (let i = 0; i < tree.length; i++) {
                  if (tree[i].id === element.id) {
                    index = i
                  }
                }
                if (index !== null) {
                  tree.splice(index, 1);
                }
              }

            }
            else if (element.type == 'section') {
              if (element.children && element.children.length) {
                result = this.deleteElement(element.children, id, element)
              }
            }
          }

        });
        return result;
      },
      findElementByAnchor(tree, anchor) {
        var result = {
          found: false,
        }
        for (let i = 0; i < tree.length; i++) {
          if (!result.found) {
            if (tree[i].internal_link_name == anchor) {
              result = {
                found: true,
                element: tree[i],
              };
            }
            else if (tree[i].type == 'section') {
              if (tree[i].children && tree[i].children.length) {
                result = this.findElementByAnchor(tree[i].children, anchor)
              }
            }
          }

        }
        return result;
      },
    },
    watch: {
      selectedCard: {
        deep: true,
        immediate: true,
        handler() {
          vm.$emit("update:view", { card_id: this.selectedCard.id })
          if (this.activityState) {
            if (this.activityState.activities[this.selectedCard.id]) {
              this.activityState.activities[this.selectedCard.id].completion = true
            }
            var n = Object.values(this.activityState.activities).filter(a => a.completion).length
            this.activityState.progress = Math.round(n / this.ncards * 100)
            this.activityState.completion = this.activityState.progress == 100
          }
          vm.$emit("update:activityState", this.activityState);
        }
      },
      view: {
        immediate: true,
        handler() {
          if (this.view && this.view.card_id) {
            this.selectCardFromId(this.view.card_id)
          }
        }
      }
    }
  })
}