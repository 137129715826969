import Replace from "./Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "global-replace-title",
  title: "Replace Content Title",
  description:
    '[Common] This operation replaces the "find" value (Case sensitive) with the "replace" value in all content\'s title',
  contentType: "global",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.header && content.header.metadata && content.header.metadata.title) {
        content.header.metadata.title = content.header.metadata.title.toString().replace(searchRegExp, replaceWith)
      }
    }
    return content
  },
  OperationOptionsComponent: Replace
}
