export const STORE_MODULE_EDITOR = 'content/editor';

export const STATE_ITEM_IS_EDITED = 'itemIsEdited';
export const STATE_EDITING_ITEM_REVISION_DRAFT = 'editingItemRevisionDraft';
export const STATE_LOCKED_DRAFT = 'lockDraft';

export const MUTATION_SET_EDITING_ITEM_REVISION_DRAFT = 'setEditingItemRevisionDraft';
export const MUTATION_LOCK_DRAFT = 'setLockedDraft';

export const ACTION_DELETE_REFERENCE = 'deleteReference';
export const ACTION_UPDATE_IS_EDITED = 'updateIsEdited';
export const ACTION_UPLOAD_DRAFT = 'uploadItemDraft';
export const ACTION_SET_DRAFT = 'setItemDraft';
export const ACTION_GET_EDTITING_ITEM_REVISION_DRAFT = 'getEditingItemRevisionDraft';
export const ACTION_SUBMIT_TRANSITION = 'submitTransition';
export const ACTION_CREATE_DRAFT = 'createDraft';
export const ACTION_COPY_CONTENT_FROM_ITEM = 'copyContentFromItem';
export const ACTION_GUIDE_2_INTERACTIVE_PRESENTATION_TRANSITION = 'guideToInteractivePresentationTransition';
export const ACTION_LOCK_DRAFT = 'lockDraft';