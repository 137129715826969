import Replace from "./Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "global-replace-description",
  title: "Replace Content Description",
  description:
    '[Common] This operation replaces the "find" value (Case sensitive) with the "replace" value in all content\'s description',
  contentType: "global",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.header && content.header.metadata && content.header.metadata.description) {
        content.header.metadata.description = content.header.metadata.description
          .toString()
          .replace(searchRegExp, replaceWith)
      }
    }
    return content
  },
  OperationOptionsComponent: Replace
}
