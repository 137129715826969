<script>
import TitleBarTitle from "./TitleBarTitle";
import TitleBarDraft from "./TitleBarDraft";
import TitleBarRevision from "./TitleBarRevision";
import WorkflowButtons from "./WorkflowButtons";
import { VSpacer } from "vuetify/lib";
export default {
  functional: true,
  render: function(createElement, context) {
    return [
      createElement(TitleBarTitle),
      createElement(VSpacer),
      createElement(TitleBarRevision),
      createElement(TitleBarDraft),
      createElement(WorkflowButtons)
    ];
  }
};
</script>
