function getBlockTextarea(id, side) {
  const blockContainer = document.getElementById(id)
  if (!blockContainer) return null
  let blockTextarea = getTextArea(blockContainer, side)
  if (blockTextarea && side) {
    blockTextarea = blockTextarea.querySelector(`.${side}`)
  }
  return blockTextarea
}

export function changeFontGlobal(id, data, side = null) {
  let blockTextarea = getBlockTextarea(id, side)
  if (blockTextarea) {
    changeFontSizeTextarea(blockTextarea, data.fontSize)
    changeColorTextarea(blockTextarea, data.color)
    changePaddingTextarea(blockTextarea, data.padding)
  }
}

export function changeFontSize(id, fontSize, side = null) {
  let blockTextarea = getBlockTextarea(id, side)
  if (blockTextarea) {
    changeFontSizeTextarea(blockTextarea, fontSize)
  }
}

export function changePadding(id, padding, side = null) {
  let blockTextarea = getBlockTextarea(id, side)
  if (blockTextarea) {
    changePaddingTextarea(blockTextarea, padding)
  }
}

export function changeColor(id, color, side = null) {
  let blockTextarea = getBlockTextarea(id, side)
  if (blockTextarea) {
    changeColorTextarea(blockTextarea, color)
  }
}

function changeFontSizeTextarea(blockTextarea, fontSize) {
  blockTextarea.style.fontSize = `${fontSize}px`
}
function changeColorTextarea(blockTextarea, color) {
  blockTextarea.style.color = color
}

function changePaddingTextarea(blockTextarea, padding) {
  blockTextarea.style.padding = `${padding}px`
}

function getTextArea(blockContainer, side) {
  if (side) {
    return blockContainer.querySelector(".lp-block-flip-card")
  } else {
    return (
      blockContainer.querySelector(".lp-block-textarea") ||
      blockContainer.querySelector(".lp-block-placeholder-textarea")
    )
  }
}

export function getNodes(str) {
  return new DOMParser().parseFromString(str, "text/html")
}

export function getStringFromNodes(nodes) {
  return nodes.body.outerHTML.replace("<body>", "").replace("</body>", "")
}
