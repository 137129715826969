import PtApiClient from "plant-api-client"
import {
  STATE_REVISIONS,
  STATE_REVISIONS_LOADING,
  MUTATION_SET_REVISIONS,
  ACTION_LOAD_REVISIONS
} from "@/store_constants/content"
import { getContentId } from "@/utils/dbTool"
export default {
  name: "revisions",
  namespaced: "true",
  state: {
    [STATE_REVISIONS]: false,
    [STATE_REVISIONS_LOADING]: false
  },
  getters: {},
  mutations: {
    [MUTATION_SET_REVISIONS](state, revisions) {
      state[STATE_REVISIONS] = revisions
    }
  },
  actions: {
    async [ACTION_LOAD_REVISIONS]({ commit, state, dispatch }, itemId) {
      try {
        const itemAllRevisions = await PtApiClient.getAllRevisionsByContentId(itemId)
        let revisions = itemAllRevisions.map(e => ({
          major_revision: e.header.major_revision,
          minor_revision: e.header.minor_revision,
          time: e.header.created_on,
          id: getContentId(e),
          summary_changes: e.header.metadata.summary_changes ? e.header.metadata.summary_changes : null
        }))
        commit(MUTATION_SET_REVISIONS, revisions)
        return revisions
      } catch (error) {
        if (error.response && error.response.data) {
          dispatch(
            "notifications/error",
            { message: error.response.data.message },
            {
              root: true
            }
          )
        } else {
          dispatch(
            "notifications/error",
            { message: "Something happened when trying to fetch data" },
            {
              root: true
            }
          )
        }
      }
    }
  }
}
