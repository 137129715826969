<template>
  <div
    class="block-overlay-canvas"
    @click.self="canCloseOutside ? closeOverlay() : ''"
    v-show="blockController.overlay">
    <div :style="style" ref="canvas">
      <div
        v-if="showCloseButton"
        class="close-icon"
        style="display: flex; justify-content: flex-end"
        @click="closeOverlay">
        <v-icon> mdi-close </v-icon>
      </div>
      <block-subcanvas
        v-show="blockController.overlay"
        :blocks="blockController.overlay.blocks"
        mode="edit"
        :style="styleCanvas"
        @create:draft="$emit('create:draft', $event)"
        :blockVariables="blockController.overlayVariables" />
    </div>
  </div>
</template>

<script>
import BlockSubcanvas from "./BlockSubcanvas.vue"
import blockOverlayCommon from "../../_mixins/blockOverlayCommon.vue"

export default {
  components: { BlockSubcanvas },
  inject: ["blockController"],
  mixins: [blockOverlayCommon],
  props: ["defaultBlockStyles"],
  computed: {
    parent() {
      return this.$refs.canvas
    },
    style() {
      if (this.blockController.overlay) {
        return {
          position: "relative",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          width: `${this.blockController.overlay.width}px`,
          height: `${this.blockController.overlay.height}px`,
          borderRadius: `${this.blockController.overlay.style.borderRadius}px`,
          backgroundColor: this.blockController.overlay.style.backgroundColor || "white"
        }
      }
      return {}
    },
    styleCanvas() {
      return {
        fontFamily: this.defaultBlockStyles.fontFamily
      }
    },
    showGrid() {
      return this.blockController.gridSize && this.gridX !== 1
    },
    gridX() {
      return this.blockController.gridSize[0]
    },
    gridY() {
      return this.blockController.gridSize[1]
    },
    canCloseOutside() {
      return !this.blockController.overlay.avoidClosingOutside
    },
    showCloseButton() {
      return !this.blockController.overlay.hideCloseButton
    }
  },
  methods: {
    closeOverlay() {
      this.blockController.overlay = null
    }
  }
}
</script>

<style scoped>
.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 1rem 1rem;
}

.block-overlay-canvas {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
}
</style>