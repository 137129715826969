<template>
  <v-container fluid class="px-0 py-0">
    <v-toolbar flat class="elevation-0 transparent">
      <v-toolbar-title>Content Tools</v-toolbar-title>
    </v-toolbar>
    <v-layout row wrap v-if="tools.length > 0">
      <v-flex xl3 lg4 md6 sm12 xs12 v-for="tool in tools" :key="tool.id">
        <tools-selector-card :key="tool.id" :tool="tool" /> </v-flex
    ></v-layout>
    <v-alert v-else :value="true" color="info" icon="info"> Sorry, no tools are available. </v-alert>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex"
import { STORE_LIBRARY_NAME, ACTION_SELECT_PLANT, STATE_PLANT_ID } from "@/store_constants/library"
import { STORE_USER_NAME, ACTION_SELECT_LIBRARY } from "@/store_constants/user"
import ToolsSelectorCard from "./components/ToolsSelectorCard.vue"
import tools from "./tools"
export default {
  props: {
    library_id: String
  },
  components: { ToolsSelectorCard },
  data() {
    return {
      loading: true,
      tools: tools
    }
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    })
  },
  methods: {
    ...mapActions(STORE_LIBRARY_NAME, [ACTION_SELECT_PLANT]),
    ...mapActions(STORE_USER_NAME, {
      selectLibrary: ACTION_SELECT_LIBRARY
    })
  },
  watch: {
    library_id: {
      immediate: true,
      async handler() {
        if (this.plant_id !== this.library_id) {
          this.loading = true
          const plant = await this[ACTION_SELECT_PLANT](this.library_id)
          await this.selectLibrary()
        }
        this.loading = false
      }
    }
  }
}
</script>

