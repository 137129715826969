<template>
  <span>
    <slot />
    <span v-if="ellipsis && ellipsis.length > 0">
      <v-menu offset-y class="menu-inline">
        <template v-slot:activator="{ on }">
          <v-btn small flat v-on="on">...</v-btn>
        </template>
        <v-card class="breadcrumb-card">
          <v-list>
            <v-list-tile-content
              v-for="(el, index) in ellipsis"
              :key="index"
              @click="$emit('change', el._id)"
              class="ellipsis-item"
            >
              <v-list-tile-title class="breadcrumb-title">
                {{ el.name }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list>
        </v-card>
      </v-menu>
    </span>
    <span v-for="(folder, index) in path" v-bind:key="folder._id">
      <v-icon v-if="index > 0" class="mr-1pm" small>chevron_right</v-icon>
      <v-btn
        small
        @click="$emit('change', folder._id)"
        flat
        :disabled="index == path.length - 1"
      >
        <v-icon small left>mdi-folder</v-icon>
        {{ folder.name }}
      </v-btn>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    path: {
      type: Array
    }
  },
  computed: {
    ellipsis() {
      if (this.path && this.path.length > 2) {
        return this.path.splice(0, this.path.length - 2)
      } else {
        return []
      }
    }
  }
};
</script>

<style scoped>
.menu-inline {
  display: inline;
}

.ellipsis-item {
  padding: 2px 5px;
  cursor: pointer;
}

.ellipsis-item:hover {
  background-color: #d8d8d8;
}

.breadcrumb-title {
  margin: 10px;
}

.breadcrumb-card {
  min-width: 200px;
}
</style>