// This state management option is based on the ideas in this article:
// https://austincooper.dev/2019/08/09/vue-observable-state-store/

import Vue from 'vue';
import PtCoreClient from 'plant-core-client';
import PApiClient from 'plant-api-client'
import { libraryApi } from "@/api/index.js";
import store from "@/store"
import { STORE_LIBRARY_NAME, ACTION_SELECT_PLANT } from '@/store_constants/library';
import { ACTION_SELECT_LIBRARY, STORE_USER_NAME, STATE_PROFILE } from '@/store_constants/user';
import {
    STORE_NOTIFICATIONS_NAME,
    ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications";
import uuid from "../../utils/uuid"
const state = Vue.observable({ // this is the magic
    outdated: null,
    selectedUpgrade: false,
    loading: false,
    pagination: {
        page: 1,
        sortBy: "dependent.header.custom_id",
        descending: false,
        rowsPerPage: 5
    },
    groupBy: 'dependents'
});


export const outdated = () => state.outdated
export const getPagination = () => state.pagination
export const loading = () => state.loading
export const selectedUpgrade = () => state.selectedUpgrade
export const groupBy = () => state.groupBy


export async function fetchOutdated() {
    state.loading = true
    var { page, rowsPerPage, sortBy, descending } = state.pagination
    if (state.groupBy == "dependents") {
        state.outdated = await PtCoreClient.getOutdatedDependents({ page, limit: rowsPerPage, sortBy, sortOrder: descending ? -1 : 1 })
    } else {
        state.outdated = await PtCoreClient.getOutdatedDependencies({ page, limit: rowsPerPage, sortBy, sortOrder: descending ? -1 : 1 })
    }
    state.loading = false
}

export const setGroupBy = groupBy => {
    state.groupBy = groupBy
    if (groupBy == "dependents") {
        state.pagination.sortBy = "dependent.header.custom_id"
    } else {
        state.pagination.sortBy = "dependency.header.custom_id"
    }
    fetchOutdated()
}
export const setPagination = pagination => {
    state.pagination = pagination
    fetchOutdated()
}

export function selectUpgrade(selectedUpgrade) {
    state.selectedUpgrade = selectedUpgrade
}

export async function recalculateContentAndRelations() {
    state.loading = true
    const response = await PtCoreClient.recalculateContentAndRelations()
    state.loading = false
    return response
}

async function updateDependent(dependent, dependencyUpdateMap, approve) {

    // login into the plant
    await store.dispatch(`${STORE_LIBRARY_NAME}/${ACTION_SELECT_PLANT}`, dependent.library_id);
    await store.dispatch(`${STORE_USER_NAME}/${ACTION_SELECT_LIBRARY}`);

    // create a new draft of the "from"
    await PApiClient.createDraft(dependent.content_id)

    // Change workflow to no_reviewer
    await PApiClient.updateDraftWorkflow(
        dependent.content_id,
        "method",
        "changeWorkflow",
        { name: "no_reviewer", lightweight: true }
    );
    // Assign it to me
    var newDraft = await PApiClient.updateDraftWorkflow(
        dependent.content_id,
        "method",
        "reassignRole",
        {
            role: 'author',
            user_id: store.state[STORE_USER_NAME][STATE_PROFILE]._id
        }
    );

    function isAFile(origin) {
        const regexFile = new RegExp("plant:/files")
        return regexFile.test(origin)
    }

    function changeFileReferences(obj, draft_id) {
        if (obj == null) return obj
        if (obj.$origin && isAFile(obj.$origin)) {
            obj.$origin = `plant:/files/${draft_id}/${uuid()}.json`
            return obj
        } else if (typeof obj == 'object' && obj != null) {
            Object.keys(obj).forEach(k => obj[k] = changeFileReferences(obj[k], draft_id))
            return obj
        } else {
            return obj
        }
    }

    var updatedFileDraft = changeFileReferences(JSON.parse(JSON.stringify(newDraft)), newDraft._id)

    function changeReferences(obj) {
        if (obj == null) return obj
        if (obj.$origin && Object.keys(dependencyUpdateMap).includes(obj.$origin)) {
            return { $origin: dependencyUpdateMap[obj.$origin] }
        }
        else if (typeof obj == 'object' && obj != null) {
            Object.keys(obj).forEach(k => obj[k] = changeReferences(obj[k]))
            return obj
        }
        else return obj
    }

    var upgradedDraft = changeReferences(JSON.parse(JSON.stringify(updatedFileDraft)))
    upgradedDraft.body.dependencies = Object.values(dependencyUpdateMap)

    // Save draft
    await PApiClient.updateDraftBody(dependent.content_id, upgradedDraft.body);

    if (approve) {
        // approve
        await PApiClient.updateDraftWorkflow(
            dependent.content_id,
            "transition",
            "approveMinorChange"
        );
    }
}

async function checkIfDraftExists(dependent) {
    try {
        await libraryApi.getDraftByContentId(
            dependent.library_id,
            dependent.content_id
        )
        return true
    } catch (e) {
        return false
    }
}


export async function updateSelectedDependents(approve) {

    if (state.groupBy == 'dependents') {
        var { dependent } = state.selectedUpgrade
        if (await checkIfDraftExists(dependent)) {
            await store.dispatch(`${STORE_NOTIFICATIONS_NAME}/${ACTION_NOTIFICATIONS_ERROR}`, {
                message: 'Draft already exists. Can not generate another automatically.'
            });
        } else {
            // change all references to the new
            var dependencyUpdateMap = {}
            for (var dependency of state.selectedUpgrade.outdated) {
                var old_ref_internal = `plant:/content/${dependency.content_id}/${dependency.header.major_revision}/${dependency.header.minor_revision}`
                var old_ref = `plant://${dependency.library_id}/content/${dependency.content_id}/${dependency.header.major_revision}/${dependency.header.minor_revision}`
                var new_ref = `plant://${dependency.library_id}/content/${dependency.content_id}/${dependency.latest.major_revision}/${dependency.latest.minor_revision}`
                dependencyUpdateMap[old_ref] = new_ref
                dependencyUpdateMap[old_ref_internal] = new_ref
            }
            await updateDependent(dependent, dependencyUpdateMap, approve)
        }
    } else if (state.groupBy == 'dependencies') {
        var { dependency } = state.selectedUpgrade
        var dependencyUpdateMap = {}
        var old_ref_internal = `plant:/content/${dependency.content_id}/${dependency.header.major_revision}/${dependency.header.minor_revision}`
        var old_ref = `plant://${dependency.library_id}/content/${dependency.content_id}/${dependency.header.major_revision}/${dependency.header.minor_revision}`
        var new_ref = `plant://${dependency.library_id}/content/${dependency.content_id}/${dependency.latest.major_revision}/${dependency.latest.minor_revision}`
        dependencyUpdateMap[old_ref] = new_ref
        dependencyUpdateMap[old_ref_internal] = new_ref
        for (var dependent of state.selectedUpgrade.outdated) {
            if (await checkIfDraftExists(dependent)) {
                await store.dispatch(`${STORE_NOTIFICATIONS_NAME}/${ACTION_NOTIFICATIONS_ERROR}`, {
                    message: 'Draft already exists. Can not generate another automatically.'
                });
            } else {
                await updateDependent(dependent, dependencyUpdateMap, approve)
            }
        }
    }
    selectUpgrade(false)
    state.loading = true
    setTimeout(async () => await fetchOutdated(), 1000)
}