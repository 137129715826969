<template>
  <v-app>
    <app-navigation-drawer v-model="drawer" />
    <app-main-bar v-model="drawer" />
    <v-content>
      <v-fade-transition>
        <router-view></router-view>
      </v-fade-transition>
    </v-content>
    <app-user-message />
    <app-loading />
  </v-app>
</template>
<script>
import AppUserMessage from "@/components/app/AppUserMessage"
import AppLoading from "@/components/app/AppLoading"
import AppMainBar from "@/components/app/AppMainBar"
import AppNavigationDrawer from "@/components/app/AppNavigationDrawer"
export default {
  name: "AppLayout",
  components: { AppUserMessage, AppLoading, AppMainBar, AppNavigationDrawer },
  data() {
    return {
      drawer: false
    }
  }
}
</script>

<style>
.h-100 {
  height: 100% !important;
}
.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4) !important;
}
.w-100 {
  width: 100% !important;
}

/* Turn on custom 10px wide scrollbar */
::-webkit-scrollbar {
  width: 10px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-height: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}

/* Turn on custom 12px wide scrollbar */
::-webkit-scrollbar:horizontal {
  height: 12px; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb:horizontal {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  background-clip: padding-box;
  border: 2px solid rgba(0, 0, 0, 0);
  min-width: 10px; /*Prevent it from getting too small */
}
::-webkit-scrollbar-thumb:horizontal:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 100px;
}
</style>
