
import parseBoolean from "./config/parseBoolean"
import css from "./pt-logic-one-shot.css"

export default {
  selector: ".logics-one-shot",
  model: {
    "value": {
      type: "boolean",
      default: false
    },
    "runing": {
      type: "boolean",
      default: false
    }
  },
  watch: function (svgElement) {
    var inputs = svgElement.dataset.inputs.split(",").map(function (elm) { return elm.split(":") })

    var oneshot = function () {
      var i = this.$data[inputs[0][0]]
      if (typeof this.$data[inputs[0][0]].value != "undefined") {
        i.value = parseBoolean(this.$data[inputs[0][0]].value)
      }
      var out = this.$data[svgElement.id]
      out.value = undefined
      if (typeof this.$data[svgElement.id].value != "undefined") {
        out.value = parseBoolean(this.$data[svgElement.id].value)
      }

      if (out.value == i.value) return//(i.value == out.value) || 
      out.value = true
      out.runing = true
      setTimeout(function () {
        out.value = false
        out.runing = false
      }, 3000)
    }

    return {
      [inputs[0][0] + ".value"]: oneshot
    }
  },
  css
}
