<template>
  <span
    >Content <b>{{ custom_id }}</b> has been approved
  </span>
</template>

<script>
export default {
  props: ["value"],
  metadata: {
    color: "green",
    icon: "mdi-check-circle",
    subscription_code: "content-approved"
  },
  computed: {
    custom_id() {
      return this.value.event.payload && this.value.event.payload.metadata
        ? `${this.value.event.payload.metadata.custom_id} (${this.value.event.payload.metadata.content_revision_type})`
        : "";
    }
  }
};
</script>
