// https://www.pdftron.com/documentation/web/guides/hiding-elements/#list-of-elements
export default {
  disabledElements: [
    "annotationCommentButton",
    "fileAttachmentToolButton",
    "linkButton",
    // "menuButton",
    "miscToolGroupButton",
    "notesPanel",
    "notesPanelButton",
    "rubberStampToolButton",
    "signatureToolButton",
    "stampToolButton",
    "thumbnailControl",
    "toggleNotesButton",
    "toolsButton",
  ],
}