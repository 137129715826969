<template>
  <p-panel-properties-group title="On click">
    <p-property-tile title="Action">
      <v-select
        box
        hide-details
        single-line
        :items="availableActions"
        :value="clickAction.type"
        @change="createUpdateAction"></v-select>
    </p-property-tile>
    <template v-if="clickAction.type == 'tooltip'">
      <p-property-tile title="Tooltip Text">
        <v-text-field hide-details single-line box v-model="clickAction.text"></v-text-field>
      </p-property-tile>
      <p-property-tile title="Tooltip Position">
        <v-select
          box
          hide-details
          single-line
          :items="availableTooltipPositions"
          v-model="clickAction.position"
          label="Position"></v-select>
      </p-property-tile>
    </template>
    <p-property-tile v-if="clickAction.type == 'link'" title="Bookmark Target">
      <v-text-field hide-details single-line box v-model="clickAction.bookmark_target"></v-text-field>
    </p-property-tile>
    <template v-if="clickAction.type == 'popup'">
      <v-subheader>Open a popup options</v-subheader>
      <p-property-tile title="Edit Popup">
        <v-btn small outline color="primary" @click="editBlockOverlay = true">Edit</v-btn>
      </p-property-tile>
    </template>
    <template v-if="clickAction.type == 'library'">
      <p-property-tile @click="addFromContentManager" title="Select Content">
        <v-btn small outline color="primary" @click="addFromContentManager">Select...</v-btn>
      </p-property-tile>
      <p-property-tile title="Custom id">
        {{ Object.entries(clickAction.content_ref).length === 0 ? "--" : getCustomId(clickAction.content_ref) }}
      </p-property-tile>
      <p-property-tile title="Name">
        {{ Object.entries(clickAction.content_ref).length === 0 ? "--" : getTitle(clickAction.content_ref) }}
      </p-property-tile>
      <p-property-tile title="Revision">
        {{
          Object.entries(clickAction.content_ref).length === 0
            ? "--"
            : `${getMajorVersion(clickAction.content_ref)}.${getMinorVersion(clickAction.content_ref)}`
        }}
      </p-property-tile>
      <p-property-tile title="Show Full Width">
        <v-checkbox hide-details v-model="clickAction.fullWidth"></v-checkbox>
      </p-property-tile>
      <p-property-tile v-if="isVideo" title="Autoplay (Video without annotations)">
        <v-switch v-model="clickAction.view.autoplay"></v-switch>
      </p-property-tile>
      <p-property-tile icon="mdi-timer" title="Delay (seconds)" v-if="clickAction.view.autoplay">
        <p-panel-input-number v-model="clickAction.view.delay" :min="0" />
      </p-property-tile>
    </template>
    <template v-if="clickAction.type == 'libraryNewTab'">
      <p-property-tile @click="addFromContentManager" title="Select Content">
        <v-btn small outline color="primary" @click="addFromContentManager">Select...</v-btn>
      </p-property-tile>
      <p-property-tile title="Custom id">
        {{ Object.entries(clickAction.content_ref).length === 0 ? "--" : getCustomId(clickAction.content_ref) }}
      </p-property-tile>
      <p-property-tile title="Name">
        {{ Object.entries(clickAction.content_ref).length === 0 ? "--" : getTitle(clickAction.content_ref) }}
      </p-property-tile>
      <p-property-tile title="Revision">
        {{
          Object.entries(clickAction.content_ref).length === 0
            ? "--"
            : `${getMajorVersion(clickAction.content_ref)}.${getMinorVersion(clickAction.content_ref)}`
        }}
      </p-property-tile>
    </template>
    <template v-if="clickAction.type == 'event'">
      <v-subheader>Event Options</v-subheader>
      <p-property-tile title="Event">
        <v-select
          box
          hide-details
          single-line
          :items="['submit', 'open:objectives', 'quiz:submit']"
          v-model="clickAction.event"></v-select>
      </p-property-tile>
    </template>
    <p-property-tile v-if="clickAction.type == 'externalLink'" title="External link">
      <v-text-field hide-details single-line box v-model="clickAction.url"></v-text-field>
    </p-property-tile>
    <template v-if="clickAction.type == 'downloadAttachment'">
      <p-property-tile title="Select attachment">
        <div v-if="attachments.length === 0">No attachments found</div>
        <v-select v-else box hide-details single-line :items="attachments" v-model="clickAction.attachment"></v-select>
        <div v-if="clickAction.attachment">
          {{ clickAction.attachment }}
        </div>
      </p-property-tile>
      <div v-if="clickAction.attachment && clickAction.attachment.name" class="ml-4">
        <v-layout row>
          <v-flex sm2>
            <p-file-icon class="mr-2" :fileName="clickAction.attachment.name"></p-file-icon>
          </v-flex>
          <v-flex sm8>
            <p class="mt-2 ellipsis-text">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ clickAction.attachment.name }}</span>
                </template>
                <span>{{ clickAction.attachment.name }}</span>
              </v-tooltip>
            </p>
          </v-flex>
          <v-flex sm2>
            <v-btn class="mr-2" icon flat>
              <a :href="clickAction.attachment.file.url" :download="clickAction.attachment.name">
                <v-icon>mdi-download-box-outline</v-icon>
              </a>
            </v-btn>
          </v-flex>
        </v-layout>
        <p v-if="attachmentDeleted"><i>*deleted from attachments but still associated to download action </i></p>
      </div>
    </template>
  </p-panel-properties-group>
</template>

<script>
import PPanelPropertiesGroup from "../../utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../../utils/PPropertyTile.vue"
import PPanelInputNumber from "../../utils/PPanelInputNumber.vue"
import { Extensions } from "plant-common"
import contentNotAllowed from "../../../contentNotAllowed"
import {
  BlockActionTooltip,
  BlockActionLink,
  BlockActionExternalLink,
  BlockActionPopup,
  BlockActionLibraryContentPopup,
  BlockActionLibraryContentNewTab,
  BlockActionEvent,
  BlockActionClose,
  BlockActionDownloadAttachment
} from "./blockActionClass.js"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { getCustomId, getMajorVersion, getMinorVersion, getTitle, getType } from "../../../../../../utils/dbTool"
import _ from "lodash"
import { PFileIcon } from "plant-common"

export default {
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelPropertiesGroup,
    PFileIcon,
    PPanelInputNumber
  },
  inject: ["blockController", "lessonPlanController"],
  data: () => ({
    editBlockOverlay: false,
    availableActions: [
      {
        text: "No Action",
        value: "none"
      },
      {
        text: "Show Tooltip",
        value: "tooltip"
      },
      {
        text: "Go to slide",
        value: "link"
      },
      {
        text: "Open a popup",
        value: "popup"
      },
      {
        text: "Content in modal",
        value: "library"
      },
      {
        text: "Content in new tab",
        value: "libraryNewTab"
      },
      {
        text: "Send Event",
        value: "event"
      },
      {
        text: "Close Popup",
        value: "close"
      },
      {
        text: "Go to external link",
        value: "externalLink"
      },
      {
        text: "Download attachment",
        value: "downloadAttachment"
      }
    ],
    availableTooltipPositions: [
      {
        text: "Top",
        value: "top"
      },
      {
        text: "Bottom",
        value: "bottom"
      },
      {
        text: "Left",
        value: "left"
      },
      {
        text: "Right",
        value: "right"
      }
    ]
  }),
  created() {
    // Set view for library click action type if it is not set
    if (this.clickAction && this.clickAction.type === "library" && !this.clickAction.view) {
      this.$set(this.clickAction, "view", {})
    }
  },
  computed: {
    clickAction() {
      var actions = this.value.data.actions || this.value.actions
      var clickAction = actions ? actions.find(action => action.trigger == "click") : false
      return clickAction || { type: "none" }
    },
    attachments() {
      return this.lessonPlanController.externalAttachments.map(atch => {
        return {
          text: atch.name,
          value: atch
        }
      })
    },
    attachmentDeleted() {
      if (this.clickAction.attachment && this.clickAction.attachment.name) {
        return !this.attachments.find(attch => attch.text === this.clickAction.attachment.name)
      } else {
        return false
      }
    },
    isVideo() {
      return this.clickAction && this.clickAction.content_ref && getType(this.clickAction.content_ref) === "video"
    }
  },
  watch: {
    "blockController.overlay"() {
      if (!this.blockController.overlay && !_.isEmpty(this.blockController)) {
        this.editBlockOverlay = false
        this.blockController.$emit("overlayActive", { type: null, overlay: null })
      }
    },
    editBlockOverlay() {
      if (this.editBlockOverlay && !_.isEmpty(this.blockController)) {
        this.blockController.overlay = this.clickAction
        this.blockController.overlay.parent = this.value.id
        this.blockController.$emit("overlayActive", { type: this.clickAction.type, overlay: this.clickAction })
      }
    },
    "clickAction.view.autoplay": {
      handler() {
        if (this.clickAction.view.autoplay && !this.clickAction.view.delay) {
          this.$set(this.clickAction.view, "delay", 0)
        }
      }
    }
  },
  methods: {
    createUpdateAction(type) {
      var BlockActionMap = {
        tooltip: BlockActionTooltip,
        link: BlockActionLink,
        popup: BlockActionPopup,
        library: BlockActionLibraryContentPopup,
        libraryNewTab: BlockActionLibraryContentNewTab,
        event: BlockActionEvent,
        close: BlockActionClose,
        externalLink: BlockActionExternalLink,
        downloadAttachment: BlockActionDownloadAttachment
      }
      var actions = this.value.data.actions || this.value.actions

      if (!actions) this.$set(this.value.data, "actions", [])

      // Delete previous action with that trigger
      var index = actions ? actions.findIndex(action => action.trigger == "click") : -1
      if (index > -1) actions.splice(index, 1)

      // Add new action
      var BlockAction = BlockActionMap[type]
      if (BlockAction) {
        actions.push(new BlockAction({ trigger: "click" }))
      }
    },
    async addFromContentManager() {
      const contentTypes = Object.keys(Extensions).filter(el => {
        return contentNotAllowed.indexOf(el) < 0
      })
      var newContentRef = await PServiceContent.selectContent({ type: contentTypes })
      if (!newContentRef) return
      this.clickAction.content_ref = newContentRef
    },
    getMajorVersion: getMajorVersion,
    getMinorVersion: getMinorVersion,
    getTitle: getTitle,
    getCustomId: getCustomId
  }
}
</script>

<style scoped>
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>