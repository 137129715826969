
<template>
  <v-select
    :items="items"
    item-text="_id"
    item-value="_id"
    :error="errorLoadingSchemas"
    :error-messages="
      errorLoadingSchemas ? 'Cannot find items of type: ' + type : ''
    "
    :loading="loadingSchemas"
  >
  </v-select>
</template>

<script>
import PServiceContent from "plant-common/src/services/PServiceContent";
export default {
  name: "ContentSeletorDropdown",
  props: ["value", "type"],
  data() {
    return {
      items: [],
      loadingSchemas: true,
      errorLoadingSchemas: false
    };
  },
  mounted() {
    PServiceContent.findContent({ type: this.type })
      .then(res => {
        res.forEach(s => this.items.push(s));
        this.loadingSchemas = false;
      })
      .catch(e => (this.errorLoadingSchemas = true));
  }
};
</script>