export const STORE_MODULE_COMMENTS = "content/comments";

export const STATE_CURRENT_LOCATION = "current_location";
export const STATE_CURRENT_FILTER = "current_filter";
export const STATE_IS_COMMENT = "isComment";
export const STATE_NAV_COMMENT = "navigationComment";
export const STATE_SELECTED_COMMENT = "selectedComment";

export const GETTER_ACTIVE_USER = "activeUser";
export const GETTER_COMMENTS = "comments";

export const MUTATION_SET_CURRENT_LOCATION = "setCurrentLocation";
export const MUTATION_SET_REPLY_ITEM_DRAFT = "setReplyItemDraft";
export const MUTATION_SET_CURRENT_FILTER = "setCurrentFilter";
export const MUTATION_SET_IS_COMMENT = "setIsComment";
export const MUTATION_SET_NAV_COMMENT = "setNavigationComment";
export const MUTATION_SET_SELECTED_COMMENT = "setSelectedComment";

export const ACTION_SAVE_CURRENT_LOCATION = "saveCurrentLocation";
export const ACTION_UPDATE_COMMENT_STATE = "updateCommentState";
export const ACTION_SEND_COMMENTS = "sendComments"
export const ACTION_SEND_REPLY = "sendReply"
export const ACTION_CURRENT_FILTER = "saveCurrentFilter"
export const ACTION_SAVE_IS_COMMENT = "saveIsComment"
export const ACTION_DELETE_COMMENT = "deleteComment"
export const ACTION_UPDATE_COMMENT = "updateComment"
export const ACTION_UPDATE_NAV_COMMENT = "updateNavigationComment"
export const ACTION_UPDATE_SELECTED_COMMENT = "updateSelectedComment"