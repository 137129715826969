<template>
	<fragment>
		<p-panel-properties-group title="Controls">
			<!-- Color -->
			<p-property-tile icon="mdi-water" title="Color">
				<preset-input-color
					:value="value.data.controls.color"
					@input="value.data.controls.color = $event"
				></preset-input-color>
			</p-property-tile>

			<!-- Size -->
			<p-property-tile icon="mdi-format-size" title="Size">
				<p-panel-input-select :items="choices" v-model="value.data.controls.size" item-text="text" />
			</p-property-tile>

			<!-- Autoplay -->
			<p-property-tile icon="mdi-play" title="Autoplay">
				<v-checkbox
					small
					:ripple="false"
					hide-details
					v-model="value.data.autoplay"
					class="p-video-panel-autoplay"
				></v-checkbox>
			</p-property-tile>
			<!-- Loop -->
			<p-property-tile icon="mdi-autorenew" title="Loop">
				<v-checkbox
					small
					:ripple="false"
					hide-details
					v-model="value.data.loop"
					class="p-audio-panel-autoplay"
				></v-checkbox>
			</p-property-tile>
			<!-- Delay in Autoplay -->
			<p-property-tile icon="mdi-timer" title="Delay (seconds)" v-if="value.data.autoplay">
				<p-panel-input-number v-model="value.data.delay" :min="0" />
			</p-property-tile>
			<v-alert :value="true" color="info" icon="info" outline class="ma-2">
				<b>Note:</b> Autoplay audios in Chrome works once the user interacts with the page, so if the autoplay is in the
				first slide, it might not work.
			</v-alert>
		</p-panel-properties-group>
		<p-panel-properties-group title="Subtitles">
			<p-property-tile
				v-for="(track, key) in subtitlesLang"
				:key="`${key}`"
				icon="mdi-subtitles-outline"
				:iconColor="isLanguageSetUp(key) ? 'primary' : undefined"
				:title="subtitlesLang[key]"
			>
				<v-tooltip v-if="isLanguageSetUp(key)" bottom>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" flat icon class="lp-outline-panel-actions-button" @click="deleteSubtitle(key)">
							<v-icon color="red">mdi-delete-outline</v-icon>
						</v-btn>
					</template>
					<span>Remove {{ subtitlesLang[key] }} subtitles</span>
				</v-tooltip>
				<v-tooltip v-else bottom>
					<template v-slot:activator="{ on }">
						<v-btn v-on="on" flat icon class="lp-outline-panel-actions-button" @click="addSubtitle(key)">
							<v-icon>add</v-icon>
						</v-btn>
					</template>
					<span>Add {{ subtitlesLang[key] }} subtitles</span>
				</v-tooltip>
			</p-property-tile>
			<div v-if="subtitlesSetUp.length > 0" class="pt-3">
				<span class="ma-3 lp-outline-panel-subtitle"> Subtitles management </span>
				<p-property-tile
					v-for="sub in subtitlesSetUp"
					:key="`sub-${sub[0]}`"
					icon="mdi-subtitles-outline"
					:iconColor="isLanguageSetUp(sub[0]) ? 'primary' : undefined"
					:title="subtitlesLang[sub[0]]"
				>
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<v-btn v-on="on" flat icon class="lp-outline-panel-actions-button" @click="downloadSubtitle(sub[0])">
								<v-icon>mdi-download-outline</v-icon>
							</v-btn>
						</template>
						<span>Download {{ subtitlesLang[sub[0]] }} subtitles</span>
					</v-tooltip>
				</p-property-tile>
			</div>
		</p-panel-properties-group>
	</fragment>
</template>

<script>
import PresetInputColor from '../../components/slides/styles/PresetInputColor';
import PPropertyTile from '../_components/utils/PPropertyTile.vue';
import PPanelPropertiesGroup from '../_components/utils/PPanelPropertiesGroup.vue';
import PPanelInputSelect from '../_components/utils/PPanelInputSelect.vue';
import PPanelInputNumber from '../../blocks/_components/utils/PPanelInputNumber.vue';
import { Fragment } from 'vue-fragment';

import { availableLanguages } from './availableLanguages.js';
import { PFile } from 'plant-common/src/utils/models';
import { uploadFile } from 'plant-common/src/utils/index.js';

export default {
	components: {
		PPropertyTile,
		PPanelPropertiesGroup,
		PresetInputColor,
		PPanelInputSelect,
		PPanelInputNumber,
		Fragment
	},
	props: ['value'],
	data() {
		return {
			choices: [
				{ text: 'Big', value: 25 },
				{ text: 'Small', value: 20 }
			],
			subtitlesLang: availableLanguages
		};
	},
	computed: {
		subtitlesSetUp() {
			return this.value.data.subtitles ? Object.entries(this.value.data.subtitles).filter(sub => sub[1]) : [];
		}
	},
	methods: {
		async addSubtitle(lang) {
			const { file } = await uploadFile('.vtt');

			this.checkSubtitleProp();
			let newFile = new PFile(file);
			await newFile.upload();
			this.$set(this.value.data.subtitles, lang, {});
			this.value.data.subtitles[lang] = newFile;
		},
		checkSubtitleProp() {
			if (!this.value.data.hasOwnProperty('subtitles')) {
				this.$set(this.value.data, 'subtitles', {});
			}
		},
		deleteSubtitle(lang) {
			this.value.data.subtitles[lang] = null;
		},
		isLanguageSetUp(lang) {
			return this.value.data.subtitles ? this.value.data.subtitles[lang] : false;
		},
		downloadSubtitle(key) {
			window.open(this.value.data.subtitles[key].url, '_blank');
		}
	}
};
</script>

<style>
.p-video-panel-autoplay > .v-input__control {
	margin-left: auto;
}

.lp-outline-panel-subtitle {
	font-size: 13px;
}
</style>
