<template>
  <div>
    <p-property-tile title="Horizontal aligment" icon="mdi-format-horizontal-align-center">
      <v-btn-toggle v-model="horizontal" mandatory>
        <v-btn flat small>
          <v-icon>format_align_left</v-icon>
        </v-btn>
        <v-btn flat small>
          <v-icon>format_align_center</v-icon>
        </v-btn>
        <v-btn flat small>
          <v-icon>format_align_right</v-icon>
        </v-btn>
        <v-btn flat small v-if="showJustify">
          <v-icon>mdi-format-align-justify</v-icon>
        </v-btn>
      </v-btn-toggle>
    </p-property-tile>
    <p-property-tile title="Vertical aligment" icon="mdi-format-vertical-align-center">
      <v-btn-toggle v-model="vertical" mandatory>
        <v-btn flat small class="transparent">
          <v-icon>mdi-format-align-top</v-icon>
        </v-btn>
        <v-btn flat small class="transparent">
          <v-icon>mdi-format-align-middle</v-icon>
        </v-btn>
        <v-btn flat small class="transparent">
          <v-icon>mdi-format-align-bottom</v-icon>
        </v-btn>
      </v-btn-toggle>
    </p-property-tile>
  </div>
</template>

<script>
import PPropertyTile from "../utils/PPropertyTile.vue"
export default {
  props: ["data", "showJustify"],
  components: { PPropertyTile },
  data() {
    return {
      horizontal: 1,
      vertical: 1,
      alignmentToToggleHtal: {
        left: 0,
        center: 1,
        right: 2,
        justify: 3
      },
      toggleToAlignmentHtal: {
        0: "left",
        1: "center",
        2: "right",
        3: "justify"
      },
      alignmentToToggleVcal: {
        "flex-start": 0,
        center: 1,
        "flex-end": 2
      },
      toggleToAlignmentVcal: {
        0: "flex-start",
        1: "center",
        2: "flex-end"
      }
    }
  },
  mounted() {
    this.horizontal = this.alignmentToToggleHtal[this.data.textAlign]
    this.vertical = this.alignmentToToggleVcal[this.data.alignItems]
  },
  watch: {
    horizontal() {
      this.data.textAlign = this.toggleToAlignmentHtal[this.horizontal]
    },
    vertical() {
      this.data.alignItems = this.toggleToAlignmentVcal[this.vertical]
    }
  }
}
</script>

<style>
.lp-text-alignment-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.lp-text-alignment-form p {
  margin-bottom: 0;
}
</style>