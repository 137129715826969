import Vue from "vue"
import moveBlock from "./moveBlock.js"
import deleteBlock from "./deleteBlock.js"
import getBlockById from "./getBlockById.js"
import setBlockName from "./setBlockName.js"
import buildSelectionTree from "./buildSelectionTree.js"

export default function (vm) {
  return new Vue({
    data() {
      return {
        scale: 1,
        selectionTree: [],
        blocks: vm.blocks,
        overlay: null,
        overlayVariables: null,
        gridSize: vm.gridSize,
        availableStencils: vm.availableStencils,
        dark: vm.dark,
        origin: { x: 0, y: 0 },
        viewerWidth: 0,
        viewerHeight: 0
      }
    },
    mixins: [moveBlock, deleteBlock, getBlockById, setBlockName, buildSelectionTree],
    watch: {
      blocks: {
        immediate: true,
        deep: true,
        handler() {
          this.buildSelectionTree()
        }
      }
    },
    methods: {
      getParent(blockId) {
        // return parent block OR false
        function findParent(parent, blocks, id) {
          var foundParent
          for (var block of blocks) {
            if (id == block.id) {
              foundParent = parent
            } else if (block.hasOwnProperty("blocks")) {
              // If block have "blocks" means that it is not a valid block type (its parent is the valid one)
              foundParent = findParent(parent, block.blocks, id)
            } else if (block.type === "carousel") {
              foundParent = findParent(block, block.data.cards, id)
            } else if (block.type === "tabs") {
              foundParent = findParent(block, block.data.tabs, id)
            } else if (block.type === "flashCard") {
              foundParent = findParent(block, block.data.blocks, id)
              if (!foundParent) {
                foundParent = findParent(block, block.data.overlays.answer.blocks, id)
              }
            } else if (block.type === "libraryContentCustom") {
              foundParent = findParent(block, block.data.blocks, id)
              if (!foundParent) {
                foundParent = findParent(block, block.data.overlays.expanded.blocks, id)
              }
            } else if (
              block.type === "contentMultipleChoiceQuestion" ||
              block.type === "contentMultipleChoiceQuestionTemplate" ||
              block.type === "adaptiveLearningResult"
            ) {
              foundParent = findParent(block, block.data.blocks, id)
              if (!foundParent && block.data.overlays) {
                // Try to find block in correct overlay
                foundParent = findParent(block, block.data.overlays.correct.blocks, id)
              }
              if (!foundParent && block.data.overlays) {
                // Try to find block in incorrect overlay
                foundParent = findParent(block, block.data.overlays.incorrect.blocks, id)
              }
            }
            if (block.actions && !foundParent) {
              var popup = block.actions.find(action => action.type == "popup")
              if (popup) {
                foundParent = findParent(block, popup.blocks, id)
              }
            }
            if (foundParent) return foundParent
          }
        }

        return findParent(false, this.blocks, blockId)
      },
      getBlockByState(state) {
        function getBlockIdByState(elements, state) {
          for (const element of elements) {
            if (state == element.state) {
              return element.id
            } else if (element.hasOwnProperty("children")) {
              let blockId = null
              blockId = getBlockIdByState(element.children, state)
              if (blockId) return blockId
            }
          }
        }

        let foundBlockId = getBlockIdByState(this.selectionTree, state)
        let foundBlock = null
        if (foundBlockId) {
          foundBlock = this.getBlockById(foundBlockId)
        }

        return foundBlock
      },
      updateState(elements, state, id) {
        for (var element of elements) {
          if (typeof id === "undefined" || element.id === id) {
            element.state = state
            this.$emit(`state-changed:${element.id}`, state)

            if (element.id === id) {
              const blockFound = this.getBlockById(id)
              if (blockFound) {
                this.$emit(`selection-changed`, { block: blockFound, state })
              }
            } else if (element.children) {
              if (state === "primary-selected" || state === "secondary-selected") {
                this.updateState(element.children, "secondary-selected")
              } else if (state === "not-selected") {
                this.updateState(element.children, "not-selected")
              } else if (state === "edit-content") {
                this.updateState(element.children, "not-selected")
              }
            }
          } else if (element.children && element.children.length > 0) {
            this.updateState(element.children, state, id)
          }
        }
      },
      selectionAdd(id) {
        // Parse tree and if block.id == id
        // Change block.state = 'primary-selected' to
        // for all children and subchildren block.children[].state= 'secondary-selected'
        this.setParentsAsEdit(id)
        this.updateState(this.selectionTree, "primary-selected", id)
      },
      selectionEdit(id) {
        // Parse tree and if block.id == id
        // Change block.state = 'edit-content' to
        // for all children and subchildren block.children[].state= 'secondary-selected'
        this.setParentsAsEdit(id)
        this.updateState(this.selectionTree, "edit-content", id)
      },
      setParentsAsEdit(id) {
        // set "edit-content" to all block's parent starting from the last one first
        var parent = this.getParent(id)
        if (parent) {
          this.selectionEdit(parent.id)
        }
      },
      selectionRemove(id) {
        // Parse tree and if block.id == id
        // Change block.state = 'not-selected' to
        // for all children and subchildren block.children[].state= 'not-selected'

        this.updateState(this.selectionTree, "not-selected", id)
      },
      selectionClear() {
        // Parse all tree and set block.state = 'not-selected'

        this.updateState(this.selectionTree, "not-selected")
        this.$emit(`selection-changed`, { block: null })
      }
    }
  })
}
