<template>
  <div class="block-placeholder-container" :style="`cursor: ${mode === 'edit' ? 'pointer' : 'default'}`">
    <img :src="require(`./placeholder_${img}.svg`)" class="block-placeholder-img" />
    <v-btn :disabled="mode === 'view'" outline large primary class="block-placeholder-text">
      <v-icon left>{{ icon }}</v-icon>
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BlockPlaceholder",
  props: ["img", "icon", "text", "mode"]
}
</script>

<style>
.block-placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

.block-placeholder-img {
  height: auto;
}
.block-placeholder-text {
  margin-top: 24px !important;
}
</style>
