<template>
  <span
    >You have been assigned as an <b>{{ value.event.payload.new_role }}</b> for
    content
    <b>{{ custom_id }}</b>
  </span>
</template>

<script>
export default {
  props: ["value"],
  metadata: {
    color: "blue",
    icon: "mdi-account-plus",
    subscription_code: "contributor-assigned-myself"
  },
  computed: {
    custom_id() {
      return this.value.event.payload && this.value.event.payload.metadata
        ? `${this.value.event.payload.metadata.custom_id} (${this.value.event.payload.metadata.content_revision_type})`
        : "";
    }
  }
};
</script>
