<template>
  <div class="lp-block-video-record">
    <!-- Audio -->
    <div>
      <div v-if="value.data.audio">
        <block-audio-viewer :value="value" />
      </div>
      <div v-else>
        <!-- container in video-recorder to avoid bug after recording -->
        <div>
          <audio-recorder :width="value.size.width" :height="value.size.height" @finishRecord="saveAudio" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PFile } from "plant-common/src/utils/models"
import AudioRecorder from "./AudioRecorder.vue"
import BlockAudioViewer from "./BlockAudioViewer.vue"

export default {
  name: "BlockAudioRecord",
  props: ["value"],
  components: { AudioRecorder, BlockAudioViewer },
  methods: {
    async saveAudio(blob) {
      const file = new File([blob], `record-${blob.lastModifiedDate}.mp3`)
      var newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.value.data, "audio", newFile)
      if (this.value.data.audio && !this.value.data.audio.type) {
        this.$set(this.value.data.audio, "type", "audio/mp3")
      }
    }
  }
}
</script>