<template>
  <toolbar-button
    v-show="selected && selected.length != 0"
    icon="device_hub"
    tooltip="Create new revision"
    @click="createNewRevision"
  ></toolbar-button>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PtModal } from "plant-common";
import ToolbarButton from "./ToolbarButton";
import {
  STORE_EXPLORER_NAME,
  STATE_SELECTED,
  ACTION_CLEAN_SELECTED,
  ACTION_OPEN_FOLDER,
  ACTION_CREATE_DRAFT_FROM_ITEMREVISION_LIST
} from "@/store_constants/explorer";
export default {
  mixins: [PtModal],
  components: { ToolbarButton },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      selected: STATE_SELECTED
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      cleanSelected: ACTION_CLEAN_SELECTED,
      openFolder: ACTION_OPEN_FOLDER,
      createDraftFromItemRevisionList:
        ACTION_CREATE_DRAFT_FROM_ITEMREVISION_LIST
    }),
    createNewRevision() {
      //itemRevisions selected folders ignored
      this.createDraftFromItemRevisionList(this.selected.filter(e => e))
        .then(itemsDraft => {
          console.log("itemDraft generated: ", itemsDraft);
        })
        .catch(err => {
          console.log(err);
        });

      //clean the selection
      this.cleanSelected();
      this.openFolder();
    }
  }
};
</script>
