<template>
  <div class="shape-control-handle" :style="`transform: translate(${x}px, ${y}px)`" @mousedown="move"></div>
</template>

<script>
export default {
  props: {
    angle: {
      type: Number
    },
    x: {
      type: Number
    },
    y: {
      type: Number
    },
    flipX: {
      type: Boolean
    },
    flipY: {
      type: Boolean
    },
    xRange: {
      type: Array,
      default: () => [-Infinity, +Infinity]
    },
    yRange: {
      type: Array,
      default: () => [-Infinity, +Infinity]
    }
  },
  inject: ["blockController"],
  methods: {
    move(evt) {
      evt.preventDefault()
      evt.target.style.willChange = "transform"
      var [lastPositionX, lastPositionY] = [evt.clientX, evt.clientY]

      var stopMove = evt => {
        evt.preventDefault()
        evt.target.style.willChange = "auto"
        window.removeEventListener("mousemove", mousemoveEventHandler)
      }
      var ensureRange = (value, min, max) => {
        if (value < min) return min
        if (value > max) return max
        return value
      }
      var mousemoveEventHandler = evt => {
        evt.preventDefault()
        var { clientX, clientY } = evt
        var angleRads = this.angle * Math.PI / 180
        var cosAngle = Math.cos(angleRads)
        var sinAngle = Math.sin(angleRads)
        const flipX = this.flipX ? -1 : 1
        const flipY = this.flipY ? -1 : 1
        var deltaX = ((clientX - lastPositionX) * cosAngle + (clientY - lastPositionY) * sinAngle) / this.blockController.scale * flipX
        var deltaY = ((clientY - lastPositionY) * cosAngle - (clientX - lastPositionX) * sinAngle)  / this.blockController.scale * flipY
        var newX = ensureRange(this.x + deltaX, this.xRange[0], this.xRange[1])
        var newY = ensureRange(this.y + deltaY, this.yRange[0], this.yRange[1])
        
        this.$emit("update:x", newX)
        this.$emit("update:y", newY)
        if (newX > this.xRange[0] && newX < this.xRange[1]) {
          lastPositionX = clientX
        }
        if (newY > this.yRange[0] && newY < this.yRange[1]) {
          lastPositionY = clientY
        }
      }
      window.addEventListener("mouseup", stopMove)
      window.addEventListener("mousemove", mousemoveEventHandler)
    }
  }
}
</script>

<style>
.shape-control-handle {
  will-change: transform;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid darkslategray;
  box-sizing: border-box;
  background: orange;
  border: 2px solid #fff;
  z-index: 1000;
  margin-top: -10px;
  margin-left: -10px;
  cursor: grab;
}
</style>
