/**
 * A recorded or upload audio.
 */
import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeDoubleArrow"
    this.size.width = 600
    this.size.height = 400
    this.data = {
      controls :{
        slope: 0.6,
        thick: 0.25
      },
      style: {
        fill: "#000000ff",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
