<template>
  <p-panel title="Block Animation" v-if="selectedBlock">
    <template #toolbar>
      <v-toolbar-title class="subheading">
        Block Arrange <span v-if="selectedBlock && selectedBlock.type">({{ selectedBlock.type }})</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
    </template>
    <arrange-form :value="selectedBlock" />
  </p-panel>
</template>

<script>
import ArrangeForm from "./ArrangeForm"
import ProtectionForm from "./ProtectionForm.vue"
import blocks from "../../index.js"
import PPropertyTile from "../utils/PPropertyTile.vue"
import PPanelPropertiesGroup from "../utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../utils/PPanelProperties.vue"
import PPanelInputNumber from "../utils/PPanelInputNumber.vue"
import PPanel from "../utils/PPanel.vue"
import ItemStyle from "../../../components/slides/styles/ItemStyle.vue"
import { PCodeEditor } from "plant-common"

export default {
  name: "BlockArrange",
  props: ["selectedBlock"],
  components: {
    PCodeEditor,
    ArrangeForm,
    ProtectionForm,
    PPropertyTile,
    PPanelPropertiesGroup,
    PPanelProperties,
    PPanel,
    PPanelInputNumber,
    ItemStyle
  },
  data() {
    return {
      panel: [true, false],
      dialog: false
    }
  },
  computed: {
    macrosActive() {
      return localStorage.PlantUserMacrosActive == "true"
    },
    selectedBlockPanel() {
      if (!this.selectedBlock) return false
      var { type } = this.selectedBlock
      if (blocks[type] && blocks[type].Panel) {
        return blocks[type].Panel
      } else {
        return false
      }
    }
  },
  methods: {
    updateLineSizePosition(beginOrEnd, XorY, val) {
      this.selectedBlock.data[beginOrEnd][XorY] = parseInt(val)
      this.selectedBlock.position.x = Math.min(this.selectedBlock.data.begin.x, this.selectedBlock.data.end.x)
      this.selectedBlock.position.y = Math.min(this.selectedBlock.data.begin.y, this.selectedBlock.data.end.y)
      this.selectedBlock.size.width = Math.max(
        1,
        Math.abs(this.selectedBlock.data.begin.x - this.selectedBlock.data.end.x)
      )
      this.selectedBlock.size.height = Math.max(
        1,
        Math.abs(this.selectedBlock.data.begin.y - this.selectedBlock.data.end.y)
      )
    }
  },
  watch: {
    "selectedBlock.rotation"(newVal) {
      if (newVal === "") {
        this.selectedBlock.rotation = 0
      }
    }
  }
}
</script>

<style>
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
