const context = require.context("./", true, /\.\/.*\/index\.js$/);

const obj = {};
context.keys().forEach((key) => {
  const [, blockName] = key.split("/");
  obj[blockName] = context(key).default;
});

// Add Block, Panel and Toolbar components
const contextComponents = require.context(
  "./",
  true,
  /\.\/.*\/(Block|Toolbar|Panel)\.vue$/
);
contextComponents.keys().forEach((key) => {
  const [, blockName, filename] = key.split("/");
  obj[blockName][filename.replace(/\..*$/, "")] = contextComponents(
    key
  ).default;
});

// Add class
const contextClass = require.context("./", true, /\.\/.*\/class.js$/);
contextClass.keys().forEach((key) => {
  const [, blockName, filename] = key.split("/");
  var C = contextClass(key).default;
  obj[blockName].block = new C();
  obj[blockName].class = C
});

export default obj;
