<template>
  <span
    >Workflow state from <b>{{ custom_id }}</b> has been changed to
    <b>{{ value.event.payload.workflow.state }}</b>
  </span>
</template>

<script>
export default {
  props: ["value"],
  metadata: {
    color: "orange",
    icon: "mdi-account-arrow-right",
    subscription_code: "workflow-state-change"
  },
  computed: {
    custom_id() {
      return this.value.event.payload && this.value.event.payload.metadata
        ? `${this.value.event.payload.metadata.custom_id} (${this.value.event.payload.metadata.content_revision_type})`
        : "";
    }
  }
};
</script>
