<template>
  <div class="h-100">
    <library-selector name="Collection List" />
    <v-container
      :style="`margin-left:230px;margin-right:0px;margin-bottom:0px;padding:0px;height:100%;max-width: calc(100% - 230px) !important;`">
      <library-selector-card name="Collection List" v-if="!plant_id || !library_id" />
      <router-view />
    </v-container>
  </div>
</template>
<script>
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "@/store_constants/library"
import { mapState, mapActions } from "vuex"
import LibrarySelector from "@/components/LibrarySelector.vue"
import LibrarySelectorCard from "@/components/LibrarySelectorCard.vue"

export default {
  components: { LibrarySelector, LibrarySelectorCard },
  created() {
    if (this[STATE_PLANT_ID])
      this.$router.push({
        name: "Collection List",
        params: { library_id: this[STATE_PLANT_ID] }
      })
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    }),
    library_id() {
      return this.$route.params.library_id
    }
  }
}
</script>
