<template>
  <p-dialog
    title="Button Icon"
    cancel-text="Save"
    cancel-color="primary"
    icon="mdi-emoticon-outline"
    :value="openDialog"
    @input="close"
    width="800">
    <template v-slot:activator>
      <slot name="activator" v-bind="{ open }" />
    </template>

    <div class="lp-toolbar-button-icon-reset">
      <v-flex xs9 px-5>
        <v-text-field label="Search..." clearable append-icon="mdi-magnify" v-model="searchIcon"></v-text-field>
      </v-flex>
      <v-flex xs3 class="text-center">
        <v-btn flat small color="primary" @click="selectIcon(null)">Remove icon</v-btn>
      </v-flex>
    </div>
    <v-container grid-list-md class="lp-toolbar-button-icon-container">
      <v-layout row wrap height="500px">
        <v-flex xs1 v-for="(icon, key) in visibleIcons" :key="key">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                depressed
                class="lp-toolbar-button-icon-button"
                @click="selectIcon(icon)"
                :class="{ 'v-btn--active': icon === value.data.icon }">
                <v-card-text>
                  <v-icon size="20">{{ icon }}</v-icon>
                </v-card-text>
              </v-btn>
            </template>
            <span>{{ icon }}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-container>
    <div class="text-xs-center">
      <v-pagination
        v-model="page"
        :length="filteredIcons.length > 0 ? Math.ceil(filteredIcons.length / perPage) : 1"></v-pagination>
    </div>
  </p-dialog>
</template>

<script>
import { PDialog } from "plant-common"
import mdicons from "./mdicons"

export default {
  components: {
    PDialog
  },
  props: ["value"],
  data: () => ({
    openDialog: false,
    page: 1,
    perPage: 72,
    searchIcon: "",
    icons: mdicons
  }),
  computed: {
    filteredIcons() {
      let fIcons = this.icons
      if (this.searchIcon && this.searchIcon.length > 0) {
        fIcons = this.icons.filter(icon => icon.includes(this.searchIcon))
      }
      return fIcons
    },
    visibleIcons() {
      return this.filteredIcons.slice((this.page - 1) * this.perPage, this.page * this.perPage)
    }
  },
  methods: {
    open() {
      this.openDialog = true
    },
    selectIcon(icon) {
      this.value.data.icon = icon
    },
    close() {
      this.openDialog = false
      this.searchIcon = ""
    }
  },
  watch: {
    searchIcon() {
      console.log("modifi")
      this.page = 1
    }
  }
}
</script>

<style>
.lp-toolbar-button-icon-button.v-btn {
  min-width: 10px;
  padding: 0;
}
.lp-toolbar-button-icon-button.v-btn--active {
  background-color: #1976d2 !important;
  color: #ffffff !important;
}
.lp-toolbar-button-icon-reset {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
}
.lp-toolbar-button-icon-container {
  margin-top: -20px !important;
}
</style>
