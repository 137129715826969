<template>
  <div class="media">
    <div class="layout row">
      <div class="media-aside media-menu">
        <v-list dense class="transparent">
          <template v-for="(setting, index) in settings">
            <div v-if="setting.show" :key="index">
              <v-subheader v-if="setting.subheader" :key="index">{{
                setting.title
              }}</v-subheader>
              <v-list-tile v-else :key="index" :to="setting.to">
                <v-list-tile-action v-if="setting.icon">
                  <v-icon>{{ setting.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ setting.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </template>
        </v-list>
      </div>
      <div class="media-content flex transparent">
        <!--router-view name="MediaList"></router-view-->
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import store from "@/store";
import { roles } from "@/config/constants";

import {
  STORE_FLEET_NAME,
  STATE_LIBRARIES,
  ACTION_GET_LIBRARIES
} from "@/store_constants/fleet";
import {
  STORE_USER_NAME,
  STATE_PROFILE,
  GETTER_IS_ADMIN
} from "@/store_constants/user";

export default {
  created() {
    this.getLibraries();
  },
  computed: {
    ...mapState(STORE_FLEET_NAME, {
      libraries: STATE_LIBRARIES
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    ...mapGetters(STORE_USER_NAME, {
      isAdmin: GETTER_IS_ADMIN
    }),
    settings() {
      return [
        { subheader: true, title: "User Settings", show: true },
        {
          icon: "mdi-account",
          title: "My profile",
          to: { name: "My Profile" },
          show: true
        },
        { subheader: true, title: "Site Settings", show: this.isAdmin },
        {
          icon: "mdi-account-group",
          title: "Users",
          to: { name: "Site Users" },
          show: this.isAdmin
        },
        {
          icon: "mdi-library-shelves",
          title: "Libraries",
          to: { name: "Site Libraries" },
          show: this.isAdmin
        }
      ];
    }
  },
  methods: {
    ...mapActions(STORE_FLEET_NAME, {
      getLibraries: ACTION_GET_LIBRARIES
    })
  }
};
</script>
<style scoped>
.media-menu {
  min-width: 260px;
  border-right: 1px solid #eee;
  min-height: calc(100vh - 50px - 64px);
}

.media-detail {
  min-width: 300px;
  border-left: 1px solid #eee;
}
</style>
