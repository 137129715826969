<template>
  <span v-if="folder">
    <template v-if="$route.query.q || filterType">
      <div class="subheading font-weight-medium">
        <span>Search results for: </span>
        <span v-if="$route.query.q">"{{ $route.query.q }}"</span>
        <span v-if="filterType"> [Type: {{ filterType }}]</span>
        <v-btn
          small
          icon
          outline
          @click="
            openFolder()
            cleanQuery()
          ">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
    </template>
    <template v-else>
      <v-btn
        e2e-attr="explorer-path"
        small
        @click="
          openFolder()
          cleanQuery()
        "
        flat
        :disabled="folder.name == 'root'">
        <v-icon small left>mdi-folder</v-icon>Root
      </v-btn>
      <span v-if="folder.ellipsis && folder.ellipsis.length > 0">
        <v-menu offset-y class="menu-inline">
          <template v-slot:activator="{ on }">
            <v-icon class="mr-2" small>chevron_right</v-icon>
            <v-btn small flat v-on="on">...</v-btn>
          </template>
          <v-card class="breadcrumb-card">
            <v-list>
              <v-list-tile-content
                v-for="(ellipsis, index) in folder.ellipsis"
                :key="index"
                @click="openFolder(ellipsis._id)"
                class="ellipsis-item">
                <v-list-tile-title class="breadcrumb-title">
                  {{ ellipsis.name }}
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list>
          </v-card>
        </v-menu>
      </span>
      <span v-for="(path, index) in folder.path" v-bind:key="path._id">
        <v-icon class="mr-2" small>chevron_right</v-icon>
        <v-btn
          e2e-attr="explorer-path"
          small
          @click="openFolder(path._id)"
          flat
          :disabled="index == folder.path.length - 1">
          <v-icon small left>mdi-folder</v-icon>
          {{ path.name }}
        </v-btn>
      </span>
    </template>
  </span>
</template>
<script>
import { mapState, mapActions } from "vuex"
import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  STATE_CONTENTS,
  STATE_EXPLORER_LOADING,
  STATE_FILTER_TYPE,
  ACTION_OPEN_FOLDER,
  ACTION_CHANGE_FILTER_TYPE
} from "@/store_constants/explorer"
export default {
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER,
      contents: STATE_CONTENTS,
      loading: STATE_EXPLORER_LOADING,
      filterType: STATE_FILTER_TYPE
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      openFolder: ACTION_OPEN_FOLDER,
      changeFilterType: ACTION_CHANGE_FILTER_TYPE
    }),
    cleanQuery() {
      if (this.$route.query.q) {
        this.$router.replace({ query: null })
      }
      this.changeFilterType(undefined)
    }
  }
}
</script>

<style scoped>
.menu-inline {
  display: inline;
}

.ellipsis-item {
  padding: 2px 5px;
  cursor: pointer;
}

.ellipsis-item:hover {
  background-color: #d8d8d8;
}

.breadcrumb-title {
  margin: 10px;
}

.breadcrumb-card {
  min-width: 200px;
}
</style>