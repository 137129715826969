<template>
  <v-container fluid class="px-0 py-0">
    <recycled-draft-list />
  </v-container>
</template>

<script>
import RecycledDraftList from "./components/RecycledDraftList.vue"

export default {
  components: { RecycledDraftList }
}
</script>
