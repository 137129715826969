<template>
	<div class="lp-image-view">
		<img
			v-if="value.data.cropped"
			width="100%"
			height="100%"
			:src="value.data.cropped.url"
			:style="`border-radius: ${borderRadius}px`"
		/>
		<img v-else width="100%" height="100%" :src="value.data.image.url" :style="`border-radius: ${borderRadius}px`" />
		<div
			:style="`
        width: 100%; 
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0; 
        background: ${value.data.overlay || 'rgba(0,0,0,0)'};
        border-radius: ${borderRadius}px;
      `"
		></div>
		<div v-if="isFullScreen" class="lp-image-view__zoom" @click="openFullScreen">
			<v-icon medium>mdi-magnify-plus</v-icon>
		</div>
	</div>
</template>

<script>
import { PtModal } from 'plant-common';
import FullScreenModal from './FullScreenModal.vue';

export default {
	name: 'ImageView',
	props: ['value'],
	mixins: [PtModal],
	computed: {
		isFullScreen() {
			return this.value.data.fullScreen?.enable;
		},
		borderRadius() {
			return this.value.data.style?.normal.borderRadius || 0;
		}
	},
	methods: {
		openFullScreen() {
			this.$modal(FullScreenModal, {
				src: this.value.data.cropped?.url || this.value.data.image.url
			});
		}
	}
};
</script>

<style>
.lp-image-view {
	width: 100%;
	height: 100%;
}

.lp-image-view img {
	object-fit: contain;
}

.lp-image-view__zoom {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 50px;
	height: 50px;
	background-color: whitesmoke;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lp-image-view__zoom:hover {
	cursor: pointer;
}
</style>
