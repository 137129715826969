export default {
  data() {
    return {
      isFullscreen: false
    }
  },
  watch: {
    isFullscreen: {
      handler() {
        if (this.isFullscreen) {
          this.enterFullscreen()
        } else {
          this.exitFullscreen()
        }
      }
    }
  },
  methods: {
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen
    },
    setFullscreenTo(value) {
      this.isFullscreen = !!value
    },
    enterFullscreen() {
      document.getElementById("lp-index").classList.add("lp-slide-fullscreen")
      const footer = document.querySelector(".interactive-footer-arrows")

      if (footer) {
        footer.addEventListener("mouseenter", this.addStyle)
        footer.addEventListener("mouseleave", this.removeStyle)
      }

      if (this.mode !== "edit")
        this.showNotification("You can also exit from fullscreen mode pressing ESC key", "primary")
    },
    exitFullscreen() {
      const footer = document.querySelector(".interactive-footer-arrows")

      if (footer) {
        footer.removeEventListener("mouseenter", this.addStyle)
        footer.removeEventListener("mouseleave", this.removeStyle)
        this.addStyle()
      }
      document.getElementById("lp-index").classList.remove("lp-slide-fullscreen")
    },
    addStyle() {
      const slideWrapper = document.querySelector(".lp-index.lp-slide-fullscreen .lp-slide-wrapper")
      slideWrapper.style.bottom = "28px"
    },
    removeStyle() {
      const slideWrapper = document.querySelector(".lp-index.lp-slide-fullscreen .lp-slide-wrapper")
      slideWrapper.style.bottom = "0"
    }
  }
}
