
import "./pt-nuscale-piping-valve-threeway.css"

export default {
  model: {
    "status": {
      type: "list",
        format: ["UNACTUATED", "ACTUATED", "REGULATING"],
          inContextmenu: true,
      default: "UNACTUATED",
        bind: {
        to: "attr",
          name: "data-status"
      },
    }
  },
  listeners: {
        mouseenter: true,
        mouseleave: true,
    click: true,
    contextmenu: true
}
}
