<template>
  <p-dialog icon="mdi-robot" title="Edit Macro" @input="$emit('close')" max-width="90%" max-height="90%">
    <v-card flat>
      <v-card-text>
        <v-text-field label="Name" v-model="value.name"></v-text-field>
        <v-text-field label="Description" v-model="value.description"></v-text-field>
        <p-code-editor v-model="value.code" />
      </v-card-text>
    </v-card>
    <template #actions>
      <span></span>
    </template>
  </p-dialog>
</template>
<script>
import { PDialog, PCodeEditor } from "plant-common"

export default {
  props: ["value"],
  components: {
    PDialog,
    PCodeEditor
  }
}
</script>
