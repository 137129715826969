<template>
  <v-dialog :value="true" persistent max-width="550">
    <v-card class="pa-2">
      <v-card-title class="headline justify-center">Importing content from Template</v-card-title>
      <v-card-text class="text-center pa-0">
        <p>Choose the content you want to import</p>
        <p>
          <i>
            Note: If you've already added this template and you deselect "Layouts" or "Galleries" option, it will be
            deleted from current Interactive Presentation imports
          </i>
        </p>
      </v-card-text>

      <v-card-text>
        <v-switch :disabled="!layouts.length" v-model="contentToImport" label="Layouts" value="layouts"></v-switch>
        <v-switch
          :disabled="!galleries.length"
          v-model="contentToImport"
          label="Galleries"
          value="galleries"></v-switch>
        <v-switch
          v-model="contentToImport"
          label="Color Palette (this action will overwrite the Color Palette of the current IP)"
          value="colorPalette"></v-switch>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="!canImport" :color="accept.color || 'success'" block @click="$emit('input', contentToImport)">
          <v-icon left>{{ accept.icon || "mdi-check" }}</v-icon
          >{{ accept.text || "accept" }}
        </v-btn>
        <v-btn :color="cancel.color || 'error'" block @click="$emit('input', false)">
          <v-icon left>{{ cancel.icon || "mdi-cancel" }}</v-icon
          >{{ cancel.text || "cancel" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalImportContent",
  props: {
    template: Object,
    accept: {
      type: Object,
      default: function () {
        return {}
      }
    },
    cancel: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      contentToImport: ["colorPalette"]
    }
  },
  computed: {
    canImport() {
      return this.contentToImport.length
    },
    layouts() {
      return this.template.body.contents.layouts.filter(layout => layout.imported === false)
    },
    galleries() {
      return this.template.body.contents.galleries.filter(gallery => gallery.imported === false)
    },
    colorPalette() {
      return this.template.body.contents.settings.styles.colors
    }
  },
  created() {
    if (this.layouts.length) this.contentToImport.push("layouts")
    if (this.galleries.length) this.contentToImport.push("galleries")
  }
}
</script>

<style></style>
