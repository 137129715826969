<template>
  <p-dialog
    title="Button States Styles"
    cancel-text="Close"
    cancel-color="primary"
    icon="mdi-palette-outline"
    :value="openDialog"
    @input="openDialog = false"
    width="800">
    <template v-slot:activator>
      <slot name="activator" v-bind="{ open }" />
    </template>

    <v-layout row wrap>
      <v-flex xs6 pa-2>
        <v-card>
          <!-- known issue with vuetify tabs  not showing the slider when loading for the first time
 		https://github.com/vuetifyjs/vuetify/issues/11558
		 workaround using active-class property and custom CSS style
		  -->
          <v-tabs ref="tabs" dense v-model="statusSelected" grow active-class="tab-active" hide-slider>
            <template v-for="(title, key) in status">
              <v-tab ripple :key="key">{{ title }}</v-tab>
              <v-tab-item :key="key">
                <v-card flat>
                  <v-card-text>
                    <style-select :value="value.data.style[key]" :title="title" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </template>
          </v-tabs>
        </v-card>
      </v-flex>
      <v-flex xs6 pa-2>
        <v-layout align-center justify-center row fill-height>
          <v-flex shrink>
            <button-view :value="value" :style="viewStyles" :state="state" />
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </p-dialog>
</template>

<script>
import StyleSelect from "../../components/slides/styles/StyleSelect.vue"
import ButtonView from "./BlockViewButton.vue"
import { PDialog } from "plant-common"

export default {
  inject: ["blockController"],
  components: {
    StyleSelect,
    ButtonView,
    PDialog
  },
  props: ["value", "state"],
  data: () => ({
    openDialog: false,
    status: {
      regular: "Regular",
      hover: "Hover",
      active: "Active",
      visited: "Visited",
      disabled: "Disabled"
    },
    statusSelected: 0
  }),
  computed: {
    viewStyles() {
      const {
        backgroundColor,
        color,
        textScale,
        textAlign: justifyContent,
        fontFamily,
        boxShadowButton: boxShadow,
        borderRadius,
        borderWidth,
        borderStyle,
        borderColor
      } = this.value.data.style.regular
      const {
        backgroundColor: backgroundColorHover,
        color: colorHover,
        textScale: textScaleHover,
        textAlign: justifyContentHover,
        fontFamily: fontFamilyHover,
        boxShadowButton: boxShadowHover,
        borderRadius: borderRadiusHover,
        borderWidth: borderWidthHover,
        borderStyle: borderStyleHover,
        borderColor: borderColorHover
      } = this.value.data.style.hover
      const {
        backgroundColor: backgroundColorActive,
        color: colorActive,
        textScale: textScaleActive,
        textAlign: justifyContentActive,
        fontFamily: fontFamilyActive,
        boxShadowButton: boxShadowActive,
        borderRadius: borderRadiusActive,
        borderWidth: borderWidthActive,
        borderStyle: borderStyleActive,
        borderColor: borderColorActive
      } = this.value.data.style.active

      return {
        backgroundColor,
        color,
        fontFamily: fontFamily || "inherit",
        boxShadow,
        borderRadius: `${borderRadius * this.blockController.scale}px`,
        borderWidth: `${borderWidth * this.blockController.scale}px`,
        borderStyle,
        borderColor,
        "--text-scale": textScale * this.blockController.scale,
        "--text-align": justifyContent == 'center' ? 'center' : 'left',
        "--justify-content": justifyContent,

        "--background-color-hover": backgroundColorHover,
        "--color-hover": colorHover,
        "--text-scale-hover": textScaleHover * this.blockController.scale,
        "--text-align-hover": justifyContentHover == 'center' ? 'center' : 'left',
        "--justify-content-hover": justifyContentHover,
        "--font-family-hover": fontFamilyHover || "inherit",
        "--border-shadow-hover": boxShadowHover,
        "--border-radius-hover": `${borderRadiusHover * this.blockController.scale}px`,
        "--border-width-hover": `${borderWidthHover * this.blockController.scale}px`,
        "--border-style-hover": borderStyleHover,
        "--border-color-hover": borderColorHover,

        "--background-color-active": backgroundColorActive,
        "--color-active": colorActive,
        "--text-scale-active": textScaleActive * this.blockController.scale,
        "--text-align-active": justifyContentActive == 'center' ? 'center' : 'left',
        "--justify-content-active": justifyContentActive,
        "--font-family-active": fontFamilyActive || "inherit",
        "--border-shadow-active": boxShadowActive,
        "--border-radius-active": `${borderRadiusActive * this.blockController.scale}px`,
        "--border-width-active": `${borderWidthActive * this.blockController.scale}px`,
        "--border-style-active": borderStyleActive,
        "--border-color-active": borderColorActive,
        width: `${this.value.size.width * this.blockController.scale}px`,
        height: `${this.value.size.height * this.blockController.scale}px`
      }
    }
  },
  mounted() {
    this.initVisitedStyle()
    this.initDisabledStyle()
  },
  methods: {
    open() {
      this.openDialog = true
    },
    initVisitedStyle() {
      // for lesson plans before the visited state
      if (!this.value.data.style.visited) {
        this.$set(this.value.data.style, "visited", {
          backgroundColor: "#bdbdbdff",
          color: "#000000ff",
          textScale: 100,
          textAlign: "center",
          justifyContent: "center",
          boxShadowButton: "none",
          borderRadius: 0,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#bdbdbdff",
          fontFamily: false
        })
      }
    },
    initDisabledStyle() {
      // for lesson plans before the disabled state
      if (!this.value.data.style.disabled) {
        this.$set(this.value.data.style, "disabled", {
          backgroundColor: "#efefef4d",
          color: "#1010104d",
          textScale: 100,
          textAlign: "center",
          justifyContent: "center",
          boxShadowButton: "none",
          borderRadius: 0,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#7676764d",
          fontFamily: false
        })
      }

      // for new style attributes in all states
      const states = ["regular", "hover", "active", "visited", "disabled"]
      if (!this.value.data.style.regular.borderColor) {
        for (var stat of states) {
          let { fontFamily, ...buttonStyle } = this.value.data.style[stat]
          this.$set(this.value.data.style, stat, {
            ...buttonStyle,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#f5f5f5ff",
            fontFamily
          })
        }
      }
    }
  }
}
</script>

<style>
.tab-active {
  border-bottom: solid 2px #1976d2;
  font-weight: bold;
  color: black;
}
</style>
