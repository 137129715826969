<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title>
        <v-tooltip
          bottom
          v-if="
            lessonPlanController.mode === 'edit' &&
            lessonPlanController.lessonPlanContents.settings.styles.navigation &&
            lessonPlanController.lessonPlanContents.settings.styles.navigation.locked
          ">
          <template v-slot:activator="{ on }">
            <span d-flex v-on="on">
              <v-icon small color="red">mdi-lock-outline</v-icon>
            </span>
          </template>
          <span
            >Interactive Presentation is globally marked as lock navigation ({{
              lessonPlanController.lessonPlanContents.settings.styles.navigation.locked
            }})</span
          >
        </v-tooltip>
        Outline
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div>
        <v-tooltip bottom v-if="lessonPlanController.mode == 'edit'">
          <template v-slot:activator="{ on }">
            <v-btn e2e-id="add-slide-regular" v-on="on" icon flat @click="lessonPlanController.addSlide()">
              <v-icon>mdi-shape-rectangle-plus</v-icon>
            </v-btn>
          </template>
          Add Slide
        </v-tooltip>
        
        <v-tooltip bottom v-if="canShowNavigationLock">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon flat @click="toggleNavigationLock">
              <v-icon :color="navigationLocked ? 'red' : ''">{{ navigationLocked ? "mdi-lock-outline" : "mdi-lock-open-outline" }}</v-icon>
            </v-btn>
          </template>
          {{ navigationLocked ? "Unlock navigation" : "Lock navigation" }}
        </v-tooltip>

        <v-menu offset-y v-if="lessonPlanController.mode == 'edit'">
          <template v-slot:activator="{ on }">
            <v-btn e2e-id="menu-dropdown-add" v-on="on" icon small>
              <v-icon>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-tile e2e-id="menu-dropdown-add-slide" @click="lessonPlanController.addSlide()">
              <v-list-tile-title>
                <v-icon>mdi-shape-rectangle-plus</v-icon>
                Add Slide
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile e2e-id="menu-dropdown-add-section" @click="lessonPlanController.addSection()">
              <v-list-tile-title>
                <v-icon>mdi-folder</v-icon>
                Add Section
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile e2e-id="menu-dropdown-add-quiz" @click="lessonPlanController.addQuizSlide()">
              <v-list-tile-title>
                <v-icon>mdi-book-education-outline</v-icon>
                Add Quiz
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="!isInteractiveSlide"
              e2e-id="menu-dropdown-add-learning-object"
              @click="importInteractivePresentationFrom('lessonPlan')">
              <v-list-tile-title>
                <v-icon>mdi-book-open-page-variant-outline</v-icon>
                Import another presentation
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile
              v-if="!isInteractiveSlide"
              e2e-id="menu-dropdown-add-learning-object-from-objective"
              @click="importInteractivePresentationFrom('objective')">
              <v-list-tile-title>
                <v-icon>mdi-bullseye-arrow</v-icon>
                Import presentation from objective
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn flat v-on="on" class="lp-outline-panel-actions-button">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile @click="lessonPlanController.changeToEditLayout()" v-if="lessonPlanController.mode == 'edit'">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-page-layout-header-footer</v-icon>
                <span>Edit Slide Templates</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="lessonPlanController.toggleSlideNumber()">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2" v-if="lessonPlanController.slideNumberShown">mdi-numeric-off</v-icon>
                <v-icon class="mr-2" v-else>mdi-numeric</v-icon>
                <div>
                  <span v-if="lessonPlanController.slideNumberShown">Hide</span> <span v-else>Show</span> Slide Number
                </div>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="copySlidesAsLayouts" v-if="lessonPlanController.mode == 'edit'">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-content-copy</v-icon>
                <span>Copy slides as Layouts</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-divider></v-divider>
    
    <tree-edit @slideChanged="$emit('slideChanged')"></tree-edit>
  </div>
</template>

<script>
import TreeEdit from "./tree/TreeEdit.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { PtModal } from "plant-common"
import FilterContentDialog from "./modal/FilterContentDialog.vue"
import DependentsForContent from "./modal/DependentsForContent.vue"
import { getCustomId } from "../../../../utils/dbTool"
import { checkSlidesForLearningObject } from "./tree/utils"

export default {
  inject: ["lessonPlanController"],
  components: {
    TreeEdit
  },
  mixins: [PtModal],
  data() {
    return {
      navigationLocked: false
    }
  },
  computed: {
    canShowNavigationLock() {
      return this.lessonPlanController.lessonPlanContents.settings.styles.navigation?.locked === "sequential" && (this.isReviewMode || this.isPreviewMode)
    },
    isInteractiveSlide() {
      return this.lessonPlanController.lessonPlanObject.header.metadata.content_revision_type === "interactiveSlide"
    },
    isPreviewMode() {
      return this.lessonPlanController.isPreviewMode
    },
    isReviewMode() {
      return this.lessonPlanController.isReviewMode
    }
  },
  created() {
    if (this.canShowNavigationLock) this.setLessonPlanMode("review")
  },
  methods: {
    copySlidesAsLayouts() {
      this.lessonPlanController.copySlidesAsLayouts()
      this.lessonPlanController.changeToEditLayout()
    },
    async importInteractivePresentationFrom(contentType) {
      let interactivePresentation = false
      switch (contentType) {
        case "lessonPlan":
          interactivePresentation = await PServiceContent.selectContent({
            type: ["lessonPlan", "interactiveSlide"],
            multipleSelection: true
          })
          break
        case "objective":
          const objective = await this.$modal(FilterContentDialog, {
            type: "objective"
          })
          if (!objective) return false
          interactivePresentation = await this.$modal(DependentsForContent, {
            content: objective,
            types: ["lessonPlan", "interactiveSlide"]
          })
          break
      }
      if (!interactivePresentation) return

      if (!Array.isArray(interactivePresentation)) {
        interactivePresentation = [interactivePresentation]
      }

      const warnings = []
      for (const ip of interactivePresentation) {
        const learningObjectFlag = checkSlidesForLearningObject(ip.body.contents.slides, false)
        if (learningObjectFlag) {
          warnings.push(getCustomId(ip))
          continue
        }
        this.lessonPlanController.addLearningObjectSlide(ip)
      }

      if (warnings.length) {
        const warningsString = warnings.join(", ")
        this.$modal("info", {
          title: `Can not add "${warningsString}" content`,
          message: `Selected content has one or more Learning Objects inside. You can not import an Interactive Presentation with Learning Objects associated as a new Learning Object for current Interactive Presentation.`,
          ok: { text: "Accept" }
        })
      }
    },
    setLessonPlanMode(mode) {
      this.lessonPlanController.mode = mode
    },
    toggleNavigationLock() {
      this.navigationLocked = !this.navigationLocked
      this.setLessonPlanMode(this.navigationLocked ? "view" : "review")
    }
  }
}
</script>

<style>
.lp-outline-panel-actions-button.v-btn {
  min-width: 20px;
  padding: 0;
  margin-right: 0;
}

.lp-outline-panel-actions-button .v-icon {
  font-size: 18px;
}
</style>
