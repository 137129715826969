import Component from "./index.vue"
import Print from "./Print"
import Preview from "./Preview"

export default {
  name: "lessonPlan",
  title: "Interactive Presentation",
  icon: require("plant-common/src/components/plant-item-viewer/lessonPlan/content-icon.svg"),
  Viewer: Component,
  Editor: Component,
  Print,
  Preview
}
