<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <svg
        class="shape-oval"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${value.size.width} ${value.size.height}`">
        <defs>
          <ellipse
            :id="`path-${id}`"
            cx="50%"
            cy="50%"
            :rx="value.size.width / 2"
            :ry="value.size.height / 2"
            :style="value.data.style" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
export default {
  name: "BlockShapeRectangle",
  inject: ["blockController"],
  components: {
    BasicBlock
  },
  props: ["value", "mode"],
  computed: {
    id() {
      return this.value.id
    }
  }
}
</script>
