<template>
  <v-list-tile avatar @click.stop="$emit('click')">
    <v-list-tile-avatar :color="color">
      <v-icon dark>{{ icon }}</v-icon>
    </v-list-tile-avatar>

    <v-list-tile-content @click.stop="openContentNotification">
      <v-list-tile-sub-title>
        <component :is="MessageComponent" :value="value" />
      </v-list-tile-sub-title>
      <v-list-tile-title>{{ prettyDate }}</v-list-tile-title>
    </v-list-tile-content>

    <v-list-tile-action class="notification-action">
      <v-btn @click.stop="$emit('remove')" flat icon small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-list-tile-action>
  </v-list-tile>
</template>

<script>
import moment from "moment"
import notificationMessages from "./notificationMessages/index.js"
import { mapActions } from "vuex"
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_ERROR } from "@/store_constants/notifications"
import {
  STORE_EVENT_NOTIFICATIONS_NAME,
  ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ
} from "@/store_constants/event-notifications"
import available_subscriptions from "@/pages/notifications/available_subscriptions"
export default {
  props: ["value"],
  computed: {
    prettyDate() {
      return moment(this.value.event.timestamp).fromNow()
    },
    MessageComponent() {
      var component = notificationMessages.find(
        c => (c.metadata || {}).subscription_code == this.value.subscription_code
      )
      if (component) {
        return component
      } else {
        return {
          template: `<span>Unknown Notification ${this.value.subscription_code}</span>`,
          metadata: { icon: "mdi-alert-circle", color: "red" }
        }
      }
    },
    icon() {
      return this.MessageComponent.metadata.icon
    },
    color() {
      return this.MessageComponent.metadata.color
    }
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    ...mapActions(STORE_EVENT_NOTIFICATIONS_NAME, {
      markAsRead: ACTION_EVENT_NOTIFICATIONS_MARK_AS_READ
    }),
    openContentNotification() {
      if (this.value.event.payload.library_id) {
        var subscription = available_subscriptions.find(an => an.code == this.value.subscription_code)
        if (subscription) {
          if (!this.value.read) {
            this.markAsRead({ notificationId: this.value._id, read: true })
          }
          window.open(subscription.getURL(this.value.event.payload), "_blank")
        } else {
          this.error({
            message: `Could not open current content. ${this.value.subscription_code} not registered.`
          })
        }
      } else {
        this.error({
          message: "Could not open current content. Library ID is missing"
        })
      }
    }
  }
}
</script>

<style scoped>
.notification-action {
  min-width: 32px;
}
</style>