import "./pt-piping-pipe-secondary.css"

export default {
	selector: 'g.pt-piping-pipe-secondary',
	setup(pageElement) {
		if (pageElement.querySelector('#marker-arrow-right')) {
			return;
		}
		pageElement.insertAdjacentHTML(
			'afterbegin',
			`<defs>
                <marker id="marker-arrow-right" markerWidth="6" markerHeight="6" refX="3" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M0,0 L0,6 L6,3 L0,0" style="fill: black;" />
                </marker>
                <marker id="marker-arrow-right-on" markerWidth="6" markerHeight="6" refX="3" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M0,0 L0,6 L6,3 L0,0" style="fill: var(--pt-color-remote-on);" />
                </marker>
                <marker id="marker-arrow-left" markerWidth="6" markerHeight="6" refX="3" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M6,0 L0,3 L6,6 L6,0" style="fill: black;" />
                </marker>
                <marker id="marker-arrow-left-on" markerWidth="6" markerHeight="6" refX="3" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M6,0 L0,3 L6,6 L6,0" style="fill: var(--pt-color-remote-on);" />
                </marker>
                <marker id="marker-arrow-double" markerWidth="14" markerHeight="6" refX="7" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M6,0 L0,3 L6,6 L6,0" style="fill: black;" />
                    <path d="M8,0 L14,3 L8,6 L8,0" style="fill: black;" />
                </marker>
                <marker id="marker-arrow-double-on" markerWidth="14" markerHeight="6" refX="7" refY="3"  orient="auto" markerUnits="userSpaceOnUse">
                    <path d="M6,0 L0,3 L6,6 L6,0" style="fill: var(--pt-color-remote-on);" />
                    <path d="M8,0 L14,3 L8,6 L8,0" style="fill: var(--pt-color-remote-on);" />
                </marker>
            </defs>`
		);
	},
	model: {
		status: {
			type: 'list',
			format: ['FLOW', 'NO FLOW'],
			default: 'NO FLOW'
		},
		direction: {
			type: 'list',
			format: ['NO DIRECTION', 'DIRECTION 1', 'DIRECTION 2', 'BOTH DIRECTIONS'],
			default: 'NO DIRECTION'
		}
	},
	listeners: {
		click: true,
		contextmenu: true
	},
	hooks: {
		beforeMount(elm) {
			let pathElement = elm.querySelector('path');

			// Do not change element if path has curves
			// From https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d
			//   - MoveTo: M, m
			//   - LineTo: L, l, H, h, V, v
			//   - Cubic Bézier Curve: C, c, S, s
			//   - Quadratic Bézier Curve: Q, q, T, t
			//   - Elliptical Arc Curve: A, a
			//   - ClosePath: Z, z
			if (pathElement.attributes.d.value.match(/[HhVvCcSsQqTtAaZz]/g)) {
				return;
			}
			let points = pathElement.attributes.d.value.split(' ');
			for (let i = 0; i < points.length - 2; i = 2 + i) {
				let x0 = Number(points[i].slice(1));
				let y0 = Number(points[i + 1]);
				let x1 = Number(points[i + 2].slice(1));
				let y1 = Number(points[i + 3]);
				let midX = x0 + (x1 - x0) / 2;
				let midY = y0 + (y1 - y0) / 2;
				elm.insertAdjacentHTML(
					'afterbegin',
					`<path d="M${x0} ${y0} L${midX} ${midY} L${x1} ${y1}" class="${Array(...pathElement.classList).join(' ')}" stroke-linecap="square"></path>`
				);
			}
			pathElement.remove();
		}
	}
};