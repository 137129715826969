<template>
  <toolbar-button
    v-if="selected"
    v-show="selected.length == 1"
    icon="mdi-folder-edit-outline"
    tooltip="Rename selected folder, only one folder"
    @click="renameFolderModal"
    :button="{ disabled: selected.length != 1 }"
  ></toolbar-button>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PtModal } from "plant-common";
import ToolbarButton from "./ToolbarButton";
import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  STATE_SELECTED,
  ACTION_RENAME_FOLDER
} from "@/store_constants/explorer";
export default {
  mixins: [PtModal],
  components: { ToolbarButton },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER,
      selected: STATE_SELECTED
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      renameFolder: ACTION_RENAME_FOLDER
    }),
    renameFolderModal() {
      var parent_id = this.selected[0] ? this.selected[0].parent_id : false;
      this.$modal("input", {
        title: "Rename Folder",
        default_value: this.selected[0].name
      }).then(name => {
        if (typeof name == "undefined") return;
        this.renameFolder({ name, folder_id: this.selected[0]._id, parent_id });
      });
    }
  }
};
</script>
