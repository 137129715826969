<template>
  <v-container class="px-0 py-0">
    <div v-if="profile.role !== roles.student.value">
      <div v-if="loading">
        <v-alert :value="true" color="info" icon="mdi-information-outline">Loading data</v-alert>
        <v-progress-linear
          :size="100"
          :width="6"
          color="primary"
          indeterminate
          class="loading-fixed"></v-progress-linear>
      </div>
      <div v-else>
        <factory-filter />
        <div v-show="filteredItemRevisionDrafts.length <= 0">
          <v-img height="500" contain :src="require('@/assets/empty_list.svg')"></v-img>
          <h5 class="text-center headline font-weight-light mt-5">
            Good Job! You don't have any workflow assignments.
          </h5>
          <div class="text-center mt-3">
            <new-content-button />
          </div>
        </div>
        <content-list
          layout="factory"
          v-show="filteredItemRevisionDrafts.length > 0"
          :items="filteredItemRevisionDrafts"
          @input="selectHandler"
          :showCheck="true"
          :showWorkflowStatus="true"
          @change="changeSelected"
          @toggleAll="toggleAllItems" />
      </div>
    </div>
    <div v-else>
      <v-alert :value="true" type="warning">
        Your Plant current role is {{ profile.role }}. You need instructor role to view this page.
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import NewContentButton from "./new-content/NewContentButton.vue"

import { mapState, mapActions, mapGetters } from "vuex"
import { ContentList } from "plant-common"
import FactoryFilter from "./FactoryFilter.vue"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import { roles } from "@/config/constants"
import {
  STORE_WORKFLOWS_NAME,
  GETTER_FILTERED_ITEM_REVISION_DRAFTS,
  GETTER_LOADING,
  ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
  ACTION_MOVE_SELECTED,
  ACTION_CHANGE_SELECTION,
  ACTION_TOGGLE_ALL_SELECTION
} from "@/store_constants/workflows"
import { getContentId } from "../../../utils/dbTool"

export default {
  components: { ContentList, FactoryFilter, NewContentButton },
  data() {
    return {
      roles: roles
    }
  },
  computed: {
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    ...mapGetters(STORE_WORKFLOWS_NAME, {
      filteredItemRevisionDrafts: GETTER_FILTERED_ITEM_REVISION_DRAFTS,
      loading: GETTER_LOADING
    })
  },
  created() {
    window.addEventListener("keydown", this.selectWithKeys)
  },
  destroyed() {
    window.removeEventListener("keydown", this.selectWithKeys)
  },
  methods: {
    ...mapActions(STORE_WORKFLOWS_NAME, [
      ACTION_SELECT_ITEM_REVISION_DRAFT_ID,
      ACTION_MOVE_SELECTED,
      ACTION_CHANGE_SELECTION,
      ACTION_TOGGLE_ALL_SELECTION
    ]),
    selectWithKeys(evt) {
      if (evt.srcElement !== document.body) return

      const keyMoves = {
        ArrowRight: 1,
        ArrowLeft: -1,
        ArrowDown: 1,
        ArrowUp: -1
      }
      if (!Object.keys(keyMoves).includes(evt.key)) return
      this[ACTION_MOVE_SELECTED](keyMoves[evt.key])
    },
    selectHandler(element) {
      if (element.header) {
        this[ACTION_SELECT_ITEM_REVISION_DRAFT_ID](getContentId(element))
      }
    },
    changeSelected($event) {
      this[ACTION_CHANGE_SELECTION]($event.item)
    },
    toggleAllItems(flag) {
      this[ACTION_TOGGLE_ALL_SELECTION](flag)
    }
  }
}
</script>

<style scoped>
.factory-list {
  overflow: auto;
  max-width: 100%;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}

.loading-fixed {
  margin: -4px 0px;
}
</style>
