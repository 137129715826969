
import "./pt-piping-valve-multiport3.css"

export default {
  model: {
    "port1": {
      type: "list",
      format: ["OPEN", "CLOSED"],
      default: "CLOSED"
    },
    "port2": {
      type: "list",
      format: ["OPEN", "CLOSED"],
      default: "CLOSED"
    },
    "port3": {
      type: "list",
      format: ["OPEN", "CLOSED"],
      default: "CLOSED"
    }
  },
  listeners: {
    mouseenter: true,
    mouseleave: true,
    click: true,
    contextmenu: true
  }
}
