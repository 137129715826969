import uuid from "../../../../utils/uuid.js"
import { BlockBasic } from "../_models/models.js"

export default class BlockCarousel extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "carousel"
    this.size.width = 1200
    this.size.height = 600
    this.data = {
      cards: [new BlockCard()],
      displayCardsTooltip: true,
      style: {
        canvas: {
          normal: {
            backgroundColor: "#ffffffff"
          }
        },
        transition: {
          type: "none"
        },
        arrows: {
          normal: {
            backgroundColor: "#ffffffff",
            arrowColor: "#000000e6"
          },
          hover: {
            backgroundColor: "#cccccc",
            arrowColor: "#000000e6"
          }
        },
        paginator: {
          normal: {
            backgroundColor: "#cccccc"
          },
          hover: {
            backgroundColor: "#000000"
          },
          active: {
            backgroundColor: "#2b52ff"
          },
          visited: {
            backgroundColor: "#cc0000"
          }
        }
      },
      navigation: {
        loop: true
      }
    }
  }
}

export class BlockCard {
  constructor() {
    this.id = uuid()
    this.title = "Card"
    this.blocks = []
  }
}
