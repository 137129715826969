import "./pt-nuscale-piping-valve-remote.css"

export default {
    "model": {
        "status": {
            type: "list",
            format: ["OPEN", "MID POSITION", "CLOSED"],
            default: "undefined"
        }
    },
    listeners: {
        mouseenter: true,
        mouseleave: true,
        click: true,
        contextmenu: true
    }
}

