<template>
  <div>
    <v-snackbar
      v-for="notification in notifications"
      :key="notification.id"
      :timeout="notification.timeout"
      :color="notification.type"
      top
      center
      @input="removeNotification(notification)"
      :value="true"
      v-bind:class="isElectron ? 'desktop-notification' : ''"
    >
      {{ notification.message }}
      <v-btn flat @click.native="removeNotification(notification)"
        >&times;</v-btn
      >
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  STORE_NOTIFICATIONS_NAME,
  STATE_NOTIFICATIONS_STACK,
  ACTION_NOTIFICATIONS_REMOVE
} from "@/store_constants/notifications";

export default {
  computed: {
    ...mapState(STORE_NOTIFICATIONS_NAME, {
      notifications: STATE_NOTIFICATIONS_STACK
    }),
    isElectron() {
      return navigator.userAgent.toLowerCase().indexOf(" electron/") > -1;
    }
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      removeNotification: ACTION_NOTIFICATIONS_REMOVE
    })
  }
};
</script>

<style scoped>
.desktop-notification {
  top: 40px;
}
</style>

