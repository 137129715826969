import Vue from "vue"
import { getFileExtension, uploadFile } from "../../../utils";
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value;
  return new Vue({
    data() {
      return {

      };
    },
    computed: {
      metadata() {
        if (vm.value && vm.value.body.attachments) {
          return vm.value.body.attachments.find(attch => attch.name == "metadata");
        } else {
          return false;
        }
      },
      hierarchy() {
        if (vm.value && vm.value.body.attachments) {
          return vm.value.body.attachments.find(attch => attch.name == "hierarchy");
        } else {
          return false;
        }
      },
      octree() {
        if (vm.value && vm.value.body.attachments) {
          return vm.value.body.attachments.find(attch => attch.name == "octree");
        } else {
          return false;
        }
      },
      canLoadCloud() {
        return this.metadata && this.octree && this.hierarchy;
      },
      defaultView() {
        if (vm.value && vm.value.body.contents && vm.value.body.contents.defaultView) {
          return vm.value.body.contents.defaultView
        } else {
          return false
        }
      }
    },
    methods: {
      uploadMetadata() {
        return uploadFile(".json")
          .then(({ url, file }) => {
            if (file.name == 'metadata.json') {
              const index = itemRevision.body.attachments.findIndex(attch => attch.name == "metadata");
              if (index > -1) {
                itemRevision.body.attachments[index] = new Attachment('metadata', file, url, getFileExtension(file));
              } else {
                itemRevision.body.attachments.push(new Attachment('metadata', file, url, getFileExtension(file)));
              }
            } else {
              throw Error(`File uploaded does not match metadata.json`)
            }
          })
      },
      uploadHierarchy() {
        return uploadFile(".bin")
          .then(({ url, file }) => {
            if (file.name == 'hierarchy.bin') {
              const index = itemRevision.body.attachments.findIndex(attch => attch.name == "hierarchy");
              if (index > -1) {
                itemRevision.body.attachments[index] = new Attachment('hierarchy', file, url, getFileExtension(file));
              } else {
                itemRevision.body.attachments.push(new Attachment('hierarchy', file, url, getFileExtension(file)));
              }
              console.log(itemRevision.body.attachments)
            } else {
              throw Error(`File uploaded does not match hierarchy.bin`)
            }
          })
      },
      uploadOctree() {
        return uploadFile(".bin")
          .then(({ url, file }) => {
            if (file.name == 'octree.bin') {
              const index = itemRevision.body.attachments.findIndex(attch => attch.name == "octree");
              if (index > -1) {
                itemRevision.body.attachments[index] = new Attachment('octree', file, url, getFileExtension(file));
              } else {
                itemRevision.body.attachments.push(new Attachment('octree', file, url, getFileExtension(file)));
              }
              console.log(itemRevision.body.attachments)
            } else {
              throw Error(`File uploaded does not match octree.bin`)
            }
          })
      },
      saveCurrentView() {
        var wn = document.getElementById("point_cloud_model").contentWindow;
        itemRevision.body.contents.defaultView = {
          position: wn.viewer.scene.view.position,
          target: wn.viewer.scene.view.getPivot()
        }
      }
    }
  })
}