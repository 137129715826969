import parseBoolean from "./config/parseBoolean";

export default {

  model: {
    "value": {
      default: false
    }
  },
  computed: {
    input_set() {
      // Default prop value if input is a number (for legacy diagrams)
      var inputs = this.$el.dataset.inputs.split(",")
      var [,id, prop] = inputs[0].split(/=|:/g)
      if (!isNaN(prop)) prop = "value"
      return parseBoolean(this.$model[id][prop])
    },
    input_reset() {
      // Default prop value if input is a number (for legacy diagrams)
      var inputs = this.$el.dataset.inputs.split(",")
      var [,id, prop] = inputs[1].split(/=|:/g)
      if (!isNaN(prop)) prop = "value"
      return parseBoolean(this.$model[id][prop])
    }
  },
  watch: {
    input_set() {
      if (this.data.input_set && !this.data.input_reset) this.data.value = true
    },
    input_reset() {
      if (this.data.input_reset) this.data.value = false
    }
  }


}
