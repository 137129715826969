<template>
  <v-text-field
    :value="parseInt(value)"
    @change="v => emitInput(0, v)"
    @input="v => clearInput(v)"
    :disabled="disabled">
    <template v-slot:append-outer>
      <v-icon @click="emitInput(-1)">mdi-minus</v-icon>
      <v-icon @click="emitInput(1)">mdi-plus</v-icon>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "PPanelInputNumber",
  props: ["value", "disabled", "min", "max"],
  methods: {
    clearInput(input) {
      if (input !== "-" && input !== "") {
        const cleanValue = !isNaN(parseInt(input)) ? parseInt(input) : 0
        if (cleanValue === this.value) {
          this.$emit("input", cleanValue + 1)
        }
        this.$nextTick(() => {
          this.$emit("input", cleanValue)
        })
      }
    },
    emitInput(addition, changedValue) {
      let newValue = changedValue ? changedValue + addition : parseInt(this.value) + addition
      if (this.min !== undefined && newValue < this.min) {
        newValue = this.min
      }
      if (this.max !== undefined && newValue > this.max) {
        newValue = this.max
      }
      this.$emit("input", newValue)
    }
  }
}
</script>
