<template>
	<div class="comments-drawer">
		<v-toolbar dense flat class="elevation-0 transparent">
			<v-btn
				v-if="isDraftOrPreview"
				color="success"
				small
				flat
				@click.stop="
					newComment = true;
					newReply = false;
				"
			>
				<v-icon small left e2e-id="new-comment-button">mdi-comment-plus-outline</v-icon>New comment
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn-toggle>
				<v-btn flat v-model="applyFilters">
					<v-icon small>mdi-filter{{ applyFilters ? '' : '-outline' }}</v-icon>
				</v-btn>
			</v-btn-toggle>
			<comments-filter v-model="filter" :commentUsers="commentUsers" :commentsLength="commentsFiltered.length" />
			<v-divider vertical class="mx-2"></v-divider>
			<comments-navigation :applyFilters="applyFilters" :commentsFiltered="commentsFiltered" />
			<v-tooltip bottom v-if="commentsFiltered.length != expanded.length">
				<template v-slot:activator="{ on }">
					<v-btn small icon flat v-on="on" @click.stop="expandAllComments()">
						<v-icon small>mdi-plus-box-multiple</v-icon>
					</v-btn>
				</template>
				<span>Expand all comments</span>
			</v-tooltip>
			<v-tooltip bottom v-else>
				<template v-slot:activator="{ on }">
					<v-btn small icon flat v-on="on" @click.stop="minimizeAllComments()">
						<v-icon small>mdi-minus-box-multiple-outline</v-icon>
					</v-btn>
				</template>
				<span>Collapse all comments</span>
			</v-tooltip>
		</v-toolbar>

		<v-card e2e-id="new-comment" v-if="isDraftOrPreview && newComment" flat>
			<v-card-text class="mt-0 pt-0">
				<p-rich-text
					v-model="comment"
					:canEdit="true"
					placeholder="Write your comment..."
					minHeight="60px"
					e2e-id="new-comment-text"
				/>
			</v-card-text>
			<v-card-actions>
				<v-btn small outline color="success" @click="sendCommentAndClear()">
					<v-icon small left>mdi-floppy</v-icon>
					save comment
				</v-btn>
				<v-btn small color="error" flat @click="newComment = false">Discard</v-btn>
			</v-card-actions>
		</v-card>

		<v-divider></v-divider>

		<div v-bind:class="[isDraftOrPreview && newComment ? 'comment-list' : 'comment-list-viewer']">
			<template v-for="(item, index) in commentsFiltered">
				<v-card
					:key="index"
					v-bind:id="item._id"
					e2e-attr="comment"
					v-if="!item.deleted_at"
					:class="
						selectedComment._id == item._id ? 'animate__animated animate__shakeX mx-1 mt-1 mb-1' : 'mx-1 mt-1 mb-1'
					"
					flat
				>
					<v-card-title class="mb-0 pb-1 caption">
						<span e2e-attr="comment-expand" @click.stop="toggleExpand(item._id)" class="pointer">
							<v-icon class="mr-1" small> mdi-chevron-{{ expanded.includes(item._id) ? 'up' : 'down' }} </v-icon>
							<comment-status-icon :state="item.state" :replies="item.replies" :ownerId="item.user_id" />
							<span class="font-weight-bold"> {{ item.user_name }} </span>
							<span class="mx-1">·</span>
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<span class="grey--text" v-on="on"> {{ item.comment_date | timeAgo }}</span>
								</template>
								<span>{{ item.comment_date | filterDate }}</span>
							</v-tooltip>
							<v-tooltip bottom v-if="item.updated_at">
								<template v-slot:activator="{ on }">
									<span class="caption grey--text" v-on="on"> <span class="mx-1">·</span> edited</span>
								</template>
								<span>{{ item.updated_at | filterDate }}</span>
							</v-tooltip>
						</span>

						<v-spacer></v-spacer>

						<v-tooltip bottom v-if="item.location">
							<template v-slot:activator="{ on }">
								<v-icon @click="openCard(item)" small left v-on="on">mdi-crosshairs-gps</v-icon>
							</template>
							<span> Go to comment slide</span>
						</v-tooltip>

						<v-menu offset-y close-on-content-click>
							<v-icon small slot="activator">mdi-dots-vertical</v-icon>
							<v-list dense>
								<v-list-tile
									@click="doCloseComment(item)"
									v-if="
										item.user_id === activeUser._id &&
										(item.state === 'open' || item.state === 'resolved') &&
										isDraftOrPreview
									"
								>
									<v-list-tile-title><v-icon small left color="success">mdi-circle</v-icon>Close</v-list-tile-title>
								</v-list-tile>
								<v-list-tile @click="doResolveComment(item)" v-if="item.state === 'open' && isDraftOrPreview">
									<v-list-tile-title><v-icon small left color="warning">mdi-circle</v-icon>Resolve</v-list-tile-title>
								</v-list-tile>
								<v-list-tile
									@click="doOpenComment(item)"
									v-if="
										((item.user_id === activeUser._id && item.state === 'closed') || item.state === 'resolved') &&
										isDraftOrPreview
									"
								>
									<v-list-tile-title>
										<v-icon small left color="error">mdi-circle-outline</v-icon>
										Re-open
									</v-list-tile-title>
								</v-list-tile>

								<template v-if="item.user_id == profile._id && item.state != 'closed' && isDraftOrPreview">
									<v-list-tile @click="openEditComment(item)">
										<v-list-tile-title><v-icon small left>edit</v-icon>Edit</v-list-tile-title>
									</v-list-tile>
									<v-list-tile @click="openDeleteComment(item)">
										<v-list-tile-title><v-icon color="red" small left>delete</v-icon>Delete</v-list-tile-title>
									</v-list-tile>
								</template>

								<v-list-tile @click="openReply(item._id)" v-if="item.state != 'closed' && isDraftOrPreview">
									<v-list-tile-title> <v-icon small left>mdi-reply</v-icon>Reply </v-list-tile-title>
								</v-list-tile>
							</v-list>
						</v-menu>
					</v-card-title>

					<template v-if="expanded.includes(item._id)">
						<v-card-text e2e-attr="comment-text">
							<div class="lp-comment-panel" v-if="editingComment == item">
								<p-rich-text
									v-model="editingComment.body"
									:canEdit="true"
									placeholder="Write your comment..."
									minHeight="60px"
								/>
								<v-btn small outline color="success" @click="updateContentComment()">
									<v-icon small left>mdi-floppy</v-icon>
									save changes
								</v-btn>
								<v-btn small color="error" flat @click="discardEditComment(item)">Discard</v-btn>
							</div>

							<p-rich-text
								v-else
								:value="item.body"
								:canEdit="false"
								placeholder="Here goes the comment"
								minHeight="60px"
							/>
						</v-card-text>

						<v-subheader v-if="getRepliesNumber(item.replies) > 0" class="ml-4">
							Replies ({{ getRepliesNumber(item.replies) }}):
						</v-subheader>

						<span v-for="(reply, key) in item.replies" :key="key">
							<div v-if="!reply.deleted_at" class="ml-4">
								<div class="caption" style="width: 100%">
									<v-icon small right>mdi-forum-outline</v-icon>
									<span class="font-weight-bold"> {{ reply.user_name }} </span>
									<span class="mx-1">·</span>
									<span class="grey--text">{{ reply.comment_date | timeAgo }}</span>
									<v-tooltip bottom v-if="reply.updated_at">
										<template v-slot:activator="{ on }">
											<span class="caption grey--text" v-on="on"> (edited)</span>
										</template>
										<span>{{ reply.updated_at | filterDate }}</span>
									</v-tooltip>
									<v-menu
										offset-y
										close-on-content-click
										style="float: right; margin-right: 1em"
										v-if="reply.user_id == profile._id && item.state != 'closed' && isDraftOrPreview"
									>
										<v-icon small slot="activator">mdi-dots-vertical</v-icon>

										<v-list dense>
											<template>
												<v-list-tile @click="openEditComment(reply, item)">
													<v-list-tile-title><v-icon small left>edit</v-icon>Edit</v-list-tile-title>
												</v-list-tile>
												<v-list-tile @click="openDeleteComment(reply, item)">
													<v-list-tile-title><v-icon color="red" small left>delete</v-icon>Delete</v-list-tile-title>
												</v-list-tile>
											</template>
										</v-list>
									</v-menu>
								</div>
								<div class="lp-comment-panel" v-if="editingComment == reply">
									<p-rich-text
										v-model="editingComment.body"
										:canEdit="true"
										placeholder="Write your comment..."
										minHeight="60px"
									/>
									<v-btn small outline color="success" @click="updateContentComment()">
										<v-icon small left>mdi-floppy</v-icon>
										save changes
									</v-btn>
									<v-btn small color="error" flat @click="discardEditComment(reply)">Discard</v-btn>
								</div>
								<p-rich-text
									v-else
									class="ml-4"
									:value="reply.body"
									:canEdit="false"
									placeholder="Here goes the reply"
									minHeight="60px"
								/>
							</div>
						</span>

						<div class="reply-message" v-if="newReply && comment_id == item._id">
							<v-divider></v-divider>
							<p-rich-text v-model="reply" :canEdit="true" placeholder="Write your reply..." minHeight="60px" />
							<v-btn
								v-if="item.state === 'open'"
								outline
								color="success"
								small
								:disabled="reply === '' || reply === '<p></p>'"
								@click.stop="sendReplyAndClear()"
							>
								<v-icon small left>mdi-floppy</v-icon>
								save
							</v-btn>

							<v-btn
								small
								outline
								color="success"
								:disabled="reply === '' || reply === '<p></p>'"
								v-if="
									item.user_id === activeUser._id &&
									(item.state === 'open' || item.state === 'resolved') &&
									isDraftOrPreview
								"
								@click.stop="saveAndCloseComment(item)"
							>
								<v-icon small left color="success">mdi-floppy</v-icon>save & close
							</v-btn>

							<v-btn
								small
								outline
								color="warning"
								:disabled="reply === '' || reply === '<p></p>'"
								v-if="item.state === 'open' && isDraftOrPreview && item.user_id !== activeUser._id"
								@click.stop="saveAndResolveComment(item)"
							>
								<v-icon small left color="warning">mdi-floppy</v-icon>save & resolve
							</v-btn>

							<v-btn
								small
								outline
								color="error"
								:disabled="reply === '' || reply === '<p></p>'"
								v-if="
									((item.user_id === activeUser._id && item.state === 'closed') || item.state === 'resolved') &&
									isDraftOrPreview
								"
								@click.stop="saveAndOpenComment(item)"
							>
								<v-icon small left color="error">mdi-floppy</v-icon>save & re-open
							</v-btn>

							<v-btn color="error" small flat @click.stop="newReply = false">discard </v-btn>
							<v-divider></v-divider>
						</div>

						<div :style="'display: flex;'">
							<v-spacer></v-spacer>
							<v-btn small flat v-if="item.state != 'closed' && isDraftOrPreview" @click.stop="openReply(item._id)">
								<v-icon small left>mdi-reply</v-icon>Reply
							</v-btn>
						</div>
					</template>

					<template v-else>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<p class="overflow-ellipsis pointer" @click.stop="toggleExpand(item._id)" v-on="on">
									{{ removeHTMLTags(item.body) }}
								</p>
							</template>
							<span>Click to expand comment</span>
						</v-tooltip>
					</template>
				</v-card>
				<v-divider :key="'divider-' + index"></v-divider>
			</template>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import 'animate.css';
import { mapGetters, mapActions, mapState } from 'vuex';
import CommentsNavigation from './CommentsNavigation';
import CommentStatusIcon from './CommentStatusIcon';
import CommentsFilter from './CommentsFilter';

import {
	STORE_MODULE_COMMENTS,
	STORE_CONTENT_NAME,
	STATE_ITEM_REVISION,
	STATE_NAV_COMMENT,
	STATE_SELECTED_COMMENT,
	STATE_CURRENT_LOCATION,
	ACTION_SEND_COMMENTS,
	GETTER_COMMENTS,
	GETTER_ACTIVE_USER,
	ACTION_UPDATE_COMMENT_STATE,
	ACTION_SEND_REPLY,
	ACTION_SAVE_CURRENT_LOCATION,
	ACTION_SAVE_IS_COMMENT,
	ACTION_DELETE_COMMENT,
	ACTION_UPDATE_COMMENT,
	ACTION_UPDATE_SELECTED_COMMENT
} from '@/store_constants/content';
import { STORE_USER_NAME, STATE_PROFILE } from '@/store_constants/user';
import { PRichText, PtModal } from 'plant-common';
import { BETWEEN_VALUE, FROM_VALUE, N_A_TEXT, TO_VALUE, LOCATION_CURRENT_VALUE } from './const';
import { getType } from '../../../../utils/dbTool';

function isCommentInCurrentLocation(location, current_location) {
	if (location.slide_id) {
		return location.slide_id == current_location.slide_id;
	} else if (location.card_id) {
		return location.card_id == current_location.card_id;
	} else {
		return false;
	}
}

function hasReplies(comment) {
	if (!comment.replies?.length) return false;

	return comment.replies.some(reply => !reply.deleted_at && reply.user_id !== comment.user_id);
}

export default {
	name: 'WrapperCommentsDrawer',
	props: [],
	mixins: [PtModal],
	components: {
		CommentsNavigation,
		CommentStatusIcon,
		PRichText,
		CommentsFilter
	},
	data() {
		return {
			applyFilters: false,
			filter: {
				location: '',
				state: '',
				replied: '',
				user: '',
				date: {
					option: '',
					from_date: new Date().toISOString().substr(0, 10),
					to_date: new Date().toISOString().substr(0, 10)
				}
			},
			comment: '',
			reply: '',
			expanded: [],
			comment_id: null,
			editingComment: false,
			editingCommentParent: false,
			isDraftOrPreview: false,
			isActivityPreview: false,
			newComment: false,
			newReply: false,
			unupdatedComment: false
		};
	},
	mounted() {
		let path = this.$router.app.$route.fullPath.split('/');
		const urlPathMode = path[path.length - 1].split('#')[0];
		if (urlPathMode === 'draft' || urlPathMode === 'preview') {
			this.isDraftOrPreview = true;
		}
		if (urlPathMode === 'preview-activity') {
			this.isActivityPreview = true;
		}

		this.filter.location = LOCATION_CURRENT_VALUE;
	},
	filters: {
		timeAgo(value) {
			return moment(value).fromNow();
		},
		filterDate(value) {
			return moment(value).format('LLL');
		}
	},
	computed: {
		...mapState(STORE_USER_NAME, {
			profile: STATE_PROFILE
		}),
		...mapState(STORE_CONTENT_NAME, {
			itemRevision: STATE_ITEM_REVISION
		}),
		...mapState(STORE_MODULE_COMMENTS, {
			navigationComment: STATE_NAV_COMMENT,
			selectedComment: STATE_SELECTED_COMMENT,
			current_location: STATE_CURRENT_LOCATION
		}),
		...mapGetters(STORE_MODULE_COMMENTS, {
			comments: GETTER_COMMENTS,
			activeUser: GETTER_ACTIVE_USER
		}),
		currentContentHasLocation() {
			if (!this.itemRevision) return false;
      
      const contentsWithLocation = ['guide', 'lessonPlan', 'interactiveSlide'];

			return contentsWithLocation.includes(getType(this.itemRevision));
		},
		commentUsers() {
			var userArray = [{ text: N_A_TEXT, value: '' }];
			this.comments.forEach(comment => {
				userArray.push({
					text: comment.user_name,
					value: comment.user_id
				});
			});
			return userArray;
		},
		commentsReversed() {
			return this.comments.reverse();
		},
		commentsFiltered() {
			var filteredComments = this.commentsReversed;
			if (this.applyFilters) {
				// Location (current_view || all)
				if (this.filter.location == LOCATION_CURRENT_VALUE && this.currentContentHasLocation) {
					filteredComments = filteredComments.filter(comment =>
						comment.location && this.current_location
							? isCommentInCurrentLocation(comment.location, this.current_location)
							: false
					);
				}
				// State (open || closed)
				if (this.filter.state != '') {
					filteredComments = filteredComments.filter(comment => comment.state == this.filter.state);
				}
				// Replied (true || false)
				if (this.filter.replied != '') {
					filteredComments = filteredComments.filter(comment =>
						this.filter.replied == 'replied' ? hasReplies(comment) : !hasReplies(comment)
					);
				}
				// User (user_id)
				if (this.filter.user != '') {
					filteredComments = filteredComments.filter(comment => comment.user_id == this.filter.user);
				}
				// Date (from || to || between)
				if (this.filter.date.option != '') {
					var fromTimestamp = new Date(this.filter.date.from_date + ' 00:00:00').getTime();
					var toTimestamp = new Date(this.filter.date.to_date + ' 23:59:59').getTime();
					switch (this.filter.date.option) {
						case FROM_VALUE:
							filteredComments = filteredComments.filter(comment => {
								if (comment.updated_at) {
									return fromTimestamp <= comment.updated_at;
								} else {
									return fromTimestamp <= comment.comment_date;
								}
							});
							break;
						case TO_VALUE:
							filteredComments = filteredComments.filter(comment => {
								if (comment.updated_at) {
									return comment.updated_at <= toTimestamp;
								} else {
									return comment.comment_date <= toTimestamp;
								}
							});
							break;
						case BETWEEN_VALUE:
							filteredComments = filteredComments.filter(comment => {
								if (comment.updated_at) {
									return fromTimestamp <= comment.updated_at && comment.updated_at <= toTimestamp;
								} else {
									return fromTimestamp <= comment.comment_date && comment.comment_date <= toTimestamp;
								}
							});
							break;
					}
				}
			}
			// Do not return deleted comments
			filteredComments = filteredComments.filter(comment => !comment.deleted_at);
			return filteredComments;
		}
	},
	methods: {
		...mapActions(STORE_MODULE_COMMENTS, [
			ACTION_SEND_COMMENTS,
			ACTION_UPDATE_COMMENT_STATE,
			ACTION_SEND_REPLY,
			ACTION_SAVE_CURRENT_LOCATION,
			ACTION_SAVE_IS_COMMENT,
			ACTION_DELETE_COMMENT,
			ACTION_UPDATE_COMMENT,
			ACTION_UPDATE_SELECTED_COMMENT
		]),
		doCloseComment(item) {
			this[ACTION_UPDATE_COMMENT_STATE]({ comment: item, state: 'closed' });
		},
		async saveAndCloseComment(item) {
			await this.sendReplyAndClear();
			this.doCloseComment(item);
		},
		doResolveComment(item) {
			this[ACTION_UPDATE_COMMENT_STATE]({ comment: item, state: 'resolved' });
		},
		async saveAndResolveComment(item) {
			await this.sendReplyAndClear();
			this.doResolveComment(item);
		},
		doOpenComment(item) {
			this[ACTION_UPDATE_COMMENT_STATE]({ comment: item, state: 'open' });
		},
		async saveAndOpenComment(item) {
			await this.sendReplyAndClear();
			this.doOpenComment(item);
		},
		openCard(item) {
			if (!this.expanded.includes(item._id)) {
				this.expanded.push(item._id);
			}
			this[ACTION_SAVE_CURRENT_LOCATION](item.location);
			this[ACTION_UPDATE_SELECTED_COMMENT](item);
		},
		sendCommentAndClear() {
			if (this.comment !== '' && this.comment !== '<p></p>') {
				let comment_info = {
					body: this.comment,
					comment: this.comment,
					user_id: this.activeUser._id,
					user_name: this.activeUser.name,
					comment_date: new Date()
				};
				this[ACTION_SEND_COMMENTS](comment_info);
				this.comment = '';
				this.newComment = false;
			}
		},
		async sendReplyAndClear() {
			if (this.reply !== '' && this.reply !== '<p></p>' && this.comment_id !== null) {
				let comment_info = {
					body: this.reply,
					comment: this.reply,
					user_id: this.activeUser._id,
					user_name: this.activeUser.name,
					comment_date: new Date()
				};
				await this[ACTION_SEND_REPLY]({ reply: comment_info, id: this.comment_id });
				this.reply = '';
				this.comment_id = null;
				this.newReply = false;
			}
		},
		openReply(commentId) {
			if (!this.expanded.includes(commentId)) {
				this.toggleExpand(commentId);
			}
			this.comment_id = commentId;
			this.newReply = true;
			this.newComment = false;
		},
		filterDate(value) {
			return moment(value).format('LLL');
		},
		openEditComment(comment, parent) {
			this.unupdatedComment = JSON.parse(JSON.stringify(comment.body));
			this.editingComment = comment;
			this.editingCommentParent = parent;
			if (!parent) {
				var index = this.expanded.findIndex(e => e == comment._id);
				if (index == -1) {
					this.expanded.push(comment._id);
				}
			}
		},
		updateContentComment() {
			this[ACTION_UPDATE_COMMENT]({
				comment: this.editingComment,
				parent: this.editingCommentParent
			});
			this.editingComment = false;
			this.editingCommentParent = false;
		},
		openDeleteComment(comment, parent) {
			this.$modal('confirm', {
				title: `Are you sure you want to delete selected comment?`,
				accept: {
					color: 'error',
					text: 'Delete',
					icon: 'mdi-delete'
				},
				cancel: { text: 'cancel', color: 'shades' }
			}).then(r => {
				if (!r) return;
				this[ACTION_DELETE_COMMENT]({ comment, parent });
			});
		},
		toggleExpand(_id) {
			var index = this.expanded.findIndex(e => e == _id);
			if (index > -1) {
				this.expanded.splice(index, 1);
			} else {
				this.expanded.push(_id);
			}
		},
		discardEditComment(item) {
			item.body = this.unupdatedComment;
			this.editingComment = false;
			this.unupdatedComment = false;
		},
		getRepliesNumber(replies) {
			return replies ? replies.filter(reply => !reply.deleted_at).length : 0;
		},
		expandAllComments() {
			this.minimizeAllComments();
			this.commentsFiltered.forEach(comment => {
				this.toggleExpand(comment._id);
			});
		},
		minimizeAllComments() {
			this.expanded = [];
		},
		removeHTMLTags(str) {
			if (str === null || str === '') return false;
			else str = str.toString();

			// Regular expression to identify HTML tags in
			// the input string. Replacing the identified
			// HTML tag with a null string.
			return str.replace(/(<([^>]+)>)/gi, '');
		}
	},
	watch: {
		comment: {
			immediate: true,
			deep: true,
			handler() {
				if (this.comment == '') {
					this[ACTION_SAVE_IS_COMMENT](false);
				} else {
					this[ACTION_SAVE_IS_COMMENT](true);
				}
			}
		},
		selectedComment: {
			immediate: true,
			handler() {
				if (this.selectedComment) {
					this.$nextTick(() => {
						const commentSelected = document.getElementById(this.selectedComment._id);
						commentSelected.scrollIntoView();
					});
				}
			}
		},
		filter: {
			inmediate: true,
			deep: true,
			handler() {
				this.applyFilters = true;
			}
		}
	}
};
</script>

<style scoped>
.comments-drawer {
	position: relative;
	height: calc(100vh - 110px);
}

.comment-actions {
	right: 0px;
	position: absolute;
}

.comment-list {
	max-height: calc(100% - 225px);
	overflow: scroll;
	overflow-x: hidden;
}
.comment-list-viewer {
	max-height: calc(100% - 10px);
	overflow: scroll;
	overflow-x: hidden;
}

.reply-message button.v-btn.v-btn--small {
	margin-left: 4px;
	margin-right: 4px;
}

.reply-message button.v-btn.v-btn--small .v-icon--left {
	margin-right: 8px;
}

.pointer {
	cursor: pointer;
}

.overflow-ellipsis {
	width: inherit;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0px 20px;
	font-style: italic;
}
</style>
