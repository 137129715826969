<template>
  <basic-block :value="value" :mode="mode" :state.sync="state" @goToBookmarkButton="handlerBookmarkButton">
    <template v-slot:main>
      <div class="lp-block-button">
        <!-- Button in edit mode -->
        <button-edit v-if="mode == 'edit'" :value="value" :state="state" />
        <!-- Button view mode -->
        <button-view-actions
          v-else
          :value="value"
          :state="state"
          @goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
          @openPopupClick="blockController.overlay = $event" />
      </div>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ButtonViewActions from "./BlockView.vue"
import ButtonEdit from "./BlockEdit.vue"
import _ from "lodash"
import uuid from "../../../../utils/uuid.js"

export default {
  name: "BlockButton",
  inject: ["blockController", "lessonPlanController"],
  components: {
    BasicBlock,
    ButtonViewActions,
    ButtonEdit
  },
  props: ["value", "mode"],
  data() {
    return {
      menu: false,
      state: "not-selected"
    }
  },
  created() {
    // For backwards compatibility that had local actions
    if (this.value && this.value.data && this.value.data.hasOwnProperty("actions")) {
      this.$set(this.value, "actions", this.value.data.actions)
      this.$delete(this.value.data, "actions")
    }
    // Backwards compatibility: actions with no id or parent
    if (this.value && this.value.actions && this.value.actions.find(action => action.trigger == "click")) {
      let actionClick = this.value.actions.find(action => action.trigger == "click")
      if (!actionClick.id) {
        this.$set(actionClick, "id", uuid())
      }
      if (!actionClick.parent) {
        this.$set(actionClick, "parent", this.value.id)
      }
    }
  },
  methods: {
    handlerBookmarkButton(bookmark_target) {
      const slideDestination = this.lessonPlanController.getSlideFromBookmark(bookmark_target)
      if (slideDestination.element) {
        const slideNav = this.lessonPlanController.slideNavigationMap.find(
          slideN => slideN.id === slideDestination.element.id
        )
        if (slideNav.available) {
          this.value.data.visited = true
        }
      } else {
        // Destination slide does not exist
        this.value.data.visited = true
      }
    }
  }
}
</script>

<style>
.lp-block-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-weight: 500;
}
</style>