<template>
  <div>
    <v-container fluid>
      <v-layout wrap>
        <v-flex xs6 class="px-3">
          <v-text-field
            v-for="(value, key) in section.fields"
            :key="key"
            v-model="section.fields[key]"
            :label="key"
          ></v-text-field>
        </v-flex>
        <v-flex xs6 class="px-3 wrapper_enabling">
          <v-text-field
            label="Internal link name"
            v-model="section.internal_link_name"
          ></v-text-field>
          <span v-if="learning_objectives">
            <v-select
              v-model="section.enabling_objectives"
              :items="learning_objectives"
              :item-text="
                obj => (obj.custom_id ? obj.custom_id + ' - ' : '') + obj.title
              "
              item-value="id"
              label="Enabling objective"
              multiple
            >
              <template v-slot:selection="{ item, index }">
                <span>
                  {{
                    (item.custom_id ? item.custom_id + " - " : "") + item.title
                  }}
                </span>
              </template>
            </v-select>
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ContentSelectorDropdown from "./ContentSelectorDropdown";

import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
export default {
  name: "PropertiesSection",
  inject: ["guideController"],
  components: { ContentSelectorDropdown },
  computed: {
    ...mapInjectedData("guideController", ["guide"]),
    section() {
      if (
        !this.guideController.selectedCard.fields ||
        this.guideController.selectedCard.fields.length == 0
      ) {
        if (
          this.defaultSection &&
          this.defaultSection[0] &&
          this.defaultSection[0].fields
        ) {
          this.guideController.selectedCard.fields = JSON.parse(
            JSON.stringify(this.defaultSection[0].fields)
          );
        }
      }
      return this.guideController.selectedCard;
    },
    learning_objectives() {
      if (
        this.guide.learning_objective &&
        this.guide.learning_objective.body &&
        this.guide.learning_objective.body.contents &&
        this.guide.learning_objective.body.contents.enabling
      ) {
        return this.guide.learning_objective.body.contents.enabling;
      } else {
        return [];
      }
    },
    defaultSection() {
      if (
        this.guide.template &&
        this.guide.template.body &&
        this.guide.template.body.contents &&
        this.guide.template.body.contents.card_templates &&
        this.guide.template.body.contents.card_templates.length
      ) {
        return this.guide.template.body.contents.card_templates.filter(
          e => e.name == "section"
        );
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped>
.wrapper_enabling {
  max-height: 250px;
  overflow: auto;
}
</style>