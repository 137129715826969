<template>
  <div class="library-content__wrapper">
    <div class="view-menu">
      <v-toolbar flat class="view-menu__options">
        <div class="view-menu__options--image-type">
          <ContentTypeIcon v-if="content" :type="getType(content)" />
        </div>
        <v-divider vertical></v-divider>

        <div class="view-menu__options--custom-id">
          <v-chip small label dark>
            <v-icon small>mdi-tag</v-icon>
            {{ getCustomId(content) }}
          </v-chip>

          <div>{{ getTitle(content) }}</div>
        </div>
        <v-spacer></v-spacer>
        <v-btn color="primary lighten-2" depressed dark class="no-print" @click="retakeScreenshot">
          Retake screenshot
        </v-btn>
      </v-toolbar>
    </div>

    <div class="loading-screenshot" v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <img v-else :src="componentSource" class="book-plant-library__image" alt="Library component image" />
  </div>
</template>

<script>
import ptApiClient from "plant-api-client"
import { ContentTypeIcon } from "plant-common"
import { getType, getTitle, getCustomId } from "../../../../utils/dbTool"
import { libraryApi } from "../../../../api"
import PServiceContent from "plant-common/src/services/PServiceContent"

const THUMBNAIL_PREVIEW_DEFAULT = "default"

export default {
  name: "PlantLibraryComponent",
  props: ["origin", "pinned_view"],
  components: { ContentTypeIcon },
  data() {
    return {
      content: null,
      componentSource: null,
      isLoading: false,
      view: null
    }
  },
  async created() {
    if (this.pinned_view) {
      this.view = JSON.parse(this.pinned_view)
    }
    if (!this.view) this.view = { interface: "simple" }
    if (this.view && !this.view.interface) this.$set(this.view, "interface", "simple")
    if (this.origin) {
      this.content = await PServiceContent.getContentFromRef(this.origin)
      this.getContentPreview()
    }
  },
  computed: {
    pinnedView() {
      return this.view ? this.view : undefined
    }
  },
  methods: {
    getCustomId,
    getTitle,
    getType,
    splitOrigin() {
      const regexCrossLibrary = new RegExp("plant://")
      if (regexCrossLibrary.test(this.origin)) {
        var [protocol, empty, library_id, content, content_id, major_revision, minor_revision] = this.origin.split("/")
      } else {
        var [, , content_id, major_revision, minor_revision] = this.origin.split("/")
      }
      return {
        library_id: library_id ? library_id : false,
        content_id,
        major_revision,
        minor_revision
      }
    },
    async getContentPreview() {
      this.isLoading = true
      const { library_id, content_id, major_revision, minor_revision } = this.splitOrigin()
      if (library_id) {
        const viewJsonStr = JSON.stringify(this.pinnedView)
        const viewJsonEncode = encodeURI(viewJsonStr)
        const { request } = await libraryApi.getContentPreviewUrl(
          library_id,
          content_id,
          major_revision,
          minor_revision,
          THUMBNAIL_PREVIEW_DEFAULT,
          viewJsonEncode,
          Math.round(Math.random() * (12 - 6) + 6),
          { timeout: 60 * 1000 }
        )
        this.componentSource = request ? request.responseURL : ""
      } else {
        this.componentSource = ptApiClient.getContentPreviewUrl(
          content_id,
          major_revision,
          minor_revision,
          THUMBNAIL_PREVIEW_DEFAULT,
          this.pinnedView,
          Math.round(Math.random() * (12 - 6) + 6)
        )
      }

      await fetch(this.componentSource)
      this.isLoading = false
    },
    async retakeScreenshot() {
      const { library_id, content_id, major_revision, minor_revision } = this.splitOrigin()
      this.componentSource = null
      if (library_id) {
        try {
          await libraryApi.deleteContentPreview(
            library_id,
            content_id,
            major_revision,
            minor_revision,
            THUMBNAIL_PREVIEW_DEFAULT,
            this.pinnedView
          )
        } catch (e) {
          // nothing
        }
      } else {
        await ptApiClient.deleteContentPreview(
          content_id,
          major_revision,
          minor_revision,
          THUMBNAIL_PREVIEW_DEFAULT,
          this.pinnedView
        )
      }
      this.getContentPreview()
    }
  }
}
</script>

<style scoped>
.library-content__wrapper {
  border: 1px solid lightgrey;
}

.view-menu {
  border-bottom: 1px solid lightgrey;
}

.view-menu__options--image-type {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.view-menu__options--image-type > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
}

.view-menu__options--custom-id .v-chip {
  margin: 0;
  margin-bottom: 4px;
}

.view-menu__options--pin-menu .v-list--disabled .v-list__tile .v-list__tile__avatar i {
  color: inherit;
}

.loading-screenshot {
  text-align: center;
  height: 160px;
  margin-top: 112px;
}
</style>
