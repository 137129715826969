<template>
  <p-dialog
    title="Variables"
    icon="mdi-variable"
    :value="openDialog"
    @input="openDialog = false"
    maxWidth="75vw"
    maxHeight="80vh"
    minHeight="0vh"
    permanent>
    <template v-slot:activator>
      <v-btn
        @click="openDialog = true"
        color="#616161"
        outline
        small
        style="font-family: monospace; text-transform: none">
        <v-icon left small>mdi-variable</v-icon>
        <span class="text-overflow: ellipsis; width: 50px; display: block; overflow: hidden">{{
          value ? value : "[variable]"
        }}</span>
      </v-btn>
      <v-icon v-if="value" left small @click="$emit('input', false)">mdi-delete-outline</v-icon>
    </template>
    <v-toolbar flat color="white">
      <v-spacer></v-spacer>
      <v-text-field label="Search" prepend-inner-icon="mdi-magnify" hide-details outline :value="search"></v-text-field>
    </v-toolbar>
    <v-data-table :headers="headers" :items="blockVariables" :rows-per-page-items="[10]" :search="search">
      <template v-slot:items="props">
        <tr v-if="!props.item.private" @click="selected = selected === props.item.name ? false : props.item.name">
          <td>
            <v-checkbox
              hide-details
              :value="selected === props.item.name"
              @change="selected = selected === props.item.name ? false : props.item.name"
              @click="selected = selected === props.item.name ? false : props.item.name"></v-checkbox>
          </td>
          <td>
            <kbd>{{ props.item.name }}</kbd>
          </td>
          <td>{{ props.item.title }}</td>
          <td>
            <kbd color="green">{{ props.item.type }}</kbd>
          </td>
          <td v-if="props.item.type !== 'object'">{{ props.item.value }}</td>
          <td v-else>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">Object</span>
              </template>
              <span>{{ props.item.value }}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <template v-slot:actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" flat @click.native="openDialog = false">Close</v-btn>
      <v-btn
        color="success"
        outline
        flat
        @click="
          $emit('input', selected)
          openDialog = false
        "
        :disabled="!selected"
        >Select</v-btn
      >
    </template>
  </p-dialog>
</template>

<script>
import { PDialog } from "plant-common"
export default {
  components: { PDialog },
  props: ["value", "blockVariables"],
  data() {
    return {
      selected: "",
      search: "",
      openDialog: false,
      headers: [
        {
          text: "",
          sortable: false,
          value: ""
        },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "Title",
          value: "title"
        },
        {
          text: "Type",
          value: "type"
        },
        {
          text: "Current Value",
          value: "value"
        }
      ]
    }
  }
}
</script>
