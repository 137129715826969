<template>
  <p-property-tile icon="mdi-book-education-outline" :title="quiz.title" :showTitleTooltip="true">
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-if="canShowResults" v-on="on" class="mr-2" @click="openQuizModal(quiz)">
            mdi-book-arrow-right-outline
          </v-icon>
          <v-icon v-else v-on="on" class="custom-disabled"> mdi-book-cancel-outline </v-icon>
        </template>
        <span v-if="canShowResults">Open activities related to objective</span>
        <span v-else>Activities assessment not available yet</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-if="canShowResults" :color="performanceStates[quiz.currentLevel].color" v-on="on"
            >mdi-circle-slice-8</v-icon
          >
          <v-icon v-else :color="performanceStates[NOT_EVALUATED].color" v-on="on">mdi-circle-slice-8</v-icon>
        </template>
        <span v-if="canShowResults">{{ performanceStates[quiz.currentLevel].name }}</span>
        <span v-else>{{ performanceStates[NOT_EVALUATED].name }}</span>
      </v-tooltip>
    </div>
  </p-property-tile>
</template>

<script>
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"
import { PtModal } from "plant-common"
import AnswersModal from "./modal/AnswersModal.vue"
import performanceStates from "../constants/performaceStates.js"

const NOT_EVALUATED = "notEvaluated"

export default {
  name: "QuizInformation",
  inject: ["lessonPlanController"],
  mixins: [PtModal],
  props: {
    quiz: Object
  },
  components: {
    PPropertyTile
  },
  data() {
    return {
      performanceStates: performanceStates,
      NOT_EVALUATED: NOT_EVALUATED
    }
  },
  computed: {
    canShowResults() {
      const quizSlide = this.lessonPlanController.getSlideFromSlideId(this.quiz.id)
      return this.lessonPlanController.isQuizSubmitted(quizSlide)
    }
  },
  methods: {
    async openQuizModal(quiz) {
      const slideIdToNavigate = await this.$modal(AnswersModal, {
        activities: quiz.activities
      })
      if (!slideIdToNavigate) return
      this.lessonPlanController.selectSlideById(slideIdToNavigate)
    }
  }
}
</script>

<style scoped>
.custom-disabled {
  cursor: auto;
  opacity: 0.6;
}
</style>
