import { render, staticRenderFns } from "./ActivitiesDialog.vue?vue&type=template&id=4555b466&scoped=true"
import script from "./ActivitiesDialog.vue?vue&type=script&lang=js"
export * from "./ActivitiesDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4555b466",
  null
  
)

export default component.exports