import ptCoreClient from "plant-core-client"
import ptApiClient from "plant-api-client"
import router from "@/router"
import {
  STORE_USER_NAME,
  STATE_TOKEN,
  STATE_PLANT_REFRESH_TOKEN,
  STATE_VERSION_INFO,
  STATE_PROFILE,
  STATE_PENDING,
  MUTATION_SET_FLEET_LOGIN,
  MUTATION_SET_REFRESH_AUTH,
  MUTATION_SET_LIBRARY,
  MUTATION_DESTROY_SESSION,
  MUTATION_LOGIN_PENDING,
  MUTATION_LOGIN_ERROR,
  ACTION_API_LOGIN,
  ACTION_SELECT_LIBRARY,
  ACTION_LOGIN_FLEET_ERROR,
  ACTION_LOGOUT,
  ACTION_REFRESH_AUTH,
  GETTER_IS_ADMIN,
  GETTER_IS_SUPERVISOR,
  GETTER_IS_INSTRUCTOR,
  GETTER_IS_STUDENT
} from "@/store_constants/user"
import { roles } from "@/config/constants"
import { NOTIFICATION_INFINITE_TIMEOUT } from "@/store_constants/notifications"

ptCoreClient.baseURL = window.__env__.VUE_APP_FLEET_API_ENDPOINT
export default {
  namespaced: true,
  name: STORE_USER_NAME,
  state: {
    [STATE_TOKEN]: "",
    [STATE_PLANT_REFRESH_TOKEN]: "",
    [STATE_VERSION_INFO]: [],
    [STATE_PROFILE]: false,
    [STATE_PENDING]: false
  },
  getters: {
    [GETTER_IS_ADMIN](state) {
      return roles.admin.value == state[STATE_PROFILE].role
    },
    [GETTER_IS_SUPERVISOR](state) {
      return roles.supervisor.value == state[STATE_PROFILE].role
    },
    [GETTER_IS_INSTRUCTOR](state) {
      return roles.instructor.value == state[STATE_PROFILE].role
    },
    [GETTER_IS_STUDENT](state) {
      return roles.student.value == state[STATE_PROFILE].role
    }
  },
  mutations: {
    [MUTATION_SET_FLEET_LOGIN](state, { profile, token, version }) {
      state[STATE_TOKEN] = token
      state[STATE_PROFILE] = profile
      state[STATE_PENDING] = false
      state[STATE_VERSION_INFO] = [
        {
          label: "Build date: ",
          info: process.env.VUE_APP_BUILD
        },
        {
          label: "Frontend Version: ",
          info: process.env.VUE_APP_VERSION
        }
      ]
      if (version)
        state[STATE_VERSION_INFO].push({
          label: "Fleet Server Version: ",
          info: version
        })
    },
    [MUTATION_SET_LIBRARY](state, { token, version }) {
      if (state[STATE_VERSION_INFO].length > 3) state[STATE_VERSION_INFO].pop()
      state[STATE_TOKEN] = token
      state[STATE_PENDING] = false
      if (version)
        state[STATE_VERSION_INFO].push({
          label: "Library Server Version: ",
          info: version
        })
    },
    [MUTATION_DESTROY_SESSION](state) {
      state[STATE_TOKEN] = ""
      state[STATE_PROFILE] = false
      state[STATE_PLANT_REFRESH_TOKEN] = ""
      state[STATE_PENDING] = false
      state[STATE_VERSION_INFO] = []
      router.push("/login")
    },
    [MUTATION_LOGIN_PENDING](state) {
      state[STATE_PENDING] = true
    },
    [MUTATION_LOGIN_ERROR](state) {
      state[STATE_PENDING] = false
    },
    [MUTATION_SET_REFRESH_AUTH](state, { jwtToken, refreshToken, user }) {
      state[STATE_TOKEN] = jwtToken
      state[STATE_PLANT_REFRESH_TOKEN] = refreshToken
      state[STATE_PROFILE] = user
      console.log("***JWT expired, Refresh token updated***")
    }
  },
  actions: {
    [ACTION_API_LOGIN]({ commit, dispatch }, credentials) {
      return ptCoreClient
        .login(credentials)
        .then(res => {
          if (!res.isAxiosError) {
            commit(MUTATION_SET_FLEET_LOGIN, res)
            dispatch("notifications/info", { message: "Login Successful" }, { root: true })
          } else {
            let errorMessage = "PLANT Login Error"
            if (res.response && res.response.data && res.response.data.message) {
              errorMessage = res.response.data.message
              if (res.response.status === 404) {
                errorMessage = errorMessage + ". Sorry, your email is not added to PLANT. Please, contact the platform administrator"
              }
            }
            dispatch(ACTION_LOGIN_FLEET_ERROR, errorMessage)
          }
          return res
        })
        .catch(e => {
          dispatch(ACTION_LOGIN_FLEET_ERROR)
          return e
        })
    },
    async [ACTION_SELECT_LIBRARY]({ commit, dispatch, state }) {
      return ptApiClient
        .login({ token: state[STATE_TOKEN] })
        .then(res => {
          commit(MUTATION_SET_LIBRARY, res)
          dispatch("notifications/info", { message: "Plant Selected" }, { root: true })
          return res
        })
        .catch(e => {
          return e
        })
    },
    [ACTION_LOGIN_FLEET_ERROR]({ commit, dispatch }, errorMessage) {
      commit(MUTATION_DESTROY_SESSION)
      dispatch("notifications/error", {
        message: errorMessage,
        timeout: NOTIFICATION_INFINITE_TIMEOUT
      }, { root: true })
    },
    [ACTION_LOGOUT]({ commit, dispatch }) {
      commit(MUTATION_DESTROY_SESSION)
      localStorage.lastPlantRoute = ""
      dispatch("library/leavePlant", {}, { root: true })
    },
    [ACTION_REFRESH_AUTH]({ commit }, authResponse) {
      commit(MUTATION_SET_REFRESH_AUTH, authResponse)
    }
  }
}
