<template>
  <p-panel title="Slide Explorer" color="black darken-1">
    <v-list dense class="tree-explorer">
      <Tree
        :value="selectionTree"
        :ondragstart="onDragStart"
        :draggable="true"
        :droppable="true"
        :eachDroppable="eachDroppable"
        @drop="onDrop">
        <div slot-scope="{ node, index, path, tree }" :class="node.state" class="node">
          <v-list-tile @click="clickedNode(node)">
            <div @click.stop="tree.toggleFold(node, path)" class="node-icon">
              <v-icon v-if="node.hasOwnProperty('children')">
                {{ node.$folded ? "mdi-chevron-right" : "mdi-chevron-down" }}
              </v-icon>
            </div>

            <div class="node-name">
              <v-list-tile-title
                class="node-name-edit"
                :contenteditable="true"
                @blur="v => setNodeName(v, node)"
                @keydown.enter="$event.target.blur()">
                {{ node.name || `${node.type} ${node.id.slice(0, 4)}` }}
              </v-list-tile-title>
            </div>

            <div class="node-icon action">
              <div v-if="!isLastElement(path, index)" @click.stop="blockController.moveBlockDown(node.id)">
                <v-icon> mdi-arrow-down-bold </v-icon>
              </div>
              <div v-else class="disabled">
                <v-icon> mdi-arrow-down-bold </v-icon>
              </div>
            </div>
            <div class="node-icon action">
              <div v-if="index > 0" @click.stop="blockController.moveBlockUp(node.id)">
                <v-icon> mdi-arrow-up-bold </v-icon>
              </div>
              <div v-else class="disabled">
                <v-icon> mdi-arrow-up-bold </v-icon>
              </div>
            </div>
            <div class="node-icon action"></div>
            <v-btn
              icon
              flat
              :disabled="!blockController.canDeleteBlock(node.id)"
              @click.stop="blockController.deleteBlock(node.id)"
              class="node-icon action">
              <v-icon> mdi-delete </v-icon>
            </v-btn>
          </v-list-tile>
        </div>
      </Tree>
    </v-list>
  </p-panel>
</template>

<script>
import { Tree, Fold, Draggable } from "he-tree-vue"
import PPanel from "../utils/PPanel.vue"

export default {
  inject: ["blockController"],
  components: {
    Tree: Tree.mixPlugins([Fold, Draggable]),
    PPanel
  },
  data() {
    return {
      isDragging: false
    }
  },
  computed: {
    selectionTree() {
      return this.reverseArray(JSON.parse(JSON.stringify(this.blockController.selectionTree)))
    }
  },
  created() {},
  destroyed() {},
  methods: {
    reverseArray(array) {
      if (!array) return []
      array.forEach(item => {
        if (item.children && item.children.length > 0) {
          item.children = this.reverseArray(item.children)
        }
      })
      if (array.find(obj => obj.type === "tabOrCard")) {
        return array
      }
      return array.reverse()
    },
    eachDroppable(currentPath, tree, store) {
      // This function return false if trying to drop a node outside its parent
      const { startPath } = store
      var partentStartPath = [...startPath]
      partentStartPath.pop()
      var parentDropPath = [...currentPath]
      parentDropPath.pop()
      if (startPath.length == currentPath.length) {
        // Verify nodes have the same parent
        return JSON.stringify(partentStartPath) === JSON.stringify(parentDropPath)
      } else if (currentPath.length === startPath.length - 1) {
        // allow drop on first position if nodes have same parent
        return JSON.stringify(partentStartPath) === JSON.stringify(currentPath)
      } else {
        return false
      }
    },
    onDrop(store) {
      const { dragNode, startPath, targetPath } = store

      if (startPath.length < targetPath.length) {
        // avoid to push block inside target children
        targetPath.pop()
      }

      this.$nextTick(() => {
        this.blockController.reorderBlock(dragNode.id, this.selectionTree, dragNode.type != "tabOrCard")
        this.blockController.selectionClear()
        this.blockController.selectionAdd(dragNode.id)
      })
    },
    onDragStart(tree, store) {
      // check target path to set $droppable to false if not same level
      function setDroppable(elements) {
        for (var element of elements) {
          if (element.hasOwnProperty("children")) {
            setDroppable(element.children)
          }
        }
      }
      this.isDragging = true
      setDroppable(this.blockController.selectionTree)
    },
    clickedNode(node) {
      if (this.isDragging) {
        this.isDragging = false
      } else {
        this.blockController.overlay = null
        this.blockController.selectionClear()
        this.blockController.selectionAdd(node.id)
      }
    },
    isLastElement(path, index) {
      if (path[path.length - 1] != index) return false

      function getLengthCurrentLevel(elements, levelDeep, pathIndex) {
        if (levelDeep == 1) {
          return elements.length
        }
        return getLengthCurrentLevel(elements[path[pathIndex]].children, --levelDeep, ++pathIndex)
      }
      const currentLength = getLengthCurrentLevel(this.selectionTree, path.length, 0)

      return index == currentLength - 1
    },
    setNodeName(value, node) {
      const v = value.target.innerText
      node.name = v
      this.blockController.setBlockName(node.id, v)
    },
    nodeIsActive(node) {
      if (this.selectedBlock == null) return false
      return this.selectedBlock.id == node.id
    },
    getBlockObject(block, active) {
      const blockObject = {}
      const name = block.name == "" ? block.type : block.name
      const displayName = name.length > 1 ? name[0].toUpperCase() + name.slice(1) : name

      blockObject["text"] = displayName
      blockObject["active"] = active || this.nodeIsActive(block)
      blockObject["id"] = block.id
      if (block.type === "carousel") {
        blockObject["children"] = this.getChildrenCardsFromCarousel(block.data.cards, blockObject["active"])
      } else if (block.type === "tabs") {
        blockObject["children"] = this.getChildrenCardsFromCarousel(block.data.tabs, blockObject["active"])
      }

      return blockObject
    },
    getCardObject(card, active) {
      const cardObject = {}

      cardObject["text"] = card.title
      cardObject["active"] = active || this.nodeIsActive(card)
      cardObject["children"] = []
      card.blocks.forEach(block => {
        cardObject["children"].push(this.getBlockObject(block, cardObject["active"]))
      })

      return cardObject
    },
    getChildrenCardsFromCarousel(cards, active) {
      const blocksInCards = []

      cards.forEach(card => {
        blocksInCards.push(this.getCardObject(card, active))
      })

      return blocksInCards
    }
  }
}
</script>

<style>
.tree-explorer {
  overflow: auto;
  height: calc(100vh - 200px);
}
.he-tree .tree-node {
  padding: 0 !important;
  margin-bottom: 0 !important;
}
.node:hover {
  background-color: #e7e7e7;
}
.node:hover .node-icon.action {
  display: block;
}
.node-icon {
  width: 20px;
  margin-left: 8px;
  text-align: right;
}
.node-icon.action {
  display: none;
}
.tree-explorer .v-icon {
  cursor: pointer;
}
.node-name,
.node-name-edit {
  padding: 0 16px 0 4px;
  width: 75%;
  max-width: 75%;
}
.primary-selected {
  background: #95cfff;
}
.secondary-selected {
  background: #d3ebff;
}
.edit-content {
  background: #c5e5ff;
}
.disabled {
  opacity: 50%;
  pointer-events: none;
}
</style>
