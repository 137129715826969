<template>
  <v-container fluid class="px-0 py-0">
    <factory-list v-if="library_id" />
    <detail-drawer />
  </v-container>
</template>
<script>
import DetailDrawer from "./components/DetailDrawer.vue"
import FactoryList from "./components/FactoryList.vue"
import { mapState, mapActions } from "vuex"
import { STORE_LIBRARY_NAME, ACTION_SELECT_PLANT, STATE_PLANT_ID } from "@/store_constants/library"
import { STORE_USER_NAME, ACTION_SELECT_LIBRARY } from "@/store_constants/user"
import { STORE_WORKFLOWS_NAME, ACTION_ITEM_REVISION_DRAFTS } from "@/store_constants/workflows"

export default {
  props: ["library_id"],
  components: { FactoryList, DetailDrawer },
  data() {
    return {
      loading: true
    }
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, [STATE_PLANT_ID])
  },
  methods: {
    ...mapActions(STORE_WORKFLOWS_NAME, [ACTION_ITEM_REVISION_DRAFTS]),
    ...mapActions(STORE_LIBRARY_NAME, [ACTION_SELECT_PLANT]),
    ...mapActions(STORE_USER_NAME, {
      selectLibrary: ACTION_SELECT_LIBRARY
    })
  },
  watch: {
    library_id: {
      immediate: true,
      async handler() {
        if (this[STATE_PLANT_ID] !== this.library_id) {
          this.loading = true
          const plant = await this[ACTION_SELECT_PLANT](this.library_id)
          await this.selectLibrary()
        }
        await this[ACTION_ITEM_REVISION_DRAFTS]()
        this.loading = false
      }
    }
  }
}
</script>

