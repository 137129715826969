import Content from "./index.vue"
export default {
  path: "notifications",
  name: "Notifications",
  component: Content,
  meta: {
    icon: "mdi-bell-outline",
    breadCrumb: "Notifications"
  }
}
