<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrowarrow-180-deg-deg-control-handler-x"
        :x="r"
        :x-range="[0, (h - (tH - tB) / 2) / 2]"
        :y="0"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.radius = x / ((h - (tH - tB) / 2) / 2))" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-180-deg-control-handler-x"
        :x="s"
        :x-range="[w * 0.25, Math.min(w, hW)]"
        :y="0"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.slope = x / w)" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-180-deg-control-handler-x"
        :x="hW"
        :x-range="[w * 0.51, w]"
        :y="0"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:x="updateHeadWidth" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-180-deg-control-handler-y"
        :x="w"
        :y="tH"
        :y-range="[5, h]"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:y="y => (value.data.controls.thickHead = y / h)" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-180-deg-control-handler-y"
        :x="w"
        :y="h - tB"
        :y-range="[Math.max(h * 0.51, tH), h - 5]"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:y="y => (value.data.controls.thickBody = (h - y) / h)" />
      <svg
        class="shape-arrow-180-deg"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M ${hW} ${tH / 2}
                L ${s} 0
                L ${s} ${(tH - tB) / 2}
                L ${r} ${(tH - tB) / 2}
                A ${r} ${r} 0 0 0 0 ${(tH - tB) / 2 + r}
                L 0 ${(tH - tB) / 2 + r}
                L 0 ${h - r}
                A ${r} ${r} 0 0 0 ${r} ${h}
                L ${r} ${h}
                L ${w} ${h}
                L ${w} ${h - hB}
                L ${tB + rI} ${h - hB}
                A ${rI} ${rI} 0 0 1 ${tB} ${h - hB - rI}
                L ${tB} ${h - hB - rI}
                L ${tB} ${(tH + tB) / 2 + rI}
                A ${rI} ${rI} 0 0 1 ${tB + rI} ${(tH + tB) / 2}
                L ${tB + rI} ${(tH + tB) / 2}
                L ${s} ${(tH + tB) / 2}
                L ${s} ${tH}
                L ${hW} ${tH / 2}
              `"
            :style="value.data.style"
            stroke-linecap="round" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"
export default {
  name: "BlockShapeArrow",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  methods: {
    updateHeadWidth(x) {
      const diff = this.value.data.controls.headWidth - x / this.w
      this.value.data.controls.slope -= diff
      this.value.data.controls.headWidth = x / this.w
    }
  },
  data() {
    return {
      state: "not-selected"
    }
  },
  computed: {
    displayHandler() {
      return this.state == "primary-selected" && this.mode === "edit"
    },
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    r() {
      return this.value.data.controls.radius * ((this.h - (this.tH - this.tB) / 2) / 2)
    },
    rI() {
      return Math.max(0, this.r - this.tB)
    },
    s() {
      return this.w * this.value.data.controls.slope
    },
    tH() {
      return this.h * this.value.data.controls.thickHead
    },
    tB() {
      return this.h * this.value.data.controls.thickBody
    },
    hB() {
      return (this.tH + this.tB) / 2 - (this.tH - this.tB) / 2
    },
    hW() {
      return this.w * this.value.data.controls.headWidth
    }
  }
}
</script>
