<template>
  <toolbar-button
    icon="mdi-file-replace-outline"
    :tooltip="tooltip ? tooltip : 'Apply bulk operations to selected contents'"
    @click="openBulkUploadDialog"></toolbar-button>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { PtModal } from "plant-common"
import ToolbarButton from "@/pages/explorer/ExploreLibrary/components/Tools/ToolbarButton.vue"
import workflows_store from "@/store/workflows"
import BulkChange from "../BulkChange/index.vue"
import { STORE_EXPLORER_NAME, STATE_SELECTED } from "@/store_constants/explorer"
import { STORE_WORKFLOWS_NAME, STATE_IS_ERROR_CREATE, ACTION_CREATE_ITEM } from "@/store_constants/workflows"
import { STATE_PLANT_ID, STORE_LIBRARY_NAME } from "@/store_constants/library"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_INFO,
  ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications"

export default {
  mixins: [PtModal],
  props: ["tooltip"],
  components: { ToolbarButton },
  beforeCreate() {
    if (!this.$store.state[STORE_WORKFLOWS_NAME]) {
      this.$store.registerModule(STORE_WORKFLOWS_NAME, workflows_store)
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      selected: STATE_SELECTED
    }),
    ...mapState(STORE_WORKFLOWS_NAME, [STATE_IS_ERROR_CREATE]),
    ...mapState(STORE_LIBRARY_NAME, {
      plant_id: STATE_PLANT_ID
    })
  },
  methods: {
    ...mapActions(STORE_WORKFLOWS_NAME, {
      create: ACTION_CREATE_ITEM
    }),
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      info: ACTION_NOTIFICATIONS_INFO,
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    async openBulkUploadDialog() {
      await this.$modal(BulkChange, {
        value: true
      })
    }
  }
}
</script>
