import DiagramReplace from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "diagram-replace-title-description-and-content",
  title: "Replace All: Diagram Title, Description, Condition Details & Keypoints",
  description:
    'This operation replaces the "find" value (Case sensitive) with the "replace" value in title, description and all diagram condition details and keypoints',
  contentType: "diagram",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      // Title
      if (content && content.header && content.header.metadata && content.header.metadata.title) {
        content.header.metadata.title = content.header.metadata.title.toString().replace(searchRegExp, replaceWith)
      }
      // Description
      if (content && content.header && content.header.metadata && content.header.metadata.description) {
        content.header.metadata.description = content.header.metadata.description
          .toString()
          .replace(searchRegExp, replaceWith)
      }
      // Condition details
      if (content && content.body && content.body.attachments) {
        content.body.attachments.forEach(attachment => {
          if (attachment.attachment_type == ".json") {
            if (attachment.metadata) {
              if (attachment.metadata.title) {
                attachment.metadata.title = attachment.metadata.title.toString().replace(searchRegExp, replaceWith)
              }
              if (attachment.metadata.description) {
                attachment.metadata.description = attachment.metadata.description
                  .toString()
                  .replace(searchRegExp, replaceWith)
              }
            }
          }
        })
      }
      // Keypoints
      if (content && content.body && content.body.contents && content.body.contents.shapes) {
        for (const property in content.body.contents.shapes) {
          if (content.body.contents.shapes[property].notes) {
            content.body.contents.shapes[property].notes = content.body.contents.shapes[property].notes
              .toString()
              .replace(searchRegExp, replaceWith)
          }
        }
      }
    }
    return content
  },
  OperationOptionsComponent: DiagramReplace
}
