import Vue from "vue"
import { getCustomId, getValidFileExtension, uploadFile } from "../../../utils"
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = "" + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  let tag = false
  if (vm.$props.selectedTag) {
    tag = vm.$props.selectedTag
  }

  let view = false
  if (vm.$props.view) {
    view = vm.$props.view
  } else {
    view = {
      rightDrawer: false,
      interface: "simple"
    }
  }
  return new Vue({
    data() {
      return {
        rightDrawerStatus: false,
        view
      }
    },
    computed: {
      file() {
        if (vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0]
        } else {
          return false
        }
      },
      hotspots() {
        if (!vm.value.body.contents.hotspots) vm.value.body.contents.hotspots = []
        if (vm.value.body.contents.hotspots.length > 0) {
          return vm.value.body.contents.hotspots
        } else {
          return []
        }
      },
      selectedTag() {
        if (tag === getCustomId(vm.value)) return false
        return tag
      },
      hasAnnotations() {
        return this.hotspots.length !== 0
      }
    },
    created() {
      if (this.view && this.view.rightDrawer) {
        this.rightDrawerStatus = this.view.rightDrawer
      }
    },
    watch: {
      rightDrawerStatus: {
        immediate: true,
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      }
    },
    methods: {
      toggleRightDrawer() {
        this.rightDrawerStatus = !this.rightDrawerStatus
      },
      getCurrentView() {
        if (vm.$props.view && vm.$props.view.interface) {
          return {
            rightDrawer: this.rightDrawerStatus,
            interface: vm.$props.view.interface
          }
        } else {
          return {
            rightDrawer: this.rightDrawerStatus
          }
        }
      },
      removePicture() {
        vm.value.body.attachments.splice(0)
      },
      uploadPicture(file) {
        if (file) {
          let url = URL.createObjectURL(file)
          vm.value.body.attachments.push(new Attachment("Picture", file, url, ".jpg"))
        } else {
          uploadFile(getValidFileExtension("picture")).then(({ url, file }) => {
            vm.value.body.attachments.push(new Attachment("Picture", file, url, ".jpg"))
          })
        }
      },
      addHotspot(hotspot) {
        if (!vm.value.body.contents.hotspots) {
          Vue.set(vm.value.body.contents, "hotspots", [])
        }
        vm.value.body.contents.hotspots.push(hotspot)
      }
    }
  })
}
