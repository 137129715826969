<template>
	<v-tooltip bottom>
		<template v-slot:activator="{ on }">
			<v-btn outline small :color="color" :disabled="disabled"  @click="$emit('click')">
				<v-icon v-if="icon" left small>{{icon}}</v-icon>
				<slot>{{text}}</slot>
			</v-btn>
		</template>
		<span>{{tooltip}}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		color: { type: String, default: "primary" },
		disabled: Boolean,
		text: String,
		tooltip: String,
		icon: String
	}
}
</script>
