<template>
  <v-container ref="wrap" grid-list-md style="overflow-y: auto; max-height: calc(100vh - 340px); max-width: 100%">
    <h3 class="subheading lighten-4 mb-3" v-if="folders.length > 0">Folders</h3>
    <v-layout v-if="folders.length > 0" row wrap class="mb-4">
      <v-flex xs12 sm6 md4 lg3 v-for="item in folders" :key="item._id">
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            @click="$emit('folderChanged', item._id)"
            :class="`elevation-${hover ? 4 : 1}`"
            style="cursor: pointer">
            <card-folder :name="item.name" />
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <h3 class="subheading lighten-4 mb-3" v-if="contents.length > 0">Contents</h3>
    <v-layout row wrap>
      <v-flex xs12 sm6 md4 lg3 v-for="item in contents" :key="item._id">
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            @click="$emit('update:selected', item)"
            :class="`elevation-${hover ? 4 : 1} content-grid-card`"
            :style="{
              cursor: 'pointer',
              background: selected == item ? '#1976d275' : ''
            }">
            <preview-content-image v-if="view == 'preview'" :content="item" :library="library"></preview-content-image>

            <card-content
              :custom_id="getCustomId(item)"
              :title="getTitle(item)"
              :date="new Date(parseInt(item._id.substring(0, 8), 16) * 1000) | moment"
              :type="getType(item)" />
          </v-card>
        </v-hover>
      </v-flex>

      <mugen-scroll
        scroll-container="wrap"
        :handler="addItems"
        :should-handle="!loading"
        :handleOnMount="false"></mugen-scroll>
    </v-layout>
  </v-container>
</template>

<script>
import { getTitle, getType, getCustomId } from "@/utils/dbTool"
import moment from "moment"
import CardFolder from "../explorer/CardFolder.vue"
import CardContent from "../explorer/CardContent.vue"
import PreviewContentImage from "../explorer/PreviewContentImage"
import MugenScroll from "vue-mugen-scroll"

export default {
  props: ["items", "view", "selected", "library"],
  components: { CardFolder, CardContent, PreviewContentImage, MugenScroll },
  filters: {
    moment: function (date) {
      return moment(date).format("LL")
    }
  },
  data() {
    return {
      itemsChunked: [],
      loading: false
    }
  },
  created() {
    if (this.items) {
      // fix when user clicks on list mode and return back to grid mode without changing the items
      this.itemsChunked = []
      this.addItems()
      this.loading = false
    }
  },
  computed: {
    folders() {
      return this.itemsChunked.filter(item => !item.header)
    },
    contents() {
      return this.itemsChunked.filter(item => item.header)
    }
  },
  watch: {
    items() {
      this.itemsChunked = []
      this.addItems()
      this.loading = false
    }
  },
  methods: {
    getTitle,
    getType,
    getCustomId,
    addItems() {
      if (this.itemsChunked.length === this.items.length) this.loading = true

      const AMOUNT = 25
      const START = this.itemsChunked.length
      const END = this.itemsChunked.length + AMOUNT
      const itemsToAdd = this.items.slice(START, END)
      this.itemsChunked = [...this.itemsChunked, ...itemsToAdd]
    }
  }
}
</script>

