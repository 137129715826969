import PtApiClient from "plant-api-client"
import { getContentId } from "@/utils/dbTool"
import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  STATE_CONTENTS,
  STATE_SELECTED,
  STATE_EXPLORER_LOADING,
  STATE_FILTER_TYPE,
  STATE_EXPLORER_VIEW,
  STATE_ALL_LIBRARY_FOLDERS,
  MUTATION_SET_CURRENT_FOLDER,
  MUTATION_START_LOADING,
  MUTATION_TOGGLE_LOADING,
  MUTATION_SELECTION,
  MUTATION_SELECTION_ALL,
  MUTATION_CLEAN_SELECTION,
  MUTATION_SET_CONTENTS,
  MUTATION_SET_FILTER_TYPE,
  MUTATION_SET_EXPLORER_VIEW,
  MUTATION_SET_ALL_LIBRARY_FOLDERS,
  ACTION_REFRESH,
  ACTION_CHANGE_SELECTION,
  ACTION_CHANGE_ALL_SELECTION,
  ACTION_OPEN_FOLDER,
  ACTION_NEW_FOLDER,
  ACTION_DELETE_ITEM,
  ACTION_SEARCH_QUERY,
  ACTION_RENAME_FOLDER,
  ACTION_CLEAN_SELECTED,
  ACTION_CREATE_DRAFT_FROM_ITEMREVISION_LIST,
  ACTION_CHANGE_FILTER_TYPE,
  ACTION_GET_ALL_LIBRARY_FOLDERS
} from "@/store_constants/explorer"

export default {
  name: STORE_EXPLORER_NAME,
  namespaced: true,
  state: {
    [STATE_FOLDER]: false,
    [STATE_ALL_LIBRARY_FOLDERS]: null,
    [STATE_CONTENTS]: [],
    [STATE_SELECTED]: [],
    [STATE_EXPLORER_LOADING]: false,
    [STATE_FILTER_TYPE]: undefined,
    [STATE_EXPLORER_VIEW]: "list"
  },
  mutations: {
    [MUTATION_SET_CURRENT_FOLDER](state, folder) {
      let ellipsis = []
      if (folder.path && folder.path.length > 2) {
        ellipsis = folder.path.splice(0, folder.path.length - 2)
      }
      state[STATE_FOLDER] = {
        name: folder.name,
        _id: folder._id,
        path: folder.path,
        ellipsis: ellipsis
      }
      state[STATE_CONTENTS] = folder.docs
      state[STATE_EXPLORER_LOADING] = false
    },
    [MUTATION_START_LOADING](state) {
      if (state[STATE_CONTENTS]) state[STATE_CONTENTS].splice(0, state[STATE_CONTENTS].length)
      state[STATE_FOLDER] = false
      state[STATE_EXPLORER_LOADING] = true
    },
    [MUTATION_TOGGLE_LOADING](state) {
      state[STATE_EXPLORER_LOADING] = !state[STATE_EXPLORER_LOADING]
    },
    [MUTATION_SELECTION](state, selection) {
      if (selection.selected) {
        state[STATE_SELECTED].push(selection)
      } else {
        let index = state[STATE_SELECTED].map(sel => sel._id).indexOf(selection._id)
        state[STATE_SELECTED].splice(index, 1)
      }
    },
    [MUTATION_SELECTION_ALL](state, flag) {
      state[STATE_CONTENTS].forEach(content => {
        content.selected = flag
      })
      if (flag) {
        state[STATE_SELECTED] = JSON.parse(JSON.stringify(state[STATE_CONTENTS]))
      } else {
        state[STATE_SELECTED] = []
      }
    },
    [MUTATION_CLEAN_SELECTION](state) {
      state[STATE_SELECTED] = []
    },
    [MUTATION_SET_CONTENTS](state, contents) {
      state[STATE_CONTENTS] = contents
    },
    [MUTATION_SET_FILTER_TYPE](state, filterValue) {
      if (filterValue) {
        state[STATE_FILTER_TYPE] = filterValue.name
      } else {
        state[STATE_FILTER_TYPE] = undefined
      }
    },
    [MUTATION_SET_EXPLORER_VIEW](state, view) {
      state[STATE_EXPLORER_VIEW] = view
    },
    [MUTATION_SET_ALL_LIBRARY_FOLDERS](state, libraryFolders) {
      state[STATE_ALL_LIBRARY_FOLDERS] = libraryFolders
    }
  },
  actions: {
    [ACTION_REFRESH]({ state, dispatch, commit }) {
      dispatch(ACTION_OPEN_FOLDER, state[STATE_FOLDER]._id)
    },
    [ACTION_CHANGE_SELECTION]({ commit }, selection) {
      commit(MUTATION_SELECTION, selection)
    },
    [ACTION_CHANGE_ALL_SELECTION]({ commit }, flag) {
      commit(MUTATION_SELECTION_ALL, flag)
    },
    [ACTION_OPEN_FOLDER]({ commit, state, dispatch }, folder_id) {
      if (!state[STATE_EXPLORER_LOADING]) {
        commit(MUTATION_START_LOADING)
        commit(MUTATION_CLEAN_SELECTION)
        if (folder_id) {
          return PtApiClient.getFolder(folder_id)
            .then(folder => {
              commit(MUTATION_SET_CURRENT_FOLDER, folder)
            })
            .catch(e => {
              commit(MUTATION_TOGGLE_LOADING, false)
              if (e.response && e.response.data && e.response.data.error) {
                dispatch("notifications/error", { message: "Error: " + e.response.data.error }, { root: true })
              } else {
                dispatch(
                  "notifications/error",
                  { message: "Error: Oops! Something happend when trying to fetch data" },
                  { root: true }
                )
              }
            })
        } else {
          PtApiClient.getFolder()
            .then(folders => {
              commit(MUTATION_SET_CURRENT_FOLDER, folders)
            })
            .catch(e => {
              commit(MUTATION_TOGGLE_LOADING, false)
              if (e.response && e.response.data && e.response.data.error) {
                dispatch("notifications/error", { message: "Error: " + e.response.data.error }, { root: true })
              } else {
                dispatch(
                  "notifications/error",
                  { message: "Error: Oops! Something happend when trying to fetch data" },
                  { root: true }
                )
              }
            })
        }
      }
    },
    [ACTION_NEW_FOLDER]({ commit, dispatch }, { name, parent_id }) {
      return PtApiClient.createFolder(name, parent_id)
        .then(folder => {
          dispatch(ACTION_OPEN_FOLDER, parent_id)
        })
        .catch(e => console.log(e))
    },
    [ACTION_DELETE_ITEM]({ commit, dispatch }, item) {
      // It is a folder (item can not be deleted)
      if (item.name) {
        return PtApiClient.deleteFolder(item._id)
      }
    },
    [ACTION_SEARCH_QUERY]({ state, commit, dispatch }, query) {
      return PtApiClient.searchContent(query, state[STATE_FILTER_TYPE])
        .then(items => {
          // set items path
          for (const item of items.docs) {
            item.path = []
            if (!item.folder) {
              item.path.push("root")
            } else {
              item.path.unshift(item.folder.name)
              let parentId = item.folder.parent_id
              while (parentId) {
                const parent = state[STATE_ALL_LIBRARY_FOLDERS][parentId]
                item.path.unshift(parent.name)
                parentId = parent.parent_id
              }
            }
            item.path = item.path.join("/")
          }
          commit(MUTATION_SET_CONTENTS, items.docs)
        })
        .catch(e => console.log(e))
    },
    [ACTION_RENAME_FOLDER]({ state, dispatch }, { name, folder_id, parent_id }) {
      return PtApiClient.updateFolder(folder_id, { name, parent_id }, "rename")
        .then(folder => {
          dispatch(ACTION_OPEN_FOLDER, state[STATE_FOLDER]._id)
        })
        .catch(e => console.log(e))
    },
    [ACTION_CLEAN_SELECTED]({ commit }) {
      commit(MUTATION_CLEAN_SELECTION)
    },
    async [ACTION_CREATE_DRAFT_FROM_ITEMREVISION_LIST]({ commit, dispatch }, content) {
      let ok = []
      let errors = []
      for (const i of content) {
        try {
          var itemDraft = await PtApiClient.createDraft(getContentId(i))
          ok.push(itemDraft)
        } catch (e) {
          errors.push({ item: i, error: e })
        }
      }

      if (ok.length > 0 && errors.length > 0) {
        dispatch(
          "notifications/info",
          { message: "Created drafts from items but some of them already had one draft" },
          { root: true }
        )
      } else if (errors.length <= 0 && ok.length > 0) {
        dispatch("notifications/info", { message: "Created drafts from items" }, { root: true })
      } else if (errors.length > 0 && ok.length <= 0) {
        dispatch("notifications/error", { message: "All items already had one draft" }, { root: true })
      }

      return { ok: ok, errors: errors }
    },
    [ACTION_CHANGE_FILTER_TYPE]({ commit }, filterValue) {
      commit("setFilterType", filterValue)
    },
    async [ACTION_GET_ALL_LIBRARY_FOLDERS]({ commit, dispatch }) {
      const folders = await PtApiClient.getAllFolders()
      const foldersMap = {}
      folders.docs.map(folder => {
        foldersMap[folder._id] = {
          name: folder.name,
          parent_id: folder.parent_id
        }
      })
      commit(MUTATION_SET_ALL_LIBRARY_FOLDERS, foldersMap)
    }
  }
}
