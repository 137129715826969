export function uploadFile() {
  return new Promise((resolve, reject) => {
    var input = document.createElement("input")
    input.setAttribute("type", "file")
    //input.setAttribute("accept", accept);
    input.style.display = "none"
    input.onchange = function (evt) {
      if (!(evt.currentTarget.files.length > 0)) {
        reject()
        return
      }
      var file = evt.currentTarget.files[0]
      resolve(file)
    }
    input.click()
    document.body.appendChild(input)
  })
}

export function getAdaptedSize(layout, position, size) {
  const layoutWidth = layout.offsetWidth - position.x
  const layoutHeight = layout.offsetHeight - position.y

  if (size.width > layoutWidth) {
    const sizeOriginalWidth = size.width
    size.width = layoutWidth
    size.height = (layoutWidth * size.height) / sizeOriginalWidth
  }
  if (size.height > layoutHeight) {
    const sizeOriginalHeight = size.height
    size.height = layoutHeight
    size.width = (layoutHeight * size.width) / sizeOriginalHeight
  }
  return size
}
