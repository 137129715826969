export const OrderListOptions = [
    {
        name: "None",
        value: "none",
        img: "none.svg"
    },
    {
        name: "Decimal",
        value: "decimal",
        img: "decimal.svg"
    },
    {
        name: "Lower Alpha",
        value: "lower-alpha",
        img: "alpha_low.svg"
    },
    {
        name: "Upper Alpha",
        value: "upper-alpha",
        img: "alpha_upper.svg"
    },
    {
        name: "Lower Roman",
        value: "lower-roman",
        img: "roman_low.svg"
    },
    {
        name: "Upper Roman",
        value: "upper-roman",
        img: "roman_upper.svg"
    }
]