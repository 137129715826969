import PtApiClient from "plant-api-client"
import PServiceContent from "plant-common/src/services/PServiceContent";
import Objective from "plant-common/src/components/plant-item-viewer/objective/model"
import store from "@/store"
export default async function convert({ learningObjectiveCustomId, destinationFolderId }) {
  var user_id = store.state.user.profile._id

  let objectiveContent = await PtApiClient.getContentByCustomId(learningObjectiveCustomId)
  if (objectiveContent.header.metadata.content_revision_type !== "learningObjective") {
    throw new Error('Content must be of type "learningObjective"')
  }
  // Create a new DRAFT for the guide
  await PtApiClient.createDraft(objectiveContent.header.metadata.content_id)

  // Change DRAFT workflow
  await PtApiClient.updateDraftWorkflow(objectiveContent.header.metadata.content_id, "method", "changeWorkflow", {
    name: "no_reviewer", lightweight: true
  })

  // Convert Enabling Objectives
  let enablingContents = []
  let enablingContent = false
  for (let enabling of objectiveContent.body.contents.enabling) {
    let { title, custom_id } = enabling
    try {
      var content = await PtApiClient.createContent(title, custom_id, destinationFolderId, "objective")
      var { content_id } = content.itemRevisionDraft.header.metadata;
      await PtApiClient.updateDraftWorkflow(content_id, "method", "changeWorkflow", {
        name: "no_reviewer", lightweight: true
      })
      await PtApiClient.updateDraftWorkflow(content_id, "method", "reassignRole", {
        role: "author",
        user_id,
        lightweight: true
      })
      var { body } = content.itemRevisionDraft
      body.contents = await PtApiClient.getContentByContentId(content_id)
      body.contents = new Objective()
      body.contents.text = title
      body.contents.level = "Cognitive Enabler"
      await PtApiClient.updateDraftBody(content_id, body)
      await PtApiClient.updateDraftWorkflow(content_id, "transition", "approveMinorChange")
    } catch (e) {
      // content exists, do nothing
    }
    enablingContent = await PServiceContent.findContent({ custom_id: enabling.custom_id })
    enablingContent[0].$origin = `plant-ref${enablingContent[0].$origin.slice(5)}`
    enablingContent[0].type = "ref"
    enablingContent[0].__latest = `${enablingContent[0].header.major_revision}.${enablingContent[0].header.minor_revision}`
    enablingContents.push(enablingContent[0])
  }

  // Assign DRAFT author to the user
  await PtApiClient.updateDraftWorkflow(objectiveContent.header.metadata.content_id, "method", "reassignRole", {
    role: "author",
    user_id,
    lightweight: true
  })

  // Convert Terminal Objective
  let objective = new Objective()
  objective.text = objectiveContent.body.contents.terminal.title
  objective.level = "Cognitive Terminal"
  objectiveContent.body.contents = objective
  objectiveContent.header.metadata.content_revision_type = "objective"

  // Add enabling objectives as references
  for (let enab of enablingContents) {
    console.log(enab)
    objectiveContent.body.references.push(enab)
  }

  await PtApiClient.updateDraftBody(objectiveContent.header.metadata.custom_id, objectiveContent.body)
  await PtApiClient.updateDraftMetadata(objectiveContent.header.metadata.custom_id, objectiveContent.header.metadata)
  // Save content
  await PtApiClient.updateDraftWorkflow(objectiveContent.header.metadata.content_id, "transition", "approveMinorChange")
}
