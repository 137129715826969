<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Operations Summary</v-toolbar-title>
    </v-toolbar>

    <v-list>
      <v-list-group
        v-for="(item, i) in operations"
        :key="i"
        :v-model="true"
        no-action
      >
        <template v-slot:activator>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ i + 1 }}. {{ item.operation.title }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <v-list-tile v-for="(subItem, index) in item.data" :key="index">
          <v-list-tile-content>
            <v-list-tile-title>{{ index }}: {{ subItem }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "OperationsSummary",
  props: {
    operations: Array
  }
};
</script>

<style>
</style>