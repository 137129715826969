<template>
  <div :style="style">
    <audio
      v-if="value.data.audio"
      width="100%"
      :src="value.data.audio.url"
      :ref="`audioPlayer-${value.id}`"
      class="p-block-audio-viewer vjs-big-play-centered video-js">
      <template v-for="(track, key) in videoSubtitles">
        <track v-if="track" :key="key" :label="subtitlesLang[key]" kind="captions" :srclang="key" :src="track.url"/>
      </template>
    </audio>
  </div>
</template>

<script>
import videojs from "video.js"
import "video.js/dist/video-js.css"
import { availableLanguages } from "../video/availableLanguages.js"

export default {
  props: ["value", "mode"],
  inject: ["lessonPlanController"],
  data() {
    return {
      player: null,
      subtitlesLang: availableLanguages
    }
  },
  computed: {
    style() {
      return {
        overflow: "hidden",
        "--audio-video-controls-color": this.value.data.controls.color,
        "--audio-video-controls-size": `${this.value.data.controls.size}px`,
        "--audio-video-controls-button-zoom": this.value.data.controls.size / 25
      }
    },
    options() {
      return {
        controls: true,
        width: this.value.size.width,
        height: this.value.size.height,
        controlBar: {
          pictureInPictureToggle: false,
          volumePanel: false,
          fullscreenToggle: false
        },
        sources: [
          {
            src: this.value.data.audio ? this.value.data.audio.url : null,
            type: this.value.data.audio ? this.value.data.audio.type : "audio/mp3"
          }
        ]
      }
    },
    videoSubtitles() {
      return this.value.data.subtitles
    },
    isSubtitlesActive() {
      return this.lessonPlanController.subtitlesActive
    },
    parentSubcanvasDelay() {
			return this.$parent.$parent.$attrs.subcanvasDelay ? this.$parent.$parent.$attrs.subcanvasDelay : 0
		}
  },
  mounted() {
    const ref = this.$refs[`audioPlayer-${this.value.id}`]
    if (ref) {
      const context = this
      this.player = videojs(ref, this.options, function onPlayerReady() {
        this.on("click", function(evt) {
          evt.stopPropagation()
        })
        if (context.value.data.autoplay && context.mode == "view") {
          setTimeout(() => {
            context.player.play()
          }, (context.value.data.delay + context.parentSubcanvasDelay) * 1000)
        }
        this.on("play", function() {
          if(context.isSubtitlesActive) {
            context.turnOnSubtitles()
          }
        })
      })
      this.changePlayerUI(this.value.data.simpleUI)
      this.$el.querySelector(".vjs-big-play-button").title = "Play Audio"      
    }
  },
  methods: {
    changePlayerUI(simple) {
      this.$el.querySelector(".vjs-progress-control").style.display = simple ? "none" : ""
      this.$el.querySelector(".vjs-remaining-time").style.display = simple ? "none" : ""
      this.$el.querySelector(".vjs-control-bar").style.display = simple ? "block" : ""
      this.$el.querySelector(".vjs-big-play-button").style.display = simple ? "none" : ""
    },
    turnOnSubtitles() {
      const textTracks = this.player.textTracks()
      
      textTracks.tracks_.forEach(textTrack => {
        textTrack.mode = 'hidden'
        textTrack.removeEventListener("cuechange"); 
      });
      
      let defaultTrack = textTracks && textTracks.tracks_ && textTracks.tracks_.length > 0 ? textTracks.tracks_[0] : false
      if(this.lessonPlanController.defaultSubtitles) {
        defaultTrack = textTracks && textTracks.tracks_ && textTracks.tracks_.find(track => track.language === this.lessonPlanController.defaultSubtitles) ? textTracks.tracks_.find(track => track.language === this.lessonPlanController.defaultSubtitles) : false
      }
      if(defaultTrack) {
        defaultTrack.mode = 'showing';
        defaultTrack.addEventListener('cuechange', () => {
          if(defaultTrack.activeCues.cues_ && defaultTrack.activeCues.cues_.length > 0) {
            const text = defaultTrack.activeCues.cues_[0].text 
            this.lessonPlanController.setSubtitlesText(text)
          } else {
            this.lessonPlanController.setSubtitlesText(false)
          }
        });
      }
    }
  },
  watch: {
    "value.data.simpleUI": {
      handler() {
        this.changePlayerUI(this.value.data.simpleUI)
      }
    },
    isSubtitlesActive: {
      handler() {
        if(this.isSubtitlesActive) {
          this.turnOnSubtitles()
        }
      }
    },
    "lessonPlanController.defaultSubtitles": {
      handler() {
        this.turnOnSubtitles()
      }
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style>
.p-block-audio-viewer.video-js {
  color: var(--audio-video-controls-color);
}

/* Change the border of the big play button. */
.p-block-audio-viewer .vjs-big-play-button {
  border-color: var(--audio-video-controls-color);
  zoom: var(--audio-video-controls-button-zoom);
}

/* Change the color of various "bars". */
.p-block-audio-viewer .vjs-volume-level,
.p-block-audio-viewer .vjs-play-progress,
.p-block-audio-viewer .vjs-slider-bar {
  background: var(--audio-video-controls-color);
}
.p-block-audio-viewer .vjs-control-bar {
  font-size: var(--audio-video-controls-size);
  background-color: rgba(43, 51, 63, 1);
  height: 100%;
}

.p-block-audio-viewer .vjs-subs-caps-button {
  display: none;
}

.video-js .vjs-play-progress:before {
  top: 0em;
}
</style>