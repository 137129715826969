import uuid from "../../../../utils/uuid.js"

export default function () {
  const objectivesButtonId = uuid()
  const submitQuizButtonId = uuid()
  return {
    blocks: [
      {
        id: objectivesButtonId,
        name: "",
        position: {
          x: 140,
          y: 900
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          },
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        has_activity: false,
        size: {
          width: 626,
          height: 100
        },
        rotation: 0,
        protection: {
          type: ["button"],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        flip: {
          x: false,
          y: false
        },
        data: {
          text: "Open Objectives Panel 🎯",
          icon: "none",
          visited: false,
          style: {
            regular: {
              backgroundColor: "#f5f5f5ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            hover: {
              backgroundColor: "#e0e0e0ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            active: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            visited: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            disabled: {
              backgroundColor: "#efefef4d",
              color: "#1010104d",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#7676764d",
              fontFamily: false
            }
          }
        },
        actions: [
          {
            id: uuid(),
            trigger: "click",
            type: "event",
            event: "open:objectives",
            parent: objectivesButtonId
          }
        ],
        type: "button"
      },
      {
        id: submitQuizButtonId,
        name: "",
        position: {
          x: 1154,
          y: 900
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          },
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        has_activity: false,
        size: {
          width: 625,
          height: 100
        },
        rotation: 0,
        protection: {
          type: ["button"],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        flip: {
          x: false,
          y: false
        },
        data: {
          text: "Finish Quiz ✅",
          icon: "none",
          visited: false,
          style: {
            regular: {
              backgroundColor: "#f5f5f5ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            hover: {
              backgroundColor: "#e0e0e0ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            active: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            visited: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#f5f5f5ff",
              fontFamily: false
            },
            disabled: {
              backgroundColor: "#efefef4d",
              color: "#1010104d",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#7676764d",
              fontFamily: false
            }
          }
        },
        actions: [
          {
            id: uuid(),
            trigger: "click",
            type: "event",
            event: "quiz:submit",
            parent: submitQuizButtonId
          },
          {
            id: uuid(),
            trigger: "hover",
            type: "tooltip",
            text: "Once you click the button you'll not be allowed to change your answers",
            position: "bottom"
          }
        ],
        type: "button"
      },
      {
        id: uuid(),
        name: "",
        position: {
          x: 0,
          y: 650
        },
        animations: [
          {
            trigger: "auto",
            name: "fadeIn",
            duration: 1,
            delay: 0
          }
        ],
        has_activity: false,
        size: {
          width: 1920,
          height: 174
        },
        protection: {
          type: ["textarea"],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        data: {
          html: "<p><strong>Progress Information</strong></p>",
          fontSize: 72,
          color: "#000000FF",
          textAlign: "center",
          alignItems: "center",
          fontFamily: false,
          padding: 0
        },
        type: "textarea",
        rotation: 0,
        flip: {
          x: false,
          y: false
        }
      }
    ]
  }
}
