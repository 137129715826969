<template>
  <p-panel title="Block Animation" v-if="selectedBlock">
    <template #toolbar>
      <v-toolbar-title class="subheading">
        Block Animation <span v-if="selectedBlock && selectedBlock.type">({{ selectedBlock.type }})</span>
      </v-toolbar-title>
    </template>
    <animation-form :value="selectedBlock" />
  </p-panel>
</template>

<script>
import AnimationForm from "./AnimationForm"
import PPanel from "../utils/PPanel.vue"

export default {
  name: "BlockAnimation",
  props: ["selectedBlock"],
  components: {
    AnimationForm,
    PPanel
  }
}
</script>

<style>
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
