<script>
import { changeAllBlockIds, cloneDeep } from "../../utils/utils.js"
import uuid from "../../../../utils/uuid.js"

export default {
  inject: ["blockController", "blockDefinitions"],
  methods: {
    /**
			 Mixin to handle a dropped block.
			 In the container that can receive blocks, add the following.
			 The component should have access to `blockController` and `blockDefinitions`

			 ```vue
						<div ref="canvas"
						@drop="blockDropped"
						@dragover.prevent
						@dragenter.prevent
						>
						</div>
				```

			*/
    onBlockDrop(evt) {
      evt.stopPropagation()

      const newBlock = this.createNewBlock(evt)
      if (!newBlock) return

      // position block on the stencil
      if (!newBlock.protection.move) {
        var { x, y } = this.$refs.canvas.getBoundingClientRect()
        var { clientX, clientY } = evt
        newBlock.position.x = Math.round((clientX - x) / this.blockController.scale)
        newBlock.position.y = Math.round((clientY - y) / this.blockController.scale)
      }
      if (newBlock.data.hasOwnProperty("begin") && newBlock.data.hasOwnProperty("end")) {
        newBlock.data.begin.x = newBlock.position.x
        newBlock.data.begin.y = newBlock.position.y
        newBlock.data.end.x = newBlock.position.x + newBlock.size.width
        newBlock.data.end.y = newBlock.position.y + newBlock.size.height
      }
      this.blocks.push(newBlock)
      this.blockController.selectionClear()
      this.$nextTick(function () {
        this.blockController.selectionAdd(newBlock.id)
      })
    },
    createNewBlock(evt) {
      const stencilId = evt.dataTransfer.getData("stencilId")

      let newBlock = this.createBasicBlock(stencilId)
      if (!newBlock) {
        newBlock = this.createStencilBlock(stencilId)
      }
      changeAllBlockIds(newBlock)
      return newBlock
    },
    createBasicBlock(stencilId) {
      let newBlock = null

      let stencil = Object.values(this.blockDefinitions).find(blockDefinition => blockDefinition.id == stencilId)

      if (stencil) {
        newBlock = new stencil.class()
      }
      return newBlock
    },
    createStencilBlock(stencilId) {
      let newBlock = null

      const stencil = Object.values(this.blockController.availableStencils).find(stencil => stencil.id == stencilId)

      if (stencil) {
        newBlock = cloneDeep(stencil.block)
        newBlock.origin_stencil_id = newBlock.id
        newBlock.id = uuid()
      }

      return newBlock
    }
  }
}
</script>