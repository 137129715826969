import { $http } from '../config';

const Auth = {
	login(data) {
		return $http.post('/auth/login', data).then(r => {
			this.token = r.data.token;
			return r.data
		})
	},
	logout() {
		this.token = false
	},
	refreshToken(token) {
		return $http.post('/auth/refreshToken', token).then(r => {
			this.token = r.data.token;
			return r.data
		})
	}
};

export default Auth;
