<template>
  <content-preview :id="id" :major_revision="major_revision" :minor_revision="minor_revision" :embeded_ip="true" />
</template>

<script>
import ContentPreview from "../ContentPreview"

export default {
  name: "ContentIP",
  props: ["id", "major_revision", "minor_revision"],
  components: { ContentPreview }
}
</script>
