export default {
    "model": {
        "status": {
            type: "list",
            format: ["ENERGIZED", "DEENERGIZED"],
            default: "DEENERGIZED",
            bind: {
                to: "attr",
                name: "data-status"
            }
        }
    },
    listeners: {
        click: true,
        contextmenu: true,
        mouseenter: true,
        mouseleave: true,
    }
}