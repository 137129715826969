<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small icon v-on="on" flat @click.stop="goToComment(false)">
          <v-icon small>mdi-comment-arrow-left-outline</v-icon>
        </v-btn>
      </template>
      <span
        v-html="
          commentsFiltered.length > 0
            ? 'Go to previous comment (' + (navigationIndex ? navigationIndex : 1) + '/' + commentsFiltered.length + ')'
            : applyFilters
            ? 'No comments found with filter criteria'
            : 'No comments found'
        "></span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small icon v-on="on" flat @click.stop="goToComment(true)">
          <v-icon small>mdi-comment-arrow-right-outline</v-icon>
        </v-btn>
      </template>
      <span
        v-html="
          commentsFiltered.length > 0
            ? 'Go to next comment (' + (navigationIndex ? navigationIndex + 1 : 1) + '/' + commentsFiltered.length + ')'
            : applyFilters
            ? 'No comments found with filter criteria'
            : 'No comments found'
        "></span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import {
  STORE_MODULE_COMMENTS,
  STATE_CURRENT_LOCATION,
  STATE_NAV_COMMENT,
  ACTION_SAVE_CURRENT_LOCATION,
  ACTION_UPDATE_NAV_COMMENT
} from "../../../../store_constants/content"

export default {
  name: "CommentsNavigation",
  props: ["applyFilters", "commentsFiltered"],
  components: {},
  data() {
    return {
      navigationIndex: 0
    }
  },
  computed: {
    ...mapState(STORE_MODULE_COMMENTS, {
      navigationComment: STATE_NAV_COMMENT,
      current_location: STATE_CURRENT_LOCATION
    })
  },
  methods: {
    ...mapActions(STORE_MODULE_COMMENTS, {
      saveCurrentLocation: ACTION_SAVE_CURRENT_LOCATION,
      updateNavigationComment: ACTION_UPDATE_NAV_COMMENT
    }),
    goToComment(findNext) {
      var goToFirstComment = () => {
        if (this.commentsFiltered.length > 0) {
          this.updateNavigationComment(this.commentsFiltered[0])
          this.saveCurrentLocation(this.commentsFiltered[0].location)
          this.navigationIndex = 0
        }
      }
      if (this.navigationComment) {
        const currentIndex = this.commentsFiltered.findIndex(comment => comment._id == this.navigationComment._id)
        if (findNext) {
          // Find next comment
          for (let i = currentIndex + 1; i < this.commentsFiltered.length; i++) {
            this.updateNavigationComment(this.commentsFiltered[i])
            this.saveCurrentLocation(this.commentsFiltered[i].location)
            this.navigationIndex = i
            break
          }
        } else {
          // Find previous comment
          if (currentIndex > 0) {
            for (let i = currentIndex - 1; i >= 0; i--) {
              this.updateNavigationComment(this.commentsFiltered[i])
              this.saveCurrentLocation(this.commentsFiltered[i].location)
              this.navigationIndex = i
              break
            }
          } else {
            goToFirstComment()
          }
        }
      } else {
        if (this.commentsFiltered.length > 0) {
          goToFirstComment()
        }
      }
    }
  }
}
</script>