// https://www.pdftron.com/documentation/web/guides/hiding-elements/#list-of-elements
export default {
  disabledElements: [
    "annotationCommentButton",
    "fileAttachmentToolButton",
    // "leftPanel",
    // "leftPanelButton",
    "linkButton",
    "menuButton",
    "miscToolGroupButton",
    "notesPanelButton",
    "notesPanel",
    "outlinesPanelButton",
    "pageNavOverlay",
    "rubberStampToolButton",
    "searchButton",
    "signatureToolButton",
    "signaturePanelButton",
    "stampToolButton",
    "textToolGroupButton",
    "thumbnailControl",
    "toggleNotesButton",
    "toolsButton",
    "viewControlsButton",
  ],
}