<template>
  <v-dialog v-model="dialog" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-if="iconify" v-slot:activator="{ on }">
      <v-btn v-on="on" icon>
        <v-icon>mdi-book-check</v-icon>
      </v-btn>
    </template>
    <template v-else v-slot:activator="{ on }">
      <v-btn v-on="on" color="primary">
        <v-icon left>mdi-book-check-outline</v-icon>
        Open activities dialog
      </v-btn>
    </template>

    <v-card>
      <v-toolbar extended dense dark color="primary">
        <v-toolbar-title>
          <v-icon left>mdi mdi-book-education-outline</v-icon>
          Quiz Activities (Total Points: {{ quizTotalPoints }})
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark flat @click="dialog = false">
            Save and return
            <v-icon right dark>save</v-icon>
          </v-btn>
        </v-toolbar-items>
        <template v-slot:extension>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn :loading="autoLoading" outline flat v-on="on" @click="autoloadActivities"
                >Autoload activities</v-btn
              >
            </template>
            <span>Automatically load activities which are related to current Interactive Presentation objectives</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn flat outline v-on="on" @click="selectActivity">Add activity</v-btn>
            </template>
            <span>Manually load any activity from Content Explorer</span>
          </v-tooltip>
        </template>
      </v-toolbar>
      <v-data-table :headers="headers" :items="activities" :pagination.sync="pagination" select-all class="elevation-1">
        <template v-slot:headers="props">
          <tr>
            <th>
              <v-checkbox v-model="allActivitiesSelected" primary hide-details @click.stop="toggleAll"></v-checkbox>
            </th>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'column sortable',
                pagination.descending ? 'desc' : 'asc',
                header.value === pagination.sortBy ? 'active' : ''
              ]"
              @click="changeSort(header.value)">
              <v-icon small>arrow_upward</v-icon>
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template v-slot:items="props">
          <td>
            <v-checkbox v-model="props.item.selected" primary hide-details></v-checkbox>
          </td>
          <td>
            <content-type-icon height="40px" :type="props.item.content_ref.header.metadata.content_revision_type" />
          </td>
          <td>{{ props.item.content_ref.header.metadata.title }}</td>
          <td>{{ props.item.content_ref.header.metadata.custom_id }}</td>
          <td>
            {{
              getObjective(props.item.content_ref)
                ? `${getObjective(props.item.content_ref).header.metadata.custom_id}: ${
                    getObjective(props.item.content_ref).header.metadata.title
                  }`
                : "N/A"
            }}
          </td>
          <td>
            <v-edit-dialog :return-value.sync="props.item.points" large @save="saveData" @cancel="cancelSave">
              <div>{{ props.item.points }}</div>
              <template v-slot:input>
                <v-text-field v-model="props.item.points" mask="##" single-line autofocus></v-text-field>
              </template>
            </v-edit-dialog>
          </td>
          <td>
            <v-icon medium @click="openInNewTab(props.item)"> mdi-open-in-new</v-icon>
            <v-icon class="ml-2" medium color="red" @click="deleteActivity(props.item)"> mdi-delete</v-icon>
          </td>
        </template>
        <template v-slot:no-data>
          <div class="text-center">
            <p class="mt-3">No activities added yet</p>
            <v-btn color="primary" outline flat @click="selectActivity">Add activity</v-btn>
          </div>
        </template>
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}
        <v-btn flat @click="snack = false">Close</v-btn>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { PtModal, ContentTypeIcon } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import {
  getCustomId,
  getTitle,
  getType,
  getObjective,
  getContentId,
  getMajorVersion,
  getMinorVersion
} from "../../../../../utils/dbTool"
import { QuizSlideActivity } from "../../../models/models"
import { getUniqueArray } from "../../../utils/utils"
import ptApiClient from "plant-api-client"
import FilterContentDialog from "../modal/FilterContentDialog"
import DependentsForContent from "../modal/DependentsForContent"

export default {
  name: "ActivitiesDialog",
  components: { ContentTypeIcon },
  props: ["activities", "iconify"],
  inject: ["lessonPlanController"],
  mixins: [PtModal],
  data() {
    return {
      dialog: false,
      headers: [
        {
          text: "Content Revision Type",
          value: "content_ref.header.metadata.content_revision_type"
        },
        { text: "Title", value: "content_ref.header.metadata.title" },
        { text: "Custom ID", value: "content_ref.header.metadata.custom_id" },
        { text: "Learning Objective", value: "content_ref.body.contents.objective.header.metadata.custom_id" },
        { text: "Points", value: "points", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ],
      pagination: {
        sortBy: "name",
        rowsPerPage: 10 // -1 for All",
      },
      snack: false,
      snackColor: "",
      snackText: "",
      autoLoading: false
    }
  },
  computed: {
    allActivitiesSelected() {
      return !this.activities.find(activity => !activity.selected)
    },
    quizTotalPoints() {
      let totalPoints = 0
      this.activities.forEach(activity => {
        if (isNaN(activity.points)) {
          activity.points = 1
        }
        if (typeof activity.points === "string") {
          activity.points = parseInt(activity.points)
        }
        if (activity.selected) {
          totalPoints = totalPoints + activity.points
        }
      })
      return totalPoints
    }
  },
  beforeMount() {
    this.$options.components.ContentTypeIcon = ContentTypeIcon
  },
  methods: {
    getObjective: getObjective,
    openInNewTab(content) {
      PServiceContent.openContent(content.content_ref, "tab")
    },
    toggleAll() {
      if (this.allActivitiesSelected) {
        this.activities.forEach(activity => {
          activity.selected = false
        })
      } else {
        this.activities.forEach(activity => {
          activity.selected = true
        })
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    async autoloadActivities() {
      this.autoLoading = true
      let objectives = []
      this.lessonPlanController.slideArray.forEach(slide => {
        if (slide.objectives && slide.objectives.length > 0) {
          slide.objectives.forEach(obj => {
            objectives.push(obj)
          })
        }
      })
      objectives = getUniqueArray(objectives, "_id")
      const objectivesReferences = objectives.map(obj => {
        return {
          content_id: getContentId(obj),
          major_revision: getMajorVersion(obj),
          minor_revision: getMinorVersion(obj)
        }
      })

      const activitiesAutoloaded = await ptApiClient.getDependentsByType(objectivesReferences, [
        "activityMultipleChoice"
      ])

      // fill activities list with request response
      activitiesAutoloaded.forEach(autoloadActivity => {
        if (this.activities.filter(activity => activity.content_ref._id === autoloadActivity._id).length === 0) {
          this.activities.push(new QuizSlideActivity(autoloadActivity))
        }
      })
      this.autoLoading = false
    },
    async deleteActivity(item) {
      const index = this.activities.indexOf(item)
      const confirm = await this.$modal("confirm", {
        title: "Confirm Delete",
        message: `Are you sure you want to delete [${getCustomId(item.content_ref)}] ${getTitle(
          item.content_ref
        )} from activities?`,
        accept: {
          color: "error",
          text: "Delete",
          icon: "mdi-delete"
        },
        cancel: { text: "cancel", color: "shades" }
      })
      if (!confirm) return
      this.activities.splice(index, 1)
    },
    selectActivity() {
      this.$modal("optionsList", {
        title: "Add new activity to current Quiz",
        message: "Please, select an option of following list:",
        options: [
          {
            text: "Open content selector",
            value: "contentSelector"
          },
          {
            text: "Filter by objective",
            value: "filterObjective"
          }
        ]
      }).then(async r => {
        if (r) {
          switch (r) {
            case "contentSelector":
              PServiceContent.selectContent({ type: "activityMultipleChoice" })
                .then(r => {
                  this.addActivityToQuiz(r)
                })
                .catch(console.error)
              break
            case "filterObjective":
              const objective = await this.$modal(FilterContentDialog, {
                type: "objective"
              })
              if (!objective) return false
              const activityMultipleChoice = await this.$modal(DependentsForContent, {
                content: objective,
                types: ["activityMultipleChoice"]
              })
              if (!activityMultipleChoice) return false
              this.addActivityToQuiz(activityMultipleChoice)
              break
          }
        }
      })
    },
    addActivityToQuiz(r) {
      const contentTypesAvailable = ["activityMultipleChoice"]
      if (r) {
        if (!contentTypesAvailable.includes(getType(r))) {
          this.$modal("info", {
            title: `Can not add "${getType(r)}" type`,
            message: `Available types are: ${contentTypesAvailable.join(", ")}`,
            ok: { text: "Accept" }
          })
          return
        } else if (this.activities.filter(activity => activity.content_ref._id === r._id).length > 0) {
          this.$modal("info", {
            title: `Can not add "${getCustomId(r)}" content`,
            message: `Selected content is already added to activities`,
            ok: { text: "Accept" }
          })
          return
        }
        this.activities.push(new QuizSlideActivity(r))
      }
    },
    saveData() {
      this.snack = true
      this.snackColor = "success"
      this.snackText = "Points saved"
    },
    cancelSave() {
      this.snack = true
      this.snackColor = "error"
      this.snackText = "Canceled"
    }
  }
}
</script>

<style scoped></style>
