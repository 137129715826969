import * as models from "../../models/models.js"
import { getPureTreeData, walkTreeData } from "he-tree-vue"
import ptApiClient from "plant-api-client"
import { libraryApi } from "../../../../api/index.js"
import ConfigureSlideDialog from "../../components/slides/modal/ConfigureSlideDialog.vue"
import { PtModal } from "plant-common"
import { cloneDeep, changeAllBlockIds, findCommentsForSlide, deepSlideCopy } from "../../utils/utils.js"
import blockDefinitions from "../../blocks/index.js"
import { getContentId, getCustomId, getTitle } from "../../../../utils/dbTool.js"
import uuid from "../../../../utils/uuid.js"
import { resetVisitedSlide } from "../../models/upgradeModel.js"

export default {
  components: {
    ConfigureSlideDialog
  },
  mixins: [PtModal],
  data() {
    return {
      editTitleMode: false,
      timerChangeSlideContent: false,
      progressModal: {
        show: false,
        title: "",
        message: ""
      }
    }
  },
  computed: {
    availableStencils() {
      const stencilGroups = []
      if (this.galleryLessonPlan.galleries.length > 0) {
        stencilGroups.push(this.galleryLessonPlan)
      }
      if (this.galleryImportedLessonPlans.galleries.length > 0) {
        stencilGroups.push(this.galleryImportedLessonPlans)
      }
      return stencilGroups.reduce((prev, curr) => {
        const stencils = curr.galleries.reduce((prev, curr) => [...prev, ...curr.stencils], [])
        return [...prev, ...stencils]
      }, [])
    }
  },
  methods: {
    addSlide(parent) {
      const slide = new models.Slide()
      this.configureSlide(slide, parent)
    },
    addSection(parent) {
      const slide = new models.Slide()
      slide.title = "New Section"
      this.configureSlide(slide, parent)
      slide.children = []
      slide.type = "Section"
    },
    addToLayout(slide) {
      const layout = new models.Layout(slide.title)
      const slideTemp = getPureTreeData(slide)
      const slideCopy = cloneDeep(slideTemp)
      // Clean up unneeded information
      slideCopy.children = false
      slideCopy.objectives = []
      slideCopy.layout = { id: null }
      slideCopy.notes = { audience: "", speaker: "", video: null }
      // Clean up ends
      slideCopy.blocks.forEach(block => changeAllBlockIds(block))
      layout.slide = slideCopy
      layout.slide.id = uuid()
      this.lessonPlanContents.layouts.push(layout)
    },
    addQuizSlide(parent) {
      const slide = new models.QuizSlide()
      slide.title = "New Quiz"
      this.addPathToItem(slide, parent)
      if (!parent) {
        this.slidesTree.slides.push(slide)
      } else {
        parent.children.push(slide)
      }
      this.selectedSlide = slide
    },
    addLearningObjectSlide(ref, id, sectionCover) {
      const slide = new models.LearningObjectSlide(getTitle(ref), ref, sectionCover)
      slide.skip = true
      slide.completionTime = slide.reference.body.contents.instructionalSettings
        ? slide.reference.body.contents.instructionalSettings.completionTime
        : 0
      if (id) {
        let search = this.getParents(this.slidesTree.slides, id, [])
        if (search.found) {
          this.addElementInTree(slide, search.parents)
        }
      } else {
        this.slidesTree.slides.push(slide)
      }
      this.selectedSlide = slide
      this.processLearningObjectives()
      delete slide.reference.body // Delete internal reference (not needed from Editor or Viewer mode)
    },
    changeToQuiz(slide) {
      slide.type = "QuizSlide"
      const quizSlide = new models.QuizSlide()
      slide.blocks = quizSlide.blocks
      slide.children = quizSlide.children
      this.$set(slide, "slideProperties", quizSlide.slideProperties)
    },
    changeToSection(slide) {
      slide.children = []
      slide.type = "Section"
    },
    changeToSlide(slide) {
      slide.children = false
      slide.type = "Slide"
    },
    cleanGarbage() {
      if (!this.templates.length) {
        this.removeImportedLayouts()
        this.removeImportedGalleries()
        return
      }

      this.removeUnusedTemplateContent(this.lessonPlanContents.layouts)
      this.removeUnusedTemplateContent(this.lessonPlanContents.galleries)
    },
    removeImportedLayouts() {
      this.lessonPlanContents.layouts = this.layouts
    },
    removeImportedGalleries() {
      this.lessonPlanContents.galleries = this.galleryLessonPlan.galleries
    },
    removeUnusedTemplateContent(contentArray) {
      const contentCopy = [...contentArray]
      const usedTemplatesId = this.templates.map(temp => temp.id)

      contentCopy.map(content => {
        if (!content.imported) return
        if (!usedTemplatesId.includes(content.imported)) {
          const contentIndex = contentArray.findIndex(
            cont => cont.id === content.id && cont.imported === content.imported
          )
          contentArray.splice(contentIndex, 1)
        }
      })
    },
    async configureSlide(slide, parent, isSameSlide = false) {
      const slideDialog = await this.$modal(ConfigureSlideDialog, {
        title: "Configure slide",
        slide,
        layouts: this.lessonPlanContents.layouts ? this.lessonPlanContents.layouts : [],
        styleSlide: this.lessonPlanContents.settings.styles.slide,
        fields: this.fields,
        blockDefinitions
      })
      if (!slideDialog) return false

      if (!isSameSlide) {
        this.addPathToItem(slide, parent)
        if (!parent) {
          this.slidesTree.slides.push(slide)
        } else {
          parent.children.push(slide)
        }
        this.selectedSlide = slide
      }
      this.addLayoutToSlide(slideDialog.slide, slideDialog.layout)
    },
    addLayoutToSlide(slide, layout) {
      this.selectedSlide.title = slide.title
      if (layout.id) {
        this.selectedSlide.layout.id = layout.id
        this.selectedSlide.layout.name = layout.name
        this.selectedSlide.blocks = cloneDeep(layout.slide.blocks)
        this.selectedSlide.blocks.forEach(block => changeAllBlockIds(block))
      } else {
        this.selectedSlide.layout = { id: null }
        this.selectedSlide.blocks = []
      }
    },
    changeToEditLayout(layoutId) {
      this.editLayouts = true
      this.selectedSlide = null
      if (layoutId) {
        this.selectLayoutById(layoutId)
      } else {
        this.goToFirstLayout()
      }
    },
    changeToEditSlides() {
      this.editLayouts = false
      this.selectedSlide = null
      this.goToFirstSlide()
    },
    updateText({ block, text }) {
      block.data.text = text
    },
    updateSlides() {
      const pureTreeData = getPureTreeData(this.slidesTree.slides) || []
      this.lessonPlanContents.slides = cloneDeep(pureTreeData)

      // Not needed to save path property in database
      walkTreeData(this.lessonPlanContents.slides, node => {
        delete node.path
      })
    },
    addPathToItem(item, parent) {
      let path = []
      const itemPath = { id: item.id, title: item.title, hidden: item.skip }

      if (parent) {
        path = cloneDeep(parent.path)
        if (path) {
          path.push(itemPath)
        }
      } else {
        path = [itemPath]
      }
      item.path = path
    },
    async removeSlideById(tree, id, removeComments) {
      let found = false
      let previousSlide = null
      let nextSlide = null
      for (let index = 0; index < tree.length; index++) {
        if (!found) {
          if (tree[index].id === id) {
            found = true
            previousSlide = index <= 0 ? null : tree[index - 1]
            nextSlide = index >= tree.length - 1 ? null : tree[index + 1]
            if (removeComments) {
              await this.findCommentsAndDelete(this.comments, tree[index])
            }
            tree.splice(index, 1)
          } else {
            if (tree[index].children && tree[index].children.length) {
              this.removeSlideById(tree[index].children, id, removeComments)
            }
          }
        }
      }
      if (found && this.selectedSlide.id === id) {
        if (previousSlide != null) {
          this.selectedSlide = previousSlide
        } else if (nextSlide != null) {
          this.selectedSlide = nextSlide
        } else {
          this.selectedSlide = null
        }
      }
    },
    async findCommentsAndDelete(comments, slide) {
      const commentsToDelete = findCommentsForSlide(slide, comments)
      const results = []
      for (let commentToDelete of commentsToDelete) {
        try {
          results.push(await ptApiClient.softDeleteComment(getContentId(this.vm.value), commentToDelete._id))
        } catch (e) {
          // Do nothing
        }
      }
      if (commentsToDelete.length !== results.length) {
        this.$modal("info", { title: "Not all comments have been removed from the deleted slides." })
      }
    },
    setEditTitleMode() {
      this.editTitleMode = true
    },
    cloneSlide() {
      if (this.selectedSlide) {
        const newSlide = this.createNewSlide()
        let search = this.getParents(this.slidesTree.slides, this.selectedSlide.id, [])
        if (search.found) {
          this.addElementInTree(newSlide, search.parents)
        }
      }
    },
    cloneChildrenForSection(slide) {
      if (slide.children && slide.children.length > 0) {
        slide.children.forEach(children => {
          this.cloneChildrenForSection(children)
        })
      }
      const newSlide = this.createNewSlide(slide)
      let search = this.getParents(this.slidesTree.slides, slide.id, [])
      if (search.found) {
        this.addElementInTree(newSlide, search.parents)
      }
    },
    cloneSection() {
      if (this.selectedSlide) {
        this.cloneChildrenForSection(this.selectedSlide)
      }
    },
    createNewSlide(slide) {
      const newSlide = cloneDeep(slide ? slide : this.selectedSlide)
      changeAllBlockIds(newSlide)
      return newSlide
    },
    copySlidesAsLayouts() {
      this.progressModal.title = "Copying slides......"
      this.progressModal.show = true

      const slides = this.slideArray.filter(slide => this.isSlide(slide))

      slides.forEach(slide => {
        this.addToLayout(slide)
      })

      this.progressModal.show = false
    },
    addElementInTree(newSlide, parents) {
      const lastParent = parents[parents.length - 1]
      const parentChildren = lastParent ? lastParent.children : this.slidesTree.slides

      for (let [index, element] of parentChildren.entries()) {
        if (element.id === this.selectedSlide.id) {
          parentChildren.splice(index + 1, 0, newSlide)
          break
        }
      }
    },
    getParents(tree, id, parents) {
      let result = {
        found: false,
        parents: parents
      }
      tree.forEach(element => {
        if (!result.found) {
          if (element.id === id) {
            result.found = true
            result.parents = parents
          } else if (this.isSection(element)) {
            if (element.children && element.children.length) {
              parents.push(element)
              result = this.getParents(element.children, id, parents)
              if (!result.found) {
                parents.pop()
              }
            }
          }
        }
      })
      return result
    },
    getCurrentRevision(item) {
      if (!item || !item.content_ref) return ""
      const header = item.content_ref.header
      if (!header) return ""
      return `${header.major_revision}.${header.minor_revision}`
    },
    getLatestRevision(item) {
      return item && item.content_ref ? item.content_ref.__latest : null
    },
    async updateSlideLatestRevision(value) {
      if (value.data.content_ref.$origin.includes("plant://")) {
        const [protocol, empty, library_id, content, content_id, major_revision, minor_revision] =
          value.data.content_ref.$origin.split("/")
        const [latest_major_revision, latest_minor_revision] = value.data.content_ref.__latest.split(".")
        const { data } = await libraryApi.getContentByRevision(
          library_id,
          content_id,
          latest_major_revision,
          latest_minor_revision
        )
        data.$origin = `plant://${library_id}/content/${content_id}/${latest_major_revision}/${latest_minor_revision}`
        value.data.content_ref = data
      } else {
        const customId = getCustomId(value.data.content_ref)
        let last_content = await ptApiClient.getContentByCustomId(customId)
        last_content.__latest = this.getCurrentRevision({
          content_ref: last_content
        })
        value.data.content_ref = last_content
      }
    },
    createMinorVersion(block) {
      if (block && block.data.content_ref) {
        this.vm.$emit("create:draft", block.data.content_ref)
      }
    },
    selectBlock() {
      this.selectedBlock = this.selectedSlide.blocks[this.selectedSlide.blocks.length - 1]
    },
    removeTemplate(templateId) {
      this.removeLayoutsAndGalleries(templateId)
      // remove template
      this.vm.value.body.contents.templates = this.vm.value.body.contents.templates.filter(
        template => template.id !== templateId
      )
    },
    removeLayoutsAndGalleries(templateId) {
      // remove layouts
      this.vm.value.body.contents.layouts = this.vm.value.body.contents.layouts.filter(
        layout => layout.imported !== templateId
      )
      // remove galleries
      this.vm.value.body.contents.galleries = this.vm.value.body.contents.galleries.filter(
        gallery => gallery.imported !== templateId
      )
    },
    async addImportedLayout(importedLayout, templateId) {
      this.vm.value.body.contents.layouts.push({
        id: importedLayout.id,
        imported: templateId,
        name: importedLayout.name,
        slide: await deepSlideCopy(importedLayout.slide)
      })
    },
    async addImportedGallery(importedGallery, templateId) {
      this.vm.value.body.contents.galleries.push({
        id: importedGallery.id,
        imported: templateId,
        name: importedGallery.name,
        stencils: await deepSlideCopy(importedGallery.stencils)
      })
    },
    setColorPalette(colorPalette) {
      this.vm.value.body.contents.settings.styles.colors = colorPalette
    }
  },
  watch: {
    selectedSlide: {
      deep: true,
      handler() {
        const updateSlidesDebounce = () => {
          clearTimeout(this.timerChangeSlideContent)
          this.timerChangeSlideContent = setTimeout(() => {
            this.updateSlides()
          }, 500)
        }
        updateSlidesDebounce()
      }
    },
    templates: {
      deep: true,
      async handler(newTemplates, oldTemplates) {
        for (const newT of newTemplates) {
          const oldTemplateFound = oldTemplates.find(oldT => oldT.id === newT.id)
          if (
            oldTemplateFound &&
            oldTemplateFound.reference &&
            oldTemplateFound.reference.$origin !== newT.reference.$origin
          ) {
            this.removeLayoutsAndGalleries(newT.id)

            if (!newT.hasOwnProperty("contentImported") || newT.contentImported.layouts) {
              const nativeTemplateLayout = newT.reference.body.contents.layouts.filter(
                layout => layout.imported === false
              )
              for (let i = 0; i < nativeTemplateLayout.length; i++) {
                resetVisitedSlide(nativeTemplateLayout[i].slide)
                await this.addImportedLayout(nativeTemplateLayout[i], newT.id)
              }
            }

            if (!newT.hasOwnProperty("contentImported") || newT.contentImported.galleries) {
              const nativeTemplateGalleries = newT.reference.body.contents.galleries.filter(
                gallery => gallery.imported === false
              )
              for (let j = 0; j < nativeTemplateGalleries.length; j++) {
                await this.addImportedGallery(nativeTemplateGalleries[j], newT.id)
              }
            }

            if (newT.contentImported && newT.contentImported.colorPalette) {
              this.setColorPalette(newT.reference.body.contents.settings.styles.colors)
            }
          }
        }
        if (this.editLayouts) this.goToFirstLayout()
      }
    }
  }
}
