<template>
  <fragment>
    <p-panel-properties-group title="Radiobutton" v-if="false">
      <p-property-tile title="Value Variable">
        <p-panel-input-variable v-model="variable" />
      </p-property-tile>
      <p-property-tile title="Items Variable">
        <p-panel-input-variable />
      </p-property-tile>
    </p-panel-properties-group>
    <p-panel-properties-group title="Text alignment">
      <text-alignment-form :data="value.data.style" />
    </p-panel-properties-group>
    <p-panel-properties-group title="Font">
      <font-global-form
        :data="value.data.style"
        :id="value.id"
        :showFontFamily="true"
        :font-sizes-numbers="fontSizesNumbers" />
    </p-panel-properties-group>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment"
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputVariable from "../_components/utils/PPanelInputVariable.vue"
import PPanelInputSelect from "../_components/utils/PPanelInputSelect.vue"
import FontGlobalForm from "../_components/panel/FontGlobalForm.vue"
import { fontSizesNumbers } from "../_components/panel/fontGlobalCommon"
import TextAlignmentForm from "../_components/panel/TextAlignmentForm.vue"

export default {
  inject: ["blockGlobals"],
  components: {
    Fragment,
    PPanelInputSelect,
    PPanelPropertiesGroup,
    PPropertyTile,
    PPanelInputVariable,
    FontGlobalForm,
    TextAlignmentForm
  },
  props: ["value"],
  data() {
    return {
      variable: false,
      fontSizesNumbers
    }
  }
}
</script>
