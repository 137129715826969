<template>
  <div
    style="
      background: #efefef;
      border: dashed;
      margin: 20px;
      width: calc(100% - 40px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    @drop.prevent="filesDropped"
    @dragover.prevent>
    <div class="display-2" style="text-align: center">
      <v-container grid-list-xl text-xs-center>
        <v-layout row wrap>
          <v-flex xs12>
            <ContentTypeIcon height="200px" :type="type" />
            <p style="font-size: 32px">{{ type }}</p>
            <p>Drop XLSX file here</p>
            <p style="font-size: 32px">
              or
              <a @click="selectFiles">select from your computer</a>
            </p>
          </v-flex>
          <v-flex xs6 offset-xs3>
            <bulk-upload-download-templates :type="type" />
          </v-flex>
          <v-flex xs6 offset-xs3>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" depressed small flat class="cursor-default">
                  <v-icon left>mdi-information-outline</v-icon>Advanced information
                </v-btn>
              </template>
              <span>
                Use PLANT URLs for the following fields: <br />
                - objective: "plant:/custom-id/OBJECTIVE_CUSTOM_ID"
              </span>
            </v-tooltip>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx"
import { mapActions, mapState } from "vuex"
import { fileToArrayBuffer } from "@/utils/bulkUpload"
import { ContentTypeIcon } from "plant-common"
import { selectFileFromComputer } from "@/utils/bulkUpload"
import * as ContentBodies from "@/classes/content/index.js"
import ptApiClient from "plant-api-client"

import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_ERROR } from "@/store_constants/notifications"
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "@/store_constants/library"

import BulkUploadDownloadTemplates from "./DownloadTemplates.vue"
import { getContentId, getMajorVersion, getMinorVersion } from "../../../../../utils/dbTool"

/**
 * Build absolute PLANT url from custom_id (assuming the library_id is always the current one)
 */
async function buildResultObject(customId, library_id) {
  let result = {}
  try {
    const content = await ptApiClient.getContentByCustomId(customId)

    result = {
      $origin: `plant://${library_id}/content/${getContentId(content)}/${getMajorVersion(content)}/${getMinorVersion(
        content
      )}`,
      custom_id: customId
    }
  } catch (e) {
    // Manage when the url is malformed. Throw an error maybe?
    result = undefined
  }

  return result
}

/**
 * Get Custom ID from PLANT url if match regex or assume it is directly the custom_id
 */
function getCustomIdFromPlantUrl(plantUrl) {
  const match = /^plant:\/custom-id\/(.*)$/g.exec(plantUrl)

  if (match && match[1]) return match[1]

  return plantUrl.trim()
}

export default {
  name: "BulkUploadUploadXls",
  props: ["type"],
  components: {
    ContentTypeIcon,
    BulkUploadDownloadTemplates
  },
  data() {
    return {
      allowed_types: [],
      new_contents: []
    }
  },
  computed: {
    ContentBodyConstructor() {
      return Object.values(ContentBodies).find(c => c.CONTENT_TYPE == this.type)
    },
    ...mapState(STORE_LIBRARY_NAME, {
      library_id: STATE_PLANT_ID
    })
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    async selectFiles() {
      var [file] = await selectFileFromComputer(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        false
      )
      this.uploadXlsxTemplate(file)
    },
    filesDropped(evt) {
      if (evt.dataTransfer.files.length != 1) {
        alert("You can only drop one file")
        return
      }
      var file = evt.dataTransfer.files[0]
      if (file.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        alert("You can only drop a XLSX file.")
        return
      }
      this.uploadXlsxTemplate(file)
    },
    async uploadXlsxTemplate(file) {
      const file_buffer = await fileToArrayBuffer(file)
      const wb = XLSX.read(file_buffer, { type: "array" })
      const import_data = XLSX.utils.sheet_to_json(wb.Sheets["IMPORT-DATA"])
      const import_config = XLSX.utils.sheet_to_json(wb.Sheets["IMPORT-CONFIG"])[0]

      if (import_data.length == 0) {
        this.showAlert(`Nothing to import.`)
        return
      }

      if (!(import_config && import_config.type)) {
        this.showAlert(
          `Malformed File: IMPORT-CONFIG not defined correctly. Please, download specific template and try again.`
        )
        return
      }

      this.allowed_types = [import_config.type]

      const cache = {} // Auxiliary cache to avoid repetitive calls to the API when transforming urls
      for await (const new_content of import_data) {
        const { custom_id, title, custom_revision } = new_content

        // If any of the properties in the spreadsheet have a PLANT url format (ie. plant:/custom-id/XXXX), change to a $ref format
        if (new_content.hasOwnProperty("objective") && new_content.objective) {
          const objectiveCustomId = getCustomIdFromPlantUrl(new_content.objective)

          // Check if URL is in cache, if not, add it to the cache
          if (!(objectiveCustomId in cache)) {
            cache[objectiveCustomId] = await buildResultObject(objectiveCustomId, this.library_id)
          }

          new_content.objective = cache[objectiveCustomId]
        }

        const body = new this.ContentBodyConstructor(new_content)
        this.new_contents.push({
          custom_id: custom_id + "",
          title: title + "",
          body,
          valid: true,
          custom_revision: custom_revision ? custom_revision + "" : "",
          status: "not-started" // 'not-started', 'in-progress', 'done', 'error'
        })
      }
      this.$emit("upload-finished", this.new_contents)
    },

    showAlert(message) {
      this.error({ message, timeout: -1 })
    }
  }
}
</script>

<style>
.type-name {
  font-size: 32px;
}

.cursor-default {
  cursor: default;
}
</style>
