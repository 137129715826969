<template>
  <fragment>
    <div v-for="x in gridsX" :key="x" :style="styleGridX(x)" />
    <div v-for="y in gridsY" :key="y" :style="styleGridY(y)" />
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment"

export default {
  props: {
    scale: Number,
    gridX: Number,
    gridY: Number,
    originX: Number,
    originY: Number,
    canvasWidth: Number,
    canvasHeight: Number
  },
  components: { Fragment },
  computed: {
    gridsX() {
      var grids = []
      var spaceX = this.gridX * this.scale
      var totalGrids = this.canvasWidth / spaceX
      var deltaX = this.originX % spaceX
      for (var i = 0; i < totalGrids; i++) {
        grids[i] = spaceX * i - deltaX
      }
      return grids
    },
    gridsY() {
      var grids = []
      var spaceY = this.gridY * this.scale
      var totalGrids = this.canvasHeight / spaceY
      var deltaY = this.originY % spaceY
      for (var i = 0; i < totalGrids; i++) {
        grids[i] = spaceY * i - deltaY
      }
      return grids
    },
    viewerSize() {
      return this.canvasWidth + this.canvasHeight
    }
  },
  watch: {
    viewerSize() {
      this.$emit("zoomfit")
    }
  },
  methods: {
    styleGridX(x) {
      return {
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: `${x}px`,
        width: "1px",
        background: "rgba(0, 0, 0, 0.1)"
      }
    },
    styleGridY(y) {
      return {
        position: "absolute",
        left: "0px",
        right: "0px",
        top: `${y}px`,
        height: "1px",
        background: "rgba(0, 0, 0, 0.1)"
      }
    }
  }
}
</script>
