<template>
  <v-container class="scrollable">
    <v-alert
      :value="true"
      color="info"
      icon="info"
      v-show="libraries.length > 0"
    >
      Please, choose a library.
    </v-alert>
    <v-layout row wrap v-if="libraries.length > 0">
      <v-flex
        lg4
        md6
        sm12
        xs12
        v-for="(library, index) in libraries"
        :key="index"
      >
        <v-card
          :to="{ name, params: { library_id: library._id } }"
          class="component-card"
        >
          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">
                <v-icon>mdi-library-shelves</v-icon> {{ library.name }}
              </h3>
              <div>{{ library.description }}</div>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-else class="text-center">
      <img :src="require('./placeholders/no-library.svg')" style="max-width:800px"/>
      <h1 class="heading">No library found. Create one on Application Settings</h1>
    </div>
  </v-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  STORE_FLEET_NAME,
  STATE_LIBRARIES,
  ACTION_GET_LIBRARIES
} from "@/store_constants/fleet";
export default {
  name: "LibrarySelectorCard",
  props: {
    name: String
  },
  created() {
    this.getLibraries();
  },
  computed: {
    ...mapState(STORE_FLEET_NAME, {
      libraries: STATE_LIBRARIES
    })
  },
  methods: {
    ...mapActions(STORE_FLEET_NAME, {
      getLibraries: ACTION_GET_LIBRARIES
    })
  }
};
</script>

<style>
.component-card {
  margin: 30px;
  padding: 10px;
  min-height: 200px;
}
.title-card {
  color: #4e4e4e;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 24px;
  text-align: center;
}
.scrollable {
  overflow: auto;
  max-height: -moz-available;
  max-height: -webkit-fill-available;
  max-height: stretch;
}

.subtext-card {
  color: #929292;
  height: 75px;
}

.fix-card-height {
  min-height: 385px;
}
</style>