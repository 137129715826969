import uuid from "../../../../utils/uuid.js"

export default function () {
  return {
    content_ref: {},
    blocks: [
      {
        id: uuid(),
        name: "",
        position: {
          x: 80,
          y: 45
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        actions: [],
        has_activity: false,
        size: {
          width: 1760,
          height: 180
        },
        protection: {
          type: ["textarea"],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        data: {
          html: "<p><span class=\"fields\" data-field=\"QuestionQuestion\">{{QuestionQuestion}}</span></p>",
          fontSize: 46,
          color: "#000000ff",
          textAlign: "center",
          alignItems: "center",
          fontFamily: false,
          padding: 0
        },
        type: "textarea"
      },
      {
        id: uuid(),
        name: "",
        position: {
          x: 1440,
          y: 945
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        has_activity: false,
        size: {
          width: 400,
          height: 90
        },
        protection: {
          type: ["button"],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        data: {
          text: "Submit",
          icon: "none",
          visited: false,
          style: {
            regular: {
              backgroundColor: "#f5f5f5ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              fontFamily: false
            },
            hover: {
              backgroundColor: "#e0e0e0ff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              fontFamily: false
            },
            active: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              fontFamily: false
            },
            visited: {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              fontFamily: false
            }
          },
          actions: [
            {
              trigger: "click",
              type: "event",
              event: "submit"
            }
          ]
        },
        type: "button"
      },
      {
        id: uuid(),
        name: "",
        position: {
          x: 80,
          y: 270
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        has_activity: false,
        size: {
          width: 1200,
          height: 630
        },
        protection: {
          type: [],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        data: {
          items: "QuestionChoices",
          value: "QuestionUserChoice",
          style: {
            color: "#000000ff",
            fontSize: 42,
            fontFamily: false,
            textAlign: "left",
            alignItems: "center",
            padding: 0
          }
        },
        type: "inputRadiobutton"
      },
      {
        id: uuid(),
        name: "",
        position: {
          x: 1030,
          y: 250
        },
        animations: [
          {
            trigger: "auto",
            name: "none",
            duration: 1,
            delay: 0
          }
        ],
        actions: [],
        has_activity: false,
        size: {
          width: 800,
          height: 600
        },
        protection: {
          type: [],
          data: false,
          move: false,
          resize: false,
          delete: false
        },
        data: {
          style: {
            normal: {
              borderRadius: 0
            }
          },
          image: null,
          cropped: null,
          cropped_data: {},
          overlay: "#00000000"
        },
        type: "imageMultipleChoice"
      }
    ],
    overlays: {
      correct: {
        id: uuid(),
        width: 960,
        height: 630,
        style: {
          backgroundColor: "#C5FFA6FF",
          borderRadius: 0
        },
        blocks: [
          {
            id: uuid(),
            name: "",
            position: {
              x: 0,
              y: 270
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 960,
              height: 90
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p>Good Job!</p>",
              fontSize: 60,
              color: "#000000ff",
              textAlign: "center",
              alignItems: "center",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          },
          {
            id: uuid(),
            name: "",
            position: {
              x: 320,
              y: 45
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 320,
              height: 225
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p><span class=\"custom-color\" style=\"color: #000000FF\">✔</span></p>",
              fontSize: 200,
              color: "#FF0000FF",
              textAlign: "center",
              alignItems: "center",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          },
          {
            id: uuid(),
            name: "",
            position: {
              x: 0,
              y: 360
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 960,
              height: 90
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p><strong>Answer Explanation</strong></p>",
              fontSize: 34,
              color: "#000000ff",
              textAlign: "center",
              alignItems: "center",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          },
          {
            id: uuid(),
            name: "",
            position: {
              x: 0,
              y: 450
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 960,
              height: 180
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p><span class=\"fields\" data-field=\"QuestionAnswerExplanation\" v-html=\"QuestionAnswerExplanation\">{{QuestionAnswerExplanation}}</span> </p>",
              fontSize: 30,
              color: "#000000ff",
              textAlign: "center",
              alignItems: "flex-start",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          }
        ]
      },
      incorrect: {
        id: uuid(),
        width: 800,
        height: 600,
        style: {
          backgroundColor: "#CA7489FF",
          borderRadius: 0
        },
        blocks: [
          {
            id: uuid(),
            name: "",
            position: {
              x: 0,
              y: 287
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 800,
              height: 180
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p><span class=\"custom-color\" style=\"color: #272727FF\">Try Again!</span></p>",
              fontSize: 72,
              color: "#000000ff",
              textAlign: "center",
              alignItems: "center",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          },
          {
            id: uuid(),
            name: "",
            position: {
              x: 0,
              y: 103
            },
            animations: [
              {
                trigger: "auto",
                name: "none",
                duration: 1,
                delay: 0
              }
            ],
            has_activity: false,
            size: {
              width: 800,
              height: 180
            },
            protection: {
              type: ["textarea"],
              data: false,
              move: false,
              resize: false,
              delete: false
            },
            data: {
              html: "<p>❌</p>",
              fontSize: 120,
              color: "#000000ff",
              textAlign: "center",
              alignItems: "center",
              fontFamily: false,
              padding: 0
            },
            type: "textarea"
          }
        ]
      }
    }
  }
}
