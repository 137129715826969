<template>
  <v-card-text class="content-grid-card-container">
    <div class="content-grid-card-folder">
      <v-icon color="yellow lighten-1" size="65px">mdi-folder</v-icon>
      <div class="subheading font-weight-medium text-truncate mt-2 mb-2 ml-2" style="width: 100%">{{ name }}</div>
    </div>
  </v-card-text>
</template>

<script>
export default {
  props: {
    name: {
      type: String
    }
  }
}
</script>

<style scoped>
.content-grid-card-folder {
  display: flex;
  align-items: center;
  height: 75px;
}

.content-grid-card-container {
  display: flex;
  align-items: center;
}
</style>