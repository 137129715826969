<template>
  <fragment>
    <p-panel-properties-group v-if="value && value.data && value.data.content_ref" title="Library content">
      <p-content-tile :value="value.data.content_ref">
        <template v-slot:append>
          <v-list-tile-action class="dependencies-list-action">
            <v-btn icon ripple @click="openDependency">
              <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
            </v-btn>
          </v-list-tile-action>
        </template>
      </p-content-tile>

      <p-property-tile title="Show toolbar">
        <v-switch v-model="value.data.show_library_toolbar"></v-switch>
      </p-property-tile>

      <p-property-tile v-if="isVideo" title="Autoplay (Video without annotations)">
        <v-switch v-model="value.data.video_autoplay"></v-switch>
      </p-property-tile>
      <p-property-tile icon="mdi-timer" title="Delay (seconds)" v-if="value.data.video_autoplay">
        <p-panel-input-number v-model="value.data.video_delay" :min="0" />
      </p-property-tile>
      <p-property-tile title="FAB: Open in new tab">
        <v-switch v-model="value.data.show_fab"></v-switch>
      </p-property-tile>
    </p-panel-properties-group>
  </fragment>
</template>
<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelInputNumber from "../_components/utils/PPanelInputNumber.vue"
import { Fragment } from "vue-fragment"
import { PContentTile } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { getType } from "../../../../utils/dbTool"

export default {
  name: "libraryContentPanel",
  components: {
    PPanelPropertiesGroup,
    PContentTile,
    PPropertyTile,
    PPanelInputNumber,
    Fragment
  },
  props: ["value"],
  data() {
    return {}
  },
  computed: {
    isVideo() {
      return this.isVideoContent || this.isYoutubeContent
    },
    isVideoContent() {
      return this.value.data.content_ref && getType(this.value.data.content_ref) === "video"
    },
    isYoutubeContent() {
      return this.value.data.content_ref && getType(this.value.data.content_ref) === "youtubeVideo"
    }
  },
  watch: {
    isVideo() {
      if (!this.isVideo) {
        this.value.data.video_autoplay = false
      }
    },
    "value.data.video_autoplay": {
      immediate: true,
      handler() {
        if (this.value.data.video_autoplay && !this.value.data.video_delay) {
          this.$set(this.value.data, "video_delay", 0)
        }
      }
    }
  },
  methods: {
    openDependency() {
      PServiceContent.openContent(this.value.data.content_ref, "tab", JSON.stringify(this.value.data.pinned_view))
    }
  }
}
</script>
