import { isRichTextFieldEmpty } from "./helpers"

export const validateObjective = objective => {
  const field = objective.body.contents.text ? objective.body.contents.text : ""
  if (isRichTextFieldEmpty(field)) return {
    isValid: false,
    message: "Required field is empty (description)"
  }
  return {
    isValid: true,
    message: ""
  }
}
