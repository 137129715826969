<template>
  <div class="lp-slide-properties">
    <v-toolbar flat class="elevation-0 transparent">
      <v-toolbar-title class="subheading">Slide Properties</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn flat v-on="on" class="lp-outline-panel-actions-button">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <!-- Add to layout -->
          <v-list-tile @click="addToLayout">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-shape-rectangle-plus</v-icon>
                <span>Save Slide as Layout</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>

          <!-- Set slide as home -->
          <v-list-tile @click="setSlideAsHome">
            <v-list-tile-title class="body-1">
              <v-layout row align-center>
                <v-icon class="mr-2">mdi-home</v-icon>
                <span>Set Slide as Home</span>
              </v-layout>
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-list three-line>
      <v-subheader class="lp-right-panel-header">Current Content Information</v-subheader>

      <!-- Slide title -->
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title class="lp-right-panel-tile-title">Slide Title</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-text-field
        v-if="lessonPlanController.mode == 'edit'"
        outline
        class="mt-0 pl-3 pr-3"
        solo
        v-model="lessonPlanController.selectedSlide.title"></v-text-field>
      <p v-else class="lp-slide-properties-view-mode">
        {{ lessonPlanController.selectedSlide.title }}
      </p>

      <!-- Bookmark -->
      <v-list-tile class="lp-slide-properties-bookmark">
        <v-list-tile-content>
          <v-list-tile-title class="lp-right-panel-tile-title">Bookmark</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-text-field
        v-if="lessonPlanController.mode == 'edit'"
        outline
        class="mt-0 pl-3 pr-3"
        solo
        v-model="lessonPlanController.selectedSlide.bookmark"></v-text-field>
      <p v-else class="lp-slide-properties-view-mode">
        {{ lessonPlanController.selectedSlide.bookmark }}
      </p>

      <span
        v-if="
          lessonPlanController.isSection(lessonPlanController.selectedSlide) ||
          lessonPlanController.isLearningObject(lessonPlanController.selectedSlide)
        ">
        <!-- Navigation -->
        <v-list-tile-content class="lp-slide-properties-navigation">
          <v-list-tile-title>
            <v-icon small>mdi-lock-outline</v-icon>
            Lock Section navigation
          </v-list-tile-title>
          <v-list-tile-sub-title>
            Makes "viewing the current section" mandatory to continue navigating to subsequent slides
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-sub-title class="mb-4">
          <v-flex offset-xs1 xs10 class="mt-4">
            <v-select
              :disabled="lessonPlanController.mode !== 'edit'"
              :items="navigationOptions"
              v-model="lessonPlanController.selectedSlide.locked"></v-select>
          </v-flex>
        </v-list-tile-sub-title>

        <!-- Hide -->
        <v-list-tile-content class="lp-slide-properties-navigation">
          <v-list-tile-title>
            <v-icon small>mdi-debug-step-over</v-icon>
            Hide Section Cover
          </v-list-tile-title>
          <v-list-tile-sub-title>
            <span>
              Check to disallow visiting the current section. It will automatically go to the next available slide or
              section.
            </span>
          </v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-sub-title>
          <v-flex offset-xs1 xs10>
            <v-switch
              v-model="lessonPlanController.selectedSlide.skip"
              hide-details
              :disabled="lessonPlanController.mode != 'edit'"></v-switch>
          </v-flex>
        </v-list-tile-sub-title>
      </span>
    </v-list>

    <!-- Change layout -->
    <div class="text-center mt-4">
      <v-btn @click="changeLayout" outline color="primary">
        <v-icon left>mdi-page-layout-header-footer</v-icon>
        Change Layout
      </v-btn>
    </div>
  </div>
</template>

<script>
import { getPureTreeData } from "he-tree-vue"
import navigationsOptions from "../constants/navigationOptions"

export default {
  name: "SlideProperties",
  inject: ["lessonPlanController"],
  created() {
    if (this.lessonPlanController.selectedSlide.locked && this.lessonPlanController.selectedSlide.locked === true) {
      this.lessonPlanController.selectedSlide.locked = "sequential"
    }
  },
  data() {
    return {
      navigationOptions: navigationsOptions
    }
  },
  computed: {
    layouts() {
      return this.lessonPlanController.lessonPlanContents.layouts
    }
  },
  methods: {
    addToLayout() {
      this.lessonPlanController.addToLayout(this.lessonPlanController.selectedSlide)
      const lastLayout = this.layouts[this.layouts.length - 1]
      this.lessonPlanController.changeToEditLayout(lastLayout.id)
    },
    changeLayout() {
      const slide = getPureTreeData(this.lessonPlanController.selectedSlide)
      this.lessonPlanController.configureSlide(slide, null, true)
    },
    setSlideAsHome() {
      if (!this.lessonPlanController.lessonPlanContents.settings.styles.breadcrumb) {
        this.lessonPlanController.lessonPlanContents.settings.styles.breadcrumb = {
          home: {
            slide_id: null
          }
        }
      }
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home.slide_id =
        this.lessonPlanController.selectedSlide.id
    }
  }
}
</script>

<style>
.lp-slide-properties .v-text-field,
.lp-slide-properties-view-mode {
  margin-top: -30px !important;
}

.lp-slide-properties-view-mode {
  margin-left: 15px;
  margin-bottom: 30px;
}

.lp-slide-properties .v-text-field--single-line input {
  margin-top: 0 !important;
}

.lp-slide-properties .theme--light.v-list .v-list__tile--link:hover {
  background: transparent !important;
}

.lp-slide-properties .v-text-field--outline > .v-input__control > .v-input__slot {
  min-height: 40px;
}

.lp-slide-properties-bookmark {
  margin-top: -30px;
}

/* Add remove layoutt */
.lp-slide-properties-add-remove.v-btn {
  min-width: 20px;
  padding: 0;
}

.lp-slide-properties-add-remove .v-icon {
  font-size: 18px;
}

.lp-slide-properties-navigation {
  margin: 0px 20px;
}
</style>
