<template>
  <v-list-group no-action dense v-model="isActive">
    <template v-slot:activator>
      <v-list-tile>
        <v-list-tile-avatar v-if="icon">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ title }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <span :id="actionIconIdNamed">
        <slot name="actionIcon"> </slot>
      </span>
    </template>
    <v-list dense class="my-tile">
      <slot />
    </v-list>
  </v-list-group>
</template>

<script>
export default {
  data() {
    return {
      isActive: false
    }
  },
  props: {
    title: {
      type: String
    },
    icon: {
      type: String
    },
    color: {
      type: String
    },
    active: {
      type: Boolean,
      value: false
    },
    actionIcon: {
      type: Boolean
    },
    actionIconId: {
      type: String,
      value: ""
    }
  },
  computed: {
    actionIconIdNamed() {
      return `actionIcon-${this.actionIconId}`
    }
  },
  created() {
    this.isActive = this.active
  },
  mounted() {
    if (this.actionIcon) this.setIconsOrder()
  },
  methods: {
    setIconsOrder() {
      const actionIcon = document.getElementById(this.actionIconIdNamed)
      actionIcon.style.order = 1

      const collapsedIcon = document.querySelector(`#${this.actionIconIdNamed} + .v-list__group__header__append-icon`)
      collapsedIcon.style.order = -1
    }
  }
}
</script>

<style>
.my-tile .v-list__tile {
  padding-left: 16px !important;
}
</style>
