import PServiceContent from "../services/PServiceContent"

// Embedded File API
export class PFile {
    constructor(file) {
        /** unique id to retrieve the file or the File object */
        this._file = file
        /** URL to retrieve the file */
        this.url = undefined
        /** Original name of the file. used to determine mime type */
        this.type = file.type
        this.name = file.name
    }

    async upload(contentId) {
        const { filename, url } = await PServiceContent.uploadFileService(this._file, contentId)
        this._file = filename
        this.url = url
    }
}