<template>
  <v-list-tile avatar class="lp-slide-detail" :class="{ active: isActive }">
    <v-btn
      flat
      type="button"
      class="lp-slide-detail-content"
      :class="{ unavailable: !slideAvailable }"
      @click="selectSlide(slide, $event)"
      @dblclick="lessonPlanController.setEditTitleMode()">
      <!-- icon section -->
      <v-list-tile-action class="mr-2">
        <!-- Quizz       -->
        <span d-flex v-if="lessonPlanController.isQuiz(slide)">
          <v-icon
            v-if="slide.children.length > 0"
            class="lp-arrow"
            :class="{ 'lp-arrow-open': !slide.$folded }"
            color="#888888"
            @click="toggleFoldIf(slide)"
            id="toggleIcon"
            >mdi-chevron-right</v-icon
          >
          <v-icon :color="slide.$folded ? 'grey' : '#1976d2'">{{
            slide.$folded ? "mdi-book-education" : "mdi-book-education-outline"
          }}</v-icon>
        </span>

        <!-- Learning Object       -->
        <span d-flex v-else-if="lessonPlanController.isLearningObject(slide)">
          <v-icon
            v-if="slide.children.length > 0"
            class="lp-arrow"
            :class="{ 'lp-arrow-open': !slide.$folded }"
            color="#888888"
            @click="toggleFoldIf(slide)"
            id="toggleIcon"
            >mdi-chevron-right</v-icon
          >
          <v-icon :color="slide.$folded ? 'grey' : '#1976d2'">{{
            slide.$folded ? "mdi-book-open-page-variant" : "mdi-book-open-page-variant-outline"
          }}</v-icon>
        </span>

        <!-- Section       -->
        <span d-flex v-else-if="lessonPlanController.isSection(slide)">
          <v-icon
            v-if="slide.children.length > 0"
            class="lp-arrow"
            :class="{ 'lp-arrow-open': !slide.$folded }"
            color="#888888"
            @click="toggleFoldIf(slide)"
            id="toggleIcon"
            >mdi-chevron-right</v-icon
          >
          <v-icon :color="slide.$folded ? 'grey' : '#1976d2'">{{
            slide.$folded ? "mdi-folder" : "mdi-folder-open"
          }}</v-icon>
        </span>
      </v-list-tile-action>
      <v-list-tile-action class="mr-2">
        <v-icon color="#1976d2" v-if="slide.id === breadcrumbHome">mdi-home</v-icon>
      </v-list-tile-action>

      <v-list-tile-content :class="{ 'lp-slide-detail-content-edit-mode': isEditMode }">
        <!-- text -->
        <v-list-tile-title :style="`max-width: ${maxWidthSlideTitle}px`">
          <v-text-field
            v-if="isEditMode"
            ref="renameTitle"
            outline
            class="mt-0 pl-3 pr-3"
            solo
            v-model="newTitle"
            @blur="onEdit(slide)"
            @input="onLiveEdit(slide)"
            @keydown.enter="onEdit(slide)"></v-text-field>

          <v-tooltip top v-else>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <span v-if="lessonPlanController.slideNumberShown">{{ slideNumber }}</span>
                {{ slide.title }}
              </span>
            </template>
            <span>{{ slide.title }}</span>
          </v-tooltip>
        </v-list-tile-title>
      </v-list-tile-content>
    </v-btn>

    <v-list-tile-action v-if="lessonPlanController.mode === 'edit' && slide.locked" class="mr-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span d-flex v-on="on">
            <v-icon small>mdi-lock-outline</v-icon>
          </span>
        </template>
        <span>Section is marked as 'locked'</span>
      </v-tooltip>
    </v-list-tile-action>
    <v-list-tile-action v-if="lessonPlanController.mode === 'edit' && slide.skip" class="mr-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span d-flex v-on="on">
            <v-icon small>mdi-debug-step-over</v-icon>
          </span>
        </template>
        <span>Section is marked as 'skip'</span>
      </v-tooltip>
    </v-list-tile-action>

    <slot></slot>
  </v-list-tile>
</template>

<script>
import { PtModal } from "plant-common"

export default {
  inject: ["lessonPlanController"],
  props: ["slide", "path", "tree", "isActive", "isEditMode"],
  mixins: [PtModal],
  data() {
    return {
      newTitle: this.slide.title,
      clicks: 0,
      delay: 300,
      timer: null
    }
  },
  computed: {
    slideAvailable() {
      if (this.lessonPlanController.mode === "edit") {
        return true
      }
      const slideState = this.lessonPlanController.slideNavigationMap.find(slide => slide.id === this.slide.id)
      return slideState ? slideState.available : true
    },
    isCompleted() {
      const slideState = this.lessonPlanController.slideNavigationMap.find(slide => slide.id === this.slide.id)
      return slideState ? slideState.completion : true
    },
    breadcrumbHome() {
      const navigationSetting = this.lessonPlanController.lessonPlanContents.settings.styles.navigation
      const id =
        navigationSetting && navigationSetting.home && navigationSetting.home.slide_id
          ? navigationSetting.home.slide_id
          : this.lessonPlanController.slidesTree.slides[0]
          ? this.lessonPlanController.slidesTree.slides[0].id
          : undefined
      return id
    },
    maxWidthSlideTitle() {
      let width = 230
      const pathWidth = 10
      const editMode = 30
      const sectionWidth = 20
      const lockWidth = 20
      const skipWidth = 20
      const breadcrumbHome = 40

      if (this.slide.id === this.breadcrumbHome) width -= breadcrumbHome
      if (this.slide.children.length) width -= sectionWidth
      if (this.lessonPlanController.mode === "edit") width -= editMode
      if (this.slide.type === "Section") width -= sectionWidth
      if (this.slide.locked) width -= lockWidth
      if (this.slide.skip && this.lessonPlanController.mode === "edit") width -= skipWidth
      if (this.path.length > 1) width -= pathWidth * this.path.length

      return width
    },
    slideNumber() {
      const index = this.lessonPlanController.slidesVisible.findIndex(s => s.id === this.slide.id)
      return index === -1 ? "" : `${index + 1}. `
    }
  },
  watch: {
    isEditMode() {
      if (this.isEditMode && this.$refs.renameTitle) {
        this.$nextTick(() => {
          this.$refs.renameTitle.focus()
        })
      }
    },
    "slide.title"() {
      this.newTitle = this.slide.title
    }
  },
  methods: {
    selectSlide(slide, event) {
      if (this.lessonPlanController.mode !== "edit" && slide.skip && slide.skip === true) {
        // avoid to select slide
        return
      }
      if (event && event.target && event.target.id === "toggleIcon") {
        // avoid to select section if user clicks on toggle icon
        return
      }

      this.selectSlideById(slide.id)
    },
    selectSlideById(id) {
      this.lessonPlanController.selectSlideById(id)
      this.$emit("slideChanged")
    },
    toggleFoldIf(slide) {
      if (
        (this.lessonPlanController.isSection(slide) ||
          this.lessonPlanController.isQuiz(slide) ||
          this.lessonPlanController.isLearningObject(slide)) &&
        slide.children.length > 0
      ) {
        this.tree.toggleFold(slide, this.path)
      }
    },
    onEdit(slide) {
      this.changeTitleSlide(slide)
      this.lessonPlanController.editTitleMode = false
    },
    onLiveEdit(slide) {
      this.changeTitleSlide(slide)
    },
    changeTitleSlide(slide) {
      slide.title = this.newTitle
    }
  }
}
</script>

<style>
/* Content all space and slot only the minimum required */
.lp-slide-detail > a {
  display: flex;
  justify-content: space-between;
}

.lp-slide-detail-content.v-btn {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  padding-left: 0;
}

.lp-slide-detail.active {
  background-color: #d5d5d5;
}

.lp-slide-detail.active .v-list__tile__title {
  font-weight: bold;
}

.lp-slide-detail-content.unavailable {
  color: #d3d3d3 !important;
}

.lp-slide-detail .v-list__tile__sub-title {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.lp-slide-detail .v-list__tile__title {
  font-size: 14px;
  font-weight: 400;
}

.lp-slide-detail .v-list__tile__action {
  min-width: auto;
}

.lp-slide-detail .v-list__tile--avatar {
  height: 40px;
}

/* Not capitalize all content inside button */
.lp-slide-detail button {
  text-transform: none;
}

/* Same behavior as v-treeview */
.lp-slide-detail .v-btn:not(.v-btn--disabled):not(.v-btn--floating):not(.v-btn--icon) .v-btn__content .v-icon {
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.lp-slide-detail .v-icon.lp-arrow {
  transform: none;
}

.lp-slide-detail .v-icon.lp-arrow.lp-arrow-open {
  transform: rotate(90deg);
}

.lp-slide-detail-content.v-btn:hover:before {
  background-color: transparent !important;
}

/* Edit title */
.lp-slide-detail .v-list__tile__content.lp-slide-detail-content-edit-mode,
.lp-slide-detail .lp-slide-detail-content-edit-mode .v-list__tile__title {
  min-height: 46px;
}

.lp-slide-detail .v-text-field--outline > .v-input__control > .v-input__slot,
.lp-slide-detail .v-text-field.v-text-field--solo .v-input__control {
  min-height: 30px;
  max-height: 30px;
}

.lp-slide-detail .v-input__slot {
  margin-top: 7px;
}

.lp-slide-detail .v-text-field--box.v-text-field--single-line input,
.lp-slide-detail .v-text-field--full-width.v-text-field--single-line input,
.lp-slide-detail .v-text-field--outline.v-text-field--single-line input {
  margin-top: -1px;
}

.lp-slide-detail .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0 10px;
}

.lp-slide-detail .v-text-field.v-text-field--enclosed {
  margin-left: -15px;
}

/* Max width title */
.lp-slide-detail .v-list__tile__title {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
