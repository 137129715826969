import "./pt-piping-page-connector.css"
import model from "../../prototypes/STATUS_FLOW_NOFLOW"

export default {
  model,
  listeners: {
        mouseenter: true,
        mouseleave: true,
    click: true
  },
  options: {
    hasNotes: true,
    canLink: true
  }
}
