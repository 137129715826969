<template>
  <v-container>
    <v-flex xs12 sm6 offset-sm3>
      <v-card color="blue darken-4" class="white--text">
        <v-card-title primary-title>
          <div>
            <div class="headline">
              General
              <v-chip>Fleet</v-chip>
            </div>
            <span>
              <div>
                Name:
                <b>{{ profile.name }}</b>
              </div>
              <div>
                Email:
                <b>{{ profile.email }}</b>
              </div>
              <div>
                Role:
                <b>{{ profile.role }}</b>
              </div>
            </span>
          </div>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-flex xs12 sm6 offset-sm3 class="system-info">
      <v-toolbar flat color="white">
        <v-toolbar-title><v-icon class="setting-icon">mdi-information-outline</v-icon> System Info</v-toolbar-title>
      </v-toolbar>
      <v-data-table :items="versionInfo" hide-actions hide-headers>
        <template slot="items" slot-scope="props">
          <tr v-if="props.item.info">
            <td>{{ props.item.label }}</td>
            <td class="text-right font-weight-bold">{{ props.item.info }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
    <v-flex xs12 sm6 offset-sm3 class="system-info" v-if="profile.role != 'student'">
      <v-toolbar flat color="white">
        <v-toolbar-title><v-icon class="setting-icon">mdi-help-circle-outline</v-icon> Help </v-toolbar-title>
      </v-toolbar>
      <v-data-table :items="helpInformation" hide-actions hide-headers>
        <template slot="items" slot-scope="props">
          <tr v-if="props.item.url">
            <td>{{ props.item.name }}</td>
            <td class="text-right font-weight-bold">
              <a :href="props.item.url" target="_blank">Open link</a>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-flex>
  </v-container>
</template>
<script>
import Base64Upload from "vue-base64-upload"
import { mapState, mapActions } from "vuex"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_INFO,
  ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications"
import { STORE_USER_NAME, STATE_PROFILE, STATE_VERSION_INFO } from "@/store_constants/user"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
export default {
  props: ["value"],
  components: { Base64Upload },
  data() {
    return {
      helpInformation: [
        {
          name: "General",
          url: "https://teams.microsoft.com/l/team/19%3a228d3de958504e90af453acf3146845b%40thread.skype/conversations?groupId=91ca5d91-f591-4d05-832a-1082467a8ea5&tenantId=b2d34863-da26-4474-8881-23ace5d219b7"
        },
        {
          name: "Helpdesk",
          url: "https://teams.microsoft.com/l/channel/19%3ae2696224c6314311813e8e2938ba96fc%40thread.skype/Helpdesk?groupId=91ca5d91-f591-4d05-832a-1082467a8ea5&tenantId=b2d34863-da26-4474-8881-23ace5d219b7"
        },
        {
          name: "Feature Requests",
          url: "https://teams.microsoft.com/l/channel/19%3af6ba3206b274419eb1c26c0d99114ee0%40thread.skype/Feature%2520Requests?groupId=91ca5d91-f591-4d05-832a-1082467a8ea5&tenantId=b2d34863-da26-4474-8881-23ace5d219b7"
        }
      ]
    }
  },
  computed: {
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE,
      versionInfo: STATE_VERSION_INFO
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      info: ACTION_NOTIFICATIONS_INFO,
      error: ACTION_NOTIFICATIONS_ERROR
    })
  }
}
</script>

<style scoped>
.profile-title {
  margin: 10px;
}

.system-info {
  margin-top: 80px;
}

.setting-icon {
  color: black;
  cursor: inherit;
}
</style>
