<template>
  <div>
    <interactive-text-designer
      :settingStyles="interactiveTextController.contents.settings.styles"
      :leftPanelsTop="leftPanelsTop"
      :leftPanelsBottom="leftPanelsBottom">
    </interactive-text-designer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import InteractiveTextDesigner from "./components/InteractiveTextDesigner.vue"
import interactiveTextController from "./controllers/InteractiveTextController/index.js"
import OutlinePanel from "./components/OutlinePanel.vue"
import GlossaryPanel from "../commonInteractive/glossary/GlossaryPanel"
import glossaryController from "../commonInteractive/glossary/GlossaryController"
import { PtModal } from "plant-common"
import workflowTypes from "../../pages/workflow/constants/WorkflowTypes"
import {
  ACTION_GET_DEPENDENTS,
  ACTION_UPDATE_ITEM_REVISION_METADATA,
  ACTION_UPLOAD_DRAFT,
  STORE_CONTENT_NAME,
  STORE_MODULE_EDITOR
} from "../../store_constants/content"
import { STATE_PLANT_SETTINGS, STORE_LIBRARY_NAME } from "../../store_constants/library"
import {
  STORE_EXPLORER_NAME,
  ACTION_GET_ALL_LIBRARY_FOLDERS,
  STATE_ALL_LIBRARY_FOLDERS
} from "../../store_constants/explorer"
import { getPath } from "../../utils/dbTool"

export default {
  name: "InteractiveText",
  props: ["value", "view", "mode", "comments"],
  mixins: [PtModal],
  components: { InteractiveTextDesigner, GlossaryPanel },
  data() {
    return {
      interactiveTextController: interactiveTextController(this),
      workflows: workflowTypes
    }
  },
  async created() {
    await this.getAllLibraryFolders()
    this.interactiveTextController.setStyleSheet()
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_EXPLORER_NAME, {
      allLibraryFolders: STATE_ALL_LIBRARY_FOLDERS
    }),
    itemPath() {
      return getPath(this.value, this.allLibraryFolders)
    },
    isDraft() {
      return this.mode === "edit"
    },
    leftPanelsBottom() {
      return [
        {
          title: "Instructional Settings",
          icon: "mdi-school-outline",
          action: () => this.openModalWorkflow()
        }
      ]
    },
    leftPanelsTop() {
      const panels = [
        {
          title: "Outline",
          icon: "mdi-file-tree",
          component: OutlinePanel
        }
      ]
      const glossaryPanel = {
        title: "Glossary",
        icon: "mdi-book-alphabet",
        component: GlossaryPanel,
        props: {
          mode: this.mode,
          value: this.value,
          controller: glossaryController(this)
        }
      }
      if (this.mode === "edit") {
        panels.push(glossaryPanel)
      } else if (
        this.mode !== "edit" &&
        this.value.body.contents.glossary &&
        this.value.body.contents.glossary.length
      ) {
        panels.push(glossaryPanel)
      }

      return panels
    }
  },
  provide() {
    return {
      controller: this.interactiveTextController
    }
  },
  watch: {},
  methods: {
    ...mapActions(STORE_CONTENT_NAME, {
      getDependents: ACTION_GET_DEPENDENTS,
      updateItemRevisionMetadata: ACTION_UPDATE_ITEM_REVISION_METADATA
    }),
    ...mapActions(STORE_MODULE_EDITOR, {
      uploadItemDraft: ACTION_UPLOAD_DRAFT
    }),
    ...mapActions(STORE_EXPLORER_NAME, {
      getAllLibraryFolders: ACTION_GET_ALL_LIBRARY_FOLDERS
    }),
    async openModalWorkflow() {
      const propsData = {
        title: `Workflow log`,
        selectedTab: "tab-datasheet-objective",
        approval_process: this.value.approval_process,
        workflows: this.workflows,
        item: this.value,
        path: this.itemPath,
        header: this.value.header,
        settings: this.settings,
        isEdit: this.mode === "edit",
        isDraft: this.isDraft,
        dependents: this.isDraft ? [] : await this.getDependents()
      }

      this.$modal("workflow", propsData)
        .then(async metadata => {
          if (!metadata) return

          if (metadata.instructionalSettings) {
            this.value.body.contents.instructionalSettings = metadata.instructionalSettings
            delete metadata.instructionalSettings
          }

          await this.uploadItemDraft()

          await this.updateItemRevisionMetadata({
            itemRevision: this.value,
            metadata
          })
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style>
.it-index {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.it-index-panel {
  position: absolute;
  bottom: 0;
}

.it-index-main {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.it-page__add-page {
  flex: 1;
}

.it-page-wrapper {
  overflow: auto;
  padding: 20px 20px 40px;
}
</style>
