<template>
  <v-list-group
    class="dependencies-list"
    v-model="active"
    :prepend-icon="active ? 'mdi-chevron-up' : 'mdi-chevron-down'"
    append-icon=""
    no-action
    e2e-attr="refs-list-elem">
    <template v-slot:activator>
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title class="dependencies-list-title">
            <span v-if="!isStudent">
              <div v-if="allDependenciesLastRevision">
                <v-icon class="pr-2">mdi-link</v-icon>
                <span>{{ title }}</span>
              </div>
              <v-badge v-else color="warning">
                <template v-slot:badge>
                  <v-icon color="white">mdi-exclamation-thick</v-icon>
                </template>
                <v-icon class="pr-2">mdi-link</v-icon>
                <span>{{ title }}</span>
              </v-badge>
            </span>
            <div v-else>
              <v-icon class="pr-2">mdi-link</v-icon>
              <span>{{ title }}</span>
            </div>
            <v-menu offset-y v-if="isDraft">
              <template v-slot:activator="{ on }">
                <v-btn @click.stop="" flat v-on="on" class="dependencies-list-action">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-tile :disabled="!dependenciesList.length" @click="checkDependenciesUpdates()">
                  <v-list-tile-title class="body-1">
                    <v-layout row align-center>
                      <v-icon class="mr-2">mdi-update</v-icon>
                      <span>Check new updates</span>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile>
                <v-list-tile
                  :disabled="!dependenciesList.length || allDependenciesLastRevision"
                  @click="updateAllToLatestRevision()">
                  <v-list-tile-title class="body-1">
                    <v-layout row align-center>
                      <v-icon class="mr-2">mdi-page-layout-header-footer</v-icon>
                      <span>Update all revisions</span>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile>
              </v-list>
            </v-menu>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <div v-if="dependenciesList.length > 0">
      <v-alert v-if="!allDependenciesLastRevision && isDraft" :value="true" type="warning"> Updates found. </v-alert>
      <p-content-tile v-for="(dependency, index) of dependenciesList" :key="index" :value="dependency">
        <template v-slot:prepend>
          <v-list-tile-action class="dependencies-list-action" v-if="!isStudent">
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-show="!isLatestRevision(dependency)" color="warning" v-on="on">mdi-alert</v-icon>
              </template>
              <span>There is a newer revision of this content.</span>
            </v-tooltip>
          </v-list-tile-action>
        </template>
        <template v-slot:append>
          <v-list-tile-action class="dependencies-list-action">
            <v-btn icon ripple @click="openDependency(dependency)">
              <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
            </v-btn>
          </v-list-tile-action>

          <v-list-tile-action class="dependencies-list-action" v-if="isDraft">
            <v-btn icon ripple @click="updateToLatestRevision(dependency)" v-show="!isLatestRevision(dependency)">
              <v-icon color="grey lighten-1">mdi-refresh</v-icon>
            </v-btn>
          </v-list-tile-action>
        </template>
      </p-content-tile>
    </div>
    <p v-else class="no-related-content">No related content</p>
  </v-list-group>
</template>

<script>
import { PContentTile } from "plant-common"
import { mapState, mapActions, mapGetters } from "vuex"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_DEPENDENCIES,
  STATE_WEAK_DEPENDENCIES,
  STATE_CAN_EDIT,
  ACTION_SET_CONTENT_REVISION
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME } from "@/store_constants/library"
import { getCustomId } from "@/utils/dbTool"
import ptApiClient from "plant-api-client"
import { cloneDeep } from "@/components/lessonPlan/utils/utils.js"
import { PtModal } from "plant-common"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { STORE_USER_NAME, GETTER_IS_STUDENT } from "@/store_constants/user"
import Helpers from "./helpers.vue"

export default {
  components: { PContentTile },
  mixins: [PtModal, Helpers],
  inject: ["DependencyBus"],
  props: {
    title: {
      type: String,
      default: "Dependencies"
    },
    weak: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      isDraft: false
    }
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      dependencies: STATE_DEPENDENCIES,
      weakDependencies: STATE_WEAK_DEPENDENCIES,
      canEdit: STATE_CAN_EDIT
    }),
    ...mapState(STORE_LIBRARY_NAME, ["settings"]),
    ...mapGetters(STORE_USER_NAME, {
      isStudent: GETTER_IS_STUDENT
    }),
    contents() {
      if (this.itemRevision && this.itemRevision.body.contents) {
        return this.itemRevision.body.contents
      } else {
        return {}
      }
    },
    allDependenciesLastRevision() {
      for (const dependency of this.dependenciesList) {
        if (!this.isLatestRevision(dependency)) {
          return false
        }
      }
      return true
    },
    dependenciesList() {
      return this.weak ? this.weakDependencies : this.dependencies
    }
  },
  mounted() {
    let path = this.$router.app.$route.fullPath.split("/")
    if (path[path.length - 1] === "draft") this.isDraft = true
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_SET_CONTENT_REVISION]),
    async openDependency(dependency) {
      PServiceContent.openContent(dependency, "tab")
    },
    async checkDependenciesUpdates() {
      for (let dependency of this.dependenciesList) {
        const customId = getCustomId(dependency)
        try {
          let last_content = await ptApiClient.getContentByCustomId(customId)
          dependency.__latest = `${last_content.header.major_revision}.${last_content.header.minor_revision}`
        } catch (e) {
          console.log(e)
        }
      }
    },
    async updateToLatestRevision(dependency) {
      const confirm = await this.confirmDialog(
        "This action will update this reference in the current content to its latest reference available. Do you want to continue?"
      )
      if (!confirm) return
      const contents = cloneDeep(this.contents)
      const selectedLibraryURL = this.settings.url
      const dependenciesUpdated = await this.updateContentToLatestRevision(contents, [dependency], selectedLibraryURL)
      await this[ACTION_SET_CONTENT_REVISION](contents)
      this.DependencyBus.$emit("updated", dependenciesUpdated)
    },
    async updateAllToLatestRevision() {
      const confirm = await this.confirmDialog(
        "This action will update ALL references in the current content to its latest reference available. Do you want to continue?"
      )
      if (!confirm) return
      const contents = cloneDeep(this.contents)
      const selectedLibraryURL = this.settings.url
      const dependenciesUpdated = await this.updateContentToLatestRevision(
        contents,
        this.dependenciesList,
        selectedLibraryURL
      )
      await this[ACTION_SET_CONTENT_REVISION](contents)
      this.DependencyBus.$emit("updated", dependenciesUpdated)
    },
    async confirmDialog(text) {
      return await this.$modal("confirm", {
        title: "Confirm Update",
        message: text,
        accept: { text: "Yes" },
        cancel: { text: "No" }
      })
    }
  }
}
</script>

<style>
.dependencies-list .v-list__group__items--no-action .v-list__tile {
  padding-left: 20px;
}
.dependencies-list .v-list__tile__action.dependencies-list-action {
  min-width: 30px;
}
.dependencies-list-title {
  display: flex;
  align-items: center;
}
.dependencies-list-action.v-btn {
  min-width: 20px;
  padding: 0;
  margin-right: 0;
  margin-left: auto;
}
.dependencies-list-action .v-icon {
  font-size: 18px;
}
.dependencies-list .no-related-content {
  padding: 10px 72px;
  font-style: italic;
}

.dependencies-list .v-list__group__items--no-action {
  overflow: scroll;
  max-height: 65vh;
}
.v-list__tile__title {
  overflow: visible !important;
}
</style>
