import css from './pt-relay-contact.css';
import CLICK_CONTEXTMENU from '../../mixins/CLICK_CONTEXTMENU';
import MOUSE_ENTER_LEAVE from '../../mixins/MOUSE_ENTER_LEAVE';

export default {
	mixins: [CLICK_CONTEXTMENU, MOUSE_ENTER_LEAVE],
	model: {
		status: {
			type: 'list',
			format: ['OPEN', 'CLOSED'],
			default: 'CLOSED',
			bind: {
				to: 'attr',
				name: 'data-status'
			}
		},
		value: {
			private: true,
			type: 'boolean',
			default: false,
			bind: {
				to: 'attr',
				name: 'data-value'
			}
		},
		input: {
			type: 'boolean',
			default: false,
			bind: {
				to: 'attr',
				name: 'data-input'
			}
		}
	},
	css
};
