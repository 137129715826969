export const STORE_USER_NAME = "user"

export const STATE_TOKEN = "token"
export const STATE_PLANT_REFRESH_TOKEN = "plant_refresh_token"
export const STATE_VERSION_INFO = "versionInfo"
export const STATE_PROFILE = "profile"
export const STATE_PENDING = "pending"

export const GETTER_IS_ADMIN = "isAdmin"
export const GETTER_IS_SUPERVISOR = "isSupervisor"
export const GETTER_IS_INSTRUCTOR = "isInstructor"
export const GETTER_IS_STUDENT = "isStudent"

export const MUTATION_SET_REFRESH_AUTH = "setRefreshAuth"
export const MUTATION_SET_FLEET_LOGIN = "setFleetLogin"
export const MUTATION_SET_LIBRARY = "setLibrary"
export const MUTATION_DESTROY_SESSION = "destroySession"
export const MUTATION_LOGIN_PENDING = "loginPending"
export const MUTATION_LOGIN_ERROR = "loginError"

export const ACTION_API_LOGIN = "apiLogin"
export const ACTION_SELECT_LIBRARY = "selectLibrary"
export const ACTION_LOGIN_FLEET_ERROR = "loginFleetError"
export const ACTION_LOGOUT = "logout"
export const ACTION_REFRESH_AUTH = "refreshAuth"