<template>
  <div class="lp-tree-comments" v-if="commentsSlide && commentsSlide.length > 0">
    <v-badge overlap :color="setBadgeColor(commentsSlide)">
      <template v-slot:badge>
        <span>{{ numberOfCommentsInState }}</span>
      </template>
      <v-icon color="grey lighten-1" medium>message</v-icon>
    </v-badge>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { STORE_MODULE_COMMENTS, GETTER_COMMENTS } from "../../../../../store_constants/content"

export default {
  inject: ["lessonPlanController"],
  props: ["item"],
  data: () => ({
    commentsSlide: [],
    numberOfCommentsInState: 0
  }),
  computed: {
    ...mapGetters(STORE_MODULE_COMMENTS, {
      comments: GETTER_COMMENTS
    })
  },
  watch: {
    comments: {
      immediate: true,
      deep: true,
      handler() {
        this.updateCommentsSlide()
      }
    },
    item: {
      handler() {
        this.updateCommentsSlide()
      }
    }
  },
  created() {
    this.updateCommentsSlide()
  },
  methods: {
    updateCommentsSlide() {
      this.commentsSlide = this.lessonPlanController.getCommentsForSlide(this.comments, this.item)
    },
    setBadgeColor(comments) {
      const open = this.getCommentsNumbersByState(comments, "open")
      const resolved = this.getCommentsNumbersByState(comments, "resolved")

      if (open > 0) {
        this.numberOfCommentsInState = open
        return "error"
      } else if (resolved > 0) {
        this.numberOfCommentsInState = resolved
        return "warning"
      } else {
        this.numberOfCommentsInState = comments.length
        return "success"
      }
    },
    getCommentsNumbersByState(comments, state) {
      return comments.filter(comment => comment.state === state && !comment.deleted_at).length
    }
  }
}
</script>

<style>
.lp-tree-comments {
  padding: 8px;
  margin-top: 10px;
}
.lp-tree-comments .v-badge .v-icon {
  font-size: 24px !important;
}
.lp-tree-comments .v-badge__badge {
  font-size: 12px;
  width: 18px;
  height: 18px;
}
</style>
