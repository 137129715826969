import Vue from "vue"
import Vuex from "vuex"
import notifications from "./notifications"
import eventNotifications from "./event-notifications"
import user from "./user"
import library from "./library"
import fleet from "./fleet"
import storage from "./storage"
Vue.use(Vuex)
import ptCoreClient from "plant-core-client"
import ptApiClient from "plant-api-client"

const store = new Vuex.Store({
  plugins: [
    storage({
      storedKeys: ["user", "library", "item"],
      destroyOn: ["destroySession", "destroyItem"],
      watchers: [
        {
          getter: state => state.user.token,
          cb: token => {
            ptCoreClient.token = token
            ptApiClient.token = token
          }
        },
        {
          getter: state => state.library.settings.url,
          cb: url => (ptApiClient.baseURL = url)
        }
      ]
    })
  ],
  modules: {
    eventNotifications,
    notifications,
    user,
    library,
    fleet
  },
  state: {
    loading: false,
    loadingMessage: "Loading",
    buffer: 0,
    loadingTriggered: false,
    mapAxiosResolved: {}
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading
    },
    setLoadingMessage(state, message) {
      state.loadingMessage = message
    },
    setBuffer(state, value) {
      state.buffer = value
    },
    setTrigger(state, value) {
      state.loadingTriggered = value
    },
    updateAxiosResolved(state, { url, flag }) {
      state.mapAxiosResolved[url] = flag
    }
  },
  actions: {
    activateLoading({ commit }, data) {
      if (data && data.loadingMessage) commit("setLoadingMessage", data.loadingMessage)
      commit("setLoading", true)
    },
    finishLoading({ commit }) {
      commit("setLoadingMessage", "Loading")
      commit("setBuffer", 0)
      commit("setLoading", false)
      commit("setTrigger", false)
    },
    loadBuffer({ commit }, buffer) {
      commit("setBuffer", buffer)
      if (buffer === 100) store.dispatch("finishLoading")
    },
    triggerLoading({ commit }) {
      commit("setTrigger", true)
    },
    setAxiosResolved({ commit }, { url, flag }) {
      commit("updateAxiosResolved", { url, flag })
    }
  }
})

ptApiClient.interceptors.request.use(function (config) {
  store.dispatch("setAxiosResolved", { url: config.url, flag: false })
  if (config.method == "post") {
    setTimeout(() => {
      if (!store.state.mapAxiosResolved[config.url]) {
        store.dispatch("activateLoading", { loadingMessage: "Processing request" })
      }
    }, 2000)
  }
  config.onUploadProgress = progressEvent => {
    const { loaded, total } = progressEvent
    // Show the upload progress bar if the request has a length that can be calculated
    // and it is larger than 25000000 bytes (25MB)
    // https://developer.mozilla.org/en-US/docs/Web/API/ProgressEvent/lengthComputable
    if (progressEvent.lengthComputable && progressEvent.total > 25000000) {
      store.dispatch("triggerLoading")
      store.dispatch("activateLoading", { loadingMessage: "Uploading" })
    }
    store.dispatch("loadBuffer", Math.round((loaded * 100) / total))
  }
  return config
})

ptApiClient.interceptors.response.use(
  function (response) {
    store.dispatch("setAxiosResolved", { url: response.config.url, flag: true })
    store.dispatch("finishLoading")
    return response
  },
  async function (error) {
    store.dispatch("setAxiosResolved", { url: error.config.url, flag: true })
    store.dispatch("finishLoading")
    if (error.response && error.response.status == 401) {
      if (store.state.user && store.state.user.plant_refresh_token) {
        const originalRequest = error.config
        // Avoid loop
        if (originalRequest.url === "/auth/refreshToken") {
          store.dispatch("notifications/error", {
            message: "Unauthorized. You do not have access to this content. Try to login again."
          })
          store.dispatch("library/leavePlant")
        }
        if (!originalRequest._retry) {
          originalRequest._retry = true
          ptApiClient
            .refreshToken({ token: store.state.user.plant_refresh_token })
            .then(refreshTokenResponse => {
              if (refreshTokenResponse) {
                store.dispatch("user/refreshAuth", refreshTokenResponse)
                return ptApiClient.axiosInstance(originalRequest)
              }
            })
            .catch(e => {
              store.dispatch("notifications/error", {
                message: "Unauthorized. You do not have access to this content. Try to login again."
              })
            })
        }
        // return Error object with Promise
        return Promise.reject(error)
      } else {
        store.dispatch("notifications/error", {
          message: "Unauthorized. You do not have access to this content. Try to login again."
        })
        store.dispatch("library/leavePlant")
        return Promise.reject(error)
      }
    } else if (error.response && error.response.status == 500) {
      store.dispatch("notifications/error", { message: "Oops! PLANT we found a creature 🐛 in our servers! " })
      return Promise.reject(error)
    } else {
      // let errorMessage = 'PLANT Server Error';
      // if (error.response && error.response.data && error.response.data.message) {
      //   errorMessage = error.response.data.message;
      // }
      // store.dispatch("notifications/error", { message: errorMessage });
      return Promise.reject(error)
    }
  }
)

ptCoreClient.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response && error.response.status == 401) {
      store.dispatch("notifications/error", {
        message: "Oops! Your fleet session has expired. You have to login again."
      })
      store.dispatch("user/logout")
    } else if (error.response && error.response.status == 500) {
      store.dispatch("notifications/error", { message: "Oops! PLANT we found a creature 🐛 in our servers! " })
    } else {
      // let errorMessage = 'FLEET Server Error';
      // if (error.response && error.response.data && error.response.data.message) {
      //   errorMessage = error.response.data.message;
      // }
      // store.dispatch("notifications/error", { message: errorMessage });
    }
    return Promise.reject(error)
  }
)

export default store
