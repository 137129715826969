<template>
  <div
    style="
      background: #efefef;
      border: dashed;
      margin: 20px;
      width: calc(100% - 40px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
    @drop.prevent="filesDropped"
    @dragover.prevent
  >
    <div class="display-2" style="text-align: center">
      <v-container grid-list-xl text-xs-center>
        <v-layout row wrap>
          <v-flex xs12>
            <p>Drop XLSX file here</p>
            <p style="font-size: 32px">
              or
              <a @click="selectFiles">select from your computer</a>
            </p>
          </v-flex>
          <v-flex xs6 offset-xs3>
            <custom-id-download-template />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import XLSX from "xlsx";
import { mapActions } from "vuex";
import { fileToArrayBuffer } from "@/utils/bulkUpload";
import { selectFileFromComputer } from "@/utils/bulkUpload";

import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications";

import CustomIdDownloadTemplate from "./DownloadTemplate.vue";

export default {
  name: "BulkChangeCustomIdXls",
  components: {
    CustomIdDownloadTemplate
  },
  data() {
    return {
      update_contents: []
    };
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    async selectFiles() {
      var [file] = await selectFileFromComputer(
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        false
      );
      this.uploadXlsxTemplate(file);
    },
    filesDropped(evt) {
      if (evt.dataTransfer.files.length != 1) {
        alert("You can only drop one file");
        return;
      }
      var file = evt.dataTransfer.files[0];
      if (
        file.type !=
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        alert("You can only drop a XLSX file.");
        return;
      }
      this.uploadXlsxTemplate(file);
    },
    async uploadXlsxTemplate(file) {
      var file_buffer = await fileToArrayBuffer(file);
      var wb = XLSX.read(file_buffer, { type: "array" });
      var import_data = XLSX.utils.sheet_to_json(
        wb.Sheets["CHANGE-CUSTOM-ID-DATA"]
      );
      var import_config = XLSX.utils.sheet_to_json(
        wb.Sheets["IMPORT-CONFIG"]
      )[0];
      if (import_data.length == 0) {
        this.showAlert(`Nothing to import.`);
        return;
      }
      if (!(import_config && import_config.type)) {
        this.showAlert(
          `Malformed File: IMPORT-CONFIG not defined correctly. Please, download specific template and try again.`
        );
        return;
      }
      for (var content_to_update of import_data) {
        var { old_custom_id, new_custom_id } = content_to_update;
        this.update_contents.push({
          old_custom_id: old_custom_id + "",
          new_custom_id: new_custom_id + "",
          status: "not-started" // 'not-started', 'in-progress', 'done', 'error'
        });
      }
      console.log(this.update_contents);
      this.$emit("upload-finished", this.update_contents);
    },

    showAlert(message) {
      this.error({ message, timeout: -1 });
    }
  }
};
</script>

<style>
.type-name {
  font-size: 32px;
}
</style>