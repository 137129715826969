<template>
	<v-list-tile>
		<v-list-tile-avatar>
			<v-icon>{{icon}}</v-icon>
		</v-list-tile-avatar>
		<v-list-tile-content>
			<v-list-tile-title>{{title}}</v-list-tile-title>
		</v-list-tile-content>
		<v-list-tile-action>
			<slot />
		</v-list-tile-action>
	</v-list-tile>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		},
		icon: {
			type: String
		}
	}
}
</script>