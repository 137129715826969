export function getYoutubeID(url) {
	if (/^[a-zA-Z0-9_-]{11}$/.test(url)) {
		return url;
	}

	const patterns = [
		/youtu\.be\/([a-zA-Z0-9_-]{11})/, // https://youtu.be/VIDEO_ID
		/youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})/, // https://www.youtube.com/watch?v=VIDEO_ID
		/youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/, // https://www.youtube.com/embed/VIDEO_ID
		/youtube\.com\/v\/([a-zA-Z0-9_-]{11})/, // https://www.youtube.com/v/VIDEO_ID
		/youtube\.com\/shorts\/([a-zA-Z0-9_-]{11})/, // https://www.youtube.com/shorts/VIDEO_ID
		/youtube\.com\/playlist\?list=([a-zA-Z0-9_-]{34})/ // Playlist
	];

	for (const pattern of patterns) {
		const match = url.match(pattern);
		if (match) {
			return match[1];
		}
	}

	return null;
}
