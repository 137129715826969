<template>
  <p-content-container>
    <ViewerToolbar 
      v-if="!viewOnly"
      @addTemplate="addTemplate"
      @renameTemplate="renameTemplate"
      @deleteTemplate="deleteTemplate"
      @replaceTemplateSvg="replaceTemplateSvg"
      @replaceTemplateVisio="replaceTemplateVisio"
    />
    <TemplateDrawer 
      v-model="value"
      :selectedTemplate="selectedTemplate"
      @showTemplate="showTemplate"
      :viewOnly="viewOnly"
    />
    <ModalRename
      :dialog="dialog"
      @setDialog="setDialog"
      @setName="setName"
    />

    <v-snackbar
      v-model="nameRepeat"
      top
      color="red"
    >
      Name for template already exists
      <v-btn
        flat
        @click="nameRepeat = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </p-content-container>
</template>

<script>
import { PContentContainer } from "plant-common";
import { uploadFile } from "plant-common/src/utils";
const svg = require("./placeholder.svg");
class GuideTemplateCard {
  constructor(name, svg) {
    this.id =  Math.floor(Math.random() * 10000000)
    this.name = name;
    this.svg = svg;
  }
}

class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}

import ViewerToolbar from "./ViewerToolbar"
import TemplateDrawer from "./TemplateDrawer"
import ModalRename from "./ModalRename"

export default {
  name: "GuideTemplateEditor",
  props: ["value", "viewOnly"],
  components: {
    PContentContainer,
    ViewerToolbar,
    TemplateDrawer,
    ModalRename
  },
  data() {
    return {
      selectedTemplate:null,
      dialog:false,
      name:'',
      nameRepeat:false
    };
  },
  created() {
    if (!this.value.body.contents.card_templates) {
      this.$set(this.value.body.contents, "card_templates", []);
      this.card_templates.push(new GuideTemplateCard("cover", svg));
      this.card_templates.push(new GuideTemplateCard("section", svg));
    }
    if(!this.value.body.contents.card_templates.find(e=>e.name==='cover'))
      this.card_templates.push(new GuideTemplateCard("cover", svg));
    if(!this.value.body.contents.card_templates.find(e=>e.name==='section'))
      this.card_templates.push(new GuideTemplateCard("section", svg));
  },
  computed: {
    card_templates() {
      if (!this.value.body.contents.card_templates) return false;
      return this.value.body.contents.card_templates;
    }
  },
  methods: {
    setDialog(value){
      this.dialog = value 
    },
    setName(name){
      if(name){
        this.card_templates.forEach(element => {
          if(element.name.toUpperCase()===name.toUpperCase()) {
            this.nameRepeat = true
          }
        });

        if(!this.nameRepeat){

          this.value.body.attachments.forEach( (e, index) => {
            if(e.name==this.selectedTemplate.name){
              e.name=name
            }
          })

          this.card_templates.forEach(e=> {
            if(e.name===this.selectedTemplate.name){
              e.name=name
            }
          })

        }
      }
      this.dialog = false
    },
    addTemplate(){
      let name="template"
      let i = 1
      while(this.card_templates.find(e=>e.name===name)){
        name=`tempalte_${i}`;
        i++;
      }      
      this.card_templates.push(new GuideTemplateCard(name, svg));

      this.selectedTemplate = null
    },
    renameTemplate(){
      if(this.selectedTemplate && this.selectedTemplate.name!=='cover' && this.selectedTemplate.name!=='section' ){
        this.dialog = true;
        this.name = this.selectedTemplate.name
      }
    },
    deleteTemplate(){
      
      if(this.selectedTemplate && this.selectedTemplate.name!=='cover' && this.selectedTemplate.name!=='section' ){
        
        this.card_templates.forEach((e,index) => {
          if(e.name==this.selectedTemplate.name){
            this.card_templates.splice(index,1)
          }
        })
  
        this.value.body.attachments.forEach( (e, index) => {
         if(e.name==this.selectedTemplate.name){
           this.value.body.attachments.splice(index,1)
         }
        })
        
        this.selectedTemplate = null
      }

    },
    showTemplate(template){
      this.selectedTemplate=template
    },
    replaceTemplateVisio(){
      console.log("upload visio")
    },
    replaceTemplateSvg(type) {
      if(this.selectedTemplate){
        uploadFile(type)
          .then(({ url, file }) => {
            const read = new FileReader();
            read.readAsText(file);
            read.onloadend = () => {
              let regex = /\{\{([\S*\d+\S*]*)\}\}/gm;
              let match
              let fields = {};
              while ((match = regex.exec(read.result)) !== null) {
                  fields[match[1]] = '';
              }
              this.selectedTemplate.fields = fields
            }
            this.value.body.attachments = this.value.body.attachments.filter( e => {
              if((e.name!=this.selectedTemplate.name)) {
                return true
              }
              else{
                return e.attachment_type != type
              }
            })
            this.value.body.attachments.push(new Attachment(this.selectedTemplate.name, file, url, type))
        });
      }
       
    }   
  }
};
</script>
<style scoped>
.svg_wrapper{
 height: calc(100% - 120px);
 overflow: hidden;
}

.selected{
  border-bottom: 2px solid rgb(25, 118, 210);
}
</style>
