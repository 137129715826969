import DatasheetReplaceProperty from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "datasheet-replace-property",
  title: "Replace Datasheet Properties",
  description:
    'This operation replaces the "find" value (Case sensitive) with the "replace" value in all the datasheet properties',
  contentType: "datasheet",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.body && content.body.contents && content.body.contents.datasheet) {
        for (const property in content.body.contents.datasheet) {
          content.body.contents.datasheet[property] = content.body.contents.datasheet[property]
            .toString()
            .replace(searchRegExp, replaceWith)
        }
      }
    }
    return content
  },
  OperationOptionsComponent: DatasheetReplaceProperty
}
