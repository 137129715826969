<template>
  <div class="lp-video-recorder">
    <video :id="id" class="video-js vjs-default-skin" playsinline></video>
    <v-snackbar v-model="snackbar" :timeout="6000" :top="true">
      {{ snackText }}
      <v-btn color="red" flat @click="snackbar = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import videojs from "video.js"
import "video.js/dist/video-js.css"

import RecordRTC from "recordrtc"
import "videojs-record/dist/videojs.record.js"
import "videojs-record/dist/css/videojs.record.css"
import "webrtc-adapter"

export default {
  props: ["width", "height", "screen"],
  data() {
    return {
      id: "",
      player: "",
      options: {
        controls: true,
        autoplay: false,
        fluid: false,
        loop: false,
        width: this.width,
        height: this.height,
        bigPlayButton: false,
        controlBar: {
          volumePanel: false
        },
        plugins: {
          // configure videojs-record plugin
          record: {
            audio: true,
            video: !this.screen,
            screen: this.screen,
            maxLength: 300,
            debug: process.env.NODE_ENV == "development"
          }
        }
      },
      snackbar: false,
      snackText: ""
    }
  },
  created() {
    // Create a dynamic id to avoid two component instance to share the same id
    this.id = `myVideo-${Math.floor(Math.random() * 100000)}`
  },
  mounted() {
    /* eslint-disable no-console */
    this.player = videojs(`#${this.id}`, this.options, () => {
      // print version information at startup
      let msg =
        "Using video.js " +
        videojs.VERSION +
        " with videojs-record " +
        videojs.getPluginVersion("record") +
        " and recordrtc " +
        RecordRTC.version

      videojs.log(msg)
    })
    // device is ready
    this.player.on("deviceReady", () => {
      this.$emit("deviceReady")
    })
    // user clicked the record button and started recording
    this.player.on("startRecord", () => {
      this.$emit("startRecord")
    })
    // user completed recording and stream is available
    this.player.on("finishRecord", () => {
      // the blob object contains the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.$emit("finishRecord", this.player.recordedData)
    })
    // error handling
    this.player.on("error", (element, error) => {
      this.$emit("error", error)
      console.warn(error)
      this.snackText = error
      this.snackbar = true
    })
    this.player.on("deviceError", () => {
      this.$emit("deviceError", this.player.deviceErrorCode)
      console.error("device error:", this.player.deviceErrorCode)
      this.snackText = this.player.deviceErrorCode
      this.snackbar = true
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>

<style>
.lp-video-recorder {
  width: 100%;
  height: 100%;
}
.lp-video-recorder .video-js {
  margin: auto;
}
</style>
