import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "glbModel",
    title: "3D Model (GLB)",
    icon: require("./content-icon.svg"),
    category: "engineering",
    Viewer,
    Editor,
    Preview: Viewer
}