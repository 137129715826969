<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <portal :to="`flipcard-toolbar-${value.id}`" v-if="mode == 'edit'">
        <template v-if="!changeContentProtection">
          <div class="lp-flock-flip-card-toolbar" v-if="state === 'edit-content'">
            <block-toolbar-button
              :icon="iconFront"
              tooltip="Show front"
              :dark="blockController.dark"
              @click="changeSide('front')" />
            <block-toolbar-button
              :icon="iconBack"
              tooltip="Show back"
              :dark="blockController.dark"
              @click="changeSide('back')" />
            <toolbar-textarea
              :editor="side == 'front' ? editorFront : editorBack"
              :data="value.data[side]"
              :side="side"
              :id="value.id"
              :dark="blockController.dark"
              :blockVariables="blockVariables" />
          </div>
        </template>
        <div v-else style="color: #7d7d7d">
          <v-icon small class="ml-2 mr-3">mdi-lock-outline</v-icon>
          Change content protection enabled
        </div>
      </portal>
      <flip-card-view
        class="lp-block-flip-card"
        :mode="mode"
        :value="value"
        :side="side"
        :editor-front="editorFront"
        :editor-back="editorBack"
        :text-edit="textEdit"
        @setTextEdit="textEdit = $event"
        :blockVariables="blockVariables"
        :editable="editable" />
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ToolbarTextarea from "../textarea/ToolbarTextarea.vue"
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import FlipCardView from "./FlipCardView.vue"
import textarea from "../textarea/mixins/textarea.vue"
import { changeFontGlobal } from "../textarea/utils.js"

export default {
  name: "BlockFlipCard",
  inject: ["blockController", "blockGlobals"],
  mixins: [textarea],
  props: ["value", "mode", "blockVariables"],
  components: {
    BasicBlock,
    ToolbarTextarea,
    BlockToolbarButton,
    FlipCardView
  },
  data() {
    return {
      side: "front",
      editorFront: null,
      editorBack: null,
      state: "not-selected",
      editable: false
    }
  },
  computed: {
    iconFront() {
      return this.getIcon(this.side === "front", "f")
    },
    iconBack() {
      return this.getIcon(this.side === "back", "b")
    },
    changeContentProtection() {
      return this.value.protection.data
    }
  },
  watch: {
    "value.protection.data": {
      deep: true,
      immediate: true,
      handler() {
        this.updateEditorOptions()
      }
    },
    textEdit() {
      this.updateEditorOptions()
    },
    state() {
      if (this.state === "edit-content") {
        this.initEditor()
        this.editable = true
      } else {
        this.editable = false
      }
    }
  },
  mounted() {
    this.initEditor()
  },
  beforeDestroy() {
    this.editorFront.destroy()
    this.editorBack.destroy()
  },
  methods: {
    changeSide(side) {
      this.side = side
    },
    getIcon(active, letter) {
      return active ? `mdi-alpha-${letter}-box` : `mdi-alpha-${letter}-box-outline`
    },
    initEditor() {
      this.initEditorFront()
      this.initEditorBack()
    },
    initEditorFront() {
      if (this.editorFront) {
        this.editorFront.destroy()
      }
      this.editorFront = this.setEditor(this.value.data.front, this.mode !== "edit")
      changeFontGlobal(this.value.id, this.value.data.front, "front")
    },
    initEditorBack() {
      if (this.editorBack) {
        this.editorBack.destroy()
      }
      this.editorBack = this.setEditor(this.value.data.back, this.mode !== "edit")
      changeFontGlobal(this.value.id, this.value.data.back, "back")
    },
    updateEditorOptions() {
      if (this.editorFront && this.editorBack) {
        this.editorFront.setOptions({
          editable: this.isEditable && this.textEdit
        })
        this.editorBack.setOptions({
          editable: this.isEditable && this.textEdit
        })
      }
    }
  }
}
</script>

<style>
.lp-block-flip-card {
  font-size: 80px;
}
.lp-block-flip-card ol,
.lp-block-flip-card ul {
  padding-left: 80px;
  display: inline-block;
}
.lp-flock-flip-card-toolbar {
  display: flex;
  align-items: center;
}
</style>