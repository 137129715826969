<template>
    <div class="lp-notes-panel" style="height: 100%">
        <v-toolbar flat class="elevation-0 transparent">
            <v-toolbar-title class="subheading">Slide notes</v-toolbar-title>
        </v-toolbar>

        <v-tabs v-model="notesIndex" centered grow>
            <v-tab href="#tab-speaker"> 
                <v-icon left>mdi-human-male-board</v-icon> 
                Speaker
            </v-tab>
            <v-tab href="#tab-audience"> 
                <v-icon left>mdi-account-group</v-icon> 
                Audience
            </v-tab>
            <v-tab href="#tab-video"> 
                <v-icon left>mdi-video</v-icon> 
                Video
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="notesIndex">
            <v-tab-item value="tab-speaker">
                <SpeakerNotesPanel />
            </v-tab-item>
            <v-tab-item value="tab-audience">
                <AudienceNotesPanel />
            </v-tab-item>
            <v-tab-item value="tab-video">
                <VideoPanel />
            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import VideoPanel from "../../../blocks/video/VideoPanel.vue"
import SpeakerNotesPanel from "./SpeakerNotesPanel.vue"
import AudienceNotesPanel from "./AudienceNotesPanel.vue"

export default {
    components: {
        VideoPanel,
        SpeakerNotesPanel,
        AudienceNotesPanel
    },
    data() {
        return {
            notesIndex: "tab-speaker"
        }
    }
}
</script>