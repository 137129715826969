<template>
  <span>
    <p-dialog
      v-model="isDialogOpen"
      v-if="revisions.length > 0"
      title="Revision History"
      min-height="auto"
      icon="mdi-archive-clock-outline">
      <template v-slot:activator>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              small
              outline
              v-on="{ ...tooltip }"
              :color="isLatest ? 'success' : 'warning'"
              @click="!isActivityPreview ? (isDialogOpen = true) : ''">
              <v-icon small left>{{ isLatest ? "mdi-check" : "mdi-alert-circle-outline" }}</v-icon>
              Rev. {{ selected.major_revision }}.{{ selected.minor_revision }}
              {{ isLatest ? "(latest)" : "" }}
            </v-btn>
          </template>
          <span>{{
            isLatest ? "This is the latest Revision of the content" : "This is NOT the latest revision of the content"
          }}</span>
        </v-tooltip>
      </template>

      <v-data-table :headers="headers" :items="revisions" item-key="time" class="elevation-0">
        <template v-slot:items="props">
          <td>{{ props.item.major_revision }}.{{ props.item.minor_revision }}</td>
          <td>
            {{ props.item.time | formatDate }}
            <span class="caption text-grey font-italic text-lighten-3">({{ props.item.time | fromNow }})</span>
          </td>
          <td>
            <v-tooltip right v-if="props.item.summary_changes">
              <template v-slot:activator="{ on }">
                <span class="pointer" v-on="on"
                  >Changes from {{ props.item.major_revision }}.{{ props.item.minor_revision }}</span
                >
              </template>
              <span v-html="props.item.summary_changes"></span>
            </v-tooltip>

            <span v-else class="grey--text"><i>There is no summary</i></span>
          </td>
          <td class="text-xs-right">
            <v-btn
              flat
              outline
              color="primary"
              :to="{
                name: 'Content Viewer Exact Revision',
                params: {
                  library_id: settings._id,
                  id: getContentId(itemRevision),
                  major_revision: props.item.major_revision,
                  minor_revision: props.item.minor_revision
                }
              }"
              small>
              Select</v-btn
            >
          </td>
        </template>
      </v-data-table>
      <template v-slot:actions><span></span></template>
    </p-dialog>

    <v-btn v-else small dark class="elevation-0" color="gray">
      <v-icon small left>mdi-pencil</v-icon>
      {{
        itemRevision &&
        itemRevision.approval_process &&
        itemRevision.approval_process.workflow &&
        itemRevision.approval_process.workflow.state
          ? itemRevision.approval_process.workflow.state
          : "DRAFT"
      }}
    </v-btn>
  </span>
</template>

<script>
import { mapState } from "vuex"
import { PDialog } from "plant-common"
import { getContentId } from "../../../utils/dbTool"
import moment from "moment"
import {
  STORE_CONTENT_NAME,
  STORE_MODULE_REVISIONS,
  STATE_REVISIONS,
  STATE_ITEM_REVISION
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
export default {
  components: { PDialog },
  data() {
    return {
      isDialogOpen: false,
      headers: [
        { text: "Rev.", value: "revision", sortable: false, width: "40px" },
        {
          text: "Date",
          // width: "250px",
          sortable: false
        },
        { text: "Summary Of Changes", value: "summaryOfChanges", sortable: false },
        { text: "", sortable: false }
      ],
      isActivityPreview: false
    }
  },
  mounted() {
    let path = this.$router.app.$route.fullPath.split("/")
    const urlPathMode = path[path.length - 1].split("#")[0]
    if (urlPathMode === "preview-activity") this.isActivityPreview = true
  },
  filters: {
    formatDate(raw_date) {
      return moment(raw_date).format("lll")
    },
    fromNow(raw_date) {
      return moment(raw_date).fromNow()
    }
  },
  methods: {
    getContentId
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_MODULE_REVISIONS, { revisions: STATE_REVISIONS }),
    ...mapState(STORE_CONTENT_NAME, { itemRevision: STATE_ITEM_REVISION }),
    isLatest() {
      return (
        this.revisions.findIndex(
          e =>
            e.major_revision == this.itemRevision.header.major_revision &&
            e.minor_revision == this.itemRevision.header.minor_revision
        ) == 0
      )
    },
    selected() {
      return this.revisions.find(
        e =>
          e.major_revision == this.itemRevision.header.major_revision &&
          e.minor_revision == this.itemRevision.header.minor_revision
      )
    }
  }
}
</script>
<style scoped>
>>> .v-table__overflow {
  height: 400px;
  overflow: auto;
}
</style>
