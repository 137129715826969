import Diagram from "../lib/rhojs";
import components from "../lib/masters/src/index";
import beforeInit from "../diagram/ptUpdate";
import Vue from "vue";

export default function (vm) {
  // eslint-disable-next-line no-unused-vars
  let view = false;
  return new Vue({
    data() {
      return {
        diagramController: false,
        current_condition_id: "",
        current_condition_changed: false,
        domElement: null,
        pan: null,
        zoom: 1,
        highlighted: [],
        selected: [],
        answers: [],
        startId: null,
        endId: null,
        classSvg: "",
        datasheetPrefix: "",
        datasheetPrefixExceptionsRegexps: [],
      };
    },
    methods: {
      init(domElement) {
        this.domElement = domElement;
        if (this.svg_attachment) this.refresh();
      },
      refresh() {
        fetch(this.svg_attachment.url)
          .then((r) => r.text())
          .then((data) => {
            this.domElement.innerHTML = "nothing";
            this.domElement.innerHTML = data;
            var svg = this.domElement.querySelector("svg");
            this.classSvg = svg.className.baseVal;
            svg.setAttribute("width", "100%");
            svg.setAttribute("height", "100%");
            beforeInit(svg);
            this.diagramController = new Diagram(svg, {
              components,
              zoom: true,
              globalEventListener: (evt, master, $model) => {
                this.$emit(evt.type, { evt, master, $model });
              },
            });
            this.diagramController.panZoom.resize();
            this.diagramController.panZoom.setOnPan(
              (newPan) => (this.pan = newPan)
            );
            this.diagramController.panZoom.setOnZoom(
              (newZoom) => (this.zoom = newZoom)
            );

            // Attempt to find the diagram datasheet prefix
            try {
              this.datasheetPrefix = svg.querySelector("g > g").dataset.datasheetPrefix || ""
              this.datasheetPrefixExceptionsRegexps = svg.querySelector("g > g").dataset.datasheetPrefixExceptions ? svg.querySelector("g > g").dataset.datasheetPrefixExceptions.split(",").map(prefix => new RegExp(`^${prefix.trim()}`)) : []
            } catch (e) {
              // do nothing if error is thrown
            }

            // Default view: all layers shown (fix visio write display:none as default in some diagrams)
            Object.keys(this.diagramController.layers).forEach((layerName) => {
              this.diagramController.layers[layerName].show();
            });

            if (vm.value.body.contents.view) {
              // View set up in editor mode
              view = vm.value.body.contents.view;
            } else if (vm.$props.view) {
              // View set up via pin view and passed as prop (retrocompatibility)
              view = vm.$props.view;
            }

            if (view) {
              if (view.condition_name) {
                this.changeCondition(
                  this.conditions.find(
                    (c) => c.name == view.condition_name
                  )
                );
              } else if (view.condition_id) {
                this.changeCondition(
                  this.conditions.find((c) => c._id == view.condition_id)
                );
              } else {
                this.changeCondition(this.conditions[0]);
              }

              if (view.selected) {
                this.selected = view.selected;
              } else {
                this.selected = [];
              }

              if (view.highlighted) {
                view.highlighted.forEach((e) => {
                  let svgComponentHighlighted = document.getElementById(e);
                  this.diagramController.highlight(svgComponentHighlighted);
                });
              }

              if (view.layers) {
                Object.keys(view.layers).forEach((layerName) => {
                  if (
                    this.diagramController.layers.hasOwnProperty(layerName) &&
                    !view.layers[layerName].visible
                  ) {
                    this.diagramController.layers[layerName].hide();
                  }
                });
              }
            } else if (this.conditions.length > 0 && !this.current_condition) {
              this.changeCondition(this.conditions[0]);
            }

            if (this.valueActivity.answers) {
              this.answers = this.valueActivity.answers;

              //move this to rhojs
              this.answers.forEach((e) => {
                let svgElement = document.querySelector(
                  ".svg-pan-zoom_viewport"
                );
                let elm = document.getElementById(e.shape.id);

                const _OFFSET = 6;
                var o = document.createElementNS(
                  "http://www.w3.org/2000/svg",
                  "rect"
                );
                var bbox;

                bbox = elm.getBBox();
                o.setAttribute("x", bbox.x - _OFFSET / 2);
                o.setAttribute("y", bbox.y - _OFFSET / 2);
                o.setAttribute("width", bbox.width);
                o.setAttribute("height", bbox.height);
                o.setAttribute("stroke", "black");
                o.setAttribute("fill", "rgba(127,191,63,0.4)");
                o.setAttribute("data-selectedId", elm.id);
                o.setAttribute("id", "a#" + elm.id);
                o.onmouseup = () => {
                  this.endDrag(o);
                };
                o.onmouseenter = () => {
                  o.setAttribute("fill", "rgba(197,11,63,0.4)");
                  o.setAttribute("x", bbox.x - _OFFSET / 2 - 2);
                  o.setAttribute("y", bbox.y - _OFFSET / 2 - 2);
                  o.setAttribute("width", bbox.width + 4);
                  o.setAttribute("height", bbox.height + 4);
                };
                o.onmouseleave = () => {
                  o.setAttribute("fill", "rgba(127,191,63,0.4)");
                  o.setAttribute("x", bbox.x - _OFFSET / 2);
                  o.setAttribute("y", bbox.y - _OFFSET / 2);
                  o.setAttribute("width", bbox.width);
                  o.setAttribute("height", bbox.height);
                };
                elm.classList.forEach((l) => o.classList.add(l));
                o.setAttribute("transform", elm.getAttribute("transform"));
                svgElement.appendChild(o);
                elm.style.opacity = 0;
              });
            }
          });
      },
      changeCondition(condition) {
        if (condition) {
          return fetch(condition.url)
            .then((r) => r.json())
            .then((data) => {
              this.diagramController.replaceState(data);
              this.current_condition_id = condition._id;
              this.current_condition_changed = false;
            })
            .catch(console.error);
        }
      },
      zoomIn() {
        this.diagramController.panZoom.zoomIn();
      },
      zoomOut() {
        this.diagramController.panZoom.zoomOut();
      },
      zoomReset() {
        this.diagramController.panZoom.reset();
      },
      setElementProp(id, prop, value) {
        this.diagramController.model[id][prop] = value;
        this.current_condition_changed = true;
      },
      getElementProp(id, prop) {
        return this.diagramController.model[id][prop];
      },
      highlight(currentTarget) {
        this.diagramController.highlight(currentTarget);
        vm.$emit("update:view", this.getCurrentView());
      },
      getCurrentView() {
        return {
          condition_id: this.current_condition_id,
          layers: JSON.parse(JSON.stringify(this.diagramController.layers)),
          highlighted: this.highlighted,
          selected: [...document.querySelectorAll("[data-selectedId]")].map(
            (e) => e.dataset.selectedId
          ),
        };
      },
      /**
       * Activity Diagram Methods
       **/
      startDrag(evt) {
        evt.preventDefault();
        this.startId = evt.currentTarget.firstElementChild.id;
        let newdiv = document.createElement("div");
        newdiv.setAttribute("id", "itemToDrag");
        newdiv.style.position = "absolute";
        newdiv.style.zIndex = 9999;
        newdiv.style.background = "white";
        newdiv.style.border = "1px solid #ccc";
        newdiv.style.height = "75px";
        newdiv.style.width = "125px";
        // newdiv.style.transform = "scale(0.5, 0.5)";
        newdiv.appendChild(evt.currentTarget.cloneNode(true));
        newdiv.style.display = "none";
        document.body.appendChild(newdiv);
      },
      drag(evt) {
        evt.preventDefault();
        var dragX = 0;
        var dragY = 0;
        if (evt.clientX) {
          dragX = evt.clientX;
          dragY = evt.clientY;
        } else {
          dragX = evt.changedTouches[0].clientX;
          dragY = evt.changedTouches[0].clientY;
        }
        let element = document.getElementById("itemToDrag");
        if (element) {
          element.style.display = "block";
          element.style.top = 5 + dragY + "px";
          element.style.left = 5 + dragX + "px";
        }
      },
      endDrag(evt) {
        if (evt.id) {
          // Called from answer.onmouseup event => evt is HTML elem
          this.endId = evt.id.split("#")[1];
          if (this.endId == this.startId) {
            let elementToRemove = document.getElementById(evt.id);
            if (elementToRemove) elementToRemove.remove();
            document.getElementById(this.startId).style.opacity = 1;
            this.answers = this.answers.filter(
              (e) => e.shape.id != this.startId
            );
          }
        } else if (
          evt.changedTouches &&
          evt.changedTouches[0] &&
          evt.changedTouches[0].clientX
        ) {
          // Called from touch event => evt is a touch event
          const pointInRect = ({ x1, y1, x2, y2 }, { x, y }) =>
            x > x1 && x < x2 && y > y1 && y < y2;
          var answerElement;
          var answerPosition;
          this.answers.forEach((ans) => {
            answerElement = document.getElementById("a#" + ans.shape.id);
            answerPosition = answerElement.getBoundingClientRect();
            // Check if touch end position is inside answer rectangle
            if (
              pointInRect(
                {
                  x1: answerPosition.x,
                  y1: answerPosition.y,
                  x2: answerPosition.x + answerPosition.width,
                  y2: answerPosition.y + answerPosition.height,
                },
                {
                  x: evt.changedTouches[0].clientX,
                  y: evt.changedTouches[0].clientY,
                }
              )
            ) {
              if (ans.shape.id == this.startId) {
                if (answerElement) answerElement.remove();
                document.getElementById(this.startId).style.opacity = 1;
                this.answers = this.answers.filter(
                  (e) => e.shape.id != this.startId
                );
              }
            }
          });
        }
        this.startId = null;
        let itemToDrag = document.getElementById("itemToDrag");
        if (itemToDrag) itemToDrag.remove();
      },
      addAnswer(element, viewOffset) {
        let aux = {};
        let xOffset = -11;
        if (element.getAttribute("transform").includes("scale(-1")) {
          element.setAttribute("transform", "translate(0, 0) scale(-1, 1)");
          xOffset = -31;
        } else {
          element.setAttribute("transform", "translate(0, 0)");
        }
        aux.id = JSON.parse(JSON.stringify(element.id));
        aux.dataset = JSON.parse(JSON.stringify(element.dataset));
        aux.outerHTML = JSON.parse(JSON.stringify(element.outerHTML));
        if (element.className.baseVal == "pt-keypoint-point") {
          this.answers.push({
            classSvg: this.classSvg,
            shape: aux,
            offset: xOffset + 25 + " " + (viewOffset - 6) + " 80 80",
          });
        } else {
          this.answers.push({
            classSvg: this.classSvg,
            shape: aux,
            offset: xOffset + " " + (viewOffset - 6) + " 40 40",
          });
        }
        if (!this.valueActivity.answers) {
          this.$set(this.valueActivity, "answers", []);
        }
        this.valueActivity.answers = this.answers;
      },
      deleteAnswers() {
        this.$set(this.valueActivity, "answers", []);
      },
      deleteShape(id) {
        this.answers = this.answers.filter((el) => el.shape.id != id);
        let aid = "a#" + id;
        document.getElementById(aid).remove();
        document.getElementById(id).style.opacity = 1;
        this.$set(this.valueActivity, "answers", this.answers);
      },
      /**
       * END Activity Diagram Methods
       **/
    },
    computed: {
      value() {
        return vm.value.body.contents.diagram;
      },
      valueActivity() {
        return vm.value.body.contents;
      },
      view() {
        return vm.$props.view;
      },
      attachments() {
        if (!this.value || !this.value.body) return [];
        return this.value.body.attachments;
      },
      shapes() {
        if (!this.value.body.contents) {
          this.$set(this.value.body, "contents", {});
        }
        if (!this.value.body.contents.shapes) {
          this.$set(this.value.body.contents, "shapes", {});
        }
        return this.value.body.contents.shapes;
      },
      svg_attachment() {
        if (!this.value || !this.value.body) return false;
        return this.value.body.attachments.find((e) => e.name == "svg");
      },
      vsdx_attachment() {
        if (!this.value || !this.value.body) return false;
        return this.value.body.attachments.find((e) => e.name == "vsdx");
      },
      conditions() {
        if (!this.value || !this.value.body) return false;
        return this.value.body.attachments.filter(
          (e) => e.attachment_type == ".json"
        );
      },
      current_condition() {
        if (!this.value || !this.value.body) return false;
        return this.value.body.attachments.find(
          (e) => e._id == this.current_condition_id
        );
      },
      layers() {
        return this.diagramController.layers;
      },
    },
    watch: {
      current_condition_id: {
        handler() {
          vm.$emit("update:view", this.getCurrentView());
        },
      },
      selected: {
        handler() {
          vm.$emit("update:view", this.getCurrentView());
        },
      },
      highlighted: {
        handler() {
          vm.$emit("update:view", this.getCurrentView());
        },
      },
      layers: {
        deep: true,
        handler() {
          vm.$emit("update:view", this.getCurrentView());
        },
      },
    },
  });
}
