
/**
 * Changes document Title (<title>) and icon (<link rel="icon"/ >)
 * @param {String} title 
 * @param {String} url 
 */
export default function changeDocumentTitleAndIcon(title, url) {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = url;
    document.title = title
}