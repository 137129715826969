import { isRichTextFieldEmpty } from "./helpers"

export const validateAMC = amc => {
  const field =
    amc.body.contents.distractors && amc.body.contents.distractors[0] ? amc.body.contents.distractors[0] : ""
  if (isRichTextFieldEmpty(field)) return {
    isValid: false,
    message: "Required field is empty (distractor)"
  }
  return {
    isValid: true,
    message: ""
  }
}
