
import "./pt-logic-input-switch.css"

export default {
  model: {
    "position1": {
      type: "boolean",
      default: false
    },
    "position2": {
      type: "boolean",
      default: true
    },
    "position3": {
      type: "boolean",
      default: false
    },
    "position4": {
      type: "boolean",
      default: false
    },
    "position5": {
      type: "boolean",
      default: false
    },
    "position6": {
      type: "boolean",
      default: false
    }
  },
  listeners: {
    mousedown(e) {
      var { position } = e.srcElement.closest("g[data-position]").dataset
      if (!position) {
        return true
      }
      this.data.position1 = false
      this.data.position2 = false
      this.data.position3 = false
      this.data.position4 = false
      this.data.position5 = false
      this.data.position6 = false
      this.data[`position${position}`] = true
      return false
    },
    mouseup(e) {
      var { position1_return, position3_return, position4_return } = e.srcElement.closest("g.pt-logic-input-switch").dataset
      if (this.data.position1 && position1_return == "1" || this.data.position3 && position3_return == "1" || this.data.position4 && position4_return == "1") {
        this.data.position1 = false
        this.data.position2 = true
        this.data.position3 = false
        this.data.position4 = false
        this.data.position5 = false
        this.data.position6 = false
      }
      return false
    },
    click(e) {
      var { position } = e.srcElement.closest("g[data-position]").dataset
      return (position ? true : false)
    }
  }
}
