<template>
  <v-list subheader two-line>
    <v-subheader>Content type</v-subheader>
    <v-list-tile>
      <v-list-tile-action>
        <ContentTypeIcon :type="type" />
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-sub-title>Type</v-list-tile-sub-title>
        <v-list-tile-title>{{ type }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-subheader>Workflow Options</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-content>
        <v-select
          style="width: 100%"
          e2e-id="workflow_type"
          v-model="workflow"
          :disabled="true"
          :items="workflows"
          label="Workflow"
          item-text="label"
          item-value="value"
          prepend-icon="mdi-sitemap"
          append-icon="mdi-chevron-down"></v-select>
      </v-list-tile-content>
    </v-list-tile>
    <v-list-tile v-for="contributor of contributors" :key="contributor.role">
      <user-selector
        :label="contributor.role"
        :disabled="true"
        :users-disabled="[]"
        @change="_id => (contributor.user_id = _id)"
        :value="contributor.user_id"></user-selector>
    </v-list-tile>
    <v-subheader>Operation Options</v-subheader>
    <v-list
      v-for="(op, index) in operationsSelected"
      :key="index"
      :class="index % 2 == 0 ? 'even-background' : 'odd-background'">
      <v-list-tile>
        <v-list-tile-content>
          <v-list-tile-title>Operation {{ index + 1 }}</v-list-tile-title>
          <v-list-tile-sub-title v-if="index != 0"
            >This step will be executed after <b>Operation {{ index }}</b></v-list-tile-sub-title
          >
        </v-list-tile-content>
        <v-list-tile-action v-if="index != 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon flat color="error" @click="operationsSelected.splice(index, 1)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span>Remove operation {{ index + 1 }}</span>
          </v-tooltip>
        </v-list-tile-action>
      </v-list-tile>
      <v-list-tile avatar v-if="operationsAvailable.length > 0">
        <v-list-tile-content>
          <v-select
            style="width: 100%"
            e2e-id="operation_option"
            v-model="op.operation"
            :items="operationsAvailable"
            label="Operation"
            item-text="title"
            item-value="value"
            return-object
            prepend-icon="mdi-cog"
            append-icon="mdi-chevron-down"></v-select>
        </v-list-tile-content>
      </v-list-tile>
      <v-alert v-else :value="true" type="warning">No operations available for {{ type }} type.</v-alert>
      <v-alert v-if="op.operation" :value="true" color="info" icon="info" outline>{{
        op.operation.description
      }}</v-alert>
      <component
        v-if="op.operation && op.operation.OperationOptionsComponent"
        :is="op.operation.OperationOptionsComponent"
        :title="op.operation.title"
        @update:data="data => updateOperationData(data, op)" />
      <v-alert v-else-if="op.operation" :value="true" type="error"
        >Software issue. Component not defined for operation</v-alert
      >
      <v-alert v-else :value="true" type="warning">Please, select an operation</v-alert>
    </v-list>
    <div class="text-center add-operation-button">
      <v-tooltip bottom v-if="canAddOperation">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon color="success" @click="operationsSelected.push({ operation: false, data: {} })">
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <span>Add operation</span>
      </v-tooltip>
      <v-alert v-else :value="true" type="info"
        >Last operation selected can not be combined with another operation. You can not add more operations after
        it.</v-alert
      >
    </div>
    <v-list-tile>
      <v-list-tile-action>
        <v-checkbox :value="approve" @change="v => $emit('update:approve', v || false)"></v-checkbox>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>Approve after upload</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import UserSelector from "@/pages/workflow/components/UserSelector"
import { ContentTypeIcon } from "plant-common"
import operations from "./operations"
import WorkflowTypes from "../../../../workflow/constants/WorkflowTypes"

export default {
  props: {
    type: String,
    workflow: String,
    approve: Boolean,
    contributors: Array,
    operationsSelected: Array,
    canAddOperation: Boolean,
    canChangeProps: Boolean
  },
  data() {
    return {
      operationsAvailable: [],
      workflows: WorkflowTypes
    }
  },
  components: {
    UserSelector,
    ContentTypeIcon
  },
  watch: {
    canChangeProps: {
      immediate: true,
      handler() {
        operations.forEach(op => {
          if (op.contentType === this.type || op.contentType === "global") {
            this.operationsAvailable.push(op)
          }
        })
        // if not canChangeProps remove operation
        if (!this.canChangeProps) {
          this.operationsAvailable = this.operationsAvailable.filter(
            op => op.id !== "datasheet-replace-properties-from-table"
          )
        }
      }
    }
  },
  methods: {
    updateOperationData(data, op) {
      op.data = data
    }
  }
}
</script>

<style scoped>
.even-background {
  background-color: #fff;
}
.odd-background {
  background-color: #f2f2f2;
}
.add-operation-button {
  margin-top: 14px;
}
</style>
