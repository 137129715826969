
import "./pt-logic-input-switch-sequenced.css"

var returnToMiddlePosition = function () {
  if (!this.data.transitionInProgress && !this.data.isMouseDown) {
    var { position1_return, position3_return, position4_return } = this.$el.dataset
    if (this.data.position1 && position1_return == "1" || this.data.position3 && position3_return == "1" || this.data.position4 && position4_return == "1") {
      this.data.position1 = false
      this.data.position2 = true
      this.data.position3 = false
      this.data.position4 = false
      this.data.position5 = false
      this.data.position6 = false
    }
  }
  return false
}

export default {
  model: {
    "position1": {
      type: "boolean",
      default: false
    },
    "position2": {
      type: "boolean",
      default: true
    },
    "position3": {
      type: "boolean",
      default: false
    },
    "position4": {
      type: "boolean",
      default: false
    },
    "position5": {
      type: "boolean",
      default: false
    },
    "position6": {
      type: "boolean",
      default: false
    },
    "transitionInProgress": {
      type: "boolean",
      default: false
    },
    "isMouseDown": {
      type: "boolean",
      default: false
    }
  },
  listeners: {
    mousedown(e) {
      var { position } = e.srcElement.closest("g[data-position]").dataset
      if (!position) {
        return true
      }
      this.data.transitionInProgress = true
      this.data.isMouseDown = true

      // When changing the switch position the output value changes sequentially, for example:
      // - going from 1 to 4 changes: 2, 3, 4
      // - going from 6 to 3 changes: 5, 4, 3
      var currentPosition = Object.entries(this.data).filter(([key, value]) => key.includes('position')).findIndex(([key, value]) => value) + 1;
      if (currentPosition > position) {
        for (let i = currentPosition - 1; i >= position; i--) {
          setTimeout(() => {
            this.data[`position${i + 1}`] = false
            this.data[`position${i}`] = true
            if (i == position) {
              setTimeout(() => {
                this.data.transitionInProgress = false
              }, 1000)
            }
          }, (currentPosition - i - 1) * 1000);
        }
      } else if (currentPosition < position) {
        for (let i = currentPosition + 1; i <= position; i++) {
          setTimeout(() => {
            this.data[`position${i - 1}`] = false
            this.data[`position${i}`] = true
            if (i == position) {
              setTimeout(() => {
                this.data.transitionInProgress = false
              }, 1000)
            }
          }, (i - currentPosition - 1) * 1000);
        }
      }
      return false
    },
    mouseup(e) {
      this.data.isMouseDown = false
      return false
    },
    click(e) {
      var { position } = e.srcElement.closest("g[data-position]").dataset
      return (position ? true : false)
    }
  },
  watch: {
    transitionInProgress: returnToMiddlePosition,
    isMouseDown: returnToMiddlePosition
  }
}
