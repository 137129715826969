import Vue from "vue"
import { walkTreeData } from "he-tree-vue"

export function upgradeModel(content) {
  const upgradeFunctions = getUpgradeFunctions(content)
  if (!content.version) {
    content.version = 0
  }
  for (let i = content.version; i < upgradeFunctions.length; i++) {
    content.version = i
    upgradeFunctions[i]()
  }
}

export function resetVisited(content) {
  walkTreeData(content.slides, node => {
    resetVisitedSlide(node)
  })
}

function getBlockActions(block) {
  if (block.data.actions) {
    return block.data.actions
  } else if (block.actions) {
    return block.actions
  } else {
    return []
  }
}

export function resetVisitedSlide(slide) {
  slide.blocks.forEach(block => {
    // Button and button and tabs inside popup
    if (block.type === "button") {
      if (block.data) {
        Vue.set(block.data, "visited", false)
      }
      const blockActions = getBlockActions(block)
      if (blockActions) {
        blockActions.forEach(action => {
          if (action.blocks) {
            action.blocks.forEach(actionBlock => {
              if (actionBlock.type === "button" && actionBlock.data) {
                Vue.set(actionBlock.data, "visited", false)
              }
              if (actionBlock.type === "tabs") {
                actionBlock.data.tabs.forEach(tab => {
                  if (tab.data) {
                    Vue.set(tab.data, "visited", false)
                  }
                })
              }
            })
          }
        })
      }
    }
    if (block.type === "tabs") {
      // Tab and button inside
      block.data.tabs.forEach(tab => {
        if (tab.data) {
          Vue.set(tab.data, "visited", false)
        }
        tab.blocks.forEach(tabBlock => {
          if (tabBlock.type === "button" && tabBlock.data) {
            Vue.set(tabBlock.data, "visited", false)
          }
        })
      })
    }
  })
}

function getUpgradeFunctions(content) {
  return [
    function () {
      upgradeToVersion0(content)
    },
    function () {
      upgradeToVersion1(content)
    },
    function () {
      upgradeToVersion2(content)
    },
    function () {
      upgradeToVersion3(content)
    },
    function () {
      upgradeToVersion4(content)
    },
    function () {
      upgradeToVersion5(content)
    },
    function () {
      upgradeToVersion6(content)
    },
    function () {
      upgradeToVersion7(content)
    },
    function () {
      upgradeToVersion8(content)
    },
    function () {
      upgradeToVersion9(content)
    },
    function () {
      upgradeToVersion10(content)
    },
    function () {
      upgradeToVersion11(content)
    }
  ]
}

function upgradeToVersion0(content) {
  // styles
  if (!content.settings) {
    Vue.set(content, "settings", {
      styles: {
        wrapper: {
          backgroundColor: "#ffffff"
        },
        slide: {
          backgroundColor: "#ffffff",
          color: "#000000",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          border: "2px solid #ccc"
        }
      }
    })
  }
  // galleries
  if (!content.galleries) {
    Vue.set(content, "galleries", [])
  }
  // templates
  if (!content.templates) {
    Vue.set(content, "templates", [])
  }
  // layouts
  if (!content.layouts) {
    Vue.set(content, "layouts", [])
  }
  // overlay image
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "image") {
        if (!block.data.overlay) {
          Vue.set(block.data, "overlay", "#00000000")
        }
      }
    })
  })
}

function upgradeToVersion1(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "textarea") {
        const change = {
          "flex-start": "left",
          center: "center",
          "flex-end": "right"
        }
        if (block.data.justifyContent) {
          const value = change[block.data.justifyContent]
          Vue.set(block.data, "textAlign", value)
        }
      }
      if (block.type === "flipCard") {
        const change = {
          "flex-start": "left",
          center: "center",
          "flex-end": "right"
        }
        if (block.data.front.justifyContent) {
          const value = change[block.data.front.justifyContent]
          Vue.set(block.data.front, "textAlign", value)
        }
        if (block.data.back.justifyContent) {
          const value = change[block.data.back.justifyContent]
          Vue.set(block.data.back, "textAlign", value)
        }
      }
    })
  })
}

function upgradeToVersion2(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "textarea") {
        if (!block.data.color) {
          Vue.set(block.data, "color", "#000000ff")
        }
      }
      if (block.type === "flipCard") {
        if (!block.data.front.color) {
          Vue.set(block.data.front, "color", "#000000ff")
        }
        if (!block.data.back.color) {
          Vue.set(block.data.back, "color", "#000000ff")
        }
      }
    })
  })
}

function upgradeToVersion3(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "textarea") {
        if (block.data.fontSizeScale) {
          Vue.set(block.data, "fontSize", block.data.fontSizeScale * 80)
        }
      }
      if (block.type === "flipCard") {
        if (!block.data.trigger) {
          Vue.set(block.data, "trigger", "hover")
        }
        if (block.data.front.fontSizeScale) {
          Vue.set(block.data.front, "fontSize", block.data.front.fontSizeScale * 80)
        }
        if (block.data.back.fontSizeScale) {
          Vue.set(block.data.back, "fontSize", block.data.back.fontSizeScale * 80)
        }
      }
    })
  })
  content.layouts.forEach(layout => {
    if (!layout.name) {
      layout.name = "New Layout"
    }
  })
  if (!content.settings.styles.colors) {
    content.settings.styles.colors = ["#ffffff", "#ffffff", "#ffffff", "#ffffff"]
  }
}

function upgradeToVersion4(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "objectives") {
        if (!block.data.order) {
          Vue.set(block.data, "order", "alphabetical")
        }
      }
    })
  })
}

function upgradeToVersion5(content) {
  content.layouts.forEach(layout => {
    if (!layout.hasOwnProperty("imported")) {
      layout.imported = false
    }
  })
  content.galleries.forEach(gallery => {
    if (!gallery.hasOwnProperty("imported")) {
      gallery.imported = false
    }
  })
}

function upgradeToVersion6(content) {
  if (!content.settings.styles.block) {
    Vue.set(content.settings.styles, "block", {
      fontFamily: "Roboto"
    })
  }
  if (content.settings.styles.colors.length === 4) {
    content.settings.styles.colors = content.settings.styles.colors.concat(["#ffffff", "#ffffff", "#ffffff", "#ffffff"])
  }
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      if (block.type === "textarea") {
        if (!block.data.fontFamily) {
          Vue.set(block.data, "fontFamily", false)
        }
      }
      if (block.type === "objectives") {
        if (!block.data.fontFamily) {
          Vue.set(block.data, "fontFamily", false)
        }
      }
      if (block.type === "button") {
        if (!block.data.style.regular.fontFamily) {
          Vue.set(block.data.style.regular, "fontFamily", false)
          Vue.set(block.data.style.hover, "fontFamily", false)
          Vue.set(block.data.style.active, "fontFamily", false)
        }
      }
      if (block.type === "flipCard") {
        if (!block.data.front.fontFamily) {
          Vue.set(block.data.front, "fontFamily", false)
          Vue.set(block.data.back, "fontFamily", false)
        }
      }
      if (block.type === "tabs") {
        if (!block.data.fontFamily) {
          Vue.set(block.data, "fontFamily", false)
        }
      }
      if (block.type === "breadcrumb") {
        if (!block.data.fontFamily) {
          Vue.set(block.data, "fontFamily", false)
        }
      }
    })
  })
}

function upgradeToVersion7(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      switch (block.type) {
        // Block: Video || Audio
        case "video":
        case "audio":
          if (!block.data.hasOwnProperty("autoplay")) {
            Vue.set(block.data, "autoplay", false)
          }
          if (!block.data.hasOwnProperty("controls")) {
            Vue.set(block.data, "controls", {
              color: "#ffffffff",
              size: 25
            })
          }
          break
        // Block: Tabs
        case "tabs":
          if (!block.data.style) {
            Vue.set(block.data, "style", {
              normal: {
                backgroundColor: "#ffffffff",
                color: "#000000e6"
              },
              active: {
                backgroundColor: "#eeeeeeff",
                color: "#000000ff"
              },
              visited: {
                backgroundColor: "#ccccccff",
                color: "#000000ff"
              }
            })
          }
          if (!block.data.fontSize) {
            Vue.set(block.data, "fontSize", 40)
          }
          block.data.tabs.forEach(tab => {
            if (!tab.data) {
              Vue.set(tab, "data", {
                visited: false
              })
            }
            tab.blocks.forEach(tabBlock => {
              // Tabs => button
              if (tabBlock.type === "button") {
                if (!tabBlock.data.hasOwnProperty("visited")) {
                  Vue.set(tabBlock.data, "visited", false)
                  Vue.set(tabBlock.data.style, "visited", {
                    backgroundColor: "#bdbdbdff",
                    color: "#000000ff",
                    textScale: 100,
                    textAlign: "center",
                    boxShadowButton: "none",
                    borderRadius: 0,
                    fontFamily: false
                  })
                }
              }
              // Tabs => video || audio
              if (tabBlock.type === "video" || tabBlock.type === "audio") {
                if (!tabBlock.data.hasOwnProperty("autoplay")) {
                  Vue.set(tabBlock.data, "autoplay", false)
                }
                if (!tabBlock.data.hasOwnProperty("controls")) {
                  Vue.set(tabBlock.data, "controls", {
                    color: "#ffffffff",
                    size: 25
                  })
                }
              }
            })
          })
          break
        // Block: Button
        case "button":
          if (!block.data.hasOwnProperty("visited")) {
            Vue.set(block.data, "visited", false)
            Vue.set(block.data.style, "visited", {
              backgroundColor: "#bdbdbdff",
              color: "#000000ff",
              textScale: 100,
              textAlign: "center",
              boxShadowButton: "none",
              borderRadius: 0,
              fontFamily: false,
              borderWidth: 0,
              borderStyle: "solid",
              borderColor: "#bdbdbdff"
            })
          }
          block.data.actions.forEach(action => {
            if (action.type === "library" && !action.hasOwnProperty("fullWidth")) {
              Vue.set(action, "fullWidth", true)
            }
            if (action.blocks) {
              action.blocks.forEach(actionBlock => {
                // Button => button
                if (actionBlock.type === "button") {
                  if (!actionBlock.data.hasOwnProperty("visited")) {
                    Vue.set(actionBlock.data, "visited", false)
                    Vue.set(actionBlock.data.style, "visited", {
                      backgroundColor: "#bdbdbdff",
                      color: "#000000ff",
                      textScale: 100,
                      textAlign: "center",
                      boxShadowButton: "none",
                      borderRadius: 0,
                      fontFamily: false
                    })
                  }
                }
                // Button => tabs
                if (actionBlock.type === "tabs") {
                  if (!actionBlock.data.style) {
                    Vue.set(actionBlock.data, "style", {
                      normal: {
                        backgroundColor: "#ffffffff",
                        color: "#000000e6"
                      },
                      active: {
                        backgroundColor: "#eeeeeeff",
                        color: "#000000ff"
                      },
                      visited: {
                        backgroundColor: "#ccccccff",
                        color: "#000000ff"
                      }
                    })
                  }
                  if (!actionBlock.data.fontSize) {
                    Vue.set(actionBlock.data, "fontSize", 40)
                  }
                  actionBlock.data.tabs.forEach(tab => {
                    Vue.set(tab, "data", {
                      visited: false
                    })
                  })
                  if (actionBlock.type === "video" || actionBlock.type === "audio") {
                    if (!actionBlock.data.hasOwnProperty("autoplay")) {
                      Vue.set(actionBlock.data, "autoplay", false)
                    }
                    if (!actionBlock.data.hasOwnProperty("controls")) {
                      Vue.set(actionBlock.data, "controls", {
                        color: "#ffffffff",
                        size: 25
                      })
                    }
                  }
                }
              })
            }
          })
          break
        // Block: TextArea
        case "textarea":
          if (!block.data.hasOwnProperty("padding")) {
            Vue.set(block.data, "padding", 0)
          }
          break
        // Block: FlipCard
        case "flipCard":
          if (!block.data.front.hasOwnProperty("padding")) {
            Vue.set(block.data.front, "padding", 0)
            Vue.set(block.data.back, "padding", 0)
          }
          break
        default:
          // Do nothing
          break
      }
    })
  })
}

function upgradeToVersion8(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      switch (block.type) {
        // Block: Button
        case "button":
          block.data.actions.forEach(action => {
            if (action.type === "popup" && !action.style.hasOwnProperty("borderRadius")) {
              Vue.set(action.style, "borderRadius", 0)
            }
          })
          break
        default:
          // Do nothing
          break
      }
    })
  })
}

function upgradeToVersion9(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      switch (block.type) {
        // Block: Video
        case "video":
          if (!block.data.hasOwnProperty("delay")) {
            Vue.set(block.data, "delay", 0)
          }
          break
        // Block: Video
        case "audio":
          if (!block.data.hasOwnProperty("delay")) {
            Vue.set(block.data, "delay", 0)
          }
          break
        default:
          // Do nothing
          break
      }
    })
  })
}

function upgradeToVersion10(content) {
  walkTreeData(content.slides, node => {
    node.blocks.forEach(block => {
      switch (block.type) {
        // Block: Button
        case "button":
          if (block.data.actions) {
            block.data.actions.forEach(action => {
              if (action.type === "popup" && action.blocks) {
                // Add padding prop to textarea and flipcard inside popup
                action.blocks.forEach(block => {
                  if (block.type === "textarea" && !block.data.hasOwnProperty("padding")) {
                    Vue.set(block.data, "padding", 0)
                  } else if (block.type === "flipCard" && !block.data.front.hasOwnProperty("padding")) {
                    Vue.set(block.data.front, "padding", 0)
                    Vue.set(block.data.back, "padding", 0)
                  }
                })
              }
            })
          }
          break
        default:
          // Do nothing
          break
      }
    })
  })
}
/** Clean up layout's children (unnecessary information saved on database) */
function upgradeToVersion11(content) {
  if (content.layouts) {
    content.layouts.forEach(layout => {
      if (layout.slide.children) {
        layout.slide.children = false
      }
      if (layout.slide.objectives && layout.slide.objectives.length > 0) {
        layout.slide.objectives = []
      }
    })
  }
}
