<template>
  <div :style="`overflow:hidden; top: 0px; bottom: 0px; left: 0px; right: 0px;`" ref="canvas">
    <component
      v-for="block in blocks"
      :key="block.id"
      :is="getBlockComponent(block.type)"
      :value="block"
      :mode="mode"
      @goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
      @selectSlideById="$emit('selectSlideById', $event)"
      :blockVariables="blockVariables"
      :subcanvasDelay="subcanvasDelay" />
  </div>
</template>

<script>
export default {
  props: ["value", "blocks", "mode", "blockVariables", "subcanvasDelay"],
  inject: ["blockController", "blockDefinitions"],
  computed: {
    parent() {
      return this.$refs.canvas
    }
  },
  methods: {
    getBlockComponent(type) {
      return this.blockDefinitions[type].Block
    }
  }
}
</script>

<style></style>
