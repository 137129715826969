import Viewer from "./Viewer";
import StudentViewer from "./StudentViewer";
import Editor from "./Editor";
import Print from "./Print"

export default {
  name: "guide",
  title: "Guide",
  icon: require("plant-common/src/components/plant-item-viewer/guide/content-icon.svg"),
  Viewer,
  StudentViewer,
  Editor,
  Print
};
