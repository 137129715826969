"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PLANT REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApi = exports.UserApiFactory = exports.UserApiFp = exports.UserApiAxiosParamCreator = exports.LibraryApi = exports.LibraryApiFactory = exports.LibraryApiFp = exports.LibraryApiAxiosParamCreator = exports.JobsApi = exports.JobsApiFactory = exports.JobsApiFp = exports.JobsApiAxiosParamCreator = exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = exports.UserRoleEnum = exports.JobTypeEnum = exports.InlineObject1RoleEnum = exports.InlineObjectRoleEnum = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("./common");
// @ts-ignore
const base_1 = require("./base");
/**
    * @export
    * @enum {string}
    */
var InlineObjectRoleEnum;
(function (InlineObjectRoleEnum) {
    InlineObjectRoleEnum["Admin"] = "admin";
    InlineObjectRoleEnum["Supervisor"] = "supervisor";
    InlineObjectRoleEnum["Instructor"] = "instructor";
    InlineObjectRoleEnum["Student"] = "student";
})(InlineObjectRoleEnum = exports.InlineObjectRoleEnum || (exports.InlineObjectRoleEnum = {}));
/**
    * @export
    * @enum {string}
    */
var InlineObject1RoleEnum;
(function (InlineObject1RoleEnum) {
    InlineObject1RoleEnum["Admin"] = "admin";
    InlineObject1RoleEnum["Supervisor"] = "supervisor";
    InlineObject1RoleEnum["Instructor"] = "instructor";
    InlineObject1RoleEnum["Student"] = "student";
})(InlineObject1RoleEnum = exports.InlineObject1RoleEnum || (exports.InlineObject1RoleEnum = {}));
/**
    * @export
    * @enum {string}
    */
var JobTypeEnum;
(function (JobTypeEnum) {
    JobTypeEnum["Normal"] = "normal";
})(JobTypeEnum = exports.JobTypeEnum || (exports.JobTypeEnum = {}));
/**
    * @export
    * @enum {string}
    */
var UserRoleEnum;
(function (UserRoleEnum) {
    UserRoleEnum["Admin"] = "admin";
    UserRoleEnum["Supervisor"] = "supervisor";
    UserRoleEnum["Instructor"] = "instructor";
    UserRoleEnum["Student"] = "student";
})(UserRoleEnum = exports.UserRoleEnum || (exports.UserRoleEnum = {}));
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
exports.AuthenticationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Get a Refresh Token
         * @summary Get a Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefreshToken: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/refreshToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Login into PLANT
         * @summary Login into PLANT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Logout
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Revoke Refresh Token
         * @summary Revoke Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/auth/revokeToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
exports.AuthenticationApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AuthenticationApiAxiosParamCreator(configuration);
    return {
        /**
         * Get a Refresh Token
         * @summary Get a Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefreshToken(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRefreshToken(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Login into PLANT
         * @summary Login into PLANT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Logout
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Revoke Refresh Token
         * @summary Revoke Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeToken(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AuthenticationApi - factory interface
 * @export
 */
exports.AuthenticationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AuthenticationApiFp(configuration);
    return {
        /**
         * Get a Refresh Token
         * @summary Get a Refresh Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefreshToken(options) {
            return localVarFp.getRefreshToken(options).then((request) => request(axios, basePath));
        },
        /**
         * Login into PLANT
         * @summary Login into PLANT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(options) {
            return localVarFp.login(options).then((request) => request(axios, basePath));
        },
        /**
         * Logout
         * @summary Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         * Revoke Refresh Token
         * @summary Revoke Token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeToken(options) {
            return localVarFp.revokeToken(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
class AuthenticationApi extends base_1.BaseAPI {
    /**
     * Get a Refresh Token
     * @summary Get a Refresh Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    getRefreshToken(options) {
        return exports.AuthenticationApiFp(this.configuration).getRefreshToken(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Login into PLANT
     * @summary Login into PLANT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    login(options) {
        return exports.AuthenticationApiFp(this.configuration).login(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Logout
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    logout(options) {
        return exports.AuthenticationApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Revoke Refresh Token
     * @summary Revoke Token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    revokeToken(options) {
        return exports.AuthenticationApiFp(this.configuration).revokeToken(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuthenticationApi = AuthenticationApi;
/**
 * JobsApi - axios parameter creator
 * @export
 */
exports.JobsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Gets job details
         * @param {string} id Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getJobById', 'id', id);
            const localVarPath = `/jobs/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Returns a paginated list of all jobs. It has filter options
         * @summary Paginated list of all jobs
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobList: (page, limit, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * JobsApi - functional programming interface
 * @export
 */
exports.JobsApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.JobsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Gets job details
         * @param {string} id Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getJobById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Returns a paginated list of all jobs. It has filter options
         * @summary Paginated list of all jobs
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobList(page, limit, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getJobList(page, limit, sort, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * JobsApi - factory interface
 * @export
 */
exports.JobsApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.JobsApiFp(configuration);
    return {
        /**
         *
         * @summary Gets job details
         * @param {string} id Job Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobById(id, options) {
            return localVarFp.getJobById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a paginated list of all jobs. It has filter options
         * @summary Paginated list of all jobs
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobList(page, limit, sort, options) {
            return localVarFp.getJobList(page, limit, sort, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
class JobsApi extends base_1.BaseAPI {
    /**
     *
     * @summary Gets job details
     * @param {string} id Job Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    getJobById(id, options) {
        return exports.JobsApiFp(this.configuration).getJobById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Returns a paginated list of all jobs. It has filter options
     * @summary Paginated list of all jobs
     * @param {number} [page] Requested page number
     * @param {number} [limit] Number of items per page
     * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    getJobList(page, limit, sort, options) {
        return exports.JobsApiFp(this.configuration).getJobList(page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.JobsApi = JobsApi;
/**
 * LibraryApi - axios parameter creator
 * @export
 */
exports.LibraryApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create library
         * @param {InlineObject2} inlineObject2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibrary: (inlineObject2, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'inlineObject2' is not null or undefined
            common_1.assertParamExists('createLibrary', 'inlineObject2', inlineObject2);
            const localVarPath = `/plants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Deletes the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentPreview: (libraryId, contentId, majorRevision, minorRevision, size, view, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('deleteContentPreview', 'libraryId', libraryId);
            // verify required parameter 'contentId' is not null or undefined
            common_1.assertParamExists('deleteContentPreview', 'contentId', contentId);
            // verify required parameter 'majorRevision' is not null or undefined
            common_1.assertParamExists('deleteContentPreview', 'majorRevision', majorRevision);
            // verify required parameter 'minorRevision' is not null or undefined
            common_1.assertParamExists('deleteContentPreview', 'minorRevision', minorRevision);
            // verify required parameter 'size' is not null or undefined
            common_1.assertParamExists('deleteContentPreview', 'size', size);
            const localVarPath = `/library/{libraryId}//preview/{contentId}/{majorRevision}/{minorRevision}/{size}?view={view}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"majorRevision"}}`, encodeURIComponent(String(majorRevision)))
                .replace(`{${"minorRevision"}}`, encodeURIComponent(String(minorRevision)))
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLibrary: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteLibrary', 'id', id);
            const localVarPath = `/plants/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets a content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {number} majorRevision Major Revision
         * @param {number} minorRevision Minor Revision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentByRevision: (libraryId, contentId, majorRevision, minorRevision, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('getContentByRevision', 'libraryId', libraryId);
            // verify required parameter 'contentId' is not null or undefined
            common_1.assertParamExists('getContentByRevision', 'contentId', contentId);
            // verify required parameter 'majorRevision' is not null or undefined
            common_1.assertParamExists('getContentByRevision', 'majorRevision', majorRevision);
            // verify required parameter 'minorRevision' is not null or undefined
            common_1.assertParamExists('getContentByRevision', 'minorRevision', minorRevision);
            const localVarPath = `/library/{libraryId}/content/{contentId}/{majorRevision}/{minorRevision}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"majorRevision"}}`, encodeURIComponent(String(majorRevision)))
                .replace(`{${"minorRevision"}}`, encodeURIComponent(String(minorRevision)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {number} [seconds] Puppeteer seconds delay to do the screenshot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPreviewUrl: (libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('getContentPreviewUrl', 'libraryId', libraryId);
            // verify required parameter 'contentId' is not null or undefined
            common_1.assertParamExists('getContentPreviewUrl', 'contentId', contentId);
            // verify required parameter 'majorRevision' is not null or undefined
            common_1.assertParamExists('getContentPreviewUrl', 'majorRevision', majorRevision);
            // verify required parameter 'minorRevision' is not null or undefined
            common_1.assertParamExists('getContentPreviewUrl', 'minorRevision', minorRevision);
            // verify required parameter 'size' is not null or undefined
            common_1.assertParamExists('getContentPreviewUrl', 'size', size);
            const localVarPath = `/library/{libraryId}//preview/{contentId}/{majorRevision}/{minorRevision}/{size}?view={view}&seconds={seconds}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)))
                .replace(`{${"majorRevision"}}`, encodeURIComponent(String(majorRevision)))
                .replace(`{${"minorRevision"}}`, encodeURIComponent(String(minorRevision)))
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (view !== undefined) {
                localVarQueryParameter['view'] = view;
            }
            if (seconds !== undefined) {
                localVarQueryParameter['seconds'] = seconds;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets a draft\'s content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftByContentId: (libraryId, contentId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('getDraftByContentId', 'libraryId', libraryId);
            // verify required parameter 'contentId' is not null or undefined
            common_1.assertParamExists('getDraftByContentId', 'contentId', contentId);
            const localVarPath = `/library/{libraryId}/draft/{contentId}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"contentId"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets a folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {string} folderId Folder ID to retrieve
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderContents: (libraryId, folderId, page, limit, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('getFolderContents', 'libraryId', libraryId);
            // verify required parameter 'folderId' is not null or undefined
            common_1.assertParamExists('getFolderContents', 'folderId', folderId);
            const localVarPath = `/library/{libraryId}/folders/{folderId}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getLibraryById', 'id', id);
            const localVarPath = `/plants/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get a library list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryList: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/plants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * NOTE. This path definition is very similar to /library/{libraryId}/folder but it is required because path parameters are always required in OpenApi/Swagger.
         * @summary Gets root folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFolderContents: (libraryId, page, limit, sort, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('getRootFolderContents', 'libraryId', libraryId);
            const localVarPath = `/library/{libraryId}/folders`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }
            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets a content array
         * @param {string} libraryId libraryId
         * @param {InlineObject4} inlineObject4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContent: (libraryId, inlineObject4, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('searchContent', 'libraryId', libraryId);
            // verify required parameter 'inlineObject4' is not null or undefined
            common_1.assertParamExists('searchContent', 'inlineObject4', inlineObject4);
            const localVarPath = `/library/{libraryId}/content/search`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject4, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {InlineObject5} inlineObject5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByCustomId: (libraryId, inlineObject5, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('searchContentByCustomId', 'libraryId', libraryId);
            // verify required parameter 'inlineObject5' is not null or undefined
            common_1.assertParamExists('searchContentByCustomId', 'inlineObject5', inlineObject5);
            const localVarPath = `/library/{libraryId}/content/search/custom_id`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject5, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {string} type content type
         * @param {string} text custom_id
         * @param {boolean} hotspot search in content.body.contents.hotspots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByTypeAndText: (libraryId, type, text, hotspot, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'libraryId' is not null or undefined
            common_1.assertParamExists('searchContentByTypeAndText', 'libraryId', libraryId);
            // verify required parameter 'type' is not null or undefined
            common_1.assertParamExists('searchContentByTypeAndText', 'type', type);
            // verify required parameter 'text' is not null or undefined
            common_1.assertParamExists('searchContentByTypeAndText', 'text', text);
            // verify required parameter 'hotspot' is not null or undefined
            common_1.assertParamExists('searchContentByTypeAndText', 'hotspot', hotspot);
            const localVarPath = `/library/{libraryId}/content?type={type}&text={text}&hotspot={hotspot}`
                .replace(`{${"libraryId"}}`, encodeURIComponent(String(libraryId)))
                .replace(`{${"type"}}`, encodeURIComponent(String(type)))
                .replace(`{${"text"}}`, encodeURIComponent(String(text)))
                .replace(`{${"hotspot"}}`, encodeURIComponent(String(hotspot)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update a library
         * @param {string} id Library Id
         * @param {InlineObject3} inlineObject3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLibrary: (id, inlineObject3, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('updateLibrary', 'id', id);
            // verify required parameter 'inlineObject3' is not null or undefined
            common_1.assertParamExists('updateLibrary', 'inlineObject3', inlineObject3);
            const localVarPath = `/plants/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject3, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * LibraryApi - functional programming interface
 * @export
 */
exports.LibraryApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.LibraryApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create library
         * @param {InlineObject2} inlineObject2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibrary(inlineObject2, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createLibrary(inlineObject2, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Deletes the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLibrary(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteLibrary(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets a content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {number} majorRevision Major Revision
         * @param {number} minorRevision Minor Revision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {number} [seconds] Puppeteer seconds delay to do the screenshot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets a draft\'s content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftByContentId(libraryId, contentId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getDraftByContentId(libraryId, contentId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets a folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {string} folderId Folder ID to retrieve
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderContents(libraryId, folderId, page, limit, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFolderContents(libraryId, folderId, page, limit, sort, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLibraryById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get a library list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryList(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getLibraryList(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * NOTE. This path definition is very similar to /library/{libraryId}/folder but it is required because path parameters are always required in OpenApi/Swagger.
         * @summary Gets root folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFolderContents(libraryId, page, limit, sort, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getRootFolderContents(libraryId, page, limit, sort, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets a content array
         * @param {string} libraryId libraryId
         * @param {InlineObject4} inlineObject4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContent(libraryId, inlineObject4, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchContent(libraryId, inlineObject4, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {InlineObject5} inlineObject5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByCustomId(libraryId, inlineObject5, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchContentByCustomId(libraryId, inlineObject5, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {string} type content type
         * @param {string} text custom_id
         * @param {boolean} hotspot search in content.body.contents.hotspots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByTypeAndText(libraryId, type, text, hotspot, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.searchContentByTypeAndText(libraryId, type, text, hotspot, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update a library
         * @param {string} id Library Id
         * @param {InlineObject3} inlineObject3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLibrary(id, inlineObject3, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateLibrary(id, inlineObject3, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * LibraryApi - factory interface
 * @export
 */
exports.LibraryApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.LibraryApiFp(configuration);
    return {
        /**
         *
         * @summary Create library
         * @param {InlineObject2} inlineObject2
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLibrary(inlineObject2, options) {
            return localVarFp.createLibrary(inlineObject2, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Deletes the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options) {
            return localVarFp.deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLibrary(id, options) {
            return localVarFp.deleteLibrary(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {number} majorRevision Major Revision
         * @param {number} minorRevision Minor Revision
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options) {
            return localVarFp.getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets the screenshot URL of the content given considering its revision, size and pinned view
         * @param {string} libraryId libraryId
         * @param {string} contentId content ID
         * @param {string} majorRevision Major Revision
         * @param {string} minorRevision Minor Revision
         * @param {string} size Screenshot size
         * @param {string} [view] Pinned view
         * @param {number} [seconds] Puppeteer seconds delay to do the screenshot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options) {
            return localVarFp.getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a draft\'s content
         * @param {string} libraryId libraryId
         * @param {string} contentId Content Id to retrieve
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDraftByContentId(libraryId, contentId, options) {
            return localVarFp.getDraftByContentId(libraryId, contentId, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {string} folderId Folder ID to retrieve
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderContents(libraryId, folderId, page, limit, sort, options) {
            return localVarFp.getFolderContents(libraryId, folderId, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a library
         * @param {string} id Library Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryById(id, options) {
            return localVarFp.getLibraryById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a library list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLibraryList(options) {
            return localVarFp.getLibraryList(options).then((request) => request(axios, basePath));
        },
        /**
         * NOTE. This path definition is very similar to /library/{libraryId}/folder but it is required because path parameters are always required in OpenApi/Swagger.
         * @summary Gets root folder contents
         * @param {string} libraryId Library ID to look up for folder
         * @param {number} [page] Requested page number
         * @param {number} [limit] Number of items per page
         * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFolderContents(libraryId, page, limit, sort, options) {
            return localVarFp.getRootFolderContents(libraryId, page, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets a content array
         * @param {string} libraryId libraryId
         * @param {InlineObject4} inlineObject4
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContent(libraryId, inlineObject4, options) {
            return localVarFp.searchContent(libraryId, inlineObject4, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {InlineObject5} inlineObject5
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByCustomId(libraryId, inlineObject5, options) {
            return localVarFp.searchContentByCustomId(libraryId, inlineObject5, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Gets an array of contents that match custom_id
         * @param {string} libraryId libraryId
         * @param {string} type content type
         * @param {string} text custom_id
         * @param {boolean} hotspot search in content.body.contents.hotspots
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchContentByTypeAndText(libraryId, type, text, hotspot, options) {
            return localVarFp.searchContentByTypeAndText(libraryId, type, text, hotspot, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update a library
         * @param {string} id Library Id
         * @param {InlineObject3} inlineObject3
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLibrary(id, inlineObject3, options) {
            return localVarFp.updateLibrary(id, inlineObject3, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * LibraryApi - object-oriented interface
 * @export
 * @class LibraryApi
 * @extends {BaseAPI}
 */
class LibraryApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create library
     * @param {InlineObject2} inlineObject2
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    createLibrary(inlineObject2, options) {
        return exports.LibraryApiFp(this.configuration).createLibrary(inlineObject2, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Deletes the screenshot URL of the content given considering its revision, size and pinned view
     * @param {string} libraryId libraryId
     * @param {string} contentId content ID
     * @param {string} majorRevision Major Revision
     * @param {string} minorRevision Minor Revision
     * @param {string} size Screenshot size
     * @param {string} [view] Pinned view
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options) {
        return exports.LibraryApiFp(this.configuration).deleteContentPreview(libraryId, contentId, majorRevision, minorRevision, size, view, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete a library
     * @param {string} id Library Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    deleteLibrary(id, options) {
        return exports.LibraryApiFp(this.configuration).deleteLibrary(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets a content
     * @param {string} libraryId libraryId
     * @param {string} contentId Content Id to retrieve
     * @param {number} majorRevision Major Revision
     * @param {number} minorRevision Minor Revision
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options) {
        return exports.LibraryApiFp(this.configuration).getContentByRevision(libraryId, contentId, majorRevision, minorRevision, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets the screenshot URL of the content given considering its revision, size and pinned view
     * @param {string} libraryId libraryId
     * @param {string} contentId content ID
     * @param {string} majorRevision Major Revision
     * @param {string} minorRevision Minor Revision
     * @param {string} size Screenshot size
     * @param {string} [view] Pinned view
     * @param {number} [seconds] Puppeteer seconds delay to do the screenshot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options) {
        return exports.LibraryApiFp(this.configuration).getContentPreviewUrl(libraryId, contentId, majorRevision, minorRevision, size, view, seconds, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets a draft\'s content
     * @param {string} libraryId libraryId
     * @param {string} contentId Content Id to retrieve
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getDraftByContentId(libraryId, contentId, options) {
        return exports.LibraryApiFp(this.configuration).getDraftByContentId(libraryId, contentId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets a folder contents
     * @param {string} libraryId Library ID to look up for folder
     * @param {string} folderId Folder ID to retrieve
     * @param {number} [page] Requested page number
     * @param {number} [limit] Number of items per page
     * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getFolderContents(libraryId, folderId, page, limit, sort, options) {
        return exports.LibraryApiFp(this.configuration).getFolderContents(libraryId, folderId, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get a library
     * @param {string} id Library Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getLibraryById(id, options) {
        return exports.LibraryApiFp(this.configuration).getLibraryById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get a library list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getLibraryList(options) {
        return exports.LibraryApiFp(this.configuration).getLibraryList(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * NOTE. This path definition is very similar to /library/{libraryId}/folder but it is required because path parameters are always required in OpenApi/Swagger.
     * @summary Gets root folder contents
     * @param {string} libraryId Library ID to look up for folder
     * @param {number} [page] Requested page number
     * @param {number} [limit] Number of items per page
     * @param {string} [sort] Space delimited list of path names. The sort order of each path is ascending unless the path name is prefixed with - which will be treated as descending.  Refer to (Query sort)[https://mongoosejs.com/docs/api.html#query_Query-sort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    getRootFolderContents(libraryId, page, limit, sort, options) {
        return exports.LibraryApiFp(this.configuration).getRootFolderContents(libraryId, page, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets a content array
     * @param {string} libraryId libraryId
     * @param {InlineObject4} inlineObject4
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    searchContent(libraryId, inlineObject4, options) {
        return exports.LibraryApiFp(this.configuration).searchContent(libraryId, inlineObject4, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets an array of contents that match custom_id
     * @param {string} libraryId libraryId
     * @param {InlineObject5} inlineObject5
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    searchContentByCustomId(libraryId, inlineObject5, options) {
        return exports.LibraryApiFp(this.configuration).searchContentByCustomId(libraryId, inlineObject5, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Gets an array of contents that match custom_id
     * @param {string} libraryId libraryId
     * @param {string} type content type
     * @param {string} text custom_id
     * @param {boolean} hotspot search in content.body.contents.hotspots
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    searchContentByTypeAndText(libraryId, type, text, hotspot, options) {
        return exports.LibraryApiFp(this.configuration).searchContentByTypeAndText(libraryId, type, text, hotspot, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update a library
     * @param {string} id Library Id
     * @param {InlineObject3} inlineObject3
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LibraryApi
     */
    updateLibrary(id, inlineObject3, options) {
        return exports.LibraryApiFp(this.configuration).updateLibrary(id, inlineObject3, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.LibraryApi = LibraryApi;
/**
 * UserApi - axios parameter creator
 * @export
 */
exports.UserApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create a user
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: (inlineObject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Delete a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteUser', 'id', id);
            const localVarPath = `/users/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * **TODO**: paginate response.
         * @summary Get users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Get a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('readUser', 'id', id);
            const localVarPath = `/users/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Update a user
         * @param {string} id User Id
         * @param {InlineObject1} inlineObject1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: (id, inlineObject1, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('updateUser', 'id', id);
            // verify required parameter 'inlineObject1' is not null or undefined
            common_1.assertParamExists('updateUser', 'inlineObject1', inlineObject1);
            const localVarPath = `/users/{_id}`
                .replace(`{${"_id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * UserApi - functional programming interface
 * @export
 */
exports.UserApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.UserApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary Create a user
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(inlineObject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createUser(inlineObject, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Delete a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteUser(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * **TODO**: paginate response.
         * @summary Get users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listUsers(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Get a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.readUser(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Update a user
         * @param {string} id User Id
         * @param {InlineObject1} inlineObject1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id, inlineObject1, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.updateUser(id, inlineObject1, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * UserApi - factory interface
 * @export
 */
exports.UserApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.UserApiFp(configuration);
    return {
        /**
         *
         * @summary Create a user
         * @param {InlineObject} [inlineObject]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(inlineObject, options) {
            return localVarFp.createUser(inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Delete a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(id, options) {
            return localVarFp.deleteUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         * **TODO**: paginate response.
         * @summary Get users list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(options) {
            return localVarFp.listUsers(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Get a user
         * @param {string} id User Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readUser(id, options) {
            return localVarFp.readUser(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Update a user
         * @param {string} id User Id
         * @param {InlineObject1} inlineObject1
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(id, inlineObject1, options) {
            return localVarFp.updateUser(id, inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
class UserApi extends base_1.BaseAPI {
    /**
     *
     * @summary Create a user
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    createUser(inlineObject, options) {
        return exports.UserApiFp(this.configuration).createUser(inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Delete a user
     * @param {string} id User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    deleteUser(id, options) {
        return exports.UserApiFp(this.configuration).deleteUser(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * **TODO**: paginate response.
     * @summary Get users list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    listUsers(options) {
        return exports.UserApiFp(this.configuration).listUsers(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Get a user
     * @param {string} id User Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    readUser(id, options) {
        return exports.UserApiFp(this.configuration).readUser(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Update a user
     * @param {string} id User Id
     * @param {InlineObject1} inlineObject1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    updateUser(id, inlineObject1, options) {
        return exports.UserApiFp(this.configuration).updateUser(id, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.UserApi = UserApi;
