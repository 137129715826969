<template>
	<v-menu offset-y class="lp-tree-actions">
		<template v-slot:activator="{ on }" v-if="!isQuizSlides(item)">
			<v-btn flat v-on="on" class="lp-tree-actions-button" @click="lessonPlanController.selectSlideById(item.id)">
				<v-icon>more_vert</v-icon>
			</v-btn>
		</template>

		<!-- Quiz -->
		<v-list v-if="lessonPlanController.isQuiz(item) || lessonPlanController.isLearningObject(item)">
			<tree-actions-common :item="item" />
		</v-list>

		<!-- Section -->
		<v-list v-else-if="lessonPlanController.isSection(item)">
			<!-- Add slide -->
			<v-list-tile @click="lessonPlanController.addSlide(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-shape-rectangle-plus</v-icon>
						<span>Add Slide</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>

			<!-- Duplicate -->
			<v-list-tile @click="lessonPlanController.cloneSection()">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-content-duplicate</v-icon>
						<span>Duplicate</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>

			<!-- Change to slide if no children -->
			<v-list-tile v-if="item.children.length === 0" @click="lessonPlanController.changeToSlide(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-arrow-right-thick</v-icon>
						<span>Change to Slide</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>

			<!-- Change to Quiz if no children -->
			<v-list-tile v-if="item.children.length === 0" @click="lessonPlanController.changeToQuiz(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-book-arrow-right-outline</v-icon>
						<span>Change to Quiz</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>

			<!-- Export section as Learning Object -->
			<v-list-tile @click="exportAsLearningObject(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-folder-arrow-right</v-icon>
						<span>Export as Learning Object</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>

			<tree-actions-common :item="item" />
		</v-list>

		<!-- Slide -->
		<v-list v-else>
			<!-- Duplicate slide -->
			<v-list-tile @click="lessonPlanController.cloneSlide()">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-content-duplicate</v-icon>
						<span>Duplicate</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>
			<!-- Change to section -->
			<v-list-tile @click="lessonPlanController.changeToSection(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-arrow-right-thick</v-icon>
						<span>Change to Section</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>
			<!-- Change to Quiz if no children -->
			<v-list-tile @click="lessonPlanController.changeToQuiz(item)">
				<v-list-tile-title class="body-1">
					<v-layout row align-center>
						<v-icon class="mr-2">mdi-book-arrow-right-outline</v-icon>
						<span>Change to Quiz</span>
					</v-layout>
				</v-list-tile-title>
			</v-list-tile>
			<tree-actions-common :item="item" />
		</v-list>
	</v-menu>
</template>

<script>
import TreeActionsCommon from './TreeActionsCommon';
import CopyItemDialog from '../../../../guideCommon/guide/Editor/CopyItemDialog';
import { PtModal } from 'plant-common';
import PtApiClient from 'plant-api-client';
import { getContentId } from '../../../../../utils/dbTool';
import { mapState } from 'vuex';
import { STATE_PROFILE, STORE_USER_NAME } from '../../../../../store_constants/user';
import { checkSlidesForLearningObject } from './utils';
import ContentDialogSelector from '../../../../ContentDialogSelector.vue';

export default {
	inject: ['lessonPlanController'],
	props: ['item', 'lessonPlanObject'],
	mixins: [PtModal],
	components: { TreeActionsCommon },
	computed: {
		...mapState(STORE_USER_NAME, {
			profile: STATE_PROFILE
		})
	},
	methods: {
		isQuizSlides(slide) {
			const quizSlides = ['MultipleChoiceSlideTemplate', 'ResultsSlideTemplate'];
			return slide.type ? quizSlides.includes(slide.type) : false;
		},

		checkSectionHasLearningObject(section) {
			const learningObjectFlag = checkSlidesForLearningObject(section.children, false);
			if (learningObjectFlag) {
				this.$modal('info', {
					title: `Can not create a new Learning Object from "${section.title}" section`,
					message: `Selected section has one or more Learning Objects inside. You can not create an Interactive Slide with Learning Objects to be imported as Learning Object in current Interactive Presentation.`,
					ok: { text: 'Accept' }
				});
				return true;
			}

			return false;
		},

		async getInstructionalObjective(section) {
			let instructionalObjective = false;

			if (section.objectives?.length) {
				instructionalObjective = section.objectives[0];

				if (section.objectives.length > 1) {
					const objSelected = await this.$modal(ContentDialogSelector, {
						contents: section.objectives,
						info_message:
							"<b>Current section has more than one objective</b>. Please, select the objective you want to associate to new Interactive Slide's Instructional Settings"
					});
					instructionalObjective = objSelected ? objSelected : false;
				}
			}

			return instructionalObjective;
		},

		async exportAsLearningObject(section) {
			const response = await this.$modal(CopyItemDialog, {
				section,
				info_message:
					'This process will create and approve automatically a new Interactive Slide with the data provided'
			});

			if (!response) return false;

			const type = 'interactiveSlide';
			const { title, custom_id } = response;
			let { path_id } = response;
			if (path_id == '$root') path_id = undefined;

			try {
				if (this.checkSectionHasLearningObject(section)) return

				const instructionalObjective = await this.getInstructionalObjective(section);

				// Content creation
				let content = await PtApiClient.createContent(title, custom_id, path_id, type);
				const content_id = getContentId(content.itemRevisionDraft);
				await PtApiClient.updateDraftWorkflow(content_id, 'method', 'changeWorkflow', {
					name: 'no_reviewer',
					lightweight: true
				});
				await PtApiClient.updateDraftWorkflow(content_id, 'method', 'reassignRole', {
					role: 'author',
					user_id: this.profile._id,
					lightweight: true
				});

				// Set Interactive Slide contents
				content.itemRevisionDraft.body.contents.breadcrumb = this.lessonPlanObject.body.contents.breadcrumb;
				content.itemRevisionDraft.body.contents.galleries = [];
				content.itemRevisionDraft.body.contents.layouts = [];
				content.itemRevisionDraft.body.contents.settings = this.lessonPlanObject.body.contents.settings;
				const sectionObject = this.lessonPlanController.getSlideObjectFromId(section.id);
				content.itemRevisionDraft.body.contents.slides = sectionObject.children;
				content.itemRevisionDraft.body.contents.templates = [];
				if (instructionalObjective) {
					content.itemRevisionDraft.body.contents.instructionalSettings = {
						completionTime: 0,
						objective: instructionalObjective
					};
				}

				const draft = await PtApiClient.updateDraftBody(content_id, content.itemRevisionDraft.body);

				// Get section files and copy to new folder
				let draftFiles = [];
				function getDraftFiles(obj) {
					if (obj == null) return obj;
					if (obj._file) {
						draftFiles.push(obj._file);
						return obj;
					} else if (typeof obj == 'object' && obj != null) {
						Object.keys(obj).forEach(k => (obj[k] = getDraftFiles(obj[k])));
						return obj;
					} else return obj;
				}

				getDraftFiles(content.itemRevisionDraft.body);
				const uniqueFiles = draftFiles.filter((value, index, self) => {
					return self.indexOf(value) === index;
				});

				await PtApiClient.copyFiles(this.lessonPlanObject._id, draft._id, uniqueFiles);

				// Approve draft and update section with LearningObjectSlide
				let contentApproved = await PtApiClient.updateDraftWorkflow(content_id, 'transition', 'approveMinorChange');
				Object.assign(contentApproved, {
					$origin: `plant:/content/${contentApproved.header.metadata.content_id}/${contentApproved.header.major_revision}/${contentApproved.header.minor_revision}`
				});
				this.lessonPlanController.addLearningObjectSlide(contentApproved, section.id, sectionObject.blocks);
				this.lessonPlanController.removeSlideById(this.lessonPlanController.slidesTree.slides, section.id, false);
			} catch (error) {
				console.error(error);
			}
		}
	}
};
</script>

<style>
.lp-tree-actions-button.v-btn {
	min-width: 20px;
	padding: 0;
}

.lp-tree-actions-button .v-icon {
	font-size: 18px;
}
</style>
