import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "learningObjective",
    title: "[deprecated] Learning Objective",
    icon: require("./content-icon.svg"),
    category: "other",
    Viewer,
    Editor,
    Preview: Viewer,
    deprecated: true
}