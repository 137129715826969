<template>
  <div class="pl__page-content">
    <div class="pl__page-media">
      <h1 class="pl__page-title">Slide Name: {{ slide.title }}</h1>
      <div class="pl__page-img">
        <slide-preview :slide="slide" :content="content"></slide-preview>
      </div>
    </div>
    <div class="pl__page-body">
      <div class="pl__page-notes">
        <h2>Speaker notes</h2>
        <p v-html="slide.notes.speaker || 'There are no speaker notes'"></p>
        <div class="pl__blur"></div>
      </div>

      <div class="pl__page-notes">
        <h2>Audience notes</h2>
        <p v-html="slide.notes.audience || 'There are no audience notes'"></p>
        <div class="pl__blur"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SlidePreview from "../SlidePreview.vue"
export default {
  name: "Row-Notes",
  props: ["slide", "content"],
  components: { SlidePreview },
  data() {
    return {}
  }
}
</script>

<style scoped>
.pl__page-content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pl__page-media {
  height: 40%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
}

.pl__page-img {
  height: calc(100% - 42px);
  text-align: center;
}

.pl__page-title {
  margin-bottom: 15px;
}

.pl__page-body {
  display: flex;
  flex-direction: column;
  height: 60%;
}

.pl__page-notes {
  height: 50%;
  overflow: hidden;
  position: relative;
}

.pl__blur {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-image: linear-gradient(rgba(250, 250, 250, 0.4), rgba(250, 250, 250, 1));
}

@media print {
  .pl__blur {
    background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgb(255, 255, 255));
  }
}
</style>
