<template>
  <v-dialog v-model="menu" width="500" persistent>
    <template v-slot:activator="{ on }">
      <p-toolbar-button
        :disabled="!selectedBlock"
        :tooltip="selectedBlock ? 'Crop image' : 'Select a block to crop image'"
        icon="mdi-crop"
        v-on="on">
        Crop
      </p-toolbar-button>
    </template>
    <toolbar-dialog-card title="Crop image" @closeMenu="menu = false">
      <vue-cropper
        v-if="menu && value.data.image"
        ref="cropper"
        :src="value.data.image.url"
        class="lp-toolbar-image-crop-block-image"
        @ready="setCropBoxData"></vue-cropper>

      <p class="body-2 mt-4">Aspect ratio:</p>
      <v-btn-toggle v-model="aspectRatio">
        <v-btn>16:9</v-btn>
        <v-btn>4:3</v-btn>
        <v-btn>1:1</v-btn>
        <v-btn>2:3</v-btn>
        <v-btn>Free</v-btn>
      </v-btn-toggle>

      <v-btn block outline color="primary" @click="cropImage()" :loading="loading" class="mt-4">Crop</v-btn>
    </toolbar-dialog-card>
  </v-dialog>
</template>

<script>
import ToolbarDialogCard from "../_components/utils/ToolbarDialogCard.vue"
import { PFile } from "plant-common/src/utils/models"
import { getMaxImageSize } from "./utils.js"
import VueCropper from "vue-cropperjs"
import "cropperjs/dist/cropper.css"
import { PToolbarButton } from "plant-common"
import _ from "lodash"

export default {
  components: {
    ToolbarDialogCard,
    VueCropper,
    PToolbarButton
  },
  props: ["value"],
  inject: ["blockController"],
  data: () => ({
    menu: false,
    loading: false,
    aspectRatio: null,
    selectedBlock: null
  }),
  watch: {
    aspectRatio() {
      const ratio = { 0: 16 / 9, 1: 4 / 3, 2: 1 / 1, 3: 2 / 3, 4: NaN }
      this.$refs.cropper.setAspectRatio(ratio[this.aspectRatio])
    }
  },
  created() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block, state }) => {
        this.selectedBlock = block
      })
    }
  },
  methods: {
    closeMenu() {
      this.menu = false
    },
    async cropImage() {
      this.loading = true
      const dataUrl = this.$refs.cropper.getCroppedCanvas().toDataURL()
      var file = await this.srcToFile(dataUrl, "file.png", "image/jpg")
      var newFile = new PFile(file)
      await newFile.upload()
      this.value.data.cropped = newFile
      this.value.data.cropped_data = this.$refs.cropper.getCropBoxData()
      var size = await getMaxImageSize(this.value.data.cropped.url, this.value.position)
      this.value.size.width = size.width
      this.value.size.height = size.height
      this.loading = false
      this.closeMenu()
    },
    srcToFile(src, fileName, mimeType) {
      return fetch(src)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          return new File([buf], fileName, { type: mimeType })
        })
    },
    setCropBoxData() {
      this.$refs.cropper.setCropBoxData(this.value.data.cropped_data)
    }
  }
}
</script>

<style>
.lp-toolbar-image-crop-block-image {
  display: flex;
  justify-content: center;
  height: 300px;
}
</style>