import { observe, watcher, propertyWatcher } from './reactivity';
import svgDatasetPolyfill from './polyfills/svgDatasetPolyfill';

import svgPanZoom from 'svg-pan-zoom';
import Layer from './classes/Layer';
import highlight from './plugins/highlight';
import select from './plugins/select';

import Hammer from 'hammerjs';

// Support touch events on svg-pan-zoom. from https://github.com/ariutta/svg-pan-zoom/blob/master/demo/mobile.html
var eventsHandler = {
	haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
	init: function (options) {
		var instance = options.instance,
			initialScale = 1,
			pannedX = 0,
			pannedY = 0;

		// Init Hammer
		// Listen only for pointer and touch events
		this.hammer = Hammer(options.svgElement, {
			inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
		});

		// Enable pinch
		this.hammer.get('pinch').set({ enable: true });

		// Handle double tap
		this.hammer.on('doubletap', function () {
			instance.zoomIn();
		});

		// Handle pan
		this.hammer.on('panstart panmove', function (ev) {
			// On pan start reset panned variables
			if (ev.type === 'panstart') {
				pannedX = 0;
				pannedY = 0;
			}

			// Pan only the difference
			instance.panBy({ x: ev.deltaX - pannedX, y: ev.deltaY - pannedY });
			pannedX = ev.deltaX;
			pannedY = ev.deltaY;
		});

		// Handle pinch
		this.hammer.on('pinchstart pinchmove', function (ev) {
			// On pinch start remember initial zoom
			if (ev.type === 'pinchstart') {
				initialScale = instance.getZoom();
				instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y });
			}

			instance.zoomAtPoint(initialScale * ev.scale, { x: ev.center.x, y: ev.center.y });
		});

		// Prevent moving the page on some devices when panning over SVG
		options.svgElement.addEventListener('touchmove', function (e) {
			e.preventDefault();
		});
	},

	destroy: function () {
		this.hammer.destroy();
	}
};

export default class Rho {
	constructor(svgElement, config) {
		this._svg = svgElement;
		this.model = {};
		this.globalEventListener = config.globalEventListener || function () {};
		this.layers = {};
		var functions_to_watch = [];
		// TODO: Move this to a plugin
		this.highlight = selector => highlight(svgElement.getElementsByClassName('svg-pan-zoom_viewport')[0], selector);
		this.select = (selector, callback, mouseEnterCallback) =>
			select(svgElement.getElementsByClassName('svg-pan-zoom_viewport')[0], selector, callback, mouseEnterCallback);
		window.model = this.model;
		// Zoom
		// TODO: move this as a plugin
		this.panZoom = svgPanZoom(svgElement, {
			controlIconsEnabled: false,
			dblClickZoomEnabled: false,
			fit: 1,
			center: 1,
			customEventsHandler: eventsHandler
		});
		// window.onresize = () => {
		//     this.panZoom.resize();
		//     this.panZoom.fit();
		//     this.panZoom.center();
		// }

		// Init Layers
		// TODO: move this as a plugin
		svgElement.querySelectorAll('v\\:layer').forEach(layerTag => {
			if (layerTag.getAttribute('v:name')) {
				this.layers[layerTag.getAttribute('v:name')] = new Layer(layerTag.getAttribute('v:index'), svgElement);
			}
			svgElement.dataset['pt-layer-' + layerTag.getAttribute('v:index')];
		});
		svgElement.querySelectorAll('g[v\\:layermember]').forEach(shapeWithinLayer => {
			var memberOf = shapeWithinLayer.getAttribute('v:layermember').split(';');
			memberOf.forEach(e => shapeWithinLayer.classList.add('pt-layer-' + e));
		});

		// Extract CSS vars from page data fields
		var pageElement = svgElement.querySelector('.pt-group-context__foregroundPage');

		// Set CSS variables
		var styleElement = document.createElement('style');
		styleElement.type = 'text/css';
		let styles = '';
		// add styles for layer opacity
		for (let i = 0; i <= 100; i++) {
			styles += `.pt-layer-${i} {opacity: 1;}\n`;
		}
		styles += `
            g {
                --pt-color-remote-on: ${pageElement.getAttribute('data-ptColorRemoteOn') || '#FF0000'};
                --pt-color-remote-off: ${pageElement.getAttribute('data-ptColorRemoteOff') || '#00FF00'};
                --pt-color-remote-unknown: ${pageElement.getAttribute('data-ptColorRemoteUnknown') || 'fuchsia'};
                --pt-color-remote-transitioning: ${pageElement.getAttribute('data-ptColorRemoteTransitioning') || 'yellow'};
            }
        `;
		styleElement.appendChild(document.createTextNode(styles));
		svgElement.insertBefore(styleElement, svgElement.firstChild);

		// Reactivty
		Object.keys(config.components).forEach(component_name => {
			const this_component = config.components[component_name];
			const instance_selector = '.' + component_name;

			// merge mixins
			if (Object.prototype.hasOwnProperty.call(this_component, 'mixins')) {
				this_component.mixins.forEach(m => merge(this_component, m));
			}

			// Apply setup
			if (this_component.setup) {
				this_component.setup(pageElement);
			}

			svgElement.querySelectorAll(instance_selector).forEach(elm => {
				var f = _addToModel.call(this, elm, this_component);

				Array.prototype.push.apply(functions_to_watch, f);
			});
		});

		functions_to_watch.forEach(f => {
			if (typeof f == 'function') watcher(f);
			else propertyWatcher(f.handler, f.key);
		});
	}
	// static use(plugin) {
	// 	// TODO: Develop a Plugin Architecture
	// }

	replaceState(newState) {
		for (var id in newState) {
			if (Object.prototype.hasOwnProperty.call(this.model, id)) {
				// Set default values to proxy before setting new ones (avoid keeping wrong status when it is not defined in new condition)
				Object.keys(this.model[id]).forEach(value => {
					this.model[id][value] = undefined;
				});
				for (var p in newState[id]) {
					if (Object.prototype.hasOwnProperty.call(this.model[id], p)) {
						this.model[id][p] = newState[id][p];
					} else {
						console.error(`replaceState: ${id}.${p} does not exists`);
					}
				}
			} else {
				// console.error(`replaceState: ${id} does not exists`)
			}
		}
	}
}

/**
 * Initialize a DOM element's reactivity
 * @param {*} elm DOM Element
 * @param {*} component Component definition
 * @returns <Function> An array of functions that Rho shall be watching
 */
function _addToModel(elm, component) {
	//
	var functions_to_watch = [];
	// Capture all properties, static and computed
	var thisModel = {};
	if (component.model) {
		Object.keys(component.model).forEach(prop => (thisModel[prop] = component.model[prop].default));
	}
	if (component.computed) {
		Object.keys(component.computed).forEach(prop => (thisModel[prop] = undefined));
	}

	if (component.hooks && component.hooks.beforeMount) {
		try {
			component.hooks.beforeMount(elm);
		} catch (e) {
			console.error('[RHO] Before Mount error', elm, e);
		}
	}

	// Add observable object to the model
	this.model[elm.id] = observe(thisModel, { $model: this.model, $el: elm });

	// Add computed properties
	if (component.computed) {
		Object.keys(component.computed).forEach(prop => {
			functions_to_watch.push(() => {
				var inputs = (elm.dataset.inputs || '').split(',').reduce((prev, input) => {
					var [inputName, from_id, prop] = input.split(/=|:/g);
					Object.defineProperty(prev, inputName, {
						get: () => this.model[from_id][prop]
					});
					return prev;
				}, {});

				var v = component.computed[prop].call({ $model: this.model, $el: elm, inputs });
				this.model[elm.id][prop] = v;
			});
		});
	}

	// Add watchers
	if (component.watch) {
		Object.keys(component.watch).forEach(prop => {
			functions_to_watch.push({
				key: prop,
				handler: () => component.watch[prop].call({ $model: this.model, $el: elm, data: this.model[elm.id] })
			});
		});
	}

	Object.keys(this.model[elm.id]).forEach(prop => {
		// Default Binding model value to DOM
		functions_to_watch.push(() => elm.setAttribute(`data-${prop}`, this.model[elm.id][prop]));
		// Custom Binding model value to DOM if bind is a function
		if (component.model && component.model[prop] && typeof component.model[prop].bind === 'function') {
			functions_to_watch.push(() => component.model[prop].bind(elm, prop, this.model[elm.id][prop]));
		}
	});

	//Bind all events
	if (component.listeners) {
		var Rho = this;
		Object.keys(component.listeners).forEach(e => {
			elm.addEventListener(e, function (evt) {
				evt.preventDefault();
				var elm = this;
				svgDatasetPolyfill(elm);
				if (typeof component.listeners[e] == 'function') {
					var propagate_event = component.listeners[e].call(
						{ $model: Rho.model, $el: elm, data: Rho.model[elm.id] },
						evt
					);
					if (propagate_event) Rho.globalEventListener(evt, component, Rho.model);
				} else {
					Rho.globalEventListener(evt, component, Rho.model);
				}
			});
		});
	}
	if (component.options) {
		Object.keys(component.options).forEach(e => {
			elm.setAttribute(`data-${e}`, component.options[e]);
		});
	}

	return functions_to_watch;
}

// Merge a `source` object to a `target` recursively
// from https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6
const merge = (target, source) => {
	// Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object) {
			if (!Object.prototype.hasOwnProperty.call(target, key)) target[key] = {};
			Object.assign(source[key], merge(target[key], source[key]));
		}
	}

	// Join `target` and modified `source`
	Object.assign(target || {}, source);
	return target;
};
