<template>
  <v-stepper-content step="3">
    <v-progress-linear v-if="loader" :indeterminate="true"></v-progress-linear>
    <v-card v-else>
      <v-layout>
        <v-flex xs5>
          <v-img :src="require('./step-3.svg')" height="225px" contain></v-img>
        </v-flex>
        <v-flex xs7>
          <v-card-title primary-title>
            <div>
              <h2 class="headline mb-0">Well done!</h2>
              <p class="subheading">
                Your "Guide" is now an "Interactive Presentation"! To start using the new features, click "Save".
              </p>
            </div>
          </v-card-title>
        </v-flex>
      </v-layout>
      <v-card-actions>
        <v-btn block color="#48C634" dark @click="save">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-stepper-content>
</template>

<script>
import { mapActions } from "vuex"
import {
  STORE_MODULE_EDITOR,
  ACTION_UPLOAD_DRAFT,
  ACTION_SET_DRAFT,
  ACTION_GUIDE_2_INTERACTIVE_PRESENTATION_TRANSITION
} from "../../../../../store_constants/content"

export default {
  props: ["step", "loader", "template"],
  methods: {
    ...mapActions(STORE_MODULE_EDITOR, {
      uploadItemDraft: ACTION_UPLOAD_DRAFT,
      setItemDraft: ACTION_SET_DRAFT,
      guideToIPTransition: ACTION_GUIDE_2_INTERACTIVE_PRESENTATION_TRANSITION
    }),
    async save() {
      // call ptapiclient to copy this.template.reference._id content folder to current Interactive presentation _id folder
      var copyFinished = await this.guideToIPTransition(this.template)
      this.uploadItemDraft().then(itemDraftUpdated => {
        this.setItemDraft(itemDraftUpdated)
        location.reload()
      })
    }
  }
}
</script>