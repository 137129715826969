<template>
  <node-view-wrapper :class="{ 'it-component__wrapper': isEditable }" :origin="node.attrs.origin">
    <component :is="component"></component>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-2"
import { getType, getTitle, getCustomId } from "../../../../utils/dbTool"
import LibraryContent from "./LibraryContentInside.vue"
import LibraryContentEdit from "./LibraryContentInsideEdit.vue"
import uuid from "../../../../utils/uuid"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { renderKatex } from "plant-common"

export default {
  name: "InteractiveTextComponent",
  components: {
    NodeViewWrapper
  },
  props: nodeViewProps,
  inject: ["controller"],
  data() {
    return {
      content: "<div>Loading...</div>",
      interactiveText: null
    }
  },
  computed: {
    isEditable() {
      return this.editor.options.editable
    },
    component() {
      return {
        template: this.content,
        components: {
          PlantLibraryComponent: this.isEditable ? LibraryContentEdit : LibraryContent
        },
        mounted() {
          this.renderFormulas()
        },
        methods: {
          renderFormulas() {
            const previewDiv = this.$refs.it_component
            if (previewDiv) {
              renderKatex(previewDiv)
            }
          }
        }
      }
    }
  },
  async created() {
    if (this.node.attrs.origin) {
      this.interactiveText = await PServiceContent.getContentFromRef(this.node.attrs.origin, this.controller.page)
      if (this.interactiveText.body.contents.page.html && this.interactiveText.body.contents.page.html.url) {
        const res = await fetch(this.interactiveText.body.contents.page.html.url)
        const contentRes = res.ok ? await res.text() : ""
        const headerFixContentRes = addIdAttributeToHeaders(contentRes)
        this.content = contentRes && `<div ref="it_component">${headerFixContentRes}</div>`
      }
    }
  },
  updated() {    
    const elements = document.querySelectorAll('.ProseMirror p');

    elements.forEach((el) => {
      const trailingBreakEl = this.createTrailingBreakEl();

      const hasTrailingBreakEl = el.querySelector('br.ProseMirror-trailingBreak');

      if (el.textContent.trim() === '' && !hasTrailingBreakEl) {
        el.appendChild(trailingBreakEl);
      }
    });
  },
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType,
    createTrailingBreakEl() {
      const trailingBreak = document.createElement('br');
      trailingBreak.className = 'ProseMirror-trailingBreak';

      return trailingBreak;
    }
  }
}

function addIdAttributeToHeaders(content) {
  let newContent = addIndividualIdsToHTML(content)
  if (newContent === content) {
    return newContent
  } else {
    return addIdAttributeToHeaders(newContent)
  }
}

function addIndividualIdsToHTML(content) {
  return content
    .replace("<h1>", `<h1 id="${uuid()}">`)
    .replace("<h2>", `<h2 id="${uuid()}">`)
    .replace("<h3>", `<h3 id="${uuid()}">`)
}
</script>

<style scoped>
.it-component__wrapper {
  border: 1px solid lightgrey;
}
</style>
