<template>
  <div style="margin-left: 350px; width: calc(100% - 350px)">
    <v-toolbar flat color="white">
      <v-toolbar-title>Files to change</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-data-table
      hide-actions
      :headers="headers"
      :items="contents"
      class="elevation-0"
      v-if="contents.length > 0"
      style="position: absolute; top: 120px; left: 350px; right: 0px; bottom: 0px; overflow: auto">
      <template v-slot:items="props">
        <td>
          <v-icon v-if="props.item.status == 'not-started'" color="primary">mdi-circle-outline</v-icon>
          <v-icon v-else-if="props.item.status == 'in-progress'" color="orange">mdi-progress-clock</v-icon>
          <v-icon v-else-if="props.item.status == 'done'" color="green">mdi-check-circle</v-icon>
          <v-tooltip bottom v-else-if="props.item.status == 'error'">
            <template v-slot:activator="{ on }">
              <v-icon color="red" v-on="on">mdi-circle</v-icon>
            </template>
            <span>{{ props.item.error }}</span>
          </v-tooltip>
        </td>
        <td>
          {{ props.item.learningObjectiveCustomId }}
        </td>
        <td>
          {{ props.item.destinationFolderId || "ROOT" }}
        </td>
      
        <td class="justify-center layout px-0">
          <v-btn
            color="red"
            icon
            flat
            @click.stop="
              contents.splice(
                contents.findIndex(e => e == props.item),
                1
              )
            ">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["contents"],
  computed: {
    headers() {
      var headers = [
        {
          text: "",
          value: "",
          sortable: false,
          width: "40px"
        },
        {
          text: "Learning Objective Custom Id",
          value: "learningObjectiveCustomId",
          sortable: true,
          width: "200px"
        },
        {
          text: "Destination Folder Id",
          value: "destinationFolderId",
          sortable: true,
          width: "200px"
        },
        {
          text: "",
          value: "",
          sortable: false,
          width: "40px"
        }
      ]
      return headers
    }
  }
}
</script>

<style scoped>
.hw-100 {
  height: 100%;
  width: 100%;
}
</style>
