<template>
  <content-activity :value="itemRevision" />
</template>
<script>
import ContentActivity from "./index.vue"
import { mapState, mapActions } from "vuex"
import { STORE_CONTENT_NAME, STATE_ITEM_REVISION, ACTION_LOAD_CONTENT } from "@/store_constants/content"
export default {
  name: "MajorMinorPreviewActivity",
  props: ["id"],
  components: { ContentActivity },
  data() {
    return {
      major_revision: this.$attrs.major_revision,
      minor_revision: this.$attrs.minor_revision
    }
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_CONTENT])
  },
  created() {
    this[ACTION_LOAD_CONTENT]({ id: this.id, major_revision: this.major_revision, minor_revision: this.minor_revision })
  }
}
</script>
<style scoped>
</style>