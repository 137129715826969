<template>
  <div class="lp-animation-form-animation">
    <v-tabs v-model="animationIndex" centered grow>
      <v-tab href="#entrance">
        <v-icon left>mdi-login</v-icon>
        Entrance
      </v-tab>
      <v-tab href="#exit">
        <v-icon left>mdi-logout</v-icon>
        Exit
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="animationIndex">
      <v-tab-item
        v-for="animationType in tabOptions"
        :value="animationType.title"
        :key="animationType.title"
        :title="animationType.title"
        class="lp-animation-form-tab">
        <v-list two-line>
          <v-list-tile class="lp-animation-form-item">
            <v-list-tile-content>
              <v-list-tile-title>{{ animationType.options[0].title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn-toggle style="box-shadow: none" class="pt-button-group" v-model="effect">
                <v-btn
                  v-for="action in animationType.options[0].actions"
                  class="lp-block-properties-size-position-button"
                  @click="action.onClick"
                  :value="action.value"
                  :disabled="action.disabled"
                  flat
                  :key="action.title">
                  <span v-if="action.icon" v-html="action.icon"></span>
                  <p>{{ action.title }}</p>
                </v-btn>
              </v-btn-toggle>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile class="lp-animation-form-item">
            <v-list-tile-content>
              <v-list-tile-title>{{ animationType.options[1].title }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn-toggle style="box-shadow: none" class="pt-button-group" v-model="direction">
                <span v-for="action in animationType.options[1].actions" :key="action.title">
                  <v-btn
                    v-if="action.display"
                    class="lp-block-properties-size-position-button"
                    @click="action.onClick"
                    :value="action.title"
                    :disabled="action.disabled"
                    flat>
                    <v-icon v-if="action.mdi">{{ action.mdi }}</v-icon>
                    <p>{{ action.title }}</p>
                  </v-btn>
                </span>
              </v-btn-toggle>
            </v-list-tile-action>
          </v-list-tile>

          <item-style
            icon="mdi-timer"
            title="Duration"
            class="lp-animation-form-item"
            v-if="value.animations[getPositionIndex].name !== 'none'">
            <p-panel-input-number v-model="value.animations[getPositionIndex].duration" :min="0" />
          </item-style>

          <item-style
            icon="mdi-alarm-snooze"
            title="Delay (ms)"
            class="lp-animation-form-item"
            v-if="value.animations[getPositionIndex].name !== 'none'">
            <p-panel-input-number
              :value="Math.round(delay * 1000)"
              @input="value.animations[getPositionIndex].delay = $event / 1000"
              :min="0" />
          </item-style>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ItemStyle from "../../../components/slides/styles/ItemStyle.vue"
import * as models from "../../_models/models.js"
import PPanelInputNumber from "../utils/PPanelInputNumber.vue"
export default {
  components: { ItemStyle, PPanelInputNumber },
  props: ["value"],
  data: () => ({
    animationIndex: "entrance",
    effect: "none",
    direction: "none"
  }),
  computed: {
    delay() {
      return this.value.animations[this.getPositionIndex].delay
    },
    hasAnimation() {
      return this.value.animations && this.value.animations.length > 0
    },
    tabOptions() {
      return [
        { title: "entrance", options: this.animationOptionsIn },
        { title: "exit", options: this.animationOptionsOut }
      ]
    },
    animationOptionsIn() {
      return [this.effectOptionsIn, this.directionOptions]
    },
    animationOptionsOut() {
      return [this.effectOptionsOut, this.directionOptions]
    },
    effectOptionsIn() {
      return {
        title: "Effect",
        actions: [
          {
            display: true,
            title: "None",
            icon: `
                <svg width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29 1.61804L35.2599 20.884L35.3722 21.2295H35.7354H55.9929L39.6042 33.1365L39.3104 33.35L39.4226 33.6955L45.6825 52.9615L29.2939 41.0545L29 40.8409L28.7061 41.0545L12.3175 52.9615L18.5774 33.6955L18.6896 33.35L18.3958 33.1365L2.00715 21.2295H22.2646H22.6279L22.7401 20.884L29 1.61804Z" fill="#C387DD" stroke="#662483"/>
                </svg>
              `,
            value: "none",
            onClick: () => {
              this.setAnimation("name", "none")
            }
          },
          {
            display: true,
            title: "Fade in",
            icon: `
                <svg width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29 1.61804L35.2599 20.884L35.3722 21.2295H35.7354H55.9929L39.6042 33.1365L39.3104 33.35L39.4226 33.6955L45.6825 52.9615L29.2939 41.0545L29 40.8409L28.7061 41.0545L12.3175 52.9615L18.5774 33.6955L18.6896 33.35L18.3958 33.1365L2.00715 21.2295H22.2646H22.6279L22.7401 20.884L29 1.61804Z" fill="url(#paint0_radial_1417_376)" stroke="#662483"/>
                  <defs>
                    <radialGradient id="paint0_radial_1417_376" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29 30) rotate(-90) scale(17)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                    </radialGradient>
                  </defs>
                </svg>
              `,
            value: "fadeIn",
            onClick: () => {
              this.setAnimation("name", "fadeIn")
            }
          },
          {
            display: true,
            title: "Back in",
            icon: `
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 1.61804L36.2599 20.884L36.3722 21.2295H36.7354H56.9929L40.6042 33.1365L40.3104 33.35L40.4226 33.6955L46.6825 52.9615L30.2939 41.0545L30 40.8409L29.7061 41.0545L13.3175 52.9615L19.5774 33.6955L19.6896 33.35L19.3958 33.1365L3.00715 21.2295H23.2646H23.6279L23.7401 20.884L30 1.61804Z" fill="#C387DD" stroke="#662483"/>
                  <rect x="29" y="46" width="2" height="12" fill="#7F27AD"/>
                  <rect x="37" y="51" width="2" height="7" fill="#7F27AD"/>
                  <rect x="21" y="51" width="2" height="7" fill="#7F27AD"/>
                </svg>
              `,
            value: "backIn",
            onClick: () => {
              this.setAnimation("name", "backIn")
            }
          },
          {
            display: true,
            title: "Slide in",
            icon: `
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 1.61804L36.2599 20.884L36.3722 21.2295H36.7354H56.9929L40.6042 33.1365L40.3104 33.35L40.4226 33.6955L46.6825 52.9615L30.2939 41.0545L30 40.8409L29.7061 41.0545L13.3175 52.9615L19.5774 33.6955L19.6896 33.35L19.3958 33.1365L3.00715 21.2295H23.2646H23.6279L23.7401 20.884L30 1.61804Z" fill="#C387DD" fill-opacity="0.41" stroke="#662483"/>
                  <rect x="29" y="46" width="2" height="12" fill="#7F27AD"/>
                  <rect x="37" y="51" width="2" height="7" fill="#7F27AD"/>
                  <rect x="21" y="51" width="2" height="7" fill="#7F27AD"/>
                  <path d="M30 10.809L34.2525 23.8969L34.3086 24.0697H34.4903H48.2517L37.1185 32.1584L36.9715 32.2652L37.0277 32.4379L41.2802 45.5258L30.1469 37.4371L30 37.3303L29.8531 37.4371L18.7198 45.5258L22.9723 32.4379L23.0285 32.2652L22.8815 32.1584L11.7483 24.0697H25.5097H25.6914L25.7475 23.8969L30 10.809Z" fill="url(#paint0_radial_1472_374)" stroke="#662483" stroke-width="0.5"/>
                  <path d="M30 20.809L32.0074 26.9871L32.0635 27.1598H32.2451H38.7411L33.4858 30.9781L33.3388 31.0848L33.3949 31.2576L35.4023 37.4357L30.1469 33.6174L30 33.5106L29.8531 33.6174L24.5977 37.4357L26.6051 31.2576L26.6612 31.0848L26.5142 30.9781L21.2589 27.1598H27.7549H27.9365L27.9926 26.9871L30 20.809Z" fill="url(#paint1_radial_1472_374)" stroke="#662483" stroke-width="0.5"/>
                  <defs>
                    <radialGradient id="paint0_radial_1472_374" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30 30) rotate(-90) scale(11.3333)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                      </radialGradient>
                      <radialGradient id="paint1_radial_1472_374" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30 30) rotate(-90) scale(5.66667)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                    </radialGradient>
                  </defs>
                </svg>
              `,
            value: "slideIn",
            onClick: () => {
              this.setAnimation("name", "slideIn")
            }
          }
        ]
      }
    },
    effectOptionsOut() {
      return {
        title: "Effect",
        actions: [
          {
            display: true,
            title: "None",
            icon: `
                <svg width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29 1.61804L35.2599 20.884L35.3722 21.2295H35.7354H55.9929L39.6042 33.1365L39.3104 33.35L39.4226 33.6955L45.6825 52.9615L29.2939 41.0545L29 40.8409L28.7061 41.0545L12.3175 52.9615L18.5774 33.6955L18.6896 33.35L18.3958 33.1365L2.00715 21.2295H22.2646H22.6279L22.7401 20.884L29 1.61804Z" fill="#C387DD" stroke="#662483"/>
                </svg>
              `,
            value: "none",
            onClick: () => {
              this.setAnimation("name", "none")
            }
          },
          {
            display: true,
            title: "Fade out",
            icon: `
                <svg width="58" height="55" viewBox="0 0 58 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29 1.61804L35.2599 20.884L35.3722 21.2295H35.7354H55.9929L39.6042 33.1365L39.3104 33.35L39.4226 33.6955L45.6825 52.9615L29.2939 41.0545L29 40.8409L28.7061 41.0545L12.3175 52.9615L18.5774 33.6955L18.6896 33.35L18.3958 33.1365L2.00715 21.2295H22.2646H22.6279L22.7401 20.884L29 1.61804Z" fill="url(#paint0_radial_1417_376)" stroke="#662483"/>
                  <defs>
                    <radialGradient id="paint0_radial_1417_376" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(29 30) rotate(-90) scale(17)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                    </radialGradient>
                  </defs>
                </svg>
              `,
            value: "fadeOut",
            onClick: () => {
              this.setAnimation("name", "fadeOut")
            }
          },
          {
            display: true,
            title: "Back out",
            icon: `
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 1.61804L36.2599 20.884L36.3722 21.2295H36.7354H56.9929L40.6042 33.1365L40.3104 33.35L40.4226 33.6955L46.6825 52.9615L30.2939 41.0545L30 40.8409L29.7061 41.0545L13.3175 52.9615L19.5774 33.6955L19.6896 33.35L19.3958 33.1365L3.00715 21.2295H23.2646H23.6279L23.7401 20.884L30 1.61804Z" fill="#C387DD" stroke="#662483"/>
                  <rect x="29" y="46" width="2" height="12" fill="#7F27AD"/>
                  <rect x="37" y="51" width="2" height="7" fill="#7F27AD"/>
                  <rect x="21" y="51" width="2" height="7" fill="#7F27AD"/>
                </svg>
              `,
            value: "backOut",
            onClick: () => {
              this.setAnimation("name", "backOut")
            }
          },
          {
            display: true,
            title: "Slide out",
            icon: `
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M30 1.61804L36.2599 20.884L36.3722 21.2295H36.7354H56.9929L40.6042 33.1365L40.3104 33.35L40.4226 33.6955L46.6825 52.9615L30.2939 41.0545L30 40.8409L29.7061 41.0545L13.3175 52.9615L19.5774 33.6955L19.6896 33.35L19.3958 33.1365L3.00715 21.2295H23.2646H23.6279L23.7401 20.884L30 1.61804Z" fill="#C387DD" fill-opacity="0.41" stroke="#662483"/>
                  <rect x="29" y="46" width="2" height="12" fill="#7F27AD"/>
                  <rect x="37" y="51" width="2" height="7" fill="#7F27AD"/>
                  <rect x="21" y="51" width="2" height="7" fill="#7F27AD"/>
                  <path d="M30 10.809L34.2525 23.8969L34.3086 24.0697H34.4903H48.2517L37.1185 32.1584L36.9715 32.2652L37.0277 32.4379L41.2802 45.5258L30.1469 37.4371L30 37.3303L29.8531 37.4371L18.7198 45.5258L22.9723 32.4379L23.0285 32.2652L22.8815 32.1584L11.7483 24.0697H25.5097H25.6914L25.7475 23.8969L30 10.809Z" fill="url(#paint0_radial_1472_374)" stroke="#662483" stroke-width="0.5"/>
                  <path d="M30 20.809L32.0074 26.9871L32.0635 27.1598H32.2451H38.7411L33.4858 30.9781L33.3388 31.0848L33.3949 31.2576L35.4023 37.4357L30.1469 33.6174L30 33.5106L29.8531 33.6174L24.5977 37.4357L26.6051 31.2576L26.6612 31.0848L26.5142 30.9781L21.2589 27.1598H27.7549H27.9365L27.9926 26.9871L30 20.809Z" fill="url(#paint1_radial_1472_374)" stroke="#662483" stroke-width="0.5"/>
                  <defs>
                    <radialGradient id="paint0_radial_1472_374" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30 30) rotate(-90) scale(11.3333)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                      </radialGradient>
                      <radialGradient id="paint1_radial_1472_374" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(30 30) rotate(-90) scale(5.66667)">
                      <stop stop-color="#C387DD"/>
                      <stop offset="1" stop-color="#C387DD" stop-opacity="0"/>
                    </radialGradient>
                  </defs>
                </svg>
              `,
            value: "slideOut",
            onClick: () => {
              this.setAnimation("name", "slideOut")
            }
          }
        ]
      }
    },
    directionOptions() {
      return {
        title: "Direction",
        actions: [
          {
            display: this.hasDirectionNone(),
            title: "None",
            mdi: "mdi-circle",
            onClick: () => {
              this.removeDirectionToName()
            }
          },
          {
            display: this.hasDirections(),
            title: "Left",
            mdi: "mdi-arrow-left",
            onClick: () => {
              this.removeDirectionToName()
              this.value.animations[this.getPositionIndex].name += "Left"
            }
          },
          {
            display: this.hasDirections(),
            title: "Right",
            mdi: "mdi-arrow-right",
            onClick: () => {
              this.removeDirectionToName()
              this.value.animations[this.getPositionIndex].name += "Right"
            }
          },
          {
            display: this.hasDirections(),
            title: "Up",
            mdi: "mdi-arrow-up",
            onClick: () => {
              this.removeDirectionToName()
              this.value.animations[this.getPositionIndex].name += "Up"
            }
          },
          {
            display: this.hasDirections(),
            title: "Down",
            mdi: "mdi-arrow-down",
            onClick: () => {
              this.removeDirectionToName()
              this.value.animations[this.getPositionIndex].name += "Down"
            }
          }
        ]
      }
    },
    getPositionIndex() {
      if (this.animationIndex === "entrance") {
        return 0
      } /* if (this.animationIndex === "exit") */
      return 1
    },
    selectedAnimation() {
      return this.value.animations[this.getPositionIndex]
    }
  },
  methods: {
    hasDirections() {
      return true
    },
    hasDirectionNone() {
      const animationWithNoNone = ["backIn", "backOut", "slideIn", "slideOut"]
      return !animationWithNoNone.includes(this.effect)
    },
    removeDirectionToName() {
      const newName = this.value.animations[this.getPositionIndex].name.replace(/Left|Right|Up|Down/g, "").trim()
      this.value.animations[this.getPositionIndex].name = newName
    },
    setAnimation(property = "name", value = "none") {
      if (this.value.animations.length == 1) {
        this.value.animations.push(new models.Animation())
      }
      this.value.animations[this.getPositionIndex][property] = value
    }
  },
  mounted() {
    // for old blocks created before changing model
    // to have 2 animation from the beginning
    if (!this.hasAnimation) {
      this.value.animations.push(new models.Animation())
    }
    if (this.value.animations.length == 1) {
      this.value.animations.push(new models.Animation())
    }
  },
  watch: {
    selectedAnimation: {
      immediate: true,
      deep: true,
      handler() {
        // set up model for animation form
        const newName = this.value.animations[this.getPositionIndex].name.replace(/Left|Right|Up|Down/g, "").trim()
        this.effect = newName
        const match = this.value.animations[this.getPositionIndex].name.match(/Left|Right|Up|Down/g)
        this.direction = match && match.length > 0 ? match[0] : "None"
      }
    }
  }
}
</script>

<style>
.lp-animation-form-tab {
  padding-top: 8px;
}
.lp-animation-form-item .v-list__tile {
  padding: 0 24px;
}
.lp-animation-form-item .v-list__tile__avatar {
  display: none;
}
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
.lp-block-properties-size-position-button .v-btn__content {
  padding: 0;
  flex-direction: column;
}
.lp-block-properties-size-position-button i,
.lp-block-properties-size-position-button span,
.lp-block-properties-size-position-button span svg {
  height: 24px;
  width: 24px;
}
.lp-block-properties-size-position-button p {
  margin: 0;
  font-size: 10px;
  font-weight: bold;
}
</style>
