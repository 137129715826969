<template>
	<fragment>
		<p-toolbar-action icon="mdi-dog" tooltip="Woff Woff!!" />
	</fragment>
</template>

<script>
import { PToolbarAction } from "plant-common";
import { Fragment } from "vue-fragment"
export default {
	props: ["value"],
	components: {
		Fragment,
		PToolbarAction
	},

}
</script>
