<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <v-breadcrumbs :items="[0]" class="lp-block-breadcrumb" :style="getStyle">
        <template v-slot:divider>
          <v-icon>{{ value.data.style.divider.icon }}</v-icon>
        </template>
        <v-breadcrumbs-item @click="$emit('selectSlideById', breadcrumbHome)">
          <v-icon
            v-if="value.data.style.home.icon != 'none'"
            class="lp-block-breadcrumb-title"
            :class="{ 'lp-breadcrumb-margin-right': homeHasText }">
            {{ value.data.style.home.icon }}
          </v-icon>
          {{ value.data.style.home.text }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-item
          v-for="(item, index) in breadcrumbFields"
          :key="index"
          :disabled="index > breadcrumbFields.length - (isHideLastPathSection ? 1 : 2)"
          class="lp-block-breadcrumb-title"
          @click="$emit('selectSlideById', item.id)"
          >{{ item.title }}</v-breadcrumbs-item
        >
      </v-breadcrumbs>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"

export default {
  name: "BlockBreadcrumb",
  inject: ["blockController", "lessonPlanController"],
  components: { BasicBlock },
  props: ["value", "mode"],
  computed: {
    homeHasText() {
      return this.value.data.style.home.text != ""
    },
    breadcrumbFields() {
      if (!this.lessonPlanController.fields) return null
      const fields = this.lessonPlanController.fields.find(field => field.hasOwnProperty("breadcrumb"))
      let path = fields ? [...fields.breadcrumb] : null
      if (path && this.isHideLastPathSection) path.pop()
      if (this.hideHiddenSections) path = path.filter(p => p.hidden !== true)

      return path
    },
    breadcrumbHome() {
      const navigationSetting = this.lessonPlanController.lessonPlanContents.settings.styles.navigation
      const id =
        navigationSetting && navigationSetting.home && navigationSetting.home.slide_id
          ? navigationSetting.home.slide_id
          : this.lessonPlanController.slidesTree.slides[0]
          ? this.lessonPlanController.slidesTree.slides[0].id
          : undefined
      return id
    },
    getStyle() {
      return {
        fontFamily: this.value.data.style.normal.fontFamily || "inherit",
        "--font-size": `${this.value.data.style.normal.fontSize}px`,
        "--padding": `${this.value.data.style.normal.spaceBetween}px`,
        "--margin-home": `${this.value.data.style.home.spaceBetween}px`,
        "--color-link": this.value.data.style.link.color,
        "--color-home-icon": this.value.data.style.home.iconColor,
        "--color-home-text": this.value.data.style.home.color,
        "--color-disabled": this.value.data.style.normal.color,
        "--color-divider": this.value.data.style.divider.color
      }
    },
    isHideLastPathSection() {
      return this.value.data.hideLastPathSection
    },
    hideHiddenSections() {
      return !this.value.data.showHiddenSections
    }
  },
  created() {
    if (!this.value.data.style) {
      this.$set(this.value.data, "style", {
        normal: {
          fontFamily: false,
          fontSize: 34,
          spaceBetween: 20,
          color: "#000101"
        },
        link: {
          color: "#1976d2"
        },
        home: {
          spaceBetween: 8,
          icon: "mdi-home",
          iconColor: "#000101",
          text: "",
          color: "#000101"
        },
        divider: {
          icon: "mdi-slash-forward",
          color: "#000101"
        }
      })
    }

    if (!this.value.data.hasOwnProperty("hideLastPathSection")) {
      this.$set(this.value.data, "hideLastPathSection", true)
    }
    if (!this.value.data.hasOwnProperty("showHiddenSections")) {
      this.$set(this.value.data, "showHiddenSections", false)
    }
  }
}
</script>

<style>
.lp-breadcrumb-margin-right {
  margin-right: var(--margin-home) !important;
}
.v-breadcrumbs__divider {
  padding: 0 var(--padding) !important;
}
.lp-block-breadcrumb-title,
.lp-block-breadcrumb .v-breadcrumbs__divider {
  font-size: var(--font-size) !important;
}
.lp-block-breadcrumb .v-breadcrumbs__divider .v-icon {
  color: var(--color-divider) !important;
}
.v-breadcrumbs__item {
  color: var(--color-link) !important;
}
.v-breadcrumbs__item--disabled {
  color: var(--color-disabled) !important;
}
.v-breadcrumbs li:first-child a,
.v-breadcrumbs .v-icon {
  font-size: var(--font-size) !important;
}
.v-breadcrumbs li:first-child a {
  color: var(--color-home-text) !important;
}
.v-breadcrumbs li:first-child .v-icon {
  color: var(--color-home-icon) !important;
}
</style>
