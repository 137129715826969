<template>
  <div class="pa-3">
    <h2>Dependents</h2>
    <pre>{{ content_name.itemRevision.header.metadata.title }}</pre>
    <pre>Show dependents of current content</pre>
  </div>
</template>

<script>
import { STORE_CONTENT_NAME, STATE_ITEM_REVISION } from "@/store_constants/content"

import { mapState } from "vuex"

export default {
  computed: {
    ...mapState({ content_name: STORE_CONTENT_NAME })
  }
}
</script>