<template>
  <transition name="fade" appear>
    <v-app>
      <desktop-system-bar />
      <router-view></router-view>
      <app-user-message />
    </v-app>
  </transition>
</template>
<script>
import DesktopSystemBar from "@/components/app/AppSystemBar"
import AppUserMessage from "@/components/app/AppUserMessage"
export default {
  components: {
    DesktopSystemBar,
    AppUserMessage
  }
}
</script>
<style lang="css">
.fade-enter-active,
.fade-leave-active {
  transition-duration: 1s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
html {
  overflow-y: auto;
}
</style>