<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <portal :to="`contentFlashcardQuestion-toolbar-${value.id}`" v-if="mode == 'edit'">
        <block-toolbar-button
          icon="mdi-arrow-expand-right"
          tooltip="Create minor version"
          @click="createMinorVersion"
          v-if="isContentSelected"
          :dark="blockController.dark" />
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-image-off-outline"
          tooltip="Change Content"
          @click="changeContent"
          :disabled="changeContentProtection">
          Change Content
        </p-toolbar-button>
        <p-toolbar-button
          v-if="isContentSelected && !blockController.overlay"
          icon="mdi-restore"
          tooltip="Reset to default styles"
          @click="resetToDefaultStyles"
          :disabled="changeContentProtection">
          Reset to default styles
        </p-toolbar-button>
        <p-toolbar-menu v-if="isContentSelected" icon="mdi-layers-outline" title="Layers" tooltip="Display layers">
          <v-list dense>
            <v-list-tile @click="displayQuestion()">
              <v-list-tile-action>
                <v-icon>mdi-border-all-variant</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Question</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile @click="displayOverlay('answer')">
              <v-list-tile-action>
                <v-icon>mdi-arrange-send-backward</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Answer</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </p-toolbar-menu>
      </portal>
      <template v-if="mode == 'edit'">
        <div class="lp-block-library-content">
          <v-btn color="primary" @click="addFromContentManager" v-if="!isContentSelected" large outline
            >Add from Content Manager
          </v-btn>
          <template v-else>
            <block-subcanvas :value="value" :blocks="value.data.blocks" :mode="mode" :blockVariables="blockVariables" />
          </template>
        </div>
      </template>
      <template v-else>
        <block-subcanvas :value="value" :blocks="value.data.blocks" mode="view" :blockVariables="blockVariables" />
      </template>
    </template>
  </basic-block>
</template>

<script>
import { PToolbarButton, PToolbarMenu } from "plant-common"
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockSubcanvas from "../_components/containers/BlockSubcanvas.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import blockOverlayCommon from "../../blocks/_mixins/blockOverlayCommon.vue"
import BlockToolbarButton from "../button/BlockToolbarButton.vue"
import _ from "lodash"

export default {
  name: "BlockFlashCard",
  inject: ["blockEventBus", "blockController"],
  mixins: [blockOverlayCommon],
  props: ["value", "mode"],
  components: {
    PToolbarButton,
    PToolbarMenu,
    BasicBlock,
    BlockSubcanvas,
    BlockToolbarButton
  },
  data() {
    return {
      blockVariables: false
    }
  },
  computed: {
    isContentSelected() {
      return Object.keys(this.value.data.content_ref).length > 0
    },
    answerOverlay() {
      return this.value.data.overlays.answer
    },
    changeContentProtection() {
      return this.value.protection.data
    }
  },
  created() {
    if (this.isContentSelected) this.addVariables()
    if (this.blockEventBus) this.blockEventBus.$on("submit", this.submitted)
  },
  methods: {
    async addFromContentManager() {
      var newContentRef = await PServiceContent.selectContent({ type: "activityFlashCard" })
      if (!newContentRef) return
      this.value.data.content_ref = newContentRef
      this.addVariables()
    },
    submitted() {
      this.blockController.overlay = this.value.data.overlays.answer
      this.blockController.overlayVariables = this.blockVariables
    },
    addVariables() {
      if (this.value.data.content_ref.body) {
        this.blockVariables = [
          {
            name: "FlashcardQuestion",
            type: "string",
            value: this.value.data.content_ref.body.contents.flashcard[0].question,
            title: "Question"
          },
          {
            name: "FlashcardAnswer",
            type: "string",
            value: this.value.data.content_ref.body.contents.flashcard[0].answer,
            title: "Answer"
          }
        ]
      }
    },
    changeContent() {
      this.value.data.content_ref = {}
    },
    resetToDefaultStyles() {
      this.value.data = defaultQuestionBlocks()
    },
    displayQuestion() {
      this.closeOverlay()
      this.blockController.overlayVariables = null
      this.blockController.$emit("overlayActive", { type: null, overlay: null })
    },
    displayOverlay(type) {
      this.blockController.overlay = this.value.data.overlays[type]
      this.blockController.overlay.parent = this.value.id
      this.blockController.overlayVariables = this.blockVariables
      this.blockController.$emit("overlayActive", { type: type, overlay: this.value.data.overlays[type] })
    },
    createMinorVersion() {
      if (this.value && this.value.data.content_ref) {
        this.$emit("create:draft", this.value.data.content_ref)
      }
    }
  },
  beforeDestroy() {
    if (this.answerOverlay && !_.isEmpty(this.blockController)) {
      this.blockController.$off(`state-changed:${this.answerOverlay.id}`)
      this.answerOverlay.blocks.forEach(answerBlock => {
        this.blockController.$off(`state-changed:${answerBlock.id}`)
      })
    }
    if (this.blockEventBus) this.blockEventBus.$off("submit", this.submitted)
  },
  watch: {
    "blockController.overlay": {
      immediate: true,
      handler() {
        if (!this.blockController.overlay && !_.isEmpty(this.blockController)) {
          this.blockController.$emit("overlayActive", { type: null, overlay: null })
        }
      }
    },
    "answerOverlay.blocks.length": {
      immediate: true,
      handler() {
        if (this.answerOverlay && !_.isEmpty(this.blockController)) {
          this.blockController.$on(`state-changed:${this.answerOverlay.id}`, state => {
            if (state == "primary-selected") {
              this.displayOverlay("answer")
            }
          })
          // if answer overlay have blocks, listen to each block state-change
          this.answerOverlay.blocks.forEach(answerBlock => {
            this.blockController.$on(`state-changed:${answerBlock.id}`, state => {
              if (state == "primary-selected") {
                this.displayOverlay("answer")
              }
            })
          })
        }
      }
    },
    "value.data.content_ref": {
      immediate: true,
      deep: true,
      handler() {
        this.addVariables()
      }
    }
  }
}
</script>

<style scoped>
.lp-block-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: none;
  position: relative;
}
</style>
../_mixins/blockOverlayCommon.vue