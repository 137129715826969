import Viewer from "./Viewer";
import Editor from "./Editor";

export default {
  name: "activityPicture",
  title: "Activity Picture",
  icon: require("./content-icon.svg"),
  category: "training",
  Viewer,
  Editor,
  Preview: Viewer
};
