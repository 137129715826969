<template>
  <v-container>
    <v-toolbar class="transparent elevation-0">
      <v-toolbar-title>{{ greeting }}</v-toolbar-title>
    </v-toolbar>
    <v-layout row wrap>
      <v-flex
        xl3
        lg4
        md6
        sm6
        xs12
        pa-3
        v-for="workarea in workareas"
        :key="workarea.title"
      >
        <card-workarea v-bind="workarea" />
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { roles } from "@/config/constants";
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user";
import CardWorkarea from "./components/CardWorkarea.vue";
export default {
  components: { CardWorkarea },
  data() {
    return {
      roles
    };
  },
  computed: {
    greeting() {
      var now = new Date();

      return `Good ${now.getHours() < 12 ? "morning" : "afternoon"} ${
        this.profile.name
      }!`;
    },
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    isAdmin() {
      return this.profile.role === roles.admin.value;
    },
    isStudent() {
      return this.profile.role === roles.student.value;
    },
    workareas() {
      return [
        {
          title: "Content Explorer",
          subtitle:
            "Explore all available contents available in PLANT, organized in folders.",
          icon: "mdi-folder-multiple-outline",
          has_access: true,
          to: "explorer"
        },
        {
          title: "Training Catalog",
          subtitle: "Browse and enroll to available training courses",
          icon: "mdi-google-classroom",
          has_access: true,
          to: "training"
        },
        {
          title: "Workflows",
          subtitle:
            "Create, review and approve content before it goes into the content explorer",
          icon: "mdi-clipboard-check-outline",
          has_access: !this.isStudent,
          to: "workflows"
        },
        {
          title: "Configuration Control",
          subtitle:
            "Keep all your approved content under control. Update your content to use always the latest revisions of the references it is using.",
          icon: "mdi-police-badge-outline",
          has_access: this.isAdmin,
          to: "configuration"
        },
        {
          title: "Content Tools",
          subtitle:
            "In this section you will find tools to execute operations on the contents.",
          icon: "mdi-hammer-wrench",
          has_access: this.isAdmin,
          to: "tools"
        }
      ];
    }
  }
};
</script>

