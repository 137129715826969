export const STORE_WORKFLOWS_NAME = "workflows"

export const STATE_ALL_ITEMS_REVISION_DRAFTS = "allItemRevisionDrafts"
export const STATE_FILTER_INPUT = "filterInput"
export const STATE_INPUT_OTHERS = "filterInputOthers"
export const STATE_IS_ERROR_CREATE = "isErrorCreate"
export const STATE_LOADING = "loading"
export const STATE_SELECTED_BULK_OPERATIONS = "selectedForBulkOperations"
export const STATE_SELECTED_ITEM_REVISION_DRAFT_ID = "selectedItemRevisionDraftId"
export const STATE_SELECTED_LIST = "selectedList"

export const GETTER_ACTIVE_USER = "activeUser"
export const GETTER_CAN_EDIT = "canEdit"
export const GETTER_FILTERED_ITEM_REVISION_DRAFTS = "filteredItemRevisionDrafts"
export const GETTER_FILTERED_STATE_WORKFLOWS = "filteredStateWorkflows"
export const GETTER_IS_ADMIN = "isAdmin"
export const GETTER_IS_SUPERVISOR = "isSupervisor"
export const GETTER_LOADING = "loading"
export const GETTER_SELECTED_ITEM_REVISION_DRAFT = "selectedItemRevisionDraft"

export const MUTATION_ADD_NEW_ITEM_TO_LIST = "addNewItemToList"
export const MUTATION_ALL_SELECTION = "allSelection"
export const MUTATION_ERROR_CREATE = "setErrorCreate"
export const MUTATION_ITEM_REVISION_DRAFTS = "setItemRevisionDrafts"
export const MUTATION_REMOVE_SELECTED_DRAFT = "removeSelectedDraft"
export const MUTATION_REMOVE_SELECTED_DRAFTS = "removeSelectedDrafts"
export const MUTATION_SELECTED_ITEM_REVISION = "setSelectedItemRevisionDraftId"
export const MUTATION_SELECTION = "selection"
export const MUTATION_SET_FILTERED_ITEM_REVISION_DRAFTS = "setFilteredItemRevisionDrafts"
export const MUTATION_TOGGLE_LOADING = "toggleLoading"
export const MUTATION_UNSELECTED_BULK_DRAFTS = "unselectBulkDrafts"
export const MUTATION_UPDATE_SELECTED_DRAFT = "updateSelectedDraft"
export const MUTATION_UPDATE_SELECTED_DRAFTS = "updateSelectedDrafts"
export const MUTATION_UPDATE_SELECTED_DRAFT_METADATA = "updateSelectedDraftMetadata"

export const ACTION_CHANGE_SELECTION = "changeSelection"
export const ACTION_CHANGE_WORKFLOW = "changeWorkflow"
export const ACTION_CHANGE_WORKFLOWS = "changeWorkflows"
export const ACTION_CREATE_ITEM = "createItem"
export const ACTION_DELETE_SELECTED_ITEM = "deleteSelectedItem"
export const ACTION_DISCARD_SELECTED_ITEMS_DRAFT = "discardSelectedItemsDraft"
export const ACTION_DISCARD_SELECTED_ITEM_DRAFT = "discardSelectedItemDraft"
export const ACTION_GET_PROPERTIES_DRAFT = "getPropertiesDraft"
export const ACTION_GET_WORKFLOW = "getWorkflowObject"
export const ACTION_ITEM_REVISION_DRAFTS = "getItemRevisionDrafts"
export const ACTION_MOVE_SELECTED = "moveSelected"
export const ACTION_REASSIGN_USERS_ROLE = "reassignUserRoles"
export const ACTION_REASSIGN_USER_ROLE = "reassignUserRole"
export const ACTION_SELECT_FILTER = "selectFilter"
export const ACTION_SELECT_ITEM_REVISION_DRAFT_ID = "selectItemRevisionDraftById"
export const ACTION_TOGGLE_ALL_SELECTION = "toggleAllSelection"
export const ACTION_UNSELECT_ITEM_REVISION_DRAFT = "unselectItemRevisionDraft"
export const ACTION_UPDATE_SELECTED_DRAFT_METADATA = "updateSelectedDraftMetadata"
