export default class LearningObjective {
  constructor() {
    this.text = ""
    this.comments = ""
    this.condition = ""
    this.standard = ""
    this.level = ""
    this.media = ""
    this.setting = ""
    this.classification = ""
    this.time = ""
    this.topic = ""
  }
}
