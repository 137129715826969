<template>
  <div class="drawer-references">
    <v-list>
      <v-list-group
        v-for="(item, index) in referenceList"
        :key="index"
        v-model="item.active"
        :prepend-icon="item.active ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        append-icon=""
        no-action
        v-show="item.show"
        e2e-attr="refs-list-elem">
        <template v-slot:activator>
          <v-list-tile>
            <v-list-tile-content>
              <v-list-tile-title>
                <div v-if="allReferencesLastRevision(item.title === 'References')">
                  <v-icon class="pr-2">{{ item.action }}</v-icon>
                  {{ item.title }}
                </div>
                <v-badge v-else color="warning">
                  <template v-slot:badge>
                    <v-icon color="white">mdi-exclamation-thick</v-icon>
                  </template>
                  <v-icon class="pr-2">{{ item.action }}</v-icon>
                  {{ item.title }}
                </v-badge>
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>
        <div v-if="item.items.length > 0" class="drawer-references-item-container">
          <v-alert
            v-if="!allReferencesLastRevision(item.title === 'References') && isDraft"
            :value="true"
            type="warning">
            Updates found.
          </v-alert>
          <v-list-tile v-for="(subItem, indexY) in item.items" :key="indexY" class="drawer-references-item">
            <v-list-tile-action class="drawer-references-item-action" v-if="!isStudent">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon v-show="!isLatestRevision(subItem)" color="warning" v-on="on">mdi-alert</v-icon>
                </template>
                <span>There is a newer revision of this content.</span>
              </v-tooltip>
            </v-list-tile-action>

            <v-list-tile-avatar>
              <ContentTypeIcon v-if="subItem.header" :type="getType(subItem)" class="no-border-radius" />
              <ContentTypeIcon v-else class="no-border-radius" />
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-sub-title>
                {{ getCustomId(subItem) }} - {{ subItem.header.major_revision }}.{{ subItem.header.minor_revision }}
              </v-list-tile-sub-title>
              <v-list-tile-title>{{ getTitle(subItem) }}</v-list-tile-title>
            </v-list-tile-content>

            <!-- Actions -->
            <v-list-tile-action class="drawer-references-item-action" v-if="isDraft">
              <v-btn icon ripple @click="updateToLatestRevision(subItem)" v-show="!isLatestRevision(subItem)">
                <v-icon color="grey lighten-1">mdi-refresh</v-icon>
              </v-btn>
            </v-list-tile-action>

            <v-list-tile-action class="drawer-references-item-action">
              <v-btn icon ripple @click="openReference(subItem)">
                <v-icon color="grey lighten-1">mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-tile-action>

            <v-list-tile-action class="drawer-references-item-action">
              <v-btn v-if="canEdit && isDraft" icon ripple @click="deleteReference(subItem.$origin)">
                <v-icon color="red lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </div>
        <p v-else class="no-related-content">No related content</p>
      </v-list-group>
      <dependencies-list :weak="false" />
      <dependencies-list v-if="isLessonPlan" :weak="true" title="Weak dependencies" />
    </v-list>

    <v-speed-dial
      v-model="fab"
      v-if="canEdit && isDraft"
      :right="true"
      :bottom="true"
      class="drawer-references-add"
      scale="scale-transition"
      :open-on-hover="false">
      <template v-slot:activator>
        <v-btn color="primary" fab v-model="fab">
          <v-icon>mdi-plus</v-icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
      <v-btn color="primary" @click="selectContent('xref')" class="drawer-references-add-option-1">
        <v-icon>mdi-source-branch</v-icon> Add Cross Reference
      </v-btn>
      <v-btn color="primary" @click="selectContent('ref')" class="drawer-references-add-option-2">
        <v-icon>mdi-web-box</v-icon> Add Reference
      </v-btn>
    </v-speed-dial>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex"
import { ContentTypeIcon } from "plant-common"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_DEPENDENCIES,
  STORE_MODULE_EDITOR,
  ACTION_DELETE_REFERENCE,
  STATE_CAN_EDIT
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME } from "@/store_constants/library"
import { STORE_USER_NAME, GETTER_IS_STUDENT } from "@/store_constants/user"

import { getCustomId, getTitle, getType } from "../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"
import DependenciesList from "./DrawerReferences/DependenciesList.vue"
import { cloneDeep } from "@/components/lessonPlan/utils/utils.js"
import { PtModal } from "plant-common"
import Helpers from "./DrawerReferences/helpers.vue"

const REF_INDEX = 0
const XREF_INDEX = 1

export default {
  name: "DrawerReferences",
  mixins: [PtModal, Helpers],
  inject: ["DependencyBus"],
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      dependencies: STATE_DEPENDENCIES,
      canEdit: STATE_CAN_EDIT
    }),
    ...mapState(STORE_LIBRARY_NAME, ["settings"]),
    ...mapGetters(STORE_USER_NAME, {
      isStudent: GETTER_IS_STUDENT
    }),
    references() {
      if (this.itemRevision) {
        return this.itemRevision.body.references
      } else {
        return []
      }
    },
    isLessonPlan() {
      return this.getType(this.itemRevision) === "lessonPlan"
    }
  },
  components: {
    ContentTypeIcon,
    DependenciesList
  },
  data() {
    return {
      fab: false,
      isDraft: false,
      referenceList: [
        {
          action: "mdi-web-box",
          title: "References",
          show: true,
          active: true,
          items: []
        },
        {
          action: "mdi-source-branch",
          title: "Cross-References",
          show: true,
          items: []
        }
      ]
    }
  },
  mounted() {
    let path = this.$router.app.$route.fullPath.split("/")
    if (path[path.length - 1] === "draft") this.isDraft = true
  },
  methods: {
    ...mapActions(STORE_MODULE_EDITOR, {
      deleteReference: ACTION_DELETE_REFERENCE
    }),
    isRef(x) {
      return x.$origin.includes("plant-ref:")
    },
    isXRef(x) {
      return x.$origin.includes("plant-xref:")
    },
    openReference(ref) {
      PServiceContent.openContent(ref, "tab")
    },
    selectContent(type) {
      PServiceContent.selectContent()
        .then(r => {
          r.$origin = `plant-${type}${r.$origin.slice(5)}`
          r.type = type
          this.references.push(r)
        })
        .catch(console.error)
    },
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType,
    async updateToLatestRevision(dependency) {
      const confirm = await this.confirmDialog(
        "This action will update this reference in the current content to its latest reference available. Do you want to continue?"
      )
      if (!confirm) return

      const [latestMajor, latestMinor] = dependency.__latest.split(".")
      const references = cloneDeep(this.references)
      const dependencyIndex = references.findIndex(elem => elem._id === dependency._id)
      const originSplitted = references[dependencyIndex].$origin.split("/")

      originSplitted[originSplitted.length - 1] = latestMinor
      originSplitted[originSplitted.length - 2] = latestMajor

      references[dependencyIndex].$origin = originSplitted.join("/")
      references[dependencyIndex].header.major_revision = latestMajor
      references[dependencyIndex].header.minor_revision = latestMinor

      this.itemRevision.body.references = [...references]
    },
    async confirmDialog(text) {
      return await this.$modal("confirm", {
        title: "Confirm Update",
        message: text,
        accept: { text: "Yes" },
        cancel: { text: "No" }
      })
    },
    allReferencesLastRevision(isRef) {
      for (const ref of this.itemRevision.body.references.filter(isRef ? this.isRef : this.isXRef)) {
        if (!this.isLatestRevision(ref)) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    itemRevision: {
      deep: true,
      immediate: true,
      handler() {
        this.referenceList[REF_INDEX].items = this.itemRevision.body.references.filter(this.isRef)
        this.referenceList[XREF_INDEX].items = this.itemRevision.body.references.filter(this.isXRef)
      }
    }
  }
}
</script>

<style scoped>
.no-border-radius {
  border-radius: 0px;
}

.no-related-content {
  padding: 10px 72px;
  font-style: italic;
}

.back-grey {
  background: lightgrey;
}

.drawer-references {
  position: relative;
  height: calc(100vh - 70px);
}
.drawer-references-item-container {
  max-height: 65vh;
  overflow: auto;
}
.drawer-references-item {
  margin: 10px 0 20px -20px;
}
.drawer-references-item-action {
  min-width: 35px;
}
.drawer-references-add {
  position: absolute;
}
.drawer-references-add-option-1 {
  width: 200px;
  right: 65px;
}
.drawer-references-add-option-2 {
  width: 200px;
  right: 65px;
}
</style>
