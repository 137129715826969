import Component from "./index.vue"

export default {
  name: "term",
  title: "Term",
  icon: require("./content-icon.svg"),
  category: "document",
  Viewer: Component,
  Editor: Component,
  Preview: Component
}
