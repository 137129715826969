<template>
  <v-card class="transparent elevation-0">
    <v-toolbar flat extended>
      <template v-slot:extension>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-btn slot="activator" color="green" dark class="mb-2"> <v-icon>add</v-icon>Add user </v-btn>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="editedItem.name" label="User's name"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="editedItem.email" type="email" label="E-mail"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-select
                      v-model="editedItem.role"
                      :items="roles"
                      item-value="role"
                      item-text="title"
                      label="Role"></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click.native="close">Cancel</v-btn>
              <v-btn color="blue darken-1" flat @click.native="save">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-toolbar-title>Site Users</v-toolbar-title>
    </v-toolbar>

    <v-data-table :headers="headers" :items="users" :search="search" class="elevation-1 scrollDataTable">
      <template slot="items" slot-scope="props">
        <td>{{ props.item.name || "-" }}</td>
        <td>{{ props.item.email }}</td>
        <td>{{ props.item.role }}</td>

        <td>
          <v-chip v-if="props.item.disabled" label outline color="red">Deactivated</v-chip>
          <v-chip v-else label outline color="green">Activated</v-chip>
        </td>

        <td class="justify-center layout px-0">
          <v-icon small class="mr-2" @click="editUser(props.item)">edit</v-icon>
          <!-- <v-icon small @click="removeUser(props.item)">delete</v-icon> -->

          <v-icon v-if="props.item.disabled" @click="toggleUser(props.item)">done</v-icon>
          <v-icon v-else @click="toggleUser(props.item)">delete</v-icon>
        </td>
      </template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { PtModal } from "plant-common"
import {
  STORE_FLEET_NAME,
  STATE_USERS,
  ACTION_GET_USERS,
  ACTION_DELETE_USER,
  ACTION_CREATE_USER,
  ACTION_UPDATE_USER
} from "@/store_constants/fleet"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_ERROR,
  ACTION_NOTIFICATIONS_INFO
} from "@/store_constants/notifications"

export default {
  mixins: [PtModal],
  data() {
    return {
      dialog: false,
      roles: [
        { role: "admin", title: "Administrator" },
        { role: "supervisor", title: "Supervisor" },
        { role: "instructor", title: "Instructor" },
        { role: "student", title: "Student" }
      ],
      search: "",
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name"
        },
        { text: "E-mail", value: "email" },
        { text: "Role", value: "role" },
        { text: "State", value: "disabled" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        role: 0
      },
      defaultItem: {
        name: "",
        email: "",
        role: 0
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User"
    },
    ...mapState(STORE_FLEET_NAME, {
      users: STATE_USERS
    }),
    isFormFilled() {
      return this.editedItem.name && this.editedItem.email && this.editedItem.role
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    }
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapActions(STORE_FLEET_NAME, {
      getUsers: ACTION_GET_USERS,
      deleteUser: ACTION_DELETE_USER,
      createUser: ACTION_CREATE_USER,
      updateUser: ACTION_UPDATE_USER
    }),
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      info: ACTION_NOTIFICATIONS_INFO,
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    initialize() {
      this.getUsers()
    },
    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    // This method shouldn't be used. Instead disable the user with toggleUser
    removeUser(user) {
      const index = this.users.indexOf(user)
      this.$modal("confirm", {
        title: `Are you sure you want to delete "${user.name}" form PLANT users?`,
        accept: {
          text: "Delete",
          color: "red",
          icon: "mdi-delete"
        },
        cancel: {
          text: "Cancel",
          color: "default"
        }
      }).then(r => {
        if (r) {
          this.deleteUser(user._id)
            .then(response => {
              this.users.splice(index, 1)
            })
            .catch(err => console.log(err))
        }
      })
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save() {
      if (this.isFormFilled) {
        this.createOrUpdateUser()
      } else {
        this.error({ message: "All fields are mandatory" })
      }
    },
    createOrUpdateUser() {
      if (this.editedIndex > -1) {
        this.updateUser({
          id: this.editedItem._id,
          email: this.editedItem.email,
          name: this.editedItem.name,
          role: this.editedItem.role,
          disabled: false
        })
          .then(response => {
            this.info({ message: "User correctly updated" })
            this.initialize()
          })
          .catch(err => console.log(err))
      } else {
        this.createUser({
          email: this.editedItem.email,
          name: this.editedItem.name,
          role: this.editedItem.role
        })
          .then(response => {
            this.info({ message: "User correctly created" })
            this.initialize()
          })
          .catch(err => console.log(err))
      }
      this.close()
    },

    toggleUser(user) {
      user.disabled = !user.disabled
      this.updateUser({
        id: user._id,
        email: user.email,
        name: user.name,
        role: user.role,
        disabled: user.disabled
      })
        .then(response => {
          this.initialize()
        })
        .catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>
.scrollDataTable {
  max-height: calc(100vh - 300px);
  overflow: auto;
}
</style>
