<template>
  <v-container
    fluid
    v-bind:class="{ 'errorNotFound': isBig, 'errorNotFound_ipad': isSmall, 'errorNotFound_mobile': isMobile }"
    v-resize="onResize"
  >
    <v-layout row wrap>
      <v-flex lg12 md12 sm12 xs12 v-show="isBig">
        <v-img :src="require('./img/errorNotFound.svg')" class="img_errorNotFound"></v-img>
        <v-card-actions class="error_msg_area">
          <div>
            <h1 class="error_msg">{{notFoundText}}</h1>
            <div class="error_msg help-text">{{contactInfoText}}</div>
            <v-btn color="primary" @click="$router.go(-1)" class="error_btn">Go back</v-btn>
          </div>
        </v-card-actions>
      </v-flex>

      <v-flex md12 sm12 xs12 v-show="isSmall">
        <v-img :src="require('./img/errorNotFound_ipad.svg')" class="img_errorNotFound"></v-img>
        <h1 class="error_msg">{{notFoundText}}</h1>
        <div class="error_msg help-text">{{contactInfoText}}</div>
        <v-btn color="primary" class="error_btn_small" @click="$router.go(-1)">Go back</v-btn>
      </v-flex>

      <v-flex md12 sm12 xs12 v-show="isMobile">
        <v-img :src="require('./img/errorNotFound_mobile.svg')"></v-img>
        <h1 class="error_msg_mobile">{{notFoundText}}</h1>
        <v-btn color="primary" class="error_btn_small" @click="$router.go(-1)">Go back</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      isBig: true,
      isSmall: false,
      isMobile: false,
      notFoundText: "Sorry, we couldn't find your page.",
      contactInfoText:
        "If you are sure that something should be here, please contact the platform administrator.",
    };
  },
  methods: {
    onResize() {
      let windowSize = { x: window.innerWidth, y: window.innerHeight };

      if (windowSize.x < 1280) {
        this.isBig = false;
        this.isSmall = true;
        this.isMobile = false;
      } else {
        this.isSmall = false;
        this.isBig = true;
        this.isMobile = false;
      }
      if (windowSize.x < 768) {
        this.isMobile = true;
        this.isSmall = false;
        this.isBig = false;
      }
    },
  },
};
</script>

<style>
.errorNotFound {
  height: 100%;
  width: 100%;
  padding-top: 0px;
  margin: auto;
  text-align: center;
}

.errorNotFound_ipad {
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: center;
}

.errorNotFound_mobile {
  height: 100%;
  width: 100%;
  margin: auto;
  text-align: -webkit-center;
}

.img_errorNotFound {
  width: 50%;
  margin: auto;
}

.error_msg {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 31px;
  text-align: center;

  color: #93aed6;
}

.error_msg_mobile {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  margin-top: 25px;
  line-height: 20px;
  color: #93aed6;
}

.error_msg_area {
  max-width: 800px;
  margin: auto;
}

.error_btn {
  border-radius: 38px;
  margin-top: 20px !important;
}

.error_btn_small {
  border-radius: 38px;
  margin-top: 20px;
}

.help-text {
  font-size: 21px !important;
  margin-top: 20px;
}

</style>