<template>
  <div class="pl-4 pr-4">
    <div :class="!readMoreExpanded ? 'objective-description-collapsed' : ''">
      <p v-html="getObjectiveDescription(learningObjective.objective)" class="objective-description"></p>
    </div>
    <div class="read-more-text pb-4" @click="readMore">
      Read <span v-if="readMoreExpanded">less</span> <span v-else>more</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObjectiveDescription",
  inject: ["lessonPlanController"],
  props: {
    learningObjective: Object
  },
  data() {
    return {
      readMoreExpanded: false
    }
  },
  methods: {
    getObjectiveDescription(objective) {
      if (objective && objective.description) {
        return objective.description
      } else {
        return "No description"
      }
    },
    readMore() {
      this.readMoreExpanded = !this.readMoreExpanded
    }
  }
}
</script>

<style scoped>
.objective-description-collapsed > p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.objective-description {
  text-align: justify;
}

.read-more-text {
  color: #1976d2;
  text-align: right;
  cursor: pointer;
}
</style>
