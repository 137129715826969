<template>
  <v-autocomplete
    v-model="content"
    v-if="true"
    :items="items"
    @blur="reset"
    label="Search by title or custom id"
    autofocus
    hide-no-data
    hide-details
    @keyup.enter="searchURL(query)"
    @change="selectContent"
    :search-input.sync="search"
    return-object
    append-icon
    prepend-inner-icon="mdi-magnify"
    browser-autocomplete="off"></v-autocomplete>
</template>

<script>
import { libraryApi } from "@/api/index.js"
import { getContentId, getTitle, getCustomId, getType } from "@/utils/dbTool"
export default {
  props: {
    library: {
      type: String
    },
    filter: {
      type: [Array, String, Boolean]
    }
  },
  data() {
    return {
      content: "",
      query: "",
      showSearch: false,
      items: [],
      search: null,
      timeout: null
    }
  },
  watch: {
    search() {
      this.query = this.search
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      // Wait 0.5 second before call to search endpoint
      this.timeout = setTimeout(() => {
        if (this.search) {
          if (this.search.length >= 3) {
            libraryApi
              .searchContent(this.library, { query: this.search })
              .then(res => {
                res.data.docs = this.filterSearch(res.data.docs)
                this.items = res.data.docs.map(el => {
                  return {
                    field: getContentId(el),
                    text: `${getCustomId(el)}: ${getTitle(el)}`,
                    data: el
                  }
                })
              })
              .catch(err => console.error(err))
          }
        }
      }, 500)
    }
  },
  methods: {
    async searchURL(query) {
      try {
        var { data } = await libraryApi.searchContent(this.library, { query })
        data.docs = this.filterSearch(data.docs)
        this.$emit("search", data)
        this.reset()
      } catch (e) {
        console.error(e)
      }
    },
    querySelections(v, res) {
      this.items = res.filter(e => {
        if (!e.header) return false
        return (
          (getTitle(e) || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1 ||
          (getCustomId(e) || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        )
      })
      return true
    },
    reset() {
      this.showSearch = false
      this.items = []
      this.search = ""
      this.query = ""
    },
    selectContent() {
      this.$emit("select", this.content)
    },
    filterSearch(results) {
      let filteredResults = results
      if (this.filter) {
        filteredResults = results.filter(result => this.filter.includes(getType(result)))
      }
      return filteredResults
    }
  }
}
</script>

<style>
</style>
