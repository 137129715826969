<template>
  <v-container class="px-0 py-0">
    <div v-if="loading">
      <v-alert :value="true" color="info" icon="mdi-information-outline">Loading data</v-alert>
      <v-progress-linear :size="100" :width="6" color="primary" indeterminate class="loading-fixed"></v-progress-linear>
    </div>
    <div v-else>
      <v-toolbar
        flat
        color="white"
        :extension-height="48"
        style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
        class="mb-2">
        <v-toolbar-title>Recycled drafts</v-toolbar-title>
        <v-spacer />
        <v-btn outline round color="primary" @click="recoverSelectedRecycledDrafts()" :disabled="selected.length !== 1">
          <v-icon left>mdi-undo</v-icon>
          <span>Recover selection</span>
        </v-btn>
        <v-btn outline round color="primary" @click="deleteSelection()" :disabled="!selected.length">
          <v-icon left>mdi-delete-sweep-outline</v-icon>
          <span>Delete selection ({{ selected.length }})</span>
        </v-btn>
        <v-btn outline round color="error" @click="emptyBin()" :disabled="!recycledDrafts?.length">
          <v-icon left>delete_forever</v-icon>
          <span>Empty recycle bin</span>
        </v-btn>
      </v-toolbar>
      <recycled-drafts-table
        layout="recycled-bin"
        v-show="recycledDrafts.length"
        :items="recycledDrafts"
        :selected="selected"
        @selected="selected = $event" />
      <div v-show="!recycledDrafts.length">
        <v-img height="500" contain :src="require('@/assets/empty_list.svg')"></v-img>
        <h5 class="text-center headline font-weight-light mt-5">You don't have any recycled content yet.</h5>
      </div>
    </div>
  </v-container>
</template>

<script>
import PtApiClient from "plant-api-client"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { ACTION_NOTIFICATIONS_ERROR, STORE_NOTIFICATIONS_NAME } from "@/store_constants/notifications"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import { mapState, mapActions } from "vuex"
import PtModal from "plant-common/src/components/PtModal"
import RecycledDraftsTable from "./RecycledDraftsTable.vue"
import NewContentDialog from "@/pages/workflow/components/new-content/NewContentDialog.vue"
import { Extensions } from "plant-common"

async function contentHasDraft(contentId) {
  try {
    const draft = await PtApiClient.getDraftByContentId(contentId, "no-dereference")
    return !!draft
  } catch (e) {
    return false
  }
}

export default {
  components: { RecycledDraftsTable, NewContentDialog },
  mixins: [PtModal],
  data() {
    return {
      selected: [],
      recycledDrafts: [],
      loading: true
    }
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      plantSettings: STATE_PLANT_SETTINGS
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    })
  },
  watch: {
    // Watching settings instead of plant_id because we need to wait for the baseURL to be set
    plantSettings: {
      immediate: true,
      handler() {
        this.loadRecycledDrafts()
      }
    }
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    async loadRecycledDrafts() {
      this.loading = true
      this.selected = []
      const response = await PtApiClient.getAllRecycledDrafts()
      this.recycledDrafts = response.docs
      this.loading = false
    },
    displayEmptyBinModal() {
      return this.$modal("confirm", {
        title: "Empty recycle bin",
        message:
          "Are you sure you want to empty the recycle bin? This action will permanently delete all the recycled drafts.",
        accept: {
          icon: "delete_forever",
          text: "Yes, delete all!",
          color: "error"
        },
        cancel: {
          icon: "close",
          color: "white"
        }
      })
    },
    notifyError(message) {
      this.error({ message })
    },
    async emptyBin() {
      const confirmed = await this.displayEmptyBinModal()
      if (confirmed) {
        this.loading = true
        try {
          await Promise.all(
            this.recycledDrafts.map(recycledDraft => PtApiClient.deleteRecycledDraftById(recycledDraft._id))
          )
          this.loadRecycledDrafts()
        } catch (error) {
          this.notifyError("An error occurred while trying to empty the recycle bin")
          this.loading = false
        }
      }
    },
    displayDeleteSelectionModal(selectionCount) {
      const countText = selectionCount === 1 ? "selected draft" : `${selectionCount} selected drafts`
      return this.$modal("confirm", {
        title: "Delete the selected drafts",
        message: `Are you sure you want to delete the ${countText}? This action will permanently delete the selected drafts.`,
        accept: {
          icon: "delete_forever",
          text: "Yes, delete them",
          color: "error"
        },
        cancel: {
          icon: "close",
          color: "white"
        }
      })
    },
    async deleteSelection() {
      const selectionCount = this.selected.length
      const confirmed = await this.displayDeleteSelectionModal(selectionCount)
      if (confirmed) {
        this.loading = true
        try {
          await Promise.all(this.selected.map(draft => PtApiClient.deleteRecycledDraftById(draft._id)))
          this.loadRecycledDrafts()
        } catch (error) {
          this.notifyError("An error occurred while trying to delete the selected drafts")
          this.loading = false
        }
      }
    },
    async recoverSelectedRecycledDrafts() {
      if (this.selected.length !== 1) {
        return
      }

      const draftId = this.selected[0]._id
      try {
        await this.recoverDraft(draftId)
        this.loadRecycledDrafts()
      } catch (e) {
        const data = e.response?.data
        let message = "There was an unknown error while recovering the draft, please try again later or contact support"
        if (data?.errors?.custom_id) {
          message = "There is already a content with this custom id"
        } else {
          message = e
        }

        this.$modal("info", {
          title: "An error occurred while recovering the draft",
          message
        })
      }
    },
    async recoverDraft(draftId) {
      const draft = await PtApiClient.getRecycledDraftById(draftId)

      let contentId = draft.header.metadata.content_id
      if (!contentId) {
        await this.createContentFromDraft(draft)
      } else if (await contentHasDraft(contentId)) {
        throw new Error("Content already has a draft")
      } else {
        await PtApiClient.createDraft(contentId, "recycled:" + draft._id, "all")
        // Assign DRAFT author to the user
        await PtApiClient.updateDraftWorkflow(contentId, "method", "reassignRole", {
          role: "author",
          user_id: this.profile._id,
          lightweight: true
        })
      }

      await PtApiClient.deleteRecycledDraftById(draftId)
    },
    async createContentFromDraft(draft) {
      const title = draft.header.metadata.title
      const customId = draft.contentCustomId
      const folderId = draft.header.metadata.folder_id
      const contentRevisionType = draft.header.metadata.content_revision_type
      const extensionType = Extensions[contentRevisionType]

      if (!extensionType) {
        throw new Error(`Extension type ${contentRevisionType} not found`)
      }

      const res = await this.$modal(NewContentDialog, {
        value: true,
        path_set: folderId,
        type_set: {
          value: extensionType,
          fixed: true
        },
        title_set: title,
        custom_id_set: customId
      })

      if (!res) {
        throw new Error("User cancelled")
      }

      return PtApiClient.createDraftFromApprovedRevision(
        "recycled:" + draft._id,
        res.custom_id,
        res.title,
        res.path_id,
        contentRevisionType
      )
    }
  }
}
</script>

<style scoped>
.loading-fixed {
  margin: -4px 0px;
}
</style>
