<template>
  <v-card flat class="px-0 py-0 mx-0 my-0 h-100 w-100">
    <explorer-toolbar />
    <ExplorerContent
      @input="id => $router.push(`knowledge/${id}/view`)"
      @folder="id => $router.push(`/knowledge/explorer?folder_id=${id}`)"
    />
  </v-card>
</template>

<script>
import ExplorerToolbar from "./components/Toolbar";
import ExplorerContent from "./ExplorerContent";
import explorer_store from "@/store/explorer";
import { STORE_EXPLORER_NAME } from "@/store_constants/explorer";

export default {
  components: {
    ExplorerToolbar,
    ExplorerContent
  },
  props: ["folder_id", "q"],
  data() {
    return {
      selected: [],
      headers: [
        {
          text: "",
          value: "id",
          align: "left",
          sortable: true,
          width: 60
        },
        {
          text: "Type",
          value: "type",
          align: "left",
          sortable: true,
          width: 60
        },
        {
          text: "Custom Id",
          value: "customId",
          align: "left",
          sortable: true,
          width: 120
        },
        {
          text: "Title",
          value: "title",
          align: "left",
          sortable: true
        }
      ]
    };
  },
  beforeCreate() {
    if (!this.$store.state[STORE_EXPLORER_NAME])
      this.$store.registerModule(STORE_EXPLORER_NAME, explorer_store);
  }
};
</script>

<style scoped>
.fix-viewer {
  bottom: 0px;
  position: absolute;
  width: 100%;
  top: 0px;
  padding-bottom: 40px;
}
</style>
