<template>
  <div style="height: 100%">
    <v-card flat style="height: 100%">
      <v-layout style="height: 100%">
        <v-flex style="height: 100%">
          <v-card-title
            @click="changeCard(guide.cover)"
            class="px-2 py-2"
            style="border-bottom: 1px solid #eaeaea; cursor: pointer">
            <v-icon>mdi-home</v-icon>
            <h4 class="ml-3">Cover {{ guideController.mode }}</h4>
          </v-card-title>
          <v-card-text ref="treeViewContainer" class="px-2 py-0 overflow_items">
            <v-treeview
              :items="guide.sections"
              :active.sync="activated"
              active-class="grey lighten-4 indigo--text"
              selected-color="indigo"
              expand-icon="mdi-chevron-down"
              indeterminate-icon="mdi-bookmark-minus"
              :open.sync="expanded"
              hoverable
              transition
              open-on-click>
              <template v-slot:label="{ item }">
                <span
                  :ref="item.id"
                  @click="changeCard(item)"
                  :class="['tree-item', selectedCard.id === item.id ? 'underline' : '']">
                  <span v-if="item.type == 'section'">{{ item.fields.title || "Section" }}</span>
                  <span v-if="item.type == 'card'">
                    {{ getTitle(item.content) }}
                    {{ getCommentsForCard(item).length }}
                    <span>
                      <v-chip style="height: 16px">{{ getCustomId(item.content) }}</v-chip>
                      <v-chip style="height: 16px" v-if="item.content.header"
                        >{{ item.content.header.major_revision }}.{{ item.content.header.minor_revision }}</v-chip
                      >
                    </span>
                  </span>
                  <span v-if="item.type == 'card-template'">{{
                    item.content.template_name ? item.content.template_name : ""
                  }}</span>
                </span>
              </template>

              <template v-slot:append="{ item }">
                <span v-if="item.type == 'card'">
                  <content-resolve-icon :value="item" />
                </span>
                <span v-if="item.type == 'section' || item.type == 'card' || item.type == 'card-template'" class="mr-2">
                  <span v-if="comments && getCommentsForCard(item).length">
                    <v-badge v-if="isDraft" overlap :color="setBadgeColor(getCommentsForCard(item))">
                      <template v-slot:badge>
                        <span>{{ getCommentsForCard(item).length }}</span>
                      </template>
                      <v-icon color="grey lighten-1" medium>message</v-icon>
                    </v-badge>
                  </span>
                </span>
                <span v-if="item.type == 'section' || item.type == 'card' || item.type == 'card-template'" class="mr-2">
                  <span>
                    <v-icon v-if="(guideController.getCardStatus(item.id) || {}).completion" color="green"
                      >mdi-circle</v-icon
                    >
                    <v-icon v-else color="blue">mdi-circle-outline</v-icon>
                  </span>
                </span>
              </template>

              <template v-slot:prepend="{ item, open }">
                <v-icon v-if="item.type == 'section'" :color="open ? 'primary' : 'grey'">{{
                  open ? "mdi-folder-open" : "mdi-folder"
                }}</v-icon>
                <span v-if="item.type == 'card'" class="ml-0">
                  <content-type-icon
                    v-if="item.content.header"
                    :type="getType(item.content)"
                    width="25px"></content-type-icon>
                  <v-icon v-else>mdi-circle</v-icon>
                </span>
                <span v-if="item.type == 'card-template'" class="ml-0">
                  <v-icon>mdi-menu</v-icon>
                </span>
              </template>
            </v-treeview>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-card>
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Comment unsaved</v-card-title>
        <v-card-text
          >You have an unsaved comment. If you change your card the comment will be deleted. Are you sure you want to
          change your card?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="dialog = false">No</v-btn>
          <v-btn color="blue darken-1" flat @click="doChangeCard">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import ContentResolveIcon from "./ContentResolveIcon.vue"
import { ContentTypeIcon } from "plant-common"
import { STATE_IS_COMMENT, STORE_CONTENT_NAME } from "../../../../store_constants/content"
import { getCustomId, getTitle, getType } from "../../../../utils/dbTool"

export default {
  name: "GuideEditorNavBar",
  inject: ["guideController"],
  data() {
    return {
      expanded: [],
      activated: [],
      dialog: false,
      selectedChangeCard: null,
      isDraft: false
    }
  },
  props: {
    sortable: {
      type: Boolean,
      default: false
    }
  },
  components: { ContentTypeIcon, ContentResolveIcon },
  computed: {
    isComment() {
      // We need to load comments like this because of SCORM export (where we don not have declared Vuex state)
      if (this.$store) {
        return this.$store.state[STORE_CONTENT_NAME].comments[STATE_IS_COMMENT]
      } else {
        return false
      }
    },
    ...mapInjectedData("guideController", ["guide", "selectedCard"]),
    comments() {
      if (this.$store) {
        return this.$store.getters["content/comments/comments"]
      } else {
        return []
      }
    }
  },
  mounted() {
    if (this.$router) {
      let path = this.$router.app.$route.fullPath.split("/")
      if (path[path.length - 1] === "draft") this.isDraft = true
    }
  },
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType,
    ...mapInjectedMethods("guideController", ["selectCard", "selectCardFromId", "getParents"]),
    changeCard(card) {
      if (this.isComment) {
        this.selectedChangeCard = card
        this.dialog = true
      } else {
        this.selectCard(card)
      }
    },
    doChangeCard() {
      this.selectCard(this.selectedChangeCard)
      this.dialog = false
      this.selectedChangeCard = null
    },
    setBadgeColor(arr) {
      let open = 0
      let close = 0

      arr.forEach(e => {
        switch (e.state) {
          case "open":
            return open++
            break
          case "closed":
            return close++
            break
          default:
            break
        }
      })

      return arr.length === open ? "red" : arr.length === close ? "blue" : "orange"
    },
    getCommentsForCard(item) {
      return this.comments
        .filter(e => e.location && e.location.id === item.id)
        .concat(this.comments.filter(e => e.location && e.location.card_id === item.id))
    }
  },
  watch: {
    selectedCard: function (newLocation, oldLocation) {
      this.activated = [newLocation.id]
      let parents = this.getParents(this.guide.sections, newLocation.id, []).parents
      if (parents) {
        parents.forEach(e => this.expanded.push(e.id))
      }
      this.$nextTick(() => {
        let element = this.$refs[newLocation.id]
        if (element) {
          this.$refs["treeViewContainer"].scrollTop = element.offsetTop - 40
        }
      })
    }
  }
}
</script>

<style scoped>
#pane_0 {
  overflow-y: auto;
  height: calc(100vh - 153px);
}

.height90 {
  height: calc(100% - 100px);
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.normal-card {
  margin: 15px 5px;
  width: 100%;
}

.normal-section {
  margin: 15px 5px;
  width: 100%;
}

.section-title {
  margin: 0px;
  padding: 5px;
  background: black;
  color: white;
}

.pointer {
  cursor: pointer;
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.tree-item {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  padding: 12px;
}

.overflow_items {
  height: calc(100vh - 194px);
  overflow: auto;
}
</style>
