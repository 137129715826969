<template>
  <div>
    <!-- Remove slide -->
    <v-list-tile @click="removeSlide">
      <v-list-tile-title class="body-1">
        <v-layout row align-center>
          <v-icon class="mr-2">mdi-trash-can</v-icon>
          <span>Remove</span>
        </v-layout>
      </v-list-tile-title>
    </v-list-tile>
  </div>
</template>

<script>
import { PtModal } from "plant-common"
import { findCommentsForSlide } from "../../../utils/utils"
export default {
  inject: ["lessonPlanController"],
  props: ["item"],
  mixins: [PtModal],
  methods: {
    removeSlide() {
      // Check if there is any comment that might be deleted
      const commentsInDeletedSlide = findCommentsForSlide(this.item, this.lessonPlanController.comments)
      if (commentsInDeletedSlide.length > 0) {
        this.$modal("options", {
          title: "Slide has comments associated",
          message: `The slide you are going to delete has ${commentsInDeletedSlide.length} comment${
            commentsInDeletedSlide.length > 1 ? "s" : ""
          } associated. What do you want to do with this associated comment${
            commentsInDeletedSlide.length > 1 ? "s" : ""
          }?`,
          options: [
            { text: "Delete", value: "delete", color: "error", icon: "mdi-delete" },
            { text: "Keep comments", value: "keep", icon: "mdi-delete-off" },
            { text: "Cancel", value: "cancel", color: "shades", icon: "mdi-cancel" }
          ],
          maxWidth: "600"
        }).then(r => {
          switch (r) {
            case "delete":
              this.lessonPlanController.removeSlideById(this.lessonPlanController.slidesTree.slides, this.item.id, true)
              break
            case "keep":
              this.lessonPlanController.removeSlideById(
                this.lessonPlanController.slidesTree.slides,
                this.item.id,
                false
              )
              break
            case "cancel":
              return
              break
            default:
              return
              break
          }
        })
      } else {
        this.lessonPlanController.removeSlideById(this.lessonPlanController.slidesTree.slides, this.item.id, false)
      }
    }
  }
}
</script>