var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-block',{attrs:{"value":_vm.value,"mode":_vm.mode,"state":_vm.state},on:{"update:state":function($event){_vm.state=$event}},scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.state == 'primary-selected' && _vm.mode === 'edit')?_c('ShapeControlHandle',{staticClass:"shape-callout-control-handler-x",attrs:{"x":_vm.r,"x-range":[0, Math.min(_vm.wLP, _vm.hLP)],"y":0,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.radius = x / _vm.w)}}):_vm._e(),(_vm.state == 'primary-selected' && _vm.mode === 'edit')?_c('ShapeControlHandle',{staticClass:"shape-callout-control-handler-extremde",attrs:{"x":_vm.eX,"y":_vm.eY,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.extremeX = x / _vm.w),"update:y":y => (_vm.value.data.controls.extremeY = y / _vm.h)}}):_vm._e(),_c('svg',{staticClass:"shape-callout",staticStyle:{"vertical-align":"top"},style:(`transform: translate(${Math.min(0, _vm.eX)}px, ${Math.min(0, _vm.eY)}px)`),attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":Math.max(_vm.w, _vm.eX) - Math.min(0, _vm.eX),"height":Math.max(_vm.h, _vm.eY) - Math.min(0, _vm.eY),"preserveAspectRatio":"none","viewBox":`
        ${Math.min(0, _vm.eX)} 
        ${Math.min(0, _vm.eY)} 
        ${Math.max(_vm.w, _vm.eX) - Math.min(0, _vm.eX)} 
        ${Math.max(_vm.h, _vm.eY) - Math.min(0, _vm.eY)}
      `}},[_c('defs',[_c('path',{style:(_vm.value.data.style),attrs:{"id":`path-${_vm.id}`,"d":`
              M ${_vm.r} 0
              A ${_vm.r} ${_vm.r} 0 0 0 0 ${_vm.r}
              L 0 ${_vm.r}
              ${_vm.westPoints}
              L 0 ${_vm.h - _vm.r}
              A ${_vm.r} ${_vm.r} 0 0 0 ${_vm.r} ${_vm.h}
              L ${_vm.r} ${_vm.h}
              ${_vm.southPoints}
              L ${_vm.w - _vm.r} ${_vm.h}
              A ${_vm.r} ${_vm.r} 0 0 0 ${_vm.w} ${_vm.h - _vm.r}
              L ${_vm.w} ${_vm.h - _vm.r}
              ${_vm.eastPoints}
              L ${_vm.w} ${_vm.r}
              A ${_vm.r} ${_vm.r} 0 0 0 ${_vm.w - _vm.r} 0
              L ${_vm.w - _vm.r} 0
              ${_vm.northPoints}
              L ${_vm.r} 0
            `,"stroke-linecap":"round"}}),_c('clipPath',{attrs:{"id":`clip-${_vm.id}`}},[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`}})])]),_c('g',[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`,"clip-path":`url(#clip-${_vm.id})`}})])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }