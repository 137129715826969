import Vue from "vue"
import Dialog from "./Dialog"

export default function(filters) {
  let ModalInstance
  return new Promise((resolve, reject) => {
    ModalInstance = Vue.extend(Dialog)

    const modal = new ModalInstance({ propsData: { filters } })
    modal.$on("input", r => {
      modal.$destroy()
      modal.$el.remove()
      resolve(r)
    })
    modal.$mount()
  })
}