<template>
  <div>
    <v-toolbar class="no-print" fixed>
      <v-toolbar-title> <v-icon left>mdi-printer</v-icon>Print Preview </v-toolbar-title>
      <v-divider vertical class="px-3"></v-divider>
      <v-toolbar-title>
        <kbd>{{ custom_id }}</kbd>
        {{ title }}
        {{ revision }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn outline color="primary" @click="print">
        <span> <v-icon left>mdi-printer</v-icon>Print </span>
      </v-btn>
    </v-toolbar>
    <component :is="component"></component>
  </div>
</template>

<script>
import InteractiveText from "./DynamicIT.vue"
import PlantLibraryComponent from "./structure/PlantLibraryComponent.vue"
import interactiveTextController from "../../interactiveText/controllers/InteractiveTextController/index.js"
import { getTitle, getCustomId, getMajorVersion, getMinorVersion } from "../../../utils/dbTool"
import { renderKatex } from "plant-common"

export default {
  name: "InteractiveBookPrint",
  props: ["value"],
  data() {
    return {
      interactiveTextController: interactiveTextController(this),
      htmlContent: null
    }
  },
  computed: {
    component() {
      return {
        template: `<div id="IBContent" class="editor__page" ref="ib_component">${this.htmlContent}</div>`,
        components: {
          InteractiveText,
          PlantLibraryComponent
        },
        mounted() {
          this.renderFormulas()
        },
        methods: {
          renderFormulas() {
            const previewDiv = this.$refs.ib_component
            if (previewDiv) {
              renderKatex(previewDiv)
            }
          }
        }
      }
    },
    title() {
      return getTitle(this.value)
    },
    custom_id() {
      return getCustomId(this.value)
    },
    revision() {
      if (getMajorVersion(this.value) === "0") {
        return `(Draft)`
      } else {
        return `(Revision ${getMajorVersion(this.value)}.${getMinorVersion(this.value)})`
      }
    }
  },
  async created() {
    await this.getPageContent()
  },
  async mounted() {
    this.setIDForCss()
    await this.interactiveTextController.applyStyleSheet()
    document.title = `${this.custom_id}: ${this.title} ${this.revision}`
  },
  methods: {
    async getPageContent() {
      this.htmlContent = ""

      if (this.value.body.contents.page.html && this.value.body.contents.page.html.url) {
        const res = await fetch(this.value.body.contents.page.html.url)
        this.htmlContent = res.ok ? await res.text() : ""
      }
    },
    setIDForCss() {
      const appWrapperElement = document.querySelector(".application--wrap")

      if (!appWrapperElement) return
      appWrapperElement.id = this.interactiveTextController.styleTargetId
    },
    print() {
      window.print()
    }
  }
}
</script>

<style>
@media print {
  @page {
    size: A4;
    margin: 5mm;
  }

  p {
    widows: 3;
  }

  .library-content__wrapper {
    break-inside: avoid;
  }

  #ib-prosemirror-for-css #IBContent.editor__page {
    background-color: white !important;
    width: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    margin: unset !important;
    padding: unset !important;
    box-shadow: unset !important;
  }

  .no-print {
    visibility: hidden;
  }
}

#IBContent.editor__page {
  margin-top: 80px !important;
}

.editor__page img {
  width: 50%;
}

#ib-prosemirror-for-css .editor__page .book-plant-library__image {
  width: 100%;
}
</style>
