export function uploadFile() {
  return new Promise((resolve, reject) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.style.display = "none";
    input.onchange = function (evt) {
      if (!(evt.currentTarget.files.length > 0)) {
        reject();
        return;
      }
      var reader = new FileReader();
      reader.onload = function (e) {
        resolve(new Uint8Array(e.target.result));
        input.remove();
      };
      reader.readAsArrayBuffer(evt.currentTarget.files[0]);
    };
    input.click();
    document.body.appendChild(input);
  });
}
export function selectFileFromComputer(accept="", multiple=false) {
  return new Promise((resolve, reject) => {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", accept);
    if(multiple)input.setAttribute("multiple", true);
    input.style.display = "none";
    input.onchange = function (evt) {
      if (!(evt.currentTarget.files.length > 0)) {
        reject();
        return;
      }
     resolve( evt.currentTarget.files)
    };
    input.click();
    document.body.appendChild(input);
  });
}

export function fileToArrayBuffer(file) {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.onload = function (e) {
      resolve(new Uint8Array(e.target.result));
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}