<template>
  <v-btn-toggle :value="typeViewNumber" @change="setTypeView">
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="list">
        <v-icon>mdi-format-list-bulleted-square</v-icon>
        <slot />
      </v-btn>
      <span>List</span>
    </v-tooltip>
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="icons">
        <v-icon>mdi-format-list-text</v-icon>
        <slot />
      </v-btn>
      <span>Icons</span>
    </v-tooltip>
    <v-tooltip bottom offset-y>
      <v-btn flat slot="activator" value="preview">
        <v-icon>mdi-view-grid</v-icon>
        <slot />
      </v-btn>
      <span>Grid</span>
    </v-tooltip>
  </v-btn-toggle>
</template>

<script>
import {
  STORE_EXPLORER_NAME,
  STATE_EXPLORER_VIEW,
  MUTATION_SET_EXPLORER_VIEW
} from "@/store_constants/explorer";
import { mapState, mapMutations } from "vuex";
import ToolbarButton from "./ToolbarButton";

export default {
  components: { ToolbarButton },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      typeViewNumber: STATE_EXPLORER_VIEW
    })
  },
  methods: {
    ...mapMutations(STORE_EXPLORER_NAME, {
      setTypeView: MUTATION_SET_EXPLORER_VIEW
    })
  }
};
</script>
