// From Ruben's talk on Vue Days Alicante: https://www.youtube.com/watch?v=4fSNG39dmJY&t=3s

var watchingFunction = null
var watchingProperty = null

export function observe(data, context) {
    const depends = {}

    return new Proxy(data, {
        get(obj, key) {
            if (watchingProperty && watchingProperty.key == key) {
                if (!depends[key]) depends[key] = []
                // Avoid watching twice the same function
                if (!depends[key].includes(watchingProperty.target)) depends[key].push(watchingProperty.target)
            } else if (watchingFunction) {
                if (!depends[key]) depends[key] = []
                // Avoid watching twice the same function
                if (!depends[key].includes(watchingFunction)) depends[key].push(watchingFunction)

            }
            return obj[key]
        },
        set(obj, key, value) {
            if (obj[key] == value) {
                // if value hasn't changed, don't need to update dependants
            } else {
                obj[key] = value
                if (depends[key]) {
                    depends[key].forEach(callback => callback.apply(context));
                }
            }
            return true
        }

    })
}

export function watcher(target) {
    watchingFunction = target
    target.apply(this)
    watchingFunction = null
}

export function propertyWatcher(target, key) {
    watchingProperty = { target, key }
    target.apply(this)
    watchingProperty = null
}