import Viewer from './Viewer.vue';
import Editor from './Editor.vue';

export default {
	name: 'youtubeVideo',
	title: 'Youtube Video',
	icon: require('./content-icon.svg'),
	category: 'multimedia',
	Viewer: Viewer,
	Editor: Editor,
	Preview: Viewer
};
