import {
  STORE_NOTIFICATIONS_NAME,
  STATE_NOTIFICATIONS_STACK,
  MUTATION_NOTIFICATIONS_ADD,
  MUTATION_NOTIFICATIONS_DELETE,
  ACTION_NOTIFICATIONS_INFO,
  ACTION_NOTIFICATIONS_WARNING,
  ACTION_NOTIFICATIONS_ERROR,
  ACTION_NOTIFICATIONS_NOTIFY,
  ACTION_NOTIFICATIONS_REMOVE,
  NOTIFICATION_INFINITE_TIMEOUT
} from "@/store_constants/notifications"

let COUNTER = 0
const INFO_TYPE = "info"
const WARNING_TYPE = "warning"
const ERROR_TYPE = "error"

export default {
  name: STORE_NOTIFICATIONS_NAME,
  namespaced: true,
  state: {
    [STATE_NOTIFICATIONS_STACK]: []
  },

  mutations: {
    [MUTATION_NOTIFICATIONS_ADD](state, message) {
      message.id = message.id || ++COUNTER
      state[STATE_NOTIFICATIONS_STACK].push(message)
    },

    [MUTATION_NOTIFICATIONS_DELETE](state, message) {
      state[STATE_NOTIFICATIONS_STACK] = state[STATE_NOTIFICATIONS_STACK].filter(m => m !== message)
    }
  },

  actions: {
    [ACTION_NOTIFICATIONS_INFO]({ commit }, { message, timeout }) {
      commit(MUTATION_NOTIFICATIONS_ADD, {
        timeout: timeout == NOTIFICATION_INFINITE_TIMEOUT ? 0 : 3000,
        type: INFO_TYPE,
        message
      })
    },
    [ACTION_NOTIFICATIONS_WARNING]({ commit }, { message, timeout }) {
      commit(MUTATION_NOTIFICATIONS_ADD, {
        timeout: timeout == NOTIFICATION_INFINITE_TIMEOUT ? 0 : 3000,
        type: WARNING_TYPE,
        message
      })
    },
    [ACTION_NOTIFICATIONS_ERROR]({ commit }, { message, timeout }) {
      commit(MUTATION_NOTIFICATIONS_ADD, {
        timeout: timeout == NOTIFICATION_INFINITE_TIMEOUT ? 0 : 3000,
        type: ERROR_TYPE,
        message
      })
    },
    [ACTION_NOTIFICATIONS_NOTIFY]() {
      console.log(arguments)
    },
    [ACTION_NOTIFICATIONS_REMOVE]({ commit }, message) {
      commit(MUTATION_NOTIFICATIONS_DELETE, message)
    }
  }
}