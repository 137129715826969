import Content from "./index.vue"
export default {
  path: "dashboard",
  name: "Dashboard",
  component: Content,
  meta: {
    icon: "mdi-view-dashboard-variant",
    breadCrumb: "Dashboard"
  }
}
