<template>
  <v-data-table
    :headers="headers"
    :items="Object.values(objectives)"
    :pagination.sync="pagination"
    select-all
    class="elevation-1">
    <template v-slot:headers="props">
      <tr>
        <th
          v-for="header in props.headers"
          :key="header.text"
          :class="[
            'column sortable',
            pagination.descending ? 'desc' : 'asc',
            header.value === pagination.sortBy ? 'active' : ''
          ]"
          @click="changeSort(header.value)">
          <v-icon small>arrow_upward</v-icon>
          {{ header.text }}
        </th>
      </tr>
    </template>
    <template v-slot:items="props">
      <td class="text-center">
        <div v-html="'🎯 ' + props.item.objective.title"></div>
      </td>
      <td class="text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip v-on="on" small class="mr-2">
              <v-avatar :class="completionReasons[props.item.completionReason].color">{{
                completionReasons[props.item.completionReason].avatar
              }}</v-avatar>
              {{ completionReasons[props.item.completionReason].name }}
            </v-chip>
          </template>
          <span>{{ completionReasons[props.item.completionReason].description }}</span>
        </v-tooltip>
      </td>
      <td class="text-center">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon :color="performanceStates[props.item.quizzes[0].currentLevel].color" v-on="on"
              >mdi-circle-slice-8</v-icon
            >
          </template>
          <span>{{ performanceStates[props.item.quizzes[0].currentLevel].name }}</span>
        </v-tooltip>
      </td>
    </template>
    <template v-slot:no-data>
      <div class="text-center">
        <p class="mt-3">No objectives found</p>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import completionReasons from "../constants/completionReasons.js"
import performaceStates from "../constants/performaceStates.js"

export default {
  name: "AssessmentSummaryDataTable",
  props: {
    objectives: Object
  },
  data() {
    return {
      headers: [
        {
          text: "Objective",
          value: "objective.title"
        },
        { text: "Content", value: "completionReason" },
        { text: "Level", value: "quizzes[0].currentLevel" }
      ],
      pagination: {
        sortBy: "name",
        rowsPerPage: -1 // -1 for All",
      },
      completionReasons: completionReasons,
      performanceStates: performaceStates
    }
  },
  computed: {},
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    }
  }
}
</script>

<style scoped></style>
