import Vue from "vue"
import { Slide } from "../../../../lessonPlan/models/models"
import BlockLibraryContent from "../../../../lessonPlan/blocks/libraryContent/class"
import { cloneDeep, deepSlideCopy } from "../../../../lessonPlan/utils/utils.js"
import uuid from "../../../../../utils/uuid.js"
import { resetVisitedSlide } from "../../../../lessonPlan/models/upgradeModel.js"
import PServiceContent from "plant-common/src/services/PServiceContent"

export async function transitionGuideToLessonPlan(contents, template, layouts, metadata, comments) {
  // body
  Vue.set(contents, "version", 10)
  Vue.set(contents, "settings", {
    styles: {
      wrapper: {
        backgroundColor: "#ffffff"
      },
      slide: {
        backgroundColor: "#ffffff",
        color: "#000000",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2),0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        border: "2px solid #ccc"
      },
      colors: ["#ffffffff", "#ffffffff", "#ffffffff", "#ffffffff", "#ffffffff", "#ffffffff", "#ffffffff", "#ffffffff"],
      block: {
        fontFamily: "Roboto"
      }
    }
  })
  Vue.set(contents, "templates", [template])

  const importedLayouts = await getImportedLayouts(template, metadata.content_id)
  const importedGalleries = await getImportedGalleries(template, metadata.content_id)
  Vue.set(contents, "layouts", importedLayouts)
  Vue.set(contents, "galleries", importedGalleries)
  let slides = await createSlides(contents, layouts, metadata, comments)

  // Add terminal Objective to the section with name Objectives or the first section
  let objectivesSection = slides.find(slide => slide.title == "Objectives") || slides.find(slide => slide.children)
  if (objectivesSection) {
    let obj = await PServiceContent.findContent({
      open_custom_id: contents.learning_objective.header.metadata.custom_id
    })
    objectivesSection.objectives.push(obj)
  }

  Vue.set(contents, "slides", slides)

  delete contents["cover"]
  delete contents["learning_objective"]
  delete contents["sections"]
  delete contents["template"]

  // header
  Vue.set(metadata, "content_revision_type", "lessonPlan")
}

function updateCommentIds(comments, oldId, newId) {
  comments
    .filter(comment => comment.location && comment.location.card_id == oldId)
    .map(commentToUpdate => {
      Vue.set(commentToUpdate.location, "slide_id", newId)
      Vue.set(commentToUpdate, "toUpdateLocation", true)
    })
}

async function createSlides(contents, layouts, metadata, comments) {
  // Extract Learning Objective
  const { learning_objective } = contents
  let enablingObjectiveMap = {}
  if (learning_objective) {
    enablingObjectiveMap = learning_objective.body.contents.enabling.reduce((prev, curr) => {
      prev[curr.id] = { customId: curr.custom_id }
      return prev
    }, {})
  }
  const slides = []
  // cover in position 0
  const cover = createSectionLayout(layouts, "cover", metadata.title)
  // update comments for cover
  updateCommentIds(comments, contents.cover.id, cover.id)
  addItemInOrder(slides, cover, 0)
  // sections from position 1
  for (let index = 0; index < contents.sections.length; index++) {
    let slide = contents.sections[index]
    await addSlide(slides, slide, layouts, comments, index + 1, enablingObjectiveMap)
  }

  return slides
}

function createSectionLayout(layouts, type, title, bookmark) {
  const layout = cloneDeep(layouts[type].slide)
  layout.id = uuid()
  if (title) {
    layout.title = title
  }
  if (bookmark) {
    layout.bookmark = bookmark
  }
  return layout
}

async function addSlide(parent, slide, layouts, comments, index, enablingObjectiveMap) {
  const parentTree = Array.isArray(parent) ? parent : parent.children
  if (slide.type === "section") {
    let title = false
    if (slide.fields && slide.fields.title) {
      title = slide.fields.title
    }
    const section = createSectionLayout(layouts, "section", title, slide.internal_link_name)
    addItemInOrder(parentTree, section, index)
    section.children = []
    for (let index = 0; index < slide.children.length; index++) {
      let child = slide.children[index]
      await addSlide(section, child, layouts, comments, index, enablingObjectiveMap)
    }

    if (slide.enabling_objectives) {
      // Manage objectives
      for (var objective of slide.enabling_objectives) {
        let obj = await PServiceContent.findContent({ open_custom_id: enablingObjectiveMap[objective].customId })
        if (obj) section.objectives.push(obj)
        else alert(`Objective not found: ${obj}`)
      }

      // update comments for section
      updateCommentIds(comments, slide.id, section.id)
    }
  }
  if (slide.type === "card") {
    const card = await createSlideContent(slide.content, layouts)
    if (slide.notes) {
      card.notes.speaker = slide.notes
    }
    addItemInOrder(parentTree, card, index)
    // update comments for card
    updateCommentIds(comments, slide.id, card.id)
  }
  if (slide.type === "card-template") {
    const cardTemplate = createSectionLayout(layouts, slide.content.template_name, slide.content.template_name)
    addItemInOrder(parentTree, cardTemplate, index)
    // update comments for card-template
    updateCommentIds(comments, slide.id, cardTemplate.id)
  }
}

function addItemInOrder(parent, child, index) {
  parent.splice(index, 0, child)
}

async function createSlideContent(content, layouts) {
  let slide
  if (layouts["slide"]) {
    slide = cloneDeep(layouts["slide"].slide)
  } else {
    slide = new Slide()
  }
  slide.id = uuid()
  let block = slide.blocks.find(block => block.type === "libraryContent")

  if (!block) {
    block = new BlockLibraryContent()
    block.size.width = 1920
    block.size.height = 1080
    slide.blocks.unshift(block)
  }

  block.data.content_ref = content
  if (!content.header) {
    // BulkChange operation does not have slide content dereferenced
    var content = await PServiceContent.getContentFromRef(content.$ref)
  }
  slide.title = content.header.metadata.title
  return slide
}

async function getImportedGalleries(template, contentId) {
  const galleries = []
  const importedGalleries = template.reference.body.contents.galleries.filter(gallery => gallery.imported === false)
  for (var importedGallery of importedGalleries) {
    galleries.push({
      id: importedGallery.id,
      imported: template.id,
      name: importedGallery.name,
      stencils: await deepSlideCopy(importedGallery.stencils, contentId)
    })
  }
  return galleries
}

async function getImportedLayouts(template, contentId) {
  const layouts = []
  const importedLayouts = template.reference.body.contents.layouts.filter(layout => layout.imported === false)
  for (var importedLayout of importedLayouts) {
    resetVisitedSlide(importedLayout.slide)
    layouts.push({
      id: importedLayout.id,
      imported: template.id,
      name: importedLayout.name,
      slide: await deepSlideCopy(importedLayout.slide, contentId)
    })
  }
  return layouts
}
