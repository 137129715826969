import Vue from "vue"
import { getLibraryId, uploadFile } from "../../../utils"
import PServiceContent from "../../../services/PServiceContent"
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = "" + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value
  let view = false
  let tag = false
  let fromVR = false
  if (vm.$props.selectedTag) {
    tag = vm.$props.selectedTag
  }
  if (vm.$props.from) {
    fromVR = vm.$props.from
  }
  if (vm.$attrs.view) {
    view = vm.$attrs.view
  }
  return new Vue({
    data() {
      return {
        vm: vm,
        pannellum: false,
        config: false,
        current: {
          hfov: false,
          yaw: false,
          pitch: false
        },
        rightDrawerStatus: false,
        datasheet: false
      }
    },
    computed: {
      file() {
        if (itemRevision.body.attachments && itemRevision.body.attachments.length > 0) {
          return itemRevision.body.attachments[0]
        } else {
          return false
        }
      },
      defaultView() {
        if (view) {
          return view
        } else {
          if (itemRevision.body.contents) {
            return {
              hfov: itemRevision.body.contents.hfov || 120,
              yaw: itemRevision.body.contents.yaw || 0,
              pitch: itemRevision.body.contents.pitch || 0
            }
          } else {
            return {
              hfov: 120,
              yaw: 0,
              pitch: 0
            }
          }
        }
      },
      fromVR() {
        return fromVR
      },
      itemRevisionObject() {
        return itemRevision
      },
      hotspots() {
        if (itemRevision.body.contents.hotspots.length > 0) {
          itemRevision.body.contents.hotspots.forEach(hp => {
            hp.type === 'text' ? (hp.cssClass = 'text-hotspot') : (hp.cssClass = 'link-hotspot');
          });

          return itemRevision.body.contents.hotspots;
        }

        return [];
      },
      selectedTag() {
        return tag
      }
    },
    created() {
      if (!itemRevision.body.contents) {
        this.$set(itemRevision.body, "contents", {})
      }

      if (!itemRevision.body.contents.hotspots) this.$set(itemRevision.body.contents, "hotspots", [])
    },
    methods: {
      registerPanzoom(panzoom) {
        this.panzoom = panzoom
      },
      hotspotClick(itemTag) {
        vm.$emit("select", itemTag)
        this.findDatasheet(itemTag)
      },
      zoomIn() {
        this.pannellum.setHfov(this.pannellum.getHfov() - 10)
      },
      zoomOut() {
        this.pannellum.setHfov(this.pannellum.getHfov() + 10)
      },
      zoomReset() {
        this.pannellum.lookAt(this.defaultView.pitch, this.defaultView.yaw, this.defaultView.hfov)
      },
      setView(hs) {
        this.pannellum.lookAt(hs.pitch, hs.yaw, hs.hfov)
      },
      destroyPannellum() {
        this.pannellum.destroy()
      },
      init({ divElement, clickHandlerFunc, createTooltipFunc, from }) {
        this.config = {
          divElement,
          clickHandlerFunc,
          createTooltipFunc,
          fromVR
        }
        var initialView = this.defaultView
        if (this.selectedTag) {
          let view = false
          view = this.hotspots.filter(e => e.text === this.selectedTag)
          if (view.length > 0) initialView = view[0]
        }
        if (from) {
          this.hotspots.forEach(hs => {
            if (hs.type == "link" && hs.itemRevisionId == from) {
              initialView.hfov = 120
              initialView.yaw = hs.yaw + (hs.yaw < 0 ? 180 : -180)
              initialView.pitch = hs.pitch
            }
          })
        }
        if (this.file) this.refresh(initialView)
      },
      refresh({ hfov, yaw, pitch }) {
        if (this.pannellum) {
          this.pannellum.destroy()
        }
        var hotSpots = JSON.parse(JSON.stringify(this.hotspots))
        var i = 0
        hotSpots.forEach(hs => {
          hs.clickHandlerFunc = this.config.clickHandlerFunc
          hs.clickHandlerArgs = i
          hs.createTooltipFunc = this.config.createTooltipFunc
          hs.createTooltipArgs = i
          i++
        })
        this.pannellum = window.pannellum.viewer(this.config.divElement, {
          type: "equirectangular",
          panorama: this.file.url,
          autoLoad: true,
          hotSpotDebug: true,
          hotSpots,
          showControls: false,
          hfov,
          yaw,
          pitch
        })
        this.pannellum.on("mouseup", () => {
          this.current.hfov = this.pannellum.getHfov()
          this.current.yaw = this.pannellum.getYaw()
          this.current.pitch = this.pannellum.getPitch()
        })
        this.pannellum.on("zoomchange", hfov => {
          this.current.hfov = hfov
          this.current.yaw = this.pannellum.getYaw()
          this.current.pitch = this.pannellum.getPitch()
        })
      },
      uploadPicture(file) {
        if (file) {
          let url = URL.createObjectURL(file)
          vm.value.body.attachments.push(new Attachment("Picture", file, url, ".jpg"))
        } else {
          uploadFile().then(({ url, file }) => {
            vm.value.body.attachments.push(new Attachment("Picture", file, url, ".jpg"))
            this.init(this.config)
          })
        }
      },
      removePicture() {
        this.pannellum.destroy()
        vm.value.body.attachments.splice(0)
        this.hotspots.splice(0)
      },
      addHotspot() {
        let newHotspot = {
          hfov: this.pannellum.getHfov(),
          yaw: this.pannellum.getYaw(),
          pitch: this.pannellum.getPitch(),
          value: "New",
          type: "text"
        }
        if (!vm.value.body.contents.hotspots) {
          Vue.set(vm.value.body.contents, "hotspots", [])
        }
        vm.value.body.contents.hotspots.push(newHotspot)
        this.refresh({
          hfov: newHotspot.hfov,
          yaw: newHotspot.yaw,
          pitch: newHotspot.pitch
        })
      },
      removeHotspot(index) {
        vm.value.body.contents.hotspots.splice(index, 1)
        this.refresh({
          hfov: this.pannellum.getHfov(),
          yaw: this.pannellum.getYaw(),
          pitch: this.pannellum.getPitch()
        })
      },
      setDefaultView() {
        vm.value.body.contents.hfov = this.pannellum.getHfov()
        vm.value.body.contents.pitch = this.pannellum.getPitch()
        vm.value.body.contents.yaw = this.pannellum.getYaw()
      },
      toggleRightDrawer() {
        this.rightDrawerStatus = !this.rightDrawerStatus
      },
      findDatasheet(tag) {
        this.datasheet = false
        this.$nextTick(() => {
          if (tag) {
            PServiceContent.findContent({ type: "datasheet", custom_id: tag, library_id: getLibraryId(vm.value) })
              .then(ds => {
                this.datasheet = ds[0]
                if (!this.rightDrawerStatus) this.toggleRightDrawer()
              })
              .catch(() => {
                this.datasheet = false
                if (this.rightDrawerStatus) this.toggleRightDrawer()
              })
          }
        })
      }
    },
    watch: {
      current: {
        deep: true,
        handler() {
          vm.$emit("update:view", this.current)
        }
      },
      "vm.$attrs.view"() {
        if (this.pannellum.getHfov() !== this.vm.$attrs.view.hfov) this.pannellum.setHfov(this.vm.$attrs.view.hfov)
        this.pannellum.setPitch(this.vm.$attrs.view.pitch)
        this.pannellum.setYaw(this.vm.$attrs.view.yaw)
      }
    }
  })
}
