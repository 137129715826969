<template>
  <p-panel-properties>
    <p-property-tile title="Color" icon="mdi-format-color-fill">
      <preset-input-color :value="data.color" @input="changeAndSetColor"></preset-input-color>
    </p-property-tile>
    <p-property-tile icon="mdi-format-font" title="Font-family" v-if="showFontFamily">
      <p-panel-input-select :items="blockGlobals.fontFamilies" v-model="data.fontFamily"></p-panel-input-select>
    </p-property-tile>
    <p-property-tile title="Font size" icon="mdi-format-size">
      <toolbar-textarea-font-size :data="data" :side="side" :id="id" :font-sizes-numbers="fontSizesNumbers" />
    </p-property-tile>
    <p-property-tile v-if="showAlternatePadding" title="Padding" icon="mdi-arrow-all" :extended="true">
      <template v-if="!paddingActionLocked" slot="content">
        <div class="padding-values">
          <div class="padding-values__item">
            <span>Top</span>
            <p-panel-simple-input-number :min="0" v-model="data.padding[0]" :height="30"></p-panel-simple-input-number>
          </div>
          <div class="padding-values__item">
            <span>Right</span>
            <p-panel-simple-input-number :min="0" v-model="data.padding[1]" :height="30"></p-panel-simple-input-number>
          </div>
          <div class="padding-values__item">
            <span>Bottom</span>
            <p-panel-simple-input-number :min="0" v-model="data.padding[2]" :height="30"></p-panel-simple-input-number>
          </div>
          <div class="padding-values__item">
            <span>Left</span>
            <p-panel-simple-input-number :min="0" v-model="data.padding[3]" :height="30"></p-panel-simple-input-number>
          </div>
        </div>
      </template>
      <div class="padding-action">
        <p-panel-simple-input-number
          v-if="paddingAllSameValues"
          :min="0"
          v-model="paddingTop"></p-panel-simple-input-number>
        <v-text-field v-else placeholder="mixed" disabled></v-text-field>
        <v-icon @click="tooglePaddingAction">{{ paddingActionIcon }}</v-icon>
      </div>
    </p-property-tile>
    <p-property-tile v-else title="Padding" icon="mdi-arrow-all">
      <toolbar-textarea-padding :data="data" :side="side" :id="id" />
    </p-property-tile>
    <p-property-tile
      v-if="showParagraphSpacing"
      title="Paragraph spacing"
      icon="mdi-format-line-spacing"
      maxWidth="25%">
      <p-panel-input-number :min="0" v-model="data.paragraphSpacing"></p-panel-input-number>
    </p-property-tile>
  </p-panel-properties>
</template>

<script>
import PPanelProperties from "../utils/PPanelProperties.vue"
import PPropertyTile from "../utils/PPropertyTile.vue"
import PPanelInputSelect from "../utils/PPanelInputSelect.vue"
import PPanelSimpleInputNumber from "../utils/PPanelSimpleInputNumber.vue"
import PPanelInputNumber from "../utils/PPanelInputNumber.vue"
import ToolbarTextareaFontSize from "../../textarea/ToolbarTextareaFontSize.vue"
import ToolbarTextareaPadding from "../../textarea/ToolbarTextareaPadding.vue"
import { changeColor } from "../../textarea/utils.js"
import PresetInputColor from "../../../components/slides/styles/PresetInputColor.vue"

export default {
  inject: ["blockGlobals"],
  props: [
    "data",
    "side",
    "id",
    "type",
    "fontSizesNumbers",
    "showFontFamily",
    "showAlternatePadding",
    "showParagraphSpacing"
  ],
  components: {
    ToolbarTextareaFontSize,
    ToolbarTextareaPadding,
    PresetInputColor,
    PPanelProperties,
    PPropertyTile,
    PPanelInputSelect,
    PPanelSimpleInputNumber,
    PPanelInputNumber
  },
  data() {
    return {
      paddingTop: this.data.padding[0]
    }
  },
  computed: {
    paddingActionLocked() {
      return this.data.paddingLocked
    },
    paddingActionIcon() {
      return this.paddingActionLocked ? "mdi-lock-outline" : "mdi-lock-open-outline"
    },
    paddingAllSameValues() {
      return this.showAlternatePadding ? new Set(this.data.padding).size === 1 : false
    }
  },
  watch: {
    paddingTop: {
      immediate: false,
      handler(value) {
        this.data.padding = [value, value, value, value]
      }
    },
    "data.paragraphSpacing": {
      handler() {
        const style = document.getElementById(`${this.type}-${this.id}`)
        style.innerHTML = `.${this.type}-${this.id} .lp-block-textarea .ProseMirror p,
.${this.type}-${this.id} .lp-block-textarea .view p {
  margin-bottom: ${this.data.paragraphSpacing}px;
}`
      }
    },
    paddingAllSameValues: {
      handler(value) {
        if (value) this.paddingTop = this.data.padding[0]
      }
    }
  },
  methods: {
    changeAndSetColor($event) {
      this.data.color = $event
      changeColor(this.id, this.data.color, this.side)
    },
    tooglePaddingAction() {
      this.data.paddingLocked = !this.data.paddingLocked
    }
  }
}
</script>

<style>
.padding-action,
.padding-values {
  display: flex;
}

.padding-values {
  border: 1px solid lightgrey;
  margin-top: 5px;
  margin-right: 5px;
  padding: 5px;
}

.padding-values__item {
  flex-direction: column;
}

.padding-values__item:not(:last-child) {
  border-right: 1px solid lightgrey;
  margin-right: 2px;
  padding: 0 2px;
}

.padding-values__item .v-text-field,
.padding-action .v-text-field {
  margin: 0;
  padding: 0;
}

.v-list--dense .extended.extended-height .v-list__tile {
  height: auto;
}
</style>
