// import analysisTask from "./analysis-task"
// import analysisKnowledge from "./analysis-knowledge"
// import analysisJob from "./analysis-job"
// import dynamicLearningActivity from "./dynamic-learning-activity"
import activityDiagram from "./activity-diagram"
import activityDiagramCondition from "./activity-diagram-condition"
import activityFillTheGap from "./activity-fillthegap"
import activityFlashCard from "./activity-flashcard"
import activityMultipleChoice from "./activity-multiple-choice"
import activityPicture from "./activity-picture"
import activityVr from "./activity-vr"
import contentCollection from "./content-collection"
import course from "./course"
import datasheet from "./datasheet"
import datasheetConfig from "./datasheet-config"
import diagram from "./diagram"
import document from "./document"
import glbModel from "./glb-model"
import glossary from "./glossary"
import guide from "./guide"
import guideTemplate from "./guideTemplate"
import interactiveBook from "./interactive-book"
import interactiveText from "./interactive-text"
import learningObjective from "./learning-objective"
import lessonPlan from "./lessonPlan"
import interactiveSlide from "./interactive-slide"
import objective from "./objective"
import picture from "./picture"
import pointCloud from "./point-cloud"
import scorm from "./scorm"
import smartLesson from "./smart-lesson"
import styleSheet from "./styleSheet"
import term from "./term"
import video from "./video"
import videovr from "./videovr"
import vr from "./vr"
import youtubeVideo from "./youtube-video"

export default {
  // analysisTask,
  // analysisKnowledge,
  // analysisJob,
  // dynamicLearningActivity,
  activityDiagram,
  activityDiagramCondition,
  activityFillTheGap,
  activityFlashCard,
  activityMultipleChoice,
  activityPicture,
  activityVr,
  contentCollection,
  course,
  datasheet,
  datasheetConfig,
  diagram,
  document,
  glbModel,
  glossary,
  guide,
  guideTemplate,
  interactiveBook,
  interactiveText,
  learningObjective,
  lessonPlan,
  interactiveSlide,
  objective,
  picture,
  pointCloud,
  scorm,
  smartLesson,
  styleSheet,
  term,
  video,
  videovr,
  vr,
  youtubeVideo
}
