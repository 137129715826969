<template>
  <v-chip small v-if="job.failedAt" color="error" text-color="white">
    <v-avatar>
      <v-icon>mdi-close-circle-outline</v-icon>
    </v-avatar>
    FAILED
  </v-chip>
  <v-chip
    small
    v-else-if="job.lastFinishedAt"
    color="success"
    text-color="white"
  >
    <v-avatar>
      <v-icon>mdi-check-circle-outline</v-icon>
    </v-avatar>
    DONE
  </v-chip>
  <v-chip small v-else-if="job.lastRunAt" color="warning" text-color="white">
    <v-avatar>
      <v-icon>mdi-circle</v-icon>
    </v-avatar>
    RUNNING
  </v-chip>
  <v-chip small v-else color="gray" text-color="white">
    <v-avatar>
      <v-icon>mdi-circle-outline</v-icon>
    </v-avatar>
    QUEUED
  </v-chip>
</template>

<script>
export default {
  name: "StatusJob",
  props: {
    job: Object
  }
};
</script>

<style>
</style>