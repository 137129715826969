import DiagramReplace from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "diagram-replace-condition-details",
  title: "Replace Diagram Condition Details",
  description:
    'This operation replaces the "find" value (Case sensitive) with the "replace" value in all diagram condition details',
  contentType: "diagram",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.body && content.body.attachments) {
        content.body.attachments.forEach(attachment => {
          if (attachment.attachment_type == ".json") {
            if (attachment.metadata) {
              if (attachment.metadata.title) {
                attachment.metadata.title = attachment.metadata.title.toString().replace(searchRegExp, replaceWith)
              }
              if (attachment.metadata.description) {
                attachment.metadata.description = attachment.metadata.description
                  .toString()
                  .replace(searchRegExp, replaceWith)
              }
            }
          }
        })
      }
    }
    return content
  },
  OperationOptionsComponent: DiagramReplace
}
