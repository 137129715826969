<template>
  <component v-bind:is="component" :value="itemRevision" :view.sync="current_view" :mode="embeded_ip ? '' : 'preview'" />
</template>

<script>
import { mapState, mapActions } from "vuex"
import content_store from "@/store/content"
import { Extensions } from "plant-common"
import { ItemViewer } from "plant-common"
import Loading from "../_layout/Loading"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveBookViewer from "@/components/interactiveBook"
import interactiveTextViewer from "@/components/interactiveText"
import changeDocumentTitleAndIcon from "@/utils/changeDocumentTitleAndIcon.js"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  ACTION_LOAD_CONTENT,
  MUTATION_DESTROY_CONTENT
} from "@/store_constants/content"
import { getType } from "../../../utils/dbTool"

export default {
  name: "ContentPreviewer",
  props: ["id", "major_revision", "minor_revision", "embeded_ip"],
  data() {
    return {
      component: Loading,
      Extensions,
      current_view: undefined
    }
  },
  components: { ItemViewer },
  beforeCreate() {
    if (this.$store.registerModule(STORE_CONTENT_NAME, content_store) === false) {
      this.$store.unregisterModule(STORE_CONTENT_NAME)
      this.$store.registerModule(STORE_CONTENT_NAME, content_store)
    }
  },
  created() {
    this.$nextTick(() => {
      try {
        if (this.$route.hash.substr(1)) {
          this.current_view = JSON.parse(decodeURI(this.$route.hash.substr(1)))
        }
      } catch (e) {
        console.warn(`The URL hash passed can not be parsed: ${this.$route.hash.substr(1)}`)
      }
      this.reloadContent()
    })
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_CONTENT, MUTATION_DESTROY_CONTENT]),
    loadContentInView(itemRevision) {
      if (!itemRevision) return
      const content_type = this.getType(itemRevision)
      // Guide and lesson plan contents placed inside app
      Extensions["guide"] = guideItemViewer
      Extensions["guideTemplate"] = guideTemplateItemViewer
      Extensions["lessonPlan"] = lessonPlanViewer
      Extensions["interactiveSlide"] = interactiveSlideViewer
      Extensions["interactiveText"] = interactiveTextViewer
      Extensions["interactiveBook"] = interactiveBookViewer
      if (Extensions[content_type] && Extensions[content_type]["Preview"]) {
        switch (content_type) {
          case "guide":
            this.component = guideItemViewer["Preview"]
            break
          case "guideTemplate":
            this.component = guideTemplateItemViewer["Preview"]
            break
          case "lessonPlan":
            this.component = lessonPlanViewer["Preview"]
            break
          case "interactiveSlide":
            this.component = interactiveSlideViewer["Preview"]
            break
          case "interactiveText":
            this.component = interactiveTextViewer["Preview"]
            break
          case "interactiveBook":
            this.component = interactiveBookViewer["Preview"]
            break
          default:
            this.component = ItemViewer
            break
        }
      } else {
        this.component = {
          template: `<div>Preview for the extension ${content_type} not found (ERR: app).</div>`
        }
      }
    },
    reloadContent() {
      let itemToLoad = {
        id: this.id,
        major_revision: this.major_revision,
        minor_revision: this.minor_revision == 0 ? "0" : this.minor_revision
      }

      this[ACTION_LOAD_CONTENT](itemToLoad)
        .then(() => {
          this.loadContentInView(this.itemRevision)
        })
        .then(() => {
          if (this.itemRevision) {
            var { title, custom_id, content_revision_type } = this.itemRevision.header.metadata
            changeDocumentTitleAndIcon(`[${custom_id}] ${title}`, Extensions[content_revision_type].icon)
          }
        })
    },
    getType: getType
  },
  destroyed() {
    this[MUTATION_DESTROY_CONTENT]()
  },
  watch: {}
}
</script>
