import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "datasheetConfig",
    title: "Datasheet Config",
    icon: require("./content-icon.svg"),
    category: "engineering",
    Viewer,
    Editor,
    Preview: Viewer
}