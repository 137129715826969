<template>
    <fragment>
        <p-panel-properties-group title="Begin" data-e2e-id="cy-Begin">
            <item-style title="Type" class="lp-animation-form-item">
                <v-select 
                    :items="availableTypes" 
                    item-text="value"
                    v-model="value.data.begin.marker.type" 
                    data-e2e-id="cy-Begin-Type"
                >
                    <template #item="{ item }">
                        <div :data-e2e-id="`cy-Begin-Type-${item.icon}`">
                            <v-icon> {{ item.icon }} </v-icon>
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <div :data-e2e-id="`cy-Begin-Type-${item.icon}`">
                            <v-icon> {{ item.icon }} </v-icon>
                        </div>
                    </template>
                </v-select>
            </item-style>
            <item-style v-if="value.data.begin.marker.type != 'none'" title="Size" class="lp-animation-form-item">
                <v-select 
                    :items="sizeTypes"
                    item-text="name"
                    item-value="value"
                    v-model="value.data.begin.marker.size"
                    data-e2e-id="cy-Begin-Size"
                >
                    <template #item="{ item }">
                        <div :data-e2e-id="`cy-Begin-Size-${item.name}`">
                            {{ item.name }}
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <div :data-e2e-id="`cy-Begin-Size-${item.name}`">
                            {{ item.name }}
                        </div>
                    </template>
                </v-select>
            </item-style>
        </p-panel-properties-group>
        <p-panel-properties-group title="End" data-e2e-id="cy-End">
            <item-style title="Type" class="lp-animation-form-item">
                <v-select 
                    :items="availableTypes" 
                    item-text="value"
                    v-model="value.data.end.marker.type"
                    data-e2e-id="cy-End-Type"
                >
                    <template #item="{ item }">
                        <div :data-e2e-id="`cy-End-Type-${item.icon}`">
                            <v-icon style="transform: rotate(180deg)"> {{ item.icon }} </v-icon>
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <div :data-e2e-id="`cy-End-Type-${item.icon}`">
                            <v-icon style="transform: rotate(180deg)"> {{ item.icon }} </v-icon>
                        </div>
                    </template>
                </v-select>
            </item-style>
            <item-style v-if="value.data.end.marker.type != 'none'" title="Size" class="lp-animation-form-item">
                <v-select 
                    :items="sizeTypes"
                    item-text="name"
                    item-value="value"
                    v-model="value.data.end.marker.size"
                    data-e2e-id="cy-End-Size"
                >
                    <template #item="{ item }">
                        <div :data-e2e-id="`cy-End-Size-${item.name}`">
                            {{ item.name }}
                        </div>
                    </template>
                    <template #selection="{ item }">
                        <div :data-e2e-id="`cy-End-Size-${item.name}`">
                            {{ item.name }}
                        </div>
                    </template>
                </v-select>
            </item-style>
        </p-panel-properties-group>
    </fragment>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import { Fragment } from "vue-fragment"
import ItemStyle from "../../components/slides/styles/ItemStyle.vue"
import availableTypes from "./markers/Markers.js"

export default {
  inject: ["blockGlobals"],
  props: ["value"],
  components: {
    PPropertyTile,
    PPanelPropertiesGroup,
    Fragment,
    ItemStyle
  },

  data() {
    return {
      availableTypes,
      sizeTypes: [
        { name: "Small",  value: 0.5 },
        { name: "Medium", value: 1 },
        { name: "Large",  value: 1.5 }
      ]
    }
  },
}
</script>

<style>
.lp-block-carousel-panel {
  font-size: 13px;
  margin: 5px 0 0 25px;
  font-weight: 500;
}
.lp-animation-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>