<template>
  <div>
    <is-latest-warning v-if="!is_student" @openLatestVersion="openLatest" />
    <v-list-tile avatar>
      <v-list-tile-action>
        <content-type-icon :type="getType(selectedCard.content)" width="35px"></content-type-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-sub-title>{{ getCustomId(selectedCard.content) }}</v-list-tile-sub-title>
        <v-list-tile-title>{{ getTitle(selectedCard.content) }}</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-btn icon @click="openSpecific()">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-action>
        <v-icon>mdi-history</v-icon>
      </v-list-tile-action>
      <v-list-tile-content>
        <v-list-tile-sub-title>Revision</v-list-tile-sub-title>
        <v-list-tile-title
          >{{ selectedCard.content.header.major_revision }}.{{
            selectedCard.content.header.minor_revision
          }}</v-list-tile-title
        >
      </v-list-tile-content>
    </v-list-tile>
    <template v-if="currentViewCard">
      <v-divider></v-divider>
      <v-subheader>Current View</v-subheader>
      <v-list-tile avatar v-for="(value, name) in currentViewCard" :key="name">
        <v-list-tile-avatar>
          <v-icon>mdi-alpha-p</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ name }}</v-list-tile-sub-title>
          <v-list-tile-title>{{ value }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { mapInjectedData } from "plant-common/src/utils"
import { STORE_USER_NAME, STATE_PROFILE } from "../../../../store_constants/user"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "../../../../store_constants/library"
import { roles } from "../../../../config/constants"
import { ContentTypeIcon } from "plant-common"
import IsLatestWarning from "./IsLatestWarning.vue"
import { getCustomId, getContentId, getTitle, getType } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "GuideInfo",
  inject: ["guideController"],
  components: {
    ContentTypeIcon,
    IsLatestWarning
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "currentViewCard"]),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    is_student() {
      return this.profile.role == roles.student.value
    }
  },
  methods: {
    openSpecific() {
      var { major_revision, minor_revision } = this.selectedCard.content.header
      window.open(
        `/content/${this.settings._id}/${getContentId(
          this.selectedCard.content
        )}/${major_revision}/${minor_revision.toString()}`
      )
    },
    openLatest() {
      PServiceContent.openContent(getContentId(this.selectedCard.content), "tab")
    },
    getContentId: getContentId,
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType
  }
}
</script>
