<template>
  <p-panel-properties class="lp-arrange-form-arrange">
    <item-style
      v-for="arrange in arrangeOptions"
      :key="arrange.title"
      :title="arrange.title"
      class="lp-arrange-form-item">
      <v-list class="ml-4">
        <v-list-tile v-for="option in arrange.options" :key="option.title">
          <v-list-tile-action>
            <v-btn-toggle style="box-shadow: none" class="pt-button-group">
              <v-tooltip bottom v-for="action in option.actions" :key="action.title">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-btn
                      @click="action.onClick"
                      :disabled="action.disabled"
                      flat
                      class="lp-block-properties-size-position-button">
                      <v-icon>{{ action.icon }}</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ action.title }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </item-style>
  </p-panel-properties>
</template>

<script>
import ItemStyle from "../../../components/slides/styles/ItemStyle.vue"
import PPanelProperties from "../utils/PPanelProperties.vue";
import _ from "lodash"
const TABS_TYPE = "tabs"

export default {
  components: { ItemStyle, PPanelProperties },
  props: ["value"],
  inject: ["blockController"],
  data() {
    return {
      selectedBlock: this.value,
      contextBlock: null
    }
  },
  created() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block, state }) => {
        if (block) {
          this.updateContext(block.id)
        }
        this.selectedBlock = block
      })
    }

    if (this.selectedBlock) {
      this.updateContext(this.selectedBlock.id)
    }
  },
  computed: {
    width() {
      return this.size.width
    },
    height() {
      return this.size.height
    },
    size() {
      if (!this.contextBlock) {
        return { width: 1920, height: 1080 }
      }
      return this.calculateSize(this.contextBlock)
    },
    arrangeOptions() {
      return [
        { title: "Align Horizontal", options: this.alignHorizontalOptions },
        { title: "Align Vertical", options: this.alignVerticalOptions },
        { title: "Stretch", options: this.stretchOptions },
        { title: "Order", options: this.orderOptions },
        { title: "Flip", options: this.flipOptions },
        { title: "Rotate", options: this.rotateOptions }
      ]
    },
    alignHorizontalOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Align Horizontal",
          actions: [
            {
              title: this.selectedBlock.protection.move
                ? "Align left disabled, this block is protected against move"
                : "Align left",
              icon: "mdi-align-horizontal-left",
              onClick: () => {
                this.selectedBlock.position.x = 0
              },
              disabled: this.selectedBlock.protection.move
            },
            {
              title: this.selectedBlock.protection.move
                ? "Align Center disabled, this block is protected against move"
                : "Align Center",
              icon: "mdi-align-horizontal-center",
              onClick: () => {
                this.selectedBlock.position.x = this.width / 2 - this.selectedBlock.size.width / 2
              },
              disabled: this.selectedBlock.protection.move
            },
            {
              title: this.selectedBlock.protection.move
                ? "Align Right disabled, this block is protected against move"
                : "Align Right",
              icon: "mdi-align-horizontal-right",
              onClick: () => {
                this.selectedBlock.position.x = this.width - this.selectedBlock.size.width
              },
              disabled: this.selectedBlock.protection.move
            }
          ]
        }
      ]
    },
    alignVerticalOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Align Vertical",
          actions: [
            {
              title: this.selectedBlock.protection.move
                ? "Align Top disabled, this block is protected against move"
                : "Align Top",
              icon: "mdi-align-vertical-top",
              onClick: () => {
                this.selectedBlock.position.y = 0
              },
              disabled: this.selectedBlock.protection.move
            },
            {
              title: this.selectedBlock.protection.move
                ? "Align Middle disabled, this block is protected against move"
                : "Align Middle",
              icon: "mdi-align-vertical-center",
              onClick: () => {
                this.selectedBlock.position.y = this.height / 2 - this.selectedBlock.size.height / 2
              },
              disabled: this.selectedBlock.protection.move
            },
            {
              title: this.selectedBlock.protection.move
                ? "Align Bottom disabled, this block is protected against move"
                : "Align Bottom",
              icon: "mdi-align-vertical-bottom",
              onClick: () => {
                this.selectedBlock.position.y = this.height - this.selectedBlock.size.height
              },
              disabled: this.selectedBlock.protection.move
            }
          ]
        }
      ]
    },
    stretchOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Stretch",
          actions: [
            {
              title: this.selectedBlock.protection.resize
                ? "Fit width disabled, this block is protected against resize"
                : "Fit width",
              icon: "mdi-arrow-split-vertical",
              onClick: () => {
                this.selectedBlock.size.width = this.width
                this.selectedBlock.position.x = 0
              },
              disabled: this.selectedBlock.protection.resize
            },
            {
              title: this.selectedBlock.protection.resize
                ? "Fit Height disabled, this block is protected against resize"
                : "Fit Height",
              icon: "mdi-arrow-split-horizontal",
              onClick: () => {
                this.selectedBlock.size.height = this.height
                this.selectedBlock.position.y = 0
              },
              disabled: this.selectedBlock.protection.resize
            },
            {
              title: this.selectedBlock.protection.resize
                ? "Fit slide disabled, this block is protected against resize"
                : "Fit slide",
              icon: "mdi-fit-to-page-outline",
              onClick: () => {
                this.selectedBlock.size.width = this.width
                this.selectedBlock.size.height = this.height
                this.selectedBlock.position.x = this.selectedBlock.position.y = 0
              },
              disabled: this.selectedBlock.protection.resize
            }
          ]
        }
      ]
    },
    orderOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Order",
          actions: [
            {
              title: "Bring to top",
              icon: "mdi-arrange-bring-forward",
              onClick: () => {
                this.blockController.moveToTop(this.selectedBlock.id)
              }
            },
            {
              title: "Bring to bottom",
              icon: "mdi-arrange-send-backward",
              onClick: () => {
                this.blockController.moveToBack(this.selectedBlock.id)
              }
            }
          ]
        }
      ]
    },
    flipOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Order",
          actions: [
            {
              title: "Flip horizontal",
              icon: "mdi-flip-horizontal",
              onClick: () => {
                this.selectedBlock.flip.x = !this.selectedBlock.flip.x
              }
            },
            {
              title: "Flip vertical",
              icon: "mdi-flip-vertical",
              onClick: () => {
                this.selectedBlock.flip.y = !this.selectedBlock.flip.y
              }
            }
          ]
        }
      ]
    },
    rotateOptions() {
      if (!this.selectedBlock) return []
      return [
        {
          title: "Rotate",
          actions: [
            {
              title: "Rotate 90deg",
              icon: "mdi-rotate-right",
              onClick: () => {
                this.selectedBlock.rotation += 90
              }
            },
            {
              title: "Rotate -90deg",
              icon: "mdi-rotate-left",
              onClick: () => {
                this.selectedBlock.rotation -= 90
              }
            }
          ]
        }
      ]
    }
  },
  methods: {
    updateContext(blockId) {
      const parent = this.blockController.getParent(blockId)
      this.contextBlock = parent ? parent : false
    },
    calculateSize(contextBlock) {
      const popupButton = this.isPopupBlock(contextBlock)
      if (popupButton) {
        return { width: popupButton.width, height: popupButton.height }
      } else if (this.isTabsBlock(contextBlock)) {
        // get tabs-headers height
        const tabs = document.getElementById(contextBlock.id)
        const headers = tabs ? tabs.getElementsByClassName("p-tabs-headers") : null
        const offsetHeight = headers && headers[0] ? headers[0].offsetHeight : 90
        return {
          width: contextBlock.size.width,
          height: contextBlock.size.height - offsetHeight
        }
      } else {
        return contextBlock.size
      }
    },
    isPopupBlock(block) {
      const popupButton = block.actions ? block.actions.find(action => action.type == "popup") : false
      return popupButton
    },
    isTabsBlock(block) {
      return block && block.type === TABS_TYPE
    }
  }
}
</script>

<style>
.lp-arrange-form-item .v-list__tile {
  padding: 0 24px;
}
.lp-arrange-form-item .v-list__tile__avatar {
  display: none;
}
.lp-arrange-form-item .v-list__tile__action {
  flex-basis: 60%;
}
</style>
