export default {
    model :{
        status: {
            type: "list",
            format: ["OPEN", "CLOSED"],
            bind: {
                to: "attr",
                name: "data-status"
            },
            default: "OPEN"
    
        }
    },
    listeners:{
        click:true,
        contextmenu:true,
        mouseenter: true,
        mouseleave: true,
    }
}