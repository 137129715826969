import "./pt-panel-alarm-light.css"

export default {
    "model": {
        "status": {
            type: "list",
            format: ["ON", "OFF"],
            default: "OFF",
            bind: {
                to: "attr",
                name: "data-status"
            }
        }
    },
    listeners: {
        mouseenter: true,
        mouseleave: true,
        click: true,
        contextmenu: true
    }
}

