<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <template v-if="value.data.cropped || value.data.image">
        <image-view :value="value" />
      </template>

      <block-placeholder
        v-else
        @click.native="mode === 'edit' ? uploadImage() : null"
        img="add_image"
        icon="mdi-image-plus"
        text="Upload Image"
        :mode="mode"
      />
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ImageView from "./ImageView.vue"
import { uploadImage } from "./utils"
import BlockPlaceholder from "../_components/placeholders/BlockPlaceholder.vue"

export default {
  name: "BlockImage",
  components: {
    BasicBlock,
    ImageView,
    BlockPlaceholder
  },
  props: ["value", "mode"],
  methods: {
    uploadImage
  },
  created() {
    if (!this.value.data.style) {
      this.$set(this.value.data, "style", {
        normal: {
          borderRadius: 0
        }
      })
    }
  }
}
</script>
