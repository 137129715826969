var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lp-index",attrs:{"id":"lp-index"}},[_c('edit-toolbar',{on:{"zoomFit":_vm.setZoomFit}}),_c('div',{staticClass:"lp-index-panel",style:({
      left: 0,
      width: (_vm.leftPanelSelected > -1 ? 480 : 80) + 'px'
    })},[_c('left-panel',{attrs:{"leftPanelsTop":_vm.leftPanelsTop,"leftPanelsBottom":_vm.leftPanelsBottom,"leftPanelSelected":_vm.leftPanelSelected},on:{"update:leftPanelSelected":function($event){_vm.leftPanelSelected=$event},"update:left-panel-selected":function($event){_vm.leftPanelSelected=$event},"slideChanged":function($event){return _vm.$emit('slideChanged', _vm.blockController)}}})],1),_c('main',{staticClass:"lp-index-main",style:({
      marginLeft: (_vm.leftPanelSelected > -1 ? 400 : 0) + 'px',
      marginRight: (_vm.rightPanelSelected > -1 ? 400 : 0) + 'px'
    })},[_c('footer-arrows',{attrs:{"slide":_vm.lessonPlanController.selectedSlideIndex + 1,"total":_vm.lessonPlanController.slidesVisible.length,"can-go-next":true,"can-go-prev":true,"isFullscreen":_vm.lessonPlanController.isFullscreen,"subtitlesActive":_vm.lessonPlanController.subtitlesActive,"defaultSubtitles":_vm.lessonPlanController.defaultSubtitles},on:{"slideChanged":function($event){return _vm.$emit('slideChanged', _vm.blockController)},"prev":_vm.goToPrevSlide,"next":_vm.goToNextSlide,"update":_vm.goToSlideIndex,"toggleFullscreen":_vm.toggleFullscreen,"toggleSubtitles":_vm.toggleSubtitles,"selectDefaultLang":_vm.selectDefaultLang}}),_c('block-canvas',{key:_vm.slideId,ref:"canvas",staticClass:"p-block-designer-canvas",attrs:{"blocks":_vm.blocks,"slide-id":_vm.slideId,"defaultBlockStyles":_vm.defaultBlockStyles,"settingStyles":_vm.settingStyles,"globalVariables":_vm.globalVariables,"mode":"edit"},on:{"create:draft":function($event){return _vm.$emit('create:draft', $event)}}}),(_vm.blockController.gridSize[0] > 1)?_c('block-canvas-grid',{attrs:{"scale":_vm.blockController.scale,"origin-x":_vm.blockController.origin.x,"origin-y":_vm.blockController.origin.y,"grid-x":_vm.blockController.gridSize[0],"grid-y":_vm.blockController.gridSize[1],"canvasWidth":_vm.blockController.viewerWidth,"canvasHeight":_vm.blockController.viewerHeight},on:{"zoomfit":_vm.setZoomFit}}):_vm._e()],1),_c('div',{staticClass:"lp-index-panel",style:({
      right: 0,
      width: (_vm.rightPanelSelected > -1 ? 480 : 80) + 'px'
    })},[_c('right-panel',{key:_vm.slideId,attrs:{"rightPanels":_vm.rightPanels,"rightPanelSelected":_vm.rightPanelSelected},on:{"update:rightPanelSelected":function($event){_vm.rightPanelSelected=$event},"update:right-panel-selected":function($event){_vm.rightPanelSelected=$event}}})],1),_c('modal-progress',{attrs:{"show":_vm.lessonPlanController.progressModal.show,"title":_vm.lessonPlanController.progressModal.title}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }