import Vue from "vue"
import { getContentId } from "../../../utils"

export default function (vm) {
  const itemRevision = vm.value
  return new Vue({
    data() {
      return {
        datasheetFilter: null
      }
    },
    created() {
      vm.$emit("select", getContentId(itemRevision))
    },
    computed: {
      datasheet() {
        if (itemRevision) {
          if (!itemRevision.body.contents.datasheet) itemRevision.body.contents.datasheet = {}
          return itemRevision.body.contents.datasheet
        } else {
          return {}
        }
      },
      schema() {
        if (itemRevision.body.contents.schema) {
          return itemRevision.body.contents.schema
        } else {
          return {}
        }
      }
    },
    methods: {
      getCurrentView() {
        return {
          filters: this.datasheetFilter
        }
      },
      setCurrentView(datasheetFilter) {
        this.datasheetFilter = datasheetFilter
      }
    },
    watch: {
      datasheetFilter: {
        deep: true,
        immediate: true,
        handler() {
          vm.$emit("update:view", this.getCurrentView())
        }
      }
    }
  })
}
