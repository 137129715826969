<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-brace-control-handler-x"
        :x="w / 2"
        :y="cPB"
        :y-range="[5, Math.min(cPH / 2, (h - cPH) / 2)]"
        :angle="value.rotation"
        @update:y="y => (value.data.controls.controlPointBody = y / h)" />
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-brace-control-handler-y"
        :x="w"
        :y="cPH"
        :y-range="[10, h - 10]"
        :angle="value.rotation"
        @update:y="updatePointHead" />
      <svg
        class="shape-brace"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M 0 0 
                A ${w / 2} ${cPB} 0 0 1 ${w / 2} ${cPB}
                L ${w / 2} ${cPH - cPB}
                A ${w / 2} ${cPB} 0 0 0 ${w} ${cPH}
                A ${w / 2} ${cPB} 0 0 0 ${w / 2} ${cPH + cPB}
                L ${w / 2} ${h - cPB}
                A ${w / 2} ${cPB} 0 0 1 0 ${h}
              `"
            :style="value.data.style" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"
export default {
  name: "BlockShapeArrow",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  data() {
    return {
      state: "not-selected"
    }
  },
  methods: {
    updatePointHead(y) {
      if (y < this.h / 2) {
        this.value.data.controls.controlPointBody = y / this.h / 2
      } else {
        this.value.data.controls.controlPointBody = (this.h - y) / this.h / 2
      }
      this.value.data.controls.controlPointHead = y / this.h
    }
  },
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    cPB() {
      return this.value.size.height * this.value.data.controls.controlPointBody
    },
    cPH() {
      return this.value.size.height * this.value.data.controls.controlPointHead
    }
  }
}
</script>
