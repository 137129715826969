import Vue from "vue"

export default function (vm) {
  return new Vue({
    data() {
      return {
        vm: vm
      }
    },
    methods: {
      initGlossary() {
        this.$set(this.vm.value.body.contents, "glossary", [])
      },
      initGlossaryImported() {
        this.$set(this.vm.value.body.contents, "glossaryImported", [])
      },
      deleteGlossaryById(glossaryContentId) {
        this.vm.value.body.contents.glossaryImported = this.vm.value.body.contents.glossaryImported.filter(
          glossary => glossary.header.metadata.content_id !== glossaryContentId
        )
      },
      deleteLocalGlossary() {
        this.vm.value.body.contents.glossary = []
      },
      deleteTermGlossaryById(termId) {
        this.vm.value.body.contents.glossary = this.vm.value.body.contents.glossary.filter(
          term => term.header.metadata.content_id !== termId
        )
      },
      getGlossaryById(glossaryId) {
        if (!this.vm.value.body.contents.glossaryImported) return null

        return this.vm.value.body.contents.glossaryImported.find(
          glossary => glossary.header.metadata.content_id === glossaryId
        )
      },
      getTermGlossaryById(termId) {
        if (!this.vm.value.body.contents.glossary) return null

        return this.vm.value.body.contents.glossary.find(term => term.header.metadata.content_id === termId)
      },
      setTermGlossary(term) {
        if (!this.vm.value.body.contents.glossary) this.initGlossary()
        this.vm.value.body.contents.glossary.push(term)
      },
      setGlossary(glossary) {
        if (!this.vm.value.body.contents.glossaryImported) this.initGlossaryImported()
        this.vm.value.body.contents.glossaryImported.push(glossary)
      }
    }
  })
}
