export const STORE_EXPLORER_NAME = "explorer"

export const STATE_FOLDER = "folder"
export const STATE_CONTENTS = "contents"
export const STATE_SELECTED = "selected"
export const STATE_EXPLORER_LOADING = "loading"
export const STATE_FILTER_TYPE = "filterType"
export const STATE_EXPLORER_VIEW = "explorerView"
export const STATE_ALL_LIBRARY_FOLDERS = "allLibraryFolders"

export const MUTATION_SET_CURRENT_FOLDER = "setCurrentFolder"
export const MUTATION_START_LOADING = "startLoading"
export const MUTATION_TOGGLE_LOADING = "toggleLoading"
export const MUTATION_SELECTION = "selection"
export const MUTATION_SELECTION_ALL = "selectionAll"
export const MUTATION_CLEAN_SELECTION = "cleanSelection"
export const MUTATION_SET_CONTENTS = "setContents"
export const MUTATION_SET_FILTER_TYPE = "setFilterType"
export const MUTATION_SET_EXPLORER_VIEW = "setExplorerView"
export const MUTATION_SET_ALL_LIBRARY_FOLDERS = "setAllLibraryFolders"

export const ACTION_REFRESH = "refresh"
export const ACTION_CHANGE_SELECTION = "changeSelection"
export const ACTION_CHANGE_ALL_SELECTION = "changeAllSelection"
export const ACTION_OPEN_FOLDER = "openFolder"
export const ACTION_NEW_FOLDER = "newFolder"
export const ACTION_DELETE_ITEM = "deleteItem"
export const ACTION_SEARCH_QUERY = "searchQuery"
export const ACTION_RENAME_FOLDER = "renameFolder"
export const ACTION_CLEAN_SELECTED = "cleanSelected"
export const ACTION_CREATE_DRAFT_FROM_ITEMREVISION_LIST = "createDraftFromItemRevisionList"
export const ACTION_CHANGE_FILTER_TYPE = "changeFilterType"
export const ACTION_GET_ALL_LIBRARY_FOLDERS = "getAllLibraryFolders"
