<template>
	<node-view-wrapper
		class="plant-library-component__wrapper"
		:style="isEditable && !dialog ? 'width: 75%;' : ''"
		:origin="node.attrs.origin"
	>
		<template v-if="isEditable">
			<v-toolbar flat class="view-menu__options">
				<div class="view-menu__options--image-type">
					<ContentTypeIcon v-if="content" :type="getType(content)" />
				</div>
				<v-divider vertical></v-divider>

				<div class="view-menu__options--custom-id">
					<v-chip small label dark>
						<v-icon small>mdi-tag</v-icon>
						{{ getCustomId(content) }}
					</v-chip>

					<div>{{ getTitle(content) }} (rev. {{ getMajorVersion(content) }}.{{ getMinorVersion(content) }})</div>
				</div>

				<v-divider vertical></v-divider>
				<v-spacer></v-spacer>

				<v-dialog lazy persistent v-model="dialog" width="70%">
					<template v-slot:activator="{ on }">
						<v-btn color="primary lighten-2" depressed dark v-on="on">Preview / Set view</v-btn>
					</template>

					<v-card>
						<v-card-text>
							<div class="view-menu">
								<v-toolbar flat class="view-menu__options">
									<div class="view-menu__options--image-type">
										<ContentTypeIcon v-if="content" :type="getType(content)" />
									</div>
									<v-divider vertical></v-divider>

									<div class="view-menu__options--custom-id">
										<v-chip small label dark>
											<v-icon small>mdi-tag</v-icon>
											{{ getCustomId(content) }}
										</v-chip>

										<div>{{ getTitle(content) }}</div>
									</div>

									<v-divider vertical></v-divider>
									<v-spacer></v-spacer>

									<div v-if="isPictureOrVideo" class="view-menu__options--simplify-view-switch mr-2">
										<label>Simplified View</label>
										<div class="simplify-view-switch">
											<span class="mr-2">Off</span>
											<v-switch
												false-value="full"
												true-value="simple"
												:input-value="localView.interface"
												@change="updateViewInterface"
												hide-details
											></v-switch>
											<span>On</span>
										</div>
									</div>

									<v-divider vertical></v-divider>

									<v-menu v-if="canShowPinTools" offset-y>
										<template v-slot:activator="{ on }">
											<v-badge :value="!samePinAndView" color="red" overlap>
												<template v-slot:badge>
													<v-icon color="white">mdi-asterisk</v-icon>
												</template>

												<v-btn icon v-on="on" tooltip="View actions" height="22">
													<v-icon>more_vert</v-icon>
												</v-btn>
											</v-badge>
										</template>

										<v-list class="view-menu__options--pin-menu">
											<v-list-tile avatar :disabled="samePinAndView" @click="pinView(JSON.stringify(localView))">
												<v-list-tile-avatar>
													<v-icon>mdi-pin</v-icon>
												</v-list-tile-avatar>
												<v-list-tile-title>{{ pinnedSaved ? 'Update pin view' : 'Pin view' }}</v-list-tile-title>
											</v-list-tile>

											<v-list-tile avatar :disabled="!pinnedSaved" @click="pinView(null)">
												<v-list-tile-avatar>
													<v-icon>mdi-pin-off</v-icon>
												</v-list-tile-avatar>
												<v-list-tile-title>Unpin view</v-list-tile-title>
											</v-list-tile>

											<v-list-tile :disabled="!canReturnToPinView" avatar @click="returnToPinView">
												<v-list-tile-avatar>
													<v-icon>mdi-restore</v-icon>
												</v-list-tile-avatar>
												<v-list-tile-title>Return to pin view</v-list-tile-title>
											</v-list-tile>
										</v-list>
									</v-menu>
								</v-toolbar>
							</div>

							<div :class="isEditable ? 'library-content__wrapper-editor' : 'library-content__wrapper-viewer'">
								<item-viewer :value="content" :view.sync="localView"></item-viewer>
							</div>
						</v-card-text>

						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" flat @click="dialog = false"> Close </v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</v-toolbar>
		</template>

		<template v-else>
			<div class="view-menu">
				<v-toolbar flat class="view-menu__options">
					<div class="view-menu__options--image-type">
						<ContentTypeIcon v-if="content" :type="getType(content)" />
					</div>
					<v-divider vertical></v-divider>

					<div class="view-menu__options--custom-id">
						<v-chip small label dark>
							<v-icon small>mdi-tag</v-icon>
							{{ getCustomId(content) }}
						</v-chip>

						<div>{{ getTitle(content) }}</div>
					</div>
				</v-toolbar>
			</div>

			<div :class="isEditable ? 'library-content__wrapper-editor' : 'library-content__wrapper-viewer'">
				<item-viewer :value="content" :view.sync="localView"></item-viewer>
			</div>
		</template>
	</node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-2';
import ItemViewer from 'plant-common/src/components/ItemViewer';
import PToolbar from 'plant-common/src/components/PToolbar';
import PToolbarButton from 'plant-common/src/components/PToolbarButton';
import { ContentTypeIcon } from 'plant-common';
import { getType, getTitle, getCustomId, getMajorVersion, getMinorVersion } from '../../../../utils/dbTool';
import PServiceContent from 'plant-common/src/services/PServiceContent';

export default {
	name: 'PlantLibraryComponent',
	inject: ['controller'],
	components: {
		NodeViewWrapper,
		ItemViewer,
		PToolbar,
		PToolbarButton,
		ContentTypeIcon
	},
	props: nodeViewProps,
	data() {
		return {
			content: null,
			localView: {},
			dialog: false
		};
	},
	computed: {
		canShowPinTools() {
			return this.isEditable && this.content !== null && this.localView !== null;
		},
		isEditable() {
			return this.editor.options.editable;
		},
		pinned_view() {
			return this.node.attrs.pinned_view;
		},
		pinnedSaved() {
			return this.pinned_view !== null;
		},
		canReturnToPinView() {
			return this.pinned_view && this.pinned_view !== JSON.stringify(this.localView);
		},
		isTipTapView() {
			return this.localView.dom || this.localView.nodeViews || this.localView.pluginViews ? true : false;
		},
		samePinAndView() {
			return this.localView && this.pinned_view && this.pinned_view === JSON.stringify(this.localView);
		},
		isPictureOrVideo() {
			return this.getType(this.content) === 'picture' || this.getType(this.content) === 'video';
		}
	},
	async created() {
		if (this.node.attrs.origin) {
			this.content = await PServiceContent.getContentFromRef(this.node.attrs.origin, this.controller.page);
		}
		if (this.node.attrs.pinned_view) {
			this.localView = JSON.parse(this.node.attrs.pinned_view);
		}

		this.cleanUpView();

		if (this.localView && !this.localView.interface) this.$set(this.localView, 'interface', 'simple');
	},
	mounted() {},
	methods: {
		getCustomId: getCustomId,
		getTitle: getTitle,
		getType: getType,
		getMajorVersion: getMajorVersion,
		getMinorVersion: getMinorVersion,
		pinView(data) {
			this.updateAttributes({
				pinned_view: data
			});
		},
		returnToPinView() {
			this.localView = JSON.parse(this.pinned_view);
		},
		updateViewInterface(newValue) {
			this.localView.interface = newValue;
		},
		cleanUpView() {
			if (this.isTipTapView) this.localView = {};
		}
	},
	watch: {
		'node.attrs.origin': {
			immediate: false,
			async handler() {
				if (this.node.attrs.origin) {
					this.content = await PServiceContent.getContentFromRef(this.node.attrs.origin, this.controller.page);
				}
			}
		}
	}
};
</script>

<style scoped>
.plant-library-component__wrapper {
	border: 1px solid lightgrey;
}

.view-menu {
	border-bottom: 1px solid lightgrey;
}

.view-menu__options--image-type {
	width: 40px;
	height: 40px;
	margin-right: 10px;
}

.view-menu__options--image-type > img {
	width: 100%;
	max-width: 100%;
	height: auto;
	margin: 0;
}

.view-menu__options--custom-id .v-chip {
	margin: 0;
	margin-bottom: 4px;
}

.view-menu__options--simplify-view-switch {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
}

.view-menu__options--simplify-view-switch .simplify-view-switch {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
}

.view-menu__options--simplify-view-switch .simplify-view-switch * {
	padding: 0;
}

.view-menu__options--pin-menu .v-list--disabled .v-list__tile .v-list__tile__avatar i {
	color: inherit;
}

.library-content__wrapper-editor {
	position: relative;
	width: 100%;
	height: 70vh;
	overflow: hidden;
}

.library-content__wrapper-viewer {
	position: relative;
	width: 100%;
	height: 650px;
	overflow: hidden;
}
</style>
