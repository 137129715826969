<template>
  <v-container grid-list-xs>
    <v-toolbar flat class="elevation-0 transparent" v-if="outdated && outdated.docs.length > 0">
      <v-toolbar-title>Configuration Control</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="width: 15em">
        <v-select
          :items="[
            { text: 'Dependents', value: 'dependents' },
            { text: 'Dependencies', value: 'dependencies' }
          ]"
          :value="groupBy"
          @change="setGroupBy"
          label="Group by">
          <template #prepend>
            <img v-if="groupBy == 'dependencies'" height="25px" :src="require('./images/group-by-dependencies.svg')" />
            <img v-else height="25px" :src="require('./images/group-by-dependents.svg')" />
          </template>
        </v-select>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn flat icon v-on="on">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-tile key="recalculate" @click="recalculateRelations">
            <v-list-tile-title><v-icon>warning</v-icon> Recalculate all relations</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <table-dependents v-if="groupBy == 'dependents' && outdated && outdated.docs.length > 0" />
    <table-dependencies v-else-if="groupBy == 'dependencies' && outdated && outdated.docs.length > 0" />
    <div v-else-if="outdated && outdated.docs.length == 0" class="text-center">
      <img :src="require('./images/placeholder.svg')" style="width: 600px; padding: 25px" />
      <h1 class="headline mb-3 mt-3">Good Job! All your content is up to date!</h1>
      <h2 class="subheading text--lighten-4 mb-3 mt-3">
        Rest assured, we will let you know when your content needs to be updated.
      </h2>
      <v-btn color="warning" outline @click="recalculateRelations">
        <v-icon left dark>warning</v-icon>
        Recalculate relations
      </v-btn>
    </div>
    <recalculate-confirmation-modal
      :dialog="confirmationDialog"
      @close="confirmationDialog = false"
      @recalculate="callRecalculate" />
    <v-dialog v-model="loadingDialog" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <p>This action may take some time, please wait.</p>
                <v-progress-linear :indeterminate="true"></v-progress-linear>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <upgrade-dialog-dependent v-if="groupBy == 'dependents' && selectedUpgrade" />
    <upgrade-dialog-dependency v-if="groupBy == 'dependencies' && selectedUpgrade" />
  </v-container>
</template>
<script>
import {
  selectedUpgrade,
  outdated,
  fetchOutdated,
  groupBy,
  setGroupBy,
  recalculateContentAndRelations
} from "./store.js"
import UpgradeDialogDependent from "./components/UpgradeDialogDependent.vue"
import UpgradeDialogDependency from "./components/UpgradeDialogDependency.vue"
import TableDependents from "./components/TableDependents.vue"
import TableDependencies from "./components/TableDependencies.vue"
import RecalculateConfirmationModal from "./components/RecalculateConfirmationModal.vue"
import { PtModal } from "plant-common"
export default {
  name: "PageConfigurationControl",
  components: {
    UpgradeDialogDependent,
    TableDependents,
    TableDependencies,
    UpgradeDialogDependency,
    RecalculateConfirmationModal
  },
  mixins: [PtModal],
  data() {
    return {
      confirmationDialog: false,
      loadingDialog: false
    }
  },
  computed: {
    selectedUpgrade,
    outdated,
    groupBy
  },
  methods: {
    setGroupBy,
    recalculateRelations() {
      this.$modal("confirm", {
        title: `This action will recalculate all contents and their relations. This actions requires a high CPU server usage. Please, confirm this action only if you are sure of its meaning.`,
        accept: {
          color: "warning",
          text: "Recalculate",
          icon: "mdi-update"
        },
        cancel: { text: "cancel", color: "shades" }
      }).then(r => {
        if (r) {
          this.confirmationDialog = true
        }
      })
    },
    async callRecalculate() {
      this.loadingDialog = true
      await recalculateContentAndRelations()
      this.loadingDialog = false
      fetchOutdated()
    }
  },
  created() {
    fetchOutdated()
  }
}
</script>