<template>
  <div>
    <v-card flat style="height: 100%">
      <v-layout style="height: 100%">
        <v-flex style="height: 100%">
          <v-card-title
            @click="changeCard(guide.cover)"
            class="px-2 py-2"
            style="border-bottom: 1px solid #eaeaea; cursor: pointer"
            e2e-attr="guide-cover"
          >
            <v-icon>mdi-home</v-icon>
            <h4 class="ml-3">Cover</h4>
            <v-spacer></v-spacer>
            <v-btn
              flat
              outline
              small
              color="success"
              class="settings-section"
              style="margin-right: 10px !important"
              @click="guideController.checkLatestRevisions()"
            >
              <v-icon>mdi-check-all</v-icon>
              Check Latest revs
            </v-btn>
            <v-btn
              flat
              outline
              small
              class="settings-section"
              style="margin-right: 10px !important"
              @click="guideController.updateAllCards()"
            >
              <v-icon>mdi-update</v-icon>
              Update All
            </v-btn>
            <v-btn
              flat
              icon
              class="settings-section"
              style="margin-right: 10px !important"
              @click="selectAction"
              e2e-attr="actions"
            >
              <v-icon small>more_vert</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text
            ref="treeViewContainer"
            id="treeViewContainer"
            class="px-2 py-2 overflow_items"
            style="height: calc(100vh - 162px)"
          >
            <Tree :value="guide.sections">
              <span
                :ref="node.id"
                slot-scope="{ node, index, path, tree }"
                @click="changeCard(node)"
                :class="[
                  'tree_node_custom',
                  selectedCard.id == node.id ? 'tree_node_selected' : ''
                ]"
              >
                <span v-if="node.type == 'section'" e2e-attr="guide-section">
                  <v-layout align-center justify-start row fill-height>
                    <v-flex xs1>
                      <v-icon
                        v-if="node.type == 'section'"
                        :color="node.$folded ? 'grey' : 'primary'"
                        @click="tree.toggleFold(node, path)"
                        >{{
                          node.$folded ? "mdi-folder" : "mdi-folder-open"
                        }}</v-icon
                      >
                    </v-flex>
                    <v-flex xs9 class="ellipsis_wrap">
                      <span
                        class="text_card"
                        @click="tree.toggleFold(node, path)"
                        :title="node.fields.title || 'Section'"
                        >{{ node.fields.title || "Section" }}</span
                      >
                    </v-flex>
                    <v-flex xs2>
                      <v-btn
                        flat
                        icon
                        class="settings-section"
                        @click="selectSectionAction(node)"
                        e2e-attr="actions"
                      >
                        <v-icon small>more_vert</v-icon>
                      </v-btn>
                      <span
                        v-if="comments && commentsSelectedCard(node).length"
                        class="settings-section pt-2 pr-2"
                      >
                        <v-badge
                          overlap
                          :color="setBadgeColor(commentsSelectedCard(node))"
                        >
                          <template v-slot:badge>
                            <span>{{ commentsSelectedCard(node).length }}</span>
                          </template>
                          <v-icon color="grey lighten-1" medium>message</v-icon>
                        </v-badge>
                      </span>
                    </v-flex>
                  </v-layout>
                </span>

                <span v-if="node.type == 'card'" e2e-attr="guide-card">
                  <v-layout align-center justify-start row fill-height>
                    <v-flex class="pt-2" xs1>
                      <content-type-icon
                        v-if="node.content.header"
                        :type="getType(node.content)"
                        width="25px"
                      ></content-type-icon>
                      <v-icon v-else>mdi-circle</v-icon>
                    </v-flex>
                    <v-flex xs9 class="ellipsis_wrap">
                      <content-resolve-icon :value="node" />
                      <span
                        class="text_card"
                        :title="
                          node.content.header
                            ? getTitle(node.content) +
                              ' - Custom Id: ' +
                              getCustomId(node.content)
                            : ''
                        "
                      >
                        {{ node.content.header ? getTitle(node.content) : "" }}
                        <v-chip style="height: 16px" v-if="node.content.header"
                          >{{ node.content.header.major_revision }}.{{
                            node.content.header.minor_revision
                          }}</v-chip
                        >
                        <v-chip
                          v-if="node.content.header"
                          style="height: 16px"
                          >{{ getCustomId(node.content) }}</v-chip
                        >
                      </span>
                    </v-flex>
                    <v-flex xs2>
                      <v-btn
                        flat
                        icon
                        class="settings-section"
                        @click="selectCardAction(node)"
                        e2e-attr="actions"
                      >
                        <v-icon small>more_vert</v-icon>
                      </v-btn>

                      <span
                        v-if="comments && commentsSelectedCard(node).length"
                        class="settings-section pt-2 pr-2"
                      >
                        <v-badge
                          overlap
                          :color="setBadgeColor(commentsSelectedCard(node))"
                        >
                          <template v-slot:badge>
                            <span>{{ commentsSelectedCard(node).length }}</span>
                          </template>
                          <v-icon color="grey lighten-1" medium>message</v-icon>
                        </v-badge>
                      </span>
                    </v-flex>
                  </v-layout>
                </span>

                <span
                  v-if="node.type == 'card-template'"
                  e2e-attr="guide-card-template"
                >
                  <v-layout align-center justify-start row fill-height>
                    <v-flex xs1>
                      <v-icon>mdi-menu</v-icon>
                    </v-flex>
                    <v-flex xs9 class="ellipsis_wrap">
                      <span
                        class="text_card"
                        :title="
                          node.content.template_name
                            ? node.content.template_name
                            : ''
                        "
                        >{{
                          node.content.template_name
                            ? node.content.template_name
                            : ""
                        }}</span
                      >
                    </v-flex>
                    <v-flex xs2>
                      <v-btn
                        flat
                        icon
                        class="settings-section"
                        @click="selectCardTemplateAction(node)"
                        e2e-attr="actions"
                      >
                        <v-icon small>more_vert</v-icon>
                      </v-btn>

                      <span
                        v-if="comments && commentsSelectedCard(node).length"
                        class="settings-section pt-2 pr-2"
                      >
                        <v-badge
                          overlap
                          :color="setBadgeColor(commentsSelectedCard(node))"
                        >
                          <template v-slot:badge>
                            <span>{{ commentsSelectedCard(node).length }}</span>
                          </template>
                          <v-icon color="grey lighten-1" medium>message</v-icon>
                        </v-badge>
                      </span>
                    </v-flex>
                  </v-layout>
                </span>
              </span>
            </Tree>
          </v-card-text>
        </v-flex>
      </v-layout>
    </v-card>

    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Comment unsaved</v-card-title>
        <v-card-text
          >You have an unsaved comment. If you change your card the comment will
          be deleted. Are you sure you want to change your card?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="dialog = false">No</v-btn>
          <v-btn color="blue darken-1" flat @click="doChangeCard">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
import { ContentTypeIcon } from "plant-common";
import { PtModal } from "plant-common";
import { mapGetters, mapState } from "vuex";
import ContentResolveIcon from "../Viewer/ContentResolveIcon.vue";

import "he-tree-vue/dist/he-tree-vue.css";
import { Tree, Draggable, Fold } from "he-tree-vue";
import CopyItemDialog from "./CopyItemDialog";
import {
  STORE_MODULE_COMMENTS,
  STATE_IS_COMMENT,
  STATE_CURRENT_LOCATION
} from "../../../../store_constants/content";
import {
  getTitle,
  getCustomId,
  getType,
  getContentId
} from "../../../../utils/dbTool";

export default {
  name: "GuideEditorNavBar",
  inject: ["guideController"],
  mixins: [PtModal],
  data() {
    return {
      over: null,
      dragging: null,
      expanded: [],
      activated: [],
      changeFromComment: false,
      selectedChangeCard: null,
      dialog: false
    };
  },
  props: {
    sortable: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ContentTypeIcon,
    Tree: Tree.mixPlugins([Draggable, Fold]),
    ContentResolveIcon
  },
  computed: {
    ...mapState(STORE_MODULE_COMMENTS, {
      current_location: STATE_CURRENT_LOCATION,
      isComment: STATE_IS_COMMENT
    }),
    ...mapInjectedData("guideController", ["guide", "selectedCard", "content"]),
    ...mapGetters("content/comments", ["comments"]),
    items() {
      const children = this.guide.sections.map(type => ({
        id: type,
        name: this.getName(type),
        children: this.getChildren(type)
      }));

      return [
        {
          id: 1,
          name: "Cover",
          children
        }
      ];
    }
  },
  mounted() {
    this.setDroppable(this.guide.sections);

    this.$refs["treeViewContainer"].addEventListener("wheel", e => {
      if (e.deltaY < 0) {
        this.$refs["treeViewContainer"].scrollTop -= 25;
      } else if (e.deltaY > 0) {
        this.$refs["treeViewContainer"].scrollTop += 25;
      }
    });
  },
  methods: {
    dropHook(tree, store) {
      console.log("tree", tree);
    },
    getTitle: getTitle,
    getCustomId: getCustomId,
    getType: getType,
    ...mapInjectedMethods("guideController", [
      "selectCard",
      "addSection",
      "addSubsection",
      "addCard",
      "removeCard",
      "removeAll",
      "removeItemFromCard",
      "selectContent",
      "selectTemplate",
      "saveViewCard",
      "selectCardFromId",
      "getParents",
      "cloneCard",
      "setDroppable"
    ]),
    changeCard(card) {
      if (this.isComment) {
        this.selectedChangeCard = card;
        this.dialog = true;
      } else {
        this.selectCard(card);
      }
    },
    doChangeCard() {
      this.selectCard(this.selectedChangeCard);
      this.dialog = false;
      this.selectedChangeCard = null;
    },
    selectCardTemplateAction(item) {
      this.selectCard(item);
      this.$modal("optionsList", {
        title: "Card Actions",
        message: "Please select an action",
        options: [
          {
            text: "Select template",
            value: "selectTemplate"
          },
          {
            text: "Delete content",
            value: "removeItemFromCard"
          },
          {
            text: "Clone card",
            value: "cloneCard"
          },
          {
            text: "Remove card",
            value: "removeCard"
          }
        ]
      }).then(r => {
        if (r) {
          if (r == "openModalDraft") {
            if (this.selectedCard.content)
              this.$emit("create:draft", this.selectedCard.content);
          } else {
            this[r]();
          }
        }
      });
    },
    selectCardAction(item) {
      this.changeCard(item);
      if (!this.isComment) {
        this.$modal("optionsList", {
          title: "Card Actions",
          message: "Please select an action",
          options: [
            {
              text: "Explore content",
              value: "selectContent"
            },
            {
              text: "Delete content",
              value: "removeItemFromCard"
            },
            {
              text: "Clone card",
              value: "cloneCard"
            },
            {
              text: "Remove card",
              value: "removeCard"
            },
            {
              text: "Pin view card",
              value: "saveViewCard"
            },
            {
              text: "Create minor version of current content",
              value: "openModalDraft"
            }
          ]
        }).then(r => {
          if (r) {
            if (r == "openModalDraft") {
              if (this.selectedCard.content)
                this.$emit("create:draft", this.selectedCard.content);
            } else {
              this[r]();
            }
          }
        });
      }
    },
    selectSectionAction(item) {
      this.changeCard(item);
      if (!this.isComment) {
        this.$modal("optionsList", {
          title: "Section Actions",
          message: "Please select an action",
          options: [
            {
              text: "Add section",
              value: "addSubsection"
            },
            {
              text: "Add card",
              value: "addCard"
            },
            {
              text: "Remove section",
              value: "removeCard"
            }
          ]
        }).then(r => {
          if (r) this[r]();
        });
      }
    },
    selectAction() {
      if (!this.isComment) {
        this.$modal("optionsList", {
          title: "Global Actions",
          message: "Please select an action",
          options: [
            {
              text: "Add section",
              value: "addSection"
            },
            {
              text: "Remove all",
              value: "removeAll"
            },
            {
              text: "Copy this content in new guide",
              value: "copyGuideForm"
            }
          ]
        }).then(r => {
          if (r) this[r]();
        });
      }
    },
    copyGuideForm() {
      this.$modal(CopyItemDialog).then(res => {
        if (!res) return false;
        var { title, custom_id, path_id } = res;
        this.$emit(
          "copyItem",
          getContentId(this.content),
          title,
          custom_id,
          path_id
        );
      });
    },
    setBadgeColor(arr) {
      let open = 0;
      let close = 0;

      arr.forEach(e => {
        switch (e.state) {
          case "open":
            return open++;
            break;
          case "closed":
            return close++;
            break;
          default:
            break;
        }
      });

      return arr.length === open
        ? "red"
        : arr.length === close
        ? "blue"
        : "orange";
    },
    commentsSelectedCard(node) {
      if (!this.comments) return [];
      return this.comments.filter(
        e =>
          !e.deleted_at &&
          e.location &&
          (e.location.id === node.id || e.location.card_id === node.id)
      );
    }
  },
  watch: {
    current_location: function (newLocation, oldLocation) {
      if (
        this.current_location &&
        this.current_location.id !== this.selectedCard.id
      ) {
        this.changeFromComment = true;
        this.selectCardFromId(this.current_location.id);
      }
    },
    selectedCard: function (newLocation, oldLocation) {
      this.activated = [newLocation.id];
      let parents = this.getParents(
        this.guide.sections,
        newLocation.id,
        []
      ).parents;
      if (parents) {
        parents.forEach(e => (e.$folded = false));
      }

      if (this.changeFromComment) {
        this.$nextTick(() => {
          let element = this.$refs[newLocation.id];
          if (element)
            this.$refs["treeViewContainer"].scrollTop = element.offsetTop - 250;
          this.changeFromComment = false;
        });
      }
    }
  }
};
</script>

<style scoped>
.height90 {
  height: calc(100% - 100px);
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.normal-card {
  margin: 15px 5px;
  width: 100%;
}

.normal-section {
  margin: 15px 5px;
  width: 100%;
}

.section-title {
  margin: 0px;
  padding: 5px;
  background: black;
  color: white;
}

.pointer {
  cursor: pointer;
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.settings-section {
  height: 32px;
  margin: 0;
  float: right;
}

.v-list__tile__title {
  height: 32px;
}

.fix-viewer {
  position: absolute;
  width: 100%;
  top: 65px;
  padding-bottom: 60px;
}
.tree-item {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  padding: 12px;
}

.below {
  border-bottom: 2px solid #1976d2;
}

.above {
  border-top: 2px solid #1976d2;
}

.append {
  border: 2px solid #1976d2;
}

.overflow_items {
  overflow: auto;
  height: 100%;
}

.tree_node_custom {
  cursor: pointer;
  display: block;
  font-size: 14px;
  width: 100%;
  overflow: hidden;
}

.tree_node_selected {
  background: #cccccc;
}

body .he-tree .tree-node {
  border: 2px solid #fc3 !important;
}

.text_card {
  margin-left: 6px;
  margin-top: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis_wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
