<template>
  <v-layout column fill-height>
    <v-flex xs12 style="flex-basis: auto">
      <v-toolbar flat class="transparent">
        <v-toolbar-title> Glossary of Terms</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-menu offset-y v-if="mode === 'edit'">
          <template v-slot:activator="{ on }">
            <v-btn flat v-on="on" class="lp-outline-panel-actions-button">
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-tile @click="selectFromContentManager('term')">
              <v-list-tile-title class="body-1">
                <v-layout row align-center>
                  <v-icon class="mr-2">mdi-plus</v-icon>
                  <span>Add term</span>
                </v-layout>
              </v-list-tile-title>
            </v-list-tile>

            <v-list-tile @click="selectFromContentManager('glossary')">
              <v-list-tile-title class="body-1">
                <v-layout row align-center>
                  <v-icon class="mr-2">mdi-book-alphabet</v-icon>
                  <span>Import glossary</span>
                </v-layout>
              </v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar>

      <v-divider></v-divider>
    </v-flex>

    <v-flex xs12 v-if="emptyGlossary && !searchTerm">
      <v-layout column fill-height align-content-center justify-center>
        <v-flex xs12 style="flex-basis: auto">
          <img style="width: 100%" :src="require('./placeholder-no-glossary.svg')" alt="no-glossary" />
        </v-flex>
        <v-flex xs12 style="flex-basis: auto">
          <h2 class="text-center py-4">Add a Term to the Glossary from the Content Library</h2>
        </v-flex>
      </v-layout>
    </v-flex>

    <v-flex xs12 px-3 v-if="!emptyGlossary || searchTerm !== ''" style="flex-basis: auto">
      <v-text-field label="Search Term" clearable append-icon="mdi-magnify" v-model="searchTerm"></v-text-field>
    </v-flex>

    <v-flex xs12 v-if="!emptyGlossary" style="position: relative">
      <div class="wrapper-overflow">
        <v-flex xs12 v-if="searchTerm !== ''" class="glossary-panel__terms">
          <div v-if="emptyGlossary" class="text-center">
            <span>No terms found</span>
          </div>
          <!-- List of Terms when we are searching   -->
          <p-panel-properties v-else three-line>
            <p-property-tile
              class="glossary-panel__term"
              v-for="term in glossary"
              :key="term.header.metadata.custom_id"
              :title="termTitle(term)"
              :showTitleAndSubtitleTooltip="true"
              :sub-title="termDescription(term)">
              <v-menu offset-y v-if="mode === 'edit'">
                <template v-slot:activator="{ on }">
                  <v-btn flat v-on="on" class="lp-outline-panel-actions-button">
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-tile @click="openTermNewWindow(term)">
                    <v-list-tile-title class="body-1">
                      <v-layout row align-center>
                        <v-icon class="mr-2">mdi-open-in-new</v-icon>
                        <span>Open term</span>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile>

                  <v-list-tile @click="removeTerm(term)">
                    <v-list-tile-title class="body-1">
                      <v-layout row align-center>
                        <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                        <span>Remove</span>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>

              <v-tooltip bottom v-else>
                <template v-slot:activator="{ on }">
                  <v-btn icon flat @click="openTermNewWindow(term)" v-on="on" class="lp-outline-panel-actions-button">
                    <v-icon>mdi-open-in-new</v-icon>
                  </v-btn>
                </template>
                <span>Open term</span>
              </v-tooltip>
            </p-property-tile>
          </p-panel-properties>
        </v-flex>

        <v-flex v-else xs12 class="glossary-panel__terms">
          <!-- List of local Glossary   -->
          <p-panel-properties>
            <p-panel-properties-group
              v-if="localGlossary.length"
              title="Local Glossary"
              action-icon
              :action-icon-id="uuid()">
              <p-panel-properties three-line>
                <p-property-tile
                  class="glossary-panel__term"
                  v-for="localTerm in localGlossary"
                  :key="localTerm.header.metadata.custom_id"
                  :title="termTitle(localTerm)"
                  :showTitleAndSubtitleTooltip="true"
                  :sub-title="termDescription(localTerm)">
                  <v-menu offset-y v-if="mode === 'edit'">
                    <template v-slot:activator="{ on }">
                      <v-btn flat v-on="on" class="lp-outline-panel-actions-button">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-tile @click="openTermNewWindow(localTerm)">
                        <v-list-tile-title class="body-1">
                          <v-layout row align-center>
                            <v-icon class="mr-2">mdi-open-in-new</v-icon>
                            <span>Open term</span>
                          </v-layout>
                        </v-list-tile-title>
                      </v-list-tile>

                      <v-list-tile @click="removeTerm(localTerm)">
                        <v-list-tile-title class="body-1">
                          <v-layout row align-center>
                            <v-icon class="mr-2">mdi-trash-can-outline</v-icon>
                            <span>Remove</span>
                          </v-layout>
                        </v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu>

                  <v-tooltip bottom v-else>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        flat
                        @click="openTermNewWindow(localTerm)"
                        v-on="on"
                        class="lp-outline-panel-actions-button mr-0">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Open term</span>
                  </v-tooltip>
                </p-property-tile>
              </p-panel-properties>

              <template v-slot:actionIcon>
                <v-btn v-if="mode === 'edit'" flat icon>
                  <v-icon color="red" @click="removeLocalGlossary">mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </p-panel-properties-group>

            <!-- List of imported glossaries -->
            <p-panel-properties-group
              v-for="glossary in importedGlossary"
              :key="getCustomId(glossary)"
              :title="getTitle(glossary) + ' (Imported)'"
              action-icon
              :action-icon-id="getCustomId(glossary)">
              <p-panel-properties three-line>
                <p-property-tile
                  class="glossary-panel__term"
                  v-for="glossaryTerm in importedGlossaryTerms(glossary)"
                  :key="uuid()"
                  :title="termTitle(glossaryTerm)"
                  :showTitleAndSubtitleTooltip="true"
                  :sub-title="termDescription(glossaryTerm)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        flat
                        @click="openTermNewWindow(glossaryTerm)"
                        v-on="on"
                        class="lp-outline-panel-actions-button mr-0">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Open term</span>
                  </v-tooltip>
                </p-property-tile>
              </p-panel-properties>

              <template v-slot:actionIcon>
                <v-btn v-if="mode === 'edit'" flat icon>
                  <v-icon color="red" @click="removeGlossary(glossary)">mdi-trash-can-outline</v-icon>
                </v-btn>
              </template>
            </p-panel-properties-group>

            <!-- List of IT glossaries within IB -->
            <p-panel-properties-group
              v-if="isGlossaryFromIB && glossaryFromItsWithinIb.length"
              title="Glossaries from Interactive texts"
              action-icon
              :action-icon-id="uuid()">
              <p-panel-properties three-line>
                <p-property-tile
                  class="glossary-panel__term"
                  v-for="glossaryTerm in glossaryFromItsWithinIb"
                  :key="uuid()"
                  :title="termTitle(glossaryTerm)"
                  :showTitleAndSubtitleTooltip="true"
                  :sub-title="termDescription(glossaryTerm)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        flat
                        @click="openTermNewWindow(glossaryTerm)"
                        v-on="on"
                        class="lp-outline-panel-actions-button mr-0">
                        <v-icon>mdi-open-in-new</v-icon>
                      </v-btn>
                    </template>
                    <span>Open term</span>
                  </v-tooltip>
                </p-property-tile>
              </p-panel-properties>
            </p-panel-properties-group>
          </p-panel-properties>
        </v-flex>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import PPanelProperties from "../../lessonPlan/blocks/_components/utils/PPanelProperties.vue"
import PPanelPropertiesGroup from "../../lessonPlan/blocks/_components/utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../../lessonPlan/blocks/_components/utils/PPropertyTile.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"
import { getType, getTitle, getCustomId } from "../../../utils/dbTool"
import uuid from "../../../utils/uuid"

import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_WARNING } from "../../../store_constants/notifications"
import { mapActions } from "vuex"

export default {
  name: "GlossaryPanel",
  components: { PPanelProperties, PPropertyTile, PPanelPropertiesGroup },
  data() {
    return {
      searchTerm: "",
      mode: this.$attrs.mode,
      value: this.$attrs.value,
      controller: this.$attrs.controller
    }
  },

  computed: {
    glossary() {
      const contents = this.value.body.contents
      if (!contents.glossary) return []
      const glossary = [...contents.glossary]

      // Merge glossaries from ITs
      if (this.isGlossaryFromIB) {
        const glossariesFromIts = this.controller.vm.interactiveTextController.glossariesFromItsFromPage

        glossariesFromIts.forEach(itTerm => {
          if (
            glossary.findIndex(ibTerm => ibTerm.header.metadata.content_id === itTerm.header.metadata.content_id) === -1
          )
            glossary.push(itTerm)
        })
      }

      // Merge imported glossaries
      if (contents.glossaryImported) {
        contents.glossaryImported.map(glossaryImp => {
          // Don't duplicate terms
          glossaryImp.body.contents.items.map(term => {
            if (
              glossary.findIndex(
                glosTerm => glosTerm.header.metadata.content_id === term.header.metadata.content_id
              ) === -1
            )
              glossary.push(term)
          })
        })
      }

      this.sortGlossaryAsc(glossary)

      if (!this.searchTerm || this.searchTerm.trim() === "") return glossary

      return glossary.filter(
        term =>
          term.body.contents.term?.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          (term.body.contents.acronym &&
            term.body.contents.acronym.toLowerCase().includes(this.searchTerm.toLowerCase()))
      )
    },
    glossaryFromItsWithinIb() {
      return this.controller.vm.interactiveTextController?.glossariesFromItsFromPage
    },
    localGlossary() {
      const contents = this.value.body.contents
      if (!contents.glossary) return []
      const glossary = [...contents.glossary]
      return this.sortGlossaryAsc(glossary)
    },
    importedGlossary() {
      const contents = this.value.body.contents
      if (!contents.glossaryImported) return []
      const glossaryImported = [...contents.glossaryImported]

      return glossaryImported
    },
    emptyGlossary() {
      return this.glossary.length === 0
    },
    isGlossaryFromIB() {
      return this.value.header.metadata.content_revision_type === "interactiveBook"
    }
  },

  created() {
    if (!this.value.body.contents.hasOwnProperty("glossary")) this.controller.initGlossary()
    if (!this.value.body.contents.hasOwnProperty("glossaryImported")) this.controller.initGlossaryImported()
  },

  watch: {
    searchTerm: {
      handler(value) {
        if (value === null) this.searchTerm = ""
      }
    }
  },

  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      warning: ACTION_NOTIFICATIONS_WARNING
    }),

    getCustomId,
    getTitle,
    uuid,

    async selectFromContentManager(filterType = "term") {
      let newContentRef = await PServiceContent.selectContent({
        type: filterType,
        multipleSelection: filterType === "term" ? true : false
      })
      if (!newContentRef) return

      if (!Array.isArray(newContentRef)) {
        newContentRef = [newContentRef]
      }

      const glossary = newContentRef[0]
      if (getType(glossary) === "glossary") {
        if (!this.existsGlossary(glossary)) {
          this.controller.setGlossary(glossary)
        } else {
          this.warning({
            message: "Glossary already imported"
          })
        }
        return
      }

      const warnings = []
      for (let term of newContentRef) {
        if (this.existsTerm(term)) {
          warnings.push(term.body.contents.term)
          continue
        }

        this.controller.setTermGlossary(term)
      }

      if (warnings.length) {
        this.warning({
          message: `Terms already added to Glossary: ${warnings}`
        })
      }
    },

    existsGlossary(glossary) {
      return this.controller.getGlossaryById(glossary.header.metadata.content_id)
    },

    existsTerm(term) {
      return this.controller.getTermGlossaryById(term.header.metadata.content_id)
    },

    importedGlossaryTerms(glossary) {
      return glossary.body.contents?.items ? glossary.body.contents.items : []
    },

    openTermNewWindow(term) {
      PServiceContent.openContent(term, "tab")
    },

    removeGlossary(glossary) {
      this.controller.deleteGlossaryById(glossary.header.metadata.content_id)
    },

    removeLocalGlossary() {
      this.controller.deleteLocalGlossary()
    },

    removeTerm(term) {
      this.controller.deleteTermGlossaryById(term.header.metadata.content_id)
    },

    termTitle(term) {
      let title = term.body.contents.term ? term.body.contents.term : ""
      if (term.body.contents.acronym) title += ` (${term.body.contents.acronym})`

      return title
    },

    termDescription(term) {
      return term.body.contents.description || ""
    },

    sortGlossaryAsc(glossary) {
      // Sort glossary asc
      glossary.sort((a, b) => {
        const aTerm = a.body.contents.term?.toLowerCase()
        const bTerm = b.body.contents.term?.toLowerCase()

        if (aTerm < bTerm) return -1
        if (aTerm > bTerm) return 1
        return 0
      })

      return glossary
    }
  }
}
</script>
<style>
.glossary-panel__terms .glossary-panel__term .v-list__tile__sub-title > p:last-child {
  margin-bottom: 0;
}
.wrapper-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
