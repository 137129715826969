var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-block',{attrs:{"value":_vm.value,"mode":_vm.mode},scopedSlots:_vm._u([{key:"main",fn:function(){return [_c('svg',{staticClass:"shape-diamond",staticStyle:{"vertical-align":"top"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":"100%","height":"100%","preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`}},[_c('defs',[_c('path',{style:(_vm.value.data.style),attrs:{"id":`path-${_vm.id}`,"d":`
              M ${_vm.w / 2} 0
              L ${_vm.w} ${_vm.h / 2}
              L ${_vm.w / 2} ${_vm.h}
              L 0 ${_vm.h / 2}
              L ${_vm.w / 2} 0
            `}}),_c('clipPath',{attrs:{"id":`clip-${_vm.id}`}},[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`}})])]),_c('g',[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`,"clip-path":`url(#clip-${_vm.id})`}})])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }