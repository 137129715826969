import { render, staticRenderFns } from "./AppUserMessage.vue?vue&type=template&id=6e082c60&scoped=true"
import script from "./AppUserMessage.vue?vue&type=script&lang=js"
export * from "./AppUserMessage.vue?vue&type=script&lang=js"
import style0 from "./AppUserMessage.vue?vue&type=style&index=0&id=6e082c60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e082c60",
  null
  
)

export default component.exports