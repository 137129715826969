import Content from "./index.vue"
import ExplorerContent from "./ExplorerContent.vue"
import store from "./store"
export default {
  path: "collection",
  name: "Collection",
  component: Content,
  children: [
    {
      path: ":library_id",
      name: "Collection List",
      props: true,
      component: ExplorerContent,
      meta: {
        store
      }
    }
  ]
}
