<template>
  <div class="lp-block-video-audio-upload">
    <!-- Video -->
    <div v-if="video">
      <div v-if="value.data.video !== null">
        <block-video-viewer :value="value" />
      </div>
      <block-placeholder
        v-else
        @click.native="uploadAudioVideo"
        img="add_video"
        icon="mdi-video-plus"
        text="Upload Video" />
    </div>
    <!-- Audio -->
    <div v-else>
      <div v-if="value.data.audio !== null">
        <block-audio-viewer :value="value" />
      </div>
      <block-placeholder
        v-else
        @click.native="uploadAudioVideo"
        img="add_audio"
        icon="mdi-microphone"
        text="Upload Audio" />
    </div>
  </div>
</template>

<script>
import { getMaxVideoSize } from "./utils.js"
import { uploadFile } from "../_utils/utils.js"
import { PFile } from "plant-common/src/utils/models"
import BlockVideoViewer from "./BlockVideoViewer.vue"
import BlockAudioViewer from "../audio/BlockAudioViewer.vue"
import BlockPlaceholder from "../_components/placeholders/BlockPlaceholder.vue"

export default {
  components: {
    BlockVideoViewer,
    BlockAudioViewer,
    BlockPlaceholder
  },
  props: ["value", "video"],
  data() {
    return {
      type: null
    }
  },
  methods: {
    async uploadAudioVideo() {
      const file = await uploadFile()
      this.type = file.type
      var newFile = new PFile(file)
      await newFile.upload()
      if (this.video) {
        this.value.data.video = newFile
      } else {
        this.value.data.audio = newFile
      }
    }
  }
}
</script>

<style>
.lp-block-video-audio-upload > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-block-video-audio-upload,
.lp-block-video-audio-upload div,
.lp-block-video-audio-upload video {
  width: 100%;
  height: 100%;
}
</style>
