<template>
  <span v-if="value">
    <p-toolbar-button
      v-if="getImage"
      :disabled="!selectedBlock || changeContentProtection"
      :tooltip="selectedBlock ? 'Remove image' : 'Select a block to remove image'"
      icon="mdi-image-off-outline"
      @click.stop="removeImage()"
      >Remove</p-toolbar-button
    >
    <p-toolbar-button
      v-if="!getImage"
      :disabled="!selectedBlock || changeContentProtection"
      :tooltip="selectedBlock ? 'Upload image' : 'Select a block to upload image'"
      icon="mdi-image-plus"
      @click.stop="uploadImage"
      >Upload</p-toolbar-button
    >
    <toolbar-image-crop-block v-if="getImage" :value="value" />
  </span>
</template>

<script>
import { PToolbarButton } from "plant-common"
import ToolbarImageCropBlock from "./ToolbarImageCropBlock.vue"
import { uploadImage } from "./utils"
import _ from "lodash"

export default {
  name: "ImageToolbar",
  props: ["value"],
  inject: ["blockController"],
  components: {
    ToolbarImageCropBlock,
    PToolbarButton
  },
  data() {
    return {
      menu: false,
      selectedBlock: null
    }
  },
  created() {
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`selection-changed`, ({ block, state }) => {
        this.selectedBlock = block
      })
    }
  },
  computed: {
    getImage() {
      if (!this.value) {
        return false
      }
      return this.value.data.image
    },
    changeContentProtection() {
      return this.value.protection.data
    }
  },
  methods: {
    uploadImage,
    removeImage() {
      this.value.data.image = null
      this.value.data.cropped = null
    }
  }
}
</script>
