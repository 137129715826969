<template>
    <v-textarea v-if="guideController.selectedCard"
      class="ma-2"
      placeholder="Here goes the speaker's notes"
      v-model="guideController.selectedCard.notes"
      :readonly="readonly"
    ></v-textarea>
  
</template>

<script>
export default {
  name: "PropertiesCard",
  props: ['readonly'],
  inject: ["guideController"]
};
</script>

