<template>
  <component v-bind:is="component" mode="print" v-model="itemRevision" :view.sync="current_view" />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import { Extensions } from "plant-common"
import Loading from "../_layout/Loading"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveTextViewer from "@/components/interactiveText"
import interactiveBookViewer from "@/components/interactiveBook"

import content_store from "@/store/content"
import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_SELECTED_TAG,
  ACTION_LOAD_CONTENT,
  ACTION_LOAD_DRAFT,
  MUTATION_DESTROY_CONTENT,
  MUTATION_SELECT_TAG,
  MUTATION_SET_CURRENT_VIEW
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { getType } from "../../../utils/dbTool"

export default {
  name: "ContentPrint",
  props: ["id", "major_revision", "minor_revision", "view"],
  data() {
    return {
      component: Loading,
      Extensions,
      current_view: false
    }
  },
  components: {},
  beforeCreate() {
    if (this.$store.registerModule(STORE_CONTENT_NAME, content_store) === false) {
      this.$store.unregisterModule(STORE_CONTENT_NAME)
      this.$store.registerModule(STORE_CONTENT_NAME, content_store)
    }
  },
  created() {
    this.$nextTick(() => {
      try {
        if (this.$route.hash.substr(1)) {
          this.current_view = JSON.parse(decodeURI(this.$route.hash.substr(1)))
        }
      } catch (e) {
        console.error(e)
      }
    })
  },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      selectedTag: STATE_SELECTED_TAG
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_CONTENT, ACTION_LOAD_DRAFT, MUTATION_DESTROY_CONTENT]),
    ...mapMutations(STORE_CONTENT_NAME, [MUTATION_SELECT_TAG, MUTATION_SET_CURRENT_VIEW]),
    loadContentInView(itemRevision) {
      if (!itemRevision) return
      const content_type = getType(itemRevision)
      // Guide and lesson plan contents placed inside app
      Extensions["guide"] = guideItemViewer
      Extensions["guideTemplate"] = guideTemplateItemViewer
      Extensions["lessonPlan"] = lessonPlanViewer
      Extensions["interactiveSlide"] = interactiveSlideViewer
      Extensions["interactiveText"] = interactiveTextViewer
      Extensions["interactiveBook"] = interactiveBookViewer

      if (Extensions[content_type] && Extensions[content_type]["Print"]) {
        this.component = Extensions[content_type]["Print"]
      } else {
        this.component = {
          template: `<div>Print View for the extension ${content_type} not found.</div>`
        }
      }
    },
    reloadContent() {
      if (this.major_revision === undefined && this.minor_revision === undefined) {
        this[ACTION_LOAD_DRAFT]({ item_id: this.id, mode: "view" }).then(() => {
          this.loadContentInView(this.itemRevision)
        })
      } else {
        let itemToLoad = {
          id: this.id,
          major_revision: this.major_revision,
          minor_revision: this.minor_revision == 0 ? "0" : this.minor_revision
        }
        this[ACTION_LOAD_CONTENT](itemToLoad).then(() => {
          this.loadContentInView(this.itemRevision)
        })
      }
    }
  },
  destroyed() {
    this[MUTATION_DESTROY_CONTENT]()
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    major_revision: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    minor_revision: {
      immediate: true,
      handler() {
        return this.reloadContent()
      }
    },
    current_view: {
      handler() {
        if (!this.current_view) return
        window.location.hash = JSON.stringify(this.current_view)
        this[MUTATION_SET_CURRENT_VIEW](this.current_view)
      }
    },
    view: {
      handler() {
        if (!this.view) return
        this.current_view = this.view
      }
    }
  }
}
</script>
