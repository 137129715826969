<template>
  <div style="width: 250px">
    <v-autocomplete
      v-model="content"
      v-if="true"
      :items="items"
      @blur="reset"
      label="Search by title or custom id"
      autofocus
      hide-no-data
      hide-details
      @keyup.enter="searchURL(query)"
      @change="openContent"
      :search-input.sync="search"
      return-object
      append-icon
      prepend-inner-icon="mdi-magnify"
      browser-autocomplete="off"></v-autocomplete>
  </div>
</template>

<script>
import { mapState } from "vuex"
import PtApiClient from "plant-api-client"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { getContentId, getTitle, getCustomId } from "../../../../../utils/dbTool"
import { STORE_EXPLORER_NAME, STATE_CONTENTS, STATE_FILTER_TYPE } from "@/store_constants/explorer"
import PServiceContent from "plant-common/src/services/PServiceContent"
export default {
  props: ["value"],
  data() {
    return {
      content: "",
      query: "",
      showSearch: false,
      loading: false,
      items: [],
      itemsInfo: [],
      search: null,
      select: null,
      timeout: null
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      contents: STATE_CONTENTS,
      filterType: STATE_FILTER_TYPE
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  watch: {
    search() {
      this.query = this.search
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      // Wait 0.5 second before call to search endpoint
      this.timeout = setTimeout(() => {
        if (this.search) {
          if (this.search.length >= 3) {
            PtApiClient.searchContent(this.search, this.filterType)
              .then(res => {
                this.items = res.docs.map(el => {
                  return {
                    field: getContentId(el),
                    text: `${getCustomId(el)}: ${getTitle(el)}`
                  }
                })
                this.itemsInfo = res
              })
              .catch(err => console.error(err))
          }
        }
      }, 500)
    }
  },
  methods: {
    searchURL(query) {
      if (this.query) {
        this.$router.push(`/explorer/${this.settings._id}?q=${query}`)
      }
      this.search = ""
      this.query = ""
      this.reset()
    },
    querySelections(v, res) {
      this.items = res.filter(e => {
        if (!e.header) return false
        return (
          (getTitle(e) || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1 ||
          (getCustomId(e) || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
        )
      })
      return true
    },
    reset() {
      this.showSearch = false
      this.items = []
    },
    openContent() {
      PServiceContent.openContent(this.content.field, "tab")
    }
  }
}
</script>

<style>
</style>
