import uuid from "../../../utils/uuid.js"
import { PFile } from "plant-common/src/utils/models"

export function copyBlock(block, position) {
  const copy = cloneDeep(block)

  copy.id = uuid()
  const protectionType = ["data", "move", "resize", "delete"]
  protectionType.forEach(type => (copy.protection[type] = false))
  if (position) {
    copy.position.x = position.x
    copy.position.y = position.y
  }
  copy.position.x += 50
  copy.position.y += 50

  if (copy.type === "shapeLine" || copy.type === "shapeLineConnector") {
    copy.data.begin.x = copy.position.x
    copy.data.begin.y = copy.position.y
    copy.data.end.x = copy.size.width + copy.position.x
    copy.data.end.y = copy.size.height + copy.position.y
  }

  changeAllBlockIds(copy)

  return copy
}

export function changeAllBlockIds(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      return changeAllBlockIds(obj[key])
    }
    if (key === "id") {
      obj[key] = uuid()
    }
  })
}

export function getLayout() {
  return document.querySelector(".lp-slide")
}

import _cloneDeep from "lodash.clonedeep"

export function cloneDeep(obj) {
  return _cloneDeep(obj)
}

export function getUniqueArray(array, key) {
  return array.filter((item, index, self) => index === self.findIndex(i => i[key] === item[key]))
}

export async function deepSlideCopy(slide, contentId) {
  async function findFiles(obj, contentId) {
    if (obj == null) return obj
    if (obj._file) {
      let downloadBlob = await (await fetch(obj.url)).blob()
      // Transform Blob to File object: https://stackoverflow.com/questions/27159179/how-to-convert-blob-to-file-in-javascript
      downloadBlob.lastModifiedDate = new Date()
      downloadBlob.name = obj._file
      downloadBlob.__proto__ = File.prototype
      let newFile = new PFile(downloadBlob)
      await newFile.upload(contentId)
      return newFile
    } else if (typeof obj == "object" && obj != null) {
      for await (let k of Object.keys(obj)) {
        obj[k] = await findFiles(obj[k], contentId)
      }
      return obj
    } else {
      return obj
    }
  }

  return await findFiles(slide, contentId)
}

/**
 *
 * @param {*} obj Interactive Presentation block
 * Convert _file (File) property to _objectFile (Object) property because clipboard can not save File class like text
 */
export function convertFileToObject(obj) {
  Object.keys(obj).forEach(function (key) {
    // Parse _file to _objectFile only if _file is a File class (typeof object)
    if (key === "_file" && typeof obj[key] === "object") {
      obj["_objectFile"] = {
        lastModified: obj[key].lastModified,
        lastModifiedDate: obj[key].lastModifiedDate,
        name: obj[key].name,
        size: obj[key].size,
        type: obj[key].type
      }
      delete obj[key]
    }
    if (obj[key] && typeof obj[key] === "object") {
      return convertFileToObject(obj[key])
    }
  })
}

/**
 *
 * @param {*} obj Interactive Presentation block
 * Creates a new Blob from _objectFile URL (blob URL) and save it as _file (File)
 */
export async function convertObjectToFile(obj) {
  const mediaFiles = ["image", "video", "audio"]
  for await (let key of Object.keys(obj)) {
    if (mediaFiles.includes(key) && obj[key] && typeof obj[key] === "object") {
      for await (let subKey of Object.keys(obj[key])) {
        if (subKey === "_objectFile") {
          const res = await fetch(obj[key]["url"])
          const blob = await res.blob()
          const file = new File([blob], obj[key]["name"] ? obj[key]["name"] : "newObject")
          obj[key] = new PFile(file)
        }
      }
    }
    if (obj[key] && typeof obj[key] === "object") {
      await convertObjectToFile(obj[key])
    }
  }
}

/**
 * @function searchForBlock: search recursively for specific block inside Interactive Presentation slide
 * @param blocks: list of blocks in current Interactive Presentation slide
 * @param id: block id
 * @returns boolean: true/false depending if block was found
 */
export function searchForBlock(blocks, id) {
  var index = -1
  var indexFound = false
  blocks.forEach(block => {
    if (block.data && block.data.cards) {
      // Current block is Carousel (BlockCarousel)
      block.data.cards.forEach(card => {
        if (card.blocks.length > 0) {
          index = card.blocks.findIndex(b => b.id == id)
          if (index >= 0) {
            indexFound = true
            card.blocks.splice(index, 1)
          }
          if (!indexFound) {
            indexFound = searchForBlock(card.blocks, id)
          }
        }
      })
    } else if (block.data && block.data.tabs) {
      // Current block is Tabs (BlockTabs)
      block.data.tabs.forEach(tab => {
        if (tab.blocks.length > 0) {
          index = tab.blocks.findIndex(b => b.id == id)
          if (index >= 0) {
            indexFound = true
            tab.blocks.splice(index, 1)
          }
          if (!indexFound) {
            indexFound = searchForBlock(tab.blocks, id)
          }
        }
      })
    }
  })
  return indexFound
}

/**
 * @function findCommentsForSlide: search recursively for all comments inside an slide and its children
 * @param slide: slide to search for
 * @param comments: list of comments inside Interactive Presentation
 * @returns array: list of comments found inside slide given
 */
export function findCommentsForSlide(slide, comments) {
  const commentsInSlide = []

  function findCommentsOnChildren(slide, comments) {
    if (slide.children && slide.children.length > 0) {
      slide.children.forEach(slideChildren => {
        comments.forEach(comment => {
          if (!comment.deleted_at && comment.location && comment.location.slide_id === slideChildren.id) {
            commentsInSlide.push(comment)
          }
        })
        findCommentsOnChildren(slideChildren, comments)
      })
    }
  }

  comments.forEach(comment => {
    if (
      !comment.deleted_at &&
      comment.location &&
      comment.location.slide_id &&
      comment.location.slide_id === slide.id
    ) {
      commentsInSlide.push(comment)
    }
  })
  findCommentsOnChildren(slide, comments)
  return commentsInSlide
}
