var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","left":"0px","right":"0px","bottom":"0px","top":"0px"}},[[_c('guide-toolbar-viewer',{style:(`padding-left:${(_vm.isPresentMode ? 0 : 80) + (_vm.drawer > -1 ? 400 : 0)}px;padding-right:${
        (_vm.isPresentMode || _vm.mode == 'activity' ? 0 : 80) + (_vm.right_drawer > -1 ? 400 : 0)
      }px;`)}),_c('viewer-shortcuts'),(!_vm.isPresentMode)?[_c('v-navigation-drawer',{attrs:{"stateless":"","absolute":"","permanent":"","mini-variant":"","left":""}},[_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.items_left),function(item,index){return _c('v-tooltip',{key:index,attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-tile',_vm._g({attrs:{"value":index == _vm.drawer},on:{"click":function($event){_vm.drawer = _vm.drawer == index ? -1 : index}}},on),[_c('v-list-tile-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])}),1),_c('v-list',{staticStyle:{"position":"absolute","bottom":"0px","left":"0px","right":"0px"}},[_c('v-list-tile',{on:{"click":function($event){_vm.drawer = _vm.drawer > -1 ? -1 : 0}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-chevron-double-"+_vm._s(_vm.drawer ? "right" : "left"))])],1)],1)],1)],1),(_vm.drawer > -1)?_c('v-navigation-drawer',{staticStyle:{"margin-left":"80px"},attrs:{"absolute":"","left":"","width":"400px"}},[_c(_vm.items_left[_vm.drawer].component,{tag:"component"})],1):_vm._e()]:_vm._e(),_c('ViewerCard',{style:(`position:absolute;overflow:hidden;border: 0px solid red; left:${
        (_vm.isPresentMode ? 0 : 80) + (_vm.drawer > -1 ? 400 : 0)
      }px;right:${
        (_vm.isPresentMode || _vm.mode == 'activity' ? 0 : 80) + (_vm.right_drawer > -1 ? 400 : 0)
      }px;top:40px;bottom:36px;`)}),(!_vm.isPresentMode && _vm.mode != 'activity')?[_c('v-navigation-drawer',{attrs:{"stateless":"","absolute":"","permanent":"","mini-variant":"","right":""}},[_c('v-list',{staticClass:"pt-0"},_vm._l((_vm.items_right),function(item,index){return _c('v-tooltip',{key:index,attrs:{"right":""}},[[_c('v-list-tile',{attrs:{"value":index == _vm.right_drawer},on:{"click":function($event){_vm.right_drawer = _vm.right_drawer == index ? -1 : index}}},[_c('v-list-tile-action',[(item.badge)?_c('v-badge',{attrs:{"small":"","color":item.badge.color,"overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(item.badge.icon))])]},proxy:true}],null,true)},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1):_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)],1)],_c('span',[_vm._v(_vm._s(item.title))])],2)}),1),_c('v-list',{staticStyle:{"position":"absolute","bottom":"0px","left":"0px","right":"0px"}},[_c('v-list-tile',{on:{"click":function($event){_vm.right_drawer = _vm.right_drawer > -1 ? -1 : 0}}},[_c('v-list-tile-action',[_c('v-icon',[_vm._v("mdi-chevron-double-"+_vm._s(_vm.right_drawer > -1 ? "right" : "left"))])],1)],1)],1)],1),(_vm.right_drawer > -1)?_c('v-navigation-drawer',{staticStyle:{"margin-right":"80px"},attrs:{"absolute":"","right":"","width":"400px"}},[_c(_vm.items_right[_vm.right_drawer].component,{tag:"component"})],1):_vm._e()]:_vm._e(),(_vm.mode != 'activity')?_c('bottom-nav',{attrs:{"styleProp":`left:${(_vm.isPresentMode ? 0 : 80) + (_vm.drawer > -1 ? 400 : 0)}px;
   right:${(_vm.isPresentMode ? 0 : 80) + (_vm.right_drawer > -1 ? 400 : 0)}px; 
   position: absolute; bottom: 0px;`}}):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }