import ContentViewer from "./ContentViewer"
import ContentPrint from "./ContentPrint"
import ContentPreview from "./ContentPreview"
import ContentIP from "./ContentIP"
import ContentEditor from "./ContentEditor"
import DraftPreview from "./DraftPreview"
import DraftPreviewActivity from "./ContentActivity/DraftPreviewActivity"
import LatestPreviewActivity from "./ContentActivity/LatestPreviewActivity"
import MajorMinorPreviewActivity from "./ContentActivity/MajorMinorPreviewActivity"
import ContentViewerLayout from "./_layout/index.vue"
export default [
  {
    name: "Content Draft Print",
    path: "/content/:library_id/:id/draft/print",
    props: true,
    component: ContentPrint
  },
  {
    path: "/content/:library_id/:id",
    component: ContentViewerLayout,
    children: [
      {
        name: "Content Viewer",
        path: "",
        redirect: "latest"
      },
      {
        name: "Edit Draft",
        path: "draft",
        component: ContentEditor,
        props: true
      },
      {
        name: "Preview Draft",
        path: "draft/preview",
        component: DraftPreview,
        props: true
      },
      {
        name: "Preview Activity",
        path: "draft/preview-activity",
        component: DraftPreviewActivity,
        props: true
      },
      {
        name: "Content Viewer Latest",
        path: "latest",
        component: ContentViewer,
        props: true
      },
      {
        name: "Preview Approved Activity",
        path: "latest/preview-activity",
        component: LatestPreviewActivity,
        props: true
      },
      {
        name: "Content Viewer Exact Revision",
        path: ":major_revision/:minor_revision",
        props: true,
        component: ContentViewer
      },
      {
        name: "Content Viewer Exact Revision",
        path: ":major_revision/:minor_revision/preview-activity",
        props: true,
        component: MajorMinorPreviewActivity
      }
    ]
  },
  {
    name: "Content Print",
    path: "/content/:library_id/:id/:major_revision/:minor_revision/print",
    props: true,
    component: ContentPrint
  },
  {
    name: "Content Preview",
    path: "/content/:library_id/:id/:major_revision/:minor_revision/preview",
    props: true,
    component: ContentPreview
  },
  {
    name: "Content Interactive Presentation App",
    path: "/content/:library_id/:id/:major_revision/:minor_revision/ip",
    props: true,
    component: ContentIP
  }
]
