import Replace from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange";
export default {
    id: 'guide-replace-speaker-notes',
    title: 'Replace Guide Speaker Notes',
    description: 'This operation replaces the "find" value (Case sensitive) with the "replace" value in all guide\'s speaker notes',
    contentType: 'guide',
    operation(content, props) {
        function recursiveReplaceChildren(child, searchRegExp, replaceWith) {
            if (child.type == 'card' && child.notes) {
                child.notes = child.notes.replace(searchRegExp, replaceWith)
            } else if (child.type == 'section' && child.children.length > 0) {
                child.children.forEach(child2 => {
                    recursiveReplaceChildren(child2, searchRegExp, replaceWith)
                });
            } else {
                // do nothing
            }
        }
        if (props && props.find && props.replace) {
            const searchRegExp = createRegExp(props.find);
            const replaceWith = props.replace;
            if (content && content.body && content.body.contents && content.body.contents.sections) {
                content.body.contents.sections.forEach(child => {
                    recursiveReplaceChildren(child, searchRegExp, replaceWith)
                });
            }
        }
        return content
    },
    OperationOptionsComponent: Replace
}