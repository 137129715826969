/**
 * Utility function to order a multiple choice question choices based on a position strategy.
 * @param correct {String} Correct answer
 * @param distractors {Array<String>} Array of distractors
 * @param position {'RANDOM'|'A'|'B'|'C' |'D' | 'LENGTH' | 'NUMBER'}
 * @returns Array of choices order according to the chosen algorithm
 */

export function orderChoices(correct, distractors, position) {
  // fixed position
  let choices = [...distractors]
    .filter(d => d && d.trim())
    .filter(dis => dis !== "" && dis !== "<p></p>" && cleanTag(dis, "p") !== "") // Remove empty distractors

  if (["A", "B", "C", "D"].includes(position)) {
    const index = ["A", "B", "C", "D"].findIndex(v => v === position)
    choices.splice(index, 0, correct)
    return choices
  }

  // Other order options
  choices.push(correct)
  if (position === "LENGTH") {
    choices = choices.sort((first, second) => (first.length > second.length ? 1 : -1))
  } else if (position === "NUMBER") {
    choices = choices.sort((first, second) => (parseFloat(first) > parseFloat(second) ? 1 : -1))
  } else {
    // Randomize if position field has not been set
    choices = choices.sort(() => Math.random() - 0.5)
  }
  return choices
}

function cleanTag(elem, tag) {
  const cleanedElem = elem.replace(`<${tag}>`, "").replace(`</${tag}>`, "").trim()
  return cleanedElem
}
