import "./pt-flowchart-path.css"

export default {
  model: {
    "active": {
      default: false,
      type: "boolean"
    }
  },
  listeners: {
    mouseenter: true,
    mouseleave: true,
    click: true,
    contextmenu: true
  },
  options: {
    hasNotes: true,
    canLink: true
  }
}
