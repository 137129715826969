<template>
	<div
		class="lp-flip-card-view"
		:class="{
			animation: mode !== 'edit',
			hover: value.data.trigger === 'hover',
			click: value.data.trigger === 'click' && flip,
			pointer: value.data.trigger === 'click',
			'lp-flip-card-view-edit': mode === 'edit'
		}"
		@click.stop="
			flip = !flip;
			setAsVisited('click');
		"
		@mouseover.stop="setAsVisited('hover')"
	>
		<div class="lp-flip-card-view-content" @dblclick="mode === 'edit' ? $emit('setTextEdit', true) : ''">
			<template v-if="mode === 'edit' && editable">
				<editor-content :editor="editorFront" class="front" :class="{ hidden: side === 'back' }" :style="styleFront" />
				<editor-content :editor="editorBack" class="back" :class="{ hidden: side === 'front' }" :style="styleBack" />
			</template>
			<template v-else>
				<component
					:is="flipCardBlock"
					:value="value.data.front.html"
					class="front"
					:class="{ hidden: side === 'back' }"
					:style="styleFront"
					:blockVariables="blockVariables"
					:block="value"
					:mode="mode"
				/>
				<component
					:is="flipCardBlock"
					:value="value.data.back.html"
					class="back"
					:class="{ hidden: side === 'front' }"
					:style="styleBack"
					:blockVariables="blockVariables"
					:block="value"
					:mode="mode"
				/>
			</template>
		</div>
	</div>
</template>

<script>
import { EditorContent } from '@tiptap/vue-2';
import { renderKatex } from 'plant-common';

export default {
	components: { EditorContent },
	props: ['mode', 'value', 'side', 'editorFront', 'editorBack', 'textEdit', 'blockVariables', 'editable'],
	data() {
		return {
			flip: false
		};
	},
	computed: {
		styleFront() {
			return this.getStyle('front');
		},
		styleBack() {
			return this.getStyle('back');
		},
		flipCardBlock() {
			return {
				name: 'FlipCardBlock',
				template: `
          <div>
            <div class="ProseMirror" v-html="valueHtmlTransformation" ref="tiptap"></div>
          </div>`,
				props: ['value', 'blockVariables', 'block', 'mode'],
				computed: {
					valueHtmlTransformation() {
						let valueHtml = this.value;
						if (this.blockVariables) {
							this.blockVariables.forEach(bVar => {
								valueHtml = valueHtml.replace('{{' + bVar.name + '}}', bVar.value);
							});
						}
						return valueHtml;
					}
				},
				mounted() {
					this.renderFormulas();
				},
				methods: {
					renderFormulas() {
						if (!this.$refs.tiptap) return;
						renderKatex(this.$refs.tiptap);
					}
				}
			};
		}
	},
	methods: {
		getStyle(side) {
			return {
				backgroundColor: this.value.data[side].backgroundColor,
				textAlign: this.value.data[side].textAlign,
				alignItems: this.value.data[side].alignItems,
				padding: this.value.data[side].padding + 'px',
				fontSize: this.value.data[side].fontSize + 'px',
				color: this.value.data[side].color
			};
		},
		setAsVisited(action) {
			if (!this.value.data.visited && action === this.value.data.trigger && this.mode !== 'edit') {
				this.$set(this.value.data, 'visited', true);
			}
		}
	}
};
</script>

<style>
/* Default style */
.lp-flip-card-view {
	line-height: 1.2;
	width: 100%;
	height: 100%;
}
.lp-flip-card-view .ProseMirror {
	width: 100%;
}
.lp-flip-card-view .ProseMirror > * {
	width: 100%;
}
.lp-flip-card-view-content {
	width: 100%;
	height: 100%;
	user-select: none;
}
.lp-flip-card-view-content .front,
.lp-flip-card-view-content .back {
	width: 100%;
	height: 100%;
	display: flex;
}
.lp-flip-card-view:not(.animation) .hidden {
	display: none;
}

/* Animation in viewer mode */
.lp-flip-card-view.animation {
	position: absolute;
	width: 100%;
}
.lp-flip-card-view.animation .lp-flip-card-view-content {
	position: absolute;
	transition: transform 1s;
	transform-style: preserve-3d;
}

/* On hover */
.lp-flip-card-view.animation.hover:hover .lp-flip-card-view-content {
	transform: rotateY(180deg);
	transition: transform 1s;
}
/* On click */
.lp-flip-card-view.animation.click .lp-flip-card-view-content {
	transform: rotateY(180deg);
	transition: transform 1s;
}
.lp-flip-card-view.animation.pointer {
	cursor: pointer;
}
.lp-flip-card-view.animation .front,
.lp-flip-card-view.animation .back {
	position: absolute;
	height: 100%;
	width: 100%;
}
.lp-flip-card-view.animation .front,
.lp-flip-card-view.animation .front > div,
.lp-flip-card-view.animation .back,
.lp-flip-card-view.animation .back > div {
	backface-visibility: hidden;
}

.lp-flip-card-view.animation .back {
	transform: rotateY(180deg);
}

.lp-flip-card-view-edit span[data-inline-katex] {
	background: rgb(235, 235, 235);
	padding: 2px;
	display: inline-block;
}
.lp-flip-card-view-edit .fields:hover {
	background: rgb(235, 235, 235);
	padding: 2px;
	display: inline-block;
}
/* Tooltip */
.lp-flip-card-view-edit .fields:before {
	content: attr(data-field);
	position: absolute;
	bottom: 100%;
	transform: translateX(50%);
	right: 50%;
	padding: 10px;
	width: 100%;
	min-width: 300px;
	background-color: #ffffff;
	text-align: left;
	font-size: 30px;
	display: none;
}
.lp-flip-card-view-edit .fields:hover:before {
	display: block;
}

.lp-flip-card-view-edit .katex-wrapper textarea {
	font-size: 2.2rem;
}

.lp-flip-card-view-edit .katex-component__title {
	font-size: 30px;
}

.lp-flip-card-view-edit .katex-component__title .v-icon {
	font-size: 42px;
}

.lp-flip-card-view-edit .katex-component__title button {
	width: 48px !important;
	height: 48px;
}
</style>
