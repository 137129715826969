<template>
  <div :style="editStyles" class="lp-button-edit">
    <span :id="buttonParentId" class="lp-icon-button">
      <v-icon v-if="value.data.icon && value.data.icon != 'none'" class="lp-icon-button-icon">{{
        value.data.icon
      }}</v-icon>
      <editable-text :key="key" v-model="value.data.text" :contenteditable="isEditable" :parent-id="buttonParentId" class="lp-icon-button-editable" />
    </span>
  </div>
</template>

<script>
import EditableText from "./BlockEditEditableText.vue"

export default {
  inject: ["blockController"],
  props: ["value", "state"],
  components: { EditableText },
  data() {
    return {
      // Editable when click after state is 'edit-content'.
      // To replicate same behaviour as in richtext blocks
      editable: false,
      key: 1
    }
  },
  computed: {
    isEditable() {
      return this.state === "edit-content";
    },
    buttonParentId() {
      return 'blockEdit-' + this.value?.id
    },
    editStyles() {
      const {
        backgroundColor,
        color,
        textScale,
        textAlign: justifyContent,
        fontFamily,
        boxShadowButton: boxShadow,
        borderRadius,
        borderWidth,
        borderStyle,
        borderColor
      } = this.value.data.style.regular

      // No change styles in hover in edit mode
      return {
        backgroundColor,
        color,
        boxShadow,
        fontFamily: fontFamily || "inherit",
        borderRadius: `${borderRadius}px`,
        borderWidth: `${borderWidth}px`,
        borderStyle,
        borderColor,
        // Styles in IconButton element
        "--text-scale": textScale,
        "--text-align": justifyContent == 'center' ? 'center' : 'left',
        "--justify-content": justifyContent,
        width: `${this.value.size.width}px`,
        height: `${this.value.size.height}px`
      }
    }
  },
  watch: {
    isEditable(value) {
      if (!value) this.key++
    }
  },
  methods: {
    changeToEditable() {
      this.editable = this.state === "edit-content"
    }
  }
}
</script>

<style>
.lp-button-edit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-icon-button {
  display: flex;
  text-align: var(--text-align);
  justify-content: var(--justify-content);
  align-items: center;
  width: 100%;
  height: 100%;
}
.lp-icon-button-icon {
  color: inherit !important;
  margin-right: 10px;
  width: calc(var(--text-scale) / 100 * 40px) !important;
}
.lp-icon-button,
.lp-icon-button-icon {
  font-size: calc(var(--text-scale) / 100 * 40px) !important;
}
.lp-icon-button-editable {
  display: inline-block;
}
</style>