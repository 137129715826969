<template>
  <div
    id="lp-slide-wrapper"
    class="lp-slide-wrapper noselect"
    :class="{ dark: blockController.dark, overflowHidden: lessonPlanController.answerHandler.active }"
    ref="viewer"
    :style="settingStyles.wrapper">
    <div class="lp-slide overflowHidden" ref="canvas" :style="slideStyles">
      <div :style="styleCanvas">
        <component
          v-for="block in blocks"
          :key="block.id"
          :is="getBlockComponent(block.type)"
          :value="block"
          :mode="mode === 'preview' ? mode : 'view'"
          @selectSlideById="$emit('selectSlideById', $event)"
          @goToBookmarkTarget="$emit('goToBookmarkTarget', $event)"
          :blockVariables="globalVariables" />
      </div>
      <block-overlay-readonly
        v-if="blockController.overlay"
        :defaultBlockStyles="defaultBlockStyles"
        @selectSlideById="$emit('selectSlideById', $event)"
        @goToBookmarkTarget="$emit('goToBookmarkTarget', $event)" />
      <subtitles-overlay>
      </subtitles-overlay>
    </div>
  </div>
</template>

<script>
import BlockOverlayReadonly from "./BlockOverlayReadonly.vue"
import blockCanvasCommon from "../../_mixins/blockCanvasCommon.vue"
import PtModal from "plant-common/src/components/PtModal/index.vue"
import { getTitle } from "../../../../../utils/dbTool"
import performaceStates from "../../../components/performance/constants/performaceStates.js"
import SubtitlesOverlay from "./SubtitlesOverlay.vue"

const SKIP = "skip"
const REVIEW = "review"

const NOT_EVALUATED = "notEvaluated"

export default {
  inject: ["blockController", "blockDefinitions", "lessonPlanController", "blockEventBus"],
  mixins: [blockCanvasCommon, PtModal],
  components: { BlockOverlayReadonly, SubtitlesOverlay },
  props: {
    blocks: Array,
    defaultBlockStyles: Object,
    settingStyles: Object,
    globalVariables: Array,
    mode: String
  },
  data() {
    return {
      modalOptions: {
        title: "Knowledge acquired",
        message:
          "Based on your performance in the initial quiz, you are already proficient in the contents showed in this section. You are not required to review this section, but you can if you want. Do you want to review the material or skip this section?",
        options: [
          { text: "Skip", value: SKIP, color: "green" },
          { text: "Review", value: REVIEW, color: "blue" }
        ],
        maxWidth: "750"
      },
      performanceStates: performaceStates
    }
  },
  computed: {
    isSectionOptional() {
      const isSection =
        this.lessonPlanController.selectedSlide?.type === "Section" ||
        (this.lessonPlanController.selectedSlide?.children &&
          this.lessonPlanController.selectedSlide.children.length > 0)
      return (
        isSection &&
        this.lessonPlanController.selectedSlide?.objectives.length &&
        this.lessonPlanController.isSectionOptional
      )
    }
  },
  methods: {
    openObjectives(e) {
      this.$emit("openObjectives")
    },
    submitQuiz(e) {
      const parentQuiz = this.lessonPlanController.getParentQuiz(this.lessonPlanController.selectedSlide)
      if (parentQuiz) {
        this.$emit("submitQuiz", parentQuiz)
      } else {
        this.lessonPlanController.showNotification("Button is not inside a Quiz")
      }
    }
  },
  mounted() {
    if (this.blockEventBus) this.blockEventBus.$on("open:objectives", this.openObjectives)
    if (this.blockEventBus) this.blockEventBus.$on("quiz:submit", this.submitQuiz)
    if (this.isSectionOptional) {
      if (
        this.lessonPlanController.selectedSlide.objectives &&
        this.lessonPlanController.selectedSlide.objectives.length > 0
      ) {
        const getCurrentLevel = objectiveId => {
          if (
            this.lessonPlanController.activityState &&
            this.lessonPlanController.activityState.performance &&
            this.lessonPlanController.activityState.performance[objectiveId] &&
            this.lessonPlanController.activityState.performance[objectiveId].quizzes &&
            this.lessonPlanController.activityState.performance[objectiveId].quizzes[0] &&
            this.lessonPlanController.activityState.performance[objectiveId].quizzes[0].currentLevel
          ) {
            return this.lessonPlanController.activityState.performance[objectiveId].quizzes[0].currentLevel
          } else {
            return NOT_EVALUATED
          }
        }
        const getObjectiveDescription = objective => {
          if (objective && objective.body && objective.body.contents && objective.body.contents.text) {
            return objective.body.contents.text
          } else {
            return "No description"
          }
        }
        const objectiveId = this.lessonPlanController.selectedSlide.objectives[0]._id
        const objectiveTitle = getTitle(this.lessonPlanController.selectedSlide.objectives[0])
        const performanceCurrentLevel = getCurrentLevel(objectiveId)

        this.modalOptions.secondMessage = `<p>
          <b>${objectiveTitle}</b> 
          <span style="color : ${this.performanceStates[performanceCurrentLevel].color}">${
          this.performanceStates[performanceCurrentLevel].name
        }</span>
          </p>
          <p>
            ${getObjectiveDescription(this.lessonPlanController.selectedSlide.objectives[0])}
          </p>`
      }
      this.$modal("options", this.modalOptions).then(r => {
        if (r === SKIP) this.lessonPlanController.skipSection()
        if (r === REVIEW) this.lessonPlanController.reviewSection()
      })
    }
  },
  beforeDestroy() {
    if (this.blockEventBus) this.blockEventBus.$off("open:objectives", this.openObjectives)
    if (this.blockEventBus) this.blockEventBus.$off("quiz:submit", this.submitQuiz)
  }
}
</script>

<style>
.noselect * {
  user-select: none;
}
</style>

<style scoped>
.overflowHidden {
  overflow: hidden;
}
</style>
