<template>
	<div class="lp-image-multiple-choice-view">
		<img
			v-if="value.data.cropped"
			width="100%"
			height="100%"
			:src="value.data.cropped.url"
			:style="`border-radius: ${borderRadius}px;`"
			alt="multiple choice image"
		/>
		<img
			v-else
			width="100%"
			height="100%"
			:src="value.data.image.url"
			:style="`border-radius: ${borderRadius}px;`"
			alt="multiple choice image"
		/>
		<div
			:style="`
        position: absolute;
        width: 100%; 
        height: 100%;
        top: 0; 
        left: 0; 
        background: ${value.data.overlay || 'rgba(0,0,0,0)'};
        border-radius: ${borderRadius}px;
      `"
		></div>
	</div>
</template>

<script>
export default {
	props: ['value'],
	computed: {
		borderRadius() {
			return this.value.data.style?.normal.borderRadius || 0;
		}
	}
};
</script>

<style scoped>
.lp-image-multiple-choice-view {
	width: 100%;
	height: 100%;
}

.lp-image-multiple-choice-view img {
	object-fit: contain;
}
</style>
