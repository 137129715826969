<template>
  <toolbar-button
    icon="mdi-folder-plus-outline"
    tooltip="Create a new folder"
    @click="createFolderModal"
  ></toolbar-button>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { PtModal } from "plant-common";
import ToolbarButton from "./ToolbarButton";
import {
  STORE_EXPLORER_NAME,
  STATE_FOLDER,
  ACTION_NEW_FOLDER
} from "@/store_constants/explorer";

export default {
  mixins: [PtModal],
  components: { ToolbarButton },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER
    })
  },
  methods: {
    ...mapActions(STORE_EXPLORER_NAME, {
      newFolder: ACTION_NEW_FOLDER
    }),
    createFolderModal() {
      var parent_id = this.folder ? this.folder._id : false;
      this.$modal("input", {
        title: "Add New Folder"
      }).then(name => {
        if (typeof name == "undefined") return;
        this.newFolder({ name, parent_id });
      });
    }
  }
};
</script>
