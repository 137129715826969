<template>
  <p-property-tile
    v-if="learningObjective.section && learningObjective.section.id"
    icon="mdi-folder-outline"
    title="Related content">
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on" small class="mr-2">
            <v-avatar :class="completionReasons[completionReason].color">{{
              completionReasons[completionReason].avatar
            }}</v-avatar>
            {{ completionReasons[completionReason].name }}
          </v-chip>
        </template>
        <span>{{ completionReasons[completionReason].description }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-if="canNavigateToSection" v-on="on" @click="goToSlide(learningObjective.section.id)">
            mdi-folder-arrow-right-outline
          </v-icon>
          <v-icon v-else v-on="on" class="custom-disabled"> mdi-folder-cancel-outline </v-icon>
        </template>
        <span v-if="canNavigateToSection">Go to content</span>
        <span v-else>Content not available yet</span>
      </v-tooltip>
    </div>
  </p-property-tile>
</template>

<script>
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"
import completionReasons from "../constants/completionReasons.js"
export default {
  name: "ContentRelatedObjective",
  inject: ["lessonPlanController"],
  components: { PPropertyTile },
  props: {
    learningObjective: Object
  },
  data() {
    return {
      completionReasons: completionReasons
    }
  },
  computed: {
    completionReason() {
      if (this.learningObjective.section && this.learningObjective.section.id) {
        return this.getCompletionReasonForSlide(this.learningObjective.section.id)
      } else {
        return null
      }
    },
    canNavigateToSection() {
      if (this.learningObjective.section && this.learningObjective.section.id) {
        return this.lessonPlanController.isSectionAvailable(this.learningObjective.section.id)
      } else {
        return false
      }
    }
  },
  methods: {
    goToSlide(slideId) {
      this.lessonPlanController.selectSlideById(slideId)
    },
    getCompletionReasonForSlide(slideId) {
      const slide = this.lessonPlanController.getSlideFromSlideId(slideId)
      const activityState = this.lessonPlanController.getSlideActivityState(slide)
      return activityState.completionReason
    }
  }
}
</script>

<style scoped>
.custom-disabled {
  cursor: auto;
  opacity: 0.6;
}
</style>
