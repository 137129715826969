
<template>
  <v-dialog v-model="value" max-width="400" max-height="400" scrollable>
    <v-card id="path-selector">
      <v-card-title class="headline">Select Destination</v-card-title>
      <v-card-title>
        <b>Path to: </b>
        {{(active[0]||{}).name}}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 400px;">
        <v-treeview
          :open.sync="open"
          :items="items"
          activatable
          :active.sync="active"
          item-key="_id"
          :multiple-active="false"
          :open-on-click="false"
          return-object
          :load-children="fetchSubfolders"
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="item._id=='$root'">mdi-factory</v-icon>
            <v-icon v-else>{{ open ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
          </template>
        </v-treeview>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" block flat @click="$emit('input', false)">Cancel</v-btn>
        <v-btn color="success" block @click="$emit('input', active[0])" :disabled="active.length<1">
          <v-icon left>mdi-folder-move</v-icon>Select
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ptApiClient from 'plant-api-client'
export default {
  props:[],
  data: () => ({
    value: true,
    open: [],
    active: [],
    folders: []
  }),
  computed: {
    items() {
      return [
        {
          _id: "$root",
          name: "This Plant",
          children: this.folders
        }
      ];
    }
  },
  methods: {
    fetchSubfolders(folder) {
      if(folder._id == "$root") {
        return ptApiClient.getRootFolders()
        .then(subfolders => {
          if (subfolders.docs.length > 0) {
            subfolders.docs.forEach(sf => (sf.children = []));
            folder.children.push(...subfolders.docs);
          } else {
            this.$delete(folder, "children");
          }
        })
        .catch(err => console.warn(err));
        folder._id = null
      } else {
        return ptApiClient.getFolder(folder._id)
        .then(subfolders => {
          if (subfolders.docs.length > 0) {
            subfolders.docs.forEach(sf => (sf.children = []));
            subfolders.docs.forEach(sf => {
              if(sf.parent_id) folder.children.push(sf);
            })
          } else {
            this.$delete(folder, "children");
          }
        })
        .catch(err => console.warn(err));
      }
    }
  }
};
</script>