import PtApiClient from "plant-api-client"

export default {
  name: "collection",
  namespaced: true,
  state: {
    contents: [],
    loading: false
  },
  mutations: {
    startLoading(state) {
      if (state.contents) state.contents.splice(0, state.contents.length)
      state.loading = true
    },
    setCollections(state, contents) {
      state.contents = contents
    },
    setLoading(state, loadingState) {
      state.loading = loadingState
    }
  },
  actions: {
    async getAllCollections({ state, commit, dispatch }) {
      commit("startLoading")
      try {
        const collections = await PtApiClient.getContentByType("contentCollection")
        commit("setLoading", false)
        commit("setCollections", [...collections])
      } catch (e) {
        commit("setLoading", false)
        if (e.response && e.response.data && e.response.data.error) {
          dispatch("notifications/error", { message: "Error: " + e.response.data.error }, { root: true })
        } else {
          dispatch(
            "notifications/error",
            { message: "Error: Oops! Something happend when trying to fetch data" },
            { root: true }
          )
        }
      }
    }
  }
}
