import jwtDecode from 'jwt-decode';
import PtApi from "../main";

const utils = {
	getIdFromToken(token) {
		var decoded = jwtDecode(token);
		return decoded._id ? decoded._id : null;
	}
}

const WorkflowSettings = {
	default: {
		type: "direct_approval",
		roles: {
			author: "@me",
			approver: ""
		}
	}
}

const injectTokenToVisoFiles = function (response) {
	if (response.data && response.data && response.data.header && response.data.header.metadata && response.data.header.metadata.content_revision_type == "diagram"
		&& response.data.body && response.data.body.attachments) {
		response.data.body.attachments.forEach(attachment => {
			if (attachment.attachment_type && (attachment.attachment_type == ".vsdx" || attachment.attachment_type == ".svg") && attachment.url) {
				attachment.url = attachment.url + "?token=" + PtApi.token
			}
		});
	}
	return response
}

/**
 * Inject token to unauthorized files for Presentation mode
 * @param {*} response 
 * @returns 
 */
const injectTokenTo401Files = async function (object) {
	for (let [index, prop] of Object.entries(object)) {
		if (prop && typeof prop == "object") {
			await injectTokenTo401Files(prop)
		} else if (index === "url") {
			try {
				const response = await fetch(prop)
				if (!response.ok && response.status === 401) {
					object[index] = `${prop}?token=${PtApi.token}`
				}
			} catch (e) {
				// do nothing: external link
			}
		}
	}
	return object
}

export { utils, WorkflowSettings, injectTokenToVisoFiles, injectTokenTo401Files };