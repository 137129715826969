<template>
  <v-dialog v-model="value" max-width="900px" scrollable>
    <type-selector @select="selectType" :content_types="content_types" />
  </v-dialog>
</template>

<script>
import TypeSelector from "./TypeSelector.vue";

export default {
  props: ["content_types"],
  components: { TypeSelector },
  data: () => ({
    value: true,
    search: null
  }),
  methods: {
    selectType(extension) {
      this.$emit("input", extension);
    }
  }
};
</script>

<style>
</style>