<template>
  <div class="lp-video-panel">
    <!-- Edit mode -->
    <div v-if="lessonPlanController.mode == 'edit'">
      <!-- See video -->
      <template v-if="lessonPlanController.selectedSlide.notes.video">
        <div class="lp-video-panel__video">
          <video ref="notes-video" class="h-100 w-100 video-js">
            <template v-for="(track, key) in videoSubtitles">
              <track
                v-if="track"
                :key="key"
                :label="subtitlesLang[key]"
                kind="subtitles"
                :srclang="key"
                :src="track.url" />
            </template>
          </video>

          <v-btn color="error" class="mt-4" @click="deleteVideo()">
            <v-icon>mdi-trash-can-outline</v-icon>Delete video
          </v-btn>
        </div>

        <div class="lp-video-panel__subtitles">
          <v-toolbar flat class="elevation-0 transparent subtitles-title">
            <v-toolbar-title class="subheading">Subtitles</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn flat fab small v-on="on" class="lp-outline-panel-actions-button">
                  <v-icon>add</v-icon>
                </v-btn>
              </template>
              <v-list>
                  <v-list-tile v-for="(lang, key) in subtitlesLang" @click="addSubtitle(key)" :key="lang + key">
                    <v-list-tile-title class="body-1">
                      <v-layout row align-center>
                        <v-icon class="mr-2">mdi-subtitles-outline</v-icon>
                        <span>{{ lang }}</span>
                      </v-layout>
                    </v-list-tile-title>
                  </v-list-tile>
              </v-list>
            </v-menu>
          </v-toolbar>

          <v-list v-if="videoSubtitles && subtitlesSet" class="subtitles-list">
            <template v-for="(track, key) in videoSubtitles">
              <v-list-tile v-if="track" :key="`${track.file}-${key}`">
                <v-list-tile-title>{{ subtitlesLang[key] }}</v-list-tile-title>
                <v-list-tile-action>
                  <v-btn class="subtitles-list__delete-button" flat icon @click="deleteSubtitle(key)">
                    <v-icon color="red">mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </template>
          </v-list>

          <p v-else>
            <i> No subtitles detected. </i>
          </p>
        </div>
      </template>
      <!-- Record video -->
      <div v-else>
        <div class="text-center">
          <span class="d-inline-block pa-2">Record Video</span>
        </div>

        <video-recorder :width="320" :height="240" :video="true" @finishRecord="saveVideo" />

        <div class="text-center">
          <span class="d-inline-block pa-2">or</span>
        </div>
        <div class="text-center ma-2">
          <v-btn class="button_drag_drop" color="info" @click="uploadFile">Upload Video</v-btn>
        </div>
      </div>
    </div>

    <!-- View mode -->
    <div v-else>
      <video
        v-if="lessonPlanController.selectedSlide.notes.video"
        controls
        width="100%"
        :src="lessonPlanController.selectedSlide.notes.video.url">
        <template v-for="(track, key) in videoSubtitles">
          <track v-if="track" :key="key" :label="subtitlesLang[key]" kind="subtitles" :srclang="key" :src="track.url" />
        </template>
      </video>
    </div>
  </div>
</template>
<script>
import VideoRecorder from "./VideoRecorder.vue"
import { PFile } from "plant-common/src/utils/models"
import { uploadFile } from "plant-common/src/utils/index.js"
import { availableLanguages } from "./availableLanguages.js"
import { PtModal } from "plant-common"
import videojs from 'video.js';
require('video.js/dist/video-js.css');

export default {
  name: "VideoPanel",
  inject: ["lessonPlanController"],
  components: { VideoRecorder },
  props: ["type"],
  mixins: [PtModal],
  data() {
    return {
      subtitlesLang: availableLanguages,
      player: null
    }
  },
  created() {
    this.initSubtitles()
  },
  mounted() {
		this.mountVideoJS()
	},
  computed: {
    videoSubtitles() {
      return this.lessonPlanController.selectedSlide.notes.subtitles
    },
    subtitlesSet() {
      let setUp = false
      Object.entries(this.videoSubtitles).forEach(value => {
        if (value[1]) {
          setUp = true
          return
        }
      })
      return setUp
    },
    options() {
			return {
				controls: true,
				sources: [
					{
						src: this.lessonPlanController.selectedSlide.notes.video ? this.lessonPlanController.selectedSlide.notes.video.url : null
					}
				]
			};
		}
  },
  methods: {
    async addSubtitle(lang) {
      const { file } = await uploadFile(".vtt")

      let newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.lessonPlanController.selectedSlide.notes.subtitles, lang, {})
      this.lessonPlanController.selectedSlide.notes.subtitles[lang] = newFile
    },
    deleteVideo() {
      this.lessonPlanController.selectedSlide.notes.video = null
      this.lessonPlanController.selectedSlide.notes.subtitles = {}
    },
    deleteSubtitle(lang) {
      this.lessonPlanController.selectedSlide.notes.subtitles[lang] = null
    },
    initSubtitles() {
      if (!this.lessonPlanController.selectedSlide.notes.hasOwnProperty("subtitles"))
        this.$set(this.lessonPlanController.selectedSlide.notes, "subtitles", {})
    },
    async uploadFile() {
      const { file } = await uploadFile("video/*")

      if (!file.type.includes("video")) {
        await this.$modal("info", {
          title: "Wrong file type",
          message: "Only video files supported",
          ok: { color: "success" }
        })
        return
      }

      let newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.lessonPlanController.selectedSlide.notes, "video", newFile)
    },
    async saveVideo(blob) {
      const file = new File([blob], `record-${blob.lastModifiedDate}.webm`)
      var newFile = new PFile(file)
      await newFile.upload()
      this.$set(this.lessonPlanController.selectedSlide.notes, "video", newFile)
    },
    mountVideoJS() {
      this.$nextTick(() => {
        const ref = this.$refs[`notes-video`];
        if (ref) {
          const context = this;
          this.player = videojs(ref, this.options, function onPlayerReady() {
            this.on('click', function (evt) {
              evt.stopPropagation();
            });
            if (context.hasAutoplay) {
              setTimeout(() => {
                context.player.play();
              }, context.delay * 1000);
            }
          });
        }
      });
    }
  },
  watch: {
    "lessonPlanController.selectedSlide.notes.video.url": {
      handler() {
        this.mountVideoJS()
      }
    }
  }
}
</script>

<style>
.lp-video-panel {
  height: 640px;
}
.lp-video-panel > div {
  margin: 22px 20px 0;
  height: 100%;
}
.lp-video-panel__video {
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.lp-video-panel__subtitles {
  height: 40%;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
}
.lp-video-panel-text {
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  margin-bottom: 20px;
  align-self: flex-start;
}
.subtitles-title .v-toolbar__content {
  padding: 0;
}
.subtitles-list {
  width: 100%;
  overflow: auto;
}
.subtitles-list .v-list__tile {
  padding: 0;
}
.subtitles-list .v-list__tile .v-list__tile__action {
  justify-content: flex-end;
}
.subtitles-list .subtitles-list__delete-button.v-btn {
  margin-right: 0;
}
</style>
