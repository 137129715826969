import Vue from "vue"
import { uploadFile } from "../../../utils"
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = "" + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  const itemRevision = vm.value
  return new Vue({
    data() {
      return {}
    },
    computed: {
      file() {
        if (vm.value && vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0]
        } else {
          return false
        }
      },
      contents() {
        if (vm.value && vm.value.body.contents) {
          return vm.value.body.contents
        } else {
          return false
        }
      }
    },
    methods: {
      removeModel() {
        itemRevision.body.attachments.splice(0)
      },
      removeConfiguration() {
        itemRevision.body.contents = {}
      },
      uploadModel(file) {
        if (file) {
          let url = URL.createObjectURL(file)
          itemRevision.body.attachments.push(new Attachment("glb", file, url, ".glb"))
        } else {
          uploadFile({ accept: "document/pdf" }).then(({ url, file }) => {
            itemRevision.body.attachments.push(new Attachment("glb", file, url, ".glb"))
          })
        }
      },
      uploadConfiguration(file) {
        if (file) {
          let reader = new FileReader()
          reader.onload = event => {
            itemRevision.body.contents = this.savreeHtmlToObject(event.target.result)
          }
          reader.readAsText(file)
        } else {
          uploadFile({ accept: "glb" }).then(({ file }) => {
            var reader = new FileReader()
            reader.onload = event => {
              itemRevision.body.contents = this.savreeHtmlToObject(event.target.result)
            }
            reader.readAsText(file)
          })
        }
      },
      savreeHtmlToObject(htmlString) {
        const parser = new DOMParser()
        const doc = parser.parseFromString(htmlString, "text/html")
        const model_info_elm = doc.getElementById("model_info")
        const model_info_sao_elm = doc.getElementById("model_info_sao")
        const model_info_annotations_list_elm = doc.getElementById("model_info_annotations_list")

        function getElementAttrs(el) {
          return [].slice.call(el.attributes).reduce((accumulator, attr) => {
            if (!["id", "style", "doclink", "courselink", "uid"].includes(attr.name))
              accumulator[attr.name] = attr.value
            return accumulator
          }, {})
        }

        return {
          options: getElementAttrs(model_info_elm),
          sao: getElementAttrs(model_info_sao_elm),
          annotations: [...model_info_annotations_list_elm.children].map(e => getElementAttrs(e))
        }
      }
    }
  })
}
