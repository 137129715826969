<template>
  <!-- Component will be loaded inside v-list component -->
  <div>
    <v-subheader>{{ title }}:</v-subheader>
    <v-list-tile>
      <v-text-field
        prepend-icon="mdi-magnify"
        v-model="data.find"
        label="Find"
        hint="Original value you want to update"
      ></v-text-field>
    </v-list-tile>
    <v-list-tile>
      <v-text-field
        prepend-icon="mdi-repeat"
        v-model="data.replace"
        label="Replace By"
        hint="Value you want to replace it with"
      ></v-text-field>
    </v-list-tile>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data() {
    return {
      data: {
        find: "",
        replace: ""
      }
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit("update:data", this.data);
      }
    }
  }
};
</script>

<style>
</style>