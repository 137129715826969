<template>
  <div>
    <v-toolbar flat class="transparent">
      <v-toolbar-title> Settings </v-toolbar-title>
      <v-spacer></v-spacer>
      <div></div>
    </v-toolbar>

    <v-divider></v-divider>

    <style-sheet-settings></style-sheet-settings>
  </div>
</template>

<script>
import StyleSheetSettings from "./StyleSheetSettings.vue"

export default {
  name: "InteractiveBookSettings",
  components: { StyleSheetSettings },
  methods: {}
}
</script>

<style></style>
