<template>
  <v-dialog :value="true" max-width="fit-content" persistent>
    <v-layout row wrap id="guide-copyItem-modal">
      <v-flex d-inline-block id="guide-copyItem-modal-image"></v-flex>

      <v-flex d-inline-block>
        <v-card class="pa-2" id="copyItem-card">
          <v-card-title primary-title>
            <div>
              <div id="copyItemTitle" class="headline">
                <span v-if="section">Create content from section</span>
                <span v-else>Copy content</span>
              </div>
              <span class="grey--text">Fill data for the new content</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('input')">
              <v-icon>close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text id="copy">
            <v-text-field e2e-id="copy_title" autofocus v-model="title" label="Title *"></v-text-field>
            <v-text-field
              e2e-id="copy_custom_id"
              v-model="custom_id"
              label="Custom Id *"
              :error-messages="errorMessagesCustomId"></v-text-field>
            <v-text-field
              e2e-id="copy_path"
              v-model="path"
              label="Path"
              append-icon="mdi-folder"
              readonly
              @click:append="selectPath"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              e2e-id="create_copy"
              id="copyItem-btn"
              :style="!custom_id || !title ? '' : 'background: #4d85d8;'"
              block
              :disabled="!custom_id || !title"
              @click="createCopy()"
              >{{ buttonText }}</v-btn
            >
          </v-card-actions>

          <v-card-title v-if="info_message">
            {{ info_message }}
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-dialog>
</template>

<script>
import { PtModal } from "plant-common"
import PathToDialog from "../../../PathToDialog.vue"
import { mapActions } from "vuex"
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_WARNING } from "../../../../store_constants/notifications"
import PtApiClient from "plant-api-client"

export default {
  name: "CopyItemDialog",
  props: {
    content_path: String,
    section: {
      type: Object,
      default: null
    },
    info_message: {
      type: String,
      default: null
    }
  },
  mixins: [PtModal],
  data() {
    return {
      custom_id: undefined,
      title: undefined,
      path: undefined,
      path_id: undefined,
      section_id: null,
      errorMessagesCustomId: ""
    }
  },
  created() {
    if (this.content_path) {
      this.path = this.content_path.name
      this.path_id = this.content_path._id
    }

    if (this.section) {
      this.section_id = this.section.id
      this.title = this.section.title
    }
  },
  computed: {
    buttonText() {
      let text = "Create content from current"

      if (this.section) text = "Create content from section"

      return text
    }
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      warning: ACTION_NOTIFICATIONS_WARNING
    }),
    selectPath() {
      this.$modal(PathToDialog, {}).then(destination_folder => {
        if (!destination_folder) return
        this.path = destination_folder.name
        this.path_id = destination_folder._id

        if (destination_folder == "$root") {
          this.path_id = undefined
          this.path = undefined
        }
      })
    },
    async createCopy() {
      try {
        await PtApiClient.getContentByCustomId(this.custom_id, true)
        this.errorMessagesCustomId = "The custom id must be unique"
      } catch (e) {
        this.errorMessagesCustomId = ""
        this.$emit("input", {
          title: this.title,
          path_id: this.path_id,
          custom_id: this.custom_id,
          section: this.section
        })
      }
    }
  }
}
</script>

<style>
#guide-copyItem-modal {
  width: 658px;
  left: 631px;
  top: 328px;

  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

#guide-copyItem-modal-image {
  width: 129px;
  height: inherit;

  background: url("../../../../../public/img/modal/newItemModalImage.svg");
  border-radius: 6px 0px 0px 6px;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

#copyItem-card {
  width: 529px;
  height: fit-content;
  box-shadow: none;
}

#copyItem-btn {
  max-width: 250px;
  height: 45px;
  border-radius: 6px;
  margin-top: -2%;
  text-transform: none;
}

#copyItem-btn * {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}

#copyItemTitle {
  color: #585858;
  font-size: 20px !important;
}

.v-input .v-label {
  font-size: 14px;
  color: #929292 !important;
}

.v-text-field input {
  font-size: 14px;
  color: #777777 !important;
}
</style>
