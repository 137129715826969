<template>
  <v-dialog v-model="value" persistent fullscreen scrollable>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <img :src="tool.icon" class="tool-image" />Custom ID Change
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon flat @click.stop="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <div
        v-if="state !== 'waiting-for-files'"
        style="
          position: absolute;
          top: 65px;
          bottom: 0px;
          height: calc(100% - 65px);
          width: 350px;
          border-right: rgba(0, 0, 0, 0.12) solid thin;
        "
      >
        <div v-if="state == 'user-input'">
          <v-alert :value="true" type="info">
            This action will change the global custom ID in the following list.
          </v-alert>
          <v-alert :value="true" type="info">
            This action will not create a draft for the selected contents, it
            will automatically changes its custom ID for all approved revisions.
          </v-alert>
          <v-divider class="custom-id-divider"></v-divider>
          <v-alert :value="true" type="warning">
            Caution! Be careful and check you agree with this changes
          </v-alert>
        </div>
        <div
          style="position: absolute; bottom: 0px; padding: 10px; width: 100%"
        >
          <v-divider></v-divider>
          <v-btn
            color="success"
            block
            outline
            @click.stop="startCustomIdChange"
            v-if="state == 'user-input'"
          >
            <v-icon left>mdi-play</v-icon>Start Upload
          </v-btn>
          <v-btn
            color="red"
            block
            outline
            @click.stop="state = 'cancelled'"
            v-if="state == 'uploading'"
          >
            <v-icon left>mdi-stop</v-icon>Cancel Upload
          </v-btn>
          <v-btn
            color="primary"
            block
            outline
            @click.stop="close()"
            v-if="state == 'completed'"
          >
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
        </div>
        <bulk-upload-progress
          v-if="state == 'completed' || state == 'uploading'"
          :items="updateContents"
        />
      </div>
      <bulk-change-custom-id-table
        v-if="state !== 'waiting-for-files'"
        :contents="updateContents"
        :disabled="state !== 'user-input'"
      />
      <template v-if="state == 'waiting-for-files'">
        <bulk-change-custom-id-upload-xls @upload-finished="manageFiles" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import BulkChangeCustomIdUploadXls from "./components/UploadXls.vue";
import BulkChangeCustomIdTable from "./components/Table.vue";
import BulkUploadProgress from "@/pages/explorer/ExploreLibrary/components/BulkUpload/Progress.vue";
import PtApiClient from "plant-api-client";

export default {
  props: {
    tool: Object
  },
  components: {
    BulkChangeCustomIdUploadXls,
    BulkChangeCustomIdTable,
    BulkUploadProgress
  },
  data: () => ({
    value: true,
    state: "waiting-for-files", // 'user-input', 'uploading', 'completed', 'select-type'
    updateContents: []
  }),
  computed: {},
  methods: {
    close() {
      this.value = false;
    },
    manageFiles(contents) {
      this.updateContents = contents;
      this.state = "user-input";
    },
    async startCustomIdChange() {
      this.state = "uploading";
      for (var content of this.updateContents) {
        content.status = "in-progress";
        if (this.state == "cancelled") {
          content.status = "error";
        } else {
          try {
            // Call to ptApiClient.forceUpdateCustomId(old, new)
            // TODO: develop pt api client method and open plant-server-content endpoint
            content.uploaded = await PtApiClient.forceUpdateCustomId(
              content.old_custom_id,
              content.new_custom_id
            );
            content.status = "done";
          } catch (error) {
            console.error(error);
            content.status = "error";
            content.error = error.response.data.message;
          }
        }
      }
      this.state = "completed";
    }
  }
};
</script>

<style scoped>
.tool-image {
  max-height: 40px;
}

.custom-id-divider {
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>