<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-triangle-control-handler-x"
        :x="value.data.controls.slope * w"
        :x-range="[0, w]"
        :y="0"
        :flipX="value.flip.x"
        :flipY="value.flip.y"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.slope = x / w)" />
      <svg
        class="shape-triangle"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <polygon :id="`path-${id}`" :points="`${s},0 ${w},${h} 0,${h}`" :style="value.data.style" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"

export default {
  name: "BlockShapeTriangle",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  data() {
    return {
      state: "not-selected"
    }
  },
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    s() {
      return this.value.size.width * this.value.data.controls.slope
    }
  }
}
</script>