import ptApiClient from "plant-api-client"

var getContentIdFromCustomId = async custom_id => {
  if (!custom_id) return false
  return getContentId(await ptApiClient.getContentByCustomId(custom_id))
}
export { getContentIdFromCustomId }

export function getContentId(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.content_id
    ? itemRevision.header.metadata.content_id
    : itemRevision.item && itemRevision.item.item_id
    ? itemRevision.item.item_id
    : encodeURIComponent(getCustomId(itemRevision))
}

export function getCustomId(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.custom_id
    ? itemRevision.header.metadata.custom_id
    : itemRevision.item && itemRevision.item.custom_id
    ? itemRevision.item.custom_id
    : ""
}

export function getTitle(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.title
    ? itemRevision.header.metadata.title
    : itemRevision.item && itemRevision.item.title
    ? itemRevision.item.title
    : ""
}

export function getDescription(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.description
    ? itemRevision.header.metadata.description
    : itemRevision && itemRevision.item && itemRevision.item.description
    ? itemRevision.item.description
    : ""
}

export function getCustomRevision(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.custom_revision
    ? itemRevision.header.metadata.custom_revision
    : ""
}

export function getType(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.content_revision_type
    ? itemRevision.header.metadata.content_revision_type
    : itemRevision.header && itemRevision.header.item_revision_type
    ? itemRevision.header.item_revision_type
    : "no type"
}

export function getFolderName(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.folder && itemRevision.folder.name ? itemRevision.folder.name : "root"
}
export function getFolder(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.folder ? itemRevision.folder : undefined
}

export function getMajorVersion(itemRevision) {
  return itemRevision && itemRevision.header && itemRevision.header.major_revision
    ? itemRevision.header.major_revision
    : "0"
}

export function getMinorVersion(itemRevision) {
  return itemRevision && itemRevision.header && itemRevision.header.minor_revision
    ? itemRevision.header.minor_revision
    : "0"
}

// Clean object (avoid send blank values to backend)
export function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export function getObjective(itemRevision) {
  if (!itemRevision) return false
  return itemRevision.body &&
    itemRevision.body.contents &&
    itemRevision.body.contents.instructionalSettings &&
    itemRevision.body.contents.instructionalSettings.objective
    ? itemRevision.body.contents.instructionalSettings.objective
    : false
}

export function getPath(itemRevision, allLibraryFolders) {
  let path = []
  if (itemRevision && !itemRevision.folder) {
    path.push("root")
  } else {
    path.unshift(itemRevision.folder.name)
    let parentId = itemRevision.folder.parent_id
    while (parentId) {
      const parent = allLibraryFolders[parentId]
      path.unshift(parent.name)
      parentId = parent.parent_id
    }
  }
  path = path.join("/")
  return path || ""
}

export function getLibraryId(itemRevision) {
  if (itemRevision.hasOwnProperty("$origin") && itemRevision.$origin.startsWith("plant://")) {
    return itemRevision.$origin.split("/")[2]
  }

  return itemRevision.header.library_id || ""
}

export function getLibraryIdFromURL(URL) {
  const selectedLibraryURLArray = URL.split("/")
  const selectedLibraryId = selectedLibraryURLArray[selectedLibraryURLArray.length - 1]
  return selectedLibraryId
}

export function setClientLibraryId(client, libraryId) {
  const newBaseURLArray = client.baseURL.split("/")
  newBaseURLArray[newBaseURLArray.length - 1] = libraryId
  client.baseURL = newBaseURLArray.join("/")
  console.log("setClientLibraryId ", client.baseURL)
}
