<template>
  <v-stepper-content step="2">
    <div class="step-container">
      <h2 class="headline text-center mb-4 mb-4">Choose new layouts</h2>
      <p class="subheading text-center">
        For each card template in your guide, select the slide layout you want to use in the interactive Presentation.
      </p>
      <div>
        <table class="g-step-2-table">
          <thead>
            <tr>
              <th>Card Template</th>
              <th>Interactive Presentation Layout</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(template, index) in templateToLayouts" :key="index">
              <td>{{ template.template }}</td>
              <td>
                <v-select :items="layouts" :return-object="true" v-model="template.layout" item-text="name"></v-select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <v-btn flat @click="goToStep1"> Back </v-btn>
      <v-btn color="#48C634" dark @click="goToStep3" v-if="coverAndSectionSet"> Finish </v-btn>
      <v-tooltip bottom v-else>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">Finish</v-btn>
        </template>
        <span>Please, select a cover and section template</span>
      </v-tooltip>
    </div>
  </v-stepper-content>
</template>

<script>
export default {
  props: ["step", "layouts", "templateToLayouts"],
  computed: {
    coverAndSectionSet() {
      return (
        this.templateToLayouts &&
        this.templateToLayouts[0] &&
        this.templateToLayouts[0].layout &&
        this.templateToLayouts[1] &&
        this.templateToLayouts[1].layout
      )
    }
  },
  methods: {
    goToStep3() {
      this.$emit("setStep", 3)
    },
    goToStep1() {
      this.$emit("setStep", 1)
    }
  }
}
</script>

<style>
.g-step-2-table {
  border-collapse: collapse;
  margin-bottom: 20px;
}

.g-step-2-table td,
.g-step-2-table th {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: left;
}
</style>
