import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "pointCloud",
    title: "Point Cloud (LAS)",
    icon: require("./content-icon.svg"),
    category: "engineering",
    Viewer,
    Editor,
    Preview: Viewer
}