<template>
  <v-container>
    <h3>Speaker Notes</h3>
    <p-rich-text :value="selectedCard.notes || 'No notes'" :canEdit="false" placeholder="Speaker notes" />
  </v-container>
</template>

<script>
import { mapInjectedData } from "plant-common/src/utils"
import { PRichText } from "plant-common"
export default {
  name: "GuideNotes",
  inject: ["guideController"],
  components: { PRichText },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"])
  }
}
</script>
