<template>
  <span
    >A new draft has been created
    <b>{{ custom_id }}</b>
  </span>
</template>

<script>
export default {
  props: ["value"],
  metadata: {
    color: "blue",
    icon: "mdi-book-plus",
    subscription_code: "new-content"
  },
  computed: {
    custom_id() {
      return this.value.event.payload && this.value.event.payload
        ? `${this.value.event.payload.custom_id} (${this.value.event.payload.content_revision_type})`
        : "";
    }
  }
};
</script>
