<template>
  <p-panel title="Slide Macros">
    <template #toolbar>
      <v-toolbar-title class="subheading">Slide Macros</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn color="success" flat small @click="newMacro()"> <v-icon small left>mdi-plus</v-icon>New Macro</v-btn>
    </template>
    <v-alert class="ma-3" :value="true" color="info" icon="info" outline dismissible>
      This is an experimental feature and subject to change. Your macros are saved in your browser and will be lost if
      you change browsers or clear browser data. See
      <a target="_blank" href="https://docs.myplanttraining.com/books/interactive-presentations/chapter/macro-examples"
        >Examples</a
      >
    </v-alert>
    <v-list dense two-line>
      <v-list-tile v-for="(macro, index) in userMacros" avatar :key="index">
        <v-list-tile-avatar>
          <v-icon>mdi-robot</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-title>{{ macro.name }}</v-list-tile-title>
          <v-list-tile-sub-title>{{ macro.description }}</v-list-tile-sub-title>
        </v-list-tile-content>
        <v-list-tile-action>
          <v-btn icon flat small color="green" @click="runUserMacro(macro.code)">
            <v-icon small>mdi-play</v-icon>
          </v-btn>
        </v-list-tile-action>
        <v-list-tile-action>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn icon flat small v-on="on">
                <v-icon small>mdi-dots-vertical</v-icon>
              </v-btn></template
            >
            <v-list dense>
              <v-list-tile @click="editMacro = macro">
                <v-list-tile-title @click="editMacro = macro">
                  <v-icon small left>mdi-pencil</v-icon>Edit</v-list-tile-title
                >
              </v-list-tile>
              <v-list-tile @click="duplicateMacro(index)">
                <v-list-tile-title> <v-icon small left>mdi-content-duplicate</v-icon>Duplicate</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="deleteMacro(index)">
                <v-list-tile-title>
                  <v-icon small left color="danger">mdi-delete-outline</v-icon>Delete</v-list-tile-title
                >
              </v-list-tile>
            </v-list>
          </v-menu>
        </v-list-tile-action>
      </v-list-tile>
    </v-list>
    <slide-macros-panel-edit-macro v-if="editMacro" @close="editMacro = null" :value="editMacro" />
  </p-panel>
</template>

<script>
import PPanel from "../utils/PPanel.vue"
import SlideMacrosPanelEditMacro from "./SlideMacrosPanelEditMacro.vue"
import { PFile } from "plant-common/src/utils/models"
import { createImageBlockFromUrl } from "../../image/utils.js"
export default {
  inject: ["blockController", "lessonPlanController", "blockDefinitions"],
  components: {
    PPanel,
    SlideMacrosPanelEditMacro
  },
  data() {
    return {
      userMacros: null,
      editMacro: null
    }
  },
  created() {
    if (localStorage.PlantUserMacros) {
      this.userMacros = JSON.parse(localStorage.PlantUserMacros)
    } else {
      this.userMacros = []
    }
  },
  methods: {
    newMacro() {
      this.userMacros.push({
        name: "New User Macro",
        description: "New Macro",
        code: "alert('Hello PLANT!')"
      })
      this.editMacro = this.userMacros(this.userMacros.length - 1)
    },
    deleteMacro(index) {
      this.userMacros.splice(index, 1)
    },
    duplicateMacro(index) {
      let newMacro = JSON.parse(JSON.stringify(this.userMacros[index]))
      newMacro.name = newMacro.name + " (copy)"
      this.userMacros.push(newMacro)
    },
    async runUserMacro(code) {
      var macro = new Function("SlideApi", `(async function(SlideApi){${code}})(SlideApi)`)
      await macro(this.getSlideApi())
    },
    getSlideApi() {
      return {
        selectedBlock: this.blockController.getBlockByState("primary-selected"),
        blockDefinitions: this.blockDefinitions,
        lessonPlanController: this.lessonPlanController,
        utils: {
          createImageBlockFromUrl,
          PFile
        }
      }
    }
  },
  watch: {
    userMacros: {
      deep: true,
      handler() {
        localStorage.PlantUserMacros = JSON.stringify(this.userMacros)
      }
    }
  }
}
</script>
