<template>
  <div>
    <v-toolbar flat class="transparent">
      <slot name="toolbar">
        <v-toolbar-title class="subheading">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items v-if="icon">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon :color="color ? color : 'primary'" v-on="on">{{ icon }}</v-icon>
            </template>
            <span>{{ description }}</span>
          </v-tooltip>
        </v-toolbar-items>
      </slot>
    </v-toolbar>
    <div style="position: absolute; left: 0px; top: 64px; right: 0px; bottom: 0px; overflow-y: auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    icon: {
      type: String
    },
    description: {
      type: String
    },
    color: {
      type: String
    }
  }
}
</script>

<style></style>
