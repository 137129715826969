<template>
  <div class="drawer-attachments">
    <div v-if="canEdit" class="text-center ma-2">
      <v-btn class="button_drag_drop" color="info" @click="uploadFile">Upload File</v-btn>
    </div>

    <div class="drawer-attachments__files-list text-center">
      <v-list three-line dense v-if="fileAttachments.length > 0">
        <v-list-tile v-for="file in fileAttachments" :key="file.name">
          <p-file-icon class="mr-2" :fileName="file.name"></p-file-icon>

          <v-list-tile-content>
            <v-list-tile-sub-title>{{ file.name }}</v-list-tile-sub-title>
          </v-list-tile-content>

          <v-list-tile-action>
            <div>
              <v-btn class="mr-2" icon flat>
                <a :href="file.file.url" :download="file.name">
                  <v-icon>mdi-download-box-outline</v-icon>
                </a>
              </v-btn>

              <v-btn icon flat @click="deleteExternalAttachment(file)">
                <v-icon color="red">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
      <span v-else class="ma-3 no-ext-attch"> No external attachments </span>
    </div>
  </div>
</template>

<script>
import { uploadFile } from "plant-common/src/utils/index.js"
import { PFile } from "plant-common/src/utils/models"
import { PtModal, PFileIcon } from "plant-common"
import applicationMime from "./resources/MimeManagament/application"
import textMime from "./resources/MimeManagament/text"
import dangerousExtensions from "./resources/MimeManagament/dangerousExtensions"

import { mapState } from "vuex"
import { STORE_CONTENT_NAME, STATE_ITEM_REVISION, STATE_CAN_EDIT } from "../../../store_constants/content"

export default {
  name: "DrawerAttachments",
  mixins: [PtModal],
  components: { PFileIcon },
  computed: {
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION,
      canEdit: STATE_CAN_EDIT
    }),
    acceptedMimeTypes() {
      let acceptedMimeTypes = [
        "audio/*",
        "image/*",
        "video/*",
        "model/*",
        "application/x-zip-compressed",
        ...applicationMime,
        ...textMime
      ]
      return acceptedMimeTypes.join(",")
    },
    fileAttachments() {
      if (!this.itemRevision) return []
      return this.itemRevision.body.contents.externalAttachments
    }
  },
  created() {
    this.initExternalAttachments()
  },
  methods: {
    initExternalAttachments() {
      if (this.itemRevision && !this.itemRevision.body.contents.externalAttachments) {
        this.$set(this.itemRevision.body.contents, "externalAttachments", [])
      }
    },

    validFileType(file) {
      const fileNameParts = file.name.split(".")
      const fileExt = fileNameParts[fileNameParts.length - 1]

      return (
        dangerousExtensions.indexOf(fileExt) === -1 &&
        (file.type.includes("audio") ||
          file.type.includes("video") ||
          file.type.includes("image") ||
          file.type.includes("model") ||
          file.type === "application/x-zip-compressed" ||
          this.acceptedMimeTypes.includes(file.type))
      )
    },

    async uploadFile() {
      const { url, file } = await uploadFile(this.acceptedMimeTypes)

      if (!this.validFileType(file)) {
        await this.$modal("info", {
          title: "Wrong file type",
          message: `The file type ${file.type} is not supported.`,
          ok: { color: "success" }
        })
        return
      }

      let newFile = new PFile(file)
      await newFile.upload()

      this.addFileToExternalAttachments(newFile)
    },

    addFileToExternalAttachments(file) {
      const newFile = {
        name: file.name,
        file
      }
      if (this.fileAttachments.findIndex(f => f.name === file.name) === -1) this.fileAttachments.push(newFile)
    },

    deleteExternalAttachment(file) {
      this.$modal("confirm", {
        title: `Are you sure you want to delete this item?`,
        accept: {
          text: "Delete",
          color: "red",
          icon: "mdi-delete"
        },
        cancel: {
          text: "Cancel",
          color: "default"
        }
      }).then(r => {
        if (r)
          this.itemRevision.body.contents.externalAttachments = this.fileAttachments.filter(f => f.name !== file.name)
      })
    }
  }
}
</script>

<style>
.drawer-attachments__files-list .v-list__tile__action {
  min-width: 65px;
}

.drawer-attachments__files-list .v-list__tile__action .v-btn a {
  height: 22px;
}
.drawer-attachments__files-list .no-ext-attch {
  font-style: italic;
}
</style>
