<template>
  <p-content-container>
   <Editor :value="value" :viewOnly="true"/>
  </p-content-container>
</template>

<script>
import { PContentContainer } from "plant-common";
import Editor from "../Editor"
export default {
  name: "GuideTemplateViewer",
  props: ["value"],
  components: {
    PContentContainer,
    Editor
  }
};
</script>
