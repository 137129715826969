<template>
  <div style="position: relative">
    <button-view :value="value" :viewStyles="viewStyles" :state="state" :has-actions="hasActions" ref="mybutton" />
  </div>
</template>

<script>
import ButtonView from "./BlockViewButton.vue"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  props: ["value", "state"],
  inject: ["blockEventBus", "blockController", "lessonPlanController"],
  components: { ButtonView, PServiceContent },
  data() {
    return {
      viewStyles: {}
    }
  },
  computed: {
    tooltipStyle() {
      return {
        position: "absolute",
        bottom: "120%",
        left: "0",
        right: "0",
        background: "#616161",
        borderRadius: "2px",
        color: "#fff",
        fontSize: "30px",
        display: "inline-block",
        padding: "5px 8px",
        width: "fit-content",
        maxWidth: "500px",
        zIndex: "8",
        boxShadow:
          "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)"
      }
    },
    actionClick() {
      var clickAction = this.value.actions.find(action => action.trigger === "click")
      return clickAction
    },
    actionHover() {
      var clickAction = this.value.actions.find(action => action.trigger === "hover")
      return clickAction
    },
    hasActions() {
      return this.actionClick !== undefined || this.actionHover !== undefined
    },
    editStyles() {
      const {
        backgroundColor,
        color,
        textScale,
        textAlign: justifyContent,
        fontFamily,
        boxShadowButton: boxShadow,
        borderRadius,
        borderWidth,
        borderStyle,
        borderColor
      } = this.value.data.style.regular

      // No change styles in hover in edit mode
      return {
        backgroundColor,
        color,
        boxShadow,
        borderRadius: `${borderRadius}px`,
        fontFamily: fontFamily || "inherit",
        borderWidth: `${borderWidth}px`,
        borderStyle,
        borderColor,
        // Styles in IconButton element
        "--text-scale": textScale,
        "--text-align": justifyContent == "center" ? "center" : "left",
        "--justify-content": justifyContent,
        width: `${this.value.size.width}px`,
        height: `${this.value.size.height}px`
      }
    }
  },
  created() {
    this.initDisabledStyle()
    this.initVisitedStyle()
  },
  methods: {
    initVisitedStyle() {
      // for lesson plans before the visited state
      if (!this.value.data.style.visited) {
        this.$set(this.value.data.style, "visited", {
          backgroundColor: "#bdbdbdff",
          color: "#000000ff",
          textScale: 100,
          textAlign: "center",
          justifyContent: "center",
          boxShadowButton: "none",
          borderRadius: 0,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#bdbdbdff",
          fontFamily: false
        })
      }
    },
    initDisabledStyle() {
      // for lesson plans before the disabled state
      if (!this.value.data.style.disabled) {
        this.$set(this.value.data.style, "disabled", {
          backgroundColor: "#efefef4d",
          color: "#1010104d",
          textScale: 100,
          textAlign: "center",
          justifyContent: "center",
          boxShadowButton: "none",
          borderRadius: 0,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: "#7676764d",
          fontFamily: false
        })
      }
    },
    calculateLinkAvailability() {
      if (this.actionClick && this.actionClick.type === "link") {
        const slideDestination = this.lessonPlanController.getSlideFromBookmark(this.actionClick.bookmark_target)
        if (slideDestination?.element) {
          const slideNav = this.lessonPlanController.slideNavigationMap.find(
            slideN => slideN.id === slideDestination.element.id
          )
          this.value.data.disabled = !slideNav.available
        }
      }
      if (!this.hasActions) {
        this.value.data.disabled = true
      }
    },
    calculateViewStyles() {
      let styles = JSON.parse(JSON.stringify(this.editStyles))

      const {
        backgroundColor: backgroundColorHover,
        color: colorHover,
        textScale: textScaleHover,
        textAlign: justifyContentHover,
        fontFamily: fontFamilyHover,
        boxShadowButton: boxShadowHover,
        borderRadius: borderRadiusHover,
        borderWidth: borderWidthHover,
        borderStyle: borderStyleHover,
        borderColor: borderColorHover
      } = this.value.data.style.hover
      
      const {
        backgroundColor: backgroundColorActive,
        color: colorActive,
        textScale: textScaleActive,
        textAlign: justifyContentActive,
        fontFamily: fontFamilyActive,
        boxShadowButton: boxShadowActive,
        borderRadius: borderRadiusActive,
        borderWidth: borderWidthActive,
        borderStyle: borderStyleActive,
        borderColor: borderColorActive
      } = this.value.data.style.active

      // Disabled states
      const isDisabled = this.value.data.disabled || !this.hasActions
      if (isDisabled) {
        styles.backgroundColor = this.value.data.style.disabled.backgroundColor
        styles.color = this.value.data.style.disabled.color
        styles.boxShadow = this.value.data.style.disabled.boxShadow
        styles.borderRadius = `${this.value.data.style.disabled.borderRadius}px`
        styles.borderWidth = `${this.value.data.style.disabled.borderWidth}px`
        styles.borderStyle = this.value.data.style.disabled.borderStyle
        styles.borderColor = this.value.data.style.disabled.borderColor
        styles.fontFamily = this.value.data.style.disabled.fontFamily || "inherit"
        // Styles in IconButton element
        styles["--text-scale"] = this.value.data.style.disabled.textScale
        styles["--text-align"] = this.value.data.style.disabled.textAlign == "center" ? "center" : "left"
        styles["--justify-content"] = this.value.data.style.disabled.textAlign
      }

      // Hover states
      styles["--background-color-hover"] = isDisabled
        ? this.value.data.style.disabled.backgroundColor
        : backgroundColorHover
      styles["--color-hover"] = isDisabled ? this.value.data.style.disabled.color : colorHover
      styles["--border-shadow-hover"] = isDisabled ? this.value.data.style.disabled.boxShadow : boxShadowHover
      styles["--border-radius-hover"] = isDisabled
        ? this.value.data.style.disabled.borderRadius
        : `${borderRadiusHover}px`
      styles["--border-width-hover"] = isDisabled ? this.value.data.style.disabled.borderWidth : `${borderWidthHover}px`
      styles["--border-style-hover"] = isDisabled ? this.value.data.style.disabled.borderStyle : borderStyleHover
      styles["--border-color-hover"] = isDisabled ? this.value.data.style.disabled.borderColor : borderColorHover
      styles["--font-family-hover"] = isDisabled
        ? this.value.data.style.disabled.fontFamily || "inherit"
        : fontFamilyHover || "inherit"
      styles["--text-scale-hover"] = isDisabled ? this.value.data.style.disabled.textScale : textScaleHover
      styles["--text-align-hover"] = isDisabled
        ? this.value.data.style.disabled.textAlign
        : justifyContentHover == "center"
        ? "center"
        : "left"
      styles["--justify-content-hover"] = isDisabled
        ? this.value.data.style.disabled.textAlign
        : justifyContentHover

      // Active states
      styles["--background-color-active"] = isDisabled
        ? this.value.data.style.disabled.backgroundColor
        : backgroundColorActive
      styles["--color-active"] = isDisabled ? this.value.data.style.disabled.color : colorActive
      styles["--text-scale-active"] = isDisabled ? this.value.data.style.disabled.textScale : textScaleActive
      styles["--border-shadow-active"] = isDisabled ? this.value.data.style.disabled.boxShadow : boxShadowActive
      styles["--border-radius-active"] = isDisabled
        ? this.value.data.style.disabled.borderRadius
        : `${borderRadiusActive}px`
      styles["--border-width-active"] = isDisabled
        ? this.value.data.style.disabled.borderWidth
        : `${borderWidthActive}px`
      styles["--border-style-active"] = isDisabled ? this.value.data.style.disabled.borderStyle : borderStyleActive
      styles["--border-color-active"] = isDisabled ? this.value.data.style.disabled.borderColor : borderColorActive
      styles["--font-family-active"] = isDisabled
        ? this.value.data.style.disabled.fontFamily || "inherit"
        : fontFamilyActive || "inherit"
      styles["--text-align-active"] = isDisabled
        ? this.value.data.style.disabled.textAlign
        : justifyContentActive == "center"
        ? "center"
        : "left"
      styles["--justify-content-active"] = isDisabled
        ? this.value.data.style.disabled.textAlign
        : justifyContentActive

      // Visited states
      if (this.value.data.visited) {
        styles.backgroundColor = this.value.data.style.visited.backgroundColor
        styles.color = this.value.data.style.visited.color
        styles.boxShadow = this.value.data.style.visited.boxShadow
        styles.borderRadius = `${this.value.data.style.visited.borderRadius}px`
        styles.borderWidth = `${this.value.data.style.visited.borderWidth}px`
        styles.borderStyle = this.value.data.style.visited.borderStyle
        styles.borderColor = this.value.data.style.visited.borderColor
        styles.fontFamily = this.value.data.style.visited.fontFamily || "inherit"
        // Styles in IconButton element
        styles["--text-scale"] = this.value.data.style.visited.textScale
        styles["--text-align"] = this.value.data.style.visited.textAlign == "center" ? "center" : "left"
        styles["--justify-content"] = this.value.data.style.visited.textAlign
      }

      this.viewStyles = styles
    }
  },
  watch: {
    "lessonPlanController.slideNavigationMap": {
      immediate: true,
      deep: true,
      handler() {
        this.calculateLinkAvailability()
        this.calculateViewStyles()
      }
    },
    "value.data.visited": {
      handler() {
        this.calculateViewStyles()
      }
    }
  }
}
</script>
