<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" outline block v-on="on"
        ><v-icon left>mdi-find-replace</v-icon>Quick Replace</v-btn
      >
    </template>

    <v-card>
      <v-card-title class="headline" primary-title>
        Quick Replace
      </v-card-title>

      <v-card-text>
        <v-radio-group v-model="field" row>
          <template v-slot:label>
            <div>Field:</div>
          </template>
          <v-radio label="Custom Id" value="custom_id"></v-radio>
          <v-radio label="Title" value="title"></v-radio>
        </v-radio-group>
        <v-text-field
        prepend-icon="mdi-magnify"
          v-model="find"
          label="Find"
          hint="Leave empty to replace the whole field."
        ></v-text-field>
        <v-text-field
        prepend-icon="mdi-repeat"
          v-model="replace"
          label="Replace By"
          hint="Use pound sign '#' for autonumbering "
        ></v-text-field>
        <v-text-field
        :disabled="!replace.includes('#')"
        prepend-icon="mdi-pound"
          v-model.number="start"
          type="number"
          label="Start with Number"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outline @click="$emit('input', {field, find, replace, start: parseInt(start)}) ; dialog = false">
          <v-icon left>mdi-find-replace</v-icon> Replace
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
      return {
          dialog: false,
          field: 'custom_id',
          find: '',
          replace: '',
          start: 0
      }
  },
};
</script>

<style>
</style>