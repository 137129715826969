export default {
  id: "datasheet-replace-properties-from-table",
  title: "Update Datasheet Properties from Table",
  description: "This operation replaces the values edited in the right table",
  contentType: "datasheet",
  chainable: true,
  operation(content, props) {
    return content
  },
  OperationOptionsComponent: { template: "<span></span>" }
}
