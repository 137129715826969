<template>
  <v-dialog v-model="loading" persistent width="500">
    <v-card color="primary" dark>
      <v-card-text class="text-center">
        {{ loadingMessage }}<span v-if="buffer != 0"> ({{ buffer }}%)</span>...
        <v-progress-linear
          v-model="buffer"
          :buffer-value="bufferValue"
          buffer
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["value"],
  data() {
    return {
      bufferValue: 100
    };
  },
  computed: {
    ...mapState(["loading", "loadingMessage", "buffer"])
  }
};
</script>

<style>
.text-center {
  text-align: center;
}
</style>
