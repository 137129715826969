import { PFile } from "plant-common/src/utils/models"
import { getLayout } from "../../utils/utils.js"
import { uploadFile, getAdaptedSize } from "../_utils/utils.js"
import ImageBlock from "./class.js"

export async function uploadImage() {
  const file = await uploadFile()
  let newFile = new PFile(file)
  await newFile.upload()
  this.value.data.image = newFile
}

export async function createImageBlockFromUrl(url, filename) {
  let imageBlock = new ImageBlock()
  const res = await fetch(url)
  let blob = await res.blob()
  let newFile = new PFile(new File([blob], filename))
  await newFile.upload()
  imageBlock.data.image = newFile
  const size = await getImageSize(imageBlock.data.image.url)
  imageBlock.size.width = size.width
  imageBlock.size.height = size.height
  return imageBlock
}

export function getImageSize(imgSrc) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      resolve({ width: this.width, height: this.height })
      img.remove()
    }
    img.onerror = reject
    img.src = imgSrc
  })
}

export async function getMaxImageSize(url, position) {
  const layout = getLayout()
  const size = await getImageSize(url)
  return getAdaptedSize(layout, position, size)
}
