<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-callout-control-handler-x"
        :x="r"
        :x-range="[0, Math.min(wLP, hLP)]"
        :y="0"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.radius = x / w)" />
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-callout-control-handler-extremde"
        :x="eX"
        :y="eY"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.extremeX = x / w)"
        @update:y="y => (value.data.controls.extremeY = y / h)" />
      <svg
        class="shape-callout"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        :width="Math.max(w, eX) - Math.min(0, eX)"
        :height="Math.max(h, eY) - Math.min(0, eY)"
        preserveAspectRatio="none"
        :viewBox="`
          ${Math.min(0, eX)} 
          ${Math.min(0, eY)} 
          ${Math.max(w, eX) - Math.min(0, eX)} 
          ${Math.max(h, eY) - Math.min(0, eY)}
        `"
        :style="`transform: translate(${Math.min(0, eX)}px, ${Math.min(0, eY)}px)`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M ${r} 0
                A ${r} ${r} 0 0 0 0 ${r}
                L 0 ${r}
                ${westPoints}
                L 0 ${h - r}
                A ${r} ${r} 0 0 0 ${r} ${h}
                L ${r} ${h}
                ${southPoints}
                L ${w - r} ${h}
                A ${r} ${r} 0 0 0 ${w} ${h - r}
                L ${w} ${h - r}
                ${eastPoints}
                L ${w} ${r}
                A ${r} ${r} 0 0 0 ${w - r} 0
                L ${w - r} 0
                ${northPoints}
                L ${r} 0
              `"
            :style="value.data.style"
            stroke-linecap="round" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"

export default {
  name: "BlockShapeCallout",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  data() {
    return {
      state: "not-selected"
    }
  },
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    r() {
      return this.w * this.value.data.controls.radius
    },
    eX() {
      return this.w * this.value.data.controls.extremeX
    },
    eY() {
      return this.h * this.value.data.controls.extremeY
    },
    // width of triangle base out of bubble
    wP() {
      return this.w * 0.25
    },
    // width space left to closer edge from triangle
    wLP() {
      return (this.w * 0.5 - this.wP) * 0.5
    },
    // height of triangle base out of bubble
    hP() {
      return this.h * 0.25
    },
    // height space left to closer edge from triangle
    hLP() {
      return (this.h * 0.5 - this.hP) * 0.5
    },
    // function of line A passing throw (0,0) and (w,h)
    fA() {
      return (this.h / this.w) * this.eX
    },
    // function of line B passing throw (0,w) and (0,h)
    fB() {
      return (this.h / -this.w) * this.eX + this.h
    },
    isQuadrantSouth() {
      return (
        // is below line A passing throw (0,0) to (w,h)
        this.fA <= this.eY &&
        // is below line B passing throw (w, 0) to (0, h)
        this.fB < this.eY
      )
    },
    isQuadrantEast() {
      return (
        // is above line A passing throw (0,0) to (w,h)
        this.fA > this.eY &&
        // is below line B passing throw (w, 0) to (0, h)
        this.fB <= this.eY
      )
    },
    isQuadrantNorth() {
      return (
        // is above line A passing throw (0,0) to (w,h)
        this.fA >= this.eY &&
        // is above line B passing throw (w, 0) to (0, h)
        this.fB > this.eY
      )
    },
    isQuadrantWest() {
      return (
        // is below line A passing throw (0,0) to (w,h)
        this.fA < this.eY &&
        // is above line B passing throw (w, 0) to (0, h)
        this.fB >= this.eY
      )
    },
    // south points
    southPoints() {
      if (this.isQuadrantSouth) {
        const startP = this.eX < this.w / 2 ? 0 : this.w / 2
        const middleP = this.eY < this.h ? this.h : this.eY
        return `
          L ${startP + this.wLP} ${this.h},
          L ${this.eX} ${middleP},
          L ${startP + this.wLP + this.wP} ${this.h}
        `
      }
      return ""
    },
    // east points
    eastPoints() {
      if (this.isQuadrantEast) {
        const startP = this.eY < this.h / 2 ? 0 : this.h / 2
        const middleP = this.eX < this.w ? this.w : this.eX
        return `
          L ${this.w} ${startP + this.hLP + this.hP},
          L ${middleP} ${this.eY},
          L ${this.w} ${startP + this.hLP}
        `
      }
      return ""
    },
    // north points
    northPoints() {
      if (this.isQuadrantNorth) {
        const startP = this.eX < this.w / 2 ? 0 : this.w / 2
        const middleP = this.eY < 0 ? this.eY : 0
        return `
          L ${startP + this.wLP + this.wP} 0,
          L ${this.eX} ${middleP},
          L ${startP + this.wLP} 0
        `
      }
      return ""
    },
    // west points
    westPoints() {
      if (this.isQuadrantWest) {
        const startP = this.eY < this.h / 2 ? 0 : this.h / 2
        const middleP = this.eX < 0 ? this.eX : 0
        return `
          L 0 ${startP + this.hLP},
          L ${middleP} ${this.eY},
          L 0 ${startP + this.hLP + this.hP}
        `
      }
      return ""
    }
  },
  watch: {
    w() {
      const maxRadius = Math.min(this.wLP, this.hLP)
      if (this.r > maxRadius) {
        this.value.data.controls.radius = maxRadius / this.w
      }
    }
  }
}
</script>
