<template>
  <div>
    <p-panel-properties-group title="Action">
      <p-panel-properties>
        <p-property-tile icon="mdi-hammer" title="Trigger">
          <div style="width: 100px">
            <v-select
              v-model="value.data.trigger"
              :items="[
                { text: 'Hover', value: 'hover' },
                { text: 'Click', value: 'click' }
              ]"
              :prepend-icon="value.data.trigger === 'click' ? 'mdi-cursor-default-click' : 'mdi-cursor-pointer'"
              hide-details
              solo
              flat
              dense></v-select>
          </div>
        </p-property-tile>
      </p-panel-properties>
    </p-panel-properties-group>
    <p-panel-properties-group title="Front side">
      <p-panel-properties>
        <font-global-form
          :data="value.data.front"
          side="front"
          :id="value.id"
          :key="value.id + 0"
          :font-sizes-numbers="fontSizesNumbers" />
        <text-alignment-form :data="value.data.front" :show-justify="true" />
        <p-property-tile icon="mdi-format-color-fill" title="Front Background color">
          <preset-input-color v-model="value.data.front.backgroundColor"></preset-input-color>
        </p-property-tile>
      </p-panel-properties>
    </p-panel-properties-group>
    <p-panel-properties-group title="Back side">
      <font-global-form
        :data="value.data.back"
        side="back"
        :id="value.id"
        :key="value.id + 1"
        :font-sizes-numbers="fontSizesNumbers" />
      <p-panel-properties>
        <text-alignment-form :data="value.data.back" :show-justify="true" />
        <p-property-tile icon="mdi-format-color-fill" title="Back Background Color">
          <preset-input-color v-model="value.data.back.backgroundColor"></preset-input-color>
        </p-property-tile>
      </p-panel-properties>
    </p-panel-properties-group>
  </div>
</template>

<script>
import PresetInputColor from "../../components/slides/styles/PresetInputColor.vue"
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../_components/utils/PPanelProperties.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import FontGlobalForm from "../_components/panel/FontGlobalForm.vue"
import { fontSizesNumbers } from "../_components/panel/fontGlobalCommon"
import TextAlignmentForm from "../_components/panel/TextAlignmentForm.vue"
export default {
  props: ["value"],
  components: {
    TextAlignmentForm,
    PresetInputColor,
    PPanelPropertiesGroup,
    PPanelProperties,
    FontGlobalForm,
    PPropertyTile
  },
  data() {
    return {
      fontSizesNumbers
    }
  }
}
</script>
