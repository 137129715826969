var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-block',{attrs:{"value":_vm.value,"mode":_vm.mode,"state":_vm.state},on:{"update:state":function($event){_vm.state=$event}},scopedSlots:_vm._u([{key:"main",fn:function(){return [(_vm.state == 'primary-selected' && _vm.mode === 'edit')?_c('ShapeControlHandle',{staticClass:"shape-arrow-control-handler-x",attrs:{"x":_vm.s,"x-range":[0, _vm.w],"y":0,"angle":_vm.value.rotation},on:{"update:x":x => (_vm.value.data.controls.slope = x / _vm.w)}}):_vm._e(),(_vm.state == 'primary-selected' && _vm.mode === 'edit')?_c('ShapeControlHandle',{staticClass:"shape-arrow-control-handler-y",attrs:{"x":0,"y":_vm.t,"y-range":[0, _vm.h * 0.49],"angle":_vm.value.rotation},on:{"update:y":y => (_vm.value.data.controls.thick = y / _vm.h)}}):_vm._e(),_c('svg',{staticClass:"shape-arrow",staticStyle:{"vertical-align":"top"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":"100%","height":"100%","preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`}},[_c('defs',[_c('path',{style:(_vm.value.data.style),attrs:{"id":`path-${_vm.id}`,"d":`
              M ${_vm.w} ${_vm.h / 2}
              L ${_vm.s} 0
              L ${_vm.s} ${_vm.t}
              L 0 ${_vm.t}
              L 0 ${_vm.h - _vm.t}
              L ${_vm.s} ${_vm.h - _vm.t}
              L ${_vm.s} ${_vm.h}
              L ${_vm.w} ${_vm.h / 2}
            `,"stroke-linecap":"round"}}),_c('clipPath',{attrs:{"id":`clip-${_vm.id}`}},[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`}})])]),_c('g',[_c('use',{attrs:{"xlink:href":`#path-${_vm.id}`,"clip-path":`url(#clip-${_vm.id})`}})])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }