import Content from "./Content.vue"
import Dashboard from "./Dashboard.vue"

export default {
  path: "recycle-bin",
  name: "Recycle bin",
  component: Content,
  children: [
    {
      path: ":library_id",
      name: "Library recycle bin",
      props: true,
      component: Dashboard
    }
  ]
}