<template>
  <div class="lp-shape-toolbar" v-if="value">
    <template v-if="!changeContentProtection">
      <template v-if="fill">
        <v-icon small class="ml-2 mr-3">mdi-format-color-fill</v-icon>
        <div class="lp-shape-toolbar-color mr-4">
          <preset-input-color
            :value="value.data.style.fill"
            @input="value.data.style.fill = $event"
            :small="true"></preset-input-color>
        </div>
      </template>
      <v-icon small class="ml-2 mr-3">mdi-border-outside</v-icon>
      <div class="lp-shape-toolbar-color">
        <preset-input-color
          :value="value.data.style.stroke"
          @input="value.data.style.stroke = $event"
          :small="true"></preset-input-color>
      </div>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn flat v-on="on">
            <v-icon small>mdi-format-line-weight</v-icon>
            <v-icon small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-text class="lp-shape-toolbar__slider">
            <v-slider
              v-model="value.data.style.strokeWidth"
              thumb-label="always"
              thumb-size="28"
              hide-details
              :min="0"
              :max="50"></v-slider>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
    <div v-else style="color: #7d7d7d">
      <v-icon small class="ml-2 mr-3">mdi-lock-outline</v-icon>
      Change content protection enabled
    </div>
  </div>
</template>

<script>
import PresetInputColor from "../../components/slides/styles/PresetInputColor"
import { PToolbarAction } from "plant-common"
export default {
  name: "ShapeToolbar",
  props: {
    value: {
      default: null
    },
    fill: {
      default: true
    }
  },
  components: {
    PresetInputColor,
    PToolbarAction
  },
  computed: {
    changeContentProtection() {
      return this.value.protection.data
    }
  }
}
</script>

<style>
.lp-shape-toolbar,
.lp-shape-toolbar-color {
  display: flex;
}
.lp-shape-toolbar-color {
  align-items: center;
}

.v-card .v-card__text.lp-shape-toolbar__slider {
  padding: 28px 20px 10px;
}
</style>
