<template>
  <Toolbar :value="value" :fill="false" />
</template>

<script>
import Toolbar from "../shape/Toolbar.vue"
export default {
  props: ["value"],
  components: { Toolbar }
}
</script>