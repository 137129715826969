<template>
    <v-toolbar>
    <v-btn @click="$emit('addTemplate')">
        New Template
    </v-btn>
    <v-btn @click="$emit('renameTemplate')">
        Rename Template
    </v-btn>
    <v-btn @click="$emit('deleteTemplate')">
        Delete Template
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn @click="$emit('replaceTemplateSvg', '.svg')">
        Upload Svg
    </v-btn>
    <v-btn @click="$emit('replaceTemplateSvg', '.vsdx')">
        Upload Visio
    </v-btn>
    <v-btn color="primary">
        Open In Visio
    </v-btn>
    </v-toolbar>
</template>

<script>

export default {
  name: "ViewerToolbar"
}

</script>