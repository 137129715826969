<template>
  <v-list two-line>
    <v-subheader>Terminal Objective</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-bullseye-arrow</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>{{ getCustomId(guide.learning_objective) }}</v-list-tile-sub-title>
        <v-list-tile-title>{{ getTitle(guide.learning_objective) }}</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-btn icon @click="openObjective(getContentId(guide.learning_objective))">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <template v-if="objectives.length">
      <v-divider></v-divider>
      <v-subheader>Enabling Objectives</v-subheader>
      <v-list-tile v-for="(objective, index) in objectives" :key="index">
        <v-list-tile-avatar>
          <v-icon>mdi-alpha-e</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ objective.custom_id }}</v-list-tile-sub-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-list-tile-title v-on="on">{{ objective.title }}</v-list-tile-title>
            </template>
            <span>{{ objective.title }}</span>
          </v-tooltip>
        </v-list-tile-content>
        <!-- Delete only availabe in the editor -->
        <v-list-tile-action v-if="false">
          <v-btn icon>
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-tile-action>
      </v-list-tile>
    </template>
  </v-list>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { ContentTypeIcon } from "plant-common"
import { getContentId, getCustomId, getTitle } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "GuideInfo",
  inject: ["guideController"],
  components: {
    ContentTypeIcon
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "guide"]),
    section() {
      return this.guideController.selectedCard
    },
    enabling_objectives() {
      if (this.section && this.section.enabling_objectives) return this.section.enabling_objectives
      else return []
    },
    learning_objectives() {
      if (
        this.guide.learning_objective &&
        this.guide.learning_objective.body &&
        this.guide.learning_objective.body.contents &&
        this.guide.learning_objective.body.contents.enabling
      ) {
        return this.guide.learning_objective.body.contents.enabling
      } else {
        return []
      }
    },
    objectives() {
      let data = []
      this.learning_objectives.forEach(e => {
        this.enabling_objectives.forEach(k => {
          if (k === e.id) {
            data.push(e)
          }
        })
      })
      return data
    }
  },
  methods: {
    getContentId: getContentId,
    getCustomId: getCustomId,
    getTitle: getTitle,
    openObjective(contentId) {
      PServiceContent.openContent(contentId, "tab")
    }
  }
}
</script>
