import {$http} from '../config';

const Users = {

	getAllUsers:  () => $http('/users').then(r => r.data),

	createUser: (email, name, role) => $http.post(`/users`, { email, name, role }).then(r => r.data),

	getUserById: (userId) => $http(`/users/${userId}`).then(r => r.data),

	deleteUserById: (userId) => $http.delete(`/users/${userId}`).then(r => r.data),

	updateUser: (userId, { email, name, role, disabled }) => $http.put(`/users/${userId}`, { email, name, role, disabled }).then(r => r.data),
};

export default Users;
