/**
 * Return highlightable svg element for select/highlight tool inside PLANT's diagrams.
 *
 * @since      02/09/2021
 * @access     public
 *
 * @param {object}  selector    SVG object from PLANT's diagram.
 *
 * @return {object} SVG object to be selected.
 */
export const selectSquare = function (selector) {
    // If there is some element inside selector with highligtable class set, return the first one
    if (selector.querySelector('.highlightable')) {
        return selector.querySelector('.highlightable')
    } else {
        return selector
    }
}