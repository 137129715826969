export const DatasheetConfigGroups = [
    {
        value: "General",
        description: "Show as General information"
    },
    {
        value: "Mechanic",
        description: "Show as Mechanic information"
    },
    {
        value: "Electric",
        description: "Show as Electric information"
    },
    {
        value: "Instrumentation",
        description: "Show as Instrumentation information"
    },
    {
        value: "Control",
        description: "Show as Control information"
    },
    {
        value: "Operation",
        description: "Show as Operation information"
    },
    {
        value: "Other",
        description: "Show as Other information"
    }

]