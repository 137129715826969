<template>
  <v-dialog v-model="value" max-width="80%" persistent scrollable>
    <v-card height="100%">
      <v-card-title class="title">
        {{ title }}

        <v-spacer></v-spacer>

        <v-btn icon @click="$emit('input')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <div class="lp-configure-slide-dialog-form">
        <!-- Edit title -->
        <v-card-text>
          <v-text-field autofocus label="Slide title" v-model="slide.title"></v-text-field>
        </v-card-text>

        <!-- Choose layout -->
        <v-card-text>
          <v-card-title class="lp-configure-slide-dialog-layout-title body-2">Choose a layout:</v-card-title>

          <v-item-group class="cs-select-layout-wrapper">
            <div id="configure-slide-dialog">
              <v-layout wrap>
                <v-flex v-for="(layout, index) in layoutsEmptyIncluded" :key="index" xs12 sm12 md6 lg4>
                  <v-item>
                    <v-card
                      @click="selectedLayout = layout"
                      :class="layout === selectedLayout ? 'accent' : ''"
                      :style="`margin: ${marginAll}px`"
                      :hover="true">
                      <div :style="`width: ${width}px; height: ${height}px; position: relative; margin: 0 auto;`">
                        <layout-viewer-readonly
                          :blocks="layout.slide.blocks"
                          :fields="fields"
                          :width="width"
                          :height="height"
                          :key="layoutIndex"
                          :blockDefinitions="blockDefinitions"></layout-viewer-readonly>
                      </div>

                      <v-divider></v-divider>

                      <v-card-title>
                        {{ layout.name }}
                      </v-card-title>
                    </v-card>
                  </v-item>
                </v-flex>
              </v-layout>
            </div>
          </v-item-group>
        </v-card-text>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="emitSelectedIfAccept(slide, selectedLayout)"
          :disabled="selectedLayout === null"
          flat
          color="success"
          >Apply</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// Using LayoutViewerReadonly to avoid using inject controller in mounted dialog
// (Error: Injection "lessonPlanController" not found)
import LayoutViewerReadonly from "./LayoutViewerReadonly"
import { PtModal } from "plant-common"
import Vue from "vue"

export default {
  components: { LayoutViewerReadonly },
  props: ["title", "slide", "layouts", "styleSlide", "fields", "blockDefinitions"],
  // Add a provide for dialog to avoid Error Injection not found
  provide() {
    return {
      blockDefinitions: this.blockDefinitions,
      blockController: {},
      blockGlobals: {},
      lessonPlanController: {
        fields: this.fields,
        getParentQuiz: () => null,
        getSlideFromBookmark: () => null
      },
      blockEventBus: new Vue()
    }
  },
  mixins: [PtModal],
  data: () => ({
    value: true,
    scale: 1,
    layoutIndex: 0,
    width: 240,
    marginAll: 8,
    itemsPerRow: 3,
    scrollBarWidth: 22,
    selectedLayout: null
  }),
  computed: {
    height() {
      //Default aspect ratio 480/270 = 1.77
      return Math.round(this.width / 1.77)
    },
    slideStyles() {
      const { backgroundColor, border, boxShadow, color } = this.styleSlide
      return {
        backgroundColor,
        border,
        boxShadow,
        color
      }
    },
    layoutsEmptyIncluded() {
      const empty = {
        id: null,
        name: "No layout",
        slide: {
          blocks: []
        }
      }
      return [empty, ...this.layouts]
    }
  },
  mounted() {
    this.displayCurrentLayout()

    const element = document.getElementById("configure-slide-dialog")
    if (element) {
      if (element.clientWidth >= 940) {
        this.itemsPerRow = 3
      } else if (element.clientWidth >= 698) {
        this.itemsPerRow = 2
      } else {
        this.itemsPerRow = 1
      }
      this.width =
        (element.clientWidth - this.scrollBarWidth - this.marginAll * 2 * this.itemsPerRow) / this.itemsPerRow
    }
  },
  methods: {
    displayCurrentLayout() {
      if (this.slide.layout && this.slide.layout.id) {
        const index = this.layouts.findIndex(l => l.id === this.slide.layout.id)
        // first layout is the empty one
        this.layoutIndex = index !== undefined ? index + 1 : 0
      }
    },
    async emitSelectedIfAccept(slide, layout) {
      if (this.slide.layout.id) {
        var confirm = await this.$modal("confirm", {
          title: "Are you sure you want to change the layout?",
          accept: {
            text: "Accept",
            color: "green",
            icon: "mdi-check"
          },
          cancel: {
            text: "Cancel",
            color: "default"
          }
        })
        if (confirm) {
          this.emitSelected(slide, layout)
        } else {
          return
        }
      } else {
        this.emitSelected(slide, layout)
      }
    },
    emitSelected(slide, layout) {
      this.$emit("input", { slide, layout })
    }
  }
}
</script>

<style>
.lp-configure-slide-dialog-layout-container {
  display: flex;
  justify-content: center;
}
.lp-configure-slide-dialog-window-card {
  display: flex !important;
  flex-direction: column;
  align-items: center;
}
.lp-configure-slide-dialog-button {
  width: 500px;
  height: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  border: none !important;
  background: none !important;
}
.lp-configure-slide-dialog-form {
  padding: 10px;
}
.lp-configure-slide-dialog-layout-title {
  margin-top: -30px;
  margin-left: -15px;
  font-weight: 400;
}
.lp-configure-slide-dialog-card-actions .mdi-record {
  font-size: 21px !important;
}
.lp-configure-slide-dialog-card-title {
  display: flex;
  justify-content: center;
  margin-top: -40px;
}
.cs-select-layout-wrapper {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
