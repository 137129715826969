import Content from "./index";
import ToolsDashboard from "./ToolsDashboard.vue";

export default {
  path: "tools",
  name: "Content Tools",
  component: Content,
  children: [
    {
      path: ":library_id",
      name: "Tools",
      props: true,
      component: ToolsDashboard,
    },
  ],
};
