<template>
  <v-content id="print-preview" v-if="guideController.guide">
    <v-toolbar class="no-print" fixed app>
      <v-toolbar-title> <v-icon left>mdi-printer</v-icon>Print Preview </v-toolbar-title>
      <v-divider vertical class="px-3"></v-divider>

      <v-toolbar-title>
        <kbd>{{ custom_id }}</kbd>
        {{ title }}
        (Revision {{ guideController.content.header.major_revision }}.{{
          guideController.content.header.minor_revision
        }})
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn outline :disabled="progress < 100" color="primary" @click="print">
        <span v-if="progress == 100"> <v-icon left>mdi-printer</v-icon>Print </span>
        <span v-else> <v-icon left class="rotate">mdi-loading</v-icon>Loading ... ({{ progress }}%) </span>
      </v-btn>
    </v-toolbar>
    <div class="print-content">
      <div class="page page-size-letter">
        <div style="padding: 0.5in">
          <h3>
            <kbd><v-icon dark small left>mdi-tag-outline</v-icon>{{ custom_id }}</kbd>
          </h3>
          <h1 class="mb-3">
            {{ title }}
          </h1>
          <img :src="previews[guideController.guide.cover.id]" style="width: 7in" />
          <h2>Contributors</h2>
          <ul>
            <li
              v-for="contributor in guideController.content.approval_process.workflow.contributors"
              :key="contributor.user_id">
              <span class="capitalize">{{ contributor.role }}: </span>
              <b>{{ contributor.name }}</b> ({{ contributor.email }})
            </li>
          </ul>
        </div>
      </div>

      <div v-for="(card, index) in guideController.cards" :key="card.id" class="page page-size-letter">
        <div style="padding: 0.5in">
          <h1 class="mb-3">
            {{ card.title || ((card.content || {}).header || {}).title || "Slide " }}
            ({{ index + 1 }}/{{ guideController.cards.length }})
          </h1>
          <img id="preview" :src="previews[card.id] || require('./placeholder.png')" style="width: 7in" />
          <template v-if="card.content && card.content.header">
            <h2 class="mb-2 mt-3">Embeded Content Information</h2>
            <kbd>{{ getCustomId(card.content) }}</kbd>
            {{ getTitle(card.content) }}
            (Revision {{ card.content.header.major_revision }}.{{ card.content.header.minor_revision }})
          </template>
          <h2 class="mb-2 mt-3">Speaker Notes</h2>
          <p-rich-text
            :value="card.notes || 'This slide does not have speaker notes'"
            :canEdit="false"
            placeholder="Speaker notes" />
        </div>
      </div>
    </div>
  </v-content>
</template>

<script>
import { PRichText } from "plant-common"
import { mapState } from "vuex"
import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from "../../../../store_constants/library/index.js"
import { getTitle, getCustomId } from "../../../../utils/dbTool"
import ptApiClient from "plant-api-client"
import guideController from "../GuideController"
const THUMBNAIL_PREVIEW_DEFAULT = "default"

async function getPngFileFromSvg(svg_string) {
  return new Promise((resolve, reject) => {
    var can = document.createElement("canvas")
    var ctx = can.getContext("2d")
    var img = new Image()
    img.onload = function () {
      can.width = this.width
      can.height = this.height
      ctx.drawImage(img, 0, 0)
      can.toBlob(blob => resolve(blob), "image/png")
    }
    img.src = URL.createObjectURL(new File([svg_string], "mysvg.svg", { type: "image/svg+xml" }))
  })
}
export default {
  name: "GuidePrint",
  components: { PRichText },
  props: ["value", "view", "mode"],
  data() {
    return {
      previews: {},
      guideController: guideController(this)
    }
  },
  async created() {
    // Get card templates
    var card_templates = {}
    if (
      this.guideController.content.body.contents.template &&
      this.guideController.content.body.contents.template.body
    ) {
      for (var card_template of this.guideController.content.body.contents.template.body.attachments) {
        card_templates[card_template.name] = await (await fetch(card_template.url)).text()
      }
    }
    let cleanOrigin
    var svg
    const PLANT_REF = "plant:/content/"
    for (var card of this.guideController.cards) {
      this.$set(this.previews, card.id, false)
      cleanOrigin = false
      if (card.type == "card") {
        if (card.content) {
          cleanOrigin = card.content.$origin.replace(PLANT_REF, "").split("/")
          var content_id = cleanOrigin[0]
          var { major_revision, minor_revision } = card.content.header
          this.previews[card.id] = ptApiClient.getContentPreviewUrl(
            content_id,
            major_revision,
            minor_revision,
            THUMBNAIL_PREVIEW_DEFAULT,
            card.view
          )
        } else {
          this.previews[card.id] = require("../empty_card.svg")
        }
      } else {
        svg = false
        if (card.type == "card-template") {
          svg = card_templates[card.content.template_name]
        } else {
          svg = card_templates[card.type]
        }

        var fields = {
          ...card.fields,
          _TITLE: getTitle(this.guideController.content),
          _CUSTOM_ID: getCustomId(this.guideController.content)
        }
        if (svg) {
          for (var field in fields) {
            var cleanString = fields[field].replace(/&/g, "&#38;")
            svg = svg.replace(new RegExp(`{{${field}}}`, "g"), cleanString)
          }
          var png_blob = await getPngFileFromSvg(svg)
          this.previews[card.id] = URL.createObjectURL(new File([png_blob], "myimage.png"))
        } else {
          this.previews[card.id] = require("./no_screenshot.png")
        }
      }
    }
  },
  computed: {
    ...mapState(STORE_LIBRARY_NAME, { plant_id: STATE_PLANT_ID }),
    title() {
      return getTitle(this.guideController.content)
    },
    custom_id() {
      return getCustomId(this.guideController.content)
    },
    progress() {
      return Math.floor(
        (Object.values(this.previews).reduce((t, v) => t + (v ? 1 : 0), 0) / this.guideController.cards.length) * 100
      )
    }
  },
  methods: {
    getCustomId,
    getTitle,
    print() {
      document.title = `PLANT - ${this.custom_id} (Revision ${this.guideController.content.header.major_revision}.${this.guideController.content.header.minor_revision})`
      window.print()
    }
  }
}
</script>
<style scoped>
html {
  overflow: scroll !important;
}
#print-preview {
  background: rgb(204, 204, 204) !important;
}
.print-content {
  overflow-y: scroll;
  height: 100vh;
}
.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen {
  .page {
    background: white;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  }
}

@media print {
  .print-content {
    overflow-y: unset;
    height: auto;
  }
  .no-print {
    display: none;
  }
  .page {
    margin: 0;
    box-shadow: 0;
  }
}

.page-size-letter {
  width: 8.5in;
  height: 11in;
}

.page-size-letter.page-layout-portrait {
  height: 8.5in;
  width: 11in;
}

dt {
  font-weight: bold;
  margin-left: 2em;
}
dd {
  margin-left: 4em;
}

.capitalize {
  text-transform: capitalize;
}
</style>../../../../utils/dbTool.js../GuideController.js