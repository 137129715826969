import PServiceContent from "plant-common/src/services/PServiceContent"

export default async function (contents) {
    var refs = []
    const findref = async obj => {
        if (obj == null) {
        } else if (obj.$origin && isNotAFile(obj.$origin)) {
            refs.push(obj)
        } else if (obj.$ref && isNotAFile(obj.$ref)) {
            if (!refs.some(ref => ref.$origin === obj.$ref)) {
                const ref = await PServiceContent.getContentFromRef(obj.$ref)
                refs.push(Object.assign(ref, { $origin: obj.$ref }))
            }
        } else if (typeof obj == "object") {
            for (const key of Object.keys(obj)) {
                await findref(obj[key])
            }
        }
    }
    await findref(contents)
    refs = refs.filter(function (a) {
        if (a.code && a.code === "ERESOLVER") {
            return false
        }
        var key = a.header.metadata.custom_id + "|" + a.header.major_revision + "|" + a.header.minor_revision
        if (!this[key]) {
            this[key] = true
            return true
        }
    }, Object.create(null))
    return refs
}

function isNotAFile(origin) {
    const regexFile = new RegExp("plant:/files")
    return !regexFile.test(origin)
}