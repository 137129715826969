<script>
import PtApiClient from "plant-api-client"
import { getContentId, getType, getMajorVersion, getMinorVersion, getFolder } from "../../utils/dbTool"
import CopyItemDialog from "../../components/guideCommon/guide/Editor/CopyItemDialog"

import { mapState, mapActions } from "vuex"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { STORE_CONTENT_NAME, ACTION_COPY_CONTENT_FROM_REVISION } from "@/store_constants/content"

export default {
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, {
      copyContentFromRevision: ACTION_COPY_CONTENT_FROM_REVISION
    }),
    createNewDraftFromContent(itemRevision) {
      this.$modal(CopyItemDialog, {
        content_path: getFolder(itemRevision)
      }).then(res => {
        if (!res) return false
        const { title, custom_id } = res
        let { path_id } = res
        if (path_id == "$root") path_id = undefined
        if (getType(itemRevision) == "guide") {
          this.copyContentFromRevision({
            revisionId: getContentId(itemRevision),
            title,
            custom_id,
            path_id
          })
            .then(res => {
              window.open(`/content/${this.settings._id}/${getContentId(res)}/draft`)
            })
            .catch(e => console.log(e))
        } else {
          PtApiClient.createDraftFromApprovedRevision(
            this.getRevisionData(itemRevision),
            custom_id,
            title,
            path_id,
            getType(itemRevision)
          )
            .then(res => {
              window.open(`/content/${this.settings._id}/${res._id}/draft`)
            })
            .catch(e => console.log(e))
        }
      })
    },
    getRevisionData(itemRevision) {
      return `${getContentId(itemRevision)}/${getMajorVersion(itemRevision)}/${getMinorVersion(itemRevision)}`
    }
  }
}
</script>
../guideCommon/guide/Editor/CopyItemDialog