/**
 * // This are the VUEX variables name, we can map the content of this value to whatever we want in each component like:
 * import { STORE_CONTENT_NAME, STATE_ITEM_REVISION } from "@/store/content/constants";
 * ...mapState(STORE_CONTENT_NAME, {variableNameOfComponent : STATE_ITEM_REVISION} )
 * // we can access in component to STATE_ITEM_REVISION value with this.variableNameOfComponent
 */

export const STORE_CONTENT_NAME = "content"

//  CORE MODULE
export const STATE_CAN_EDIT = "canEdit"
export const STATE_ITEM_ID = "itemId"
export const STATE_ITEM_REVISION = "itemRevision"
export const STATE_LOADING = "loading"
export const STATE_SELECTED_TAG = "selectedTag"
export const STATE_CURRENT_VIEW = "current_view"
export const STATE_REVISION_CHANGED_TOGGLE = "revisionChangedToggle"
export const STATE_DEPENDENCIES = "dependencies"
export const STATE_WEAK_DEPENDENCIES = "weakDependencies"

export const GETTER_ACTIVE_USER = "activeUser"

export const MUTATION_DESTROY_CONTENT = "destroyContent"
export const MUTATION_SELECT_TAG = "selectTag"
export const MUTATION_SET_CAN_EDIT = "setCanEdit"
export const MUTATION_SET_CONTENT = "setContent"
export const MUTATION_SET_CONTENT_REVISION = "setContentRevision"
export const MUTATION_SET_DEPENDENCIES = "setDependencies"
export const MUTATION_SET_WEAK_DEPENDENCIES = "setWeakDependencies"
export const MUTATION_SET_COMMENT_ITEM_DRAFT = "setCommentItemDraft"
export const MUTATION_SET_CURRENT_VIEW = "setCurrentView"
export const MUTATION_SET_REPLY_ITEM_DRAFT = "setReplyItemDraft"
export const MUTATION_UPDATE_ITEM_REVISION_METADATA = "updateItemRevisionMetadata"

export const ACTION_COPY_CONTENT_FROM_REVISION = "copyContentFromRevision"
export const ACTION_COPY_CONTENT_FROM_APPROVED_REVISION = "copyContentFromApprovedRevision"
export const ACTION_CREATE_DRAFT_FROM_REVISION = "createDraftFromItemRevision"
export const ACTION_DELETE_CONTENT = "deleteContent"
export const ACTION_GET_DEPENDENTS = "getDependents"
export const ACTION_LOAD_CONTENT = "loadContent"
export const ACTION_LOAD_DRAFT = "loadDraft"
export const ACTION_DELETE_SCORM = "deleteScorm"
export const ACTION_BUILD_SCORM = "buildScorm"
export const ACTION_DOWNLOAD_SCORM = "downloadScorm"
export const ACTION_CHECK_DRAFT = "checkDraft"
export const ACTION_SET_CONTENT_REVISION = "setContentRevision"
export const ACTION_UPDATE_ITEM_REVISION_METADATA = "updateItemRevisionMetadata"
export const ACTION_CALCULATE_DEPENDENCIES = "calculateDependencies"
export const ACTION_PREVIEW_SCORM = "getScormPreview"

export * from "./modules/editor"
export * from "./modules/revisions"
export * from "./modules/comments"

/* DEV STRUCTURE ANNOTATIONS

     User stories:

     - Core
        - dashboard
        - workflows
        - Explorer
        - Setting
        - Training Catalog
        - My Catalog

     - Content (viewer /editor)

     - Guide


   Folder structure:

      store_constants/
         content/
            index
            modules/
         library/
            ...
         user/
            ...

      store/
         content/
            index
            modules/
         library/
            ...
*/
