<template>
	<div>
		<template v-if="contents && !contents.length == 0">
			<content-list
				v-if="typeView == 'list'"
				layout="knowledge"
				:items="contents"
				:showCheck="true"
				:showPath="showPath"
				defaultSort="name"
				:contentActions="true"
				:userRole="userProfile.role"
				@input="goToContent"
				@folder="getFolder"
				@change="changeSelected"
				@toggleAll="toggleAllItems"
				@content-action="handleContentAction"
				:key="componentKey" />
			<content-grid
				v-else
				:typeView="typeView"
				:items="contents"
				@input="goToContent"
				@folder="getFolder"
				:library="plant_id" />
		</template>
		<template v-else>
			<div v-if="loading">
				<v-alert :value="true" color="info" icon="mdi-information-outline">Loading data</v-alert>
				<v-progress-linear
					:size="100"
					:width="6"
					color="primary"
					indeterminate
					class="loading-fixed"></v-progress-linear>
			</div>
			<div v-else :value="true" class="empty_folder">
				<v-img height="500" contain :src="require('./empty_folder.svg')"></v-img>
				<h5 class="text-center headline font-weight-light mt-5">This folder is empty</h5>
			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ContentList, PtModal } from 'plant-common';
import ContentGrid from '@/pages/explorer/ExploreLibrary/ContentGrid';
import { getContentId, getMajorVersion, getMinorVersion, getType } from '../../../utils/dbTool';
import contentActions from '../../../components/commonContentActions/contentActions.vue';

import { STORE_LIBRARY_NAME, STATE_PLANT_ID } from '../../../store_constants/library';
import {
	STORE_EXPLORER_NAME,
	STATE_FOLDER,
	STATE_CONTENTS,
	STATE_EXPLORER_LOADING,
	STATE_EXPLORER_VIEW,
	STATE_SELECTED,
	ACTION_OPEN_FOLDER,
	ACTION_CHANGE_SELECTION,
	ACTION_CHANGE_ALL_SELECTION,
	ACTION_SEARCH_QUERY
} from '@/store_constants/explorer';
import { STORE_USER_NAME, STATE_PROFILE } from '../../../store_constants/user';
import * as CommonApps from '@/pages/commonApps';

const { VUE_APP_IP_APP_AVAILABLE } = window.__env__ || false;
const IP_APP_AVAILABLE = VUE_APP_IP_APP_AVAILABLE === 'true';

export default {
	components: {
		ContentList,
		ContentGrid
	},
	mixins: [contentActions, PtModal],
	data() {
		return {
			search: '',
			selected: [],
			type: '',
			all_items: [],
			items: [],
			componentKey: 0
		};
	},

	computed: {
		...mapState(STORE_EXPLORER_NAME, {
			folder: STATE_FOLDER,
			contents: STATE_CONTENTS,
			loading: STATE_EXPLORER_LOADING,
			typeView: STATE_EXPLORER_VIEW,
			selectedStore: STATE_SELECTED
		}),
		...mapState(STORE_LIBRARY_NAME, {
			plant_id: STATE_PLANT_ID
		}),
		...mapState(STORE_USER_NAME, {
			userProfile: STATE_PROFILE
		}),
		showPath() {
			return this.contents.some(item => item.hasOwnProperty('path'));
		}
	},
	watch: {
		selectedStore(v) {
			if (v.length === 0) this.componentKey += 1;
		},
		'selected.length': {
			handler() {
				this.changeSelection(this.selected);
			}
		},
		'$route.query.folder_id': {
			immediate: true,
			handler() {
				if (!this.$route.query.q) this.openFolder(this.$route.query.folder_id);
			}
		},
		'$route.query.q': {
			immediate: true,
			handler() {
				if (this.search != this.$route.query.q) {
					this.search = this.$route.query.q;
				}
				if (this.$route.query.q && this.$route.query.q != '') {
					this.searchQuery(this.$route.query.q);
				} else {
					this.openFolder();
				}
			}
		}
	},
	methods: {
		...mapActions(STORE_EXPLORER_NAME, {
			openFolder: ACTION_OPEN_FOLDER,
			changeSelection: ACTION_CHANGE_SELECTION,
			changeAllSelection: ACTION_CHANGE_ALL_SELECTION,
			searchQuery: ACTION_SEARCH_QUERY
		}),
		getFolder(element) {
			if (element._id && !this.loading) this.openFolder(element._id);
		},
		getLocatorFromContent(content) {
			const libraryId = this.plant_id;
			const contentId = getContentId(content);
			let revision = '';

			if (content.state === 'draft') {
				revision = 'draft';
			} else {
				revision = `${getMajorVersion(content)}.${getMinorVersion(content)}`;
			}

			return { libraryId, contentId, revision };
		},
		ipAppUrl(content) {
			const { libraryId, contentId, revision } = this.getLocatorFromContent(content);

			return `/apps/interactive-presentation/index.html?libraryId=${libraryId}&contentId=${contentId}&revision=${revision}`;
		},
		appUrl(content) {
			const { libraryId, contentId, revision } = this.getLocatorFromContent(content);

			const appName = CommonApps.getAppName(getType(content));

			return CommonApps.getAppUrl(appName, libraryId, contentId, revision);
		},
		getContentUrl(content) {
			const contentType = getType(content);

			if (IP_APP_AVAILABLE && contentType === 'lessonPlan') {
				return this.ipAppUrl(content);
			}

			if (CommonApps.apps.includes(contentType)) {
				return this.appUrl(content);
			}

			let routeData = this.$router.resolve({
				name: 'Content Viewer Latest',
				params: { id: getContentId(content), library_id: this.plant_id }
			});

			if (content.state === 'draft') {
				routeData.href = routeData.href.replace('latest', 'draft');
			}

			return routeData.href;
		},
		goToContent(content) {
			if (this.loading) return;

			if (!content._id) return;

			window.open(this.getContentUrl(content), '_blank');
		},
		handleContentAction(data) {
			const { event, item } = data;
			switch (event) {
				case 'createNewDraftFrom':
					this.createNewDraftFromContent(item);
					break;

				default:
					break;
			}
		},
		changeSelected($event) {
			this.changeSelection($event.item);
		},
		toggleAllItems(flag) {
			this.changeAllSelection(flag);
		}
	}
};
</script>
<style scoped>
.empty_folder {
	margin: 50px auto;
}

.loading-fixed {
	margin: -4px 0px;
}

.explorer-content-breadcrumb-container {
	display: flex;
	align-items: flex-start;
	margin: 10px 25px 20px 25px;
}

.explorer-content-view {
	margin-top: 10px;
}

.explorer-content-breadcrumb {
	margin-left: 10px;
	margin-top: 5px;
}
</style>
