export const staticSteps = [
  {
    id: "metadata",
    title: "Metadata",
    description: "Fill the information for the Lesson"
  },
  {
    id: "select-parts",
    title: "Select parts",
    description: "Select the parts of the current Lesson"
  },
  {
    id: "select-objectives",
    title: "Select objectives",
    description: "Select the objectives of the current Lesson"
  },
  {
    id: "references",
    title: "References",
    description: "Select the references used in current Lesson"
  }
]
