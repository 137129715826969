import { validateObjective } from "./objectiveValidator"
import { validateAMC } from "./amcValidator"
import { getType } from "../utils"

export const validate = content => {
  let validation = {
    isValid: true,
    message: ""
  }
  const type = getType(content)
  switch (type) {
    case "objective":
      validation = validateObjective(content)
      break
    case "activityMultipleChoice":
      validation = validateAMC(content)
      break
    default:
      validation = {
        isValid: true,
        message: ""
      }
      break
  }

  return validation
}
