<template>
	<v-dialog :value="true" @input="$emit('input', false)" persistent max-width="290">
		<v-card>
			<v-card-title class="headline">{{dialogTitle}}</v-card-title>
			<v-card-text>
				<v-text-field ref="input" autofocus label="Name" v-model="changedName" @keyup.enter="$emit('input', changedName)"></v-text-field>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" flat @click.native="$emit('input', false )">Cancel</v-btn>
				<v-btn
					color="success"
					:disabled="!changedName"
					outline
					@click.native="$emit('input', changedName)"
				>Rename</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: ["dialogTitle", "name"],
	data() {
		return {
			changedName: this.name
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.input.$el.querySelector('input').focus()
			this.$refs.input.$el.querySelector('input').select()
		})
	}
}
</script>

<style>
</style>