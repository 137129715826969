<template>
  <v-list two-line>
    <v-subheader>Link Name</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-pound-box-outline</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Link name</v-list-tile-sub-title>
        <v-list-tile-title>{{selectedCard.internal_link_name}}</v-list-tile-title>
      </v-list-tile-content>
 
    </v-list-tile>
  </v-list>
</template>

<script>
import { mapInjectedData } from "plant-common/src/utils";
export default {
  inject: ["guideController"],
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "guide"]),
  }
};
</script>
