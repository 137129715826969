<template>
  <button
    type="button"
    :disabled="value.data.disabled"
    :style="viewStyles"
    :key="value.data.visited"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    class="lp-button-view">
    <icon-button class="lp-button-view-icon-button" :value="value" :state="state"></icon-button>
  </button>
</template>

<script>
import IconButton from "./IconButton.vue"

export default {
  name: "BlockViewButton",
  props: ["value", "viewStyles", "state"],
  components: { IconButton },
  data() {
    return {
      hovered: false
    }
  }
}
</script>

<style>
.lp-button-view {
  display: flex;
  align-items: center;
}

.lp-button-view-icon-button {
  font-size: calc(var(--text-scale) / 100 * 40px) !important;
}

/* Hover styles only in view mode */
.lp-button-view:hover {
  background-color: var(--background-color-hover) !important;
  color: var(--color-hover) !important;
  font-size: calc(var(--text-scale-hover) / 100 * 40px) !important;
  box-shadow: var(--border-shadow-hover) !important;
  border-radius: var(--border-radius-hover) !important;
  border-width: var(--border-width-hover) !important;
  border-style: var(--border-style-hover) !important;
  border-color: var(--border-color-hover) !important;
}

.lp-button-view:hover .lp-button-view-icon-button {
  font-family: var(--font-family-hover);
  text-align: var(--text-align-hover);
  justify-content: var(--justify-content-hover);
  font-size: calc(var(--text-scale-hover) / 100 * 40px) !important;
}
.lp-button-view:hover .lp-button-view-icon-button .v-icon {
  font-size: calc(var(--text-scale-hover) / 100 * 40px) !important;
}

/* Focus is showed with other properties than outline */
.lp-button-view:focus {
  outline: none;
}

.lp-button-view:focus {
  background-color: var(--background-color-hover) !important;
  color: var(--color-hover) !important;
  font-size: calc(var(--text-scale-hover) / 100 * 40px) !important;
  box-shadow: var(--border-shadow-hover) !important;
  border-radius: var(--border-radius-hover) !important;
  border-width: var(--border-width-hover) !important;
  border-style: var(--border-style-hover) !important;
  border-color: var(--border-color-hover) !important;
}

/* Active styles */
.lp-button-view:active {
  background-color: var(--background-color-active) !important;
  color: var(--color-active) !important;
  font-size: calc(var(--text-scale-active) / 100 * 40px) !important;
  box-shadow: var(--border-shadow-active) !important;
  border-radius: var(--border-radius-active) !important;
  border-width: var(--border-width-active) !important;
  border-style: var(--border-style-active) !important;
  border-color: var(--border-color-active) !important;
}

.lp-button-view:active .lp-button-view-icon-button {
  font-family: var(--font-family-active);
  text-align: var(--text-align-active);
  justify-content: var(--justify-content-active);
  font-size: calc(var(--text-scale-active) / 100 * 40px) !important;
}
.lp-button-view:active .lp-button-view-icon-button .v-icon {
  font-size: calc(var(--text-scale-active) / 100 * 40px) !important;
}
</style>
