import Component from "./index.vue"

export default {
  name: "contentCollection",
  title: "Content Collection",
  icon: require("./content-icon.svg"),
  category: "training",
  Viewer: Component,
  Editor: Component,
  Preview: Component
}
