<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    class="elevation-1"
    :pagination="pagination"
    @update:pagination="setPagination"
    :must-sort="true"
    :total-items="totalDocs"
    :rows-per-page-items="customRowsPerPage">
    <template v-slot:items="props">
      <slot v-if="props.item.dependency">
        <tr style="cursor: pointer" @click="selectUpgrade(props.item)">
          <td>
            <v-icon small left>mdi-library-shelves</v-icon>
            {{ props.item.dependency.library.name }}
          </td>
          <td>
            <content-type-icon :type="props.item.dependency.header.content_revision_type" />
          </td>
          <td>
            <kbd>{{ props.item.dependency.header.custom_id }}</kbd>
          </td>
          <td>{{ props.item.dependency.header.metadata.title }}</td>
          <td>{{ props.item.dependency.header.major_revision }}.{{ props.item.dependency.header.minor_revision }}</td>
          <td>{{ props.item.dependency.latest.major_revision }}.{{ props.item.dependency.latest.minor_revision }}</td>
          <td>{{ props.item.outdated.length }}</td>
        </tr>
      </slot>
    </template>
  </v-data-table>
</template>

<script>
import { outdated, loading, selectUpgrade, selectedUpgrade, setPagination, getPagination } from "../store.js"
import { ContentTypeIcon } from "plant-common"

export default {
  components: { ContentTypeIcon },
  data() {
    return {
      headers: [
        {
          text: "Library",
          value: "dependency.library.name",
          width: "10%"
        },
        {
          text: "Type",
          value: "dependency.header.metadata.content_revision_type",
          width: "25px"
        },
        {
          text: "Custom Id",
          value: "dependency.header.custom_id",
          width: "15%"
        },
        {
          text: "Title",
          value: "dependency.header.metadata.title",
          width: "75%"
        },
        {
          text: "Revision",
          sortable: false,
          width: "10em"
        },
        {
          text: "Latest",
          sortable: false,
          width: "10em"
        },
        {
          text: "Outdated Dependents",
          value: "outdatedCount",
          width: "10em"
        }
      ],
      customRowsPerPage: [5, 10, 25, 50]
    }
  },

  computed: {
    outdated,
    loading,
    selectedUpgrade,
    pagination: getPagination,
    totalDocs() {
      if (!this.outdated) return 0
      return this.outdated.totalDocs
    },
    items() {
      if (!this.outdated) return []
      return this.outdated.docs
    }
  },
  methods: {
    selectUpgrade,
    setPagination
  }
}
</script>

<style>
</style>