import { Node } from "@tiptap/core"

export const Fields = Node.create({
  name: "fields",
  group: "inline",
  inline: true,
  content: "text*",
  selectable: false,
  atom: true,

  addOptions: {
    HTMLAttributes: {},
    renderLabel({ options, node }) {
      return `${options.matcher.char}${node.attrs.label}`
    },
    matcher: {
      char: "",
      allowSpaces: false,
      startOfLine: false
    },
    mentionClass: "fields"
  },

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute("data-field"),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            "data-field": attributes.id
          }
        }
      },
      label: {
        default: null,
        parseHTML: element => element.textContent,
        renderHTML: attributes => {
          if (!attributes.label) {
            return {}
          }

          return {
            label: attributes.label
          }
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: "span[data-field]"
      }
    ]
  },

  renderHTML({ node }) {
    return [
      "span",
      {
        class: this.options.mentionClass,
        "data-field": node.attrs.id,
        "v-html": node.attrs.id
      },
      `${this.options.matcher.char}${node.attrs.label}`
    ]
  },

  addCommands() {
    return {
      setFields:
        field =>
          ({ chain }) => {
            return chain()
              .focus()
              .insertContent([
                {
                  type: "fields",
                  attrs: field
                },
                {
                  type: "text",
                  text: " "
                }
              ])
          }
    }
  }
})
