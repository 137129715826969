import GlobalReplaceTitle from "./global/global-replace-title"
import GlobalReplaceDescription from "./global/global-replace-description"
import DatasheetReplaceBoth from "./datasheet/datasheet-replace-both"
import DatasheetReplaceProperty from "./datasheet/datasheet-replace-property"
import DatasheetReplaceDescription from "./datasheet/datasheet-replace-description"
import DatasheetReplaceTitleDescriptionContent from "./datasheet/datasheet-replace-title-description-and-content"
import DatasheetReplacePropertiesFromTable from "./datasheet/datasheet-replace-properties-from-table"
import DiagramReplaceBoth from "./diagram/diagram-replace-both"
import DiagramReplaceConditionDetails from "./diagram/diagram-replace-condition-details"
import DiagramReplaceKeypoint from "./diagram/diagram-replace-keypoint"
import DiagramReplaceTitleDescriptionContent from "./diagram/diagram-replace-title-description-and-content"
import GuideReplaceSpeakerNotes from "./guide/guide-replace-speaker-notes"
// import MultipleChoiceReplace from "./activityMultipleChoice/multipleChoice-replace-property"

export default [
  GlobalReplaceTitle,
  GlobalReplaceDescription,
  DatasheetReplaceBoth,
  DatasheetReplaceDescription,
  DatasheetReplaceProperty,
  DatasheetReplaceTitleDescriptionContent,
  DatasheetReplacePropertiesFromTable,
  DiagramReplaceBoth,
  DiagramReplaceConditionDetails,
  DiagramReplaceKeypoint,
  DiagramReplaceTitleDescriptionContent,
  GuideReplaceSpeakerNotes
  // MultipleChoiceReplace
]
