<template>
  <v-icon :color="color" class="mr-1" small>mdi-comment{{ replied ? "-multiple" : "" }}</v-icon>
</template>

<script>
export default {
  props: {
    state: String,
    replies: Array,
    ownerId: String
  },
  computed: {
    color() {
      if (this.state === "open") {
        return "error"
      } else if (this.state === "resolved") {
        return "warning"
      } else {
        return "success"
      }
    },
    replied() {
      return this.replies && this.replies.some(reply => !reply.deleted_at && reply.user_id !== this.ownerId)
    }
  }
}
</script>

<style></style>
