<template>
  <component v-bind:is="component" :mode="mode" :value="itemRevision" ref="editor" :view.sync="view" />
</template>
<script>
import { Extensions } from "plant-common"
import { ItemViewer } from "plant-common"
import guideItemViewer from "@/components/guideCommon/guide"
import guideTemplateItemViewer from "@/components/guideCommon/guide-template"
import lessonPlanViewer from "@/components/lessonPlan"
import interactiveSlideViewer from "@/components/interactiveSlide"
import interactiveTextViewer from "@/components/interactiveText"
import interactiveBookViewer from "@/components/interactiveBook"
import { mapState, mapActions, mapMutations } from "vuex"
import Loading from "../_layout/Loading"
import {
  STORE_CONTENT_NAME,
  STORE_MODULE_COMMENTS,
  STATE_CURRENT_LOCATION,
  STATE_ITEM_REVISION,
  MUTATION_SET_CURRENT_VIEW,
  ACTION_LOAD_DRAFT,
  ACTION_SAVE_CURRENT_LOCATION
} from "@/store_constants/content"
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { getType } from "../../../utils/dbTool"
import changeDocumentTitleAndIcon from "@/utils/changeDocumentTitleAndIcon.js"
import deepEqual from "@/utils/deepEqual"
export default {
  name: "DraftPreview",
  props: ["id"],
  components: {},
  data() {
    return {
      component: Loading,
      Extensions,
      view: false,
      mode: "view"
    }
  },
  computed: {
    ...mapState(STORE_MODULE_COMMENTS, {
      current_location: STATE_CURRENT_LOCATION
    }),
    ...mapState(STORE_CONTENT_NAME, {
      itemRevision: STATE_ITEM_REVISION
    }),
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    })
  },
  methods: {
    ...mapActions(STORE_CONTENT_NAME, [ACTION_LOAD_DRAFT]),
    ...mapActions(STORE_MODULE_COMMENTS, [ACTION_SAVE_CURRENT_LOCATION]),
    ...mapMutations(STORE_CONTENT_NAME, [MUTATION_SET_CURRENT_VIEW]),
    getType: getType,
    async goTo(custom_id) {
      if (getType(this.itemRevision) == "diagram") {
        window.open(`/content/${this.settings._id}/${encodeURIComponent(custom_id)}/latest`)
      }
    }
  },
  created() {
    this[ACTION_LOAD_DRAFT]({ item_id: this.id, mode: "view" }).then(() => {
      if (!this.itemRevision) return
      const item_revision_type = this.getType(this.itemRevision)
      // Guide and lesson plan contents placed inside app
      Extensions["guide"] = guideItemViewer
      Extensions["guideTemplate"] = guideTemplateItemViewer
      Extensions["lessonPlan"] = lessonPlanViewer
      Extensions["interactiveSlide"] = interactiveSlideViewer
      Extensions["interactiveText"] = interactiveTextViewer
      Extensions["interactiveBook"] = interactiveBookViewer
      if (Extensions[item_revision_type]) {
        switch (item_revision_type) {
          case "guide":
            this.component = guideItemViewer["Viewer"]
            break
          case "guideTemplate":
            this.component = guideTemplateItemViewer["Viewer"]
            break
          case "lessonPlan":
            this.component = lessonPlanViewer["Viewer"]
            break
          case "interactiveSlide":
            this.component = interactiveSlideViewer["Viewer"]
            break
          case "interactiveText":
            this.component = interactiveTextViewer["Viewer"]
            break
          case "interactiveBook":
            this.component = interactiveBookViewer["Viewer"]
            break
          default:
            this.component = ItemViewer
            break
        }
        var { title, custom_id, content_revision_type } = this.itemRevision.header.metadata
        changeDocumentTitleAndIcon(`*Preview [${custom_id}] ${title}`, Extensions[content_revision_type].icon)
      } else {
        this.component = {
          template: `<div>Editor for the extension ${item_revision_type} not found.</div>`
        }
      }
    })
    this[ACTION_SAVE_CURRENT_LOCATION](null)
  },
  watch: {
    view: {
      handler() {
        if (!this.view) return
        window.location.hash = JSON.stringify(this.view)
        this[MUTATION_SET_CURRENT_VIEW](this.view)
        this[ACTION_SAVE_CURRENT_LOCATION](this.view)
      }
    },
    current_location: {
      deep: true,
      handler() {
        if (deepEqual(this.current_location, this.view)) return
        this.view = this.current_location
      }
    }
  }
}
</script>
