<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <portal :to="`adaptiveLearningResult-toolbar-${value.id}`" v-if="mode == 'edit'"></portal>
      <template v-if="mode == 'edit'">
        <div class="">
          <template>
            <block-subcanvas :value="value" :blocks="value.data.blocks" :mode="mode" :blockVariables="blockVariables" />
            <div class="assessments-summary__data-table">
              <assessment-summary-data-table-vue :objectives="objectivesSummary"></assessment-summary-data-table-vue>
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <block-subcanvas :blocks="value.data.blocks" mode="view" :blockVariables="blockVariables" />
        <div class="assessments-summary__data-table">
          <assessment-summary-data-table-vue :objectives="objectivesSummary"></assessment-summary-data-table-vue>
        </div>
      </template>
    </template>
  </basic-block>
</template>

<script>
import AssessmentSummaryDataTableVue from "../../components/performance/modal/AssessmentSummaryDataTable.vue"
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockSubcanvas from "../_components/containers/BlockSubcanvas.vue"

export default {
  name: "AdaptiveLearningResult",
  inject: ["blockController", "lessonPlanController"],
  components: {
    BasicBlock,
    BlockSubcanvas,
    AssessmentSummaryDataTableVue
  },
  props: ["value", "mode"],
  data() {
    return {
      blockVariables: false
    }
  },
  created() {
    this.addVariables()
  },
  computed: {
    objectives() {
      return this.lessonPlanController.activityState?.performance
        ? this.lessonPlanController.activityState.performance
        : null
    },
    objectivesSummary() {
      if (!this.objectives) return {}

      let objectivesWithCompletionReason = JSON.parse(JSON.stringify(this.objectives))
      Object.values(objectivesWithCompletionReason).forEach(objectiveWCR => {
        if (objectiveWCR.section && objectiveWCR.section.id) {
          const slide = this.lessonPlanController.getSlideFromSlideId(objectiveWCR.section.id)
          const activityState = this.lessonPlanController.getSlideActivityState(slide)
          objectiveWCR.completionReason = activityState.completionReason
        } else {
          objectiveWCR.completionReason = null
        }
      })

      return objectivesWithCompletionReason
    }
  },
  methods: {
    addVariables() {
      let resultsVariables = {
        quizScore: 0,
        partialScore: {
          value: {},
          partialScoreHuman: "Results for each objective"
        },
        successScore: 0
      }
      if (this.lessonPlanController.activityState) {
        const slide = this.lessonPlanController.getSlideFromBlock(this.value)
        const quizId = this.lessonPlanController.getParentIdFromSlide(slide)
        const quiz = this.lessonPlanController.getSlideFromSlideId(quizId)
        resultsVariables = {
          quizScore: this.lessonPlanController.getQuizScore(quizId),
          partialScore: this.lessonPlanController.getPartialScore(quizId),
          successScore: parseInt(quiz.slideProperties.settings.success_score)
        }
      }
      this.blockVariables = [
        {
          name: "QuizScore",
          type: "number",
          value: `${Math.round((resultsVariables.quizScore + Number.EPSILON) * 100)} / 100`,
          title: "Quiz Score"
        },
        {
          name: "PartialScoreHuman",
          type: "string",
          value: resultsVariables.partialScore.partialScoreHuman,
          title: "Partial Score"
        },
        {
          name: "SuccessScore",
          type: "number",
          value: resultsVariables.successScore,
          title: "Success Score"
        }
      ]
    }
  }
}
</script>

<style scoped>
.lp-block-library-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.assessments-summary__data-table {
  position: absolute;
  width: 90%;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  max-height: 50%;
  overflow: auto;
  box-shadow: 0px 0px 20px 5px rgb(0 0 0 / 10%);
}
</style>
