export function getImageSize(src) {
	return new Promise(resolve => {
		let img = new Image();
		img.onload = function () {
			resolve({ width: img.width, height: img.height });
		};
		img.src = src;
	});
}

export function hexToRgb(hex) {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16)
			}
		: null;
}
