import {
  STORE_CONTENT_NAME,
  STATE_ITEM_REVISION,
  STATE_CURRENT_LOCATION,
  STATE_CURRENT_FILTER,
  STATE_IS_COMMENT,
  STATE_NAV_COMMENT,
  STATE_SELECTED_COMMENT,
  ACTION_SAVE_CURRENT_LOCATION,
  ACTION_UPDATE_COMMENT_STATE,
  ACTION_SEND_COMMENTS,
  ACTION_SEND_REPLY,
  ACTION_CURRENT_FILTER,
  MUTATION_SET_CURRENT_LOCATION,
  MUTATION_SET_REPLY_ITEM_DRAFT,
  MUTATION_SET_CURRENT_FILTER,
  MUTATION_SET_COMMENT_ITEM_DRAFT,
  MUTATION_SET_IS_COMMENT,
  MUTATION_SET_NAV_COMMENT,
  MUTATION_SET_SELECTED_COMMENT,
  GETTER_ACTIVE_USER,
  GETTER_COMMENTS,
  ACTION_SAVE_IS_COMMENT,
  STORE_MODULE_COMMENTS,
  ACTION_DELETE_COMMENT,
  ACTION_UPDATE_COMMENT,
  ACTION_UPDATE_NAV_COMMENT,
  ACTION_UPDATE_SELECTED_COMMENT
} from "@/store_constants/content"

import PtApiClient from "plant-api-client"
import { getContentId } from "@/utils/dbTool"

export default {
  name: STORE_MODULE_COMMENTS,
  namespaced: true,
  state: {
    [STATE_CURRENT_LOCATION]: null,
    [STATE_CURRENT_FILTER]: null,
    [STATE_IS_COMMENT]: false,
    [STATE_NAV_COMMENT]: false,
    [STATE_SELECTED_COMMENT]: false
  },
  getters: {
    [GETTER_ACTIVE_USER](state, getters, rootState) {
      return rootState.user.profile
    },
    [GETTER_COMMENTS](state, getters, rootState) {
      if (!rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]) return []
      return rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments
    }
  },
  mutations: {
    [MUTATION_SET_CURRENT_LOCATION](state, location) {
      state[STATE_CURRENT_LOCATION] = location
    },
    [MUTATION_SET_REPLY_ITEM_DRAFT](state, comment) {
      rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION].approval_process.comments.push(comment)
    },
    [MUTATION_SET_CURRENT_FILTER](state, filter) {
      state[STATE_CURRENT_FILTER] = filter
    },
    [MUTATION_SET_IS_COMMENT](state, value) {
      state[STATE_IS_COMMENT] = value
    },
    [MUTATION_SET_NAV_COMMENT](state, value) {
      state[STATE_NAV_COMMENT] = value
      state[STATE_SELECTED_COMMENT] = value
    },
    [MUTATION_SET_SELECTED_COMMENT](state, value) {
      state[STATE_SELECTED_COMMENT] = value
    }
  },
  actions: {
    [ACTION_SAVE_CURRENT_LOCATION]({ commit }, location) {
      commit(MUTATION_SET_CURRENT_LOCATION, location)
    },
    [ACTION_UPDATE_SELECTED_COMMENT]({ commit }, comment) {
      commit(MUTATION_SET_SELECTED_COMMENT, comment)
    },
    [ACTION_UPDATE_COMMENT_STATE]({ state, commit, dispatch, rootState }, data) {
      PtApiClient.updateDraftCommentState(
        getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
        { state: data.state },
        data.comment._id
      )
        .then(response => {
          commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
          dispatch("notifications/info", { message: "Comment " + data.state }, { root: true })
        })
        .catch(e => dispatch("notifications/error", { message: "Comment not " + data.state }, { root: true }))
    },
    [ACTION_SEND_COMMENTS]({ state, commit, dispatch, rootState }, comment) {
      comment.location = state.current_location
      PtApiClient.updateDraftComment(getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]), comment)
        .then(response => {
          commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
          dispatch("notifications/info", { message: "Comment added" }, { root: true })
        })
        .catch(e => {
          dispatch("notifications/error", { message: "Comment not added" }, { root: true })
        })
    },
    async [ACTION_SEND_REPLY]({ state, commit, dispatch, rootState }, { reply, id }) {
      await PtApiClient.replyDraftComment(getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]), reply, id)
        .then(response => {
          commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
          dispatch("notifications/info", { message: "Reply added" }, { root: true })
        })
        .catch(e => dispatch("notifications/error", { message: "Reply not added" }, { root: true }))
    },
    [ACTION_CURRENT_FILTER]({ commit }, filter) {
      commit(MUTATION_SET_CURRENT_FILTER, filter)
    },
    [ACTION_UPDATE_NAV_COMMENT]({ commit }, comment) {
      commit(MUTATION_SET_NAV_COMMENT, comment)
    },
    [ACTION_SAVE_IS_COMMENT]({ commit }, value) {
      commit(MUTATION_SET_IS_COMMENT, value)
    },
    [ACTION_DELETE_COMMENT]({ state, commit, dispatch, rootState }, { comment, parent }) {
      if (comment) {
        if (parent) {
          PtApiClient.softDeleteReplyComment(
            getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]),
            comment._id,
            parent._id
          )
            .then(response => {
              commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
              dispatch("notifications/info", { message: "Reply deleted" }, { root: true })
            })
            .catch(e =>
              dispatch("notifications/error", { message: "Reply not deleted. Something happened" }, { root: true })
            )
        } else {
          PtApiClient.softDeleteComment(getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]), comment._id)
            .then(response => {
              commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
              dispatch("notifications/info", { message: "Comment deleted" }, { root: true })
            })
            .catch(e =>
              dispatch("notifications/error", { message: "Comment not deleted. Something happened" }, { root: true })
            )
        }
      } else {
        dispatch("notifications/warning", { message: "Can not delete selected comment" }, { root: true })
      }
    },
    [ACTION_UPDATE_COMMENT]({ state, commit, dispatch, rootState }, { comment, parent }) {
      if (comment) {
        PtApiClient.updateComment(getContentId(rootState[STORE_CONTENT_NAME][STATE_ITEM_REVISION]), { comment, parent })
          .then(response => {
            commit(`${[STORE_CONTENT_NAME]}/${[MUTATION_SET_COMMENT_ITEM_DRAFT]}`, response.comments, { root: true })
            dispatch("notifications/info", { message: "Comment updated" }, { root: true })
          })
          .catch(e =>
            dispatch("notifications/error", { message: "Comment not updated. Something happened" }, { root: true })
          )
      } else {
        dispatch("notifications/warning", { message: "Can not update selected comment." }, { root: true })
      }
    }
  }
}
