import css from './pt-relay-coil.css';
import model from '../../prototypes/VALUE_BOOLEAN';
import CLICK_CONTEXTMENU from '../../mixins/CLICK_CONTEXTMENU';
import MOUSE_ENTER_LEAVE from '../../mixins/MOUSE_ENTER_LEAVE';

export default {
	model,
	css,
	mixins: [CLICK_CONTEXTMENU, MOUSE_ENTER_LEAVE]
};
