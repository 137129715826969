<template>
  <v-menu
    offset-y
    origin="center center"
    :nudge-bottom="10"
    transition="slide-y-transition"
  >
    <v-btn icon large flat slot="activator">
      <v-avatar color="teal" size="35">
        <v-icon color="white">mdi-account</v-icon>
      </v-avatar>
    </v-btn>
    <v-list class="pa-0">
      <v-list-tile :to="{ name: 'My Profile' }">
        <v-list-tile-action>
          <v-icon>mdi-account</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>My Profile</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile @click="logout().then(() => $router.push('/'))">
        <v-list-tile-action>
          <v-icon>mdi-logout</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>Logout</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapActions } from "vuex";

import { STORE_USER_NAME, ACTION_LOGOUT } from "@/store_constants/user";
export default {
  data() {
    return {
      items: [
        {
          icon: "account_circle",
          to: { name: "My Profile" },
          title: "Profile"
        },
        {
          icon: "exit_to_app",
          title: "Logout",
          click() {
            this.logout().then(() => this.$router.push("/"));
          }
        }
      ]
    };
  },
  methods: {
    ...mapActions(STORE_USER_NAME, {
      logout: ACTION_LOGOUT
    })
  }
};
</script>

