import DiagramReplace from "../global/Replace"
import { createRegExp } from "../../../../../../../utils/bulkChange"
export default {
  id: "diagram-replace-keypoint",
  title: "Replace Diagram Keypoints",
  description:
    'This operation replaces the "find" value (Case sensitive) with the "replace" value in all the diagram keypoints',
  contentType: "diagram",
  operation(content, props) {
    if (props && props.find && props.replace) {
      const searchRegExp = createRegExp(props.find)
      const replaceWith = props.replace
      if (content && content.body && content.body.contents && content.body.contents.shapes) {
        for (const property in content.body.contents.shapes) {
          if (content.body.contents.shapes[property].notes) {
            content.body.contents.shapes[property].notes = content.body.contents.shapes[property].notes
              .toString()
              .replace(searchRegExp, replaceWith)
          }
        }
      }
    }
    return content
  },
  OperationOptionsComponent: DiagramReplace
}
