<template>
  <div class="lp-audio-recorder">
    <v-tooltip bottom v-if="!isRecording">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="startRecord" fab dark large color="green">
          <v-icon dark>mdi-microphone</v-icon>
        </v-btn>
      </template>
      <span>Start recording</span>
    </v-tooltip>
    <v-tooltip bottom v-else>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click="stopRecord" fab dark large color="red" class="animate-flicker">
          <v-icon dark>mdi-record-circle-outline</v-icon>
        </v-btn>
      </template>
      <span>Stop recording</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  props: ["width", "height"],
  data() {
    return {
      audioRecorder: undefined,
      audioChunks: [],
      feedback: "",
      stream: undefined,
      isRecording: false
    }
  },
  methods: {
    startRecord() {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this.stream = stream
        // Initialize the media recorder object
        this.audioRecorder = new MediaRecorder(this.stream)

        // dataavailable event is fired when the recording is stopped
        this.audioRecorder.addEventListener("dataavailable", e => {
          this.audioChunks.push(e.data)
          this.stopStreams()
          const recordData = new Blob(this.audioChunks, { type: "audio/webm" })
          this.$emit("finishRecord", recordData)
        })
        this.audioChunks = []
        this.audioRecorder.start()
        this.isRecording = true
      })
    },
    stopRecord() {
      this.audioRecorder.stop()
    },
    stopStreams() {
      // Stop all stream tracks to hide "using microphone or camera" tab icon on the browser
      if (this.stream) {
        this.stream
          .getTracks() // get all tracks from the MediaStream
          .forEach(track => track.stop()) // stop each of them
      }
    }
  },
  beforeDestroy() {
    this.stopStreams()
  }
}
</script>

<style scoped>
.lp-audio-recorder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.animate-flicker {
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
}
</style>
