<template>
  <div>
    <interactive-text-designer
      :settingStyles="interactiveTextController.contents.settings.styles"
      :leftPanelsTop="leftPanelsTop"
      :leftPanelsBottom="leftPanelsBottom"
      :pageId="pageId">
    </interactive-text-designer>
  </div>
</template>

<script>
import InteractiveTextDesigner from "../interactiveText/components/InteractiveTextDesigner.vue"
import interactiveTextController from "../interactiveText/controllers/InteractiveTextController/index.js"
import OutlinePanel from "../interactiveText/components/OutlinePanel.vue"
import ObjectivePanel from "./components/ObjectivePanel.vue"
import Settings from "./components/Settings.vue"
import GlossaryPanel from "../commonInteractive/glossary/GlossaryPanel"
import glossaryController from "../commonInteractive/glossary/GlossaryController"

export default {
  name: "InteractiveBook",
  props: ["value", "view", "mode", "comments"],
  components: { InteractiveTextDesigner, Settings, GlossaryPanel },
  data() {
    return {
      interactiveTextController: interactiveTextController(this)
    }
  },
  computed: {
    pageId() {
      return this.interactiveTextController.selectedPage ? this.interactiveTextController.selectedPage.id : "0"
    },
    leftPanelsBottom() {
      return [
        {
          title: "Interactive Book Settings",
          icon: "mdi-cog",
          component: Settings,
          disabled: this.interactiveTextController.mode !== "edit"
        }
      ]
    },
    hasSomeGlossary() {
      return (
        (this.value.body.contents.glossary && this.value.body.contents.glossary.length) ||
        (this.value.body.contents.glossaryImported && this.value.body.contents.glossaryImported.length) ||
        (this.interactiveTextController &&
          this.interactiveTextController.glossariesFromItsFromPage &&
          this.interactiveTextController.glossariesFromItsFromPage.length)
      )
    },
    leftPanelsTop() {
      const panels = [
        {
          title: "Outline",
          icon: "mdi-file-tree",
          component: OutlinePanel
        }
      ]
      const glossaryPanel = {
        title: "Glossary",
        icon: "mdi-book-alphabet",
        component: GlossaryPanel,
        props: {
          mode: this.mode,
          value: this.value,
          controller: glossaryController(this)
        }
      }
      if (this.mode === "edit") {
        panels.push(glossaryPanel)
      } else if (this.mode !== "edit" && this.hasSomeGlossary) {
        panels.push(glossaryPanel)
      }

      if (this.interactiveTextController.ibObjectives.length) {
        panels.push({
          title: "Objectives",
          icon: "mdi-bullseye-arrow",
          component: ObjectivePanel
        })
      }

      return panels
    }
  },
  provide() {
    return {
      controller: this.interactiveTextController
    }
  }
}
</script>

<style>
.it-index {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.it-index-panel {
  position: absolute;
  bottom: 0;
}

.it-index-main {
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.it-page__add-page {
  flex: 1;
}

.it-page-wrapper {
  overflow: auto;
  padding: 20px 20px 40px;
}
</style>
