import Vue from "vue"
import { getCustomId, uploadFile } from "../../../utils";
class Attachment {
  constructor(name, file, url, attachment_type) {
    this._id = '' + Math.random()
    this.name = name
    this.file = file
    this.url = url
    this.attachment_type = attachment_type
    this.metadata = {}
  }
}
export default function (vm) {
  return new Vue({
    data() {
      return {

      };
    },
    created() {
      vm.$emit('select', getCustomId(vm.value))
    },
    computed: {
      file() {
        if (vm.value.body.attachments && vm.value.body.attachments.length > 0) {
          return vm.value.body.attachments[0];
        } else {
          return false;
        }
      },
      selectedTag() {
        return vm.selectedTag;
      }
    },
    methods: {
      removeDocument() {
        vm.value.body.attachments.splice(0)
        this.removeAllTags();
      },
      uploadDocument(file) {
        if (file) {
          let url = URL.createObjectURL(file);
          vm.value.body.attachments.push(new Attachment('Document', file, url, `.${file.type.replace(/(.*)\//g, '')}`))
        } else {
          uploadFile()
            .then(({ url, file }) => {
              vm.value.body.attachments.push(new Attachment('Document', file, url, ".pdf"))
            })
        }
      },
      autoLoadTags(tags) {
        this.checkEmptyTags()
        vm.value.body.contents.hotspots = tags
      },
      removeAllTags() {
        vm.value.body.contents.hotspots = []
      },
      confirmDeleteTag(deleteTag) {
        let indexToDelete = -1;
        vm.value.body.contents.hotspots.forEach((tag, index) => {
          if (tag._id == deleteTag._id) indexToDelete = index
        })
        vm.value.body.contents.hotspots.splice(indexToDelete, 1)
      },
      addCustomTag(tag) {
        this.checkEmptyTags()
        vm.value.body.contents.hotspots.push(tag)
      },
      checkEmptyTags() {
        if (vm.value.body.contents.hotspots == undefined) {
          this.$set(vm.value.body.contents, "hotspots", []);
        }
      }
    }
  })
}