<template>
  <v-layout row fill-height>
    <v-flex xs2 style="background: #eaeaea; border-right: 1px solid #ccc">
      <v-card :class="[viewOnly ? 'sidebar-templates-viewer' : 'sidebar-templates']" flat tile>
        <v-list class="py-0">
          <v-list-tile
            v-for="card_template in card_templates"
            :key="card_template.name"
            @click="showTemplate(card_template)"
            :class="[
              'list_template',
              selectedTemplate && selectedTemplate.name === card_template.name ? 'selected' : ''
            ]">
            <v-list-tile-content>
              <v-list-tile-title>
                {{ card_template.name.toUpperCase() }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card>
    </v-flex>
    <v-flex xs10>
      <v-card :class="[viewOnly ? 'container-templates-viewer' : 'container-templates']" flat tile>
        <v-card-text style="height: 100%">
          <div v-if="selectedTemplate">
            <div v-if="svgImage()" class="svg_wrapper">
              <img :src="svgImage().url" alt="template" style="width: 80%; border: 1px solid #eaeaea" />
            </div>
            <div v-else>No svg in this template</div>
          </div>
          <div v-else>Select a template</div>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: "GuideTemplateEditor",
  props: ["value", "selectedTemplate", "viewOnly"],
  components: {},
  data() {
    return {}
  },
  computed: {
    card_templates() {
      if (!this.value.body.contents.card_templates) return false
      return this.value.body.contents.card_templates
    }
  },
  methods: {
    showTemplate(template) {
      this.$emit("showTemplate", template)
    },
    svgImage() {
      if (!this.selectedTemplate) return false
      return this.value.body.attachments.find(e => e.name == this.selectedTemplate.name)
    },
    getFields(fields) {
      if (fields) {
        let text = "Fields: "
        Object.keys(fields).forEach(e => (text += "[" + e + "]"))
        return text
      }
    }
  }
}
</script>
<style scoped>
.svg_wrapper {
  height: calc(100% - 120px);
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.selected {
  background: #eaeaea;
}

.list_template {
  border-bottom: 1px solid #eaeaea;
}

.sidebar-templates {
  height: calc(100vh - 178px);
  overflow: auto;
}

.container-templates {
  height: calc(100vh - 178px);
}

.sidebar-templates-viewer {
  height: calc(100vh - 0px);
  overflow: auto;
}

.container-templates-viewer {
  height: calc(100vh - 0px);
}
</style>
