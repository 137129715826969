export default {
    model :{
        status: {
            type: "list",
            format: ["FLOW", "NO FLOW"],
            bind: {
                to: "attr",
                name: "data-status"
            },
            default: "NO FLOW"
    
        }
    },
    listeners:{
        click:true,
        contextmenu:true
    }
}