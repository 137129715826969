import Component from "./index.vue"

export default {
  name: "glossary",
  title: "Glossary",
  icon: require("./content-icon.svg"),
  category: "training",
  Viewer: Component,
  Editor: Component,
  Preview: Component
}
