<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="300px">
      <v-card>
        <v-card-title>
          <span class="headline">Rename template</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Template name" required v-model="templateName"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="cancelName">Close</v-btn>
          <v-btn color="blue darken-1" flat @click="saveName">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
export default {
    name:'ModalRename',
    props: ["dialog"],
    data(){
        return {
            templateName: ''
        }
    },
    methods:{
        saveName(){
            this.$emit('setName', this.templateName)
            this.templateName = ''
        },
        cancelName(){
            this.$emit('setDialog', false)
            this.templateName = ''
        }
    }
}
</script>