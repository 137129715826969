import Component from "./index.vue"
import Print from "./components/Print.vue"

export default {
  name: "interactiveBook",
  title: "Interactive Book",
  icon: require("plant-common/src/components/plant-item-viewer/interactive-book/content-icon.svg"),
  Viewer: Component,
  Editor: Component,
  Print,
  Preview: Print
}
