<template>
  <v-breadcrumbs :items="crumbs" divider="/">
    <v-breadcrumbs-item slot="item" slot-scope="{ item }" exact :to="item.to">
      {{ item.text }}
    </v-breadcrumbs-item>
  </v-breadcrumbs>
</template>
<script>
import { STORE_LIBRARY_NAME, STATE_PLANT_SETTINGS } from "@/store_constants/library"
import { mapState, mapActions } from "vuex"

const PATH_ROOT = "/"
export default {
  computed: {
    ...mapState(STORE_LIBRARY_NAME, {
      settings: STATE_PLANT_SETTINGS
    }),
    crumbs: function () {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let pathTo = ""
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        pathTo = pathTo + "/" + path
        breadcrumbArray.push({
          path: path,
          to: pathTo,
          text: this.$route.matched[idx + 1]
            ? this.$route.matched[idx + 1].meta.breadCrumb || (this.$route.matched[idx + 1].name && path.length > 18)
              ? `${this.$route.matched[idx + 1].name}`
              : this.$route.matched[idx + 1].name || path
            : ""
        })

        return breadcrumbArray
      }, [])
      if (this.settings._id && this.$route.params.library_id) {
        breadcrumbs.push({
          path: breadcrumbs[breadcrumbs.length - 1].path,
          text: this.settings.name,
          to: breadcrumbs[breadcrumbs.length - 1].to
        })
      }
      return breadcrumbs
    }
  }
}
</script>