var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"it-index"},[(_vm.isDraft)?_c('edit-toolbar',{attrs:{"editor":_vm.editor}}):_vm._e(),_c('div',{staticClass:"it-index-panel",style:({
			left: 0,
			width: (_vm.leftPanelSelected > -1 ? 480 : 80) + 'px',
			top: _vm.isDraft ? '50px' : 0
		})},[_c('left-panel',{attrs:{"leftPanelsTop":_vm.leftPanelsTop,"leftPanelsBottom":_vm.leftPanelsBottom,"leftPanelSelected":_vm.leftPanelSelected},on:{"update:leftPanelSelected":function($event){_vm.leftPanelSelected=$event},"update:left-panel-selected":function($event){_vm.leftPanelSelected=$event}}})],1),_c('main',{staticClass:"it-index-main",style:({
			left: (_vm.leftPanelSelected > -1 ? 480 : 80) + 'px',
			top: _vm.isDraft ? '50px' : 0
		}),attrs:{"id":_vm.styleTargetId}},[_c('interactive-text-canvas',{staticClass:"p-block-designer-canvas",attrs:{"settingStyles":_vm.settingStyles,"editor":_vm.editor}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }