<template>
  <v-card flat class="ma-2">
    <v-text-field
      :disabled="true"
      v-model="contentTitle"
      label="Title *"
      prepend-icon="mdi-alpha-t-box-outline"
      readonly
    ></v-text-field>
    <v-text-field
      :disabled="true"
      v-model="contentCustomId"
      label="Custom Id *"
      prepend-icon="mdi-tag-outline"
      readonly
    >
    </v-text-field>
    <v-text-field
      :disabled="true"
      v-model="contentFolder"
      label="Path"
      prepend-icon="mdi-folder-outline"
      readonly
    ></v-text-field>
  </v-card>
</template>

<script>
import { getTitle, getCustomId, getFolderName } from "../../../../utils/dbTool";
export default {
  props: {
    content: Object
  },
  computed: {
    contentTitle() {
      return getTitle(this.content);
    },
    contentCustomId() {
      return getCustomId(this.content);
    },
    contentFolder() {
      return getFolderName(this.content);
    }
  }
};
</script>

<style>
</style>