export const STORE_FLEET_NAME = "fleet"

export const STATE_LIBRARIES = "libraries"
export const STATE_ALL_LIBRARIES = "all_libraries"
export const STATE_USERS = "users"

export const MUTATION_SET_LIBRARIES = "setLibraries"
export const MUTATION_SET_LIBRARY = "setLibrary"
export const MUTATION_REMOVE_LIBRARY = "removeLibrary"
export const MUTATION_SET_USERS = "setUsers"
export const MUTATION_SET_USER = "setUser"
export const MUTATION_REMOVE_USER = "removeUser"

export const ACTION_GET_LIBRARIES = "getLibraries"
export const ACTION_DELETE_LIBRARY = "deleteLibrary"
export const ACTION_CREATE_LIBRARY = "createLibrary"
export const ACTION_UPDATE_LIBRARY = "updateLibrary"
export const ACTION_GET_USERS = "getUsers"
export const ACTION_DELETE_USER = "deleteUser"
export const ACTION_CREATE_USER = "createUser"
export const ACTION_UPDATE_USER = "updateUser"