<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="100%"
    height="100%"
    preserveAspectRatio="none"
    :viewBox="`0 0 ${width} ${height}`"
    class="lp-shape-style-svg"
  >
    <rect
      v-if="shape == 'rectangle'"
      :width="width"
      :height="height"
      rx="0"
      ry="0"
      :style="shapeStyle"
    ></rect>
    <ellipse
      v-if="shape == 'ellipse'"
      :rx="width / 2"
      :ry="height / 2"
      cx="50%"
      cy="50%"
      :style="shapeStyle"
    ></ellipse>
    <polygon
      v-if="shape == 'triangle'"
      :points="pointsTriangle"
      :style="shapeStyle"
    />
  </svg>
</template>

<script>
export default {
  props: ["shape", "width", "height", "shapeStyle"],
  computed: {
    style() {
      const { fill, fillOpacity, stroke, strokeWidth } = this.shapeStyle;
      return {
        fill,
        fillOpacity,
        stroke,
        strokeWidth
      };
    },
    pointsTriangle() {
      const { width, height } = this;
      return `${width / 2},0 ${width},${height} 0,${height}`;
    }
  }
};
</script>

<style>
/* To display svg without crop if stroke-width */
svg.lp-shape-style-svg {
  overflow: visible;
}
</style>