
import "./pt-piping-three-way-T-port.css"

export default {
  model: {
    "position": {
      type: "list",
      format: ["POSITION 1", "POSITION 2", "POSITION 3", "POSITION 4"],
      default: "POSITION 1"
    },
  
  },
  listeners: {
    mouseenter: true,
    mouseleave: true,
    click: true,
    contextmenu: true
  }
}
