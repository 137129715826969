import { BlockBasic } from "../_models/models.js"

/**
 * A image in any format allowed in the web.
 */
export default class BlockImage extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "image"
    this.size.width = 800
    this.size.height = 600
    this.data = {
      style: {
        normal: {
          borderRadius: 0
        }
      },
      image: null,
      cropped: null,
      cropped_data: {},
      overlay: "#00000000",
      fullScreen: {
        enable: false
      }
    }
  }
}
