import Content from "./index.vue"
import SettingsUsers from "./SettingsUsers.vue"
import SettingsMyProfile from "./SettingsMyProfile"
import SettingsLibraries from "./SettingsLibraries"
export default {
  path: "settings",
  component: Content,
  meta: {
    breadCrumb: "Control Panel"
  },
  children: [
    {
      name: "Settings",
      path: "",
      redirect: "profile",
    },
    {
      name: "Site Libraries",
      path: "libraries",
      component: SettingsLibraries,
    },
    {
      name: "My Profile",
      path: "profile",
      component: SettingsMyProfile,
    },
    {
      name: "Site Users",
      path: "users",
      component: SettingsUsers,
    }

  ]

}
