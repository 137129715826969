import PtApi from '../main';
import axios from 'axios';
import { injectTokenToVisoFiles } from './utils';

const interceptors = {
	request: [],
	response: []
};

const $http = axios.create({
	timeout: 600000
});

function isEditContentUrl(url) {
	const regex = new RegExp('/draft/[a-f0-9]{24}\\?mode=edit');
	return regex.test(url);
}

$http.interceptors.request.use(axios_config => {
	axios_config['baseURL'] = PtApi.baseURL;
	axios_config.headers['Authorization'] = `Bearer ` + PtApi.token;
	if (isEditContentUrl(axios_config['url'])) {
		axios_config.params = {
			timestamp: new Date().getTime() // Avoid cached repsonse when editing content
		};
	}
	return axios_config;
});

$http.interceptors.response.use(
	function (response) {
		return injectTokenToVisoFiles(response);
	},
	function (error) {
		let err = Object.assign({}, error);
		interceptors.response.forEach(f => f(err));
		return Promise.reject(err);
	}
);

export default $http;
