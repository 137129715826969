import parseBoolean from "./config/parseBoolean"

export default {
  computed: {
    value() {
      var input_values = this.$el.dataset.inputs
        .split(',')
        .map(input => {
          var [, id, prop] = input.split(/=|:/g)
      
          // If the object that shape is refering to does not exists, returns false
          if (!this.$model.hasOwnProperty(id)) return false
          // If the input exists but it is undefined, returns false
          else if (typeof this.$model[id][prop] === "undefined") return false
          // otherwise returns the value
          else return parseBoolean(this.$model[id][prop])
        })
      return input_values.reduce((prev, curr) => prev || curr, false)
    }
  }
}
