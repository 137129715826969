<template>
  <marker 
    :viewBox="`0 0 ${mw} ${mh}`" 
    :refX="mw" :refY="mh/2" 
    :markerWidth="mw" 
    :markerHeight="mh" 
    :orient="orient"
  > 
    <path
      :d="`M0,0 L0,${mh} L${mw},${mh/2} L0,0`"
      :style="`fill: ${stroke}`"
    />
  </marker>
</template>

<script>
export default {
  props: ["stroke", "markerSize", "orient"],
  computed: {
    mw() {
      return 5 * this.markerSize 
    },
    mh() {
      return 5 * this.markerSize
    }
  }
}
</script>