import { render, staticRenderFns } from "./AssessmentSummaryDataTable.vue?vue&type=template&id=8e0da1e4&scoped=true"
import script from "./AssessmentSummaryDataTable.vue?vue&type=script&lang=js"
export * from "./AssessmentSummaryDataTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8e0da1e4",
  null
  
)

export default component.exports