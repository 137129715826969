<template>
  <div v-if="selectedCard.content">
    <v-alert
      :value="true"
      color="warning"
      icon="mdi-alert-outline"
      class="ma-3"
      v-if="current_revision !== latest_revision">
      <h3>More recent revision available {{ current_revision }} - {{ latest_revision }}</h3>
      <p>
        The content shown is revision
        <b>{{ current_revision }}</b
        >, but there is a newer revision available
        <b>{{ latest_revision }}</b>
        <sup>
          <v-btn icon small dark @click="open()">
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
        </sup>
      </p>
      <v-btn v-if="canEdit" color="success" @click="guideController.updateCardToLatestContent(selectedCard)"
        >Update to latest revision</v-btn
      >
    </v-alert>
    <v-alert :value="true" color="success" class="ma-3" icon="mdi-check" outline v-else
      >Content shown is up to date</v-alert
    >
  </div>
  <div v-else>
    <v-alert :value="true" color="info" class="ma-3" icon="mdi-information-outline" outline
      >No content selected</v-alert
    >
  </div>
</template>

<script>
import { mapInjectedData } from "plant-common/src/utils"
import { mapState } from "vuex"
import { getContentId, getCustomId } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"
export default {
  name: "IsLatestWarning",
  inject: ["guideController"],
  components: {},
  computed: {
    // TODO: use PContent mode property instead of state
    ...mapState("content", ["canEdit"]),
    ...mapInjectedData("guideController", ["selectedCard"]),
    latest_revision() {
      if (this.selectedCard.content) {
        return this.guideController.latests_revisions[getCustomId(this.selectedCard.content)]
      } else {
        return false
      }
    },
    current_revision() {
      if (this.selectedCard.content) {
        var { major_revision, minor_revision } = this.selectedCard.content.header
        return `${major_revision}.${minor_revision}`
      } else {
        return false
      }
    }
  },
  methods: {
    updateToLatest() {
      this.selectedCard = this.guideController.getLatestContent(this.selectedCard.content)
    },
    open() {
      PServiceContent.openContent(getContentId(this.selectedCard.content), "tab")
    }
  }
}
</script>
