<template>
  <v-list subheader two-line>
    <v-subheader>Destination folder</v-subheader>
    <v-list-tile>
      <v-list-tile-action>
        <v-icon>mdi-folder-outline</v-icon>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-sub-title>Folder</v-list-tile-sub-title>
        <v-list-tile-title>{{ destination_folder.name }}</v-list-tile-title>
      </v-list-tile-content>
      <!-- <v-list-tile-action>
              <v-btn icon flat @click="">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </v-list-tile-action> -->
    </v-list-tile>
    <v-list-tile>
      <v-list-tile-action>
        <ContentTypeIcon :type="type" />
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-sub-title>Type</v-list-tile-sub-title>
        <v-list-tile-title>{{ type }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
    <v-subheader>Workflow Options</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-content>
        <v-select
          style="width: 100%"
          e2e-id="workflow_type"
          v-model="workflow"
          :disabled="true"
          :items="workflows"
          label="Workflow"
          item-text="label"
          item-value="value"
          prepend-icon="mdi-sitemap"
          append-icon="mdi-chevron-down"></v-select>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile v-for="contributor of contributors" :key="contributor.role">
      <user-selector
        :label="contributor.role"
        :disabled="true"
        :users-disabled="[]"
        @change="_id => (contributor.user_id = _id)"
        :value="contributor.user_id"></user-selector>
    </v-list-tile>
    <v-list-tile>
      <v-list-tile-action>
        <v-checkbox :value="approve" @change="v => $emit('update:approve', v || false)"></v-checkbox>
      </v-list-tile-action>

      <v-list-tile-content>
        <v-list-tile-title>Approve after upload</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>
  </v-list>
</template>

<script>
import UserSelector from "@/pages/workflow/components/UserSelector"
import { ContentTypeIcon } from "plant-common"
import WorkflowTypes from "../../../../workflow/constants/WorkflowTypes"

export default {
  props: {
    files_extension: String,
    destination_folder: Object,
    type: String,
    allowed_types: Array,
    workflow: String,
    approve: Boolean,
    contributors: Array
  },
  components: {
    UserSelector,
    ContentTypeIcon
  },
  data() {
    return {
      workflows: WorkflowTypes
    }
  }
}
</script>

<style>
</style>