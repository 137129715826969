import Viewer from "./Viewer"
import Editor from "./Editor"

export default {
    name: "course",
    title: "Course Plan",
    icon: require("./content-icon.svg"),
    category: "training",
    Viewer,
    Editor,
    Preview: Viewer
}