<script>
export default {
  methods: {
    goToNextSlideAfterCompleted() {
      if (this.activityState) {
        const slides = this.content.slides
        let slideId = slides[0].id
        if (this.activityState.completion) {
          slideId = this.getLastSlide()
        } else {
          let lastSlide = this.getNextSlideAfterCompleted(
            this.lessonPlanController.lessonPlanContents.slides,
            this.activityState.activities
          )
          if (lastSlide) {
            slideId = lastSlide
          }
        }
        this.lessonPlanController.selectSlideById(slideId)
        this.lessonPlanController.activityState = this.activityState
      }
    },
    getLastSlide() {
      return this.content.slides[this.content.slides.length - 1].id
    },
    getNextSlideAfterCompleted(slides, activities) {
      let lastSlide = null
      for (const slide of slides) {
        if (this.isNotCompleted(activities[slide.id])) {
          lastSlide = slide.id
          break
        } else if (slide.children) {
          lastSlide = this.getNextSlideAfterCompleted(slide.children, activities[slide.id].activities)
          if (lastSlide) break
        }
      }
      return lastSlide
    },
    isNotCompleted(activity) {
      return (
        activity &&
        !activity.completion &&
        (!activity.activities || !JSON.stringify(activity.activities).includes("true"))
      )
    }
  }
}
</script>