/**
 * Autostore strategy in the router.
 * 
 * This function will automatically register and unregister the if a store object is in the `route.meta` property. The store shall
 *  have a name
 */
import store from "../store"

export default function (to, from, next) {
    if (from.meta.store && from.meta.store.name) {
        store.unregisterModule(from.meta.store.name)
    }
    if (to.meta.store && to.meta.store.name) {
        store.registerModule(to.meta.store.name, to.meta.store)
    }
    return next()
}