/**
 * A recorded or upload audio.
 */
import { BlockBasic } from "../_models/models.js"

export default class BlockAudio extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "audio"
    this.size.width = 640
    this.size.height = 280
    this.data = {
      audio: null,
      typeAction: null,
      autoplay: false,
      delay: 0,
      controls: {
        color: "#ffffffff",
        size: 20
      },
      simpleUI: false
    }
  }
}
