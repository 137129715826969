import { } from "../../models/models"

import BlockAudio from "../audio/index.js"
import BlockVideo from "../video/index.js"
import BlockImage from "../image/index.js"
import BlockFlipCard from "../flipCard/index.js"
import BlockLibraryContent from "../libraryContent/index.js"
import BlockLibraryContentCustom from "../libraryContentCustom/index.js"
import BlockContentMultipleChoiceQuestion from "../contentMultipleChoiceQuestion/index.js"
import BlockFlashCard from "../flashCard/index.js"
import BlockButton from "../button/index.js"
import BlockTabs from "../tabs/index.js"
import BlockTextarea from "../textarea/index.js"
import BlockObjectives from "../objectives/index.js"
import BlockBreadcrumb from "../breadcrumb/index.js"
import BlockCarousel from "../carousel/index.js";
import BlockShapeLine from "../shapeLine/index.js";
import BlockShapeLineConnector from "../shapeLineConnector/index.js";
import BlockShapeArrow from "../shapeArrow/index.js";
import BlockShapeArrow90 from "../shapeArrow90/index.js";
import BlockShapeArrow180 from "../shapeArrow180/index.js";
import BlockShapeCallout from "../shapeCallout/index.js";
import BlockShapeDoubleArrow from "../shapeDoubleArrow/index.js";
import BlockShapeBrace from "../shapeBrace/index.js";
import BlockShapeRectangle from "../shapeRectangle/index.js";
import BlockShapeDiamond from "../shapeDiamond/index.js";
import BlockShapeOval from "../shapeOval/index.js";
import BlockShapeTriangle from "../shapeTriangle/index.js";

// import blocks from
export default [
  {
    id: "basic",
    name: "Basic",
    stencils: [
      BlockTextarea,
      BlockImage,
      BlockLibraryContent,
      BlockLibraryContentCustom,
      BlockContentMultipleChoiceQuestion,
      BlockFlashCard,
      BlockObjectives,
      BlockVideo,
      BlockAudio
    ]
  },
  {
    id: "shapes",
    name: "Shapes",
    stencils: [
      BlockShapeLine,
      BlockShapeLineConnector,
      BlockShapeArrow,
      BlockShapeArrow90,
      BlockShapeArrow180,
      BlockShapeDoubleArrow,
      BlockShapeCallout,
      BlockShapeBrace,
      BlockShapeRectangle,
      BlockShapeDiamond,
      BlockShapeOval,
      BlockShapeTriangle
    ]
  },
  {
    id: "interactive",
    name: "Interactive",
    stencils: [
      BlockButton,
      BlockFlipCard,
      BlockTabs,
      BlockCarousel
    ]
  },
  {
    id: "navigation",
    name: "Navigation",
    stencils: [BlockBreadcrumb]
  }
]
