<template>
	<span style="text-align: center; font-size: 21px">
		<v-btn
			color="primary"
			:disabled="disabled"
			block
			outline
			@click.stop="downloadXlsxTemplate(type)"
		>
			<v-icon left>mdi-download</v-icon>Download XLSX template
		</v-btn>
	</span>
</template>

<script>
import * as ContentBodies from "@/classes/content/index.js";
import XLSX from "xlsx";

export default {
	name: "BulkUploadDownloadTemplates",
	props: ["type", "referenceContent", "disabled"],
	computed: {
		ContentBodyConstructor() {
			return Object.values(ContentBodies).find(c => c.CONTENT_TYPE == this.type)
		}
	},
	methods: {
		downloadXlsxTemplate(type) {
			var wb = XLSX.utils.book_new();
			var config = { type }
			var columns = ["custom_id", "title", "custom_revision"]
			
			if (this.referenceContent) {
				config.$ref = this.referenceContent.$origin
			}
			if (typeof this.ContentBodyConstructor.BULK_UPLOAD.columns === "function") {
				columns = columns.concat(this.ContentBodyConstructor.BULK_UPLOAD.columns(this.referenceContent))
			} else {
				columns = columns.concat(this.ContentBodyConstructor.BULK_UPLOAD.columns)
			}
			var ws_import_data = XLSX.utils.aoa_to_sheet([columns]);
			XLSX.utils.book_append_sheet(wb, ws_import_data, "IMPORT-DATA");
			var ws_import_config = XLSX.utils.json_to_sheet([config]);
			ws_import_config["!protect"] = { password: "PLANT-IMPORT" };
			XLSX.utils.book_append_sheet(wb, ws_import_config, "IMPORT-CONFIG");
			XLSX.writeFile(wb, type + "import-template.xlsx");
		}
	}
};
</script>

<style>
</style>