import { $http } from "../config"
import { rereference } from "../$RefParser"
import { Content } from "./index"
import { v4 } from "uuid"

const Drafts = {
  /**
   * @returns {PLANT.DraftList}
   */
  getAllDrafts: () => $http(`/draft`).then(r => r.data),
  getWorkflowData: workflow => $http(`/draft/workflow/${workflow}`).then(r => r.data),
  createDraft: (contentId, from, commentsToImport) =>
    $http.post(`/draft/${contentId}/${from ? `?from=${from}` : ""}`, { commentsToImport: commentsToImport }).then(r => r.data),
  deleteDraft: contentId => $http.delete(`/draft/${contentId}`).then(r => r.data),
  /**
   * @param contentId {string}
   * @returns {PLANT.ItemRevisionDraft}
   */
  getDraftByContentId: (contentId, mode = "edit") => $http(`/draft/${encodeURIComponent(contentId)}${(mode ? "?mode=" + mode : "")}`).then(res => res.data),
  updateDraftContent: (contentId, formData) =>
    $http.put(`/draft/${contentId}/content`, formData, {
      headers: Object.assign(
        { "Content-Type": "multipart/form-data" },
        formData.getHeaders ? formData.getHeaders() : {}
      )
    }),
  updateDraftBody: async (contentId, draftBody) => {
    var XFormData
    if (typeof window === "undefined") {
      XFormData = require("form-data")
    } else {
      XFormData = FormData
    }
    let formData = new XFormData()
    draftBody.attachments.forEach(a => {
      if (a.file) {
        // for server files, if file is a string, it will be considered a server file path
        if (typeof a.file == "string") {
          a.file_key = a.file
        } else {
          var file_extension = a.file.name.split(".").pop()
          a.file_key = `${v4()}.${file_extension}`
          formData.append(a.file_key, a.file, a.file.name)
        }
        a.metadata = a.metadata || {}
      }
    })

    draftBody = await rereference(draftBody)
    Object.keys(draftBody).forEach(k => formData.append(k, JSON.stringify(draftBody[k] || {})))
    return $http
      .put(`/draft/${contentId}/content`, formData, {
        headers: Object.assign(
          { "Content-Type": "multipart/form-data" },
          formData.getHeaders ? formData.getHeaders() : {}
        )
      })
      .then(res => res.data)
      .catch(e => {
        return e
      })
  },
  uploadDraftFile: async (contentId, file) => {
    var XFormData
    if (typeof window === "undefined") {
      XFormData = require("form-data")
    } else {
      XFormData = FormData
    }
    let formData = new XFormData()
    var file_extension = file.name.split(".").pop()
    formData.append(`${v4()}.${file_extension}`, file)
    return $http
      .post(`/draft/${contentId}/files`, formData, {
        headers: Object.assign(
          { "Content-Type": "multipart/form-data" },
          formData.getHeaders ? formData.getHeaders() : {}
        )
      })
      .then(res => {
        return {
          url: res.data.url,
          filename: res.data.filename
        }
      })
      .catch(e => {
        console.log(e)
        return e
      })
  },
  /**
   @param contentId {string}
   @param command {string}
   @param name {string}
   @param [args] {object}
   @returns {import('./..').ItemRevisionDraft}
   */
  updateDraftWorkflow: (contentId, command, name, args) =>
    $http
      .put(`/draft/${contentId}/workflow`, {
        command,
        name,
        args
      })
      .then(r => r.data),
  updateDraftComment: (contentId, comment) => $http.post(`/draft/${contentId}/comment`, comment).then(r => r.data),
  updateDraftMetadata: (contentId, metadata) => $http.put(`/draft/${contentId}/metadata`, metadata).then(r => r.data),
  replyDraftComment: (contentId, comment, commentId) =>
    $http.post(`/draft/${contentId}/comment/${commentId}/reply`, comment).then(r => r.data),
  updateDraftCommentState: (contentId, state, commentId) =>
    $http.put(`/draft/${contentId}/comment/${commentId}/state`, state).then(r => r.data),
  updateComment: (contentId, data) => $http.put(`/draft/${contentId}/comment`, data).then(r => r.data),
  updateCommentsLocation: (contentId, commentsToUpdateInTransition) =>
    $http
      .put(`/draft/${contentId}/commentsLocation`, {
        data: commentsToUpdateInTransition
      })
      .then(r => {
        return r.data
      }),
  deleteComments: (contentId, commentsToDelete) =>
    $http.delete(`/draft/${contentId}/comment`, { data: commentsToDelete }).then(r => {
      return r.data
    }),
  softDeleteComment: (contentId, commentId) =>
    $http.delete(`/draft/${contentId}/comment/soft/${commentId}`).then(r => {
      return r.data
    }),
  softDeleteReplyComment: (contentId, commentId, parentId) =>
    $http.delete(`/draft/${contentId}/comment/soft/${commentId}/reply/${parentId}`).then(r => {
      return r.data
    }),
  /**
   * @param fromId {string}
   * @param custom_id {string}
   * @param title {string}
   * @param folder_id {string}
   * @param item_revision_type {string}
   * @param is_item_revision {boolean}
   * @returns {PLANT.DraftResponse}
   */
  createDraftFromItem: async (
    fromId,
    custom_id,
    title,
    folder_id,
    item_revision_type = "guide",
    is_item_revision = false
  ) => {
    let newDraft = await Content.createContent(title, custom_id, folder_id, item_revision_type)
    // Get fromID item
    let fromItemDraft
    if (!is_item_revision) {
      fromItemDraft = await Drafts.getDraftByContentId(fromId)
    } else {
      fromItemDraft = await Content.getContentByContentId(fromId)
    }
    function getContentId(itemRevision) {
      if (!itemRevision) return false
      return itemRevision.header && itemRevision.header.metadata && itemRevision.header.metadata.content_id
        ? itemRevision.header.metadata.content_id
        : itemRevision.item && itemRevision.item.item_id
          ? itemRevision.item.item_id
          : undefined
    }
    // Update new draft body with fromId body updateDraftContent: (contentId, formData)
    let contentId = getContentId(newDraft.itemRevisionDraft)
    let updatedDraft = await Drafts.updateDraftBody(contentId, fromItemDraft.body)
    return updatedDraft
  },
  getDraftApprovalProcess: contentId => $http.get(`/draft/${contentId}/approvalProcess`).then(r => r.data),
  copyFiles: (fromId, destinationId, files) =>
    $http.post(`/draft/${fromId}/copyFilesTo/${destinationId}`, { files }).then(result => result)
}

export default Drafts
