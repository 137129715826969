<template>
  <div :style="style">
    <template v-if="state == 'not-selected' && mode === 'edit'">
      <svg
        class="line-overlay movable-available"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        style="overflow: overlay; z-index: 1000"
        :width="value.size.width"
        :height="h"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`"
        @click="$parent.$emit('blockClick')"></svg>
    </template>
    <template v-else-if="mode === 'edit'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        @dblclick="$parent.$emit('blockDblClick')"
        @mousedown="move"
        class="line-overlay movable"
        style="overflow: overlay; z-index: 1000"
        :width="value.size.width"
        :height="h"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`"></svg>
      <div
        @mousedown="moveControl($event, 'begin')"
        class="movable-control begin"
        :class="{ disabled: value.protection.resize }"
        :style="`
          position: absolute; 
          left: ${block.data.end.x > block.data.begin.x ? 0 : w}px;
          top: ${block.data.end.y >= block.data.begin.y ? (h > 15 ? 0 : 10) : h}px;
        `" />
      <div
        @mousedown="moveControl($event, 'end')"
        class="movable-control end"
        :class="{ disabled: value.protection.resize }"
        :style="`
          position: absolute;
          left: ${block.data.end.x > block.data.begin.x ? w : 0}px;
          top: ${block.data.end.y > block.data.begin.y ? (h < 15 ? 15 : h) : 0}px;
        `" />
    </template>
    <slot name="control-handlers"></slot>
    <div
      :class="`${state == 'edit-content' && !value.protection.data ? 'edit-text' : ''}`"
      :id="block.id"
      :style="{ ...animationStyle, width: '100%', position: 'absolute', top: '0', zIndex: -1 }">
      <slot name="main"></slot>
    </div>
  </div>
</template>

<script>
import "animate.css"
import "../../_css/animateOverwritten.css"
import _ from "lodash"

export default {
  name: "LineBlock",
  inject: ["blockController"],
  props: {
    value: Object,
    mode: String
  },
  data() {
    return {
      lastPositionX: 0,
      lastPositionY: 0,
      state: "not-selected"
    }
  },
  created() {
    // Observe changes on block selection and change state accordingly
    if (!_.isEmpty(this.blockController)) {
      this.blockController.$on(`state-changed:${this.value.id}`, state => {
        this.state = state
        this.$emit("update:state", state)
      })
    }
  },
  computed: {
    style() {
      return {
        position: `absolute`,
        width: `${this.w}px`,
        height: `${this.h}px`,
        transform: `translate(${this.block.position.x}px,${this.block.position.y}px)`
      }
    },
    h() {
      return this.block.size.height
    },
    w() {
      return this.block.size.width
    },
    block() {
      return this.$parent.value
    },
    parent() {
      return this.$parent.$parent.parent
    },
    gridSize() {
      return this.blockController.gridSize || [1, 1]
    },
    gridX() {
      return this.gridSize[0]
    },
    gridY() {
      return this.gridSize[1]
    },
    isAnimationNone() {
      return this.value.animations && this.value.animations.length > 0 && this.value.animations[0].name === "none"
    },
    animationStyle() {
      if (!this.value.animations || this.isAnimationNone || this.mode === "edit") return {}
      return {
        animation: this.value.animations.map(a => a.name).join(","),
        "animation-duration": this.value.animations.map(a => `${a.duration}s`).join(","),
        "animation-delay": this.value.animations.map(a => `${parseFloat(a.delay) + this.inheritDelay}s`).join(","),
        "animation-fill-mode": "both"
      }
    },
		inheritDelay() {
			return this.$parent.$attrs.subcanvasDelay ? this.$parent.$attrs.subcanvasDelay : 0;
		}
  },
  methods: {
    move(evt) {
      if (!this.state == "selected" || this.value.protection.move) return
      evt.preventDefault()
      evt.target.style.willChange = "transform"
      evt.target.dataset.blockState = "move"
      var lastPositionX = evt.clientX
      var lastPositionY = evt.clientY
      var lastBlockPositionX = this.block.position.x
      var lastBlockPositionY = this.block.position.y
      var initialBlockPosition = { x: this.block.position.x, y: this.block.position.y }
      var { top, bottom, left, right } = this.parent.getBoundingClientRect()
      var stopMove = evt => {
        evt.preventDefault()
        evt.target.style.willChange = "auto"
        evt.target.dataset.blockState = "normal"

        // Update begin and end based on position
        var delta = {
          x: this.block.position.x - initialBlockPosition.x,
          y: this.block.position.y - initialBlockPosition.y
        }
        this.block.data.begin.x += delta.x
        this.block.data.begin.y += delta.y
        this.block.data.end.x += delta.x
        this.block.data.end.y += delta.y
        window.removeEventListener("mousemove", mousemoveEventHandler)
      }
      var mousemoveEventHandler = evt => {
        evt.preventDefault()
        var { clientX, clientY } = evt
        if (clientX < left || clientX > right || clientY < top || clientY > bottom) {
          stopMove(evt)
          return
        }
        lastBlockPositionX += (clientX - lastPositionX) / this.blockController.scale
        lastBlockPositionY += (clientY - lastPositionY) / this.blockController.scale
        this.block.position.x = Math.round(lastBlockPositionX / this.gridX) * this.gridX
        this.block.position.y = Math.round(lastBlockPositionY / this.gridY) * this.gridY
        lastPositionX = clientX
        lastPositionY = clientY
      }
      window.addEventListener("mouseup", stopMove, { once: true })
      window.addEventListener("mousemove", mousemoveEventHandler)
    },
    moveControl(evt, id) {
      if (!this.state == "selected" || this.value.protection.move) return
      evt.preventDefault()
      evt.target.style.willChange = "transform"
      evt.target.dataset.blockState = "move"
      var lastPositionX = evt.clientX
      var lastPositionY = evt.clientY
      var lastBlockPositionX = id === "begin" ? this.block.data.begin.x : this.block.data.end.x
      var lastBlockPositionY = id === "begin" ? this.block.data.begin.y : this.block.data.end.y
      var stopMoveControl = evt => {
        evt.preventDefault()
        evt.target.style.willChange = "auto"
        evt.target.dataset.blockState = "normal"
        window.removeEventListener("mousemove", mousemoveEventHandler)
      }
      var mousemoveEventHandler = evt => {
        evt.preventDefault()
        var { clientX, clientY } = evt
        lastBlockPositionX += (clientX - lastPositionX) / this.blockController.scale
        lastBlockPositionY += (clientY - lastPositionY) / this.blockController.scale
        const newPositionX = Math.round(lastBlockPositionX / this.gridX) * this.gridX
        const newPositionY = Math.round(lastBlockPositionY / this.gridY) * this.gridY
        if (id === "begin") {
          this.block.data.begin.x = newPositionX
          this.block.data.begin.y = newPositionY
        } else {
          this.block.data.end.x = newPositionX
          this.block.data.end.y = newPositionY
        }
        this.block.position.x = Math.min(this.block.data.begin.x, this.block.data.end.x)
        this.block.position.y = Math.min(this.block.data.begin.y, this.block.data.end.y)
        this.block.size.width = Math.max(1, Math.abs(this.block.data.begin.x - this.block.data.end.x))
        this.block.size.height = Math.max(1, Math.abs(this.block.data.begin.y - this.block.data.end.y))

        lastPositionX = clientX
        lastPositionY = clientY
      }
      window.addEventListener("mouseup", stopMoveControl)
      window.addEventListener("mousemove", mousemoveEventHandler)
    }
  }
}
</script>

<style scoped>
.line-overlay {
  stroke-width: 50px;
  stroke: rgba(255, 0, 0, 0);
}
.line-overlay:hover {
  cursor: pointer;
  /*stroke: rgba(255, 0, 0, 1);*/
}

.edit-text {
  position: absolute;
  width: 100%;
  height: 100%;
  outline: dashed 2px gray;
  cursor: text;
}
.movable {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: move;
  background: rgba(255, 255, 255, 0);
}
.movable.disabled {
  cursor: default;
}
.movable.edit-disabled {
  cursor: not-allowed;
}
.movable:hover {
  outline-width: 2px;
}
.movable[data-block-state="move"] {
  cursor: move;
  outline-style: dashed;
}

.movable[data-block-state="move"] ~ .movable-control {
  display: none;
}

.movable.selected {
  outline: solid 1px #4af;
}
.movable-control {
  will-change: transform;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-sizing: border-box;
  background: #4af;
  border: 2px solid #fff;
  z-index: 10;
  margin-top: -15px;
  margin-left: -15px;
}

.movable-control:hover {
  background: orange;
}
.movable-control:active {
  background: red;
}

.movable-control.disabled {
  background: #ccc;
  cursor: default;
}
</style>
