<template>
  <v-card>
    <v-card-title class="lp-toolbar-dialog-card-title subheading grey lighten-3 pt-1 pb-1 pl-4" primary-title>
      <span>{{ title }}</span>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          flat
          @click="$emit('closeMenu')"
          aria-label="Close dialog"
          class="lp-toolbar-dialog-card-button"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-actions>
    </v-card-title>

    <v-card-text>
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["title"]
}
</script>

<style>
.lp-toolbar-dialog-card-title {
  display: flex;
  justify-content: space-between;
}
.lp-toolbar-dialog-card-button.v-btn {
  min-width: 10px;
  padding: 0 4px;
}
</style>