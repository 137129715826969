<template>
  <basic-block></basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"

export default {
  name: "BlockTemplate",
  components: {
    BasicBlock
  },
  props: ["value"],
  data() {
    return {}
  },
  computed: {},
  watch: {}
}
</script>


