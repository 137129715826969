<template>
	<v-container grid-list-md 
		style="overflow-y: auto;max-height: calc(100vh - 180px);max-width: 100%;">
		 <h3 class="subheading lighten-4 mb-3">Libraries</h3>
		<v-layout row wrap class="mb-4">
			<v-flex xs12 sm6 md4 lg3 v-for="library in libraries" :key="library._id">
				<v-hover>
					<v-card
						slot-scope="{ hover }"
						height="100%"
						@click="$emit('select', library)"
						:class="`elevation-${hover ? 4 : 1}`"
						style="cursor:pointer;"
						e2e-attr="plant-library"
					>
						<card-library :name="library.name" />
					</v-card>
				</v-hover>
			</v-flex>
		</v-layout>
		
	</v-container>
</template>

<script>
import CardLibrary from "./CardLibrary.vue"

export default {
	props: ["libraries", "view"],
	components: { CardLibrary },

};
</script>