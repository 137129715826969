import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeOval"
    this.size.width = 600
    this.size.height = 400
    this.data = {
      controls:{
        radius: 0
      },
      style: {
        fill: "#000000ff",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
