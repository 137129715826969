
export default {
    "model": {
        "level": {
            type:"string",
            default: "50%",
            bind(elm, prop, newValue){
                document.getElementById(`${elm.id}-fill`).children[0].attributes.offset.value = newValue
                document.getElementById(`${elm.id}-fill`).children[1].attributes.offset.value = newValue
            }
        }
    },
    listeners: {
        mouseenter: true,
        mouseleave: true,
        click: true,
        contextmenu: true
    },
    hooks: {
        beforeMount(elm){
            try{
                elm.insertAdjacentHTML( 'beforeBegin', `<linearGradient id="${elm.id}-fill" x1="0" x2="0" y1="1" y2="0">
                <stop offset="50%" stop-color="${elm.dataset["fillColorBottom"]}" />
                <stop offset="50%" stop-color="${elm.dataset["fillColorTop"]}"  stop-opacity="1"/>
                <stop offset="100%" stop-color="${elm.dataset["fillColorTop"]}" />
                </linearGradient>` )
                elm.querySelectorAll(".pt-piping-tank__body > *").forEach(part=> part.style.fill=`url(#${elm.id}-fill)`)
            } catch(e){
                console.log('error on ', elm)

            }
        }
    }
}
