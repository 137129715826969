<template>
  <div>
    <is-latest-warning />
    <v-container fluid>
      <v-layout wrap>
        <v-flex xs12 class="px-3">
          <v-textarea
            v-if="guideController.selectedCard"
            class="ma-2"
            placeholder="Speaker's notes"
            v-model="guideController.selectedCard.notes"
            :readonly="readonly"></v-textarea>
        </v-flex>
        <v-flex xs12 class="px-3 wrapper_enabling">
          <v-text-field v-if="!readonly" label="Internal link name" v-model="section.internal_link_name"></v-text-field>
          <span v-if="objectives.length">
            <div v-for="(objective, index) in objectives" :key="index">
              <v-card tile flat>
                <v-card-title>
                  <span v-if="objective.custom_id" class="grey--text">{{ objective.custom_id }} -</span>
                  <span>{{ objective.title }}</span>
                </v-card-title>
              </v-card>
            </div>
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapInjectedData } from "plant-common/src/utils"
import IsLatestWarning from "../Viewer/IsLatestWarning.vue"
import { getContentId } from "../../../../utils/dbTool"
export default {
  name: "GuideViewerFooter",
  props: ["readonly"],
  components: { IsLatestWarning },
  inject: ["guideController"],
  created() {
    if (!this.section.internal_link_name) {
      this.$set(this.section, "internal_link_name", "")
    }
  },
  data() {
    return {
      data: []
    }
  },
  computed: {
    ...mapInjectedData("guideController", ["guide", "selectedCard"]),
    section() {
      return this.guideController.selectedCard
    },
    enabling_objectives() {
      if (this.section && this.section.enabling_objectives) return this.section.enabling_objectives
      else return []
    },
    learning_objectives() {
      if (
        this.guide.learning_objective &&
        this.guide.learning_objective.body &&
        this.guide.learning_objective.body.contents &&
        this.guide.learning_objective.body.contents.enabling
      ) {
        return this.guide.learning_objective.body.contents.enabling
      } else {
        return []
      }
    },
    objectives() {
      let data = []
      this.learning_objectives.forEach(e => {
        this.enabling_objectives.forEach(k => {
          if (k === e.id) {
            data.push(e)
          }
        })
      })
      return data
    }
  }
}
</script>

<style scoped>
.wrapper_enabling {
  max-height: 125px;
  overflow: auto;
}
</style>