<template>
  <component :is="component" />
</template>

<script>
import ViewerCover from "./ViewerCover"
import ViewerSection from "./ViewerSection"
import ViewerTemplate from "./ViewerTemplate"
import ViewerCard from "./ViewerCard"
import ViewerMindmap from "./ViewerMindmap"
import Viewer$RefError from "./Viewer$RefError"

const ViewerComponentMap = {
  card: ViewerCard,
  section: ViewerSection,
  cover: ViewerCover,
  mindmap: ViewerMindmap,
  "card-template": ViewerTemplate
}

export default {
  name: "GuideCardOptions",
  inject: ["guideController"],
  data() {
    return { component: { template: "loading" } }
  },
  computed: {
    selectedCard() {
      return this.guideController.selectedCard
    }
  },
  watch: {
    selectedCard: {
      immediate: true,
      handler() {
        this.component = {
          template: `<div>Loading</div>`
        }
        this.$nextTick(() => {
          if (this.selectedCard.content && this.selectedCard.content.$error) {
            this.component = Viewer$RefError
          } else if (Object.keys(ViewerComponentMap).includes(this.selectedCard.type)) {
            this.component = ViewerComponentMap[this.selectedCard.type]
          } else
            this.component = {
              template: `<div></div>`
            }
        })
      }
    }
  }
}
</script>

