<template>
 <pre>
     {{selectedCard}}
 </pre>
</template>

<script>
import { ItemViewer } from "plant-common";
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
export default {
  name: "GuideViewerCard",
  inject: ["guideController"],
  components: {
    ItemViewer
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "currentViewCard"])
  },
  methods: {
    ...mapInjectedMethods("guideController", ["selectContent"])
  }
};
</script>
