<template>
  <fragment>
    <portal-target v-if="value" :name="`flipcard-toolbar-${value.id}`"></portal-target>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment"

export default {
  props: ["value"],
  components: {
    Fragment
  }
}
</script>
