<template>
</template>
<script>

import { mapGetters, mapActions, mapState }from "vuex";
import {
  STORE_MODULE_COMMENTS,
  STATE_IS_COMMENT,
} from "../../../../store_constants/content";

export default {
  name: "shortcutsGuideViewer",
  inject: ["guideController"],
  methods: {
    keyDownTextField(e) {
      if(!this.isComment){
        switch (e.keyCode) {
          case 33: // page up
          case 37: // left
          case 38: // up
            this.guideController.decrement();
            break;
          case 34: // pagae down
          case 39: // right
          case 40: // down
            this.guideController.increment();
            break;
          default:
            return; // exit this handler for other keys
        }
      }
    }
  },
  computed:{
  ...mapState(STORE_MODULE_COMMENTS, {
      isComment: STATE_IS_COMMENT,
    })
  },
  mounted() {
    document.addEventListener("keydown", this.keyDownTextField, false);
  },
  destroyed() {
    document.removeEventListener("keydown", this.keyDownTextField);
  }
};
</script>

