<template>
  <basic-block :value="value" :mode="mode">
    <template v-slot:main>
      <div class="lp-block-audio">
        <div class="lp-block-audio-edit" v-if="mode == 'edit'">
          <!-- No selection -->
          <div v-if="value.data.typeAction == null" class="lp-block-audio-upload-record">
            <block-placeholder
              @click.native="setUploadAction"
              img="add_audio"
              icon="mdi-microphone"
              text="Upload Audio" />
            <block-placeholder
              @click.native="setRecordAction"
              img="record_audio"
              icon="mdi-record-circle-outline"
              text="Record Audio" />
          </div>
          <div v-else class="lp-block-audio-record-upload">
            <!-- Selection record -->
            <block-audio-record :value="value" v-if="value.data.typeAction == 'record'" />
            <!-- Selection upload -->
            <block-video-audio-upload :value="value" :video="false" v-if="value.data.typeAction == 'upload'" />
          </div>
        </div>
        <!-- View mode -->
        <div class="lp-block-audio-view" v-else>
          <block-audio-viewer :value="value" mode="view" />
        </div>
      </div>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import BlockAudioRecord from "./BlockAudioRecord.vue"
import BlockVideoAudioUpload from "../video/BlockVideoAudioUpload.vue"
import BlockAudioViewer from "./BlockAudioViewer"
import BlockPlaceholder from "../_components/placeholders/BlockPlaceholder.vue"

export default {
  inject: ["blockController"],
  name: "BlockAudio",
  components: {
    BasicBlock,
    BlockAudioRecord,
    BlockVideoAudioUpload,
    BlockAudioViewer,
    BlockPlaceholder
  },
  props: ["value", "mode"],
  methods: {
    setUploadAction() {
      this.$set(this.value.data, "typeAction", "upload")
    },
    setRecordAction() {
      this.$set(this.value.data, "typeAction", "record")
    },
    setSimpleUI() {
      const isBig = this.value.data.controls.size === 25
      this.value.size.width = isBig ? 100 : 85
      this.value.size.height = isBig ? 75 : 60
      this.value.protection.resize = true
    }
  },
  watch: {
    "value.data.simpleUI": {
      handler() {
        if (this.value.data.simpleUI) {
          this.setSimpleUI()
        }
      }
    },
    "value.data.controls.size": {
      handler() {
        if (this.value.data.simpleUI) {
          this.setSimpleUI()
        }
      }
    }
  }
}
</script>

<style>
.lp-block-audio {
  height: 100%;
}
.lp-block-audio-edit {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lp-block-audio-upload-record {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}
.lp-block-audio-record-upload,
.lp-block-audio-record-upload div {
  width: 100%;
  height: 100%;
}
.lp-block-audio .vjs-record .vjs-device-button.vjs-control {
  top: 80% !important;
  width: 2em !important;
  height: 1.4em !important;
  margin-left: -1em !important;
}
.lp-block-audio .vjs-record .vjs-device-button.vjs-control:before {
  font-size: 1em !important;
}
.lp-block-audio audio:focus {
  outline: none;
}
.lp-configure-slide-dialog-window-card .lp-block-audio-view {
  pointer-events: none;
}
</style>
