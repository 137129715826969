<template>
  <span :id="buttonParentId" class="lp-icon-button">
    <v-icon v-if="value.data.icon && value.data.icon != 'none'" class="lp-icon-button-icon">{{
      value.data.icon
    }}</v-icon>
    <editable-text v-model="value.data.text" :contenteditable="state == 'edit-content'" :parent-id="buttonParentId" />
  </span>
</template>

<script>
import EditableText from "./BlockEditEditableText.vue"

export default {
  inject: ["blockController"],
  props: ["value", "state"],
  components: { EditableText },
  computed: {
    buttonParentId() {
      return 'iconButton-' + this.value?.id
    }
  }
}
</script>

<style>
.lp-icon-button {
  display: flex;
  text-align: var(--text-align);
  justify-content: var(--justify-content);
  align-items: center;
  width: 100%;
  height: 100%;
}
.lp-icon-button-icon {
  color: inherit !important;
  margin-right: 10px;
  width: calc(var(--text-scale) / 100 * 40px) !important;
}
.lp-icon-button,
.lp-icon-button-icon {
  font-size: calc(var(--text-scale) / 100 * 40px) !important;
}
</style>