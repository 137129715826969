<template>
  <p-dialog
    :value="selectedUpgrade ? true : false"
    @input="selectUpgrade(false)"
    min-height="650px"
    max-height="800px"
    max-width="800px"
  >
    <template #toolbar>
      <v-toolbar-title> <v-icon left>mdi-update</v-icon>Update Content </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="selectUpgrade(false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <v-toolbar flat class="transparent">
      <v-toolbar-items class="hidden-sm-and-down">
        <content-type-icon :type="selectedUpgrade.dependency.header.content_revision_type" />
      </v-toolbar-items>
      <v-toolbar-title>
        <span class="caption">
          <v-chip small label dark>
            <v-icon small>mdi-library-shelves</v-icon>
            {{ selectedUpgrade.dependency.library.name }}
          </v-chip>
          <v-chip small label dark>
            <v-icon small left>mdi-tag</v-icon>
            {{ selectedUpgrade.dependency.header.custom_id }}
          </v-chip>
        </span>
        <br />
        {{ selectedUpgrade.dependency.header.metadata.title }} (Rev
        {{ selectedUpgrade.dependency.header.major_revision }}.{{ selectedUpgrade.dependency.header.minor_revision }})
      </v-toolbar-title>
    </v-toolbar>
    <h3 class="headline mt-4 mb-2">Outdated Dependents</h3>
    <p>
      The following contents use revision
      {{ selectedUpgrade.dependency.header.major_revision }}.{{ selectedUpgrade.dependency.header.minor_revision }}
      of
      <v-chip small label dark>
        <v-icon small left>mdi-tag</v-icon>
        {{ selectedUpgrade.dependency.header.custom_id }}
      </v-chip>
      while the latest revision is
      {{ selectedUpgrade.dependency.latest.major_revision }}.{{ selectedUpgrade.dependency.latest.minor_revision }}.
    </p>
    <v-data-table :headers="headers" :items="selectedUpgrade.outdated">
      <template v-slot:items="props">
        <slot>
          <tr style="cursor: pointer">
            <td>
              <v-icon small left>mdi-library-shelves</v-icon>
              {{ props.item.library.name }}
            </td>
            <td>
              <content-type-icon :type="props.item.header.content_revision_type" />
            </td>
            <td>{{ props.item.latest.custom_id }}</td>
            <td>{{ props.item.latest.metadata.title }}</td>
            <td>{{ props.item.header.major_revision }}.{{ props.item.header.minor_revision }}</td>
          </tr>
        </slot>
      </template>
    </v-data-table>
    <template #actions>
      <v-btn color="success" outline @click="updateSelectedDependents(approve)">
        <v-icon left>mdi-update</v-icon>
        Update ({{ selectedUpgrade.outdated.length }})
      </v-btn>
      <v-checkbox v-model="approve" class="ml-2" label="Approve after update (minor revision)"></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn @click="selectUpgrade(false)" flat color="red">cancel</v-btn>
    </template>
  </p-dialog>
</template>

<script>
import { PDialog, ContentTypeIcon } from "plant-common"
import { selectedUpgrade, selectUpgrade, updateSelectedDependents } from "../store"
import ContentCardSmall from "./ContentCardSmall.vue"

export default {
  props: ["header"],
  components: { PDialog, ContentCardSmall, ContentTypeIcon, ContentTypeIcon },
  data() {
    return {
      approve: false,
      headers: [
        {
          text: "library",
          value: "library.name",
          width: "30px"
        },
        {
          text: "Type",
          value: "header.content_revision_type",
          width: "30px"
        },
        {
          text: "Custom Id",
          value: "header.custom_id",
          width: "20%"
        },
        {
          text: "Title",
          value: "header.metadata.title",
          width: "80%"
        },

        {
          text: "Latest",
          width: "10em",
          sortable: false
        }
      ]
    }
  },
  computed: {
    selectedUpgrade
  },
  methods: {
    updateSelectedDependents,
    selectUpgrade
  }
}
</script>
