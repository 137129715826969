/**
 * A recorded or upload audio.
 */
import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shape"
    this.data = {
      // rectangle, triangle, ellipse
      shape: "rectangle",
      style: {
        fill: "#000000ff",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
