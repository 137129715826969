var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lp-image-view"},[(_vm.value.data.cropped)?_c('img',{style:(`border-radius: ${_vm.borderRadius}px`),attrs:{"width":"100%","height":"100%","src":_vm.value.data.cropped.url}}):_c('img',{style:(`border-radius: ${_vm.borderRadius}px`),attrs:{"width":"100%","height":"100%","src":_vm.value.data.image.url}}),_c('div',{style:(`
        width: 100%; 
        height: 100%;
        position: absolute;
        top: 0; 
        left: 0; 
        background: ${_vm.value.data.overlay || 'rgba(0,0,0,0)'};
        border-radius: ${_vm.borderRadius}px;
      `)}),(_vm.isFullScreen)?_c('div',{staticClass:"lp-image-view__zoom",on:{"click":_vm.openFullScreen}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-magnify-plus")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }