<template>
	<v-dialog :value="true" persistent width="400">
		<v-toolbar flat>
			<v-toolbar-title>Save Block in gallery</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn flat icon @click.native="$emit('input', false)">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-toolbar>
		<v-card>
			<v-card-text>
				<v-text-field v-model="name" label="Name" prepend-icon="mdi-toy-brick-outline"></v-text-field>
				<v-autocomplete
					:items="icons"
					item-text="name"
					item-value="name"
					v-model="icon"
					:prepend-icon="'mdi-'+icon"
					label="Icon"
				></v-autocomplete>
				<v-select
					:items="galleries"
					item-text="name"
					item-value="id"
					v-model="gallery_id"
					prepend-icon="mdi-folder-outline"
					label="Gallery"
				></v-select>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="error" flat @click.native="$emit('input', false)">Cancel</v-btn>
				<v-btn color="success" outline @click.native="$emit('input', {gallery_id, name, icon : 'mdi-'+icon})" :disabled="!gallery_id || name==''">Save in gallery</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import icons from "./icons.json"
export default {
	props: ["value", "galleries"],
	data() {
		return {
			gallery_id: false,
			name: "New Block",
			icon: "toy-brick-outline",
			icons
		}
	}
};
</script>

