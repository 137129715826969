<template>
  <div class="lp-toolbar-textarea-color-container">
    <preset-input-color
      :value="editor.getAttributes('textStyle').color"
      @input="setColor"
      @menuOpened="handleMenuState"
      class="lp-toolbar-textarea-color"
      :small="true"
      :closeButton="closeButton">
    </preset-input-color>
  </div>
</template>

<script>
import PresetInputColor from "../../components/slides/styles/PresetInputColor.vue"

export default {
  props: ["editor", "closeButton"],
  components: { PresetInputColor },
  data() {
    return {
      timerSetColor: null,
      color: this.editor.getAttributes("textStyle").color
    }
  },
  methods: {
    setColor(event) {
      this.color = event
    },
    handleMenuState(isOpen) {
      if (!isOpen) this.editor.chain().focus().setColor(this.color).run()
    }
  }
}
</script>

<style>
.lp-toolbar-textarea-color-container {
  display: flex;
  margin-left: 10px;
}
.lp-toolbar-textarea-color {
  margin-right: 10px;
}
</style>
