export const STORE_NOTIFICATIONS_NAME = "notifications"

export const STATE_NOTIFICATIONS_STACK = "stack"
export const MUTATION_NOTIFICATIONS_ADD = "add"
export const MUTATION_NOTIFICATIONS_DELETE = "delete"
export const ACTION_NOTIFICATIONS_INFO = "info"
export const ACTION_NOTIFICATIONS_WARNING = "warning"
export const ACTION_NOTIFICATIONS_ERROR = "error"
export const ACTION_NOTIFICATIONS_NOTIFY = "notify"
export const ACTION_NOTIFICATIONS_REMOVE = "remove"

export const NOTIFICATION_INFINITE_TIMEOUT = -1