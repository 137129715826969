<script>
import { Editor } from "@tiptap/vue-2"
import Document from "@tiptap/extension-document"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import StarterKit from "@tiptap/starter-kit"
import TextStyle from "@tiptap/extension-text-style"
import { Color } from "@tiptap/extension-color"
import FontFamily from "@tiptap/extension-font-family"
import Underline from "@tiptap/extension-underline"
import Subscript from "@tiptap/extension-subscript"
import Superscript from "@tiptap/extension-superscript"
import HardBreak from "@tiptap/extension-hard-break"
import { Fields } from "../../_components/extensions/tiptap/Fields.js"
import { OrderListClass, Clipboard, FormulaMark, FormulaClass } from "plant-common"
import _ from "lodash"

export default {
  name: "MixinRichText",
  data() {
    return {
      textEdit: false
    }
  },
  methods: {
    setEditor(data, notEditable = false) {
      const editor = new Editor({
        editable: !notEditable,
        onUpdate: _.debounce(() => {
          data.html = editor.getHTML()
        }, 300),
        extensions: [
          Document,
          Paragraph,
          Text,
          StarterKit,
          TextStyle,
          Color,
          FontFamily,
          Subscript,
          Superscript,
          Underline,
          Fields,
          OrderListClass,
          Clipboard,
          HardBreak,
          FormulaMark,
          FormulaClass
        ],
        content: data.html
      })
      return editor
    }
  }
}
</script>
