import "./pt-piping-pump-centrifugal.css"

export default {
  model: {
    "status": {
      type: "list",
      format: ["ON", "OFF"],
      bind: {
        to: "attr",
        name: "data-status"
      },
    }
  },
  listeners: {
        mouseenter: true,
        mouseleave: true,
    click: true,
    contextmenu: true
  }
}