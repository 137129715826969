<template>
<p-toolbar>
  <div>
    <p-toolbar-action @click="decrement()" icon="mdi-chevron-left" tooltip="Back" position="top"></p-toolbar-action>
    <p-toolbar-action @click="increment()" icon="mdi-chevron-right" tooltip="Next" position="top"></p-toolbar-action>
    <p-toolbar-action @click="selectCard({type:'mindmap'})" icon="mdi-share-variant" tooltip="Mindmap" position="top"></p-toolbar-action>
  </div>
</p-toolbar>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils";
import { PToolbarAction, PToolbar } from "plant-common";
export default {
  name:"StudentGuideActions",
  components: { PToolbar, PToolbarAction },
  inject: ["guideController"],
  data() {
    return {};
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard"])
  },
  methods: {
    ...mapInjectedMethods("guideController", [
      "decrement",
      "playPresentation",
      "increment",
      "selectCard"
    ])
  }
};
</script>

<style>
</style>

