<template>
  <p-dialog :value="value" max-height="fit-content" max-width="fit-content">
    <template #toolbar>
      <v-toolbar-title> Job Detail </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-layout row wrap id="jobDetail-modal">
      <v-flex xs12 d-inline-block>
        <v-card class="mb-1 jobDetail-card">
          <v-card-text id="info">
            <status-job :job="job" class="ml-3" />
            <v-tabs :value="tabIndex" grow class="transparent">
              <v-tab v-for="(tab, index) in tabs" :key="index">
                <v-icon left>{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
              <v-tab-item v-for="(tab, index) in tabs" :key="index">
                <v-card flat>
                  <v-card-text v-if="tab.show">
                    <v-text-field
                      v-for="prop in tab.props"
                      :key="prop.label"
                      readonly
                      :prepend-icon="prop.icon"
                      :label="prop.label"
                      :value="prop.value"
                      v-show="prop.show"></v-text-field>
                  </v-card-text>
                  <v-card-text v-else> No data available</v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <template #actions> <div></div> </template>
  </p-dialog>
</template>

<script>
import { PDialog } from "plant-common"
import moment from "moment"
import StatusJob from "./components/StatusJob.vue"
export default {
  name: "JobDetailModal",
  components: { PDialog, StatusJob },
  props: { job: Object, value: Boolean },
  data() {
    return {
      panel: [true, true, true],
      tabIndex: 0,
      tabs: [
        {
          show: true,
          icon: "mdi-briefcase-outline",
          title: "Job",
          props: [
            {
              show: this.job.failReason,
              label: "Fail reason",
              icon: "mdi-message-alert",
              value: this.job.failReason
            },
            {
              show: true,
              label: "Job ID",
              icon: "mdi-identifier",
              value: this.job._id
            },
            {
              show: true,
              label: "Name",
              icon: "mdi-alpha-t-box-outline",
              value: this.job.name
            },
            {
              show: true,
              label: "Job executed at",
              icon: "mdi-clock-outline",
              value: this.dateFormated(this.job.lastFinishedAt)
            }
          ]
        },
        {
          show: this.job.data && this.job.data.arguments,
          icon: "mdi-file",
          title: "Content",
          props: [
            {
              show: true,
              label: "Content ID",
              icon: "mdi-identifier",
              value: this.job.data && this.job.data.arguments ? this.job.data.arguments.content_id : false
            },
            {
              show: true,
              label: "Custom ID",
              icon: "mdi-identifier",
              value: this.job.data && this.job.data.custom_id ? this.job.data.custom_id : false
            },
            {
              show: true,
              label: "Major revision",
              icon: "mdi-cogs",
              value: this.job.data && this.job.data.arguments ? this.job.data.arguments.major_revision : false
            },
            {
              show: true,
              label: "Minor revision",
              icon: "mdi-cogs",
              value: this.job.data && this.job.data.arguments ? this.job.data.arguments.minor_revision : false
            },
            {
              show: true,
              label: "SCORM version",
              icon: "mdi-zip-box-outline",
              value:
                this.job.data && this.job.data.arguments && this.job.data.arguments.scorm_version
                  ? this.job.data.arguments.scorm_version
                  : "1.2"
            }
          ]
        },
        {
          show: this.job.data && this.job.data.user,
          icon: "mdi-account",
          title: "User",
          props: [
            {
              show: true,
              label: "User Name",
              icon: "mdi-account-outline",
              value: this.job.data && this.job.data.user ? this.job.data.user.name : false
            },
            {
              show: true,
              label: "User Role",
              icon: "mdi-clipboard-account-outline",
              value: this.job.data && this.job.data.user ? this.job.data.user.role : false
            }
          ]
        }
      ]
    }
  },
  methods: {
    dateFormated(value) {
      return moment(value).format("LLLL")
    }
  }
}
</script>

<style>
#jobDetail-modal {
  width: 65vw;
  max-width: 1024px;
  height: 80vh;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  border-radius: 0px 0px 6px 6px;
}

.jobDetail-card {
  height: 80vh;
  overflow: scroll;
  box-shadow: none !important;
}

.data-headline {
  color: #585858;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-dialog {
  max-height: 100% !important;
}
</style>
 