<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-90-deg-control-handler-x"
        :x="r"
        :x-range="[0, Math.min(s, h / 2 + tB / 2)]"
        :y="0"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.radius = x / w / h)" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-90-deg-control-handler-x"
        :x="s"
        :x-range="[w * 0.51, w]"
        :y="0"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.slope = x / w)" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-90-deg-control-handler-y"
        :x="w"
        :y="tH"
        :y-range="[5, h]"
        :angle="value.rotation"
        @update:y="y => (value.data.controls.thickHead = y / h)" />
      <ShapeControlHandle
        v-if="displayHandler"
        class="shape-arrow-90-deg-control-handler-y"
        :x="tB"
        :x-range="[5, w * 0.49]"
        :y="h"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.thickBody = x / w)" />
      <svg
        class="shape-arrow-90-deg"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <path
            :id="`path-${id}`"
            :d="`
                M ${w} ${tH / 2}
                L ${s} 0
                L ${s} ${(tH - tB) / 2}
                L ${r} ${(tH - tB) / 2}
                A ${r} ${r} 0 0 0 0 ${(tH - tB) / 2 + r}
                L 0 ${(tH - tB) / 2 + r}
                L 0 ${h}
                L ${tB} ${h}
                L ${tB} ${(tH + tB) / 2 + rI}
                A ${rI} ${rI} 0 0 1 ${tB + rI} ${(tH + tB) / 2}
                L ${tB + rI} ${(tH + tB) / 2}
                L ${s} ${(tH + tB) / 2}
                L ${s} ${tH}
                L ${w} ${tH / 2}
              `"
            :style="value.data.style"
            stroke-linecap="round" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"
export default {
  name: "BlockShapeArrow",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  data() {
    return {
      state: "not-selected"
    }
  },
  props: ["value", "mode"],
  computed: {
    displayHandler() {
      return this.state == "primary-selected" && this.mode === "edit"
    },
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    r() {
      return Math.min(this.value.data.controls.radius * this.w * this.h, this.s, this.h / 2 + this.tB / 2)
    },
    rI() {
      return Math.max(0, this.r - this.tB)
    },
    s() {
      return this.value.size.width * this.value.data.controls.slope
    },
    tH() {
      return this.value.size.height * this.value.data.controls.thickHead
    },
    tB() {
      return this.value.size.width * this.value.data.controls.thickBody
    }
  }
}
</script>
