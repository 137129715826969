<script>
import ptApiClient from "plant-api-client"
import { getCustomId, getLibraryId, getLibraryIdFromURL, setClientLibraryId } from "@/utils/dbTool"

export default {
  methods: {
    getCurrentRevision(dependency) {
      if (!dependency || !dependency.header) return ""
      return `${dependency.header.major_revision}.${dependency.header.minor_revision}`
    },

    getLatestRevision(dependency) {
      return dependency && dependency.__latest ? dependency.__latest : null
    },

    async updateContentToLatestRevision(contents, dependencies, selectedLibraryURL = "") {
      const mapDependency = []
      const selectedLibraryId = getLibraryIdFromURL(selectedLibraryURL)
      let hasSelectedLibraryChanged = false

      for (const dependency of dependencies) {
        const customId = getCustomId(dependency)
        const dependencyLibraryId = getLibraryId(dependency)

        if (dependencyLibraryId && selectedLibraryId !== dependencyLibraryId) {
          setClientLibraryId(ptApiClient, dependencyLibraryId)
          hasSelectedLibraryChanged = true
        }

        try {
          const last_content = await ptApiClient.getContentByCustomId(customId)
          last_content.__latest = `${last_content.header.major_revision}.${last_content.header.minor_revision}`
          this.updateContents(contents, dependency, last_content)
          mapDependency.push({ oldOrigin: dependency.$origin, newOrigin: last_content.$origin })
        } catch (e) {
          console.log(e)
        }

        if (hasSelectedLibraryChanged) {
          setClientLibraryId(ptApiClient, selectedLibraryId)
          hasSelectedLibraryChanged = false
        }
      }
      return mapDependency
    },

    isLatestRevision(dependency) {
      const latestRevision = this.getLatestRevision(dependency)
      if (!latestRevision) return true
      return this.getCurrentRevision(dependency) === latestRevision
    },

    updateContents(obj, dependency, lastContent) {
      if (obj == null) {
      } else if (
        obj.hasOwnProperty("$origin") &&
        obj.$origin === dependency.$origin &&
        lastContent.$origin !== dependency.$origin
      ) {
        Object.assign(obj, lastContent)
      } else if (typeof obj == "object") {
        for (const key of Object.keys(obj)) {
          this.updateContents(obj[key], dependency, lastContent)
        }
      }
    }
  }
}
</script>
