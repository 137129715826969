<template>
  <v-tabs v-model="active" fixed-tabs>
    <v-tab :key="1">Guide-Tree</v-tab>
    <v-tab :key="2">Notes</v-tab>
    <v-tab-item :key="1">
      <v-card flat>
        <v-layout>
          <v-flex>
            <v-card-title
              @click="selectCard(guide.cover)"
              class="px-2 py-2"
              style="border-bottom: 1px solid #eaeaea; cursor: pointer">
              <v-icon>mdi-home</v-icon>
              <h4 class="ml-3">Cover</h4>
            </v-card-title>
            <v-card-text class="px-2 py-0 overflow_items">
              <v-treeview
                :items="guide.sections"
                active-class="grey lighten-4 indigo--text"
                selected-color="indigo"
                expand-icon="mdi-chevron-down"
                indeterminate-icon="mdi-bookmark-minus"
                hoverable
                transition
                open-on-click
                :active.sync="activated"
                :open.sync="expanded">
                <template v-slot:label="{ item }">
                  <span @click="selectCard(item)" class="tree-item">
                    <span v-if="item.type == 'section'">
                      {{ item.fields.title || "Section" }}
                    </span>
                    <span v-if="item.type == 'card'">
                      {{ item.content.header ? getTitle(item.content) : "" }}
                    </span>
                    <span v-if="item.type == 'card-template'">
                      {{ item.content.template_name ? item.content.template_name : "" }}
                    </span>
                  </span>
                </template>

                <template v-slot:prepend="{ item, open }">
                  <v-icon v-if="item.type == 'section'" :color="open ? 'primary' : 'grey'">
                    {{ open ? "mdi-folder-open" : "mdi-folder" }}
                  </v-icon>
                  <span v-if="item.type == 'card'" class="ml-0">
                    <content-type-icon
                      v-if="item.content.header"
                      :type="getType(item.content)"
                      width="25px"></content-type-icon>
                    <v-icon v-else>mdi-circle</v-icon>
                  </span>
                  <span v-if="item.type == 'card-template'" class="ml-0">
                    <v-icon>mdi-menu</v-icon>
                  </span>
                </template>
              </v-treeview>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-card>
    </v-tab-item>
    <v-tab-item :key="2">
      <v-card flat>
        <v-card-text class="overflow_items">
          <div v-if="selectedCard.notes">{{ selectedCard.notes }}</div>
          <div v-else><i>No notes to show</i></div>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import draggable from "vuedraggable"
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { ContentTypeIcon } from "plant-common"
import { getTitle, getType } from "../../../../utils/dbTool"

export default {
  name: "GuideEditorNavBar",
  inject: ["guideController"],
  data() {
    return {
      expanded: [],
      activated: []
    }
  },
  props: {
    sortable: {
      type: Boolean,
      default: false
    }
  },
  components: { ContentTypeIcon, draggable },

  computed: {
    ...mapInjectedData("guideController", ["guide", "selectedCard"])
  },
  methods: {
    ...mapInjectedMethods("guideController", ["selectCard", "getParents"]),
    getTitle: getTitle,
    getType: getType
  },
  watch: {
    selectedCard: function (newLocation, oldLocation) {
      this.activated = [newLocation.id]
      let parents = this.getParents(this.guide.sections, newLocation.id, []).parents
      if (parents) {
        parents.forEach(e => this.expanded.push(e.id))
      }
    }
  }
}
</script>

<style scoped>
.height90 {
  height: calc(100% - 100px);
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.normal-card {
  margin: 15px 5px;
  width: 100%;
}

.normal-section {
  margin: 15px 5px;
  width: 100%;
}

.section-title {
  margin: 0px;
  padding: 5px;
  background: black;
  color: white;
}

.pointer {
  cursor: pointer;
}

.active-card {
  border-color: #468cfc !important;
  border: 2px;
  border-style: solid;
}

.inactive-card {
  cursor: pointer;
}

.tree-item {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
  font-size: 14px;
  margin: 0;
  padding: 12px;
}

.overflow_tabs {
  height: 100%;
}

.overflow_items {
  height: calc(100vh - 202px);
  overflow: auto;
}
</style>
