<template>
  <v-list two-line>
    <v-subheader>Guide Information</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-presentation</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Lesson</v-list-tile-sub-title>
        <v-list-tile-title>{{ getCustomId(content) }} - {{ getTitle(content) }}</v-list-tile-title>
      </v-list-tile-content>
    </v-list-tile>

    <v-list-tile avatar v-if="guide.learning_objective">
      <v-list-tile-avatar>
        <v-icon>mdi-bullseye-arrow</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Terminal Objective</v-list-tile-sub-title>
        <v-list-tile-title
          >{{ getCustomId(guide.learning_objective) }} - {{ getTitle(guide.learning_objective) }}</v-list-tile-title
        >
      </v-list-tile-content>
      <v-list-tile-action>
        <v-btn icon @click="openObjectiveTemplate(getContentId(guide.learning_objective))">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>

    <v-divider></v-divider>
    <v-subheader>Look and Feel</v-subheader>
    <v-list-tile avatar v-if="guide.template">
      <v-list-tile-avatar>
        <v-icon>mdi-pencil-ruler</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Template</v-list-tile-sub-title>
        <v-list-tile-title>{{ getCustomId(guide.template) }} - {{ getTitle(guide.template) }}</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-btn icon @click="openObjectiveTemplate(getContentId(guide.template))">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-list-tile-action>
    </v-list-tile>
    <v-list-tile avatar v-else>
      <v-list-tile-avatar>
        <v-icon>mdi-pencil-ruler</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content> No template selected </v-list-tile-content>
    </v-list-tile>
    <v-divider></v-divider>
    <v-subheader>Delivery options</v-subheader>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-human-male-board</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Classroom</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-icon color="success">mdi-check</v-icon>
      </v-list-tile-action>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-desktop-mac</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Self-Study</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-icon color="success">mdi-check</v-icon>
      </v-list-tile-action>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon>mdi-video-account</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-sub-title>Online training</v-list-tile-sub-title>
      </v-list-tile-content>
      <v-list-tile-action>
        <v-icon color="success">mdi-check</v-icon>
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>

<script>
import { mapInjectedData } from "plant-common/src/utils"
import { getContentId, getCustomId, getTitle } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "GuideInfo",
  inject: ["guideController"],
  computed: {
    ...mapInjectedData("guideController", ["guide", "content"])
  },
  methods: {
    getContentId: getContentId,
    getCustomId: getCustomId,
    getTitle: getTitle,
    openObjective(contentId) {
      PServiceContent.openContent(contentId, "tab")
    }
  }
}
</script>
