<template>
  <fragment>
    <p-panel-properties-group title="Data">
      <p-property-tile title="Text">
        <v-text-field box single-line hide-details v-model="value.data.text"></v-text-field>
      </p-property-tile>
      <p-property-tile title="Button Icon">
        <DialogButtonIcon :value="value">
          <template v-slot:activator="{ open }">
            <p-panel-button text="Edit" icon="mdi-emoticon-outline" tooltip="Edit button icon" @click="open" />
          </template>
        </DialogButtonIcon>
      </p-property-tile>
      <p-property-tile title="Button Styles">
        <DialogButtonStyle :value="value" :state="state">
          <template v-slot:activator="{ open }">
            <p-panel-button text="Edit" icon="mdi-palette-outline" tooltip="Edit button format" @click="open" />
          </template>
        </DialogButtonStyle>
      </p-property-tile>
    </p-panel-properties-group>
  </fragment>
</template>

<script>
import PPanelPropertiesGroup from "../_components/utils/PPanelPropertiesGroup.vue"
import PPanelProperties from "../_components/utils/PPanelProperties.vue"
import PPropertyTile from "../_components/utils/PPropertyTile.vue"
import PPanelButton from "../_components/utils/PPanelButton.vue"
import DialogButtonStyle from "./DialogButtonStyle.vue"
import DialogButtonIcon from "./DialogButtonIcon.vue"
import { Fragment } from "vue-fragment"
export default {
  props: ["value", "state"],
  components: {
    Fragment,
    PPanelPropertiesGroup,
    PPanelProperties,
    PPanelButton,
    PPropertyTile,
    DialogButtonStyle,
    DialogButtonIcon
  }
}
</script>