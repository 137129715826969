
import parseBoolean from "./config/parseBoolean"
import "./pt-logic-input.css"

export default {
  model: {
    "value": {
      type: "boolean",
      default: false
    }
  },
  listeners: {
    click() {
      if (typeof this.data.value == "undefined") {
        this.data.value = undefined
      } else {
        this.data.value = !parseBoolean(this.data.value)
      }
      return false
    }
  }
}
