var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.style)},[(_vm.state == 'not-selected' && _vm.mode === 'edit')?[_c('svg',{staticClass:"line-overlay movable-available",staticStyle:{"overflow":"overlay","z-index":"1000"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":_vm.value.size.width,"height":_vm.h,"preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`},on:{"click":function($event){return _vm.$parent.$emit('blockClick')}}})]:(_vm.mode === 'edit')?[_c('svg',{staticClass:"line-overlay movable",staticStyle:{"overflow":"overlay","z-index":"1000"},attrs:{"xmlns":"http://www.w3.org/2000/svg","version":"1.1","width":_vm.value.size.width,"height":_vm.h,"preserveAspectRatio":"none","viewBox":`0 0 ${_vm.w} ${_vm.h}`},on:{"dblclick":function($event){return _vm.$parent.$emit('blockDblClick')},"mousedown":_vm.move}}),_c('div',{staticClass:"movable-control begin",class:{ disabled: _vm.value.protection.resize },style:(`
        position: absolute; 
        left: ${_vm.block.data.end.x > _vm.block.data.begin.x ? 0 : _vm.w}px;
        top: ${_vm.block.data.end.y >= _vm.block.data.begin.y ? (_vm.h > 15 ? 0 : 10) : _vm.h}px;
      `),on:{"mousedown":function($event){return _vm.moveControl($event, 'begin')}}}),_c('div',{staticClass:"movable-control end",class:{ disabled: _vm.value.protection.resize },style:(`
        position: absolute;
        left: ${_vm.block.data.end.x > _vm.block.data.begin.x ? _vm.w : 0}px;
        top: ${_vm.block.data.end.y > _vm.block.data.begin.y ? (_vm.h < 15 ? 15 : _vm.h) : 0}px;
      `),on:{"mousedown":function($event){return _vm.moveControl($event, 'end')}}})]:_vm._e(),_vm._t("control-handlers"),_c('div',{class:`${_vm.state == 'edit-content' && !_vm.value.protection.data ? 'edit-text' : ''}`,style:({ ..._vm.animationStyle, width: '100%', position: 'absolute', top: '0', zIndex: -1 }),attrs:{"id":_vm.block.id}},[_vm._t("main")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }