<template>
  <v-hover>
    <v-card
      class="component-card text-center ma-2 pa-2"
      slot-scope="{ hover }"
      :class="`elevation-${hover ? 12 : 2}`"
      @click="openDialog(tool)"
    >
      <v-card-text>
        <v-avatar size="50px" color="grey lighten-4" class="mb-2">
          <img :src="tool.icon" />
        </v-avatar>
        <h3 class="headline mb-0 title-card">
          {{ tool.title }}
        </h3>
        <div class="subtext-card mt-2">{{ tool.description }}</div>
      </v-card-text>
    </v-card>
  </v-hover>
</template>
<script>
import { PtModal } from "plant-common";
export default {
  name: "LibrarySelectorCard",
  mixins: [PtModal],
  props: {
    tool: Object
  },
  methods: {
    async openDialog(tool) {
      const toolDialog = await this.$modal(tool.Component, { tool: tool });
      if (!toolDialog) return false;
    }
  }
};
</script>

<style>
.component-card {
  min-height: 200px;
  cursor: pointer;
}
.title-card {
  color: #4e4e4e;
  font-style: normal;
  font-weight: bold;
  font-size: 20px !important;
  line-height: 24px;
}
.subtext-card {
  color: #929292;
  height: 75px;
}
</style>