import "./select.css"
import { selectSquare } from "./selectSquare";

const SELECTED_CLASSNAME = "diagramjs-selected"
var _OFFSET = 6;

/**
 * Create and inject selectable svg object
 *
 * @param {object}    elm         Selectable diagram object.
 * @param {object}    svgElement  Global diagram object.
 * @param {function}  callback    Callback function.
 *
 */
var addSelectRect = function (elm, svgElement, callback, mouseEnterCallback) {
  var o = document.createElementNS("http://www.w3.org/2000/svg", "rect");
  var bbox;

  bbox = selectSquare(elm).getBBox();
  o.setAttribute("x", bbox.x - _OFFSET / 2);
  o.setAttribute("y", bbox.y - _OFFSET / 2);
  o.setAttribute("width", bbox.width + _OFFSET);
  o.setAttribute("height", bbox.height + _OFFSET);
  o.setAttribute("stroke", 'black')
  o.setAttribute("fill", 'none');
  o.setAttribute("rx", 5);
  o.setAttribute("ry", 5);
  o.setAttribute("data-selectedId", elm.id);
  elm.classList.forEach(l => o.classList.add(l))
  o.classList.add(SELECTED_CLASSNAME)
  o.onclick = function () {
    this.remove()
    //elm.dispatchEvent(new Event('click'))
    if (typeof callback == 'function') callback()
  }
  o.onmouseenter = function () {
    if (typeof mouseEnterCallback == 'function') mouseEnterCallback()
  }
  o.setAttribute("transform", elm.getAttribute("transform"));
  svgElement.appendChild(o);
}

var checkParents = function (elem) {
  var initElem = elem
  while (elem.parentNode && elem.parentNode.nodeName.toLowerCase() != 'body') {
    elem = elem.parentNode;
    if (initElem.getAttribute('class') === elem.getAttribute('class'))
      return false;
  }
  return true;
}

/**
 * Return selectable svg element in PLANT's diagram.
 *
 * @param {object}    svgElement  Global diagram object.
 * @param {object}    selector    Selectable diagram object.
 * @param {function}  callback    Callback function.
 * 
 * @return {object} SVG object to be selected.
 */
var select = function (svgElement, selector, callback, mouseEnterCallback) {
  if (checkParents(selector) && Object.keys(selector.dataset).length > 0) {
    if (selector == undefined) {
      var selected = []
      svgElement.querySelectorAll("." + SELECTED_CLASSNAME).forEach((elm) => selected.push(elm.dataset.selectedId))
      return selected
    } else if (selector === false) {
      svgElement.querySelectorAll("." + SELECTED_CLASSNAME).forEach((elm) => elm.remove())
      return
    }
    if (typeof selector == "string") {
      return svgElement.querySelectorAll(selector).forEach((elm) => addSelectRect(elm, svgElement, callback, mouseEnterCallback))
    } else {
      return addSelectRect(selector, svgElement, callback, mouseEnterCallback)
    }
  }

};

export default select