<template>
  <div class="lp-index" :class="{ dark: blockController.dark }">
    <edit-toolbar @zoomFit="setZoomFit" />
    <div
      class="lp-index-panel"
      :style="{
        left: 0,
        width: (leftPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <left-panel
        :leftPanelsTop="leftPanelsTotal"
        :leftPanelsBottom="leftPanelsBottom"
        :leftPanelSelected.sync="leftPanelSelected"
        @slideChanged="$emit('slideChanged', blockController)"
        :dark="blockController.dark" />
    </div>
    <main
      class="lp-index-main"
      :style="{
        marginLeft: (leftPanelSelected > -1 ? 400 : 0) + 'px',
        marginRight: (rightPanelSelected > -1 ? 400 : 0) + 'px'
      }">
      <block-canvas-readonly
        v-if="imported"
        :key="slideId + '_imported'"
        :slide-id="slideId"
        ref="canvas"
        :blocks="blocks"
        :defaultBlockStyles="defaultBlockStyles"
        :settingStyles="settingStyles">
      </block-canvas-readonly>
      <block-canvas
        v-else
        :key="slideId"
        :slide-id="slideId"
        ref="canvas"
        :blocks="blocks"
        :defaultBlockStyles="defaultBlockStyles"
        :settingStyles="settingStyles"
        @create:draft="$emit('create:draft', $event)">
      </block-canvas>
      <block-canvas-grid
        v-if="blockController.gridSize[0] > 1"
        @zoomfit="setZoomFit"
        :scale="blockController.scale"
        :origin-x="blockController.origin.x"
        :origin-y="blockController.origin.y"
        :grid-x="blockController.gridSize[0]"
        :grid-y="blockController.gridSize[1]"
        :canvasWidth="blockController.viewerWidth"
        :canvasHeight="blockController.viewerHeight" />
    </main>
    <div
      class="lp-index-panel"
      :style="{
        right: 0,
        width: (rightPanelSelected > -1 ? 480 : 80) + 'px'
      }">
      <right-panel
        :rightPanelSelected.sync="rightPanelSelected"
        :dark="blockController.dark"
        :readonly="imported"
        :key="slideId" />
    </div>
  </div>
</template>

<script>
import LeftPanel from "../../../../components/structure/LeftPanel.vue"
import EditToolbar from "./EditToolbar.vue"
import BlockCanvas from "../../containers/BlockCanvas.vue"
import BlockCanvasReadonly from "../../containers/BlockCanvasReadonly.vue"
import BlockCanvasGrid from "../../containers/BlockCanvasGrid.vue"
import RightPanel from "../../../../components/structure/RightPanel.vue"
import blockController from "../../../_controllers/BlockController/index.js"
import GalleryPanel from "../../../../components/gallery/GalleryPanel.vue"
import Vue from "vue"

export default {
  name: "LayoutDesigner",
  components: {
    LeftPanel,
    EditToolbar,
    RightPanel,
    BlockCanvas,
    BlockCanvasReadonly,
    BlockCanvasGrid
  },
  provide() {
    return {
      blockDefinitions: this.blockDefinitions,
      blockController: this.blockController,
      blockEventBus: new Vue()
    }
  },
  props: {
    blocks: Array,
    slideId: String,
    blockDefinitions: Object,
    defaultBlockStyles: Object,
    settingStyles: Object,
    leftPanelsTop: Array,
    leftPanelsBottom: Array,
    rightPanels: Array,
    gridSize: Array,
    isBlockSelected: Boolean,
    availableStencils: Array,
    dark: Boolean,
    imported: Boolean
  },
  data() {
    return {
      leftPanelSelected: -1,
      rightPanelSelected: -1,
      blockController: blockController(this)
    }
  },
  computed: {
    leftPanelsTotal() {
      const panels = [...this.leftPanelsTop]
      if (!this.imported) {
        panels.push({
          title: "Block Gallery",
          icon: "mdi-toy-brick-outline",
          component: GalleryPanel,
          id: "blockGallery"
        })
      }
      return panels
    }
  },
  watch: {
    blocks: {
      deep: true,
      handler() {
        this.blockController.blocks = this.blocks
      }
    },
    availableStencils: {
      deep: true,
      handler() {
        this.blockController.availableStencils = this.availableStencils
      }
    },
    gridSize: {
      deep: true,
      handler() {
        this.blockController.gridSize = this.gridSize
      }
    }
  },
  methods: {
    setZoomFit() {
      this.$nextTick(() => {
        this.$refs["canvas"].zoomFit()
      })
    }
  }
}
</script>
