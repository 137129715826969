import { BlockBasic } from "../_models/models.js"
/**
 * A block used for buttons
 */
export default class BlockButton extends BlockBasic {
  constructor(block) {
    super(block)
    if (!block) {
      this.type = "button"
      this.size.width = 400
      this.size.height = 100
      this.protection.type = ["button"]
      this.data = {
        text: "Click here",
        icon: "none",
        visited: false,
        style: {
          regular: {
            backgroundColor: "#f5f5f5ff",
            color: "#000000ff",
            // percentage text scale
            textScale: 100,
            textAlign: "center",
            boxShadowButton: "none",
            /* borderRadius will change to 50px */
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#f5f5f5ff",
            fontFamily: false
          },
          hover: {
            backgroundColor: "#e0e0e0ff",
            color: "#000000ff",
            textScale: 100,
            textAlign: "center",
            boxShadowButton: "none",
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#f5f5f5ff",
            fontFamily: false
          },
          active: {
            backgroundColor: "#bdbdbdff",
            color: "#000000ff",
            textScale: 100,
            textAlign: "center",
            boxShadowButton: "none",
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#f5f5f5ff",
            fontFamily: false
          },
          visited: {
            backgroundColor: "#bdbdbdff",
            color: "#000000ff",
            textScale: 100,
            textAlign: "center",
            boxShadowButton: "none",
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#f5f5f5ff",
            fontFamily: false
          },
          disabled: {
            backgroundColor: "#efefef4d",
            color: "#1010104d",
            textScale: 100,
            textAlign: "center",
            boxShadowButton: "none",
            borderRadius: 0,
            borderWidth: 0,
            borderStyle: "solid",
            borderColor: "#7676764d",
            fontFamily: false
          }
        },
        actions: []
      }
    } else {
      Object.assign(this, block)
    }
  }

  setText(text) {
    this.data.text = text
  }
}
