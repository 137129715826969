const NOT_EVALUATED = "notEvaluated"
const NOT_PROFICIENT = "notProficient"
const PROFICIENT = "proficient"
const MASTER = "master"

export default {
    [NOT_EVALUATED]: { name: "Not evaluated", color: "#dfdfdf" },
    [NOT_PROFICIENT]: { name: "Not proficient", color: "red" },
    [PROFICIENT]: { name: "Proficient", color: "blue" },
    [MASTER]: { name: "Master", color: "green" }
}