<template>
  <div class="lp-popup-properties">
    <p-panel title="Popup Properties">
      <p-panel-properties>
        <p-panel-properties-group title="Size">
          <div class="pl-4 pr-4">
            <div>
              <v-text-field
                box
                type="number"
                v-model.number="blockController.overlay.width"
                label="Width"
                suffix="px"></v-text-field>
              <v-text-field
                box
                type="number"
                v-model.number="blockController.overlay.height"
                label="Height"
                suffix="px"></v-text-field>
            </div>
          </div>
        </p-panel-properties-group>
        <p-panel-properties-group title="Style" class="panel-properties-style">
          <div class="pl-4 pr-4">
            <item-style icon="mdi-format-color-fill" title="Background-color">
              <preset-input-color
                :value="blockController.overlay.style.backgroundColor"
                @input="blockController.overlay.style.backgroundColor = $event"></preset-input-color>
            </item-style>
          </div>
          <div class="pl-4 pr-4">
            <item-style icon="mdi-radius" title="Border radius">
              <v-select
                :items="availableBorderRadius"
                v-model="blockController.overlay.style.borderRadius"
                item-text="text"></v-select>
            </item-style>
          </div>
        </p-panel-properties-group>
        <p-panel-properties-group title="Behavior" class="panel-properties-behavior">
          <p-property-tile icon="mdi-close-circle-outline" title="Hide close button">
            <v-switch v-model="blockController.overlay.hideCloseButton" hide-details></v-switch>
          </p-property-tile>
          <p-property-tile icon="mdi-cursor-default-click-outline" title="Avoid to close popup clicking outside">
            <v-switch v-model="blockController.overlay.avoidClosingOutside" hide-details></v-switch>
          </p-property-tile>
        </p-panel-properties-group>
      </p-panel-properties>
    </p-panel>
  </div>
</template>

<script>
import PresetInputColor from "../../../components/slides/styles/PresetInputColor.vue"
import PPanelPropertiesGroup from "../utils/PPanelPropertiesGroup.vue"
import PPropertyTile from "../utils/PPropertyTile.vue"
import PPanelProperties from "../utils/PPanelProperties.vue"
import PPanel from "../utils/PPanel.vue"
import ItemStyle from "../../../components/slides/styles/ItemStyle.vue"

export default {
  name: "PopupProperties",
  inject: ["blockController"],
  components: {
    PresetInputColor,
    PPanelPropertiesGroup,
    PPanelProperties,
    PPanel,
    ItemStyle,
    PPropertyTile
  },
  data() {
    return {
      panel: [true, true],
      availableBorderRadius: [
        { text: "No", value: 0 },
        {
          text: "Yes",
          value: 50
        }
      ]
    }
  }
}
</script>

<style>
.panel-properties-behavior .v-list__tile__action {
  flex-basis: 15%;
  overflow: inherit !important;
}
.panel-properties-style .v-list__tile__action {
  flex-basis: 50%;
}
</style>