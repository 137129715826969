<template>
  <basic-block :value="value" :mode="mode" :state.sync="state">
    <template v-slot:main>
      <ShapeControlHandle
        v-if="state == 'primary-selected' && mode === 'edit'"
        class="shape-callout-control-handler-x"
        :x="r"
        :x-range="[0, w / 2]"
        :y="0"
        :angle="value.rotation"
        @update:x="x => (value.data.controls.radius = x / w)" />
      <svg
        class="shape-rectangle"
        style="vertical-align: top"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        :viewBox="`0 0 ${w} ${h}`">
        <defs>
          <rect :id="`path-${id}`" :width="w" :height="h" :rx="r" :ry="r" :style="value.data.style" />
          <clipPath :id="`clip-${id}`">
            <use :xlink:href="`#path-${id}`" />
          </clipPath>
        </defs>
        <g>
          <use :xlink:href="`#path-${id}`" :clip-path="`url(#clip-${id})`" />
        </g>
      </svg>
    </template>
  </basic-block>
</template>

<script>
import BasicBlock from "../_components/containers/BasicBlock.vue"
import ShapeControlHandle from "../_components/utils/ShapeControlHandle.vue"
export default {
  name: "BlockShapeRectangle",
  inject: ["blockController"],
  components: {
    BasicBlock,
    ShapeControlHandle
  },
  props: ["value", "mode"],
  data() {
    return {
      state: "not-selected"
    }
  },
  computed: {
    id() {
      return this.value.id
    },
    w() {
      return this.value.size.width
    },
    h() {
      return this.value.size.height
    },
    r() {
      return this.w * this.value.data.controls.radius
    }
  }
}
</script>
