<template>
  <div class="plant-library-component__wrapper">
    <div class="view-menu">
      <v-toolbar flat class="view-menu__options">
        <div class="view-menu__options--image-type">
          <ContentTypeIcon v-if="content" :type="getType(content)" />
        </div>
        <v-divider vertical></v-divider>

        <div class="view-menu__options--custom-id">
          <v-chip small label dark>
            <v-icon small>mdi-tag</v-icon>
            {{ getCustomId(content) }}
          </v-chip>

          <div>{{ getTitle(content) }}</div>
        </div>
      </v-toolbar>
    </div>

    <div class="library-content__wrapper-viewer">
      <item-viewer :value="content" :view="view"></item-viewer>
    </div>
  </div>
</template>

<script>
import ItemViewer from "plant-common/src/components/ItemViewer"
import { ContentTypeIcon } from "plant-common"
import { getType, getTitle, getCustomId } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "LibraryContentInside",
  components: {
    ItemViewer,
    ContentTypeIcon
  },
  inject: ["controller"],
  props: {
    origin: {
      type: String
    },
    pinned_view: {
      type: String
    }
  },
  data() {
    return {
      content: null,
      view: null
    }
  },
  computed: {},
  async created() {
    if (this.origin) {
      this.content = await PServiceContent.getContentFromRef(this.origin, this.controller.page)
    }
    if (this.pinned_view) {
      this.view = JSON.parse(this.pinned_view)
    }
    if (!this.view) this.view = { interface: "simple" }
    if (this.view && !this.view.interface) this.$set(this.view, "interface", "simple")
  },
  mounted() {},
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType
  }
}
</script>

<style scoped>
.view-menu {
  border-bottom: 1px solid lightgrey;
}

.view-menu__options--image-type {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.view-menu__options--image-type > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
}

.view-menu__options--custom-id .v-chip {
  margin: 0;
  margin-bottom: 4px;
}

.view-menu__options--pin-menu .v-list--disabled .v-list__tile .v-list__tile__avatar i {
  color: inherit;
}

.library-content__wrapper-editor {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

.library-content__wrapper-viewer {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
}

.plant-library-component__wrapper {
  border: 1px solid lightgrey;
}
</style>
