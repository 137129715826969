<template>
  <p-dialog :value="value" max-height="fit-content" max-width="fit-content">
    <template #toolbar>
      <v-toolbar-title> New content </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn flat icon @click="$emit('input', false)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <v-layout row wrap id="newContent-modal">
      <v-flex xs12 d-inline-block>
        <v-stepper v-model="e1" id="newContent-stepper">
          <v-stepper-header id="newContent-stepper-header">
            <v-stepper-step :complete="e1 > 1" step="1"> Content Type </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2"> Content Data </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Workflow Type</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step 1 -->
            <v-stepper-content step="1" class="pa-0">
              <v-card class="mb-1 newContent-card">
                <type-selector @select="selectType" />
              </v-card>
              <v-layout row justify-space-between>
                <v-flex xs2> </v-flex>
                <v-flex xs2>
                  <v-btn color="primary" @click="transitionTo(2)" :disabled="!item_type"> Continue </v-btn>
                </v-flex>
              </v-layout>
            </v-stepper-content>

            <!-- Step 2 -->
            <v-stepper-content step="2" class="pa-0">
              <v-card class="mb-1 newContent-card">
                <v-layout row wrap>
                  <v-flex xs1 style="text-align: center">
                    <ContentTypeIcon v-if="item_type" :type="item_type" />
                  </v-flex>
                  <v-flex xs11>
                    <h1 class="data-headline mt-2">Creating a new {{ item_type_title }}</h1>
                  </v-flex>
                </v-layout>
                <v-card-text id="new">
                  <v-text-field
                    autofocus
                    v-model="title"
                    label="Title *"
                    prepend-icon="mdi-alpha-t-box-outline"></v-text-field>
                  <v-text-field v-model="custom_id" label="Custom Id *" :error-messages="errorMessagesCustomId">
                    <template v-slot:prepend>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-tag-outline</v-icon>
                        </template>
                        Custom Id must be unique
                      </v-tooltip>
                    </template>
                  </v-text-field>
                  <v-text-field
                    v-model="path"
                    label="Path"
                    prepend-icon="mdi-folder-outline"
                    readonly
                    @click="selectPath"></v-text-field>
                </v-card-text>
              </v-card>
              <v-layout row justify-space-between>
                <v-flex xs2>
                  <v-btn flat @click="transitionTo(1)" :disabled="is_type_fixed">Previous</v-btn>
                </v-flex>
                <v-flex xs2>
                  <v-btn color="primary" @click="checkAndTransitionTo(3)" :disabled="!title || !custom_id">
                    Continue
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-stepper-content>
            <!-- Step 3 -->
            <v-stepper-content step="3" class="pa-0">
              <v-card class="mb-1 ml-3 newContent-card">
                <v-flex xs12>
                  <v-select
                    e2e-id="workflow_type"
                    :items="workflows"
                    item-text="label"
                    item-value="value"
                    label="Workflow"
                    prepend-icon="mdi-sitemap"
                    :value="draftToCreate.approval_process.workflow.name"
                    @change="handleGetWorkflowData"
                    append-icon="mdi-chevron-down"></v-select>
                </v-flex>
                <v-flex
                  xs12
                  v-for="contributor in draftToCreate.approval_process.workflow.contributors"
                  :key="contributor.role">
                  <user-selector
                    e2e-attr="user_selector"
                    style="text-transform: capitalize"
                    :label="`${contributor.role} ${
                      draftToCreate.approval_process.workflow.assigned_to === contributor.role ? '(assignee)' : ''
                    }`"
                    :placeholder="`Select user for ${contributor.role}`"
                    :users-disabled="
                      draftToCreate.approval_process.workflow.contributors
                        .filter(c => c.user_id !== undefined && c.user_id !== contributor.user_id)
                        .map(c => c.user_id)
                    "
                    :value="contributor.user_id"
                    prepend-icon="mdi-clipboard-account"
                    @change="user_id => reassignUserRole(user_id, contributor.role)"
                    append-icon="mdi-chevron-down"></user-selector>
                </v-flex>
              </v-card>
              <v-layout row justify-space-between>
                <v-flex xs2>
                  <v-btn flat @click="transitionTo(2)">Previous</v-btn>
                </v-flex>
                <v-flex xs2>
                  <v-btn color="success" @click="createContent"> Create </v-btn>
                </v-flex>
              </v-layout>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-flex>
    </v-layout>
    <template #actions> <div></div> </template>
  </p-dialog>
</template>

<script>
import ptApiClient from "plant-api-client"
import { PtModal, PDialog } from "plant-common"
import PathToDialog from "@/components/PathToDialog.vue"
import TypeSelector from "./TypeSelector.vue"
import UserSelector from "../UserSelector"
import { mapActions, mapState } from "vuex"
import { ContentTypeIcon } from "plant-common"
import workflows_store from "@/store/workflows"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_ERROR,
  ACTION_NOTIFICATIONS_WARNING
} from "@/store_constants/notifications"
import { STORE_WORKFLOWS_NAME, ACTION_GET_WORKFLOW } from "@/store_constants/workflows"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import WorkflowTypes from "../../constants/WorkflowTypes"

export default {
  mixins: [PtModal],
  components: { ContentTypeIcon, TypeSelector, UserSelector, PDialog },
  data() {
    return {
      e1: 0,
      item_type: undefined,
      is_type_fixed: false,
      item_type_title: undefined,
      custom_id: undefined,
      title: undefined,
      path: undefined,
      path_id: undefined,
      workflows: WorkflowTypes,
      draftToCreate: {
        approval_process: {
          workflow: {}
        }
      },
      errorMessagesCustomId: ""
    }
  },
  props: ["path_set", "type", "value", "type_set", "title_set", "custom_id_set"],
  computed: {
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    isPathSetAndNotChanged() {
      return this.path_set && JSON.stringify(this.path_set.name) === JSON.stringify(this.path)
    }
  },
  created() {
    if (this.type_set) {
      if (typeof this.type_set === "string") {
        this.selectType(this.type_set)
      } else {
        this.selectType(this.type_set.value)
        this.is_type_fixed = this.type_set.fixed
      }
    }

    if (this.title_set) {
      this.title = this.title_set
    }

    if (this.custom_id_set) {
      this.custom_id = this.custom_id_set
    }
  },
  async mounted() {
    if (this.path_set && this.path_set.name) {
      this.path = this.path_set.name
    }
    if (this.type && this.type.title) {
      this.item_type_title = this.type.title
      this.item_type = this.type.name
    }

    if (!this.$store.state[STORE_WORKFLOWS_NAME]) {
      this.$store.registerModule(STORE_WORKFLOWS_NAME, workflows_store)
    }

    this.draftToCreate.approval_process.workflow = await this.getWorkflowObject("direct_approval")
    this.draftToCreate.approval_process.workflow.contributors.find(c => c.role === "author").user_id = this.profile._id
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      error: ACTION_NOTIFICATIONS_ERROR,
      warning: ACTION_NOTIFICATIONS_WARNING
    }),
    ...mapActions(STORE_WORKFLOWS_NAME, {
      getWorkflowObject: ACTION_GET_WORKFLOW
    }),
    async getWorkflowData(name) {
      this.draftToCreate.approval_process.workflow = await this.getWorkflowObject(name)
    },
    reassignUserRole(user_id, role) {
      this.$set(
        this.draftToCreate.approval_process.workflow.contributors.find(c => c.role === role),
        "user_id",
        user_id
      )
    },
    selectPath() {
      this.$modal(PathToDialog, {}).then(destination_folder => {
        if (!destination_folder) return
        this.path = destination_folder.name
        if (destination_folder._id === "$root") {
          this.path_id = undefined
        } else {
          this.path_id = destination_folder._id
        }
      })
    },
    createContent() {
      this.$emit("input", {
        title: this.title,
        item_type: this.item_type,
        path_id: this.path_set ? this.path_set._id : this.path_id,
        custom_id: this.custom_id,
        workflow: this.draftToCreate.approval_process.workflow
      })
    },
    transitionTo(t) {
      this.e1 = t
    },
    async checkAndTransitionTo(t) {
      if (t === 3) {
        try {
          await ptApiClient.getContentByCustomId(this.custom_id, true)
          this.errorMessagesCustomId = "The custom id must be unique"
        } catch (e) {
          this.errorMessagesCustomId = ""
          this.transitionTo(t)
        }
      }
    },
    selectType(type) {
      this.item_type = type.name
      this.item_type_title = type.title
      this.e1 = 2
    },
    async handleGetWorkflowData(name) {
      await this.getWorkflowData(name)
      this.reassignUserRole(this.profile._id, "author")
    }
  }
}
</script>

<style>
#newContent-modal {
  width: 65vw;
  max-width: 1024px;
  height: 80vh;
  background: #ffffff;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  border-radius: 0 0 6px 6px;
}
#newContent-stepper {
  height: 80vh;
  background: none;
  box-shadow: none;
}
#newContent-stepper-header {
  box-shadow: none;
}
.newContent-card {
  height: 65vh;
  overflow: scroll;
  box-shadow: none !important;
}

.data-headline {
  color: #585858;
  font-size: 20px !important;
  font-weight: 500;
  letter-spacing: 0.02em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-dialog {
  max-height: 100% !important;
}
</style>
