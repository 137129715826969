<template>
  <v-app
    :style="`background-image: url('${branding.login_background_url}');background-repeat: no-repeat;background-size:cover; background-attachment: fixed;`">
    <v-content>
      <div class="overlay"></div>

      <v-container fluid fill-height>
        <img
          :src="branding.logo_url"
          width="300px"
          style="position: absolute; top: 30px; left: 30px; filter: brightness(0) invert(1); opacity: 0.5" />
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4 xl3>
            <v-card class="elevation-0" flat v-if="auth_method === 'azure'" color="rgba(255,255,255,.7)">
              <v-card-text class="text-xs-center">
                <img :src="require('@/assets/plant-logo.svg')" class="ma-3" />
                <v-btn
                  block
                  large
                  :href="login_url"
                  flat
                  outline
                  style="margin: auto"
                  color="black"
                  :disabled="pending"
                  class="mt-3">
                  <img :src="require('@/../assets/ms-logo.png')" />
                  &nbsp; &nbsp; Sign in with Microsoft Account
                </v-btn>
              </v-card-text>
              <v-card-actions class="justify-center">
                <p>Version: <b>{{ build_version }}</b>&emsp;Build date: {{ build_date }}</p>
              </v-card-actions>
            </v-card>
            <v-card class="elevation-12" style="background: rgba(255, 255, 255, 0.8)" v-else-if="auth_method === 'ad'">
              <v-card-text class="text-xs-center">
                <div style="padding: 35px; width: 100%">
                  <v-img :src="require('@/assets/plant-logo.svg')"></v-img>
                </div>
                <div style="width: 75%; margin: 0 auto">
                  <form>
                    <v-text-field v-model="username" label="User" prepend-icon="mdi-account" required></v-text-field>
                    <v-text-field
                      v-model="password"
                      label="Password"
                      type="password"
                      prepend-icon="mdi-key"
                      required></v-text-field>
                    <div class="spacer">
                      <v-btn flat outline block @click="submit"> <v-icon left>mdi-login</v-icon>Login </v-btn>
                    </div>
                  </form>
                </div>
              </v-card-text>
              <v-card-actions class="justify-center">
                <p>Version: <b>{{ build_version }}</b>&emsp;Build date: {{ build_date }}</p>
              </v-card-actions>
            </v-card>

            <v-card v-else class="elevation-12">
              <v-card-text class="text-xs-center">
                <div style="padding: 35px; width: 100%">
                  <v-img :src="require('@/assets/plant-logo.svg')"></v-img>
                </div>
                <div style="width: 75%; margin: 0 auto">
                  Auth method
                  <b>{{ auth_method }}</b> not valid
                </div>
              </v-card-text>
              <v-card-actions class="justify-center">
                <p>Version: <b>{{ build_version }}</b>&emsp;Build date: {{ build_date }}</p>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex"
import { login_url } from "./login_url.js"
import branding from "@/branding"
import {
  STORE_NOTIFICATIONS_NAME,
  ACTION_NOTIFICATIONS_INFO,
  ACTION_NOTIFICATIONS_ERROR
} from "@/store_constants/notifications"
import { STORE_USER_NAME, STATE_PENDING, ACTION_API_LOGIN } from "@/store_constants/user"

var getFragmentArgs = function () {
  var returnVal = {}

  var argString = window.location.hash
  //everything after src belongs as part of the url, not to be parsed
  var argsAndSrc = argString.split(/src=/)
  returnVal["src"] = argsAndSrc[1]
  //everything before src is args for this page.
  var argArray = argsAndSrc[0].split("&")
  for (var i = 0; i < argArray.length; i++) {
    var nameVal = argArray[i].split("=")
    //strip the hash
    if (i == 0) {
      var name = nameVal[0]
      nameVal[0] = name.slice(1)
    }
    returnVal[nameVal[0]] = decodeURI(nameVal[1])
  }
  return returnVal
}
export default {
  data() {
    return {
      build_date: process.env.VUE_APP_BUILD,
      build_version: process.env.VUE_APP_VERSION,
      login_url,
      auth_method: window.__env__.VUE_APP_AUTH_METHOD,
      username: "",
      password: "",
      branding
    }
  },
  created() {
    var params = getFragmentArgs()
    if (params.id_token) {
      let credentials = {
        id_token: params.id_token
      }
      this.apiLogin({ credentials })
        .then(res => {
          if (!res.isAxiosError) {
            this.$router.push("/dashboard")
          }
        })
        .catch(e => {
          this.error({ message: e })
        })
    } else if (params.error) {
      this.error({ message: "Error message: " + params.error })
    }
  },
  computed: {
    ...mapState(STORE_USER_NAME, {
      pending: STATE_PENDING
    })
  },
  methods: {
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      info: ACTION_NOTIFICATIONS_INFO,
      error: ACTION_NOTIFICATIONS_ERROR
    }),
    ...mapActions(STORE_USER_NAME, {
      apiLogin: ACTION_API_LOGIN
    }),
    submit() {
      if (this.username && this.password) {
        let credentials = {
          username: this.username,
          password: this.password
        }
        this.apiLogin({ credentials })
          .then(m => this.$router.push("/dashboard"))
          .catch(e => this.error({ message: e }))
      } else {
        this.error({ message: "Error message: Fill the credentials" })
      }
    }
  }
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  background-size: cover;
  z-index: -0;
}

.spacer {
  margin: 16px 0;
}

body {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
