<template>
  <p-panel v-if="lessonPlanController.editLayouts" title="Layout Format">
    <p-panel-properties>
      <grid-size-selector></grid-size-selector>
    </p-panel-properties>
  </p-panel>

  <p-panel v-else title="Global Format">
    <p-panel-properties>
      <grid-size-selector></grid-size-selector>

      <!-- Slide Format -->
      <p-panel-properties-group icon="mdi-card-outline" title="Slide Format">
        <style-select :value="slideStyles" title="Slide styles" />
      </p-panel-properties-group>

      <!-- Slide Wrapper Format -->
      <p-panel-properties-group icon="mdi-arrange-send-backward" title="Slide Wrapper Format">
        <style-select :value="lessonPlanController.lessonPlanContents.settings.styles.wrapper" title="Wrapper styles" />
      </p-panel-properties-group>

      <!-- Palette -->
      <p-panel-properties-group icon="mdi-palette-swatch-outline" title="Palette">
        <item-style
          icon="mdi-water-outline"
          :title="`Color ${index + 1}`"
          v-for="(color, index) in lessonPlanController.lessonPlanContents.settings.styles.colors"
          :key="index">
          <preset-input-color
            :value="lessonPlanController.lessonPlanContents.settings.styles.colors[index]"
            @input="changeColor($event, index)"></preset-input-color>
        </item-style>
      </p-panel-properties-group>

      <!-- Default Block Format -->
      <p-panel-properties-group icon="mdi-toy-brick-outline" title="Default Block Format">
        <p-property-tile icon="mdi-format-font" title="Font family">
          <v-select
            :items="lessonPlanController.fontFamilies"
            v-model="lessonPlanController.lessonPlanContents.settings.styles.block.fontFamily"
            class="lp-info-left-panel-select"></v-select>
        </p-property-tile>
      </p-panel-properties-group>

      <!-- Navigation -->
      <p-panel-properties-group icon="mdi-compass-outline" title="Navigation">
        <p-property-tile icon="mdi-traffic-light-outline" title="Restrict navigation">
          <v-select
            :items="navigationOptions"
            v-model="lessonPlanController.lessonPlanContents.settings.styles.navigation.locked"
            class="lp-info-left-panel-select"></v-select>
        </p-property-tile>

        <p-property-tile icon="mdi-home" title="Home Slide">
          <span class="pointer" @click="openSlideSelectorDialog">{{ homeSlide }}...</span>
        </p-property-tile>
      </p-panel-properties-group>

      <!-- Advanced -->
      <p-panel-properties-group icon="mdi-puzzle-outline" title="Advanced">
        <p-property-tile icon="mdi-robot-outline" title="Macros (requires reload)">
          <v-switch :input-value="macrosActive" @change="updateMacrosActive" hide-details></v-switch>
        </p-property-tile>
      </p-panel-properties-group>
    </p-panel-properties>
  </p-panel>
</template>

<script>
import { getCustomId, getTitle } from "../../../../../utils/dbTool.js"
import { PToolbarButton, PtModal } from "plant-common"
import SlideWrapperStyle from "./SlideWrapperStyle.vue"
import ItemStyle from "./ItemStyle.vue"
import PresetInputColor from "./PresetInputColor.vue"
import PPanelProperties from "../../../blocks/_components/utils/PPanelProperties.vue"
import PPropertyTile from "../../../blocks/_components/utils/PPropertyTile.vue"
import PPanel from "../../../blocks/_components/utils/PPanel.vue"
import PPanelPropertiesGroup from "../../../blocks/_components/utils/PPanelPropertiesGroup.vue"
import StyleSelect from "./StyleSelect.vue"
import SlideSelector from "../modal/SlideSelector.vue"
import navigationOptions from "../constants/navigationOptions"
import GridSizeSelector from "./GridSizeSelector.vue"

export default {
  inject: ["lessonPlanController"],
  components: {
    PToolbarButton,
    SlideWrapperStyle,
    ItemStyle,
    PresetInputColor,
    PPanelProperties,
    PPanel,
    PPropertyTile,
    PPanelPropertiesGroup,
    StyleSelect,
    SlideSelector,
    GridSizeSelector
  },
  data() {
    return {
      macrosActive: localStorage.PlantUserMacrosActive === "true",
      panel: 0,
      availableSizes: [
        { text: "None", value: [1, 1] },
        { text: "Extra Small", value: [40, 22.5] },
        { text: "Small", value: [80, 45] },
        { text: "Medium small", value: [120, 67.5] },
        { text: "Medium", value: [160, 90] },
        { text: "Big", value: [320, 180] }
      ],
      navigationOptions: navigationOptions
    }
  },
  created() {
    if (
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation &&
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation.locked === true
    ) {
      // Update navigation.locked model (before: boolean - true/false, now: string - navigationOptions)
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation.locked = "sequential"
    }
    if (!this.lessonPlanController.lessonPlanContents.settings.styles.navigation) {
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation = {
        locked: false
      }
    }
  },
  computed: {
    slideStyles() {
      const styles = this.lessonPlanController.lessonPlanContents.settings.styles.slide
      const newStyles = {}

      for (let key in styles) {
        if (key !== "color") {
          newStyles[key] = styles[key]
        }
      }

      this.lessonPlanController.lessonPlanContents.settings.styles.slide = newStyles

      return this.lessonPlanController.lessonPlanContents.settings.styles.slide
    },

    homeSlide() {
      if (!this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home) {
        this.$set(this.lessonPlanController.lessonPlanContents.settings.styles.navigation, "home", { slide_id: null })
      }
      let slide = this.lessonPlanController.getSlideFromSlideId(
        this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home.slide_id
      )

      if (slide) {
        let slideIndex = this.lessonPlanController.slideArray.findIndex(s => s.id === slide.id)
        return `${slide.title} (Slide ${slideIndex + 1})`
      } else if (this.lessonPlanController.slideArray.length > 0) {
        this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home.slide_id = null
        return `${this.lessonPlanController.slideArray[0].title}  (Slide 1, default)`
      } else {
        return "[Not Set]"
      }
    }
  },
  mixins: [PtModal],
  methods: {
    updateMacrosActive(value) {
      this.macrosActive = value
      localStorage.PlantUserMacrosActive = value
    },
    getTitle: getTitle,
    getCustomId: getCustomId,
    changeColor($event, index) {
      this.$set(this.lessonPlanController.lessonPlanContents.settings.styles.colors, index, $event)
    },
    openSlideSelectorDialog() {
      this.$modal(SlideSelector, { lessonPlanController: this.lessonPlanController }).then(slide => {
        if (!slide) return
        this.setSlideAsHome(slide)
      })
    },
    setSlideAsHome(slide) {
      this.lessonPlanController.lessonPlanContents.settings.styles.navigation.home.slide_id = slide.id
    }
  }
}
</script>

<style>
.lp-info-left-panel-action button {
  min-width: 20px;
}

.lp-info-left-panel-select .v-select__slot {
  width: 125px;
}
</style>
../constants/navigationOptions.js