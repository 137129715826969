export function scaleActivity(content) {
  const blockContainer = getBlockContainer(content);
  if (blockContainer) {
    const scale = blockContainer.offsetWidth / content.offsetWidth;
    if (scale) {
      content.setAttribute(
        "style",
        `position: absolute; top: 15%; left: 50%; transform: translate(-50%, -15%) scale(${scale})`
      );
    }
  }
}

function getBlockContainer(content) {
  return content.closest(".lpc-block-library-content");
}
