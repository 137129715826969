<template>
  <v-list>
    <v-list-tile avatar>
      <v-list-tile-content>
        <h2>Upload Progress</h2>
      </v-list-tile-content>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-content>
        <v-list-tile-title
          >Progess ( {{ uploadProgress }}%)</v-list-tile-title
        >
        <v-progress-linear v-model="uploadProgress"></v-progress-linear>
      </v-list-tile-content>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon color="primary">mdi-clock-outline</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>Pending:</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        {{ items.filter((c) => c.status == "not-started").length }}
      </v-list-tile-action>
    </v-list-tile>
    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon color="orange">mdi-reload</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>Processing:</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        {{ items.filter((c) => c.status == "in-progress").length }}
      </v-list-tile-action>
    </v-list-tile>

    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon color="success">mdi-check-circle-outline</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>Completed:</v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        {{ items.filter((c) => c.status == "done").length }}
      </v-list-tile-action>
    </v-list-tile>

    <v-list-tile avatar>
      <v-list-tile-avatar>
        <v-icon color="red">mdi-alert-circle-outline</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title>Errors: </v-list-tile-title>
      </v-list-tile-content>
      <v-list-tile-action>
        {{ items.filter((c) => c.status == "error").length }}
      </v-list-tile-action>
    </v-list-tile>
  </v-list>
</template>

<script>
export default {
  props: ["items"],
  computed: {
    uploadProgress() {
      return (
        Math.floor((this.items.filter((c) => c.status == "done").length /
          this.items.length) *
        100)
      );
    },
  },
};
</script>

<style>
</style>