<template>
  <v-dialog :value="show" fullscreen>
    <v-card flat tile>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn flat icon @click="show = false"><v-icon>close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="card-text-height">
        <v-layout fill-height align-center>
          <div class="zoomist-container">
            <div class="zoomist-wrapper">
              <div class="zoomist-image">
                <img ref="imgRef" :src="src" />
              </div>
            </div>
          </div>
        </v-layout>
      </v-card-text>
      <v-card-actions class="card-actions-height">
        <div class="custom-zoomist-slider"></div>
        <v-spacer></v-spacer>
        <v-slider
          max="10"
          min="1"
          v-model="zoomValue"
          step="0"
          prepend-icon="mdi-magnify-minus"
          @click:prepend="zoomBy(-0.4)"
          append-icon="mdi-magnify-plus"
          @click:append="zoomBy(0.4)"></v-slider>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Zoomist from 'zoomist'

export default {
  name: "FullScreenImage",
  props: {
    src: String
  },
  mounted() {
    this.canvasImage = new Zoomist('.zoomist-container', {
      maxScale: 10,
      bounds: true,
      slider: false,
      zoomer: false
    });

    this.canvasImage.on('zoom', (_, scale) => {
      this.zoomValue = scale;
    });

    this.imgRef = this.$refs['imgRef']

    this.adjustImageSize();

    window.addEventListener('resize', () => {
      if (this.show) this.adjustImageSize();
    });
  },
  data() {
    return {
      show: true,
      zoomValue: 1,
      canvasImage: undefined,
      imgRef: undefined
    }
  },
  methods: {
    zoomBy(value) {
      this.zoomValue += value;
    },
    adjustImageSize() {

      if (!this.imgRef) return;

      const container = document.getElementsByClassName('zoomist-container')[0];

      const widthContainer = container.offsetWidth;
      const heightContainer = container.offsetHeight;

      const actualImg = new Image();
      actualImg.src = this.imgRef.src;

      actualImg.onload = () => this.onLoadImage(actualImg, widthContainer, heightContainer);
    },
    onLoadImage(actualImg, widthContainer, heightContainer) {
      let width = actualImg.width;
      let height = actualImg.height;

      const minSize = 200;

      if (width < minSize) width = minSize;
      if (height < minSize) height = minSize;

      if (width > widthContainer) {
        const ratio = widthContainer / width;

        width *= ratio;
        height *= ratio;
      }

      if (height > heightContainer) {
        const ratio = heightContainer / height;

        width *= ratio;
        height *= ratio;
      }

      this.imgRef.style.width = `${width}px`;
      this.imgRef.style.height = `${height}px`;
    }
  },
  watch: {
    zoomValue(value) {
      this.canvasImage.zoomTo(value);
    }
  }
}
</script>

<style scoped>
.card-text-height {
  height: calc(100vh - 140px);
}

.card-actions-height {
  height: 5vh;
}

.zoomist-container {
  width: 100%;
  height: 100%;
}

.zoomist-image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.zoomist-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
</style>
