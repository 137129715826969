<template>
  <v-card class="transparent elevation-0">
    <v-toolbar flat  extended>
      <template v-slot:extension>
        <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="700px">
          <v-btn slot="activator" color="green" dark class="mb-2">
            <v-icon>add</v-icon>Add Library
          </v-btn>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 class="plant-data-container">
                    <v-text-field v-model="editedItem.name" label="Library's name"></v-text-field>
                    <v-switch v-model="editedItem.active" label="Active"></v-switch>
                    <v-textarea v-model="editedItem.description" type="text" label="Description" no-resize></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click.native="close">Cancel</v-btn>
              <v-btn color="success" flat @click.native="save" outline > <v-icon>mdi-content-save</v-icon>Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="infoDialog" max-width="700px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12 class="plant-data-container">
                    <v-text-field v-model="editedItem.name" label="Library's name" outlined disabled></v-text-field>
                    <v-textarea v-model="editedItem.description" type="text" label="Description" outlined disabled no-resize></v-textarea>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" flat @click.native="close">Exit</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-toolbar-title>Site Libraries</v-toolbar-title>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="libraries"
      :search="search"
      class="elevation-1 scrollDataTable"
    >
      <template slot="items" slot-scope="props">
        <tr @click="showInfoLibrary($event, props.item)">
          <td>{{ props.item.name || "-" }}</td>
          <td>{{ props.item.active }}</td>

          <td class="text-right layout px-0">
            <v-btn icon flat><v-icon   @click.stop="editLibrary(props.item)">edit</v-icon></v-btn>
            <!-- <v-icon @click.stop="removeLibrary(props.item)">delete</v-icon> -->
          </td>
        </tr>
      </template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { PtModal } from "plant-common";
import {
  STORE_FLEET_NAME,
  STATE_ALL_LIBRARIES,
  ACTION_GET_LIBRARIES,
  ACTION_DELETE_LIBRARY,
  ACTION_CREATE_LIBRARY,
  ACTION_UPDATE_LIBRARY,
} from "@/store_constants/fleet";
export default {
  mixins: [PtModal],
  data: () => ({
    dialog: false,
    infoDialog: false,
    search: "",
    headers: [
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name"
      },
      { text: "Active", value: "active" }, 
      { text: "Actions", value: "actions", sortable: false }
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      description: "",
      img: "",
      active: false
    },
    defaultItem: {
      name: "",
      description: "",
      img: ""
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Library" : this.editedIndex === -2 ? "Library Info" : "Edit Library";
    },
    ...mapState(STORE_FLEET_NAME, {
      libraries: STATE_ALL_LIBRARIES
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    infoDialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(STORE_FLEET_NAME, {
      getLibraries: ACTION_GET_LIBRARIES, 
      deleteLibrary: ACTION_DELETE_LIBRARY, 
      createLibrary: ACTION_CREATE_LIBRARY, 
      updateLibrary: ACTION_UPDATE_LIBRARY
    }),
    initialize() {
      this.getLibraries();
    },
    editLibrary(item) {
      this.editedIndex = this.libraries.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    removeLibrary(library) {
      const index = this.libraries.indexOf(library);
      this.$modal("confirm", {
        title: `Are you sure you want to delete "${library.name}" form PLANT libraries?`,
        accept: {
          text: "Delete",
          color: "red",
          icon: "mdi-delete"
        },
        cancel: {
          text: "Cancel",
          color: "default"
        }
      }).then(r => {
        if (r) {
          this.deleteLibrary(library._id)
            .then(this.getLibraries)
            .catch(err => console.log(err));
        }
      });
    },
    close() {
      this.dialog = false;
      this.infoDialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        this
          .updateLibrary({
            id: this.editedItem._id,
            name: this.editedItem.name,
            description: this.editedItem.description,
            active: this.editedItem.active,
            img: this.editedItem.img
          })
          .then(this.getLibraries)
          .catch(err => console.log(err));
      } else {
        this
          .createLibrary({
            name: this.editedItem.name,
            desc: this.editedItem.description,
            img: this.editedItem.img
          })
          .then(this.getLibraries)
          .catch(err => console.log(err));
      }
      this.close();
    },

    showInfoLibrary(e, item) {
      e.stopPropagation();
      this.editedIndex = -2;
      this.editedItem = Object.assign({}, item);
      this.infoDialog = true;
    },
  }
};
</script>

<style scoped>
.scrollDataTable {
  max-height: calc(100vh - 300px);
  overflow: auto;
}

.plant-data-container{
  padding-left: 5%;
}
</style>
