/* eslint-disable no-redeclare */
// PLANT masters transition from version 0 to version 1.0

export default function (svgElement) {

    if (!svgElement) return
    preProcessVisio(svgElement)
    const MASTERS_MAP = {
        "g.piping-pipe-primary": "pt-piping-pipe-primary",
        "g.piping-pipe-secondary": "pt-piping-pipe-secondary",
        "g.piping-pump-centrifugal": "pt-piping-pump-centrifugal",

        "g.penetration": "pt-piping-penetration",
        "g.piping-valve-local": "pt-piping-valve-local",
        "g.piping-valve-remote": "pt-piping-valve-remote",
        "g.piping-valve-safety-remote": "pt-piping-valve-safety-remote",
        "g.piping-valve-threeway": "pt-piping-valve-threeway",
        "g.pt-piping-pump-vertical": "pt-piping-pump-vertical",
        "g.ui-table-link": "pt-ui-table-link",
        "g.instrumentation-instrument": "pt-instrumentation-instrument",
        "g.piping-page-connector": "pt-piping-page-connector",
        "g.logics-input": "pt-logic-input",
        "g.logics-output": "pt-logic-output",
        "g.logics-connector": "pt-logic-connector",
        "g.logics-or": "pt-logic-or",
        "g.logics-split": "pt-logic-split",
        "g.logics-not": "pt-logic-not",
        "g.logics-and": "pt-logic-and",
        "g.logics-and2": "pt-logic-and2",
        "g.logics-memory-rl": "pt-logic-memory-rl",
        "g.logics-memory-lr": "pt-logic-memory-lr",
        "g.logics-memory-rs": "pt-logic-memory-rs",
        "g.logics-memory-sr": "pt-logic-memory-sr",
        "g.logics-timer": "pt-logic-delay-true",
        "g.logics-one-shot": "pt-logic-one-shot",
        "g.pt-logic-select2": "pt-logic-select2",
        "g.pt-piping-pump": "pt-piping-pump",
        "g.pt-component-static": "pt-component-static",

        "g.piping-penetration": "pt-piping-penetration",
        "g.pt-logic-wipeout": "pt-logic-wipeout",
        "g.pt-electric-switch": "pt-electric-switch",
        "g.pt-electric-bus": "pt-electric-bus",
        "g.pt-electric-wire": "pt-electric-wire",
        "g.pt-electric-transformer": "pt-electric-transformer",
        "g.pt-electric-disconnect-switch": "pt-electric-disconnect-switch",

        "g.pt-relay-supply": "pt-relay-supply",
        "g.pt-relay-supply-bus": "pt-relay-supply-bus",
        "g.pt-relay-wire": "pt-relay-wire",
        "g.pt-relay-coil": "pt-relay-coil",
        "g.pt-relay-push-no": "pt-relay-push-no",
        "g.pt-relay-push-nc": "pt-relay-push-nc",
        "g.pt-relay-contact-coil-nc": "pt-relay-contact-coil-nc",
        "g.pt-relay-contact-coil-no": "pt-relay-contact-coil-no",
        "g.pt-relay-contact-manual-no": "pt-relay-contact-manual-no",
        "g.pt-relay-contact-manual-nc": "pt-relay-contact-manual-nc",
        "g.pt-relay-control": "pt-relay-control",
        "g.pt-relay-light": "pt-relay-light",
        "g.pt-relay-intersection": "pt-relay-intersection",
        "g.pt-keypoint-point": "pt-keypoint-point",
        // Nuscale
        "g.pt-nuscalepiping-valve-local": "pt-nuscale-piping-valve-local",
        "g.pt-nuscalepiping-valve-remote": "pt-nuscale-piping-valve-remote",
        "g.pt-nuscale-piping-pipe-primary": "pt-nuscale-piping-pipe-primary",
        "g.pt-nuscale-piping-pipe-secondary": "pt-nuscale-piping-pipe-secondary",
        "g.pt-nuscale-piping-pump-centrifugal": "pt-nuscale-piping-pump-centrifugal",
        "g.pt-nuscale-piping-pump-alt": "pt-nuscale-piping-pump-alt",
        "g.pt-nuscale-piping-valve-threeway": "pt-nuscale-piping-valve-threeway",
        "g.pt-nuscale-piping-connector": "pt-nuscale-piping-connector",

    }
    const CLASS_MAP = {
        "g.piping-pipe-primary": "pt-piping-pipe-primary",
        "g.piping-pipe-secondary": "pt-piping-pipe-secondary",
        "g.piping-valve-remote g.piping-valve-left": "pt-piping-valve-remote__left",
        "g.piping-valve-remote g.piping-valve-right": "pt-piping-valve-remote__right",
        "g.piping-valve-remote": "pt-piping-valve-remote",
        "g.piping-valve-local g.piping-valve-left": "pt-piping-valve-local__left",
        "g.piping-valve-local g.piping-valve-right": "pt-piping-valve-local__right",
        "g.piping-valve-local": "pt-piping-valve-local",
        "g.piping-valve-threeway g.piping-valve-threeway-act": "pt-piping-valve-threeway__act",
        "g.piping-valve-threeway g.piping-valve-threeway-unact": "pt-piping-valve-threeway__unact",
        "g.piping-valve-threeway": "pt-piping-valve-threeway",
        "g.piping-valve-safety-remote g.piping-valve-safety-down": "pt-piping-valve-safety-remote__down",
        "g.piping-valve-safety-remote g.piping-valve-safety-right": "pt-piping-valve-safety-remote__right",
        "g.piping-valve-safety-remote": "pt-piping-valve-safety-remote",
        "g.piping-pump-centrifugal": "pt-piping-pump-centrifugal",
        "g.piping-pump-centrifugal-body": "pt-piping-pump-centrifugal__body",
        "g.piping-pump-centrifugal-arrow": "pt-piping-pump-centrifugal__arrow",
        "g.ui-table-link": "pt-ui-table-link",
        "g.piping-dot": "pt-std-hide",
        "g.piping-page-connector": "pt-piping-page-connector",
        "g.logics-input": "pt-logic-input",
        "g.logics-connector": "pt-logic-connector",
        "g.logics-and": "pt-logic-and",
        "g.logics-and2": "pt-logic-and2",
        "g.logics-or": "pt-logic-or",
        "g.logics-output": "pt-logic-output",
        "g.logics-memory-rl": "pt-logic-memory-rl",
        "g.logics-memory-lr": "pt-logic-memory-lr",
        "g.logics-memory-rs": "pt-logic-memory-rs",
        "g.logics-memory-sr": "pt-logic-memory-sr",
        "g.logics-timer": "pt-logic-delay-true",
        "g.logics-timer-body": "pt-logic-delay-true__body",
        "g.logics-one-shot-body": "pt-logic-one-shot__body",
        "g.logics-one-shot": "pt-logic-one-shot",
        "g.logics-not": "pt-logic-not",
        "g.piping-penetration": "pt-piping-penetration",
        "g.instrumentation-instrument": "pt-instrumentation-instrument",
        "g.pt-instrumentation-instrument > g[transform]:not([transform=''])": "highlightable", //https://tecnatomusa.visualstudio.com/plant/_workitems/edit/5345/
        "g.piping-penetration-penetration": "pt-piping-penetration__pipe",
        "g.pt-piping-manual-tag": "pt-tag",
        "g.logics-split": "pt-logic-split",
    }

    for (var s in MASTERS_MAP) {
        svgElement.querySelectorAll(s).forEach((elm) => elm.setAttribute("data-master", MASTERS_MAP[s]))
    }
    for (var s in CLASS_MAP) {
        svgElement.querySelectorAll(s).forEach((elm) => elm.setAttribute("class", CLASS_MAP[s]))
    }

    svgElement.querySelectorAll("[data-class]").forEach((elm) => elm.removeAttribute("data-class"))

    // Update attribute "data-input" to follow the pattern: input0=id:prop,input1=id:prop
    for (var elm of svgElement.querySelectorAll("[data-inputs]")) {
        elm.dataset.inputs = elm.dataset.inputs
            .split(",")
            .map((e, index) => {
                if (e.match(/([^=]*)=([^:]*):(.*)/g)) {
                    var [name, id, prop] = e.split(/:|=/g)
                    if (prop == "") prop = "value"
                    return `${name}=${id}:${prop}`
                }
                else {
                    var [id, prop] = e.split(":")
                    if ((!isNaN(prop) && typeof parseInt(prop) == "number") || prop == "" || prop == undefined) prop = "value"
                    return `input${index}=${id}:${prop}`
                }
            })
            .join(",")
    }
}

const VISTAG_PROPS = "v\\:custprops"
const VISTAG_DEFS = "v\\:userdefs"

var vval = function (e) {
    if (e.hasAttribute("v:val")) {
        return e.getAttribute("v:val").replace(/VT\d?\((.*)\):?(.*)/, "$1");
    } else {
        return ""
    }
};

var visioToObject = function (g) {
    var obj = { user: {}, prop: {} };
    var custprops = g.querySelector(VISTAG_PROPS)
    var userdefs = g.querySelector(VISTAG_DEFS)
    if ((custprops != null) && (custprops.parentNode == g)) {
        for (var i = 0; i < custprops.children.length; i++) {
            obj.prop[custprops.children[i].getAttribute("v:nameu")] = vval(custprops.children[i])
        }
    }

    if ((userdefs != null) && (userdefs.parentNode == g)) {
        for (var i = 0; i < userdefs.children.length; i++) {
            obj.user[userdefs.children[i].getAttribute("v:nameu")] = vval(userdefs.children[i])
        }
    }

    return obj;
}

const preProcessVisio = function (svgElement) {

    const VISIO_TAGS = ["v:documentproperties", "v:pageproperties", "v:userdefs", "v:custprops"]
    const VISIO_TAGS_SELECTOR = VISIO_TAGS.map((e) => e.replace(/(.*):(.*)/g, "$1\\:$2")).join(",")
    const VISIO_ATTR = ["v:mid", "v:groupcontext"]
    const VISIO_ATTR_SELECTOR = VISIO_ATTR.map((e) => e.replace(/(.*):(.*)/g, "[$1\\:$2]")).join(",")

    svgElement.querySelectorAll("g").forEach(function (g) {
        var titleElement = g.querySelector("title")
        if (titleElement) {
            g.id = titleElement.innerHTML
            titleElement.innerHTML = ""
        }
        var obj = visioToObject(g)
        if (obj.user.class) {
            g.setAttribute("class", obj.user.class)
        }
        if (obj.prop.class) {
            g.setAttribute("class", obj.prop.class)
        }

        for (var p in obj.user) {
            g.setAttribute("data-" + p, obj.user[p])
        }

        for (var p in obj.prop) {
            g.setAttribute("data-" + p, obj.prop[p])
        }

    })

    // add Group Context
    svgElement.querySelectorAll("[v\\:groupcontext]").forEach((e) => e.classList.add("pt-group-context__" +e.getAttribute("v:groupcontext")))
    
    svgElement.querySelectorAll(VISIO_TAGS_SELECTOR).forEach((e) => e.remove())
    svgElement.querySelectorAll(VISIO_ATTR_SELECTOR).forEach((e) => VISIO_ATTR.forEach((attr) => e.removeAttribute(attr)))
    svgElement.removeAttribute("xmlns:v")
}
