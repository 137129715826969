<template>
  <v-btn color="success" outline @click="uploadItemDraft()" :disabled="locked">
    <v-icon left>mdi-content-save-outline</v-icon>SAVE
  </v-btn>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { STORE_MODULE_EDITOR, ACTION_UPLOAD_DRAFT, STATE_LOCKED_DRAFT } from "../../../store_constants/content"

export default {
  name: "TitleBarSave",
  computed: {
    ...mapState(STORE_MODULE_EDITOR, {
      locked: STATE_LOCKED_DRAFT
    })
  },
  methods: {
    ...mapActions(STORE_MODULE_EDITOR, {
      uploadItemDraft: ACTION_UPLOAD_DRAFT
    })
  }
}
</script>
