<template>
  <figure v-lazyload class="image__wrapper">
    <ImageSpinner class="image__spinner" />
    <img class="image__item" :data-url="srcImage" alt="" />
  </figure>
</template>
<script>
import { getContentId, getType } from "@/utils/dbTool"
import ImageSpinner from "./ImageSpinner"
import LazyLoadDirective from "@/directives/LazyLoadDirective"
const THUMBNAIL_PREVIEW_SMALL = "small"

export default {
  name: "PreviewContentImage",
  props: ["content", "library"],
  components: { ImageSpinner },
  directives: {
    lazyload: LazyLoadDirective
  },
  computed: {
    srcImage() {
      let content_id = getContentId(this.content)
      let type = getType(this.content)
      if (
        type != "guide" &&
        type != "guideTemplate" &&
        type != "glbModel" &&
        type != "pointCloud" &&
        type != "interactiveText"
      ) {
        let { major_revision, minor_revision } = this.content.header
        let viewJsonStr = JSON.stringify(undefined)
        let viewJsonEncode = encodeURI(viewJsonStr)
        let size = "default"
        return `api/library/${this.library}/preview/${content_id}/${major_revision}/${minor_revision}/${THUMBNAIL_PREVIEW_SMALL}?view=${viewJsonEncode}`
      } else {
        switch (type) {
          case "guide":
            return require("@/../public/img/placeholders/guide.png")
            break
          case "guideTemplate":
            return require("@/../public/img/placeholders/guide_template.png")
            break
          case "glbModel":
            return require("@/../public/img/placeholders/threed.png")
            break
          case "pointCloud":
            return require("@/../public/img/placeholders/threed.png")
            break
          case "interactiveText":
            return require("@/../public/img/placeholders/interactive_text.png")
            break
          default:
            return require("@/../public/img/placeholders/no_image.png")
            break
        }
      }
    }
  }
}
</script>

<style scoped>
.image__item {
  width: 100%;
}

.loaded .image__spinner {
  display: none;
}

.image__wrapper {
  text-align: center;
}
</style>
