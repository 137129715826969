import uuid from "../../../../utils/uuid.js"

export class BlockBasic {
  constructor(block) {
    if (!block) {
      this.id = uuid()
      this.name = ""
      this.position = { x: 0, y: 0 }
      this.animations = [new Animation()]
      this.has_activity = false
      this.size = { width: 600, height: 600 }
      this.rotation = 0
      // Protection type list of allowed type strings
      this.protection = {
        type: [],
        data: false,
        move: false,
        resize: false,
        delete: false
      }
      this.flip = { x: false, y: false }
      this.data = {}
      this.actions = []
    } else {
      Object.assign(this, block)
    }
  }
}

/**
 * Block actions
 */
export class BlockAction {
  constructor(action) {
    this.id = uuid()
    if (!action) {
      // "hover" or "click"
      this.trigger = undefined
    } else {
      Object.assign(this, action)
    }
  }
}

export class BlockActionLink extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "link"
    this.bookmark_target = ""
  }
}

export class BlockActionExternalLink extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "externalLink"
    this.url = ""
  }
}

export class BlockActionTooltip extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "tooltip"
    this.text = ""
  }
}

export class BlockActionPopup extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "popup"
    this.width = 1720
    this.height = 880
    this.style = {
      backgroundColor: "#ffffffff",
      /* borderRadius will change to 50px */
      borderRadius: 0
    }
    this.blocks = []
  }
}

export class BlockActionLibraryContentPopup extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "library"
    this.fullWidth = true
    this.content_ref = {}
  }
}

export class BlockActionLibraryContentNewTab extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "libraryNewTab"
    this.content_ref = {}
  }
}

export class BlockActionEvent extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "event"
    this.event = ""
  }
}

export class BlockActionClose extends BlockAction {
  constructor(action) {
    super(action)
    this.type = "close"
  }
}

export class Animation {
  constructor() {
    this.trigger = "auto"
    // animation's name from Animate.css or "none"
    this.name = "none"
    // duration in seconds */
    this.duration = 1
    // delay in seconds */
    this.delay = 0
  }
}
