<template>
  <v-container grid-list-md class="fix-grid content-grid">
    <h3 class="subheading lighten-4 mb-3" v-if="folders.length > 0">Folders</h3>
    <v-layout v-if="folders.length > 0" row wrap class="mb-4">
      <v-flex xs12 sm6 md4 lg3 v-for="item in folders" :key="item._id">
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            @click="item.header ? $emit('input', item) : $emit('folder', item)"
            :class="`elevation-${hover ? 4 : 1} content-grid-card`">
            <!-- Card folder -->
            <card-folder :name="item.name" />
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
    <h3 class="subheading lighten-4 mb-3" v-if="contents.length > 0">Contents</h3>
    <v-layout row wrap>
      <v-flex xs12 sm6 md4 lg3 v-for="item in contents" :key="item._id">
        <v-hover>
          <v-card
            slot-scope="{ hover }"
            height="100%"
            @click="item.header ? $emit('input', item) : $emit('folder', item)"
            :class="`elevation-${hover ? 4 : 1} content-grid-card`">
            <preview-content-image
              v-if="typeView == 'preview'"
              :content="item"
              :library="library"></preview-content-image>

            <!-- Card content-->
            <card-content
              :custom_id="getCustomId(item)"
              :title="getTitle(item)"
              :date="new Date(parseInt(item._id.substring(0, 8), 16) * 1000) | moment"
              :type="getType(item)" />
          </v-card>
        </v-hover>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { getTitle, getType, getCustomId } from "../../../utils/dbTool"
import moment from "moment"
import PreviewContentImage from "@/components/explorer/PreviewContentImage"
import CardFolder from "@/components/explorer/CardFolder"
import CardContent from "@/components/explorer/CardContent"

export default {
  props: ["items", "typeView", "library"],
  components: { PreviewContentImage, CardFolder, CardContent },
  data() {
    return {
      page: 1
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format("LL")
    }
  },
  computed: {
    folders() {
      return this.items.filter(item => !item.header)
    },
    contents() {
      return this.items.filter(item => item.header)
    }
  },
  methods: {
    getTitle: getTitle,
    getType: getType,
    getCustomId: getCustomId
  }
}
</script>

<style scoped>
.fix-grid {
  overflow-y: auto;
  max-height: calc(100vh - 180px);
  max-width: 100%;
}

.content-grid-card {
  cursor: pointer;
}

.pagination {
  margin-top: 20px;
}
</style>