import { Extensions } from 'plant-common';

export const apps = ['youtubeVideo'];

export function getAppName(contentType) {
	const separator = ' ';
	const glue = '-';

	return Extensions[contentType].title.split(separator).join(glue).toLowerCase();
}

export function getAppUrl(appName, libraryId, contentId, revision) {
	return `/apps/${appName}-app/index.html?libraryId=${libraryId}&contentId=${contentId}&revision=${revision}`;
}
