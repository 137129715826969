<template>
  <component :is="component" />
</template>

<script>
import PropertiesCover from "./PropertiesCover"
import PropertiesSection from "./PropertiesSection"
import PropertiesCard from "./GuideNotes"
import PropertiesCardTemplate from "./PropertiesCardTemplate"

const PropertiesComponentMap = {
  card: PropertiesCard,
  section: PropertiesSection,
  cover: PropertiesCover,
  "card-template": PropertiesCardTemplate
}

export default {
  name: "GuideCardOptions",
  inject: ["guideController"],
  computed: {
    component() {
      if (Object.keys(PropertiesComponentMap).includes(this.selectedCard.type)) {
        return PropertiesComponentMap[this.selectedCard.type]
      } else {
        return {
          template: `<div></div>`
        }
      }
    },
    selectedCard() {
      return this.guideController.selectedCard
    }
  }
}
</script>

