<template>
  <div class="content-fit">
    <div
      v-if="typeof this.svg == 'object'"
      id="template"
      class="template"
      ref="template"
      v-bind="fields"
      style="height: 100%"></div>
    <component v-else :is="component" v-bind="fields" ref="svg_element" />
  </div>
</template>

<script>
import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"

export default {
  name: "VueSVG",
  props: ["svg", "fields"],
  inject: ["guideController"],
  data() {
    return {
      domElement: null,
      data: {
        props: this.fields ? Object.keys(this.fields) : {},
        template: `<div>Loading</div>`
      }
    }
  },
  mounted() {
    if (typeof this.svg == "object") {
      this.init(this.$refs.template)
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["findElementByAnchor", "selectCard"]),
    init(domElement) {
      this.domElement = domElement
      if (this.svg) this.refresh()
    },
    refresh() {
      fetch(this.svg.url)
        .then(r => r.text())
        .then(data => {
          if (this.domElement) {
            if (this.fields._title) {
              data = data.replace(/{{_TITLE}}/g, this.fields._title)
            }

            if (this.fields.title) {
              data = data.replace(/{{title}}/g, this.fields.title)
            }

            if (this.fields.objective) {
              data = data.replace(/{{objective}}/g, this.fields.objective)
            }

            if (this.fields._custom_id) {
              data = data.replace(/{{_CUSTOM_ID}}/g, this.fields._custom_id)
            }

            this.domElement.innerHTML = "nothing"
            this.domElement.innerHTML = data
            var svg = this.domElement.querySelector("svg")
            this.preProcessVisio(svg)
            svg.setAttribute("width", "100%")
            svg.setAttribute("height", "100%")

            if (this.$refs["template"]) {
              let dataLink = svg.querySelectorAll("[data-link]")
              if (dataLink) {
                dataLink.forEach(element => {
                  element.style.cursor = "pointer"
                  element.removeEventListener("click", this.goto)
                  element.addEventListener("click", this.goto)
                })
              }
            }
          }
        })
    },
    goto(event) {
      let anchor = event.currentTarget.dataset.link.split("#")[1]
      let result = this.findElementByAnchor(this.guide.sections, anchor)
      if (result.found) {
        this.selectCard(result.element)
      }
    },

    vval(e) {
      if (e.hasAttribute("v:val")) {
        return e.getAttribute("v:val").replace(/VT\d?\((.*)\):?(.*)/, "$1")
      } else {
        return ""
      }
    },

    visioToObject(g) {
      const VISTAG_PROPS = "v\\:custprops"
      const VISTAG_DEFS = "v\\:userdefs"

      var obj = { user: {}, prop: {} }
      var custprops = g.querySelector(VISTAG_PROPS)
      var userdefs = g.querySelector(VISTAG_DEFS)
      if (custprops != null && custprops.parentNode == g) {
        for (var i = 0; i < custprops.children.length; i++) {
          obj.prop[custprops.children[i].getAttribute("v:nameu")] = this.vval(custprops.children[i])
        }
      }

      if (userdefs != null && userdefs.parentNode == g) {
        for (var i = 0; i < userdefs.children.length; i++) {
          obj.user[userdefs.children[i].getAttribute("v:nameu")] = this.vval(userdefs.children[i])
        }
      }

      return obj
    },

    preProcessVisio(svgElement) {
      const VISIO_TAGS = ["v:documentproperties", "v:pageproperties", "v:userdefs", "v:custprops"]
      const VISIO_TAGS_SELECTOR = VISIO_TAGS.map(e => e.replace(/(.*):(.*)/g, "$1\\:$2")).join(",")
      const VISIO_ATTR = ["v:mid", "v:groupcontext"]
      const VISIO_ATTR_SELECTOR = VISIO_ATTR.map(e => e.replace(/(.*):(.*)/g, "[$1\\:$2]")).join(",")

      svgElement.querySelectorAll("g").forEach(g => {
        var titleElement = g.querySelector("title")
        if (titleElement) {
          g.id = titleElement.innerHTML
          titleElement.innerHTML = ""
        }
        var obj = this.visioToObject(g)
        if (obj.user.class) {
          g.setAttribute("class", obj.user.class)
        }
        if (obj.prop.class) {
          g.setAttribute("class", obj.prop.class)
        }

        for (var p in obj.user) {
          g.setAttribute("data-" + p, obj.user[p])
        }

        for (var p in obj.prop) {
          g.setAttribute("data-" + p, obj.prop[p])
        }
      })

      svgElement.querySelectorAll(VISIO_TAGS_SELECTOR).forEach(e => e.remove())
      svgElement.querySelectorAll(VISIO_ATTR_SELECTOR).forEach(e => VISIO_ATTR.forEach(attr => e.removeAttribute(attr)))
      svgElement.removeAttribute("xmlns:v")
    }
  },
  computed: {
    ...mapInjectedData("guideController", ["selectedCard", "guide"]),
    component() {
      if (!this.svg) return {}
      if (typeof this.svg == "object") return {}
      else {
        this.$nextTick(() => {
          this.$refs["svg_element"].$el.setAttribute("height", "100%")
          this.$refs["svg_element"].$el.setAttribute("width", "100%")
        })
        return {
          props: Object.keys(this.fields),
          template: this.svg
        }
      }
    }
  },
  watch: {
    svg() {
      if (typeof this.svg == "object") {
        this.init(this.$refs.template)
      }
    }
  }
}
</script>


