var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToComment(false)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-arrow-left-outline")])],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.commentsFiltered.length > 0
          ? 'Go to previous comment (' + (_vm.navigationIndex ? _vm.navigationIndex : 1) + '/' + _vm.commentsFiltered.length + ')'
          : _vm.applyFilters
          ? 'No comments found with filter criteria'
          : 'No comments found'
      )}})]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"small":"","icon":"","flat":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToComment(true)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-comment-arrow-right-outline")])],1)]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(
        _vm.commentsFiltered.length > 0
          ? 'Go to next comment (' + (_vm.navigationIndex ? _vm.navigationIndex + 1 : 1) + '/' + _vm.commentsFiltered.length + ')'
          : _vm.applyFilters
          ? 'No comments found with filter criteria'
          : 'No comments found'
      )}})])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }