<template>
  <p-panel title="Objectives Level">
    <p-panel-properties>
      <template v-if="Object.entries(objectives).length > 0">
        <p-property-tile title="Assessments's summary">
          <v-btn flat icon @click="openSummary"> <v-icon>mdi-open-in-new</v-icon></v-btn>
        </p-property-tile>
        <div v-for="([id, learningObjective], index) in Object.entries(objectives)" :key="id">
          <p-panel-properties-group
            icon="mdi-bullseye-arrow"
            :title="learningObjective.objective.title"
            :active="index === 0">
            <div class="pl-4 pr-4">
              <objective-description :learningObjective="learningObjective" />
              <content-related-objective :learningObjective="learningObjective" />
              <div v-for="quiz in learningObjective.quizzes" :key="quiz.id">
                <quiz-information :quiz="quiz" />
              </div>
            </div>
          </p-panel-properties-group>
        </div>
      </template>
      <div class="pl-4 pr-4" v-else>
        <v-icon class="mr-2">mdi-human-handsdown</v-icon>No objectives found in current Interactive Presentation.
      </div>
    </p-panel-properties>
  </p-panel>
</template>

<script>
import PPanel from "../../blocks/_components/utils/PPanel.vue"
import PPropertyTile from "../../blocks/_components/utils/PPropertyTile.vue"
import PPanelProperties from "../../blocks/_components/utils/PPanelProperties.vue"
import PPanelPropertiesGroup from "../../blocks/_components/utils/PPanelPropertiesGroup.vue"
import ContentRelatedObjective from "./sections/ContentRelatedObjective.vue"
import ObjectiveDescription from "./sections/ObjectiveDescription.vue"
import QuizInformation from "./sections/QuizInformation.vue"
import { PtModal } from "plant-common"
import AssessmentSummary from "./modal/AssessmentSummary.vue"

export default {
  name: "PerformancePanel",
  inject: ["lessonPlanController"],
  mixins: [PtModal],
  components: {
    PPanel,
    PPanelProperties,
    PPanelPropertiesGroup,
    PPropertyTile,
    ContentRelatedObjective,
    ObjectiveDescription,
    QuizInformation
  },
  data() {
    return {}
  },
  computed: {
    objectives() {
      return this.lessonPlanController.activityState.performance
    }
  },
  methods: {
    openSummary() {
      let objectivesWithCompletionReason = JSON.parse(JSON.stringify(this.objectives))
      Object.values(objectivesWithCompletionReason).forEach(objectiveWCR => {
        if (objectiveWCR.section && objectiveWCR.section.id) {
          const slide = this.lessonPlanController.getSlideFromSlideId(objectiveWCR.section.id)
          const activityState = this.lessonPlanController.getSlideActivityState(slide)
          objectiveWCR.completionReason = activityState.completionReason
        } else {
          objectiveWCR.completionReason = null
        }
      })
      this.$modal(AssessmentSummary, {
        objectives: objectivesWithCompletionReason
      })
    }
  }
}
</script>

<style scoped>
.v-list__tile__action .v-btn--icon {
  margin: 0px;
}
</style>
