<template>
  <div style="position: absolute; left: 0px; right: 0px; bottom: 0px; top: 0px">
    <template>
      <guide-toolbar-viewer
        :style="`padding-left:${(isPresentMode ? 0 : 80) + (drawer > -1 ? 400 : 0)}px;padding-right:${
          (isPresentMode || mode == 'activity' ? 0 : 80) + (right_drawer > -1 ? 400 : 0)
        }px;`" />
      <viewer-shortcuts />
      <template v-if="!isPresentMode">
        <v-navigation-drawer stateless absolute permanent mini-variant left>
          <v-list class="pt-0">
            <v-tooltip right v-for="(item, index) in items_left" :key="index">
              <template v-slot:activator="{ on }">
                <v-list-tile v-on="on" @click="drawer = drawer == index ? -1 : index" :value="index == drawer">
                  <v-list-tile-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-tile-action>
                </v-list-tile>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list>
          <v-list style="position: absolute; bottom: 0px; left: 0px; right: 0px">
            <v-list-tile @click="drawer = drawer > -1 ? -1 : 0">
              <v-list-tile-action>
                <v-icon>mdi-chevron-double-{{ drawer ? "right" : "left" }}</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>
        <v-navigation-drawer v-if="drawer > -1" absolute left width="400px" style="margin-left: 80px">
          <component :is="items_left[drawer].component" />
        </v-navigation-drawer>
      </template>

      <ViewerCard
        :style="`position:absolute;overflow:hidden;border: 0px solid red; left:${
          (isPresentMode ? 0 : 80) + (drawer > -1 ? 400 : 0)
        }px;right:${
          (isPresentMode || mode == 'activity' ? 0 : 80) + (right_drawer > -1 ? 400 : 0)
        }px;top:40px;bottom:36px;`" />
      <template v-if="!isPresentMode && mode != 'activity'">
        <v-navigation-drawer stateless absolute permanent mini-variant right>
          <v-list class="pt-0">
            <v-tooltip right v-for="(item, index) in items_right" :key="index">
              <template>
                <v-list-tile @click="right_drawer = right_drawer == index ? -1 : index" :value="index == right_drawer">
                  <v-list-tile-action>
                    <v-badge v-if="item.badge" small :color="item.badge.color" overlap>
                      <template v-slot:badge>
                        <v-icon small>{{ item.badge.icon }}</v-icon>
                      </template>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-badge>
                    <v-icon v-else>{{ item.icon }}</v-icon>
                  </v-list-tile-action>
                </v-list-tile>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list>
          <v-list style="position: absolute; bottom: 0px; left: 0px; right: 0px">
            <v-list-tile @click="right_drawer = right_drawer > -1 ? -1 : 0">
              <v-list-tile-action>
                <v-icon>mdi-chevron-double-{{ right_drawer > -1 ? "right" : "left" }}</v-icon>
              </v-list-tile-action>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>

        <v-navigation-drawer v-if="right_drawer > -1" absolute right width="400px" style="margin-right: 80px">
          <component :is="items_right[right_drawer].component" />
        </v-navigation-drawer>
      </template>
      <bottom-nav
        v-if="mode != 'activity'"
        :styleProp="`left:${(isPresentMode ? 0 : 80) + (drawer > -1 ? 400 : 0)}px;
     right:${(isPresentMode ? 0 : 80) + (right_drawer > -1 ? 400 : 0)}px; 
     position: absolute; bottom: 0px;`" />
    </template>
  </div>
</template>

<script>
import { PContentContainer } from "plant-common"
import GuideToolbarViewer from "./ViewerToolbar"
import ViewerNavBar from "../Viewer/ViewerNavBar"
import BottomNav from "../Viewer/BottomNav"
import ViewerInfo from "./ViewerInfo"
import ViewerCurrentDetail from "./ViewerCurrentDetail"
import ViewerCurrentNotes from "./ViewerCurrentNotes"
import ViewerCurrentObjectives from "./ViewerCurrentObjectives"
import ViewerCurrentMisc from "./ViewerCurrentMisc"
import ViewerShortcuts from "../Viewer/ViewerShortcuts"
import ViewerCard from "../Common/Viewer"
import guideController from "../GuideController"
import VerticalTabs from "../../../VerticalTabs"
import { roles } from "../../../../config/constants"
import { STORE_USER_NAME, STATE_PROFILE } from "../../../../store_constants/user"

import { mapInjectedData, mapInjectedMethods } from "plant-common/src/utils"
import { mapState } from "vuex"
import { STORE_MODULE_COMMENTS, STATE_CURRENT_LOCATION } from "../../../../store_constants/content"
import { getCustomId } from "../../../../utils/dbTool"
export default {
  name: "GuideViewer",
  props: ["value", "view", "mode", "records", "isStarted"],
  components: {
    GuideToolbarViewer,
    ViewerCard,
    BottomNav,
    ViewerNavBar,
    ViewerShortcuts,
    PContentContainer,
    VerticalTabs
  },
  data() {
    return {
      drawer: -1,
      right_drawer: -1,
      guideController: guideController(this),
      right_selected: null,
      items_left: [
        {
          title: "Lesson Information",
          icon: "mdi-information-outline",
          component: ViewerInfo
        },
        { title: "Outline", icon: "mdi-file-tree", component: ViewerNavBar }
      ]
    }
  },
  created() {
    if (this.isStarted) {
      this.loadProgressInGuide()
    } else {
      if (this.view) {
        this.selectCardFromId(this.view.card_id)
      } else {
        this.selectCard(this.guide.cover)
      }
    }
  },
  computed: {
    isPresentMode() {
      return this.mode == "present"
    },
    ...mapState(STORE_MODULE_COMMENTS, {
      current_location: STATE_CURRENT_LOCATION
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    is_student() {
      return this.profile.role == roles.student.value
    },
    ...mapInjectedData("guideController", ["selectedCard", "cards", "guide"]),
    items_right() {
      var is_latest
      var { selectedCard } = this.guideController
      if (!selectedCard.content) is_latest = undefined
      else {
        if (selectedCard.content.header) {
          var latest_revision = this.guideController.latests_revisions[getCustomId(selectedCard.content)]
          var current_revision = `${selectedCard.content.header.major_revision}.${selectedCard.content.header.minor_revision}`
          is_latest = current_revision == latest_revision
        }
      }
      return [
        {
          title: "Speaker Notes",
          icon: "mdi-message-bulleted",
          component: ViewerCurrentNotes
        },
        {
          title: "Content Detail",
          icon: "mdi-file-outline",
          component: ViewerCurrentDetail,
          badge:
            typeof is_latest !== "undefined"
              ? {
                  icon: is_latest ? "mdi-check" : "mdi-exclamation",
                  color: is_latest ? "success" : "warning"
                }
              : false
        },
        {
          title: "Objectives",
          icon: "mdi-bullseye-arrow",
          component: ViewerCurrentObjectives
        },
        {
          title: "More Adjustments",
          icon: "mdi-dots-horizontal",
          component: ViewerCurrentMisc
        }
      ]
    }
  },
  provide() {
    return {
      guideController: this.guideController
    }
  },
  methods: {
    ...mapInjectedMethods("guideController", ["decrement", "increment", "selectCard", "selectCardFromId"]),
    loadProgressInGuide() {
      if (this.guideController.activityState) {
        let cardToLoad = this.cards[0]
        if (this.guideController.activityState.completion) {
          cardToLoad = this.cards[this.cards.length - 1]
        } else {
          this.cards.every(card => {
            if (!this.guideController.activityState.activities[card.id].completion) {
              cardToLoad = card
              return false
            }
            return true
          })
        }
        this.selectCard(cardToLoad)
      }
    }
  },
  watch: {
    current_location: function (newLocation, oldLocation) {
      if (this.current_location && this.current_location.id !== this.selectedCard.id) {
        this.selectCardFromId(this.current_location.id)
      }
    },
    value: function (newValue, oldValue) {
      this.$router.go()
    }
  }
}
</script>
./ViewerNavBar./BottomNav./ViewerShortcuts