import "./pt-logic-output-component.css"

export default {

  computed: {
    action1_output() {
      return this.inputs.action1
    },
    action2_output() {
      return this.inputs.action2
    },
    action3_output() {
      return this.inputs.action3
    },
    action4_output() {
      return this.inputs.action4
    }
  },
}

