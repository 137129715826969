var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lp-flip-card-view",class:{
		animation: _vm.mode !== 'edit',
		hover: _vm.value.data.trigger === 'hover',
		click: _vm.value.data.trigger === 'click' && _vm.flip,
		pointer: _vm.value.data.trigger === 'click',
		'lp-flip-card-view-edit': _vm.mode === 'edit'
	},on:{"click":function($event){$event.stopPropagation();_vm.flip = !_vm.flip;
		_vm.setAsVisited('click');},"mouseover":function($event){$event.stopPropagation();return _vm.setAsVisited('hover')}}},[_c('div',{staticClass:"lp-flip-card-view-content",on:{"dblclick":function($event){_vm.mode === 'edit' ? _vm.$emit('setTextEdit', true) : ''}}},[(_vm.mode === 'edit' && _vm.editable)?[_c('editor-content',{staticClass:"front",class:{ hidden: _vm.side === 'back' },style:(_vm.styleFront),attrs:{"editor":_vm.editorFront}}),_c('editor-content',{staticClass:"back",class:{ hidden: _vm.side === 'front' },style:(_vm.styleBack),attrs:{"editor":_vm.editorBack}})]:[_c(_vm.flipCardBlock,{tag:"component",staticClass:"front",class:{ hidden: _vm.side === 'back' },style:(_vm.styleFront),attrs:{"value":_vm.value.data.front.html,"blockVariables":_vm.blockVariables,"block":_vm.value,"mode":_vm.mode}}),_c(_vm.flipCardBlock,{tag:"component",staticClass:"back",class:{ hidden: _vm.side === 'front' },style:(_vm.styleBack),attrs:{"value":_vm.value.data.back.html,"blockVariables":_vm.blockVariables,"block":_vm.value,"mode":_vm.mode}})]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }