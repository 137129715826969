import './pt-relay-supply-bus.css';
import CLICK_CONTEXTMENU from '../../mixins/CLICK_CONTEXTMENU';
import MOUSE_ENTER_LEAVE from '../../mixins/MOUSE_ENTER_LEAVE';

export default {
	mixins: [CLICK_CONTEXTMENU, MOUSE_ENTER_LEAVE],
	model: {
		'value': {
			private: true,
			type: 'boolean',
			default: true,
			bind: {
				to: 'attr',
				name: 'data-value'
			}
		}
	}
};
