var selectContentFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var findContentFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var openContentFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var getLatestFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var uploadFileServiceFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var getDependentsFunction = () => new Promise((resolve, reject) => reject("Not implemented"))
var getContentFromRefFunction = () => new Promise((resolve, reject) => reject("Not implemented"))

/**
 * Registers a driver that implements the PServiceContent
 * @param {Object} PContentDriver
 * @param {function} PContentDriver.selectContentDriver - A driver function to prompt the user to select content
 * @param {function} PContentDriver.findContentDriver - A driver function to find content and return an array
 * @param {function} PContentDriver.openContentDriver - A driver function to open a content and the target
 * @param {function} PContentDriver.uploadFileServiceDriver - A driver function to upload a file in current Draft
 * @param {function} PContentDriver.getDependentsFunction - A driver function to get dependents of current Content
 * @param {function} PContentDriver.getContentFromRefFunction - A driver function to get the content from a reference(absolute or relative)
 */
const registerDriver = function ({
  selectContentDriver,
  findContentDriver,
  getLatestDriver,
  openContentDriver,
  uploadFileServiceDriver,
  getDependentsDriver,
  getContentFromRefDriver
}) {
  selectContentFunction = selectContentDriver
  findContentFunction = findContentDriver
  openContentFunction = openContentDriver
  getLatestFunction = getLatestDriver
  uploadFileServiceFunction = uploadFileServiceDriver
  getDependentsFunction = getDependentsDriver
  getContentFromRefFunction = getContentFromRefDriver
}
/**
 * Prompts user to select content
 * @param {Object} selectContentOptions
 * @param {string | array<string>} selectContentOptions.type - Content type to find
 * @returns {Promise<PContent>} The PContent selected by the user
 */
const selectContent = function (selectContentOptions) {
  return selectContentFunction(selectContentOptions)
}
/**
 * Finds content from the content repository.
 * @param {Object} selectContentOptions
 * @param {string} selectContentOptions.type - Content type to find
 * @returns {Promise<[PContent]>} The promise as an array of PContent
 */
const findContent = function (findContentOptions) {
  return findContentFunction(findContentOptions)
}
/**
 * Open content
 * @param {string} plant_url - PLANT content url. eg. plant://content/17236y4uhsdy23/1/3
 * @param {'tab'|'dialog'} target - where to open the content
 */
const openContent = function (plant_url, target, tag) {
  return openContentFunction(plant_url, target, tag)
}

/**
 * Get latest versions
 * @param {Array} getLatestOptions
 * @returns {Promise<Array>} The promise as an array of latest versions
 */
const getLatest = function (getLatestOptions) {
  return getLatestFunction(getLatestOptions)
}
/**
 * Upload File Service
 * @param {File} file
 * @returns {Promise<{_file: String, url: String}>} The promise as an array of latest versions
 */
const uploadFileService = function (file, contentId) {
  return uploadFileServiceFunction(file, contentId)
}

/**
 * Get Dependents
 * @param {Object} content
 * @returns {Promise<Array>} The promise as an array of content dependents
 */
const getDependents = function (content) {
  return getDependentsFunction(content)
}

const getContentFromRef = function (ref, dependencies) {
  return getContentFromRefFunction(ref, dependencies)
}

export default {
  selectContent,
  findContent,
  registerDriver,
  getLatest,
  openContent,
  uploadFileService,
  getDependents,
  getContentFromRef
}
