<template>
  <span v-if="value">
    <p-toolbar-button
      v-if="value.data.audio"
      icon="mdi-image-off-outline"
      @click.stop="removeAudio"
      :disabled="changeContentProtection">
      Remove Audio
    </p-toolbar-button>
    <p-toolbar-button v-if="value.data.typeAction && !value.data.audio" icon="mdi-select-off" @click.stop="removeAudio">
      Remove Action type
    </p-toolbar-button>
  </span>
</template>

<script>
import { PToolbarButton } from "plant-common"

export default {
  name: "AudioToolbar",
  props: ["value"],
  components: {
    PToolbarButton
  },
  methods: {
    removeAudio() {
      this.$set(this.value.data, "audio", null)
      this.$set(this.value.data, "typeAction", null)
      this.$set(this.value.data, "subtitles", {})
    }
  },
  computed: {
    changeContentProtection() {
      return this.value.protection.data
    }
  }
}
</script>
