<template>
  <v-toolbar flat class="view-menu__options">
    <div class="view-menu__options--image-type">
      <ContentTypeIcon v-if="content" :type="getType(content)" />
    </div>
    <v-divider vertical></v-divider>

    <div class="view-menu__options--custom-id">
      <v-chip small label dark>
        <v-icon small>mdi-tag</v-icon>
        {{ getCustomId(content) }}
      </v-chip>

      <div>{{ getTitle(content) }}</div>
    </div>

    <v-divider vertical></v-divider>
    <v-spacer></v-spacer>

    <v-dialog lazy persistent v-model="dialog" width="70%">
      <template v-slot:activator="{ on }">
        <v-btn color="primary lighten-2" depressed dark v-on="on">Preview</v-btn>
      </template>

      <v-card>
        <v-card-text>
          <div class="view-menu">
            <v-toolbar flat class="view-menu__options">
              <div class="view-menu__options--image-type">
                <ContentTypeIcon v-if="content" :type="getType(content)" />
              </div>
              <v-divider vertical></v-divider>

              <div class="view-menu__options--custom-id">
                <v-chip small label dark>
                  <v-icon small>mdi-tag</v-icon>
                  {{ getCustomId(content) }}
                </v-chip>

                <div>{{ getTitle(content) }}</div>
              </div>

              <v-divider vertical></v-divider>
            </v-toolbar>
          </div>

          <div class="library-content__wrapper-viewer">
            <item-viewer :value="content" :view.sync="view"></item-viewer>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import ItemViewer from "plant-common/src/components/ItemViewer"
import PToolbar from "plant-common/src/components/PToolbar"
import PToolbarButton from "plant-common/src/components/PToolbarButton"
import { ContentTypeIcon } from "plant-common"
import { getType, getTitle, getCustomId } from "../../../../utils/dbTool"
import PServiceContent from "plant-common/src/services/PServiceContent"

export default {
  name: "LibraryContentInsideEdit",
  components: {
    ItemViewer,
    PToolbar,
    PToolbarButton,
    ContentTypeIcon
  },
  props: {
    origin: {
      type: String
    },
    pinned_view: {
      type: String
    }
  },
  data() {
    return {
      content: null,
      view: null,
      dialog: false
    }
  },
  computed: {},
  async created() {
    if (this.origin) {
      this.content = await PServiceContent.getContentFromRef(this.origin)
    }
    if (this.pinned_view) {
      this.view = JSON.parse(this.pinned_view)
    }
    if (!this.view) this.view = { interface: "simple" }
    if (this.view && !this.view.interface) this.$set(this.view, "interface", "simple")
  },
  mounted() {},
  methods: {
    getCustomId: getCustomId,
    getTitle: getTitle,
    getType: getType
  }
}
</script>

<style scoped>
.library-content__wrapper-editor {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}

.library-content__wrapper-viewer {
  position: relative;
  width: 100%;
  height: 650px;
  overflow: hidden;
}

.view-menu__options--image-type {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.view-menu__options--image-type > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
}
</style>
