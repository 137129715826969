import { Extension } from '@tiptap/core'

export const Clipboard = Extension.create({
    name: 'clipboard',

    addCommands() {
        return {
            pasteText: (clipboardText) => async ({ tr }) => {
                let onlyText = clipboardText.replace(/<\/?[^>]+(>|$)/g, "")
                let onlyTextNoBreaks = onlyText.replace(/(\r\n|\n|\r)/gm, "")
                tr.insertText(onlyTextNoBreaks)
                return true
            },
            pasteFormat: (clipboardText) => ({ chain }) => {
                return chain().insertContent(clipboardText).run()
            },
        }

    }
})