import { BlockBasic } from "../_models/models.js"

export default class BlockShape extends BlockBasic {
  constructor(block) {
    super(block)
    this.type = "shapeLine"
    this.size.width = 400
    this.size.height = 1
    this.position.x = 0
    this.position.y = 0
    this.data = {
      begin: {
        x: 0,
        y: 15,
        marker: { 
          type: "none", 
          size: 1 
        }
      },
      end: {
        x: 400,
        y: 15,
        marker: { 
          type: "none", 
          size: 1
        }
      },
      style: {
        fill: "#000000ff",
        stroke: "#000000ff",
        strokeWidth: 3
      }
    }
  }
}
