
import parseBoolean from "./config/parseBoolean"
import "./pt-logic-input-push.css"

export default {
  model: {
    "value": {
      type: "boolean",
      default: false
    }
  },
  listeners: {
    mousedown() {
      this.data.value = true
      return false
    },
    mouseup() {
      this.data.value = false
      return false
    }
  }
}
