import "./pt-piping-penetration.css"
import model from "../../prototypes/STATUS_FLOW_NOFLOW"

export default {
    selector: "g.pt-piping-penetration",
    model,
    listeners: {
        mouseenter: true,
        mouseleave: true,
        click: true
    }
}
