<template>
  <v-dialog :value="value" scrollable max-width="1800px" persistent>
    <v-card height="90vh" width>
      <v-toolbar flat>
        <v-toolbar-title> <v-icon left>mdi-upload</v-icon>Bulk Upload </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon flat @click.stop="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <div
        v-if="state !== 'waiting-for-files'"
        style="
          position: absolute;
          top: 65px;
          bottom: 0px;
          height: calc(100% - 65px);
          width: 350px;
          border-right: rgba(0, 0, 0, 0.12) solid thin;
        ">
        <bulk-upload-options
          v-if="state == 'user-input'"
          :files_extension="files_extension"
          :allowed_types="allowed_types"
          :approve.sync="upload_options.approve"
          :contributors.sync="upload_options.contributors"
          :destination_folder.sync="upload_options.destination_folder"
          :type.sync="upload_options.type"
          :workflow.sync="upload_options.workflow" />
        <div class="pl-3 pr-3" v-if="state == 'user-input' && uploadMethod == 'files'">
          <v-divider></v-divider>
          <v-subheader>Tools</v-subheader>
          <bulk-upload-replace-dialog @input="quickReplace" />
          <v-btn block color="green darken-3" outline @click="downloadXlsx">
            <v-icon left>mdi-download</v-icon>Download XLSX
          </v-btn>
          <v-btn block color="green darken-3" outline @click="uploadXlsx">
            <v-icon left>mdi-upload</v-icon>Upload XLSX
          </v-btn>
        </div>
        <div
          class="pl-3 pr-3"
          v-if="state == 'user-input' && uploadMethod == 'spreadsheet' && contentBulkUploadOptions.attachments">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" block color="green darken-3" outline @click="uploadContentAttachments">
                <v-icon left>mdi-upload</v-icon>Upload Attachments
              </v-btn>
            </template>
            <span>The image filenames should match the content custom_id where it will be attached</span>
          </v-tooltip>
        </div>
        <div style="position: absolute; bottom: 0px; padding: 10px; width: 100%">
          <v-divider></v-divider>
          <v-btn color="success" block outline @click.stop="startUpload" v-if="state == 'user-input'">
            <v-icon left>mdi-play</v-icon>Start Upload
          </v-btn>
          <v-btn color="red" block outline @click.stop="state = 'cancelled'" v-if="state == 'uploading'">
            <v-icon left>mdi-stop</v-icon>Cancel Upload
          </v-btn>
          <v-btn color="primary" block outline @click.stop="close()" v-if="state == 'completed'">
            <v-icon left>mdi-close</v-icon>Close
          </v-btn>
        </div>
        <bulk-upload-progress v-if="state == 'completed' || state == 'uploading'" :items="new_contents" />
      </div>
      <bulk-upload-table
        v-if="state !== 'waiting-for-files'"
        :contents="new_contents"
        :disabled="state !== 'user-input'" 
        :type="content_type"/>
      <template v-if="state == 'waiting-for-files'">
        <bulk-upload-drag-and-drop
          v-if="uploadMethod == DRAG_AND_DROP_OPTION"
          :type="content_type"
          @drop-finished="manageFiles" />
        <bulk-upload-upload-xls v-if="uploadMethod == XLS_OPTION" :type="content_type" @upload-finished="manageFiles" />
        <bulk-upload-upload-xls-with-reference
          v-if="uploadMethod == 'spreadsheet-with-reference'"
          @upload-finished="manageFiles"
          :type="content_type" />
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import XLSX from "xlsx"
import { mapState, mapActions } from "vuex"
import { STORE_USER_NAME, STATE_PROFILE } from "@/store_constants/user"
import { uploadFile, selectFileFromComputer } from "@/utils/bulkUpload"

import * as ContentBodies from "@/classes/content/index.js"
import { Attachment } from "@/classes/content/index.js"
import { DRAG_AND_DROP_OPTION, XLS_OPTION } from "./constants.js"

import PtApiClient from "plant-api-client"

import BulkUploadReplaceDialog from "./ReplaceDialog.vue"
import BulkUploadTable from "./Table.vue"
import BulkUploadOptions from "./Options.vue"
import BulkUploadProgress from "./Progress.vue"
import BulkUploadDragAndDrop from "./DragAndDrop"
import BulkUploadUploadXls from "./UploadXls"
import BulkUploadUploadXlsWithReference from "./UploadXlsWithReference"

import { STORE_EXPLORER_NAME, STATE_FOLDER } from "@/store_constants/explorer"
import { STORE_NOTIFICATIONS_NAME, ACTION_NOTIFICATIONS_WARNING } from "@/store_constants/notifications"

export default {
  name: "BulkUpload",
  props: ["value", "content_type"],
  components: {
    BulkUploadReplaceDialog,
    BulkUploadTable,
    BulkUploadOptions,
    BulkUploadProgress,
    BulkUploadDragAndDrop,
    BulkUploadUploadXls,
    BulkUploadUploadXlsWithReference
  },
  data() {
    return {
      state: "waiting-for-files", // 'user-input', 'uploading', 'completed', 'select-type'
      files_extension: "",
      allowed_types: [],
      upload_options: {
        destination_folder: { _id: "", name: "" },
        type: "",
        workflow: "no_reviewer",
        approve: false,
        contributors: [{ role: "author", user_id: "" }]
      },
      new_contents: [],
      files: [],
      DRAG_AND_DROP_OPTION,
      XLS_OPTION
    }
  },
  computed: {
    ...mapState(STORE_EXPLORER_NAME, {
      folder: STATE_FOLDER
    }),
    ...mapState(STORE_USER_NAME, {
      profile: STATE_PROFILE
    }),
    uploadMethod() {
      return Object.values(ContentBodies).find(b => b.BULK_UPLOAD && b.CONTENT_TYPE == this.content_type).BULK_UPLOAD
        .method
    },
    contentBulkUploadOptions() {
      return Object.values(ContentBodies).find(c => c.BULK_UPLOAD && c.CONTENT_TYPE == this.content_type).BULK_UPLOAD
    }
  },
  created() {
    // Set default options
    this.upload_options.contributors[0].user_id = this.profile._id
    this.upload_options.destination_folder._id = this.folder._id
    this.upload_options.destination_folder.name = this.folder.name
    this.upload_options.workflow = "no_reviewer"
  },
  methods: {
    manageFiles(newContents) {
      this.new_contents = newContents
      this.upload_options.type = this.content_type
      this.allowed_types = [this.content_type]
      this.state = "user-input"
    },
    quickReplace({ field, find, replace, start }) {
      var i = start
      for (var content of this.new_contents) {
        var replace_i = replace
        var hashes = replace.match(/#{1,6}/g)
        if (hashes) {
          replace_i = replace.replace(/#{1,6}/g, i.toString().padStart(hashes[0].length, "0"))
        }
        content[field] = content[field].replace(find || /.*/, replace_i)
        i = i + 1
      }
    },
    downloadXlsx() {
      var wb = XLSX.utils.book_new()
      var export_table = this.new_contents.map(content => {
        var { filename, custom_id, custom_revision, title } = content
        return { filename, custom_id, custom_revision, title }
      })
      var ws = XLSX.utils.json_to_sheet(export_table)
      XLSX.utils.book_append_sheet(wb, ws, "PLANT-IMPORT")
      XLSX.writeFile(wb, "bulk-upload.xlsx")
    },
    async uploadXlsx() {
      var file_buffer = await uploadFile()
      var wb = XLSX.read(file_buffer, { type: "array" })
      var ws = wb.Sheets["PLANT-IMPORT"]
      var changes = XLSX.utils.sheet_to_json(ws)
      for (var change of changes) {
        var { filename } = change
        var content = this.new_contents.find(c => c.filename == filename)
        if (change.custom_id) change.custom_id = change.custom_id + ""
        if (change.title) change.title = change.title + ""
        if (content) Object.assign(content, change)
      }
    },
    async uploadContentAttachments() {
      // show modal to pick up multiple files
      var files = await selectFileFromComputer(this.contentBulkUploadOptions.attachments[0].accept, true)
      let customIdsNotFound = []
      // for each file, if name match with the custom id, add it to content.attachment
      for (var file of files) {
        var extension = file.name.substring(file.name.lastIndexOf("."))
        var filename = file.name.slice(0, file.name.length - extension.length)
        var content = this.new_contents.find(content => content.custom_id == filename)
        if (content) {
          let url = URL.createObjectURL(file)
          content.body.attachments.push(
            new Attachment(this.contentBulkUploadOptions.attachments[0].name, file, url, extension)
          )
        } else {
          customIdsNotFound.push(filename)
        }
      }
      if (customIdsNotFound.length > 0) {
        this.warning({
          message: `The following custom_ids were not found in current list: ${customIdsNotFound.join(", ")}`,
          timeout: -1
        })
      }
    },
    async startUpload() {
      this.state = "uploading"
      for (var content of this.new_contents) {
        content.status = "in-progress"
        if (this.state == "cancelled") {
          content.status = "error"
        } else {
          try {
            content.uploaded = await PtApiClient.createContent(
              content.title,
              content.custom_id,
              this.upload_options.destination_folder._id,
              this.upload_options.type
            )
            var { content_id } = content.uploaded.itemRevisionDraft.header.metadata

            await PtApiClient.updateDraftWorkflow(content_id, "method", "changeWorkflow", {
              name: this.upload_options.workflow,
              lightweight: true
            })
            await PtApiClient.updateDraftWorkflow(content_id, "method", "reassignRole", {
              role: this.upload_options.contributors[0].role,
              user_id: this.upload_options.contributors[0].user_id,
              lightweight: true
            })
            const metadata = {
              library_id: content.uploaded?.itemRevisionDraft?.header?.metadata?.library_id,
              custom_revision: content.custom_revision,
              title: content.title,
              content_id,
              summary_changes: "<p>Content created from bulk upload feature.</p>"
            }
            await PtApiClient.updateDraftBody(content_id, content.body)
            await PtApiClient.updateDraftMetadata(content_id, metadata)
            if (this.upload_options.approve) {
              await PtApiClient.updateDraftWorkflow(content_id, "transition", "approveMinorChange")
            }
            content.status = "done"
          } catch (error) {
            console.error(error)
            content.status = "error"
            content.error = error.response.data.message
          }
        }
      }
      this.state = "completed"
    },
    close() {
      this.new_contents = []
      this.$emit("input", false)
    },
    ...mapActions(STORE_NOTIFICATIONS_NAME, {
      warning: ACTION_NOTIFICATIONS_WARNING
    })
  },
  watch: {
    value() {
      this.state = "waiting-for-files"
    },
    new_contents: {
      deep: true,
      handler() {
        if (this.new_contents.length == 0) {
          this.state = "waiting-for-files"
        }
      }
    }
  }
}
</script>

<style>
</style>./DragAndDrop.js./UploadXls.js./UploadXlsWithReference.js